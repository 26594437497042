import { NextApiRequest } from 'next';
import { getSession } from 'next-auth/react';
import { IDB_INTEGRATION_STORE_NAME, INTEGRATIONS } from './constants';

const unauthorizedResponse: ServerResponseWithItemsData = {
    ok: false,
    msg: 'User not Authorzied',
    data: { error: 'unauthorized' },
    code: 401,
};

export const getSessionOrThrowError = async (req: NextApiRequest) => {
    const session: any = await getSession({ req });
    if (session) {
        return session;
    } else {
        throw unauthorizedResponse;
    }
};

export const getNextAuthSession = async () => {
    const session: any = await getSession();
    return session;
};

export const buildIntegrationItemList = (items, rootId) => {
    const insertChildrenInList = (
        parentId: string,
        ancestorList: string[],
        childIds: string[],
        items,
        zIndex: number,
    ) => {
        if (!childIds || childIds.length < 1) return [];
        const arr = [];
        for (let i = 0; i < childIds.length; i++) {
            const childId = childIds[i];
            const item = items[childId];
            if (!item) continue;
            const grandchildIds = item.childrenList || [];
            if (grandchildIds.length > 0) {
                arr.push({
                    raw: item,
                    id: childIds[i],
                    childIndex: i,
                    parentId,
                    ancestorList,
                    hasChildren: true,
                    zIndex,
                    name: item.name,
                    '@type': 'IntegrationItem',
                    iconLink: item?.iconLink || item?.thumbnail_url || null,
                });
                arr.push(
                    insertChildrenInList(childId, ancestorList.concat([childId]), grandchildIds, items, zIndex + 1),
                );
            } else {
                arr.push({
                    raw: item,
                    id: childId,
                    childIndex: i,
                    parentId,
                    ancestorList,
                    hasChildren: false,
                    zIndex,
                    name: item.name,
                    '@type': 'IntegrationItem',
                    iconLink: item?.iconLink || item?.thumbnail_url || null,
                });
            }
        }
        return arr;
    };

    const childrenList = items?.[rootId]?.childrenList;
    return insertChildrenInList(null, [], childrenList, items, 0).flat(Infinity);
};

const IDB_NAME = 'Bedrock';
export const IDB_VERSION = 1;

const openIDBDatabase = (): Promise<IDBDatabase> => {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = window.indexedDB.open(IDB_NAME, IDB_VERSION);

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db = (event.target as IDBRequest<IDBDatabase>).result;
            db.createObjectStore(IDB_INTEGRATION_STORE_NAME, { keyPath: 'id' });
        };

        request.onsuccess = () => {
            resolve(request.result as IDBDatabase);
        };

        request.onerror = () => {
            reject('Failed to initialize integrations cache storage');
        };
    });
};

async function withIDBTransaction<T>(
    mode: IDBTransactionMode,
    callback: (transaction: IDBTransaction, objectStore: IDBObjectStore) => Promise<T>,
): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
        try {
            const db = await openIDBDatabase();
            const transaction = db.transaction(IDB_INTEGRATION_STORE_NAME, mode);
            const objectStore = transaction.objectStore(IDB_INTEGRATION_STORE_NAME);
            const result = await callback(transaction, objectStore);
            resolve(result);
        } catch (error) {
            reject(error);
        }
    });
}

export function writeIntegrationsToIndexedDB<T>(userId: string, data: T): Promise<void> {
    return withIDBTransaction('readwrite', async (_, objectStore) => {
        return new Promise<void>((resolve, reject) => {
            const getRequest = objectStore.get(userId);

            getRequest.onsuccess = () => {
                const existingData = getRequest.result;
                if (existingData) {
                    const putRequest = objectStore.put({ id: userId, data });
                    putRequest.onsuccess = () => resolve();
                    putRequest.onerror = () => reject(new Error('Error while caching integrations data'));
                } else {
                    const addRequest = objectStore.add({ id: userId, data });
                    addRequest.onsuccess = () => resolve();
                    addRequest.onerror = () => reject(new Error('Error while caching integrations data'));
                }
            };

            getRequest.onerror = () => reject(new Error('Error while reading integrations data cache'));
        });
    });
}

export function readIntegrationsFromIndexedDB(userId: string): Promise<any> {
    return withIDBTransaction('readwrite', async (_, objectStore) => {
        return new Promise((resolve, reject) => {
            const request = objectStore.get(userId);

            request.onsuccess = (event) => {
                const result = (event.target as IDBRequest).result;
                resolve(result?.data ? JSON.parse(result.data) : {});
            };

            request.onerror = () => reject(new Error('Error while reading integrations data from cache'));
        });
    });
}

export const getDriveFileMetadata = async (tokens: string, driveId: string) => {
    const response = await INTEGRATIONS['drive']?.readFileMetadata(tokens, driveId);

    return response?.data?.data;
};
