import { Option } from './Omnibox/types';
import TextMdIcon from '@/icons/TextMdIcon';
import LinkMdIcon from '@/icons/LinkMdIcon';
import BlobMdIcon from '@/icons/BlobMdIcon';
import DraftIcon from '@/icons/DraftIcon';
// import SpreadsheetMdIcon from '@/icons/SpreadsheetMdIcon';
import CodeMdIcon from '@/icons/CodeMdIcon';
// import GoogleDocsIcon from '@/icons/GoogleDocsIcon';

export const commands: Option[] = [
    {
        label: 'New Text',
        target: 'RichText',
        type: 'Command',
        icon: <TextMdIcon />,
    },
    {
        label: 'New Web Page',
        target: 'Webwindow',
        type: 'Command',
        icon: <LinkMdIcon />,
    },
    {
        label: 'New Object',
        target: 'Blob',
        type: 'Command',
        icon: <BlobMdIcon />,
    },
    {
        label: 'New Upload',
        target: 'DigitalDocument',
        type: 'Command',
        icon: <DraftIcon />,
    },
    // {
    //     label: 'New Spreadsheet',
    //     target: 'Spreadsheet',
    //     type: 'Command',
    //     icon: <SpreadsheetMdIcon />,
    // },
    {
        label: 'New Code',
        target: 'Code',
        type: 'Command',
        icon: <CodeMdIcon />,
    },
    // {
    //     label: 'New Google Doc',
    //     target: 'DigitalDocument',
    //     type: 'Command',
    //     icon: <GoogleDocsIcon />,
    // },
];
