export default function CaretDownSmIcon(): JSX.Element {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.14645 3.77145C1.34171 3.57618 1.65829 3.57618 1.85355 3.77145L6 7.91789L10.1464 3.77145C10.3417 3.57618 10.6583 3.57618 10.8536 3.77145C11.0488 3.96671 11.0488 4.28329 10.8536 4.47855L6 9.33211L1.14645 4.47855C0.951184 4.28329 0.951184 3.96671 1.14645 3.77145Z"
                fill="#333333"
            />
        </svg>
    );
}
