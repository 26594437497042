import styles from '@/components/baseOnboarding/Import/styles.module.scss';
import Image from 'next/image';
import React from 'react';

const AppEntityIcon = ({ app }) => {
    return (
        <span className={styles.itemIcon}>
            <div className={styles.appIcon}>
                <Image alt="" src={`/appIcons/${app.icon}`} layout="fill" objectFit="contain" objectPosition="center" />
                <span className={`${styles.activityMarker} ${app.available ? '' : styles.activityMarkerDisabled}`} />
            </div>
        </span>
    );
};

export default AppEntityIcon;
