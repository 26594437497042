import Button from '../../DesignSystem/Button';
import Text from '../../DesignSystem/Text';
import BedrockIcon from '@/icons/BedrockIcon';
import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { hideGlobalModal } from '@/redux/ui/actions';
import useUnauthorizedRedirect from '@/hooks/useUnauthorizedRedirect';

const SignInMessageModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const { redirectToLogin } = useUnauthorizedRedirect();
    const handleClick = () => {
        redirectToLogin().then(() => dispatch(hideGlobalModal('SignInMessageModal')));
    };
    return (
        <div className={styles.container}>
            <div className={styles.headerMsg}>
                <BedrockIcon />
                <Text weight="bold" className={styles.noMargin}>
                    Welcome to the dataspace!
                </Text>
            </div>
            <Text>Sign in to your account and start collaborating</Text>
            <Button variant="primary" size="s" className={styles.btn} onClick={handleClick}>
                Sign in
            </Button>
        </div>
    );
};

export default SignInMessageModal;
