export default function AccountIcon(): JSX.Element {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.49731 4.9873C4.49731 3.33045 5.84046 1.9873 7.49731 1.9873C9.15417 1.9873 10.4973 3.33045 10.4973 4.9873C10.4973 6.64416 9.15417 7.9873 7.49731 7.9873C5.84046 7.9873 4.49731 6.64416 4.49731 4.9873ZM10.2485 8.54865C11.3123 7.7256 11.9973 6.43645 11.9973 4.9873C11.9973 2.50202 9.9826 0.487305 7.49731 0.487305C5.01203 0.487305 2.99731 2.50202 2.99731 4.9873C2.99731 6.43645 3.68231 7.7256 4.74617 8.54864C2.24784 9.61753 0.497314 12.098 0.497314 14.9873C0.497314 15.4015 0.833101 15.7373 1.24731 15.7373C1.66153 15.7373 1.99731 15.4015 1.99731 14.9873C1.99731 11.9497 4.45975 9.4873 7.49731 9.4873C10.5349 9.4873 12.9973 11.9497 12.9973 14.9873C12.9973 15.4015 13.3331 15.7373 13.7473 15.7373C14.1615 15.7373 14.4973 15.4015 14.4973 14.9873C14.4973 12.098 12.7468 9.61753 10.2485 8.54865Z"
                fill="black"
                fillOpacity="0.4"
            />
        </svg>
    );
}
