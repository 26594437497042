export default function HighlightMdIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M11.25 1.25L5.58058 6.91942L9.33709 10.6759L13.7565 6.2565"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M5.13867 7.36133L3.37091 10.8969L5.35964 12.8856L8.89518 11.1178"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
            <path
                d="M4.40142 13.8441L5.35983 12.8857L3.37109 10.897L1.13106 13.137C0.816073 13.452 1.03916 13.9906 1.48461 13.9906L4.04787 13.9906C4.18048 13.9906 4.30766 13.9379 4.40142 13.8441Z"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
