export default function OpenInTabIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.38 7v6c0 .2.16.38.37.38h11.5c.2 0 .38-.17.38-.38V7h1.24v6c0 .9-.72 1.63-1.62 1.63H1.75C.85 14.63.12 13.9.12 13V7h1.25ZM1.25 2.5V5h1.5V2.5h-1.5ZM1 1.25a1 1 0 0 0-1 1v4h4v-4a1 1 0 0 0-1-1H1ZM5.85 2.5V5h1.5V2.5h-1.5ZM5.6 1.25a1 1 0 0 0-1 1v4h4v-4a1 1 0 0 0-1-1h-2Z"
                fill="#4D4D4D"
            />
            <path
                d="M10.56 2c0-.35.28-.63.63-.63h3.63v3.64a.62.62 0 1 1-1.25 0v-1.5L7.64 9.44a.62.62 0 1 1-.88-.88l5.93-5.94h-1.5a.62.62 0 0 1-.63-.62Z"
                fill="#4D4D4D"
            />
        </svg>
    );
}
