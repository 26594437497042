export default function CheckboxOffMdIcon(): JSX.Element {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.875 8.5C0.875 4.28883 4.28883 0.875 8.5 0.875C12.7112 0.875 16.125 4.28883 16.125 8.5C16.125 12.7112 12.7112 16.125 8.5 16.125C4.28883 16.125 0.875 12.7112 0.875 8.5ZM8.5 2.375C5.11726 2.375 2.375 5.11726 2.375 8.5C2.375 11.8827 5.11726 14.625 8.5 14.625C11.8827 14.625 14.625 11.8827 14.625 8.5C14.625 5.11726 11.8827 2.375 8.5 2.375Z"
                fill="#333333"
            />
        </svg>
    );
}
