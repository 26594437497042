import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import { useMobileQuery } from '@/hooks/useMediaQuery';

const Tooltip: React.FunctionComponent<TippyProps> = (props) => {
    const isMobile = useMobileQuery();

    if (isMobile) {
        return props?.children;
    }

    return <Tippy {...props} />;
};

export default Tooltip;
