import { withApollo } from 'next-apollo';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ApolloClient, HttpLink, split, InMemoryCache } from '@apollo/client';
import getConfig from 'next/config';
import fetch from 'cross-fetch';

const { publicRuntimeConfig } = getConfig();

export const BASE_DOMAIN = publicRuntimeConfig.hostname;
export const BASE_ORIGIN = `http${BASE_DOMAIN === 'localhost' ? '' : 's'}://${BASE_DOMAIN}`;
export const BASE_WS_ORIGIN = `ws${BASE_DOMAIN === 'localhost' ? '' : 's'}://${BASE_DOMAIN}`;

export const BASE_PATH = publicRuntimeConfig.fabricContainerPath;
export const NODE_ENV = publicRuntimeConfig.nodeEnv;

const httpLink = new HttpLink({
    uri: `${BASE_ORIGIN}/v1/graphql`,
    credentials: 'same-origin',
    fetch: fetch,
});

const wsLink = process.browser
    ? new WebSocketLink({
          uri: `${BASE_WS_ORIGIN}/v1/graphql`,
          options: {
              reconnect: true,
          },
      })
    : null;

export const cache = new InMemoryCache({
    possibleTypes: {
        Entity: ['entityByLatest', 'entityById', 'Entities'],
    },
    typePolicies: {
        fastforwards: {
            keyFields: ['entity_id'],
        },
    },
});

const link = process.browser
    ? split(
          // split based on operation type
          ({ query }) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
          },
          wsLink,
          httpLink,
      )
    : httpLink;

const config = {
    link: link,
    cache: cache,
    onError: function (e): void {
        console.log(e);
    },
};

export const client = new ApolloClient(config);

export default withApollo(client);
