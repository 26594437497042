import React, { useRef } from 'react';
import ControlMenu from '../ControlMenu';
import styles from './styles.module.scss';
import QuickCreateButton from '../../../modals/contextComponents/quickCreateButton';
import 'tippy.js/dist/tippy.css';
import ExternalLinkMdIcon from '@/icons/ExternalLinkMdIcon';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationItems } from 'redux/integrations/actions';
import Text from 'components/DesignSystem/Text';
import { getIntegrationAppLastFetch } from 'redux/integrations/selectors';
import TimeAgo from 'react-timeago';
import { getShortcutLabels } from '@/utils/helpers';
import { isDraftSelector } from '@/redux/entities/selectors';
import { openLocalFile } from 'integrations/adapters/LocalFilesAdapter/helpers';
import { getIsUserAnonymous } from '@/redux/user/selectors';
import Tooltip from 'components/Tooltip';

interface Props {
    eid: string;
    entity: Entity;
    parentId: string;
    childIndex?: number;
    name?: string;
    toggleListOpen?: (id: string, open: boolean) => void;
    infoMode?: string;
    isIntegrationItemInDataspace?: boolean;
    isLocalFile?: boolean;
    isLocalFileViewedFromBrowser?: boolean;
    canShow?: boolean;
    isAppsFolder?: boolean;
}

const ControlButtons = ({
    eid,
    entity,
    parentId,
    name,
    toggleListOpen,
    childIndex,
    infoMode,
    isIntegrationItemInDataspace,
    isLocalFile,
    isLocalFileViewedFromBrowser,
    canShow = true,
    isAppsFolder,
}: Props): React.ReactElement => {
    const dispatch = useDispatch();
    const isDraft = useSelector(isDraftSelector(eid));
    const userIsAnonymous = useSelector(getIsUserAnonymous);
    const ref = useRef(null);
    const stopPropagation = (e): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleFetchIntegrationItemClick = () => dispatch(getIntegrationItems(entity.app, entity.appId));
    const lastFetch = useSelector(getIntegrationAppLastFetch(entity?.app, entity?.appId));

    const openLocalFileHandler = () => {
        openLocalFile(entity?.foreignId);
    };

    return (
        <div
            ref={ref}
            className={`${styles.controlButtonsContainer} ${canShow ? styles.canShow : styles.hidden}`}
            onClick={stopPropagation}
        >
            {isIntegrationItemInDataspace && entity?.childrenList && (
                <Tooltip
                    key={`fetch-integration-item-${eid}`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy'} id={`tooltip-el-${eid}`}>
                            {lastFetch ? (
                                <span>
                                    Synced <TimeAgo date={lastFetch} />.{' '}
                                </span>
                            ) : (
                                ''
                            )}
                            Click to sync now
                        </div>
                    }
                >
                    <div
                        onClick={handleFetchIntegrationItemClick}
                        className={`${styles.linkchip} listItemMenuBtn`}
                        style={{ height: 22, pointerEvents: isLocalFileViewedFromBrowser ? 'none' : 'auto' }}
                    >
                        <Text size="xxs" noMargin>
                            {entity.appId}
                        </Text>
                    </div>
                </Tooltip>
            )}
            {isLocalFile && (
                <Tooltip
                    key={`c-el-${eid}`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy'} id={`tooltip-el-${eid}`}>
                            Open on your computer
                        </div>
                    }
                    interactive={true}
                >
                    <a
                        rel="noreferrer"
                        title="Open on your computer"
                        onClick={openLocalFileHandler}
                        className={`${styles.linkchip} listItemMenuBtn`}
                    >
                        <ExternalLinkMdIcon />
                    </a>
                </Tooltip>
            )}
            {!isDraft &&
                !userIsAnonymous &&
                infoMode !== 'compact-flat' &&
                entity?.['@type'] !== 'LocalDirectory' &&
                entity?.['@type'] !== 'App' &&
                !isIntegrationItemInDataspace &&
                !isAppsFolder && (
                    <Tooltip
                        key={`c-qc-${eid}`}
                        placement="bottom"
                        appendTo={() => document.body}
                        delay={100}
                        content={
                            <div className={'tooltipTippy tooltipTippyCol'} id={`tooltip-${eid}`}>
                                Quickly create an entity inside
                                <span className={'tooltipSubtext'}>{getShortcutLabels().Add}</span>
                            </div>
                        }
                    >
                        <span>
                            <QuickCreateButton
                                eid={eid}
                                toggleListOpen={toggleListOpen}
                                className={'controlMenuButton'}
                            />
                        </span>
                    </Tooltip>
                )}
            {infoMode !== 'compact-flat' && (
                <Tooltip
                    key={`c-cm-${eid}`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy'} id={`tooltip-${eid}`}>
                            Delete, duplicate, and more
                        </div>
                    }
                >
                    <span>
                        <ControlMenu
                            eid={eid}
                            entity={entity}
                            parentId={parentId}
                            name={name}
                            childIndex={childIndex}
                        />
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default ControlButtons;
