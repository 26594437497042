export default function ExpandMdIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.34 1.13a.75.75 0 1 0 0 1.5h2.97L8.22 5.72a.75.75 0 0 0 1.06 1.06l3.1-3.1v2.98a.75.75 0 0 0 1.5 0V1.13H8.34ZM6.78 9.28a.75.75 0 0 0-1.06-1.06l-3.1 3.1V8.33a.75.75 0 0 0-1.5 0v5.53h5.55a.75.75 0 1 0 0-1.5H3.68l3.09-3.09Z"
                fill="#333"
            />
        </svg>
    );
}
