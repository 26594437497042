import {
    SET_UI,
    CLOSE_MODAL,
    SET_SELECTED_ENTITIES,
    CLEAR_SELECTED_ENTITIES,
    SET_BROWSED,
    CLEAR_BROWSED,
    SET_COPIED_ENTITIES,
    SET_FINDER,
    CLOSE_FINDER,
    INITIAL_LOAD_SUCCESS,
    SET_DRAGGING_HLIST_ITEM,
    TOGGLE_SIDEBAR,
    CREATE_DATASPACE_SUCCESS,
    SET_DATASPACE,
    TOGGLE_EXPANDED_HLIST_ITEMS,
    TOGGLE_SHOW_HLIST,
    SET_EXPANDED_HLIST_ITEMS,
    SHOW_GLOBAL_MODAL,
    HIDE_GLOBAL_MODAL,
    SET_REROUTING,
    SET_RECENT_QUICK_NOTES,
    ADD_RECENT_QUICK_NOTE,
    SET_PANEL_OPEN,
    ADD_TOAST,
    REMOVE_TOAST,
    CLEAR_ALL_TOASTS,
    SHOW_FILES_UPLOAD_TOAST,
    HIDE_FILES_UPLOAD_TOAST,
    SET_FILE_UPLOAD_SUCCESS,
    SET_FILE_UPLOAD_FAIL,
    SET_IS_TRASH_PAGE,
    LOG_QUICK_CREATE_MENU_ITEM,
    TRIGGER_SCROLL_TO_BROWSED,
    SHOW_MENU,
    HIDE_MENU,
    SET_RENAMING_INSTANCE,
    CLEAR_RENAMING_INSTANCE,
    ADD_ACTIVE_ENTITY_CONTAINER,
    UNPROTECT_SELECTION,
    SET_FINDER_TABS,
    REMOVE_CHILD_EID_SUCCESS,
    BULK_REMOVE_CHILD_EIDS_SUCCESS,
    SET_UPLOADING_ENTITIES,
    CLEAR_UPLOADING_ENTITIES,
    SET_UPLOADING_ENTITY_PROGRESS,
    CHANGE_HLIST_ROOT,
    SET_IS_ALL_NOTES_VIEW,
    TOGGLE_CHAT_PANEL,
    ADD_AUDIBLE_CONTAINER,
    REMOVE_AUDIBLE_CONTAINER,
    REMOVE_ACTIVE_ENTITY_CONTAINER,
} from '../actionTypes';
import { DRAFTS_DATASPACE_ID, DRAFTS_FINDER_ID, SHARED_DATASPACE_ID, SHARED_FINDER_ID } from '../constants';

import { UIState, Finder } from '../types';
import { cloneDeep, compact, find, isEmpty, map, merge, uniq, filter } from 'lodash';
import { GLOBAL_MODAL_DEFAULT_PARAMS } from '../../components/modals/GlobalModal';
import { deleteTabs, insertTab } from '@/components/entities/subcomponents/Tabs/TabsBar';

const initialState = {
    copiedEntities: [],
    editMode: 'none',
    folderView: false,
    selectedEntities: [],
    quillEditorRefs: {},
    draggingHListItem: false,
    finder: {
        id: null,
        flatStyle: false,
        allFinders: [],
        tabs: {},
    },
    collapseSidebar: true,
    showHList: false,
    previewSize: 70,
    uploadEntityPopupParentId: null,
    openPanels: {},
    initialLoad: true,
    recentlyBrowsed: [],
    globalModals: {
        modals: [],
        params: GLOBAL_MODAL_DEFAULT_PARAMS,
    },
    rerouting: false,
    browsedInstanceIsBlank: false,
    recentQuickNotes: [],
    entityPanelId: null,
    toasts: [],
    filesUploadToast: {
        show: false,
        files: [],
    },
    isTrashPage: false,
    quickCreateMenuParams: null,
    quickCreateMenuLogs: [],
    expandedHListItems: {},
    scrollToBrowsed: true,
    menu: null,
    renamingInstance: null,
    activeEntityContainers: Array.from({ length: 10 }),
    protectSelection: false,
    uploadingEntities: null,
    singleUseHListRoot: null,
    isHlistCollapsed: false,
    hlistCanBeToggled: true,
    isAllNotesView: false,
    isBaseOnboarding: false,
    showChatPanel: false,
    audibleContainers: [],
} as UIState;

const RECENTLY_SEARCHED_MAX_AMOUNT = 10;

const addToRecentlyBrowsed = (state: UIState, entityIdentity: EntityIdentity) => {
    const filteredRecentlyBrowsed = state.recentlyBrowsed.filter((b) => b?.id !== entityIdentity?.id);
    return compact([entityIdentity, ...filteredRecentlyBrowsed].slice(0, RECENTLY_SEARCHED_MAX_AMOUNT));
};

const getInitialState = () => {
    if (typeof window !== 'undefined' && window?.sessionStorage?.getItem('UIState'))
        return Object.assign({}, initialState, JSON.parse(window?.sessionStorage?.getItem('UIState')));
    else return initialState;
};

const cleanUIState = (newState: UIState): string => {
    const copiedState = cloneDeep(newState);
    const cleanState = (({
        browsedInstance,
        collapseSidebar,
        editMode,
        finder,
        previewSize,
        recentlyBrowsed,
        recentQuickNotes,
        quickCreateMenuLogs,
        expandedHListItems,
        isBaseOnboarding,
    }) => ({
        browsedInstance,
        collapseSidebar,
        editMode,
        finder,
        previewSize,
        recentlyBrowsed,
        recentQuickNotes,
        quickCreateMenuLogs,
        expandedHListItems,
        isBaseOnboarding,
    }))(copiedState);

    return JSON.stringify(cleanState);
};

const UIReducer = (state = getInitialState(), action) => {
    const getNewState = () => {
        switch (action.type) {
            case SET_UI: {
                const newState = Object.assign({}, state, action.payload.UI);
                if (action.payload.UI.browsedInstance?.id !== state.browsedInstance?.id)
                    newState.recentlyBrowsed = addToRecentlyBrowsed(state, action.payload.UI.browsedInstance);
                return newState;
            }
            case CLOSE_MODAL: {
                return {
                    ...state,
                    modal: '',
                };
            }
            case SET_SELECTED_ENTITIES: {
                return {
                    ...state,
                    selectedEntities: [...action.payload.ids] as EntityIdentity[],
                };
            }
            case CLEAR_SELECTED_ENTITIES: {
                if (state.selectedEntities?.length > 0) {
                    return {
                        ...state,
                        selectedEntities: [],
                    };
                } else return state;
            }
            case SET_BROWSED: {
                if (!action.payload.id) return state;

                const oldParentId = state.browsedInstance?.parentId;
                let parentId = '';
                if (typeof action.payload.parentId === 'boolean' && action.payload.parentId && oldParentId)
                    parentId = oldParentId;
                else parentId = action.payload.parentId;
                const newSelectedEntities = action.payload.setAsSelectedEntities
                    ? [{ id: action.payload.id, parentId: parentId }]
                    : state.selectedEntities;

                const newTabsState = action.payload.tabFinderId
                    ? insertTab(
                          state.finder.tabs?.[action.payload.tabFinderId],
                          { id: action.payload.id, parentId: parentId },
                          state.browsedInstance?.id,
                          true,
                      )
                    : state.finder.tabs;

                return {
                    ...state,
                    browsedInstance: { id: action.payload.id, parentId: parentId } as EntityIdentity,
                    selectedEntities: newSelectedEntities,
                    finder: merge({}, state.finder, { tabs: { [state.finder.id]: newTabsState } }),
                    recentlyBrowsed: addToRecentlyBrowsed(state, { id: action.payload.id, parentId: parentId }),
                };
            }
            case CLEAR_BROWSED: {
                if (!isEmpty(state.browsedInstance)) {
                    return {
                        ...state,
                        browsedInstance: {},
                    };
                } else return state;
            }
            case SET_COPIED_ENTITIES: {
                return {
                    ...state,
                    copiedEntities: action.payload.copiedEntities as EntityIdentity[],
                };
            }
            case SET_FINDER: {
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, action.payload.finder) as Finder,
                };
            }
            case CLOSE_FINDER: {
                return {
                    ...state,
                    finder: initialState.finder,
                };
            }
            case INITIAL_LOAD_SUCCESS: {
                const tabs = {};
                const allFinders = map(action.payload.finders, ({ id, dsid }) => {
                    tabs[id] = { tabsArray: [], tabsHistory: [] };
                    return { id, dsid };
                });
                allFinders.push({ id: SHARED_FINDER_ID, dsid: SHARED_DATASPACE_ID });
                allFinders.push({ id: DRAFTS_FINDER_ID, dsid: DRAFTS_DATASPACE_ID });
                const finderToSet = find(allFinders, ['dsid', action.payload.dsid])?.id;
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, {
                        id: finderToSet,
                        allFinders,
                    }),
                };
            }
            case SET_DATASPACE: {
                const finderToSet = find(state.finder.allFinders, ['dsid', action.payload.dsid])?.id;
                const tabsHistory = state.finder.tabs?.[finderToSet]?.tabsHistory;
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, {
                        id: finderToSet,
                    }),
                    browsedInstance: action.payload.isNotes
                        ? state.browsedInstance
                        : { id: tabsHistory?.[tabsHistory?.length - 1] } || {},
                    selectedEntities: [],
                };
            }
            case SET_DRAGGING_HLIST_ITEM: {
                return {
                    ...state,
                    draggingHListItem: action.payload.draggingHListItem,
                };
            }
            case TOGGLE_SIDEBAR: {
                return {
                    ...state,
                    collapseSidebar: !state.collapseSidebar,
                };
            }
            case TOGGLE_SHOW_HLIST: {
                return {
                    ...state,
                    showHList: !state.showHList,
                };
            }
            case CREATE_DATASPACE_SUCCESS: {
                const newFinderId = action.payload.results.insertFinder.insertedEntity.id;
                const newDataspaceId = action.payload.results.insertDataspace.insertedEntity.id;
                const newAllFinders = [...state.finder.allFinders];
                const filteredUserDataspaces = newAllFinders.filter(
                    (finder) => finder.dsid !== DRAFTS_DATASPACE_ID && finder.dsid !== SHARED_DATASPACE_ID,
                );
                filteredUserDataspaces.push({ id: newFinderId, dsid: newDataspaceId });
                const updatedAllFinders = filteredUserDataspaces.concat([
                    { id: SHARED_FINDER_ID, dsid: SHARED_DATASPACE_ID },
                    { id: DRAFTS_FINDER_ID, dsid: DRAFTS_DATASPACE_ID },
                ]);
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, {
                        id: newFinderId,
                        dsid: newDataspaceId,
                        allFinders: updatedAllFinders,
                        tabs: { ...state.tabs, [newFinderId]: { tabsArray: [], tabsHistory: [] } },
                    }),
                };
            }
            case TOGGLE_EXPANDED_HLIST_ITEMS: {
                const { targetEids, open } = action.payload;
                const newExpandedEids = { ...state?.expandedHListItems };
                for (const eid of targetEids) {
                    if (open) {
                        newExpandedEids[eid] = open;
                    } else {
                        delete newExpandedEids[eid];
                    }
                }
                return {
                    ...state,
                    expandedHListItems: newExpandedEids,
                };
            }
            case SET_EXPANDED_HLIST_ITEMS: {
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, {
                        expandedHListItems: action.payload.expandedHListItems,
                    }),
                };
            }
            case SHOW_GLOBAL_MODAL: {
                const modalAlreadyShown =
                    state?.globalModals?.modals?.length &&
                    state.globalModals.modals.find((modal) => modal.type === action.payload.type);
                if (modalAlreadyShown) return state;
                const globalModals = {
                    modals: [
                        ...(state?.globalModals?.modals || []),
                        {
                            type: action.payload.type,
                            props: action.payload.props,
                        },
                    ],
                    params: action.payload.globalModalParams || GLOBAL_MODAL_DEFAULT_PARAMS,
                };

                return {
                    ...state,
                    globalModals,
                };
            }
            case HIDE_GLOBAL_MODAL: {
                let modals;
                if (action.payload.type) {
                    modals = state.globalModals.modals.filter((modal) => modal.type !== action.payload.type);
                } else {
                    modals = null;
                }

                return {
                    ...state,
                    globalModals: { ...state.globalModals, modals },
                };
            }
            case LOG_QUICK_CREATE_MENU_ITEM: {
                const { value } = action.payload;
                const newLogs = [value, ...state.quickCreateMenuLogs];
                return {
                    ...state,
                    quickCreateMenuLogs: uniq(newLogs).slice(0, 5),
                };
            }
            case SET_REROUTING: {
                return {
                    ...state,
                    rerouting: action.payload.rerouting,
                };
            }
            case SET_RECENT_QUICK_NOTES: {
                return {
                    ...state,
                    recentQuickNotes: action.payload.recentQuickNotes,
                };
            }
            case ADD_RECENT_QUICK_NOTE: {
                if (state?.recentQuickNotes?.length > 4) state?.recentQuickNotes?.pop();
                return {
                    ...state,
                    recentQuickNotes: [action.payload.eid, ...state?.recentQuickNotes],
                };
            }
            case SET_PANEL_OPEN: {
                const { panelName, open } = action.payload;
                return {
                    ...state,
                    openPanels: Object.assign({}, state.openPanels, { [panelName]: open }),
                    entityPanelId: panelName === 'entity-panel' && !open ? null : state.entityPanelId,
                };
            }
            case ADD_TOAST: {
                const newToasts =
                    action.payload?.toast?.text?.length && action.payload?.toast?.msgType
                        ? [...state.toasts, { ...action.payload.toast }]
                        : state.toasts;
                return {
                    ...state,
                    toasts: newToasts,
                };
            }
            case REMOVE_TOAST: {
                const { id } = action.payload;
                let newToasts = [];
                !id
                    ? (newToasts = state.toasts?.slice(1))
                    : (newToasts = state.toasts?.filter((toast) => toast.id !== id));
                return {
                    ...state,
                    toasts: newToasts,
                };
            }
            case CLEAR_ALL_TOASTS: {
                return [];
            }
            case SHOW_FILES_UPLOAD_TOAST: {
                return {
                    ...state,
                    filesUploadToast: {
                        show: true,
                        files: [
                            ...state.filesUploadToast.files,
                            ...action.payload.map((name) => ({
                                name,
                                isUploading: true,
                                isUploadedSuccessfully: false,
                                isUploadFailed: false,
                            })),
                        ],
                    },
                };
            }
            case HIDE_FILES_UPLOAD_TOAST: {
                return {
                    ...state,
                    filesUploadToast: {
                        show: false,
                        files: [],
                    },
                };
            }
            case SET_FILE_UPLOAD_SUCCESS: {
                return {
                    ...state,
                    filesUploadToast: {
                        show: true,
                        files: state.filesUploadToast.files.map((file) => {
                            if (file.name === action.payload) {
                                return {
                                    ...file,
                                    isUploading: false,
                                    isUploadedSuccessfully: true,
                                    isUploadFailed: false,
                                };
                            }
                            return file;
                        }),
                    },
                };
            }
            case SET_FILE_UPLOAD_FAIL: {
                return {
                    ...state,
                    filesUploadToast: {
                        show: true,
                        files: state.filesUploadToast.files.map((file) => {
                            if (file.name === action.payload) {
                                return {
                                    ...file,
                                    isUploading: false,
                                    isUploadedSuccessfully: false,
                                    isUploadFailed: true,
                                };
                            }
                            return file;
                        }),
                    },
                };
            }
            case SET_IS_TRASH_PAGE: {
                return {
                    ...state,
                    isTrashPage: action.payload.isTrashPage,
                };
            }
            case TRIGGER_SCROLL_TO_BROWSED: {
                return {
                    ...state,
                    scrollToBrowsed: action.payload.value,
                };
            }
            case SHOW_MENU: {
                return {
                    ...state,
                    menu: action.payload,
                    ...(state.selectedEntities?.length > 0 && { protectSelection: true }),
                };
            }
            case HIDE_MENU: {
                return {
                    ...state,
                    menu: null,
                };
            }
            case SET_RENAMING_INSTANCE: {
                return {
                    ...state,
                    renamingInstance: { id: action.payload.id, parentId: action.payload.parentId },
                };
            }
            case CLEAR_RENAMING_INSTANCE: {
                return {
                    ...state,
                    renamingInstance: null,
                };
            }
            case ADD_ACTIVE_ENTITY_CONTAINER: {
                const { containerProps } = action.payload;
                const { eid, app, appId } = containerProps;
                const newActiveEntityContainers = [...state.activeEntityContainers];

                const currentIndex = newActiveEntityContainers.findIndex((container) => {
                    const props = container?.props;
                    if (!props) return false;
                    return app && appId
                        ? props.eid === eid && props.app === app && props.appId === appId
                        : props.eid === eid;
                });

                // All of this is because mapping over a normal array occasionally causes a re-render.
                const incrementOtherIndexes = (index) => {
                    newActiveEntityContainers.forEach((container, i) => {
                        if (i !== index && container?.index !== undefined) {
                            container.index++;
                        }
                    });
                };
                if (currentIndex > -1) {
                    if (newActiveEntityContainers[currentIndex].index !== 0) {
                        incrementOtherIndexes(currentIndex);
                        newActiveEntityContainers[currentIndex].index = 0;
                    }
                } else {
                    const firstEmptyIndex = newActiveEntityContainers.indexOf(undefined);
                    if (firstEmptyIndex > -1) {
                        newActiveEntityContainers[firstEmptyIndex] = { props: containerProps, index: 0 };
                        incrementOtherIndexes(firstEmptyIndex);
                    } else {
                        const largestIndexContainer = newActiveEntityContainers.reduce((acc, container) => {
                            return acc?.index > container?.index ? acc : container;
                        });
                        const largestIndex = newActiveEntityContainers.indexOf(largestIndexContainer);
                        newActiveEntityContainers[largestIndex] = { props: containerProps, index: 0 };
                        incrementOtherIndexes(largestIndex);
                    }
                }
                return {
                    ...state,
                    activeEntityContainers: newActiveEntityContainers,
                    recentlyBrowsed: addToRecentlyBrowsed(state, { id: eid, app: app, appId: appId }),
                };
            }
            case REMOVE_ACTIVE_ENTITY_CONTAINER: {
                return {
                    ...state,
                    activeEntityContainers: map(state.activeEntityContainers, (container) =>
                        container?.props?.eid !== action.payload.eid ? container : undefined,
                    ),
                };
            }
            case UNPROTECT_SELECTION: {
                return {
                    ...state,
                    protectSelection: false,
                };
            }
            case SET_FINDER_TABS: {
                const newTabs = Object.assign({}, state.finder.tabs, {
                    [action.payload.finderId]: action.payload.tabsState,
                });
                return {
                    ...state,
                    finder: Object.assign({}, state.finder, {
                        tabs: newTabs,
                    }),
                };
            }
            case REMOVE_CHILD_EID_SUCCESS:
            case BULK_REMOVE_CHILD_EIDS_SUCCESS: {
                // remove entities from tabs
                const { entities, tabFinderId } = action.payload;
                const deletedEntities = entities.filter((e) => e.deleted);
                if (deletedEntities?.length > 0) {
                    const { allTabsState, handleBrowsedMap } = deleteTabs(
                        state.finder.tabs,
                        deletedEntities.map((e) => e.id),
                        state.browsedInstance?.id,
                    );

                    let newBrowsedInstance = state.browsedInstance;
                    let newSelectedEntities = state.selectedEntities;

                    if (tabFinderId) {
                        const { tabsArray, tabsHistory } = allTabsState[tabFinderId];
                        const handleBrowsed = handleBrowsedMap[tabFinderId];
                        if (handleBrowsed === 'PREVIOUS_TAB') {
                            newBrowsedInstance = { id: tabsHistory[tabsHistory.length - 1] };
                            newSelectedEntities = [{ id: tabsHistory[tabsHistory.length - 1] }];
                        } else if (handleBrowsed === 'CLEAR' || tabsArray.length === 0) {
                            newBrowsedInstance = {};
                            newSelectedEntities = [];
                        }
                    }
                    return {
                        ...state,
                        finder: Object.assign({}, state.finder, {
                            tabs: allTabsState,
                        }),
                        browsedInstance: newBrowsedInstance,
                        selectedEntities: newSelectedEntities,
                    };
                } else {
                    return state;
                }
            }
            case SET_UPLOADING_ENTITIES: {
                const uploadingEntities = action.payload.entityIds.reduce((acc, eid) => {
                    acc[eid] = 0;
                    return acc;
                }, {});
                return {
                    ...state,
                    uploadingEntities,
                };
            }
            case CLEAR_UPLOADING_ENTITIES: {
                return {
                    ...state,
                    uploadingEntities: null,
                };
            }
            case SET_UPLOADING_ENTITY_PROGRESS: {
                return {
                    ...state,
                    uploadingEntities: {
                        ...state.uploadingEntities,
                        [action.payload.eid]: action.payload.progress,
                    },
                };
            }
            case CHANGE_HLIST_ROOT: {
                return {
                    ...state,
                    singleUseHListRoot: action.payload.instance as EntityIdentity,
                };
            }
            case SET_IS_ALL_NOTES_VIEW: {
                return {
                    ...state,
                    isAllNotesView: action.payload.value,
                };
            }
            case TOGGLE_CHAT_PANEL: {
                return {
                    ...state,
                    showChatPanel: !state.showChatPanel,
                };
            }
            case ADD_AUDIBLE_CONTAINER: {
                return {
                    ...state,
                    audibleContainers: uniq([...state.audibleContainers, action.payload.eid]),
                };
            }
            case REMOVE_AUDIBLE_CONTAINER: {
                return {
                    ...state,
                    audibleContainers: filter(state.audibleContainers, (id) => id !== action.payload.eid),
                };
            }
            default:
                return state;
        }
    };

    const newState = getNewState();
    if (typeof window !== 'undefined') window.sessionStorage.setItem('UIState', cleanUIState(newState));

    return newState;
};

export default UIReducer;
