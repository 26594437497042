import { postRequest } from '../httpHelper';
import { APIRequest } from '../types';
import { BASE_ORIGIN } from '@/config';

export const API = {
    CREATE_ENTITY: `
        mutation createEntity($id: uuid!, $entity: jsonb!, $owner: uuid, $dataspaceId: uuid) {
            insert_Entity_special(object: {id: $id, entity: $entity, owned_by: $owner, dsid: $dataspaceId }) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
        }
    `,
    CREATE_ENTITY_AND_CHILD: `
        mutation createParentAndChildEntities($id: uuid!, $entity: jsonb!, $owner: uuid, $childId: uuid!, $childEntity: jsonb!, $dataspaceId: uuid!) {
            insertEntity: insert_Entity_special(object: {id: $id, entity: $entity, owned_by: $owner, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
            insertChild: insert_Entity_special(object: {id: $childId, entity: $childEntity, owned_by: $owner, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
        }
    `,
    CREATE_ENTITY_AND_UPDATE_PARENT: `
        mutation createEntityAndUpdateParent($id: uuid!, $entity: jsonb!, $owner: uuid, $parentId: uuid!, $updatedChildrenList: [uuid!], $dataspaceId: uuid!) {
            insert_Entity_special(object: {id: $id, entity: $entity, owned_by: $owner, dsid: $dataspaceId}, parentId: $parentId) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
            update_Entities_by_pk(pk_columns: {id: $parentId}, _append: {entity: {childrenList: $updatedChildrenList}}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
                public_entity
            }
        }
    `,
    CREATE_ENTITY_AND_CHILD_AND_UPDATE_PARENT: `
        mutation createEntityAndChildAndUpdateParent($id: uuid!, $entity: jsonb!, $owner: uuid, $childId: uuid!, $childEntity: jsonb!, $parentId: uuid!, $parentEntity: jsonb!, $dataspaceId: uuid!) {
            insertEntity: insert_Entity_special(object: {id: $id, entity: $entity, owned_by: $owner, dsid: $dataspaceId}, parentId: $parentId) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
            insertChild: insert_Entity_special(object: {id: $childId, entity: $childEntity, owned_by: $owner, dsid: $dataspaceId}, parentId: $parentId) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
            update_Entities_by_pk(pk_columns: {id: $parentId}, _set: {entity: $parentEntity}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
                public_entity
            }
        }
    `,
    INSERT_ENTITY: `
        mutation insertEntity($object: EntityInput!, $parentId: uuid!) {
            insert_Entity_special(object: $object, parentId: $parentId) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
        }
    `,
    GET_ENTITY: `
        query getEntity($id: uuid!) {
            Entities(where: { id: { _eq: $id } }) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
                deleted
                public_entity
            }
        }
    `,
    GET_PUBLIC_ENTITY: `
        query getEntity($id: uuid!) {
            Entities(where: {id: { _eq: $id }}) {
                id
                entity
                owned_by
                created_at
                updated_at
                public_entity
            }
        }
    `,
    GET_ENTITIES: `
        query getEntities($ids: [uuid!]) {
            Entities(where: { id: { _in: $ids } }) {
                id
                entity
                dsid
                deleted
                owned_by
                created_at
                updated_at
                public_entity
            }
        }
    `,
    GET_DELETED_ENTITIES: `
        query getDeletedEntities($dsid: uuid!) {
          Entities(where: {deleted: {_eq: true} _and: {dsid:{_eq: $dsid}}}) {
            id
            entity
            dsid
            owned_by
            updated_at
          }
        }
    `,
    GET_RECENT_ENTITIES: `
        query getRecentCondensedEntities($dataspaceId: uuid!, $limit: Int) {
            condensedEntities(
                where: {
                    dsid: {_eq: $dataspaceId},
                    entity_type: {_nin: ["Finder", "Dataspace", "Person"]},
                    deleted: {_eq: false}
                },
                limit: $limit,
                order_by: {updated_at:desc}
            ){
                id
                dsid
                name
                entity_type
                children_list
                additional_types
                emoji
                url
                created_at
                updated_at
                foreign_id
                email
                thumbnail_url
                icon_link
                public_entity
            }
        }
    `,
    GET_USER: `
        query Me {
            me {
                id
                email
                name
                dsid
                entity
                owned_by
                created_at
                updated_at
            }
        }
    `,
    INITIAL_LOAD: `
    query initialLoad($memberships: [uuid!], $owner: uuid!) {
        dataspaces {
            id
            entity
            owned_by
            created_at
            updated_at
        }
        finders(order_by: { created_at: asc }) {
            id
            entity
            dsid
            owned_by
            created_at
            updated_at
        }
        condensedEntities(
            where: {
                dsid: {_in: $memberships}
            }) {
                id
                dsid
                name
                owned_by
                entity_type
                children_list
                additional_types
                emoji
                url
                created_at
                updated_at
                foreign_id
                email
                deleted
                thumbnail_url
                icon_link
                app
                app_id
                public_entity
                key
                mime_type
        }
        sharedCondensedEntities: condensedEntities(
            where: {
                _and: {
                    dsid: {_nin: $memberships},
                    deleted: { _neq: true },
                    owned_by: { _neq: $owner },
                    entity_type: {_nin: ["Finder", "Dataspace", "Person"]},
                    public_entity: {_neq: true}
                }
            }) {
                id
                dsid
                name
                owned_by
                entity_type
                children_list
                additional_types
                emoji
                url
                created_at
                updated_at
                foreign_id
                email
                deleted
                thumbnail_url
                icon_link
                app
                app_id
                public_entity
        }
        draftCondensedEntities: condensedEntities(
            where: {
                _and: {
                    dsid: { _is_null: true },
                    owned_by: { _eq: $owner },
                    deleted: { _neq: true },
                    entity_type: {_nin: ["Finder", "Dataspace", "Person"]}
                }
            }) {
                id
                dsid
                name
                owned_by
                entity_type
                children_list
                additional_types
                emoji
                url
                created_at
                updated_at
                foreign_id
                email
                deleted
                thumbnail_url
                icon_link
                app
                app_id
                public_entity
        }
        additionalTypes(where: {dsid: { _in: $memberships }}) {
            id
            entity
            dsid
            name
            owned_by
            created_at
            updated_at
        }
    }
`,
    GET_ALL_COLLABORATORS: `
        query GetAllCollaborators($memberships: [String!]) {
            people(where: {memberships: { _has_keys_any: $memberships}}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
        }
    `,
    GET_ADDITIONAL_TYPES: `
        query GetAdditionalTypes($ids: [uuid!], $dataspaceId: uuid) {
            EntityIndices(where: {_and: { entity_type: { _eq: "AdditionalType" }, id: { _in: $ids }, dsid: { _eq: $dataspaceId } } } ) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
        }
    `,
    GET_ENTITY_VERSIONS: `
        query getEntityVersions($ids: [uuid!], $dataspaceId: uuid) {
            EntityVersions(where: {_and: { entity_id: { _in: $ids }, dsid: { _eq: $dataspaceId } } }) {
                id
                entity_id
                obsoleted_by
                created_at
            }
        }
    `,
    UPDATE_ENTITY: `
        mutation updateEntity($entity: jsonb!, $id: uuid!) {
            update_Entities(where: { id: { _eq: $id } }, _set: { entity: $entity }) {
                    returning {
                        id
                        entity
                        dsid
                        owned_by
                        created_at
                        updated_at
                        deleted
                        public_entity
                }
            }
        }
    `,
    UPDATE_USER: `
        mutation updateUser($user: jsonb!, $id: uuid!) {
            update_people(where: { id: { _eq: $id } }, _set: { entity: $user }) {
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    COMPLETE_USER_SIGNUP: `
        mutation completeUserSignup($dataspaceId: uuid!, $dataspaceEntity: jsonb!, $finderEntity: jsonb!, $userId: uuid!, $userEntity: jsonb!, $id1: uuid!, $entity1: jsonb!, $id2: uuid!, $entity2: jsonb!, $id3: uuid!, $entity3: jsonb!, $id4: uuid!, $entity4: jsonb!) {
            insertWelcomeEntity1: insert_Entity_special(object: {id: $id1, entity: $entity1, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    created_at
                    updated_at
                }
            }
            insertWelcomeEntity2: insert_Entity_special(object: {id: $id2, entity: $entity2, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    created_at
                    updated_at
                }
            }
            insertWelcomeEntity3: insert_Entity_special(object: {id: $id3, entity: $entity3, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    created_at
                    updated_at
                }
            }
            insertWelcomeEntity4: insert_Entity_special(object: {id: $id4, entity: $entity4, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    created_at
                    updated_at
                }
            }
            insertDataspace: insert_Entity_special(object: {id: $dataspaceId, entity: $dataspaceEntity, owned_by: $userId}) {
                insertedEntity {
                    id
                    entity
                    owned_by
                    created_at
                    updated_at
                }
            }
            insertFinder: insert_Entity_special(object: {entity: $finderEntity, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
            update_people(where: { id: { _eq: $userId } }, _set: { entity: $userEntity, dsid: $dataspaceId }) {
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    CREATE_USER: `
        mutation createUser($userEntity: jsonb!) {
            insertUser: insert_Entity_special(object: {entity: $userEntity}) {
                requestEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    CREATE_DATASPACE: `
        mutation createDataspace($dataspaceId: uuid!, $dataspaceEntity: jsonb!, $finderEntity: jsonb!, $userId: uuid!, $userEntity: jsonb!) {
            insertDataspace: insert_Entity_special(object: {id: $dataspaceId, entity: $dataspaceEntity, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
            insertFinder: insert_Entity_special(object: {entity: $finderEntity, owned_by: $userId, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
            update_people(where: { id: { _eq: $userId } }, _set: { entity: $userEntity }) {
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    UPDATE_ENTITIES_PUBLIC_STATE: `
        mutation setEntitiesPublicState($ids: [uuid!]!, $publicState: Boolean!) {
            update_publicEntities(where: { id: { _in: $ids } }, _set: { public_entity: $publicState }) {
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
        }
    `,
    GET_USER_INTEGRATION_ENTITIES: `
        query GetUserIntegrations ($user_id: uuid!) {
            EntityIndices(where: {_and: { entity_type: { _eq: "Integration" }, owned_by:{ _eq: $user_id} } } ) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
        }
    `,
    BULK_INSERT_ENTITIES: `
        mutation bulkUpsertEntities($entities: [EntityInput!]!, $parentId: uuid!) {
            insert_Entities_bulk(objects: $entities, parentId: $parentId) {
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    BULK_UPDATE_ENTITIES: `
        mutation bulkUpdateEntities($updates: [Entities_updates!]! = []) {
            update_Entities_many(updates: $updates) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                    deleted
                }
            }
        }
    `,
    CREATE_SCHEMA_FROM_OBJECT: `
        mutation createSchemaFromObject($newSchemaId: uuid!, $newSchemaEntity: jsonb!, $blobId: uuid!, $blobEntity: jsonb!, $dataspaceId: uuid!) {
            insert_Entity_special(object: {id: $newSchemaId, entity: $newSchemaEntity, dsid: $dataspaceId}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                }
            }
            update_Entities_by_pk(pk_columns: {id: $blobId}, _set: {entity: $blobEntity}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
                public_entity
            }
        }
    `,
    SEARCH_ENTITIES: `
        query ($search: String!, $dataspaces: _uuid!) {
            search_entities(args: { search: $search, dataspaces: $dataspaces }) {
                id
                entity
                created_at
                updated_at
            }
        }
    `,
    SOFT_DELETE_ENTITY: `
        mutation deleteEntity($id: uuid!, $parentId: uuid!, $parentEntity: jsonb! ) {
            u1: update_Entities(where: { id: { _eq: $id } }, _set: { deleted: true }) {
                    returning {
                        id
                        entity
                        dsid
                        owned_by
                        created_at
                        updated_at
                }
            }
            u2: update_Entities(where: { id: { _eq: $parentId } }, _set: { entity: $parentEntity }) {
                    returning {
                        id
                        entity
                        dsid
                        owned_by
                        created_at
                        updated_at
                }
            }
        }
    `,
    ADD_MEMBERS: `
        mutation addMembers($emails: [String!]!, $dataspaceId: uuid!, $role: String!) {
            add_Members(emails: $emails, dataspaceId: $dataspaceId, role: $role) {
                users {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
                dataspace {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    GET_ENTITY_ACL_USERS: `
        query getEntityACLUsers($id: uuid!) {
            Entities_by_pk(id: $id) {
                acl_users {
                    permissions
                    acl_user {
                        entity
                        id
                    }
                }
                strict_acl
            }
        }
    `,
    FIND_USERS: `
        query foo($search: String) {
            people(where: { _or: [{ email: { _ilike: $search } }, { name: { _ilike: $search } }] }) {
                id
                email
                name
                avatar_url
            }
        }
    `,
    SHARE_ENTITY: `
        mutation shareEntity($id: uuid!, $strictAcl: Boolean!, $userIds: [uuid!]!, $permissions: [String!]!, $sendEmail: Boolean!, $noteToUsers: String, $newUserEmails: [String!]) {
            share_Entity(id: $id, strictAcl: $strictAcl, userIds: $userIds, permissions: $permissions, sendEmail: $sendEmail, newUserEmails: $newUserEmails, noteToUsers: $noteToUsers) {
                entityId
            }
        }
    `,
    GET_FINDER_BY_DSID: `
        query getFinderByDSID($dsid: uuid!) {
            EntityIndices(where: { _and: {
                    entity_type: { _eq: "Finder" },
                    dsid: { _eq: $dsid }
                }}) {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    deleted
            }
        }
    `,
    SAVE_DRAFTS: `
        mutation saveDrafts($dsid: uuid!, $ids: [uuid!]!, $finderId: uuid!, $finderEntity: jsonb!) {
            save_drafts(dsid: $dsid, ids: $ids, finderId: $finderId, finderEntity: $finderEntity) {
                newEntities {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
        }
    `,
    SOFT_DELETE_ENTITIES: `
        mutation softDeleteEntities($ids: [uuid!]) {
            update_Entities(where: { id: { _in: $ids } }, _set: { deleted: true }) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    deleted
                }
            }
        }
    `,
    RESTORE_ENTITIES_FROM_TRASH: `
        mutation restoreSoftDeletedEntities($ids: [uuid!]) {
            update_Entities(where: { id: { _in: $ids } }, _set: { deleted: false }) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    deleted
                }
            }
        }
    `,
    HARD_DELETE_ENTITIES: `
        mutation hardRemoveEntities($ids: [uuid!]) {
            delete_Entities(where: { id: { _in: $ids } }) {
                affected_rows
                returning {
                    id
                    entity
                }
            }
        }
    `,
    BULK_GROUP_ENTITIES: `
        mutation bulkUpdateEntities($updates: [Entities_updates!]! = [], $object: EntityInput!, $parentId: uuid!) {
            insert_Entity_special(object: $object, parentId: $parentId) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                    deleted
                }
            }
            update_Entities_many(updates: $updates) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                    deleted
                }
            }
        }

    `,
    BULK_MOVE_ENTITIES: `
        mutation UpdateChildrenList($updates: [Entities_updates!]! = []) {
            update_Entities_many(updates: $updates) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                    deleted
                }
            }
        }
    `,
    BULK_REMOVE_CHILD_EIDS: `
        mutation UpdateChildrenList($updates: [Entities_updates!]! = [], $eids: [uuid!], $setDeletedTo: Boolean!) {
            update_Entities_many(updates: $updates) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    public_entity
                    deleted
                }
            }
            softDeleteEntities: update_Entities(where: { id: { _in: $eids } }, _set: { deleted: $setDeletedTo }) {
                affected_rows
                returning {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                    deleted
                }
            }
        }
    `,
    ADD_APP: `
        mutation addApp($userId: uuid, $dsid: uuid!, $updatedDataspace: jsonb!, $appEntityId: uuid!, $appEntity: jsonb!) {
            insertApp: insert_Entity_special(object: {id: $appEntityId, entity: $appEntity, owned_by: $userId, dsid: $dsid}) {
                insertedEntity {
                    id
                    entity
                    dsid
                    owned_by
                    created_at
                    updated_at
                }
            }
            updateDataspace: update_Entities_by_pk(pk_columns: {id: $dsid}, _set: {entity: $updatedDataspace}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
        }
    `,
    REMOVE_APP: `
        mutation addApp($dsid: uuid!, $updatedDataspace: jsonb!, $appEntityId: uuid!) {
            removeApp: delete_Entities_by_pk(id: $appEntityId) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
            updateDataspace: update_Entities_by_pk(pk_columns: {id: $dsid}, _set: {entity: $updatedDataspace}) {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
            }
        }
    `,
};

export default function gqlRequest(action: APIRequest) {
    const variables = action.payload?.nextQueryVariables || action.payload || {};
    const queryType = action.queryType || action.type;
    const query = API[queryType];
    const body = { query, variables };
    // console.log('| query', queryType, '| variables', body.variables, '| action', action, '| body', body);

    return postRequest(`${BASE_ORIGIN}/v1/graphql`, body);
}

export async function signupWebhook(session) {
    const { id, personEntity: newUser, user_created_at: created_at } = session;
    if (process.env.NODE_ENV !== 'development') {
        try {
            await fetch('/api/signupWebhook', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ id, newUser, created_at }),
            });
        } catch (err) {
            console.error('Error in signupWebhook: ', err);
        }
    }
}

export function feedbackWebhook(feedback, user) {
    try {
        return fetch('/api/feedbackWebhook', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ feedback, user }),
        });
    } catch (err) {
        console.error('Error in feedbackWebhook: ', err);
    }
}

export async function profileWebhook(email, entryPoint) {
    if (process.env.NODE_ENV !== 'development') {
        try {
            await fetch('/api/profileWebhook', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, entryPoint }),
            });
        } catch (err) {
            console.error('Error in profileWebhook: ', err);
        }
    }
}
