import dynamic from 'next/dynamic';
import React, { FunctionComponent, ReactElement } from 'react';
import AccountIcon from '@/icons/AccountIcon';
import PageIcon from '@/icons/PageIcon';
import TextMdIcon from '@/icons/TextMdIcon';
import CodeMdIcon from '@/icons/CodeMdIcon';
import SpreadsheetMdIcon from '@/icons/SpreadsheetMdIcon';
import SpaceIcon from '@/icons/SpaceIcon';
import AdditionalTypeIcon from '@/icons/AdditionalTypeIcon';
import BlobMdIcon from '@/icons/BlobMdIcon';
import ListIcon from '@/icons/ListIcon';
import DraftIcon from '@/icons/DraftIcon';
import HighlightMdIcon from '@/icons/HighlightMdIcon';
// import FolderIcon from '@/icons/FolderIcon';
import EntityIntegrationItem from './EntityIntegrationItem';
import ChatGPT from '@/icons/ChatGPT';
import GlobeMdIcon from '@/icons/GlobeMdIcon';
import FolderIcon from '@/icons/FolderIcons/FolderIcon';
import HomeIcon from '@/icons/HomeIcon';

const EntityPerson = dynamic(() => import('./EntityPerson'), { ssr: false });
const EntityDocument = dynamic(() => import('./EntityDocument'), { ssr: false });
const EntityEmbed = dynamic(() => import('./EntityEmbed'), { ssr: false });
const EntityListItem = dynamic(() => import('./EntityListItem'), { ssr: false });
const EntityPage = dynamic(() => import('./EntityPage'), { ssr: false });
const EntityRichText = dynamic(() => import('./EntityRichText'), { ssr: false });
const EntitySpreadsheet = dynamic(() => import('./EntitySpreadsheet'), { ssr: false });
const EntityCode = dynamic(() => import('./EntityCode'), { ssr: false });
const EntityWebwindow = dynamic(() => import('./EntityWebwindow'), { ssr: false });
const EntityWorkspace = dynamic(() => import('./EntityWorkspace'), { ssr: false });
const EntityHList = dynamic(() => import('./EntityHList'), { ssr: false });
const EntityAdditionalType = dynamic(() => import('./EntityAdditionalType'), { ssr: false });
const EntityFinder = dynamic(() => import('./EntityFinder'), { ssr: false });
const EntityBlob = dynamic(() => import('./EntityBlob'), { ssr: false });
const EntityTypedCollection = dynamic(() => import('./typedCollection/EntityTypedCollection'), { ssr: false });
const EntityHighlight = dynamic(() => import('./EntityHighlight'), { ssr: false });
// const EntityLocalDirectory = dynamic(() => import('./EntityLocalDirectory'), { ssr: false });
const EntityRawHTML = dynamic(() => import('./EntityRawHTML'), { ssr: false });
const EntityApps = dynamic(() => import('./EntityApps'), { ssr: false });

type TypeInfoSection = {
    icon: string | ReactElement;
    closedIcon?: string;
    component: FunctionComponent;
    fancyName: string;
    skeleton?: object;
};

type TypeInfo = {
    [key: string]: TypeInfoSection;
};

const typeInfo = {
    Person: {
        icon: <AccountIcon />,
        component: EntityPerson,
        fancyName: 'Person',
        skeleton: {
            '@type': 'Person',
            name: '',
            email: '',
            avatarUrl: '',
        },
    },
    Dataspace: {
        icon: '',
        component: null,
        fancyName: 'DataSpace',
        skeleton: {
            '@type': 'Dataspace',
            name: '',
            iconUrl: '',
            spaceType: 'Individual',
            members: {},
            roles: {},
            apps: [],
        },
    },
    Finder: {
        icon: '',
        component: EntityFinder,
        fancyName: 'Base',
        skeleton: {
            '@type': 'Finder',
            childrenList: [],
            contentList: [],
        },
    },
    IntegrationItem: {
        icon: '',
        component: EntityIntegrationItem,
        fancyName: 'Integration Item',
        skeleton: {
            '@type': 'IntegrationItem',
            app: '',
            appId: '',
            iconLink: '',
            name: '',
            webViewLink: '',
        },
    },
    Page: {
        icon: <PageIcon />,
        component: EntityPage,
        fancyName: 'Page',
        skeleton: {
            '@type': 'Page',
            name: '',
            childrenList: [],
            contentList: [],
        },
    },
    RichText: {
        icon: <TextMdIcon />,
        component: EntityRichText,
        fancyName: 'Text',
        skeleton: {
            '@type': 'RichText',
            name: '',
            content: '',
        },
    },
    Embed: {
        icon: <CodeMdIcon />,
        component: EntityEmbed,
        fancyName: 'Text',
        skeleton: {
            '@type': 'Embed',
            name: '',
            embedCode: '',
        },
    },
    Spreadsheet: {
        icon: <SpreadsheetMdIcon />,
        component: EntitySpreadsheet,
        fancyName: 'Spreadsheet',
        skeleton: {
            '@type': 'Spreadsheet',
            name: '',
            spreadsheet: '',
        },
    },
    Code: {
        icon: <CodeMdIcon />,
        component: EntityCode,
        fancyName: 'Code',
        skeleton: {
            '@type': 'Code',
            name: '',
            doc: '',
        },
    },
    Workspace: {
        icon: <SpaceIcon />,
        component: EntityWorkspace,
        fancyName: 'Workspace',
    },
    Webwindow: {
        icon: <GlobeMdIcon />,
        component: EntityWebwindow,
        fancyName: 'Web Link',
        skeleton: {
            '@type': 'Webwindow',
            name: '',
            url: 'https://www.google.com/',
            urlLocked: false,
        },
    },
    Apps: {
        icon: <HomeIcon />,
        component: EntityApps,
        fancyName: 'Apps',
        skeleton: {
            '@type': 'Apps',
            name: '',
            childrenList: [],
        },
    },
    HList: {
        icon: <FolderIcon />,
        component: EntityHList,
        fancyName: 'Folder',
        skeleton: {
            '@type': 'HList',
            name: '',
            childrenList: [],
        },
    },
    AdditionalType: {
        icon: <AdditionalTypeIcon />,
        component: EntityAdditionalType,
        fancyName: 'Type',
        skeleton: {
            '@type': 'AdditionalType',
            name: '',
            fields: [],
            verified: 'false',
        },
    },
    App: {
        icon: <BlobMdIcon />,
        component: EntityHList,
        fancyName: 'App',
        skeleton: {
            '@type': 'App',
            name: '',
            iconPath: '',
        },
    },
    Blob: {
        icon: <BlobMdIcon />,
        component: EntityBlob,
        fancyName: 'Object',
        skeleton: {
            '@type': 'Blob',
            name: '',
            fields: [],
        },
    },
    TypedCollection: {
        icon: <ListIcon />,
        component: EntityTypedCollection,
        fancyName: 'Typed Table',
        skeleton: {
            '@type': 'TypedCollection',
            name: '',
        },
    },
    RawHTML: {
        icon: <ChatGPT />,
        component: EntityRawHTML,
        fancyName: 'HTML',
        skeleton: {
            '@type': 'RawHTML',
            name: '',
            html: '',
        },
    },
    // Integration: {
    //     icon: <DriveIcon />,
    //     component: EntityIntegration,
    //     fancyName: 'Integration',
    //     skeleton: {
    //         '@type': 'Integration',
    //         name: '',
    //         url: '',
    //         auth_email: '',
    //         dsid: '',
    //         type: '',
    //     },
    // },
    // needs major refreshing or obsoleting
    DigitalDocument: {
        icon: <DraftIcon />,
        component: EntityDocument,
        fancyName: 'Upload',
        skeleton: {
            '@type': 'DigitalDocument',
            name: '',
            url: '',
            dsid: '',
        },
    },
    Highlight: {
        icon: <HighlightMdIcon />,
        component: EntityHighlight,
        fancyName: 'Highlight',
        skeleton: {
            '@type': 'Highlight',
            name: '',
            content: '',
            url: '',
        },
    },
    ListItem: {
        icon: '📍',
        component: EntityListItem,
        fancyName: 'List Item',
    },
    // LocalDirectory: {
    //     icon: <FolderIcon />,
    //     component: EntityLocalDirectory,
    //     fancyName: 'Local Directory',
    //     skeleton: {
    //         '@type': 'LocalDirectory',
    //         name: '',
    //     },
    // },
} as TypeInfo;

export default typeInfo;
