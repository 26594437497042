export default function HomeMdIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.25.5C1.28.5.5 1.28.5 2.25v3C.5 6.22 1.28 7 2.25 7h3C6.22 7 7 6.22 7 5.25v-3C7 1.28 6.22.5 5.25.5h-3ZM2 2.25c0-.14.11-.25.25-.25h3c.14 0 .25.11.25.25v3c0 .14-.11.25-.25.25h-3A.25.25 0 0 1 2 5.25v-3ZM2.25 8C1.28 8 .5 8.78.5 9.75v3c0 .97.78 1.75 1.75 1.75h3c.97 0 1.75-.78 1.75-1.75v-3C7 8.78 6.22 8 5.25 8h-3ZM2 9.75c0-.14.11-.25.25-.25h3c.14 0 .25.11.25.25v3c0 .14-.11.25-.25.25h-3a.25.25 0 0 1-.25-.25v-3Zm6-7.5C8 1.28 8.78.5 9.75.5h3c.97 0 1.75.78 1.75 1.75v3c0 .97-.78 1.75-1.75 1.75h-3C8.78 7 8 6.22 8 5.25v-3ZM9.75 2a.25.25 0 0 0-.25.25v3c0 .14.11.25.25.25h3c.14 0 .25-.11.25-.25v-3a.25.25 0 0 0-.25-.25h-3Zm0 6C8.78 8 8 8.78 8 9.75v3c0 .97.78 1.75 1.75 1.75h3c.97 0 1.75-.78 1.75-1.75v-3c0-.97-.78-1.75-1.75-1.75h-3ZM9.5 9.75c0-.14.11-.25.25-.25h3c.14 0 .25.11.25.25v3c0 .14-.11.25-.25.25h-3a.25.25 0 0 1-.25-.25v-3Z"
                fill="#333"
            />
        </svg>
    );
}
