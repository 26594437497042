import React, { useState } from 'react';
import { Formik } from 'formik';
import { FormEvent } from 'react';
import Dropzone from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { feedbackWebhook } from '@/redux/api';
import { toast, hideGlobalModal } from '@/redux/ui/actions';
import { getUserSelector } from '@/redux/user/selectors';
import Button from '../../DesignSystem/Button';
import styles from './SendFeedbackModal.module.scss';
import { getSafeFileName, uploadSingleFile } from '@/utils/helpers';
import CloseMdIcon from '@/icons/CloseMdIcon';
import PasteSmIcon from '@/icons/PasteSmIcon';

const MAX_FILE_SIZE = 3000000;

const SendFeedbackModal: React.FunctionComponent = () => {
    const [isSending, setIsSending] = useState(false);
    const [error, setError] = useState(null);
    const currentUser = useSelector(getUserSelector);
    const dispatch = useDispatch();

    const closeModal = () => dispatch(hideGlobalModal('SendFeedbackModal'));

    const showSuccessToast = () => dispatch(toast({ text: 'Thank you for your feedback!', msgType: 'success' }));

    const handleSubmit = async (values) => {
        setIsSending(true);
        setError(null);
        let attachments = [];
        try {
            if (values.attachments.length) {
                attachments = await Promise.all(
                    values.attachments.map((file) => {
                        const safeName = getSafeFileName(file);
                        return uploadSingleFile(file, safeName);
                    }),
                );
            }

            const feedback = {
                text: values.text,
                ...(attachments.length && { attachments }),
            };

            await feedbackWebhook(feedback, currentUser);
            showSuccessToast();
            closeModal();
        } catch (err) {
            setError(`Something went wrong. Please try again later. -- ${err}`);
        } finally {
            setIsSending(false);
        }
    };

    return (
        <div className={styles.sendFeedbackPopup}>
            <div className={styles.popupHeaderSection}>
                <span className={styles.popupHeader}>Send feedback</span>
                <span className={styles.popupSubHeader}>
                    Your computer is your most used tool. We&apos;re here to get this right for you.
                </span>
                {error && <span className={styles.popupError}>{error}</span>}
            </div>
            <div className={styles.popupBodySection}>
                <Formik
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={{ text: '', attachments: [] }}
                    onSubmit={(values): void => {
                        handleSubmit(values);
                    }}
                >
                    {({ handleSubmit, handleChange, values, setFieldValue }): JSX.Element => (
                        <form
                            noValidate
                            onSubmit={(e: FormEvent<HTMLFormElement>): void => {
                                handleSubmit(e);
                            }}
                        >
                            <div className={styles.feedbackInputArea}>
                                <textarea
                                    ref={(element) => element && element.focus()}
                                    placeholder={`How can Bedrock improve for you, ${currentUser?.name}?`}
                                    value={values.text}
                                    name="text"
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                            {values.attachments.length > 0 && (
                                <div className={styles.imageGrid}>
                                    {values.attachments.map((file) => (
                                        <div key={file.name} className={styles.gridItem}>
                                            <Button
                                                size="s"
                                                icon={CloseMdIcon}
                                                variant="secondary"
                                                onClick={() =>
                                                    setFieldValue(
                                                        'attachments',
                                                        values.attachments.filter((item) => file.name !== item.name),
                                                    )
                                                }
                                                className={styles.removeButton}
                                            />
                                            <img src={URL.createObjectURL(file)} alt={file.name} />
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div className={styles.feedbackPopupActions}>
                                <Dropzone
                                    accept="image/*"
                                    maxSize={MAX_FILE_SIZE}
                                    onDrop={(acceptedFiles) => {
                                        if (error) {
                                            setError(null);
                                        }
                                        setFieldValue(
                                            'attachments',
                                            values.attachments.length
                                                ? [...values.attachments, ...acceptedFiles]
                                                : [...acceptedFiles],
                                        );
                                    }}
                                    onDropRejected={() => setError('Image size must be less than 3MB')}
                                >
                                    {({ getRootProps, getInputProps, isDragActive }) => (
                                        <div
                                            {...getRootProps()}
                                            className={`dropzone ${
                                                isDragActive ? `${styles.attachmentsDropzone_active}` : ''
                                            } ${styles.attachmentsDropzone}`}
                                        >
                                            <div>
                                                <span className={styles.attachmentLabel}>
                                                    <PasteSmIcon />
                                                    Attach screenshots and images or just drag them here.
                                                </span>
                                            </div>
                                            <input {...getInputProps()} />
                                        </div>
                                    )}
                                </Dropzone>
                                <Button
                                    size="s"
                                    type="submit"
                                    disabled={isSending || (!values.text && !values.attachments.length)}
                                    className={styles.submitButton}
                                >
                                    {isSending ? 'Sending...' : 'Send feedback'}
                                </Button>
                            </div>
                        </form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default SendFeedbackModal;
