export default function GreenCheckboxIcon(): JSX.Element {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                fill="#3AD53B"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.4678 5.59467C11.7607 5.88756 11.7607 6.36244 11.4678 6.65533L6.25 11.8732L3.21967 8.84283C2.92678 8.54994 2.92678 8.07506 3.21967 7.78217C3.51256 7.48928 3.98744 7.48928 4.28033 7.78217L6.25 9.75184L10.4072 5.59467C10.7001 5.30178 11.1749 5.30178 11.4678 5.59467Z"
                fill="white"
            />
        </svg>
    );
}
