import React from 'react';
import EntityImageIcon from '@/components/EntityImageIcon';
import styles from './Thumbnail.module.scss';

type Props = {
    thumbnailUrl: string;
};

const Thumbnail = ({ thumbnailUrl }: Props): React.ReactElement => {
    return (
        <div className={styles.thumbnailWrapper}>
            <EntityImageIcon src={thumbnailUrl} className={styles.thumbnailImage} />
        </div>
    );
};

export default Thumbnail;
