import styles from '../contextMenu.module.scss';
import clsx from 'clsx';

const MenuItem = ({ item, currentSelectedItem, setCurrentSelectedItem }) => {
    return (
        <li
            key={item.name}
            onClick={item?.onClick || null}
            onMouseEnter={() => setCurrentSelectedItem(item.name)}
            className={clsx(
                styles[item.customStyle ?? 'item'],
                currentSelectedItem === item.name && styles[item.customSelectedStyle ?? 'selected'],
            )}
            data-action-name={item.name}
        >
            {item.icon && <div className={styles.itemIcon}>{item.icon}</div>}
            <span className={styles.itemLabel}>{item.name}</span>
            {item.experimental && item.experimental}
            {item.hotkey && <div className={styles.itemHotkey}>{item.hotkey}</div>}
        </li>
    );
};

export default MenuItem;
