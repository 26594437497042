import React, { ReactElement, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import styles from './styles.module.scss';
import { LISTITEM, ENTITY } from '@/dndTypes';
import { NativeTypes } from 'react-dnd-html5-backend';

export const _accept = [LISTITEM, ENTITY, NativeTypes.FILE];

export const dropFunction =
    (dropLocation: string, dropEntities, handleFileDrop, target, additionalIndent?: React.MutableRefObject<number>) =>
    (item: DnDMovedItem, monitor): void => {
        if (!monitor.didDrop() && dropEntities) {
            if (item.files) {
                handleFileDrop(item);
                return;
            }
            dropEntities(item, target, dropLocation, additionalIndent?.current);
        }
    };

export const EdgeDropZone = ({
    isTrashPage,
    dropEntities,
    edgeMaxAdditionalIndent,
    childPadding,
    monitor,
    target,
    draggingHListItem,
    indentLevel,
    edgeId,
    handleFileDrop,
    includeRootInHList,
    ...rest
}): ReactElement => {
    const additionalIndent = useRef(0);

    const edgeDropSettings = () => {
        return {
            accept: _accept,
            drop: dropFunction('edge', dropEntities, handleFileDrop, target, additionalIndent),
            collect: function (monitor): { isHovered } {
                return {
                    isHovered: monitor.isOver() && monitor.canDrop(),
                };
            },
            ...(rest?._canDrop && { canDrop: rest._canDrop('edge') }),
        };
    };

    const [{ isHovered: edgeIsHovered }, edgeDrop] = useDrop(edgeDropSettings());
    let edgeBaseOffset = (indentLevel + 1) * childPadding + 12 || childPadding + 12; // 12 = $hlistItemPadding + Icon padding
    if (includeRootInHList) edgeBaseOffset += childPadding;
    const requestRef = useRef(null);
    const prevTime = useRef(0);
    const calculateAdditionalOffset = (time) => {
        if (time - prevTime.current > 16) {
            // 60fps
            const mouseOffset = monitor.getDifferenceFromInitialOffset()?.x;
            const attemptedIndent = Math.floor(mouseOffset / childPadding);
            const addnlIndent =
                edgeMaxAdditionalIndent > 0 && attemptedIndent > 0
                    ? Math.min(attemptedIndent, edgeMaxAdditionalIndent)
                    : 0;
            additionalIndent.current = addnlIndent;
            const additionalOffset = addnlIndent * childPadding;
            const edge = document.getElementById(edgeId) as HTMLElement;
            edge.style.marginLeft = `${(edgeBaseOffset + additionalOffset).toString()}px`;
            prevTime.current = time;
        }
        requestRef.current = requestAnimationFrame(calculateAdditionalOffset);
    };

    useEffect(() => {
        if (edgeIsHovered) requestRef.current = requestAnimationFrame(calculateAdditionalOffset);
        return () => {
            if (requestRef.current) cancelAnimationFrame(requestRef.current);
        };
    }, [edgeIsHovered]);

    return (
        <div
            ref={!isTrashPage ? edgeDrop : null}
            className={`${rest?.hlistBottom ? styles.bottomEdge : ''} ${styles.edgeDropZone} 
            ${!draggingHListItem && styles.pointerEventsNone}`}
        >
            <div
                id={edgeId}
                className={`${edgeIsHovered ? styles.edgeDropZoneHover : ''} ${
                    !draggingHListItem ? styles.pointerEventsNone : ''
                }`}
            />
        </div>
    );
};
