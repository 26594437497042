export default function SpaceIcon(): JSX.Element {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M.87 2.24c0-.97.79-1.75 1.75-1.75h3.94c.97 0 1.75.78 1.75 1.75v5.12c0 .97-.78 1.75-1.75 1.75H2.62c-.96 0-1.75-.78-1.75-1.75V2.24Zm1.75-.25a.25.25 0 0 0-.25.25v5.12c0 .14.11.25.25.25h3.94c.14 0 .25-.11.25-.25V2.24a.25.25 0 0 0-.25-.25H2.62Zm7.31 2.75c0-.97.79-1.75 1.75-1.75h2.7c.96 0 1.74.78 1.74 1.75v2.68c0 .97-.78 1.75-1.75 1.75h-2.69c-.96 0-1.75-.78-1.75-1.75V4.74Zm1.75-.25a.25.25 0 0 0-.25.25v2.68c0 .14.12.25.25.25h2.7c.13 0 .24-.1.24-.25V4.74a.25.25 0 0 0-.25-.25h-2.69Zm-5.3 6.31c-.97 0-1.76.78-1.76 1.75v1.44c0 .96.79 1.75 1.75 1.75h5.5c.97 0 1.75-.79 1.75-1.75v-1.44c0-.97-.78-1.75-1.75-1.75h-5.5Zm-.26 1.75c0-.14.11-.25.25-.25h5.5c.14 0 .25.11.25.25v1.44c0 .14-.11.25-.25.25h-5.5a.25.25 0 0 1-.25-.25v-1.44Z"
                fill="#333"
            />
        </svg>
    );
}
