import { useSession } from 'next-auth/react';
import { getUser, setAnonymousUser } from '../redux/user/actions';
import { getUserSelector } from '../redux/user/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { getReroutingSelector } from '../redux/ui/selectors';
import { setRerouting } from '../redux/ui/actions';
import { personOwnsDataspace } from '@/utils/helpers';
import { isEmpty } from 'lodash';
import useAppWrapper from '@/hooks/useAppWrapper';

function Auth({ children, entityView, singleNoteView }) {
    const { data: session, status }: any = useSession();
    const router = useRouter();
    const isUser = !!session?.user;
    const { id, dsid, initialUserLoad, entity } = useSelector(getUserSelector);
    const dispatch = useDispatch();
    const rerouting = useSelector(getReroutingSelector);
    const { isBaseApp } = useAppWrapper();

    useEffect(() => {
        if (status === 'loading') {
            return; // Do nothing while loading
        }
        if ((entityView || singleNoteView) && !isUser) {
            dispatch(setAnonymousUser());
        }
        if (initialUserLoad && session?.user?.email) {
            dispatch(getUser()); //Can't rely on user object because it may not exist
        }
    }, [isUser, status, initialUserLoad]);

    useEffect(() => {
        if (entityView || singleNoteView) return;
        if (status === 'unauthenticated') router.push('/login');

        if (!isEmpty(entity)) {
            const ownsDataspace = personOwnsDataspace(entity);
            if (
                (isUser && !dsid && id) ||
                entity?.complete !== true ||
                !ownsDataspace ||
                (isBaseApp && entity?.baseOnboardingComplete !== true)
            ) {
                router.push(isBaseApp ? '/onboarding' : '/welcome', undefined, { shallow: true });
                return;
            }
        }
    }, [isUser, dsid, id, status, entity]);

    useEffect(() => {
        if (rerouting) dispatch(setRerouting(false));
    }, [router.asPath]);

    if (
        (isUser && id && (dsid || ['welcome', 'onboarding'].includes(router.pathname.split('/')[1]))) ||
        (id && entityView) ||
        singleNoteView
    ) {
        return children;
    }

    return <img className="loadingImg" src="/images/bedrock-loader.gif" />;
}

export default Auth;
