import React, { useEffect, useRef, useState } from 'react';
import { Image } from 'react-bootstrap';
import avatarStyles from '@/pages/avatar.module.scss';
import Text from '../Text';
import Compressor from 'compressorjs';

interface AvatarButtonProps {
    onChange: (avatar: File | Blob) => void;
    value: string;
    square?: boolean;
    placeholder?: JSX.Element;
}

const AvatarButton: React.FC<AvatarButtonProps> = ({ onChange, value, square, placeholder }) => {
    const avatarPreview = '/defaultAvatar.png';
    const avatarRef = useRef<HTMLInputElement>(null);
    const [avatarPath, setAvatarPath] = useState(value);

    useEffect(() => {
        setAvatarPath(value);
    }, [value]);

    const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e): void => {
        const image = e.target.files[0];
        if (image) {
            new Compressor(image, {
                quality: 0.6,
                success: (compressedResult) => {
                    setAvatarPath(URL.createObjectURL(compressedResult));
                    onChange(compressedResult);
                },
            });
        }
    };

    const handleAvatarClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
        e.preventDefault();
        avatarRef.current?.click();
    };

    return (
        <div className={avatarStyles.avatarUploadContainer}>
            <button onClick={handleAvatarClick} className={avatarStyles.avatarUpload} type="button">
                <div className={square ? avatarStyles.iconCropperSettings : avatarStyles.imageCropper}>
                    {placeholder && !avatarPath && (
                        <div className={avatarStyles.placeholderContainer}>{placeholder}</div>
                    )}
                    {avatarPath && (
                        <Image
                            className={`${square ? avatarStyles.icon : avatarStyles.avatar}`}
                            id="avatarPreview"
                            src={avatarPath || avatarPreview}
                        />
                    )}
                </div>
                <Text size="s" align="center" variant="inactive" className={avatarStyles.avatarUploadLabel}>
                    {avatarPath ? 'Change ' : 'Add '}
                    {square ? 'icon' : 'photo'}
                </Text>
            </button>
            <input
                ref={avatarRef}
                // onChange={(e) => {props.handleChange; handleDisplayFileDetails(e);}}
                onChange={handleFileChange}
                className="d-none"
                type="file"
                id="avatarUploadInput"
                name="avatar"
                accept="image/*"
                // value={props.values.avatar}
            />
        </div>
    );
};

export default AvatarButton;
