import { useCallback, useRef } from 'react';
import { useVirtual } from 'react-virtual';

function easeInOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
}

const useVirtualSmooth = (size) => {
    const parentRef = useRef<HTMLDivElement>();
    const scrollingRef = useRef<number>();

    const scrollToFn = useCallback((offset, defaultScrollTo) => {
        const duration = 450;
        const start = parentRef?.current?.scrollTop;
        const startTime = (scrollingRef.current = Date.now());

        const run = () => {
            if (scrollingRef.current !== startTime) return;
            const now = Date.now();
            const elapsed = now - startTime;
            const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
            const interpolated = start + (offset - start) * progress;

            if (elapsed < duration) {
                defaultScrollTo(interpolated);
                requestAnimationFrame(run);
            } else {
                defaultScrollTo(interpolated);
            }
        };

        requestAnimationFrame(run);
    }, []);

    const getSize = useCallback(() => 32, []);

    const rowVirtualizer = useVirtual({
        size,
        parentRef,
        estimateSize: getSize, // $hlistItemHeight
        overscan: 10,
        scrollToFn,
    });

    return {
        rowVirtualizer,
        parentRef,
    };
};

export default useVirtualSmooth;
