import styles from './WelcomeVideoModal.module.scss';
import Iframely from '../../Iframely';

//TODO: Set correct url for welcome video
const WELCOME_VIDEO_URL = 'https://www.youtube.com/watch?v=dQw4w9WgXcQ';

const WelcomeVideoModal = () => {
    return (
        <div className={styles.welcomeVideoModalContainer}>
            <Iframely url={WELCOME_VIDEO_URL} />
        </div>
    );
};

export default WelcomeVideoModal;
