import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { INTEGRATIONS_LIST } from '../../integrations/constants';
import { find } from 'lodash';
import { buildUID, parseUID } from '@/utils/IDManager';

const getState = (state: AppState) => state.Integrations;

export const getIntegrationPendingSelector = createSelector(getState, (state) => state.pending);

export const getIntegrationItemsListSelector = (app, appId) =>
    createSelector(getState, (state) => state?.[app]?.[appId]?.itemsList);

export const getIntegrationItemsSelector = (app, appId) =>
    createSelector(getState, (state) => state?.[app]?.[appId]?.items);

export const getIntegrationItemsListItemSelector = (app, appId, id) =>
    createSelector(getState, (state) => {
        const itemsList = state?.[app]?.[appId]?.itemsList;
        return find(itemsList, { id });
    });

export const getAllIntegrationItemsSelector = createSelector(getState, (state) => {
    const items = [];
    INTEGRATIONS_LIST.forEach((app) => {
        const accounts = Object.keys(state[app]);
        accounts.forEach((appId) =>
            state[app][appId].itemsList?.map((item) => {
                items.push(Object.assign({}, item, { app, appId }));
            }),
        );
    });
    return items;
});

export const getIntegrationItemSelector = (uid) =>
    createSelector(getState, (state) => {
        const { fid, app, appId } = parseUID(uid);
        const uidNoParent = buildUID({ fid, app, appId });
        if (state?.[app]?.[appId]?.items?.[uidNoParent]) {
            return Object.assign({}, state[app][appId].items[uidNoParent], { app, appId });
        } else if (fid === 'root') {
            return {
                app,
                appId,
                childrenList: [],
                '@type': 'IntegrationItem',
                name: `${app} - ${appId}`,
            };
        }
    });

export const getIntegrationRootListSelector = (app, appId) =>
    createSelector(getState, (state) => state?.[app]?.[appId]?.items?.root);

export const getIntegrationStateSelector = createSelector(getState, (state) => state);

export const getIntegrationAppLastFetch = (app, appId) =>
    createSelector(getState, (state) => state?.[app]?.[appId]?.lastFetch);

const getEntityLists = (state: AppState) => state.Entities.entityLists;

export const getDsidListIntegrationItemAddedTo = (foreignId) =>
    createSelector(getEntityLists, (entityLists) => {
        return Object.entries(entityLists).reduce((acc, current: any[]) => {
            const [key, list] = current;
            if (list?.some((entity) => entity?.foreignId === foreignId)) {
                acc.push(key);
            }
            return acc;
        }, []);
    });
