import styles from './styles.module.scss';
import React from 'react';
import Button from '../../../DesignSystem/Button';
import DeleteIcon from '@/icons/DeleteIcon';
import RefreshMdIcon from '@/icons/RefreshMdIcon';
import { useDispatch } from 'react-redux';
import { restoreEntitiesFromTrash } from '../../../../redux/entities/actions';
import { clearBrowsed, showGlobalModal } from '../../../../redux/ui/actions';
import Tooltip from 'components/Tooltip';
import { clsx } from 'clsx';

type Props = {
    eid: string;
    parentId: string;
    canRestore?: boolean;
    canShow?: boolean;
    noHover?: boolean;
};

const DeletedEntitiesControls = ({ eid, parentId, canRestore, canShow, noHover = false }: Props) => {
    const dispatch = useDispatch();

    const stopPropagation = (e): void => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleRestore = () => {
        dispatch(clearBrowsed());
        dispatch(restoreEntitiesFromTrash([{ id: eid, parentId }]));
    };

    const showConfirmRemoveModal = () =>
        dispatch(showGlobalModal('ConfirmHardRemoveModal', { entities: [{ id: eid, parentId }] }));

    return (
        <div
            className={clsx(
                styles.controlButtonsContainer,
                !canShow && styles.hidden,
                noHover && styles.noHoverControlButtons,
            )}
            onClick={stopPropagation}
        >
            {canRestore && (
                <Tooltip
                    key={`re-${eid}`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy tooltipTippyCol'} id={`tooltip-el-${eid}`}>
                            Restore Entity
                        </div>
                    }
                >
                    <div>
                        <Button
                            size="m"
                            variant="plain"
                            className="controlMenuButton mr-2"
                            icon={RefreshMdIcon}
                            onClick={handleRestore}
                        />
                    </div>
                </Tooltip>
            )}

            <Tooltip
                key={`de-${eid}`}
                placement="bottom"
                appendTo={() => document.body}
                delay={100}
                content={
                    <div className={'tooltipTippy'} id={`tooltip-el-${eid}`}>
                        Delete Forever
                    </div>
                }
            >
                <div>
                    <Button
                        size="m"
                        variant="plain"
                        className="controlMenuButton"
                        icon={DeleteIcon}
                        onClick={showConfirmRemoveModal}
                    />
                </div>
            </Tooltip>
        </div>
    );
};

export default DeletedEntitiesControls;
