export default function CheckboxMdIcon(): JSX.Element {
    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.5 0.6875C4.18528 0.6875 0.6875 4.18528 0.6875 8.5C0.6875 12.8147 4.18528 16.3125 8.5 16.3125C12.8147 16.3125 16.3125 12.8147 16.3125 8.5C16.3125 4.18528 12.8147 0.6875 8.5 0.6875ZM12.4678 7.15533C12.7607 6.86244 12.7607 6.38756 12.4678 6.09467C12.1749 5.80178 11.7001 5.80178 11.4072 6.09467L7.25 10.2518L5.28033 8.28217C4.98744 7.98928 4.51256 7.98928 4.21967 8.28217C3.92678 8.57506 3.92678 9.04994 4.21967 9.34283L6.71967 11.8428L7.25 12.3732L7.78033 11.8428L12.4678 7.15533Z"
                fill="#333333"
            />
        </svg>
    );
}
