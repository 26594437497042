import { useCallback, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BedrockDropdown from '@/components/DesignSystem/Dropdown';
import { getDataspaceSelector } from '@/redux/entities/selectors';
import bedrockDropdownStyles from '@/components/DesignSystem/Dropdown/BedrockDropdownStyles.module.scss';
import styles from '@/components/sidebar/styles.module.scss';
import { useEntities } from '@/hooks/reduxHooks';
import { getDataspaceIdsSelector } from '@/redux/ui/selectors';
import { setDataspace } from '@/redux/entities/actions';
import HomeIcon from '@/icons/HomeIcon';
import { useRouter } from 'next/router';
import { DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID } from '@/redux/constants';
import EntityImageIcon from '@/components/EntityImageIcon';

const DefaultDataspaceIcon = () => {
    return (
        <div className={styles.dataspaceIconDefault}>
            <HomeIcon />
        </div>
    );
};

type Props = {
    disableSpecialDataspaces?: boolean;
};

const DataspaceSelector = ({ disableSpecialDataspaces }: Props) => {
    const dispatch = useDispatch();
    const currentDataspace = useSelector(getDataspaceSelector);
    const dataspaceIds = useSelector(getDataspaceIdsSelector);
    const dataspaces = useEntities(dataspaceIds, false, 'objects');
    const router = useRouter();
    const handleDataspaceClick = useCallback((e) => {
        const dsid = e.target.getAttribute('data-dataspace-id');
        if (dsid) {
            if (/^\/base\/.+/.test(location.pathname)) router.push('/base/' + dsid, `/base/${dsid}`, { shallow: true });
            else dispatch(setDataspace(dsid));
        }
    }, []);

    const listedDataspaces = useMemo((): JSX.Element[] => {
        const dataspaceList = !disableSpecialDataspaces
            ? dataspaces
            : dataspaces.filter((ds) => ![SHARED_DATASPACE_ID, DRAFTS_DATASPACE_ID].includes(ds.id));
        return dataspaceList.map((ds, idx) => {
            return (
                <Dropdown.Item
                    key={`dataspace-${idx}-${ds.name}`}
                    eventKey={`dataspace-${idx}-${ds.name}`}
                    className={styles.dataspaceDropdownItem}
                    data-dataspace-id={ds.id}
                    onClick={handleDataspaceClick}
                    href="#"
                >
                    {ds?.iconUrl ? (
                        <EntityImageIcon src={ds.iconUrl} className={styles.dataspaceIcon} />
                    ) : (
                        <DefaultDataspaceIcon />
                    )}
                    {ds.name}
                </Dropdown.Item>
            );
        });
    }, [currentDataspace, dataspaces, disableSpecialDataspaces]);
    return (
        <div className={styles.dataspaceSelectorDropdown}>
            <BedrockDropdown>
                <Dropdown>
                    <Dropdown.Toggle className={'bold textS'} style={{ padding: 4 }}>
                        {currentDataspace?.iconUrl ? (
                            <EntityImageIcon src={currentDataspace.iconUrl} className={styles.dataspaceIcon} />
                        ) : (
                            <DefaultDataspaceIcon />
                        )}
                        <span className={styles.dataspaceDropdownCurrent}>{currentDataspace?.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={bedrockDropdownStyles.dropdownMenu} rootCloseEvent="mousedown">
                        {listedDataspaces}
                    </Dropdown.Menu>
                </Dropdown>
            </BedrockDropdown>
        </div>
    );
};

export default DataspaceSelector;
