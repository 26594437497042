export default function TriangleRightIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.50363 5.98888L5.50363 10.0111C5.50363 10.162 5.53658 10.2921 5.60249 10.4014C5.6684 10.5124 5.75685 10.5982 5.86785 10.6589C5.97885 10.7196 6.102 10.75 6.23728 10.75C6.30145 10.75 6.36562 10.7413 6.4298 10.724C6.49397 10.7066 6.55554 10.6806 6.61451 10.6459L10.1241 8.63221C10.2489 8.5611 10.3417 8.46917 10.4024 8.35643C10.4649 8.24369 10.4961 8.12488 10.4961 8C10.4961 7.87512 10.4649 7.75544 10.4024 7.64096C10.34 7.52649 10.2472 7.43543 10.1241 7.36779L6.61451 5.35407C6.49484 5.28469 6.36909 5.25 6.23728 5.25C6.102 5.25 5.97885 5.27949 5.86785 5.33846C5.75685 5.39916 5.6684 5.48502 5.60249 5.59603C5.53658 5.70703 5.50363 5.83798 5.50363 5.98888Z"
                fill="#BABABA"
            />
        </svg>
    );
}
