import { IntegrationName } from 'integrations/types';
import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    SET_ANONYMOUS_USER,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    COMPLETE_USER_SIGNUP,
    COMPLETE_USER_SIGNUP_SUCCESS,
    COMPLETE_USER_SIGNUP_FAILURE,
    DISMISS_PROMPT,
} from '../actionTypes';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { getNextAuthSession } from 'integrations/helpers';
import { NODE_ENV } from '@/config';

let analytics;
const juneKey = process.env.NEXT_PUBLIC_JUNE_WRITE_KEY;

if (NODE_ENV !== 'development' && juneKey && typeof window !== 'undefined') {
    AnalyticsBrowser.load({
        writeKey: juneKey,
    }).then(([resolvedAnalytics]) => {
        analytics = resolvedAnalytics;
    });
}
const session: any = getNextAuthSession();

export const getUser = (): ReduxAction => {
    return {
        type: GET_USER,
        payload: {},
    };
};

export const getUserSuccess = (user: Entity): ReduxAction => {
    return {
        type: GET_USER_SUCCESS,
        payload: { user },
    };
};

export const getUserFailure = (error: unknown): ReduxAction => {
    return {
        type: GET_USER_FAILURE,
        payload: { error },
    };
};

export const setAnonymousUser = (): ReduxAction => {
    return {
        type: SET_ANONYMOUS_USER,
        payload: {},
    };
};

export const createUser = (
    email: string,
    galacticaInterest?: boolean,
    bypassWaitlist?: boolean,
    callback?: () => void,
): ReduxAction => {
    analytics?.track('Create User', {
        email,
        galacticaInterest,
        userId: session?.id,
    });
    return {
        type: CREATE_USER,
        payload: { email, galacticaInterest, bypassWaitlist, callback },
    };
};

export const createUserSuccess = (user: Entity): ReduxAction => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: { user },
    };
};

export const createUserFailure = (error: unknown): ReduxAction => {
    return {
        type: CREATE_USER_FAILURE,
        payload: { error },
    };
};

export const completeUserSignup = (values): ReduxAction => {
    analytics?.track('Complete User Signup', {
        values,
        userId: session?.id,
    });
    return {
        type: COMPLETE_USER_SIGNUP,
        payload: { ...values },
    };
};

export const completeUserSignupSuccess = (results: object): ReduxAction => {
    return {
        type: COMPLETE_USER_SIGNUP_SUCCESS,
        payload: { results },
    };
};

export const completeUserSignupFailure = (error: unknown): ReduxAction => {
    return {
        type: COMPLETE_USER_SIGNUP_FAILURE,
        payload: { error },
    };
};

export const dismissPrompt = (prompt: IntegrationName): ReduxAction => {
    return {
        type: DISMISS_PROMPT,
        payload: { prompt },
    };
};
