export default function TriangleDownSmIcon(): JSX.Element {
    return (
        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.46263 0.184546L0.53737 0.184546C0.427626 0.184546 0.333018 0.208512 0.253548 0.256444C0.172817 0.304377 0.110376 0.368707 0.0662256 0.449435C0.0220755 0.530164 2.79389e-07 0.619722 2.70787e-07 0.718109C2.66707e-07 0.764781 0.00630739 0.811452 0.0189219 0.858123C0.0315361 0.904794 0.0504575 0.949573 0.0756862 0.99246L1.54021 3.54486C1.59193 3.63568 1.65878 3.70317 1.74078 3.74731C1.82277 3.79272 1.90918 3.81543 2 3.81543C2.09082 3.81543 2.17786 3.79272 2.26112 3.74731C2.34437 3.70191 2.4106 3.63442 2.45979 3.54486L3.92431 0.99246C3.97477 0.905425 4 0.813975 4 0.71811C4 0.619722 3.97856 0.530164 3.93567 0.449436C3.89152 0.368707 3.82908 0.304377 3.74834 0.256445C3.66761 0.208512 3.57237 0.184546 3.46263 0.184546Z"
                fill="black"
                fillOpacity="0.25"
            />
        </svg>
    );
}
