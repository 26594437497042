import React from 'react';
import styles from '@/components/page.module.scss';

const Loader = () => {
    return (
        <div className={styles.loaderWrapper}>
            <img className="loadingImg" src="/images/bedrock-loader.gif" />
        </div>
    );
};

export default Loader;
