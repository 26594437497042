export default function AddChildEntityIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.875 3V7.5H8.125V3H6.875ZM6.875 7.5V12H8.125V7.5H6.875ZM7.5 8.125H12V6.875H7.5V8.125ZM7.5 6.875H3V8.125H7.5V6.875Z"
                fill="#4D4D4D"
            />
            <rect x="0.625" y="0.625" width="13.75" height="13.75" rx="1.375" stroke="#4D4D4D" strokeWidth="1.25" />
        </svg>
    );
}
