import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import { OpenItemParams, useAppBackground, useEntities, useEntity, useFinderPath } from '@/hooks/reduxHooks';
import { SEARCH_CONDENSED_ENTITY_NAMES } from '@/queries';
import { createEntity } from '@/redux/entities/actions';
import { getAllCondensedEntitiesSelector } from '@/redux/entities/selectors';
import { getDataspaceIdsSelector, getFinderSelector } from '@/redux/ui/selectors';
import { getIsUserAnonymous } from '@/redux/user/selectors';
import { abbreviateBreadcrumb, asyncGQL, buildBreadcrumb, getEntityIcon, simpleTextMatch } from '@/utils/helpers';
import { commands } from '../commands';
import omniboxStyles from './styles.module.scss';
import windowFrameStyles from '../../components/page.module.scss';
import { useUploadEntityPopup } from '../modals/uploadEntityPopup';
import { Option, Group, GetOptions } from './types';
import { setRerouting } from '@/redux/ui/actions';
import LinkMdIcon from '@/icons/LinkMdIcon';
import { BASE_ORIGIN } from '@/config';
import { getAllIntegrationItemsSelector } from '@/redux/integrations/selectors';
import { merge, sortBy, unionBy } from 'lodash';
import typeInfo from '../entities/typeInfo';
import SearchIcon from '@/icons/SearchIcon';
import { DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID } from '@/redux/constants';

interface OmniboxProps {
    autoFocus?: boolean;
    canCreate?: boolean;
    canDeepSearch?: boolean;
    className?: string;
    defaultOptions?: Option[];
    defaultOptionsLabel?: string;
    forwardRef?: any;
    getOptions?: (search: string) => void;
    hideIcon?: boolean;
    inputValue?: string;
    instanceId?: string;
    isGlobal?: boolean;
    loadingMessage?: () => any;
    noOptionsMessage?: ({}) => any;
    selectedOptionComponent?: () => any;
    onBlur?: () => void;
    onCreateNewEntity?: (type: string) => void;
    onEntitySelect?: (entityIdentity: OpenItemParams, dsid?: string) => void;
    onFocus?: (e: any) => void;
    onSelect?: (option: Option) => void;
    onSelectSideEffect?: () => void;
    option?: Option;
    placeholder: string;
    styleOverrides?: { [key: string]: any };
    options?: Option[];
    useGroups?: boolean;
    searchIcon?: boolean;
    componentOverrides?: { [key: string]: any };
    showMenuOnFocus?: boolean;
}

// TODO: handle shift enter - open in finder
const Omnibox = (props: OmniboxProps) => {
    const {
        autoFocus,
        canCreate,
        canDeepSearch,
        defaultOptions,
        defaultOptionsLabel,
        forwardRef,
        getOptions,
        hideIcon = false,
        inputValue: providedInputValue,
        instanceId = 'omnibox',
        loadingMessage,
        noOptionsMessage,
        onBlur,
        onCreateNewEntity,
        onEntitySelect,
        onFocus,
        onSelect,
        onSelectSideEffect,
        option: providedOption,
        placeholder,
        styleOverrides,
        selectedOptionComponent,
        options,
        useGroups,
        searchIcon,
        componentOverrides = {},
        showMenuOnFocus = true,
    } = props;
    // const [selectedOption, setSelectedOption] = useState(option);
    // used to capture input value
    const router = useRouter();
    const [inputValue, setInputValue] = useState(providedInputValue);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [groupedOptions, setGroupedOptions] = useState<Group[]>([]);
    const { isLightBackground } = useAppBackground();
    const localRef = useRef(null);
    const timerRef = useRef<number | NodeJS.Timeout | null>(null);
    const inputValueRef = useRef(inputValue);
    const MAX_GROUP_SIZE = 50;
    const dataspaces = useSelector(getDataspaceIdsSelector);

    useEffect(() => {
        setInputValue(providedInputValue);
    }, [providedInputValue]);

    useEffect(() => {
        inputValueRef.current = inputValue;
        loadOptions(inputValue);
    }, [inputValue]);

    const handleInputChange = (input, { action }) => {
        if (!defaultOptions?.length || !menuIsOpen) setMenuIsOpen(!!input);
        if (action === 'menu-close' || action === 'input-blur') {
            setMenuIsOpen(false);
        } else if (action === 'set-value') {
            return;
        } else {
            setInputValue(input);
        }
    };

    const handleFocus = (e) => {
        if (onFocus) onFocus(e);
        if (defaultOptions?.length && showMenuOnFocus) setMenuIsOpen(true);
        if (inputValueRef.current) loadOptions(inputValueRef.current);
    };

    const handleBlur = () => {
        if (onBlur) onBlur();
    };

    const blurOmnibox = () => {
        forwardRef?.current?.blur();
        localRef?.current?.blur();
    };

    const Option = (props) => {
        const dataspaceEntity = useEntity(props.data?.dsid, true, 'omnibox');
        const pathIds = useFinderPath(props.data?.id, props.data?.dsid);
        const pathEntities = useEntities(pathIds, true, 'omnibox');
        // Note: we probably need to refactor this to NOT iterate though all types each time component gets re-rendered
        // ...some day
        const path = [
            'Command',
            'newUser',
            'SearchBedrock',
            'SearchGoogle',
            'WebURL',
            'vehicleMake',
            'vehicleModel',
            'vehicleYear',
            'bankName',
            'paymentCard',
            'companyType',
            'gender',
        ].includes(props.data?.type)
            ? []
            : buildBreadcrumb(dataspaceEntity, pathEntities, null, props.data?.dsid === DRAFTS_DATASPACE_ID);
        if (path.length > 1) path?.pop();

        const chipletParams = { isIntegrationItem: props.data?.type === 'integrationItem', command: props.data?.type };
        const chipletIcon = props.data?.icon || getEntityIcon(props.data?.entity, chipletParams);

        const title = props.data.label || props.data.entity?.name || props.data.name;
        const fullPath = path.join(' / ');

        const hint = fullPath ? `\n\nLocated in ${fullPath}` : '';

        return (
            <components.Option {...props}>
                <div className={omniboxStyles.result} title={`"${title}"${hint}`}>
                    {chipletIcon && <div className={omniboxStyles.resultChiplet}>{chipletIcon}</div>}
                    <div className={omniboxStyles.optionNameContainer}>
                        <span className={omniboxStyles.optionName} title={title}>
                            {title}
                        </span>
                        <span className={omniboxStyles.path} title={fullPath}>
                            {abbreviateBreadcrumb(fullPath)}
                        </span>
                    </div>
                </div>
            </components.Option>
        );
    };

    const DefaultSingleValue = (props) => {
        return (
            <components.SingleValue {...props}>
                <div
                    className={`${omniboxStyles.result} ${omniboxStyles.label} ${
                        isLightBackground ? windowFrameStyles.darkText : ''
                    }`}
                >
                    {!hideIcon && <div className={omniboxStyles.resultChiplet}>{props?.data?.icon || ''}</div>}
                    {props.data.label}
                </div>
            </components.SingleValue>
        );
    };

    const IndicatorsContainer = () => {
        return searchIcon ? (
            <div className={omniboxStyles.indicatorsContainer}>
                <SearchIcon />
            </div>
        ) : null;
    };

    const formatGroupLabel = (data) => (
        <div style={{ paddingLeft: 0, marginLeft: '-7px' }}>
            <span>{data.label}</span>
        </div>
    );

    const defaultStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%',
        }),
        input: (provided) => ({
            ...provided,
            fontSize: 12,
            paddingBottom: 0,
            paddingTop: 0,
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: 12,
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? 'rgba(0, 160, 247, 0.15)' : 'none',
            color: 'rgb(51, 51, 51)',
            borderRadius: 3,
            fontSize: 12,
            marginLeft: 6,
            marginRight: 6,
            paddingBottom: 0,
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 0,
            width: 'calc(100% - 12px)', // margin + padding
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: '5px',
            cursor: 'text',
            minHeight: 'none',
            paddingBottom: 0,
            paddingTop: 0,
            ':hover': {
                background: 'rgba(255, 255, 255, 0.4)',
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            paddingBottom: 0,
            paddingTop: 0,
        }),
        menu: (provided) => ({
            ...provided,
            border: 'none',
            borderRadius: 5,
            boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
            overflow: 'hidden',
            padding: '2px 0',
            zIndex: 20,
        }),
    };

    const styles = merge(defaultStyles, styleOverrides);

    const userIsAnonymous = useSelector(getIsUserAnonymous);
    const allIntegrationItems = useSelector(getAllIntegrationItemsSelector);
    const condensedEntities = useSelector(getAllCondensedEntitiesSelector);
    const condensedEntitiesArray = useMemo(() => {
        return Object.values(condensedEntities).filter(
            (entity: CondensedEntity) =>
                !!entity.metadata?.dsid && !entity.deleted && !['Dataspace', 'Finder'].includes(entity['@type']),
        );
    }, [condensedEntities]);
    const urlTestRegex = /([\w+]+\:\/\/)?([\w\d-]+\.)*[\w-]+[\.\:]\w+([\/\?\=\&\#\.]?[\w-]+)*\/?/gm;

    const getCreateOptions = (search?: string) => {
        return [
            ...(inputValueRef.current?.trim()
                ? commands.filter((el) => el.label.toLowerCase().includes(inputValueRef.current.trim()))
                : commands),
            ...(search && urlTestRegex.test(search)
                ? [
                      {
                          label: `New Web Page (${search})`, // get actual website title
                          icon: <LinkMdIcon />,
                          type: 'WebURL',
                          value: search,
                      },
                  ]
                : []),
        ];
    };
    const getSearchOptions = () => [
        {
            label: `Search Bedrock for ${inputValueRef.current.trim()}`,
            type: 'SearchBedrock',
            value: inputValueRef.current.trim(),
        },
        {
            label: `Search Google for ${inputValueRef.current.trim()}`,
            type: 'SearchGoogle',
            value: inputValueRef.current.trim(),
        },
    ];

    const getDefaultGroupedOptions = () => {
        return [
            { label: defaultOptionsLabel || 'Entities', options: defaultOptions || ([] as Option[]) },
            ...(canCreate ? [{ label: 'Create', options: getCreateOptions() as Option[] }] : []),
        ];
    };

    useEffect(() => {
        if (!inputValueRef.current && (useGroups || !options) && menuIsOpen)
            setGroupedOptions(getDefaultGroupedOptions());
    }, [defaultOptions, options, useGroups, menuIsOpen]);

    const getOptionsFromArray = (arr, str: string, type?: string) => {
        return arr
            .filter((item) => simpleTextMatch(item.name, str))
            .map((item) => {
                return {
                    label: item.name?.length ? item.name : 'Untitled ' + typeInfo[item['@type']]?.fancyName,
                    id: item.id || item.metadata?.id,
                    dsid: item.dsid || item.metadata?.dsid,
                    app: item.app,
                    appId: item.appId,
                    entity: item,
                    type,
                } as Option;
            })
            .slice(0, MAX_GROUP_SIZE);
    };

    const defaultLoadOptions: GetOptions = (search: string) => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        if (!search) {
            setGroupedOptions(getDefaultGroupedOptions());
            return;
        }

        timerRef.current = setTimeout(() => {
            search = search.trim();
            if (userIsAnonymous) {
                setGroupedOptions([]);
                return;
            }

            // Return cached entities first
            const cachedEntitiesOptions = sortBy(getOptionsFromArray(condensedEntitiesArray, search), ['label']);
            const cachedEntitiesGroup = [{ label: 'Entities', options: cachedEntitiesOptions }];
            const integrationGroup = [
                {
                    label: 'Integrations',
                    options: sortBy(getOptionsFromArray(allIntegrationItems, search, 'integrationItem'), ['label']),
                },
            ];
            const createGroup = canCreate && search ? [{ label: 'Create', options: getCreateOptions(search) }] : [];
            const searchGroup = canDeepSearch && search ? [{ label: 'Search', options: getSearchOptions() }] : [];

            const cachedGroupedOptions = [...cachedEntitiesGroup, ...integrationGroup, ...createGroup, ...searchGroup];

            inputValueRef.current?.trim() === search && setGroupedOptions(cachedGroupedOptions);

            asyncGQL(SEARCH_CONDENSED_ENTITY_NAMES, {
                search: search,
                dataspaces: dataspaces?.filter((id) => id !== SHARED_DATASPACE_ID && id !== DRAFTS_DATASPACE_ID),
            })
                .then((resp) => {
                    const results: Option[] = resp.data.search_condensed_entity_names.map(
                        ({
                            id,
                            dsid,
                            name,
                            emoji,
                            entity_type,
                            url,
                            thumbnail_url,
                            app,
                            icon_link,
                            mime_type,
                            key,
                        }) => ({
                            id,
                            dsid,
                            label: name?.length ? name : 'Untitled ' + typeInfo[entity_type]?.fancyName,
                            entity: {
                                metadata: {
                                    dsid,
                                },
                                emoji,
                                ['@type']: entity_type,
                                name,
                                url,
                                thumbnailUrl: thumbnail_url,
                                app,
                                iconLink: icon_link,
                                mimeType: mime_type,
                                key,
                            },
                        }),
                    );

                    if (inputValueRef.current?.trim() === search) {
                        const fullGroupedOptions = [
                            // { label: 'Entities', options: sortBy(results, ['label']) },
                            {
                                label: 'Entities',
                                options: unionBy(cachedEntitiesOptions, sortBy(results, ['label']), 'id'),
                            },
                            ...integrationGroup,
                            ...createGroup,
                            ...searchGroup,
                        ];
                        setGroupedOptions(fullGroupedOptions);
                    }
                })
                .catch((e) => {
                    console.error(e);
                });
        }, 300);
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current);
            }
        };
    }, []);

    const loadOptions = getOptions ? getOptions : defaultLoadOptions;
    const SingleValue = selectedOptionComponent ? selectedOptionComponent : DefaultSingleValue;

    const finder = useSelector(getFinderSelector);
    const openUploadPopup = useUploadEntityPopup();

    const dispatch = useDispatch();

    const redirect = (link: string, openInNewTab: boolean, sideEffect?: () => void) => {
        if (openInNewTab) {
            window.open(`${BASE_ORIGIN}/${link}`);
        } else {
            if (sideEffect) sideEffect();
            router.push(link);
        }
    };

    const updateBrowsed = useCallback((returnedEntity: Entity) => {
        const id = returnedEntity?.id;
        if (onEntitySelect) {
            onEntitySelect({ id });
            return;
        }
        redirect('/entity/' + id, true, () => dispatch(setRerouting(true)));
    }, []);

    const updateBrowsedFallback = useCallback(() => {
        router.back();
    }, []);

    const defaultOnCreateNewEntity = (type) => {
        if (type === 'DigitalDocument') {
            openUploadPopup();
            return;
        }
        dispatch(
            createEntity(
                {
                    type,
                    parentEntity: finder.entity,
                    parentId: finder.id,
                },
                updateBrowsed,
                updateBrowsedFallback,
            ),
        );
    };

    const defaultHandleSelect = (option: Option) => {
        switch (option.type) {
            case 'Command': {
                if (onCreateNewEntity) {
                    onCreateNewEntity(option.target);
                } else {
                    defaultOnCreateNewEntity(option.target);
                }
                return;
            }
            case 'SearchGoogle': {
                window.open(`https://google.com/search?q=${option.value}`, '_blank');
                return;
            }
            case 'SearchBedrock': {
                redirect(`/search?q=${option.value}`, true);
                return;
            }
            case 'WebURL': {
                dispatch(
                    createEntity(
                        {
                            type: 'Webwindow',
                            parentEntity: finder.entity,
                            parentId: finder.id,
                            entity: { name: option.value, url: option.value },
                        },
                        updateBrowsed,
                        updateBrowsedFallback,
                    ),
                );
                return;
            }
            default: {
                if (onEntitySelect) {
                    onEntitySelect({ id: option.id, app: option.app, appId: option.appId, dsid: option.dsid });
                }
                return option;
            }
        }
    };

    const handleOnChange = (option) => {
        setInputValue('');
        if (onSelect) {
            onSelect(option);
        } else {
            defaultHandleSelect(option);
        }
        onSelectSideEffect && onSelectSideEffect();
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') blurOmnibox();
    };

    return (
        <Select
            ref={forwardRef || localRef}
            blurInputOnSelect
            isSearchable
            placeholder={placeholder}
            value={providedOption}
            onChange={handleOnChange}
            inputValue={inputValue ? inputValue : ''}
            // handle both Entities & Commands
            getOptionLabel={(e: any) => (e.entity?.name ? e.entity.name : e.label)}
            getOptionValue={(e) => (e.id ? e.id : e.value)}
            onInputChange={handleInputChange}
            openMenuOnClick={false}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: Option,
                SingleValue: SingleValue,
                IndicatorsContainer: IndicatorsContainer,
                ...componentOverrides,
            }}
            menuIsOpen={menuIsOpen}
            instanceId={instanceId}
            styles={styles}
            autoFocus={autoFocus}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            isLoading={false}
            options={!useGroups && options ? options : groupedOptions}
            filterOption={() => true}
            noOptionsMessage={noOptionsMessage}
            loadingMessage={loadingMessage}
            formatGroupLabel={formatGroupLabel}
        />
    );
};

export default Omnibox;
