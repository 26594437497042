import React, { memo } from 'react';
import styles from '@/components/entities/Entity.module.scss';
import ControlMenu from '@/components/entities/subcomponents/ControlMenu';
import UserAvatars from '@/components/entities/subcomponents/UserAvatars';
import useAppWrapper from '@/hooks/useAppWrapper';
import { getItemName } from '@/utils/helpers';
import { clsx } from 'clsx';
import { useDispatch } from 'react-redux';
import { updateEntity } from '@/redux/entities/actions';
import Tooltip from 'components/Tooltip';
import Tabs from '@/components/DesignSystem/Tabs';

const previewTypeActions = [
    {
        tooltip: 'Gallery view',
        label: 'Gallery',
        value: 'GALLERY',
    },
    {
        tooltip: 'Metadata view',
        label: 'Info',
        value: 'METADATA',
    },
];

type EntityTopBarProps = {
    entity: Entity;
    eid: string;
    showPreviewTypeToggler?: boolean;
};

const EntityTopBar = ({ entity, eid, showPreviewTypeToggler }: EntityTopBarProps) => {
    const entityName = getItemName(entity);
    const { isNotesApp, isBaseApp } = useAppWrapper();
    const dispatch = useDispatch();
    const previewType = entity.previewType || 'METADATA';

    const togglePreviewType = () => {
        if (previewType === 'GALLERY') dispatch(updateEntity(eid, { ...entity, previewType: 'METADATA' }));
        if (previewType === 'METADATA') dispatch(updateEntity(eid, { ...entity, previewType: 'GALLERY' }));
    };

    return (
        <div
            className={clsx(styles.topBar, isNotesApp && styles.notesTopBar, isBaseApp && styles.baseEntityTopBar)}
            data-tauri-drag-region
        >
            {isNotesApp ? (
                <span className={styles.notesEntityName} title={entityName}>
                    {entityName}
                </span>
            ) : (
                <span className={styles.entityName} title={entityName}>
                    {entityName}
                </span>
            )}

            <div className={styles.topBarAvatarsMenuBtn}>
                <UserAvatars eid={eid} context="EntityFrame" showIcons={true} />
                {showPreviewTypeToggler && (
                    <Tabs
                        tabsData={previewTypeActions}
                        value={previewType}
                        onClick={togglePreviewType}
                        small
                        noHighlight
                    />
                )}
                <Tooltip
                    key={`c-cm-${eid}`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy'} id={`tooltip-${eid}`}>
                            Options
                        </div>
                    }
                >
                    <span>
                        <ControlMenu eid={eid} entity={entity} name={entity.name} />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

export default memo(EntityTopBar);
