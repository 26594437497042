import { useDrag, useDrop } from 'react-dnd';
import { LISTITEM } from '@/dndTypes';
import React, { ReactElement, useRef, memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedEntities, setDraggingHListItem } from '@/redux/ui/actions';
import {
    getDraggingHListItemSelector,
    getEntityUploadingProgressSelector,
    isTrashPageSelector,
    getIsAllNotesViewSelector,
    getIsBaseOnboardingSelector,
    getHasManySelectedEntitiesSelector,
} from '@/redux/ui/selectors';
import { useFileDrop, useMemoCompare } from '@/hooks/remoteHooks';
import { useEntity } from '@/hooks/reduxHooks';
import styles from './styles.module.scss';
import { getLocalDirectoryTreeSelector } from '@/redux/files/selectors';
import { getDirContent } from '@/redux/files/actions';
import HListItemPresentation from './presentation';
import { useRouter } from 'next/router';
import { getEidFromUri, isElectron, openSingleNoteWindow } from '../../../../utils/helpers';
import { IntegrationName } from '../../../../integrations/types';
import HListBottomDropZone from './HListBottomDropZone';
import { EdgeDropZone, dropFunction, _accept } from './DropZones';
import { INTEGRATIONS } from 'integrations/constants';
import { openLocalFile } from 'integrations/adapters/LocalFilesAdapter/helpers';
import useAppWrapper from '@/hooks/useAppWrapper';
import { isDraftSelector, isSharedSelector } from '@/redux/entities/selectors';
import { getUserSelector } from '@/redux/user/selectors';
import clsx from 'clsx';
import { useMobileQuery } from '@/hooks/useMediaQuery';

const preventDetailsToggle = function (e): void {
    e.preventDefault();
};

interface HListItemProps {
    eid: string;
    entity?: Entity;
    childIndex: number;
    infoMode?: InfoMode;
    passthrough?: EntityPassthroughProps;
    isBrowsed?: boolean;
    isSelected?: boolean;
    isOpen?: boolean;
    isChildOfBrowsed?: boolean;
    isChildOfSelected?: boolean;
    isFocused?: boolean;
    indentLevel?: number;
    parentId?: string;
    handleItemClick?: (id: any, event: MouseEvent) => void;
    overrideClick?: (identity: EntityIdentity, event: MouseEvent) => void;
    toggleListOpen?: (id: string, open: boolean) => void;
    ancestorList?: string[];
    dropEntities?: (item, target, location: string, additionalIndent?: number) => void;
    searchText?: string;
    disableDragDrop?: boolean;
    isIntegrationItem?: boolean;
    app?: IntegrationName;
    appId?: string;
    edgeMaxAdditionalIndent?: number;
    lastItem?: boolean;
    rootEid?: string;
    includeRootInHList?: boolean;
    isChecked?: boolean;
    isAncestorChecked?: boolean;
    setIsChecked?: (value: boolean, ids: string[]) => void;
    isRootAppItem?: boolean;
    appsFolderId?: string;
    // TODO: when app type entities will have their account entities generated in redux, remove appChildrenLength
    appChildrenLength?: number;
    changeHListRoot?: (entity: EntityIdentity) => void;
}

const HListItem = ({
    eid,
    childIndex,
    parentId,
    infoMode,
    isBrowsed,
    isSelected,
    isOpen,
    isChildOfBrowsed,
    isChildOfSelected,
    isFocused,
    indentLevel,
    passthrough,
    handleItemClick,
    overrideClick,
    toggleListOpen,
    ancestorList,
    dropEntities,
    searchText,
    disableDragDrop,
    isIntegrationItem,
    app,
    edgeMaxAdditionalIndent,
    lastItem,
    rootEid,
    includeRootInHList,
    isChecked,
    isAncestorChecked,
    setIsChecked,
    isRootAppItem,
    appsFolderId,
    // TODO: when app type entities will have their account entities generated in redux, remove appChildrenLength
    appChildrenLength,
    changeHListRoot,
    ...rest
}: HListItemProps & React.HTMLAttributes<HTMLDivElement>): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const router = useRouter();
    const entity = useEntity(eid, true, 'HListItem');
    if (!entity) console.log('xxx entity not found', eid);
    const isAppsFolder = appsFolderId === eid;
    const { dsid } = useSelector(getUserSelector);

    const draggingHListItem = useSelector(getDraggingHListItemSelector);
    const tree = useSelector(getLocalDirectoryTreeSelector);
    const handleSiblingFileDrop = useFileDrop(parentId, childIndex);
    const handleChildFileDrop = useFileDrop(eid, entity?.childrenList?.length || 0);
    const memoizedPassthrough = useMemoCompare(passthrough);
    const isIntegrationItemInDataspace = entity?.['@type'] === 'IntegrationItem' && !isIntegrationItem;
    const expandTimeoutId = useRef(null);

    const isTrashPage = useSelector(isTrashPageSelector);
    const uploadProgress = useSelector(getEntityUploadingProgressSelector(eid));
    const isValidUploadProgress = !isNaN(uploadProgress);

    const isDraft = useSelector(isDraftSelector(eid));
    const isShared = useSelector(isSharedSelector(eid));
    const isAllNotesView = useSelector(getIsAllNotesViewSelector);
    const isBaseOnboarding = useSelector(getIsBaseOnboardingSelector);

    const isMobile = useMobileQuery();

    // TODO: when app type entities will have their account entities generated in redux, remove appChildrenLength
    const hasChildren =
        appChildrenLength ||
        eid === 'root' ||
        (entity?.childrenList?.length > 0 && !['compact-flat', false].includes(infoMode)) ||
        (entity?.['@type'] === 'IntegrationItem' &&
            INTEGRATIONS[app]?.isFolder(entity) &&
            entity?.childrenList?.length > 0);
    const isLocalFileViewedFromBrowser = entity?.app === 'localFiles' && !isElectron();
    const isMultiSelection = useSelector(getHasManySelectedEntitiesSelector);
    const { isNotesApp } = useAppWrapper();

    useEffect(() => {
        if (passthrough?.localDirectory && entity?.path) {
            if (!tree[entity?.path]) dispatch(getDirContent(entity?.path));
        }
    }, [passthrough?.localDirectory, entity?.path]);

    // For use in Middle and Edge drop zones
    const _canDrop = useCallback(
        (dropLocation: string) =>
            (item: DnDMovedItem): boolean => {
                // TODO: Needs to be replaced with an ancestorList check for all selectedEntities
                const noInfiniteLoop = !ancestorList?.includes(item.eid);
                return (
                    noInfiniteLoop &&
                    !isSelected &&
                    !disableDragDrop &&
                    !(eid === item.eid && dropLocation === 'middle') &&
                    !(dropLocation === 'middle' && entity?.['@type'] === 'IntegrationItem') &&
                    !(dropLocation === 'middle' && eid === appsFolderId) &&
                    !ancestorList.includes(appsFolderId)
                );
            },
        [],
    );
    const target = { eid, parentId, childIndex, ancestorList, childrenList: entity?.childrenList };

    // Middle drop zone
    const middleDropSettings = () => {
        return {
            accept: _accept,
            canDrop: _canDrop('middle'),
            drop: dropFunction('middle', dropEntities, handleChildFileDrop, target),
            collect: (monitor) => ({
                itemIsHovered: monitor.isOver({ shallow: true }) && monitor.canDrop(),
            }),
        };
    };

    const [{ itemIsHovered }, drop] = useDrop(middleDropSettings());

    // Drag settings
    const [{ isDragging, monitor }, drag] = useDrag({
        type: LISTITEM,
        item: {
            type: LISTITEM,
            eid,
            parentId,
            childIndex,
            additionalTypes: entity?.additionalTypes,
        },
        collect: (monitor) => ({
            monitor: monitor,
            isDragging: monitor.isDragging(),
        }),
        end: (item: DnDMovedItem, monitor) => {
            const res = monitor.getDropResult();
            return { res, item };
        },
        canDrag: (_monitor) => {
            return !(searchText?.length > 0 || disableDragDrop);
        },
    });

    useEffect(() => {
        if (itemIsHovered && !draggingHListItem) dispatch(setDraggingHListItem(true));
        if (hasChildren && itemIsHovered && !expandTimeoutId.current) {
            const timeoutId = setTimeout(expandList, 1000);
            expandTimeoutId.current = timeoutId;
        }
        if (!itemIsHovered && expandTimeoutId.current) {
            clearTimeout(expandTimeoutId.current);
            expandTimeoutId.current = null;
        }
        return () => {
            if (expandTimeoutId.current) clearTimeout(expandTimeoutId.current);
        };
    }, [itemIsHovered]);

    useEffect(() => {
        if (isDragging !== draggingHListItem) dispatch(setDraggingHListItem(isDragging));
        if (isDragging && !isSelected) {
            dispatch(setSelectedEntities([{ id: eid, parentId: parentId }]));
        }
    }, [isDragging]);

    const className = clsx(
        styles.listItem,
        isAllNotesView && styles.listItemLarge,
        infoMode === 'compact-flat' && styles.listItemMedium,
        isDragging && styles.dragging,
        (isLocalFileViewedFromBrowser || isValidUploadProgress || (entity?.['@type'] === 'App' && entity?.disabled)) &&
            styles.disabled,
        isSelected || isChildOfSelected || isBrowsed ? styles.selected : styles.unselected,
        isBrowsed && styles.browsed,
        !isBrowsed && [
            isChildOfBrowsed && !isMultiSelection
                ? styles.browsedChildren
                : isChildOfSelected && styles.selectedChildren,
        ],
        itemIsHovered && styles.hovered,
    );
    const summaryClass = clsx(
        styles.summary,
        'summary',
        `HListItem-${eid}`,
        typeof infoMode === 'string' && styles[infoMode],
        isAllNotesView && styles.summaryLarge,
        isFocused && 'focused',
        passthrough?.localDirectory && styles.localDir,
        isBaseOnboarding && styles.baseOnboardingHListItem,
    );

    drag(ref);

    const childPadding = 24;
    const getPadding = (entity) => {
        if (searchText?.length > 0) return 0;
        if (entity?.['@type'] === 'LocalDirectory' && !eid) {
            // count level of nesting relative to root LocalDirectory path
            const localDirRootPath = entity?.path;
            const localDirNestLevel = passthrough?.localDirItemData?.path
                ?.slice(localDirRootPath?.length)
                ?.split('/')
                ?.filter((f) => f?.length)?.length;
            // Replace domAncestors with entityList check
            return ancestorList?.length > 2
                ? (ancestorList?.length - 1) * childPadding * (indentLevel + localDirNestLevel)
                : (indentLevel + localDirNestLevel) * childPadding;
        }
        return indentLevel * childPadding + (includeRootInHList ? childPadding : 0);
    };

    useEffect(() => {
        if (isBrowsed) {
            const currentEid = getEidFromUri((router.query?.eid as string) ?? '');
            const currentDataspaceId = getEidFromUri((router.query?.dataspaceId as string) ?? dsid ?? '');

            if (passthrough?.updateURLWithBrowsed) {
                if (eid !== currentEid) {
                    // This router.replace does not play nice with Electron Tabs,
                    // it will make every tab to switch to the latest Entity in tab with Finder
                    // disable it for now:
                    //
                    // router.replace(`/entity/${eid}`);
                }
            } else {
                // if dataspace didn't change, avoid router and update url directly
                // that way it does not fire additional events that are ignored in that state (loader in _app.tsx)
                if (currentDataspaceId !== router.query?.dataspaceId) {
                    router.push({
                        query: {
                            ...router.query,
                            eid: currentEid,
                            dataspaceId: currentDataspaceId,
                            b: eid?.substring(0, 8) || '',
                        },
                    });
                } else {
                    const url = new URL(window.location.href);
                    url.searchParams.set('eid', currentEid);
                    url.searchParams.set('b', eid?.substring(0, 8) || '');
                    window.history.pushState(null, '', url.toString());
                }
            }
        }
    }, [isBrowsed, passthrough?.updateURLWithBrowsed]);

    const idAndParentId = {
        id: eid,
        parentId: parentId,
        isIntegrationItem,
        isIntegrationItemInDataspace,
    } as EntityIdentity;
    if (!isIntegrationItemInDataspace) {
        idAndParentId.app = entity?.app;
        idAndParentId.appId = entity?.appId;
    }

    const onCheck = useCallback(() => {
        const value = entity?.childrenList?.length ? [eid, ...entity.childrenList] : [eid];
        setIsChecked(!isChecked, value);
    }, [eid, entity.childrenList, isChecked, setIsChecked]);

    const onClick = useCallback(
        (e): void => {
            e.stopPropagation();
            if (overrideClick) {
                overrideClick(idAndParentId, e);
                return;
            }
            const clickType = e.detail == 1 ? 'single' : 'double';
            if (
                isLocalFileViewedFromBrowser ||
                isValidUploadProgress ||
                (isBaseOnboarding && infoMode !== 'checkbox') ||
                isRootAppItem ||
                entity?.['@type'] === 'App'
            ) {
                return;
            }

            if (entity?.['@type'] === 'HList' && entity?.childrenList?.length && isMobile) {
                changeHListRoot(idAndParentId);
                return;
            }

            if (entity?.app === 'localFiles' && clickType === 'double' && !entity?.childrenList?.length) {
                openLocalFile(entity?.foreignId);
                return;
            }

            if (infoMode === 'checkbox') {
                onCheck();
                return;
            }

            if (isNotesApp && clickType === 'double') {
                openSingleNoteWindow(eid);
                return;
            }
            handleItemClick(idAndParentId, e);
        },
        [
            isLocalFileViewedFromBrowser,
            entity,
            overrideClick,
            isValidUploadProgress,
            onCheck,
            changeHListRoot,
            isMobile,
        ],
    );

    useEffect(() => {
        if (ancestorList?.length && isChecked !== isAncestorChecked) {
            setIsChecked(isAncestorChecked, [eid]);
        }
    }, [isAncestorChecked]);

    const onChipletClick = useCallback(
        (e): void => {
            e.stopPropagation();
            hasChildren && toggleListOpen(eid, !isOpen);
        },
        [hasChildren, isOpen],
    );

    const expandList = useCallback(() => {
        toggleListOpen(eid, true);
        expandTimeoutId.current = null;
    }, []);

    const edgeId = `edge-${rootEid}-${eid}`;

    const lastItemMaxIndentLevel = ancestorList?.includes(appsFolderId) ? 0 : indentLevel;

    return (
        <div
            ref={!isTrashPage ? ref : null}
            data-hlistitemid={eid}
            data-eid={eid}
            onClick={onClick}
            className={className}
            id={eid}
            {...rest}
        >
            {(entity || isIntegrationItem) && (
                <HListItemPresentation
                    drop={drop}
                    eid={eid}
                    parentId={parentId}
                    childIndex={childIndex}
                    open={isOpen}
                    paddingLeft={getPadding(entity)}
                    summaryClass={summaryClass}
                    name={entity?.name}
                    entity={entity}
                    hasChildren={hasChildren}
                    infoMode={infoMode}
                    onClick={onClick}
                    onChipletClick={onChipletClick}
                    preventDetailsToggle={preventDetailsToggle}
                    passthrough={memoizedPassthrough}
                    toggleListOpen={toggleListOpen}
                    searchText={searchText}
                    isIntegrationItem={isIntegrationItem}
                    isIntegrationItemInDataspace={isIntegrationItemInDataspace}
                    isTrashPage={isTrashPage}
                    isLocalFileViewedFromBrowser={isLocalFileViewedFromBrowser}
                    uploadProgress={uploadProgress}
                    isDraft={isDraft}
                    isShared={isShared}
                    isAllNotesView={isAllNotesView}
                    isChecked={isChecked}
                    setIsChecked={onCheck}
                    isAppsFolder={isAppsFolder}
                    isBaseOnboarding={isBaseOnboarding}
                />
            )}
            {/* Edge drop zone (between HListItems, 1 per HlistItem, located above it) */}
            {eid !== rootEid && (
                <EdgeDropZone
                    isTrashPage={isTrashPage}
                    dropEntities={dropEntities}
                    edgeMaxAdditionalIndent={edgeMaxAdditionalIndent}
                    childPadding={childPadding}
                    monitor={monitor}
                    target={target}
                    indentLevel={indentLevel}
                    edgeId={edgeId}
                    draggingHListItem={draggingHListItem}
                    handleFileDrop={handleSiblingFileDrop}
                    _canDrop={_canDrop}
                    includeRootInHList={includeRootInHList}
                />
            )}
            {lastItem && (
                <HListBottomDropZone
                    isTrashPage={isTrashPage}
                    dropEntities={dropEntities}
                    parentId={eid}
                    monitor={monitor}
                    target={target}
                    childPadding={childPadding}
                    edgeMaxAdditionalIndent={lastItemMaxIndentLevel}
                    rootEid={rootEid}
                    draggingHListItem={draggingHListItem}
                    includeRootInHList={includeRootInHList}
                />
            )}
        </div>
    );
};

export default memo(HListItem);
