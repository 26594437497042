import React, { useState, useEffect, useMemo, CSSProperties } from 'react';
import { BASE_DOMAIN, BASE_ORIGIN } from '../../config';

import {
    getEntityIcon,
    getHumanReadableUri,
    getShortcutLabels,
    includesInstance,
    isBase,
    isElectron,
    openSingleNoteWindow,
} from '../../utils/helpers';

import { useDispatch, useSelector } from 'react-redux';
import { clearBrowsed, setCopiedEntities, showGlobalModal, toast } from '../../redux/ui/actions';
import {
    getCopiedEntitiesSelector,
    getFinderSelector,
    getSelectedEntitiesSelector,
    getDataspaceIdsSelector,
    isTrashPageSelector,
} from '../../redux/ui/selectors';
import { useEntities, useEntity } from '../../hooks/reduxHooks';
import { DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID } from '@/redux/constants';

import typeInfo from '../entities/typeInfo';

import styles from './contextMenu.module.scss';
import {
    createEntity,
    bulkRemoveChildEids,
    bulkCreateEntities,
    bulkGroupEntities,
    updateEntityPublicState,
    restoreEntitiesFromTrash,
    saveDrafts,
} from '../../redux/entities/actions';
import { Dropdown } from 'react-bootstrap';
import { getDsidSelector, isDraftSelector } from '../../redux/entities/selectors';
import { noop } from 'lodash';
import Switch from 'react-switch';
import PasteMdIcon from '@/icons/PasteMdIcon';
import CopyEntityIcon from '@/icons/CopyEntityIcon';
import DuplicateIcon from '@/icons/DuplicateIcon';
import CopyLinkIcon from '@/icons/CopyLinkIcon';
import OpenInTabIcon from '@/icons/OpenInTabIcon';
import OpenInWorkspaceIcon from '@/icons/OpenInWorkspaceIcon';
import BedrockDiscoMiniIcon from '@/icons/BedrockDiscoMiniIcon';
import DeleteIcon from '@/icons/DeleteIcon';
import AddChildEntityIcon from '@/icons/AddChildEntityIcon';
import RefreshMdIcon from '@/icons/RefreshMdIcon';
import CloseMdIcon from '@/icons/CloseMdIcon';
import FolderIcon from '@/icons/FolderIcons/FolderIcon';

import { MenuType } from '@/redux/types';
import EmojiPicker from '../forms/emojiPicker';
import TextField from '../forms/textField';
import { addIntegrationItemToDataspace, getIntegrationItems } from '@/redux/integrations/actions';
import AddToDataSpaceIcon from '@/icons/AddToDataSpaceIcon';
import SubMenuItem from '@/components/modals/ContextMenuComponents/SubMenuItem';
import MenuItem from '@/components/modals/ContextMenuComponents/MenuItem';
import { QuickCreateMenu } from '@/components/modals/QuickCreateMenu/QuickCreateMenu';
import { getIsUserAnonymous } from '@/redux/user/selectors';
import { getDsidListIntegrationItemAddedTo, getIntegrationAppLastFetch } from '@/redux/integrations/selectors';
import TimeAgo from 'react-timeago';
import BedrockNotesAppIcon from '@/icons/BedrockNotesAppIcon';
import { useRouter } from 'next/router';
import useAppWrapper from '@/hooks/useAppWrapper';
import { parseUID } from '@/utils/IDManager';

type Props = {
    isVisible: boolean;
    menuParams: MenuType;
    menuRef: React.RefObject<HTMLDivElement>;
    closeMenu: () => void;
    onSelect: (action: (e: any) => void) => (e: any) => void;
    updateSubmenuPositions: (value: CSSProperties) => void;
    subMenuPositionCSS: CSSProperties;
};

export default function ContextMenu({
    menuParams,
    closeMenu,
    menuRef,
    onSelect,
    subMenuPositionCSS,
    updateSubmenuPositions,
    isVisible,
}: Props): React.ReactElement {
    const dispatch = useDispatch();
    const router = useRouter();
    const {
        contextMenuEntity: {
            id: contextId,
            entity: savedContextEntity,
            parentId: contextParentId,
            childIndex,
            isLocalDirectory,
            localDirectoryPath,
            isIntegrationItem,
        },
        x,
        y,
    } = menuParams;
    const entity = useEntity(contextId, true, 'ContextMenu');
    const contextEntity =
        savedContextEntity && entity ? Object.assign({}, savedContextEntity, entity) : entity || savedContextEntity;
    const copiedEntities = useSelector(getCopiedEntitiesSelector);
    const copiedIds = copiedEntities.map((e) => e.id);
    const copiedActualEntities = useEntities(copiedIds, false, 'contextMenu');
    const finder = useSelector(getFinderSelector);
    const isDraft = useSelector(isDraftSelector(contextId));
    const dsid = useSelector(getDsidSelector);
    const dsids = useSelector(getDataspaceIdsSelector);
    const dataspaces = useEntities(dsids, false, 'SaveToDataspaceButton');
    const dataspace = dataspaces.find((ds) => ds?.id === dsid);
    const isTrashPage = useSelector(isTrashPageSelector);
    const isLocalFileViewedFromBrowser = contextEntity?.app === 'localFiles' && !isElectron();
    const userIsAnonymous = useSelector(getIsUserAnonymous);
    const lastFetch = useSelector(getIntegrationAppLastFetch(contextEntity?.app, contextEntity?.appId));
    const isIntegrationRootEntity = contextEntity?.app && contextEntity?.appId && parseUID(contextId).fid === 'root';
    const isAppEntity = contextEntity?.['@type'] === 'App';
    const appsFolderId = dataspace?.appsFolderId;
    const isAppsFolder = appsFolderId === contextId;
    //Uncomment when Paste Mirror is back
    // const copiedAllIdsInTree = useDescendants(copiedIds);
    // const contextLineage = useAncestors(contextId);
    // const blockPasteMirror = copiedAllIdsInTree.some((id) => contextLineage.includes(id));
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const isContextIdInSelectedEntities = includesInstance(selectedEntities, {
        id: contextId,
        parentId: contextParentId,
    });
    const workingEntities = isContextIdInSelectedEntities
        ? selectedEntities
        : [{ id: contextId, parentId: contextParentId }];
    const [isPublic, setIsPublic] = useState(contextEntity?.metadata?.public_entity || false);
    const [isPickerOpened, setPickerOpened] = useState(false);
    const [isRenaming, setIsRenaming] = useState(false);
    const isIntegrationItemOrEntity = contextEntity?.['@type'] === 'IntegrationItem';
    const contextEntityFancyName =
        contextEntity?.['@type'] === 'Finder'
            ? dataspace?.name
            : contextEntity?.name || 'Untitled ' + typeInfo[contextEntity?.['@type']]?.fancyName;

    const dataspacesItegrationItemAddedTo = useSelector(getDsidListIntegrationItemAddedTo(contextId));
    const { isNotesApp } = useAppWrapper();
    useEffect(() => {
        setIsPublic(contextEntity?.metadata?.public_entity || false);
    }, [contextEntity?.metadata?.public_entity]);

    let url = '';
    if (contextEntity) {
        url = isIntegrationItem
            ? `${BASE_ORIGIN}/entity/${contextId}?app=${contextEntity.app}&appId=${contextEntity.appId}`
            : `${BASE_ORIGIN}/entity/${getHumanReadableUri(contextEntity.name, contextId)}`;
    }
    const appurl = `bedrock-app://${BASE_DOMAIN}/entity/${contextId}`;
    const notesAppurl = `bedrock-notes://${BASE_DOMAIN}/notes/note/${contextId}`;
    const workspaceUrl = `${BASE_ORIGIN}/workspace/${contextId}`;

    useEffect(() => {
        if (!contextId) closeMenu();
    }, [contextId, closeMenu]);

    const handleCopy = function (): void {
        navigator.clipboard.writeText(contextId);
        dispatch(setCopiedEntities(workingEntities));
        dispatch(
            toast({
                msgType: 'success',
                text: `Successfully copied ${workingEntities.length > 1 ? 'Entities' : 'Entity'} to clipboard`,
            }),
        );
    };

    const handleCopyLink = function (): void {
        navigator.clipboard.writeText(url);
    };

    const handleCopyEid = function (): void {
        navigator.clipboard.writeText(contextId);
    };

    const handleGroup = function (): void {
        dispatch(bulkGroupEntities(workingEntities, contextParentId, contextId));
    };

    const handleNewTab = function (): void {
        if (window) {
            window.open(url);
        }
    };

    // For testing purposes
    const handleWorkspace = function (): void {
        const onElectron = isElectron();
        if (window && !onElectron) {
            window.open(workspaceUrl);
        } else if (window) {
            router.push(workspaceUrl);
        }
    };

    const handleOpenInApp = function (): void {
        if (window) {
            window.open(appurl, '_self');
        }
    };

    const handleOpenInNotesApp = function (): void {
        if (isNotesApp) {
            openSingleNoteWindow(contextId);
            return;
        }
        if (window) {
            window.open(notesAppurl, '_self');
        }
    };

    const handlePasteDuplicate = async function (): Promise<void> {
        dispatch(bulkCreateEntities({ newEntities: copiedActualEntities, parentId: contextId, isPasted: true }));
    };

    const handleDuplicateSingleEntity = async function (): Promise<void> {
        dispatch(
            bulkCreateEntities({ newEntities: [contextEntity], parentId: contextParentId, index: childIndex + 1 }),
        );
    };

    // const handlePasteMirror = async function (): Promise<void> {
    //     dispatch(bulkCreateEntities({ newEntities: copiedActualEntities, parentId: contextId, isPasted: true }));
    // };

    const handleDelete = function (e): void {
        e.stopPropagation();
        dispatch(bulkRemoveChildEids(workingEntities));
        closeMenu();
    };

    const handleAddLocalDirToBedrock = () => {
        dispatch(
            createEntity({
                type: 'LocalDirectory',
                parentEntity: finder.entity,
                parentId: finder.id,
                entity: { name: contextEntityFancyName, path: localDirectoryPath },
            }),
        );
    };

    const requestLocalFileFromElectron = async () => {
        if (window?.sendToElectron && window?.dirEventsChannelName && localDirectoryPath) {
            window.sendToElectron(window.dirEventsChannelName, {
                action: 'GET_FILE',
                options: { path: localDirectoryPath, destinationEid: finder.id },
            });
        }
    };

    const handleOpenFileLocally = () => {
        const path = localDirectoryPath;
        if (path) {
            window?.sendToElectron(window?.dirEventsChannelName, {
                action: 'OPEN_FILE',
                options: { path },
            });
        }
    };

    const handleRestore = () => {
        dispatch(clearBrowsed());
        dispatch(restoreEntitiesFromTrash(workingEntities));
    };

    const handleDeleteFromTrash = () => {
        dispatch(showGlobalModal('ConfirmHardRemoveModal', { entities: workingEntities }));
    };

    const handleDataspaceClick = (dsid) => () => {
        if (isIntegrationItem) dispatch(addIntegrationItemToDataspace(contextEntity as IntegrationItem, dsid));
        if (isDraft) {
            dispatch(saveDrafts([contextEntity.metadata.id], dsid));
        }
    };

    const handleSyncIntegration = () => dispatch(getIntegrationItems(contextEntity.app, contextEntity.appId));

    const handleRevokeIntegration = () => {
        dispatch(showGlobalModal('ConfirmRevokeIntegrationModal', { entity: contextEntity }));
    };

    const isFinder = contextEntity ? contextEntity['@type'] == 'Finder' : undefined;
    const isSharedDataspace = dsid === SHARED_DATASPACE_ID;

    const shortCutLabels = getShortcutLabels();

    const shouldShowTrashPageControls =
        contextId && workingEntities.length && !isIntegrationItem && !isSharedDataspace && isTrashPage;

    const shouldShowDataspaceSubmenu = useMemo(
        () => contextEntity && (isIntegrationItem || isDraft) && !isIntegrationRootEntity,
        [contextEntity, isDraft, isIntegrationItem, isIntegrationRootEntity],
    );

    // Exclude case adding entity to shared dataspace
    const filteredDataSpaces = dataspaces.filter((ds) => ![DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID].includes(ds?.id));

    const addMenuParams: MenuType = {
        x: x + menuRef?.current?.clientWidth,
        y: y,
        type: 'QuickCreateMenu',
        eid: contextId,
    };
    const items = [
        {
            name: 'Add',
            submenu: true,
            icon: <AddChildEntityIcon />,
            customSubmenu: (
                <QuickCreateMenu
                    menuParams={addMenuParams}
                    menuRef={menuRef}
                    onSelect={onSelect}
                    closeMenu={closeMenu}
                    isVisible={isVisible}
                    subMenuPositionCSS={subMenuPositionCSS}
                    updateSubmenuPositions={updateSubmenuPositions}
                />
            ),
            hotkey: contextEntity?.['@type'] === 'Finder' ? shortCutLabels.AddInScoped : shortCutLabels.Add,
            display:
                contextId &&
                !(isContextIdInSelectedEntities && workingEntities?.length > 1) &&
                !isDraft &&
                !isIntegrationItemOrEntity &&
                !isTrashPage &&
                !userIsAnonymous &&
                !isNotesApp,
        },
        {
            name: 'Add to Dataspace',
            submenu: true,
            icon: <AddToDataSpaceIcon />,
            submenuItems: filteredDataSpaces.map((ds) => ({
                id: ds?.id,
                name: ds?.name,
                checked: dataspacesItegrationItemAddedTo.includes(ds.id),
                onClick: onSelect(handleDataspaceClick(ds.id)),
            })),
            display: shouldShowDataspaceSubmenu,
        },
        {
            name: 'Paste',
            icon: <PasteMdIcon />,
            hotkey: shortCutLabels.Paste,
            onClick: onSelect(handlePasteDuplicate),
            display:
                contextId &&
                !isIntegrationRootEntity &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                copiedEntities.length &&
                !isTrashPage &&
                !isDraft &&
                !userIsAnonymous &&
                !isNotesApp,
        },
        {
            name: 'Copy',
            icon: <CopyEntityIcon />,
            hotkey: shortCutLabels.Copy,
            onClick: onSelect(handleCopy),
            display:
                contextId &&
                !isIntegrationRootEntity &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length > 0 &&
                !isDraft &&
                !isTrashPage &&
                !isNotesApp,
        },
        {
            name: `Duplicate`,
            icon: <DuplicateIcon />,
            onClick: onSelect(handleDuplicateSingleEntity),
            display:
                contextId &&
                !isIntegrationRootEntity &&
                contextParentId &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                (!isContextIdInSelectedEntities || selectedEntities.length === 1) &&
                !isDraft &&
                !isTrashPage &&
                !userIsAnonymous,
        },
        {
            name: 'Group',
            icon: <FolderIcon />,
            hotkey: shortCutLabels.Group,
            onClick: onSelect(handleGroup),
            display:
                contextId &&
                !isIntegrationRootEntity &&
                contextParentId &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length > 0 &&
                !isDraft &&
                !isTrashPage &&
                !userIsAnonymous,
        },
        {
            divider: true,
            display:
                contextId &&
                !isIntegrationRootEntity &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isTrashPage &&
                !userIsAnonymous &&
                !isNotesApp,
        },
        {
            name: `Copy Link`,
            icon: <CopyLinkIcon />,
            hotkey: shortCutLabels.CopyLink,
            onClick: onSelect(handleCopyLink),
            display:
                contextId &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isTrashPage &&
                !isLocalFileViewedFromBrowser &&
                !isIntegrationRootEntity &&
                !isNotesApp,
        },
        {
            name: `Copy EID`,
            icon: <CopyLinkIcon />,
            hotkey: shortCutLabels.CopyLink,
            onClick: onSelect(handleCopyEid),
            display:
                contextId &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isTrashPage &&
                !isLocalFileViewedFromBrowser &&
                !isIntegrationRootEntity &&
                process.env.NODE_ENV === 'development' &&
                !isNotesApp,
        },
        {
            name: 'Open In New Tab',
            icon: <OpenInTabIcon />,
            hotkey: shortCutLabels.OpenInNewTab,
            onClick: onSelect(handleNewTab),
            display:
                contextId &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isTrashPage &&
                !isIntegrationRootEntity &&
                !isLocalFileViewedFromBrowser &&
                !isElectron() &&
                !isNotesApp,
        },
        {
            name: 'Open In Workspace',
            icon: <OpenInWorkspaceIcon />,
            onClick: onSelect(handleWorkspace),
            display:
                contextId &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isIntegrationRootEntity &&
                !isIntegrationItem &&
                !isTrashPage &&
                !isLocalFileViewedFromBrowser &&
                !isNotesApp &&
                !isBase(),
            experimental: <kbd className={'experimental'}>🧪👩‍🔬</kbd>,
        },
        {
            name: 'Open In Bedrock App ↗',
            icon: (
                <span className="appIconMini">
                    <BedrockDiscoMiniIcon />
                </span>
            ),
            onClick: onSelect(handleOpenInApp),
            display:
                !isElectron() &&
                contextId &&
                !isIntegrationRootEntity &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                !isFinder &&
                workingEntities.length === 1 &&
                !isTrashPage &&
                !isNotesApp,
            customStyle: 'itemBase',
        },
        {
            name: isNotesApp ? 'Open In New Window' : 'Open In Notes App ↗',
            icon: (
                <span className="appIconMini">
                    <BedrockNotesAppIcon />
                </span>
            ),
            onClick: onSelect(handleOpenInNotesApp),
            display:
                contextId && workingEntities.length === 1 && !isTrashPage && contextEntity?.['@type'] === 'RichText',
            customStyle: 'itemBase',
        },
        {
            name: 'Delete',
            icon: <DeleteIcon />,
            hotkey: shortCutLabels.Delete,
            onClick: onSelect(handleDelete),
            display:
                contextId &&
                !isIntegrationRootEntity &&
                contextParentId &&
                !isIntegrationItem &&
                !isLocalDirectory &&
                workingEntities.length &&
                !isSharedDataspace &&
                !isTrashPage &&
                !isDraft &&
                !userIsAnonymous,
            customStyle: 'deleteEntity',
        },
        {
            name: 'Restore',
            icon: <RefreshMdIcon />,
            onClick: onSelect(handleRestore),
            display: shouldShowTrashPageControls,
        },
        {
            name: 'Delete Forever',
            icon: <DeleteIcon />,
            onClick: onSelect(handleDeleteFromTrash),
            display: shouldShowTrashPageControls || isDraft,
            customStyle: 'deleteEntity',
        },
        // {
        //     name: `Paste Mirror`,
        //     onClick: hideOnClick(handlePasteMirror),
        //     display: copiedEntities.length && NODE_ENV === 'development',
        //     block: blockPasteMirror,
        // },
        {
            name: 'Open Locally',
            onClick: onSelect(handleOpenFileLocally),
            display: isLocalDirectory && !isIntegrationItem && workingEntities.length && !isTrashPage,
        },
        {
            name: 'Add Directory to Bedrock',
            onClick: onSelect(handleAddLocalDirToBedrock),
            display:
                isLocalDirectory &&
                contextEntity.type === 'directory' &&
                !isIntegrationItem &&
                workingEntities.length &&
                !isTrashPage,
        },
        {
            name: 'Upload File to Bedrock',
            onClick: onSelect(requestLocalFileFromElectron),
            display:
                isLocalDirectory &&
                contextEntity.type === 'file' &&
                !isIntegrationItem &&
                workingEntities.length &&
                !isTrashPage,
        },
        {
            name: 'Re-sync',
            onClick: onSelect(handleSyncIntegration),
            display: isIntegrationRootEntity,
            icon: <RefreshMdIcon />,
        },
        {
            name: 'Revoke',
            onClick: onSelect(handleRevokeIntegration),
            display: isIntegrationRootEntity,
            icon: <CloseMdIcon />,
        },
    ];
    const hasVisibleItems = items.some((i) => i.display);

    useEffect(() => {
        if (shouldShowDataspaceSubmenu) {
            const subMenuVerticalPadding = 8;
            const subMenuVerticalOffset = 50;
            const subMenuListItemHeight = 31;
            const dataspaceListHeight = dataspaces?.length * subMenuListItemHeight;

            const shouldInvertVerticalPosition =
                y > window?.innerHeight - (subMenuVerticalPadding * 2 + dataspaceListHeight + subMenuVerticalOffset);

            updateSubmenuPositions({
                ...(shouldInvertVerticalPosition && { bottom: `-${subMenuVerticalPadding}px`, top: 'unset' }),
                maxHeight: `${subMenuListItemHeight * 10 + subMenuVerticalPadding * 2}px`,
                overflowY: 'auto',
            });
        }
    }, [dataspaces?.length, menuRef, x, y, shouldShowDataspaceSubmenu]);

    useEffect(() => {
        if (!hasVisibleItems && !isIntegrationItem) {
            closeMenu();
        }
    }, [hasVisibleItems, isIntegrationItem, closeMenu]);

    // exclude dividers and not displayed items for navigation with keyboard arrows
    const filteredContextMenuItems = items.filter((item) => item.display && !item?.divider);

    const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
    const [currentSelectedSubmenuItem, setCurrentSelectedSubmenuItem] = useState(null);
    const [currentSubmenuList, setCurrentSubmenuList] = useState(null);
    const [customSubmenuOpened, setCustomSubmenuOpened] = useState(false);

    useEffect(() => {
        if (!customSubmenuOpened && !isRenaming) {
            const handleKeyDown = (e) => {
                switch (e.key) {
                    case 'ArrowDown':
                        if (!currentSelectedItem) {
                            setCurrentSelectedItem(filteredContextMenuItems[0].name);
                        } else if (currentSubmenuList) {
                            const prevIndex = currentSubmenuList.findIndex(
                                (item) => item.id === currentSelectedSubmenuItem,
                            );
                            const newIndex = prevIndex === currentSubmenuList.length - 1 ? 0 : prevIndex + 1;
                            setCurrentSelectedSubmenuItem(currentSubmenuList[newIndex].id);
                        } else {
                            const prevIndex = filteredContextMenuItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            const newIndex = prevIndex === filteredContextMenuItems.length - 1 ? 0 : prevIndex + 1;
                            setCurrentSelectedItem(filteredContextMenuItems[newIndex].name);
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowUp':
                        if (!currentSelectedItem) {
                            setCurrentSelectedItem(filteredContextMenuItems[0].name);
                        } else if (currentSubmenuList) {
                            const prevIndex = currentSubmenuList.findIndex(
                                (item) => item.id === currentSelectedSubmenuItem,
                            );
                            const newIndex = prevIndex === 0 ? currentSubmenuList.length - 1 : prevIndex - 1;
                            setCurrentSelectedSubmenuItem(currentSubmenuList[newIndex].id);
                        } else {
                            const prevIndex = filteredContextMenuItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            const newIndex = prevIndex === 0 ? filteredContextMenuItems.length - 1 : prevIndex - 1;
                            setCurrentSelectedItem(filteredContextMenuItems[newIndex].name);
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowRight':
                        if (currentSelectedItem && !currentSelectedSubmenuItem) {
                            const currentIndex = filteredContextMenuItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            if (filteredContextMenuItems[currentIndex]?.submenu) {
                                if (filteredContextMenuItems[currentIndex]?.submenuItems) {
                                    setCurrentSubmenuList(filteredContextMenuItems[currentIndex]?.submenuItems);
                                    setCurrentSelectedSubmenuItem(
                                        filteredContextMenuItems[currentIndex]?.submenuItems[0].id,
                                    );
                                } else {
                                    setCustomSubmenuOpened(true);
                                }
                            }
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowLeft':
                        if (currentSelectedSubmenuItem) {
                            setCurrentSubmenuList(null);
                            setCurrentSelectedSubmenuItem(null);
                            setCustomSubmenuOpened(false);
                        }
                        e.preventDefault();
                        break;
                    case 'Enter':
                        if (currentSelectedSubmenuItem) {
                            // need to imitate click so that ui feedback on select works properly
                            const targetElement = document.querySelector(
                                `[data-action-item-id="${currentSelectedSubmenuItem}"]`,
                            ) as HTMLElement;
                            targetElement.click();
                        } else if (currentSelectedItem) {
                            // need to imitate click so that ui feedback on select works properly
                            const targetElement = document.querySelector(
                                `[data-action-name="${currentSelectedItem}"]`,
                            ) as HTMLElement;
                            targetElement.click();
                        }
                        e.preventDefault();
                        break;
                    case 'Escape':
                        closeMenu();
                        e.preventDefault();
                        break;
                    default:
                        break;
                }
            };
            document.addEventListener('keydown', handleKeyDown);

            return () => document.removeEventListener('keydown', handleKeyDown);
        }
    }, [
        currentSelectedItem,
        currentSelectedSubmenuItem,
        currentSubmenuList,
        customSubmenuOpened,
        isRenaming,
        closeMenu,
    ]);

    const clickStyle = `.file-list [class="${contextId}"] > .entity {
        border: 2px solid #5595F6;
        border-radius: 3px;
    };`;

    const togglePublic = function (): void {
        setIsPublic(!isPublic);
        dispatch(updateEntityPublicState(contextId, !isPublic));
    };

    const openShareDialog = async () => {
        dispatch(showGlobalModal('ShareEntityModal', { entity: contextEntity }));
    };

    const shouldShowShareOption = useMemo(() => {
        return selectedEntities.length <= 1 || !selectedEntities.some((entity) => entity.id === contextId);
    }, [selectedEntities, contextId]);

    let icon;
    if (contextEntity) {
        icon = getEntityIcon(contextEntity, { isIntegrationItem: isIntegrationItemOrEntity });
    }

    const canRenameEntity =
        !isTrashPage &&
        !isIntegrationItemOrEntity &&
        !userIsAnonymous &&
        contextEntity?.['@type'] !== 'Finder' &&
        !isAppEntity &&
        !isAppsFolder;

    return (
        <>
            {workingEntities?.length > 1 && (
                <li key={'count'} className={`${styles.item} ${styles.noHover}`}>
                    <span className={styles.contextMenuSubheader}>{workingEntities.length} items</span>
                </li>
            )}
            {workingEntities?.length === 1 && contextEntityFancyName && !isIntegrationRootEntity && (
                <li key={'name'} className={`${styles.item} ${styles.noHover}`}>
                    <div
                        className={`${styles.itemIcon} ${!isIntegrationItemOrEntity ? styles.pointer : ''} ${
                            styles.allowEvents
                        }`}
                        onClick={(): void =>
                            !isTrashPage &&
                            !userIsAnonymous &&
                            !isIntegrationItemOrEntity &&
                            !isAppsFolder &&
                            !isAppEntity &&
                            setPickerOpened(true)
                        }
                    >
                        {icon}
                    </div>
                    <TextField
                        eid={contextEntity?.metadata?.id}
                        propertyKey={'name'}
                        placeholder={contextEntityFancyName}
                        canRename={canRenameEntity}
                        onActiveCallback={setIsRenaming}
                        className={`${styles.contextMenuSubheader} ${
                            !isIntegrationItemOrEntity && !isRenaming ? styles.pointer : ''
                        } ${styles.allowEvents} ${canRenameEntity ? styles.inputHover : ''}`}
                    />
                </li>
            )}
            {isIntegrationRootEntity && (
                <li className={`${styles.item} ${styles.noHover}`}>
                    <div className={styles.contextMenuSubheader}>
                        {lastFetch ? (
                            <>
                                Synced <TimeAgo date={lastFetch} />
                            </>
                        ) : (
                            <>Not Synced</>
                        )}
                    </div>
                </li>
            )}
            <Dropdown.Divider className={styles.divider} />

            {contextEntity?.['@type'] !== 'Finder' ? (
                <EmojiPicker
                    eid={contextId}
                    entity={contextEntity}
                    propertyKey={'emoji'}
                    isPickerOpened={isPickerOpened}
                    setPickerOpened={setPickerOpened}
                />
            ) : null}
            <div onMouseLeave={() => setCurrentSelectedItem(null)}>
                {items.map((item, idx) => {
                    if (item.display) {
                        if (item.divider) {
                            return <Dropdown.Divider key={'divider-' + idx} className={styles.divider} />;
                        } else if (item.submenu) {
                            return (
                                <SubMenuItem
                                    key={item?.name + idx}
                                    item={item}
                                    currentSelectedItem={currentSelectedItem}
                                    setCurrentSelectedItem={setCurrentSelectedItem}
                                    setCurrentSelectedSubmenuItem={setCurrentSelectedSubmenuItem}
                                    setCurrentSubmenuList={setCurrentSubmenuList}
                                    currentSubmenuList={currentSubmenuList}
                                    currentSelectedSubmenuItem={currentSelectedSubmenuItem}
                                    customSubmenuOpened={customSubmenuOpened}
                                    setCustomSubmenuOpened={setCustomSubmenuOpened}
                                    subMenuPositionCSS={subMenuPositionCSS}
                                />
                            );
                        } else {
                            return (
                                <MenuItem
                                    key={item.name + idx}
                                    item={item}
                                    currentSelectedItem={currentSelectedItem}
                                    setCurrentSelectedItem={setCurrentSelectedItem}
                                />
                            );
                        }
                    }
                })}
            </div>

            {contextId &&
            contextId !== finder?.id &&
            !isTrashPage &&
            !isDraft &&
            !isLocalFileViewedFromBrowser &&
            !userIsAnonymous &&
            !isAppEntity &&
            !isAppsFolder &&
            dsid === contextEntity?.metadata?.dsid ? (
                <div id="shared-section">
                    <Dropdown.Divider className={styles.divider} />
                    <Dropdown.Item className={styles.itemNoHover} onMouseDown={togglePublic}>
                        <span>Make public</span>
                        <Switch
                            checked={isPublic}
                            onChange={() => noop()}
                            className={styles.switch}
                            onColor="#00A0F7"
                            onHandleColor="#fff"
                            uncheckedIcon={false}
                            checkedIcon={false}
                            id="shared-switch"
                            handleDiameter={20}
                            height={22}
                            width={40}
                            aria-label="Make public"
                        />
                    </Dropdown.Item>
                    {shouldShowShareOption && !isLocalFileViewedFromBrowser && (
                        <li onClick={onSelect(openShareDialog)} className={styles.item}>
                            <span className={styles.itemLabel}>Share</span>
                        </li>
                    )}
                </div>
            ) : null}
            <style>{clickStyle}</style>
        </>
    );
}
