import React from 'react';
import ButtonStyles from './BedrockButtonStyles.module.scss';

interface ButtonProps extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'onClick'> {
    size?: 's' | 'm' | 'l';
    variant?: 'plain' | 'primary' | 'secondary' | 'tertiary' | 'secondaryBlue';
    icon?: () => JSX.Element;
    iconClass?: string;
    className?: string;
    disabled?: boolean;
    forwardRef?: React.Ref<HTMLButtonElement>;
    type?: 'button' | 'submit' | 'reset';
    textAlign?: 'center' | 'end' | 'start';
    onClick?: (event: any) => void;
    customButtonStyle?: React.CSSProperties;
    active?: boolean;
}

const Button = ({
    size = 'm',
    variant = 'primary',
    textAlign = 'center',
    disabled,
    active,
    type = 'button',
    icon: Icon,
    iconClass,
    className,
    children,
    forwardRef,
    onClick,
    customButtonStyle,
    ...rest
}: React.PropsWithChildren<ButtonProps>): JSX.Element => {
    const buttonClasses = [ButtonStyles[variant], ButtonStyles[size]];
    if (!children && Icon) buttonClasses.push(ButtonStyles.iconOnly);
    if (disabled) buttonClasses.push(ButtonStyles.disabled);
    if (active) buttonClasses.push(ButtonStyles.active);
    const buttonClassesString = `${buttonClasses.join(' ')} ${className}`;

    return (
        <button
            className={buttonClassesString}
            disabled={disabled}
            type={type}
            ref={forwardRef}
            onClick={onClick}
            style={customButtonStyle}
            {...rest}
        >
            {Icon &&
                (iconClass ? (
                    <div className={iconClass}>
                        <Icon />
                    </div>
                ) : (
                    <Icon />
                ))}
            {children && <span className={`${ButtonStyles.text} ${ButtonStyles[textAlign]}`}>{children}</span>}
        </button>
    );
};

export default Button;
