import { all, takeLatest, takeEvery, put, call, select } from 'redux-saga/effects';
import { getUserSelector } from '../user/selectors';
import { cloneDeep, find, findIndex, isEqual, uniqBy } from 'lodash';
import {
    getDsidSelector,
    getFullEntitySelector,
    getAllFullEntitiesSelector,
    getAllCondensedEntitiesSelector,
    getDeletedCondensedEntitySelector,
    getCondensedEntitiesSelector,
    getCondensedEntitySelector,
} from '../entities/selectors';
import {
    CREATE_ENTITY,
    CREATE_ENTITY_AND_UPDATE_PARENT,
    GET_ENTITY,
    GET_ENTITIES,
    INITIAL_LOAD,
    UPDATE_ENTITY,
    CREATE_DATASPACE,
    ADD_MEMBERS,
    GET_ALL_COLLABORATORS,
    GET_RECENT_ENTITIES,
    REMOVE_CHILD_EID,
    BULK_INSERT_ENTITIES,
    BULK_CREATE_ENTITIES,
    BULK_UPDATE_ENTITIES,
    BULK_MOVE_ENTITIES,
    BULK_REMOVE_CHILD_EIDS,
    SET_ENTITIES,
    CREATE_SCHEMA_FROM_OBJECT,
    BULK_GROUP_ENTITIES,
    SAVE_DRAFTS,
    HARD_DELETE_ENTITIES,
    UPDATE_USER,
    UPDATE_ENTITIES_PUBLIC_STATE,
    RESTORE_ENTITIES_FROM_TRASH,
    EMPTY_TRASH,
    GET_PUBLIC_ENTITY_DESCENDANTS,
    ADD_APP,
    REMOVE_APP,
} from '../actionTypes';

import {
    createEntitySuccess,
    createEntityFailure,
    setEntitiesSuccess,
    setEntitiesFailure,
    getEntitySuccess,
    getEntityFailure,
    getEntitiesSuccess,
    getEntitiesFailure,
    getRecentEntitiesSuccess,
    getRecentEntitiesFailure,
    initialLoadSuccess,
    initialLoadFailure,
    updateEntitySuccess,
    updateEntityFailure,
    createDataspaceSuccess,
    createDataspaceFailure,
    addMembersSuccess,
    addMembersFailure,
    getAllCollaboratorsSuccess,
    getAllCollaboratorsFailure,
    removeChildEidSuccess,
    removeChildEidFailure,
    createSchemaFromObjectFailure,
    createSchemaFromObjectSuccess,
    bulkCreateEntitiesSuccess,
    bulkCreateEntitiesFailure,
    bulkUpdateEntitiesSuccess,
    bulkUpdateEntitiesFailure,
    bulkMoveEntitiesSuccess,
    bulkMoveEntitiesFailure,
    bulkGroupEntitiesSuccess,
    bulkGroupEntitiesFailure,
    bulkRemoveChildEidsFailure,
    bulkRemoveChildEidsSuccess,
    presetEntities,
    undoPresetEntities,
    saveDraftsSuccess,
    saveDraftsFailure,
    setEntities,
    getEntities,
    updateUserSuccess,
    updateUserFailure,
    updateEntityPublicStateSuccess,
    updateEntityPublicStateFailure,
    restoreEntitiesFromTrashSuccess,
    restoreEntitiesFromTrashFailure,
    hardDeleteEntitiesSuccess,
    hardDeleteEntitiesFailure,
    emptyTrashFailure,
    getPublicEntityDescendantsFailure,
    getPublicEntityDescendantsSuccess,
    presetCondensedEntities,
    restoreEntitiesFromTrash,
    undoPresetCondensedEntities,
    addAppToDataspaceFailure,
    removeAppFromDataspaceFailure,
} from './actions';

import { callback, fallback } from '../callbacks';

import genericEntitySkeleton from '../../components/entities/entitySkeleton';
import typeInfo from '../../components/entities/typeInfo';
import {
    cloneEntity,
    prepareNewDataspaceAndAssociatedEntities,
    addEidsToChildrenListAndClone,
    isEid,
    asyncGQL,
    checkPresetEnabled,
    buildEntityUpdatePairs,
    purifyEntity,
    extractParentEntitiesFromRawEntityArray,
    removeChildrenFromParentChildrenList,
    findNonChildEntities,
    getAllDescendantEids,
    buildChildrenListUpdatePairs,
    buildCondensedEntity,
    buildEntity,
    replaceChildId,
    removeChildrenIdFromParents,
} from '@/utils/helpers';

import { getPublicEntityDescendants } from '@/utils/apiHelpers';

import gqlRequest from '../api';
import { v4 as uuidv4 } from 'uuid';
import { filter, map, uniq } from 'lodash';
import {
    getBrowsedInstanceSelector,
    getFinderEntityByDsidSelector,
    getFinderSelector,
    getUIStateSelector,
} from '../ui/selectors';
import {
    setSelectedEntities,
    clearBrowsed,
    setBrowsed,
    toast,
    clearSelectedEntities,
    toggleExpandedHListItems,
} from '../ui/actions';
import { new_GET_ENTITY, GET_ENTITIES as new_GET_ENTITIES } from '../../queries';
import { MessageType } from '../../types/toasts';
import { getCachedIntegrationData } from '@/redux/integrations/actions';
import { setUI } from '../ui/actions';
import { getAllExpandedHListItemsSelector } from '../ui/selectors';
import { parseUID } from '@/utils/IDManager';

export function* createEntitySaga(action) {
    const rebuildEntityList = true;
    const skeleton = genericEntitySkeleton();
    const entityType = action.payload.type;
    const parentId = action.payload.parentId; //optional
    const oldParentEntity = yield select(getCondensedEntitySelector(parentId));
    const parentEntity = { ...oldParentEntity };
    const fallbackParentEntity = cloneDeep(parentEntity);

    const user = yield select(getUserSelector);
    const dsid = yield select(getDsidSelector);
    let dataspaceId;
    if (action.payload?.draft) {
        dataspaceId = null;
    } else {
        dataspaceId = dsid;
    }
    const owner = user?.id;
    let entity = action.payload.entity || ({} as Entity);
    const id = action.payload.id || uuidv4();
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;

    try {
        entity = Object.assign(cloneEntity(skeleton), typeInfo[entityType]?.skeleton, entity) as Entity;
        parentEntity.childrenList = parentEntity?.childrenList || [];
        parentEntity.childrenList.push(id);

        if (parentId && parentEntity) {
            action.queryType = CREATE_ENTITY_AND_UPDATE_PARENT;
            action.payload.nextQueryVariables = {
                id,
                entity,
                owner,
                dataspaceId,
                parentId,
                updatedChildrenList: parentEntity.childrenList,
            };
        } else {
            action.queryType = CREATE_ENTITY;
            action.payload.nextQueryVariables = { id, entity, owner, dataspaceId };
        }

        // --- Pre-apply entity transformations to redux store before API call.
        // --- Will not be done if there is a callback with no way to fallback.
        // --- presetEntities & undoPresetEntities both take an array of form [{id, entity}]
        if (presetEnabled) {
            const nextEntities = [{ id, entity, owned_by: owner, dsid: dataspaceId }];
            if (parentId && parentEntity)
                nextEntities.push({ id: parentId, entity: parentEntity, owned_by: owner, dsid });
            yield put(presetEntities(nextEntities, rebuildEntityList));
            if (action.payload.callback) yield put(callback(action.payload.callback, nextEntities[0]));
            presetSuccess = true;
        }

        const response = yield call(gqlRequest, action);
        yield put(createEntitySuccess(response.data, !presetEnabled && rebuildEntityList, owner));
        if (!presetEnabled && action.payload.callback)
            yield put(callback(action.payload.callback, response.data?.insert_Entity_special.insertedEntity));
    } catch (err) {
        // --- Undo preset if API call failed
        if (presetEnabled && presetSuccess) {
            const previousEntities = [];
            if (parentId && fallbackParentEntity) previousEntities.push({ id: parentId, entity: fallbackParentEntity });
            yield put(undoPresetEntities(previousEntities, rebuildEntityList));
            if (action.payload.fallback) yield put(fallback(action.payload.fallback));
        }

        yield put(createEntityFailure({ error: err }));
    }
}

export function* createSchemaFromObjectSaga(action) {
    const { blobEntity, blobId, schemaName } = action.payload;
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;
    const fallbackEntities = [{ id: blobId, entity: Object.assign({}, blobEntity) }];

    try {
        const dataspaceId = yield select(getDsidSelector);
        const newSchemaFields = [];
        blobEntity.fields.forEach((field): void => {
            const { id, name, fieldType } = field;
            newSchemaFields.push({ id, name, fieldType });
        });

        const skeleton = genericEntitySkeleton();
        const newSchemaId = uuidv4();
        const newSchemaEntity = Object.assign(cloneEntity(skeleton), typeInfo['AdditionalType'].skeleton, {
            name: schemaName,
            fields: newSchemaFields,
        }) as Entity;
        fallbackEntities.push({ id: newSchemaId, entity: null });

        const newBlobEntity = cloneEntity(blobEntity);
        newBlobEntity.additionalTypes = [newSchemaId];
        if (!newBlobEntity.typedFields) {
            newBlobEntity.typedFields = {
                [newSchemaId]: {},
            };
        }
        const newFields = {};
        blobEntity.fields.map((field) => (newFields[field.id] = field.value));
        newBlobEntity.typedFields[newSchemaId] = {
            ...newBlobEntity.typedFields[newSchemaId],
            ...newFields,
        };
        newBlobEntity.fields = [];
        action.payload.nextQueryVariables = {
            newSchemaId,
            newSchemaEntity,
            blobId,
            blobEntity: newBlobEntity,
            dataspaceId,
        };

        const newEntities = [
            { id: newSchemaId, entity: newSchemaEntity },
            { id: blobId, entity: newBlobEntity },
        ];
        if (presetEnabled) {
            yield put(presetEntities(newEntities));
            presetSuccess = true;
        }

        const response = yield call(gqlRequest, action);
        const entities = [response.data.insert_Entity_special.insertedEntity, response.data.update_Entities_by_pk];
        yield put(createSchemaFromObjectSuccess(entities));
    } catch (e) {
        if (presetEnabled && presetSuccess) yield put(undoPresetEntities(fallbackEntities));

        yield put(createSchemaFromObjectFailure(e));
    }
}

export function* setEntitiesSaga(action) {
    try {
        const condensedEntities = yield select(getAllCondensedEntitiesSelector);
        // const browsedInstance = yield select(getBrowsedInstanceSelector);
        const newEntities = uniqBy(
            action.payload.entities.map((e) => {
                e.id = e.entity_id || e.id;
                return e;
            }),
            // .filter((e) => {
            //     return e.id !== browsedInstance?.id;
            // }),
            'id',
        );
        const rebuildEntityList = findIndex(newEntities, (e: any) => {
            return (
                !condensedEntities[e.id] ||
                !isEqual(e.entity?.childrenList, condensedEntities[e.id]?.childrenList) ||
                !isEqual(e.deleted, condensedEntities[e.id]?.deleted)
            );
        });
        const { id: userId } = yield select(getUserSelector);
        yield put(setEntitiesSuccess(newEntities, rebuildEntityList > -1, userId));
    } catch (err) {
        yield put(setEntitiesFailure({ error: err }));
    }
}

export function* getEntitySaga(action) {
    try {
        if (action.payload.id?.length > 0) {
            action.payload.nextQueryVariables = { id: action.payload.id };
            const response = yield call(gqlRequest, action);
            // console.log('GET entity response', response);
            const entities = response.data.Entities;
            // Since graphQL skips ids that aren't in the db, we compare the results to the query to see if the id failed.
            const invalidIds = entities[0]?.id !== action.payload.id ? [action.payload.id] : [];
            yield put(getEntitySuccess(response.data.Entities, invalidIds as string[]));
        }
    } catch (err) {
        yield put(getEntityFailure({ error: err }, action.payload.id));
    }
}

export function* getEntitiesSaga(action) {
    action.queryType = GET_ENTITIES;
    const ids = filter(action.payload.ids, (id) => isEid(id));
    try {
        if (ids?.length > 0) {
            action.payload.nextQueryVariables = { ids };
            const response = yield call(gqlRequest, action);
            // console.log('GET entities response', response);
            const entities = response.data.Entities;
            // Since graphQL skips ids that aren't in the db, we compare the results to the query to see which ids failed.
            const invalidIds = filter(ids, (id) => {
                !entities?.some((e) => {
                    e.id === id;
                });
            });
            yield put(getEntitiesSuccess(response.data.Entities, invalidIds as string[]));
        }
    } catch (err) {
        yield put(getEntitiesFailure({ error: err }, action.payload.ids));
    }
}

export function* hardDeleteEntitiesSaga(action) {
    const { entities } = action.payload;

    const condensedEntities = yield select(getAllCondensedEntitiesSelector);
    const deletedEntities = yield select(getDeletedCondensedEntitySelector);
    const entitiesToDelete = entities.map((entity) => condensedEntities[entity.id]);
    const { entityIdsToDelete, nonFinderParentIds } = entities.reduce(
        (acc, entity) => {
            const parent = condensedEntities[entity.parentId];
            if (parent?.['@type'] !== 'Finder' && !acc.nonFinderParentIds.includes(entity.parentId)) {
                acc.nonFinderParentIds.push(entity.parentId);
            }
            acc.entityIdsToDelete.push(entity.id);
            return acc;
        },
        { entityIdsToDelete: [], nonFinderParentIds: [] },
    );
    const entityIdsToDeleteWithChildren = [...entityIdsToDelete];

    try {
        // building delete list in case if deleting entity has children, must delete them as well
        const fillListWithChildren = (entityIds) => {
            entityIds.forEach((id) => {
                if (condensedEntities[id]) {
                    entityIdsToDeleteWithChildren.push(id);
                    if (deletedEntities?.[id]?.childrenList?.length) {
                        const childrenEntities = deletedEntities[id].childrenList;
                        fillListWithChildren(childrenEntities);
                    }
                }
            });
        };

        entitiesToDelete.forEach((entity) => {
            if (entity?.childrenList?.length > 0) {
                fillListWithChildren(entity.childrenList);
            }
        });

        // make request to hard delete all entities in entityIdsToDeleteWithChildren
        action.payload.nextQueryVariables = { ids: entityIdsToDeleteWithChildren };
        const deletedEntitiesResponse = yield call(gqlRequest, action);

        let updatedParentEntitiesResponse = null;

        // check if there's a need to update children list of parent entities,
        // e.g. folder in trash page contains item that was deleted
        if (nonFinderParentIds.length) {
            // get affected parent entities
            const getAffectedParentsResponse = yield asyncGQL(new_GET_ENTITIES, { ids: nonFinderParentIds }, false);

            // clone and build new childrenList for affected parents
            const affectedParentEntities = getAffectedParentsResponse?.data?.Entities.map((parent) => ({
                id: parent.id,
                entity: cloneEntity(parent.entity),
            }));
            const parentsUpdates = removeChildrenFromParentChildrenList(entities, affectedParentEntities);

            // make request to update affected parents
            action.queryType = BULK_UPDATE_ENTITIES;
            action.payload.nextQueryVariables = { updates: buildEntityUpdatePairs(parentsUpdates) };
            updatedParentEntitiesResponse = yield call(gqlRequest, action);
        }

        const deletedEids = deletedEntitiesResponse.data.delete_Entities.returning.map((e) => e.id);
        const updatedParents = updatedParentEntitiesResponse?.data.update_Entities_many.flatMap((e) => e.returning);

        const toastText = `Successfully deleted Entity ${
            entitiesToDelete.length > 1
                ? `${entitiesToDelete.length} Entities`
                : entitiesToDelete[0]?.name || `Untitled ${typeInfo[entitiesToDelete[0]['@type']].fancyName}`
        }`;
        const toastMessage = {
            text: toastText,
            msgType: MessageType.SUCCESS,
        };
        const { id: userId } = yield select(getUserSelector);
        yield put(clearSelectedEntities());
        yield put(hardDeleteEntitiesSuccess(deletedEids, updatedParents, userId));
        yield put(toast(toastMessage));
    } catch (err) {
        yield put(hardDeleteEntitiesFailure({ error: err }));
    }
}

export function* emptyTrashSaga(action) {
    const deletedCondensedEntities = yield select(getDeletedCondensedEntitySelector);

    const deletedEntityIds = Object.keys(deletedCondensedEntities);

    try {
        action.queryType = HARD_DELETE_ENTITIES;
        action.payload.nextQueryVariables = { ids: deletedEntityIds };
        const deletedEntitiesResponse = yield call(gqlRequest, action);

        const deletedEids = deletedEntitiesResponse.data.delete_Entities.returning.map((e) => e.id);

        yield put(clearSelectedEntities());
        yield put(hardDeleteEntitiesSuccess(deletedEids));
        yield put(toast({ text: 'Successfully deleted all Entities', msgType: MessageType.SUCCESS }));
    } catch (error) {
        yield put(emptyTrashFailure(error));
    }
}

export function* getAllCollaboratorsSaga(action) {
    try {
        const user = yield select(getUserSelector);
        const memberships = Object.keys(user?.memberships);
        action.payload.memberships = memberships;
        const response = yield call(gqlRequest, action);
        // console.log('GET all people response', response);
        yield put(getAllCollaboratorsSuccess(response.data.people));
    } catch (err) {
        yield put(getAllCollaboratorsFailure({ error: err }));
    }
}

export function* getRecentEntitiesSaga(action) {
    // const user = yield select(getUserSelector);
    const dataspaceId = yield select(getDsidSelector);
    action.payload.dataspaceId = dataspaceId;
    // action.queryType = GET_RECENT_ENTITIES;
    try {
        const response = yield call(gqlRequest, action);
        // console.log('GET recentEntities response', response);
        yield put(getRecentEntitiesSuccess(response.data.condensedEntities));
    } catch (err) {
        yield put(getRecentEntitiesFailure({ error: err }));
    }
}

export function* initialLoadSaga(action) {
    const user = yield select(getUserSelector);
    const userId = user?.id;
    const dsid = user?.dsid;
    const memberships = Object.keys(user?.memberships);
    if (memberships?.length > 0) {
        action.payload.owner = userId; // type uuid
        action.payload.memberships = memberships;
        try {
            const response = yield call(gqlRequest, action);
            const confirmedDataspaces = response.data.dataspaces;
            const dsids = map(confirmedDataspaces, 'id');
            const finders = response.data.finders;
            const confirmedFinders = filter(finders, (f) => {
                return dsids.includes(f.dsid);
            });
            const { condensedEntities, additionalTypes, draftCondensedEntities, sharedCondensedEntities } =
                response.data;
            // TEMP: Get all members across all users' dataspaces
            const membersToFetch = [];
            confirmedDataspaces.map((ds) => {
                membersToFetch.push(...Object.keys(ds.entity?.members));
            });

            // let idsToFetch = confirmedFinders[0].entity.childrenList; // Finder list entities
            if (userId) {
                yield put(getCachedIntegrationData(userId));
            }
            yield put(
                initialLoadSuccess(
                    confirmedFinders,
                    confirmedDataspaces,
                    membersToFetch,
                    condensedEntities,
                    draftCondensedEntities,
                    sharedCondensedEntities,
                    additionalTypes,
                    dsid,
                    userId,
                ),
            );
            yield put(getEntities(uniq(membersToFetch), 'initialLoadSaga'));
        } catch (err) {
            yield put(initialLoadFailure({ error: err }));
        }
    }
}

export function* updateEntitySaga(action) {
    const id = action.payload.id;
    const updatedEntity = action.payload.updatedEntity;
    let fallbackEntity = yield select(getFullEntitySelector(id));
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;

    try {
        action.payload.nextQueryVariables = { id, entity: updatedEntity };

        if (presetEnabled) {
            const nextEntities = [{ id, entity: updatedEntity }];
            yield put(presetEntities(nextEntities, true));
            if (action.payload.callback) yield put(callback(action.payload.callback, nextEntities[0]));
            presetSuccess = true;
        }

        if (!fallbackEntity) {
            const fetchedEntity = yield asyncGQL(new_GET_ENTITY, { id });
            fallbackEntity = Object.assign({}, fetchedEntity?.data?.Entities[0]?.entity);
        }
        const response = yield call(gqlRequest, action);
        yield put(updateEntitySuccess(response.data.update_Entities.returning));
        if (!presetEnabled && action.payload.callback)
            yield put(callback(action.payload.callback, response.data.update_Entities.returning?.[0]));
    } catch (err) {
        if (presetEnabled && presetSuccess) {
            const previousEntities = [{ id, entity: fallbackEntity }];
            yield put(undoPresetEntities(previousEntities));
            if (action.payload.fallback) yield put(fallback(action.payload.fallback));
        }

        yield put(updateEntityFailure({ error: err }));
    }
}

export function* updateEntitiesPublicStateSaga(action) {
    const { id, publicState } = action.payload;
    const condensedEntities = yield select(getAllCondensedEntitiesSelector);
    const currentEntity = condensedEntities[id];

    const affectedEntityIds = [id];

    const fillListWithDescendants = (entityIds) => {
        entityIds.forEach((id) => {
            if (condensedEntities[id]) {
                affectedEntityIds.push(id);
                if (condensedEntities[id].childrenList?.length) {
                    const childrenEntities = condensedEntities[id].childrenList;
                    fillListWithDescendants(childrenEntities);
                }
            }
        });
    };

    if (currentEntity?.childrenList?.length) {
        fillListWithDescendants(currentEntity.childrenList);
    }

    try {
        action.payload.nextQueryVariables = { ids: affectedEntityIds, publicState };
        const response = yield call(gqlRequest, action);
        yield put(updateEntityPublicStateSuccess(response.data.update_publicEntities.returning));
    } catch (err) {
        yield put(updateEntityPublicStateFailure({ error: err }));
    }
}

export function* updateUserSaga(action) {
    const id = action.payload.id;
    let updatedUser = action.payload.updatedUser;
    const originalUser = yield select(getFullEntitySelector(id));
    let fallbackUser = Object.assign({}, originalUser); // Not clone, because need metadata
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;

    try {
        // TEMPORARY
        if (!originalUser) {
            const fetchedEntity = yield asyncGQL(new_GET_ENTITY, { id });
            updatedUser = Object.assign({}, cloneEntity(fetchedEntity?.data?.Entities[0]?.entity), updatedUser);
            fallbackUser = Object.assign({}, fetchedEntity?.data?.Entities[0]?.entity); // Not clone, because need metadata
        }
        // action.payload.entity.childrenList = removeDuplicates(action.payload.entity?.childrenList);
        action.payload.nextQueryVariables = { id, user: updatedUser };

        if (presetEnabled) {
            const nextEntities = [{ id, entity: updatedUser }];
            yield put(presetEntities(nextEntities, true));
            if (action.payload.callback) yield put(callback(action.payload.callback, nextEntities[0]));
            presetSuccess = true;
        }

        const response = yield call(gqlRequest, action);
        yield put(updateUserSuccess(response.data.update_people.returning));
        if (!presetEnabled && action.payload.callback)
            yield put(callback(action.payload.callback, response.data.update_people.returning?.[0]));
    } catch (err) {
        if (presetEnabled && presetSuccess) {
            const previousEntities = [{ id, entity: fallbackUser }];
            yield put(undoPresetEntities(previousEntities));
            if (action.payload.fallback) yield put(fallback(action.payload.fallback));
        }

        yield put(updateUserFailure({ error: err }));
    }
}

export function* createDataspaceSaga(action) {
    try {
        action.queryType = CREATE_DATASPACE;
        const updatedAction = prepareNewDataspaceAndAssociatedEntities(action);
        const response = yield call(gqlRequest, updatedAction);
        const { id: userId } = yield select(getUserSelector);
        yield put(createDataspaceSuccess(response.data, userId));
        if (action?.payload?.callback) action.payload.callback(response?.data?.insertDataspace.insertedEntity);
    } catch (err) {
        yield put(createDataspaceFailure({ error: err }));
    }
}

export function* addMembersSaga(action) {
    const { emails, role, callback } = action.payload;
    let { dataspaceId } = action.payload;
    dataspaceId = yield dataspaceId || select(getDsidSelector);
    try {
        action.payload.nextQueryVariables = { emails, dataspaceId, role: role || 'member' };
        const response = yield call(gqlRequest, action);
        const users = response.data?.add_Members.users;

        yield put(addMembersSuccess([response.data?.add_Members.dataspace, ...users]));
        if (callback) callback();
    } catch (err) {
        yield put(addMembersFailure({ error: err }));
    }
}

export function* removeChildEidSaga(action) {
    const rebuildEntityList = true;
    const parentId = action.payload.parentId;
    let parentEntity = yield select(getFullEntitySelector(parentId));
    let fallbackParentEntity = Object.assign({}, parentEntity); // Not clone, because need metadata
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;

    try {
        // TEMPORARY
        if (!parentEntity) {
            const fetchedEntity = yield asyncGQL(new_GET_ENTITY, { id: parentId });
            parentEntity = cloneEntity(fetchedEntity?.data?.Entities[0]?.entity);
            fallbackParentEntity = Object.assign({}, fetchedEntity?.data?.Entities[0]?.entity); // Not clone, because need metadata
        }

        const index = parentEntity.childrenList.indexOf(action.payload.childEid);
        if (index > -1) {
            const newEntity = cloneEntity(parentEntity);
            newEntity['childrenList']?.splice(index, 1);

            if (presetEnabled) {
                const nextEntities = [{ id: parentId, entity: newEntity }];
                yield put(presetEntities(nextEntities, rebuildEntityList));
                if (action.payload.callback) yield put(callback(action.payload.callback, nextEntities[0]));
                presetSuccess = true;
            }

            action.queryType = UPDATE_ENTITY;
            action.payload.nextQueryVariables = { id: parentId, entity: newEntity };
            const response = yield call(gqlRequest, action);
            const { id: tabFinderId } = yield select(getFinderSelector);
            yield put(
                removeChildEidSuccess(
                    response.data.update_Entities.returning,
                    tabFinderId,
                    !presetEnabled && rebuildEntityList,
                ),
            );

            const browsedInstance = yield select(getBrowsedInstanceSelector);
            if (browsedInstance?.id === action.payload.childEid) {
                yield put(clearBrowsed());
                yield put(setSelectedEntities([]));
            }
            if (!presetEnabled && action.payload.callback)
                yield put(callback(action.payload.callback, response.data.update_Entities.returning?.[0]));
        }
    } catch (err) {
        if (presetEnabled && presetSuccess) {
            const previousEntities = [{ id: action.payload.parentId, entity: fallbackParentEntity }];
            yield put(undoPresetEntities(previousEntities, rebuildEntityList));
            if (action.payload.fallback) yield put(fallback(action.payload.fallback));
        }
        yield put(removeChildEidFailure({ error: err }));
    }
}

//
// Bulk operations (CRUD multiple entities)
//

export function* bulkCreateEntitiesSaga(action) {
    const rebuildEntityList = true;
    const allFullEntities = yield select(getAllFullEntitiesSelector);
    const skeleton = genericEntitySkeleton();
    const user = yield select(getUserSelector);
    const dsid = yield select(getDsidSelector);
    const owner = user?.id;
    const { newEntities, parentId, index, allEntitiesIncluded } = action.payload;
    const topLevelNewEntities = findNonChildEntities(newEntities);
    const idsToFetch = newEntities.map((e) => e?.metadata?.id).filter(Boolean);

    // TEMPORARY
    idsToFetch.push(parentId);
    topLevelNewEntities.forEach((entity) => {
        if (entity?.childrenList?.length > 0) {
            for (const childId of entity.childrenList) {
                const { eid } = parseUID(childId);
                if (eid) idsToFetch.push(eid);
            }
        }
    });
    const fetch = yield asyncGQL(new_GET_ENTITIES, { ids: idsToFetch }, false);
    yield put(setEntities(fetch?.data?.Entities, 'bulkCreateEntitiesSaga'));
    const parentEntity = cloneEntity(find(fetch?.data?.Entities, ['id', parentId])?.entity);

    let builtEntities = [];
    const fallbackParentEntity = Object.assign({}, parentEntity);
    const presetEnabled = checkPresetEnabled(action);
    let presetSuccess = false;

    const addEntityAndChildren = (entity: Entity, id: string) => {
        let entities = [];
        let newChildrenList = [];
        if (entity?.childrenList?.length > 0 && entity?.['@type'] !== 'IntegrationItem') {
            newChildrenList = Array.from({ length: entity.childrenList.length }, () => uuidv4());
            entity.childrenList.forEach((childId, i) => {
                const childEntity =
                    allFullEntities[childId] || allEntitiesIncluded
                        ? find(newEntities, ['id', childId])
                        : find(fetch?.data?.Entities, ['id', childId])?.entity;
                const moreEntities = addEntityAndChildren(childEntity, newChildrenList[i]);
                entities = entities.concat(moreEntities);
            });
        } else {
            newChildrenList = entity.childrenList;
        }
        entities.push({
            id,
            owned_by: owner,
            dsid,
            entity: {
                ...cloneEntity(skeleton),
                ...typeInfo[entity['@type']].skeleton,
                ...cloneEntity(entity),
                childrenList: newChildrenList,
            } as Entity,
        });
        return entities;
    };

    try {
        const newEntityIds = [];
        topLevelNewEntities.forEach((newEntity) => {
            // const id = newEntity.id ? newEntity.id : uuidv4(); //Used for mirrored entities, need to change for aliases anyway
            const id = uuidv4();
            newEntityIds.push(id);
            builtEntities = builtEntities.concat(addEntityAndChildren(newEntity, id));
        });

        const parentEntityWithNewChildren = {
            id: parentId,
            entity: addEidsToChildrenListAndClone(parentEntity, newEntityIds, index),
        };

        const newEntitiesAsEntityIdentity = newEntityIds.map((id) => {
            return { id, parentId: parentId };
        });

        if (presetEnabled) {
            yield put(presetEntities([...builtEntities, parentEntityWithNewChildren], rebuildEntityList));
            const expandedHListItems = yield select(getAllExpandedHListItemsSelector);
            yield put(
                setUI({
                    selectedEntities: newEntitiesAsEntityIdentity,
                    expandedHListItems: {
                        ...expandedHListItems,
                        [parentId]: true,
                    },
                }),
            );
            if (action.payload.callback) yield put(callback(action.payload.callback));
            // TODO: Incorporate expected response object, right now the only callback redirects to finder though
            presetSuccess = true;
        }

        builtEntities.forEach((e) => purifyEntity(e));

        action.queryType = BULK_INSERT_ENTITIES;
        action.payload.nextQueryVariables = { entities: builtEntities, parentId };
        const bulkInsertResponse = yield call(gqlRequest, action);
        const insertedEntities = bulkInsertResponse.data?.insert_Entities_bulk.returning;
        const affectedEntities = [...insertedEntities];

        if (insertedEntities?.length) {
            action.queryType = UPDATE_ENTITY;
            action.payload.nextQueryVariables = { id: parentId, entity: parentEntityWithNewChildren.entity };
            const parentUpdateResponse = yield call(gqlRequest, action);
            affectedEntities.push(...parentUpdateResponse?.data.update_Entities.returning);
        }

        yield put(
            bulkCreateEntitiesSuccess(
                affectedEntities,
                newEntitiesAsEntityIdentity,
                !presetEnabled && rebuildEntityList,
            ),
        );
        if (insertedEntities) {
            const isPasted = action.payload.isPasted;
            const skipBrowseOnCreate = action.payload.skipBrowseOnCreate;
            const insertedEntitiesWithoutChildren = extractParentEntitiesFromRawEntityArray(insertedEntities);
            // const toastActions = insertedEntitiesWithoutChildren.map((entity) => {
            //     const text = `${isPasted ? 'Pasted' : 'Created'} Entity ${
            //         entity.entity.name || 'Untitled ' + typeInfo[entity.entity['@type']].fancyName
            //     }`;
            //     const message = {
            //         text,
            //         msgType: MessageType.SUCCESS,
            //     };
            //     return toast(message);
            // });
            // yield all(toastActions.map((action) => put(action)));
            const toastText = `${isPasted ? 'Pasted' : 'Created'} ${insertedEntitiesWithoutChildren.length} ${
                insertedEntitiesWithoutChildren.length > 1 ? 'Entities' : 'Entity'
            }`;
            const toastMessage = {
                text: toastText,
                msgType: MessageType.SUCCESS,
            };
            yield put(toast(toastMessage));
            if (!isPasted && !skipBrowseOnCreate) {
                const { id: tabFinderId } = yield select(getFinderSelector);
                yield put(setBrowsed(insertedEntitiesWithoutChildren[0].id, parentId, true, tabFinderId));
            }
        }
        if (!presetEnabled && action.payload.callback) yield put(callback(action.payload.callback));
    } catch (e) {
        if (presetEnabled && presetSuccess) {
            let previousEntities = [{ id: parentId, entity: fallbackParentEntity }];
            previousEntities = previousEntities.concat(
                builtEntities.map((e) => {
                    return { id: e.id, entity: null };
                }),
            );
            yield put(undoPresetEntities(previousEntities, rebuildEntityList));
            if (action.payload.fallback) yield put(fallback(action.payload.fallback));
        }

        yield put(bulkCreateEntitiesFailure(e));
    }
}

export function* bulkUpdateEntitiesSaga(action) {
    try {
        action.queryType = BULK_UPDATE_ENTITIES;
        action.payload.nextQueryVariables = { updates: buildEntityUpdatePairs(action.payload.entities) };
        const response = yield call(gqlRequest, action);

        yield put(bulkUpdateEntitiesSuccess(response.data.update_Entities_many.flatMap((e) => e.returning)));
    } catch (err) {
        yield put(bulkUpdateEntitiesFailure({ error: err }));
    }
}

export function* bulkMoveEntitiesSaga(action) {
    const rebuildEntityList = true;
    let presetSuccess = false;

    const { destinationEid, index, entities } = action.payload;
    const parentIds = new Set<string>();
    const entityIdsSet = new Set<string>();
    entities.map((e) => {
        entityIdsSet.add(e.id);
        parentIds.add(e.parentId);
    });

    const fallbackUI = yield select(getUIStateSelector);
    const fallbackDestinationEntity = yield select(getCondensedEntitySelector(destinationEid));
    const fallbackParents = yield select(getCondensedEntitiesSelector(Array.from(parentIds)));
    const fallbackEntities = [...fallbackParents, fallbackDestinationEntity];
    const updatedEntities = [];

    try {
        const updatedDestinationEntity = cloneDeep(fallbackDestinationEntity);
        const updatedParents = []; // Parents that are not the destination entity

        // Remove children from parents
        for (const parent of fallbackParents) {
            const parentIsDestination = parent.metadata.id === destinationEid;
            if (parentIsDestination) {
                // Remove children from destination entity
                updatedDestinationEntity.childrenList = updatedDestinationEntity.childrenList.filter(
                    (childId) => !entityIdsSet.has(childId),
                );
            } else {
                // Remove children from other parents
                const newParent = cloneDeep(parent);
                newParent.childrenList = parent.childrenList.filter((childId) => !entityIdsSet.has(childId));
                updatedParents.push(newParent);
            }
        }

        // Add children to destination entity
        updatedDestinationEntity.childrenList = addEidsToChildrenListAndClone(
            updatedDestinationEntity,
            Array.from(entityIdsSet),
            index,
        ).childrenList;

        // Preset entities and update UI
        updatedEntities.push(...updatedParents, updatedDestinationEntity);
        yield put(presetCondensedEntities(updatedEntities, rebuildEntityList, 'bulkMoveEntitiesSaga'));
        const expandedHListItems = yield select(getAllExpandedHListItemsSelector);
        const selectedEntities = Array.from(entityIdsSet).map((id) => ({ id, parentId: destinationEid }));
        yield put(
            setUI({
                selectedEntities,
                expandedHListItems: {
                    ...expandedHListItems,
                    [destinationEid]: true,
                },
            }),
        );
        presetSuccess = true;

        // Make request to update entities
        action.payload.nextQueryVariables = {
            updates: buildChildrenListUpdatePairs(updatedEntities),
        };
        const response = yield call(gqlRequest, action);

        yield put(
            bulkMoveEntitiesSuccess(
                response.data.update_Entities_many.flatMap((e) => e.returning),
                destinationEid,
                !presetSuccess && rebuildEntityList,
            ),
        );
    } catch (err) {
        if (presetSuccess) {
            yield put(undoPresetCondensedEntities(fallbackEntities, rebuildEntityList, 'bulkMoveEntitiesSaga:failed'));
            yield put(setUI(fallbackUI));
        }
        yield put(bulkMoveEntitiesFailure({ error: err }));
    }
}

export function* bulkGroupEntitiesSaga(action) {
    const { selectedEntities, contextParentId: targetParentId, contextId } = action.payload;
    const rebuildEntityList = true;
    let presetSuccess = false;

    const targetId = uuidv4();
    const { id: owner } = yield select(getUserSelector);
    const dsid = yield select(getDsidSelector);

    const fallbackUI = yield select(getUIStateSelector);
    const selectedEids = [];
    const selectedParentEids = [];
    action.payload.selectedEntities.forEach((e: EntityIdentity) => {
        selectedEids.push(e.id);
        selectedParentEids.push(e.parentId);
    });
    const fallbackParents = yield select(getCondensedEntitiesSelector(uniq(selectedEntities.map((e) => e.parentId))));

    try {
        const updatedParents = fallbackParents.map((parentEntity) => {
            let newEntity = cloneDeep(parentEntity);
            if (parentEntity.id === targetParentId) {
                newEntity = replaceChildId(newEntity, contextId, targetId);
            }
            newEntity = removeChildrenIdFromParents(newEntity, selectedEids);
            return newEntity;
        });

        const newFolderCondensedEntity = buildCondensedEntity({
            entityType: 'HList',
            id: targetId,
            owner,
            dsid,
            childrenList: selectedEids,
        });
        yield put(
            presetCondensedEntities(
                [...updatedParents, newFolderCondensedEntity],
                rebuildEntityList,
                'bulkGroupEntitiesSaga',
            ),
        );
        const expandedHListItems = yield select(getAllExpandedHListItemsSelector);
        const newSelectedEntities = selectedEids.map((id) => ({ id, parentId: targetId }));
        yield put(
            setUI({
                selectedEntities: newSelectedEntities,
                expandedHListItems: {
                    ...expandedHListItems,
                    [targetId]: true,
                },
            }),
        );
        const { id: tabFinderId } = yield select(getFinderSelector);
        yield put(setBrowsed(targetId, targetParentId, true, tabFinderId));
        presetSuccess = true;

        action.payload.nextQueryVariables = {
            updates: buildChildrenListUpdatePairs(updatedParents),
            object: buildEntity('HList', targetId, owner, dsid, selectedEids),
            parentId: targetParentId,
        };
        const response = yield call(gqlRequest, action);
        const updatedEntities = response.data.update_Entities_many.flatMap((e) => e.returning);
        const insertedEntity = response.data.insert_Entities_one;

        yield put(bulkGroupEntitiesSuccess([...updatedEntities, insertedEntity], targetId, rebuildEntityList));
    } catch (err) {
        if (presetSuccess) {
            yield put(undoPresetCondensedEntities(fallbackParents, rebuildEntityList, 'bulkGroupEntitiesSaga:failed'));
            yield put(setUI(fallbackUI));
        }
        yield put(bulkGroupEntitiesFailure({ error: err }));
    }
}

export function* restoreEntitiesFromTrashSaga(action: ReduxAction<RestoreEntitiesFromTrashPayload>) {
    const condensedEntities = yield select(getAllCondensedEntitiesSelector);
    const { entityIdentities } = action.payload;
    const { allFinders } = yield select(getFinderSelector);

    // This process readds deleted entities to the top level of the Finder they belonged to.
    // If the deleted entity was the child of another deleted entity, it will be removed from its parent's childrenList.
    try {
        const updatedParentsMap = entityIdentities.reduce((map, entityIdentity) => {
            let finderId: string, finder: CondensedEntity;

            const parentId = entityIdentity.parentId;
            const parent = condensedEntities[parentId];
            const parentIsFinder = parent['@type'] === 'Finder' && !parent.deleted;

            if (parentIsFinder) {
                finderId = parentId;
                finder = parent;
            } else {
                // Remove child from deleted parent's childrenList if parent is not a finder
                if (map.has(parentId)) {
                    map.get(parentId).childrenList = map
                        .get(parentId)
                        .childrenList.filter((childId) => childId !== entityIdentity.id);
                } else {
                    map.set(parentId, {
                        id: parent.metadata.id,
                        childrenList: [
                            ...(parent.childrenList || []).filter((childId) => childId !== entityIdentity.id),
                        ],
                        metadata: parent.metadata,
                    });
                }

                // Prepare to add restored entity to finder that it belonged to
                const condensedEntity = condensedEntities[entityIdentity.id];
                const targetDsid = condensedEntity.metadata.dsid;
                finderId = allFinders.find((finder) => finder.dsid === targetDsid).id;
                finder = condensedEntities[finderId];
            }

            // Add restored entity to finder
            if (map.has(finderId)) {
                map.get(finderId).childrenList.push(entityIdentity.id);
            } else {
                map.set(finderId, {
                    id: finderId,
                    childrenList: [...(finder.childrenList || []), entityIdentity.id],
                    metadata: finder.metadata,
                });
            }

            return map;
        }, new Map());

        const updatedParents = Array.from(updatedParentsMap.values());

        const entityIdsToRestore = entityIdentities.map((entity) => entity.id);
        const allEntityIdsToRestore = getAllDescendantEids(condensedEntities, entityIdsToRestore);
        const response = yield call(gqlRequest, {
            queryType: BULK_REMOVE_CHILD_EIDS,
            payload: {
                nextQueryVariables: {
                    updates: buildChildrenListUpdatePairs(updatedParents),
                    eids: allEntityIdsToRestore,
                    setDeletedTo: false,
                },
            },
        });
        const updatedFinders = response.data.update_Entities_many.flatMap((e) => e.returning);
        const restoredEntities = response.data.softDeleteEntities.returning;
        const updatedEntitiesWithParent = [...updatedFinders, ...restoredEntities];

        const toastText = `Successfully restored ${
            allEntityIdsToRestore.length > 1 ? `${allEntityIdsToRestore.length} Entities` : `Entity`
        }`;
        const toastMessage = {
            text: toastText,
            msgType: MessageType.SUCCESS,
        };
        yield put(clearSelectedEntities());
        yield put(restoreEntitiesFromTrashSuccess(updatedEntitiesWithParent, true));
        yield put(toast(toastMessage));
    } catch (err) {
        yield put(restoreEntitiesFromTrashFailure({ error: err }));
    }
}

export function* bulkRemoveChildEidsSaga(action) {
    const rebuildEntityList = true;
    let presetSuccess = false;
    let serverSuccess = false;

    const selectedEids = [];
    const selectedParentEids = [];
    action.payload.selectedEntities.forEach((e: EntityIdentity) => {
        selectedEids.push(e.id);
        selectedParentEids.push(e.parentId);
    });

    const fallbackUI = yield select(getUIStateSelector);
    const fallbackParentsOfSelected = yield select(getCondensedEntitiesSelector(uniq(selectedParentEids)));

    const condensedEntities = yield select(getAllCondensedEntitiesSelector);
    const selectedCondensedEntitiesWithChildren = getAllDescendantEids(condensedEntities, selectedEids);

    try {
        const updatedParentsList = fallbackParentsOfSelected.map((parentEntity) => {
            const newEntity = cloneDeep(parentEntity);
            newEntity.childrenList = newEntity.childrenList.filter((childId) => !selectedEids.includes(childId));
            return newEntity;
        });

        yield put(presetCondensedEntities(updatedParentsList, rebuildEntityList, 'bulkRemoveChildEidsSaga'));

        // TODO: remove the tabs that are being deleted
        presetSuccess = true;

        action.payload.nextQueryVariables = {
            updates: buildChildrenListUpdatePairs(updatedParentsList),
            eids: selectedCondensedEntitiesWithChildren,
            setDeletedTo: true,
        };
        const response = yield call(gqlRequest, action);
        const updatedEntities = response.data.update_Entities_many.flatMap((e) => e.returning);
        const deletedEntities = response.data.softDeleteEntities.returning;

        const { id: tabFinderId } = yield select(getFinderSelector);

        yield put(bulkRemoveChildEidsSuccess([...updatedEntities, ...deletedEntities], tabFinderId, rebuildEntityList));
        serverSuccess = true;

        const browsedInstance = yield select(getBrowsedInstanceSelector);
        if (!browsedInstance?.id) {
            yield put(clearSelectedEntities());
        }
        if (selectedEids.includes(browsedInstance?.id)) {
            yield put(clearBrowsed());
            yield put(clearSelectedEntities());
        }

        if (updatedEntities.length) {
            const text = `Successfully deleted ${
                selectedCondensedEntitiesWithChildren?.length > 1 ? 'Entities' : 'Entity'
            }`;
            const message = {
                text,
                msgType: MessageType.SUCCESS,
                undo: restoreEntitiesFromTrash(action.payload.selectedEntities),
            };
            yield put(toast(message));
        }
    } catch (err) {
        if (presetSuccess) {
            yield put(
                undoPresetCondensedEntities(
                    fallbackParentsOfSelected,
                    rebuildEntityList,
                    'bulkRemoveChildEidsSaga:failure',
                ),
            );
            yield put(setUI(fallbackUI));
        }
        if (serverSuccess) yield put(restoreEntitiesFromTrash(action.payload.selectedEntities));
        yield put(bulkRemoveChildEidsFailure({ error: err }));
    }
}

export function* saveDraftsSaga(action) {
    const { dsid } = action.payload;
    const finderEntity = yield select(getFinderEntityByDsidSelector(dsid));

    try {
        action.payload.finderId = finderEntity?.metadata?.id;
        action.payload.finderEntity = finderEntity;
        const response = yield call(gqlRequest, action);
        const { newEntities } = response.data.save_drafts;

        const { id: userId } = yield select(getUserSelector);
        yield put(saveDraftsSuccess(newEntities, true, userId));

        const toastMessage = {
            text: `Draft${newEntities.length > 1 ? 's' : ''} added to dataspace`,
            msgType: MessageType.SUCCESS,
        };
        yield put(toast(toastMessage));
    } catch (err) {
        yield put(saveDraftsFailure({ error: err }));

        const toastMessage = {
            text: `Error while adding to dataspace ${err?.message}`,
            msgType: MessageType.ERROR,
        };
        yield put(toast(toastMessage));
    }
}

export function* getPublicEntityDescendantsSaga(action) {
    const { dsid, eid } = action.payload;

    try {
        const { entities } = yield getPublicEntityDescendants(dsid, eid);

        yield put(getPublicEntityDescendantsSuccess(entities, eid));
    } catch (err) {
        yield put(getPublicEntityDescendantsFailure(err));
    }
}

export function* addAppToDataspaceSaga(action) {
    const { dsid, appEntity } = action.payload;
    const appEntityId = uuidv4();
    const fallbackDataspace = yield select(getFullEntitySelector(dsid));
    const { id: userId } = yield select(getUserSelector);

    try {
        const updatedDataspace = {
            ...cloneEntity(fallbackDataspace),
            apps: [...(fallbackDataspace?.apps || []), appEntityId],
        };

        action.payload.nextQueryVariables = {
            userId,
            dsid,
            updatedDataspace,
            appEntityId,
            appEntity,
        };
        const entitiesToPreset = [
            { id: appEntityId, entity: appEntity, owned_by: userId, dsid },
            { id: dsid, entity: updatedDataspace, owned_by: userId, dsid },
        ];
        yield put(presetEntities(entitiesToPreset, true));

        yield call(gqlRequest, action);
        yield put(toggleExpandedHListItems([`${dsid}_apps`], true));
    } catch (err) {
        yield put(addAppToDataspaceFailure(err));
        yield put(undoPresetEntities([{ id: dsid, entity: fallbackDataspace }], true));
    }
    if (action.payload.callback) yield put(callback(action.payload.callback));
}

export function* removeAppFromDataspaceSaga(action) {
    const { dsid, appEntityId } = action.payload;
    const fallbackDataspace = yield select(getFullEntitySelector(dsid));

    try {
        const updatedDataspace = {
            ...cloneEntity(fallbackDataspace),
            apps: [...(fallbackDataspace?.apps || []).filter((id: string) => id !== appEntityId)],
        };

        action.payload.nextQueryVariables = {
            dsid,
            updatedDataspace,
            appEntityId,
        };

        const entitiesToPreset = [{ id: dsid, entity: updatedDataspace }];
        yield put(presetEntities(entitiesToPreset, true));

        yield call(gqlRequest, action);
    } catch (err) {
        yield put(undoPresetEntities([{ id: dsid, entity: fallbackDataspace }], true));
        yield put(removeAppFromDataspaceFailure(err));
    }
    if (action.payload.callback) yield put(callback(action.payload.callback));
}

function* entitiesSaga() {
    yield all([
        takeEvery(CREATE_ENTITY, createEntitySaga),
        takeEvery(SET_ENTITIES, setEntitiesSaga),
        takeLatest(GET_ENTITY, getEntitySaga),
        takeLatest(GET_ENTITIES, getEntitiesSaga),
        takeLatest(RESTORE_ENTITIES_FROM_TRASH, restoreEntitiesFromTrashSaga),
        takeLatest(HARD_DELETE_ENTITIES, hardDeleteEntitiesSaga),
        takeLatest(EMPTY_TRASH, emptyTrashSaga),
        takeLatest(GET_RECENT_ENTITIES, getRecentEntitiesSaga),
        takeLatest(INITIAL_LOAD, initialLoadSaga),
        takeEvery(UPDATE_ENTITY, updateEntitySaga),
        takeEvery(UPDATE_ENTITIES_PUBLIC_STATE, updateEntitiesPublicStateSaga),
        takeEvery(UPDATE_USER, updateUserSaga),
        takeEvery(ADD_MEMBERS, addMembersSaga),
        takeLatest(CREATE_DATASPACE, createDataspaceSaga),
        takeLatest(GET_ALL_COLLABORATORS, getAllCollaboratorsSaga),
        takeEvery(REMOVE_CHILD_EID, removeChildEidSaga),
        takeEvery(BULK_CREATE_ENTITIES, bulkCreateEntitiesSaga),
        takeEvery(BULK_UPDATE_ENTITIES, bulkUpdateEntitiesSaga),
        takeEvery(BULK_MOVE_ENTITIES, bulkMoveEntitiesSaga),
        takeEvery(BULK_GROUP_ENTITIES, bulkGroupEntitiesSaga),
        takeEvery(BULK_REMOVE_CHILD_EIDS, bulkRemoveChildEidsSaga),
        takeEvery(CREATE_SCHEMA_FROM_OBJECT, createSchemaFromObjectSaga),
        takeEvery(SAVE_DRAFTS, saveDraftsSaga),
        takeLatest(GET_PUBLIC_ENTITY_DESCENDANTS, getPublicEntityDescendantsSaga),
        takeEvery(ADD_APP, addAppToDataspaceSaga),
        takeEvery(REMOVE_APP, removeAppFromDataspaceSaga),
    ]);
}

export default entitiesSaga;
