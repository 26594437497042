export default function GlobeMdIcon(): JSX.Element {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            data-3d-icon={true}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="8.06055" cy="8" r="7.5" fill="white" />
            <circle cx="8.06055" cy="8" r="7.37069" stroke="black" strokeOpacity="0.05" strokeWidth="0.258621" />
            <path
                d="M8 13.75C9.27458 13.7499 10.513 13.3265 11.5209 12.5463C12.5288 11.7661 13.2491 10.6733 13.5685 9.43942M8 13.75C6.72542 13.7499 5.48695 13.3265 4.47907 12.5463C3.47118 11.7661 2.75094 10.6733 2.43145 9.43942M8 13.75C9.58763 13.75 10.875 11.1753 10.875 8C10.875 4.82472 9.58763 2.25 8 2.25M8 13.75C6.41236 13.75 5.125 11.1753 5.125 8C5.125 4.82472 6.41236 2.25 8 2.25M13.5685 9.43942C13.6867 8.97942 13.75 8.49706 13.75 8C13.7516 7.01107 13.4969 6.0386 13.0108 5.17739M13.5685 9.43942C11.8647 10.3839 9.94809 10.8781 8 10.875C5.97983 10.875 4.0817 10.3543 2.43145 9.43942M2.43145 9.43942C2.31054 8.96918 2.24957 8.48554 2.25 8C2.25 6.97458 2.51834 6.01114 2.9892 5.17739M8 2.25C9.01982 2.24958 10.0214 2.52048 10.9019 3.03492C11.7825 3.54935 12.5104 4.2888 13.0108 5.17739M8 2.25C6.98018 2.24958 5.9786 2.52048 5.09804 3.03492C4.21748 3.54935 3.48964 4.2888 2.9892 5.17739M13.0108 5.17739C11.6197 6.38229 9.84034 7.04431 8 7.04167C6.08461 7.04167 4.33278 6.33889 2.9892 5.17739"
                stroke="#666666"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
