import React from 'react';
import styles from './tabs.module.scss';

interface Tab {
    label?: string;
    icon?: JSX.Element;
    value: string;
}

interface Props {
    tabsData: Tab[];
    value?: any;
    onClick?: (value) => void;
    small?: boolean;
    noHighlight?: boolean;
}

const Tabs = ({ tabsData, onClick, value, small, noHighlight }: Props) => {
    const [tabBoundingBox, setTabBoundingBox] = React.useState(null);
    const [wrapperBoundingBox, setWrapperBoundingBox] = React.useState(null);
    const [highlightedTab, setHighlightedTab] = React.useState(null);
    const [isHoveredFromNull, setIsHoveredFromNull] = React.useState(true);

    const handleClick = (value) => () => {
        if (onClick) onClick(value);
    };

    const highlightRef = React.useRef(null);
    const wrapperRef = React.useRef(null);

    const repositionHighlight = (e, tab) => {
        setTabBoundingBox(e.target.getBoundingClientRect());
        setWrapperBoundingBox(wrapperRef.current.getBoundingClientRect());
        setIsHoveredFromNull(!highlightedTab);
        setHighlightedTab(tab);
    };

    const resetHighlight = () => setHighlightedTab(null);

    const highlightStyles = {};

    if (tabBoundingBox && wrapperBoundingBox) {
        highlightStyles['transitionDuration'] = isHoveredFromNull ? '0ms' : '150ms';
        highlightStyles['opacity'] = highlightedTab ? 1 : 0;
        highlightStyles['width'] = `${tabBoundingBox.width}px`;
        highlightStyles['transform'] = `translate(${tabBoundingBox['left'] - wrapperBoundingBox.left}px)`;
    }

    return (
        <div
            className={`${styles.tabsNav} ${small ? styles.tabsNavSmall : ''}`}
            ref={wrapperRef}
            onMouseLeave={resetHighlight}
        >
            {!noHighlight && <div className={styles.tabsHighlight} ref={highlightRef} style={highlightStyles} />}
            {tabsData.map((tab) => (
                <div
                    className={`${styles.tab} ${value === tab.value ? styles.active : ''}`}
                    key={tab.value}
                    onClick={handleClick(tab.value)}
                    onMouseOver={(ev) => repositionHighlight(ev, tab)}
                >
                    {tab?.icon}
                    {tab?.label}
                </div>
            ))}
        </div>
    );
};

export default Tabs;
