import {
    CALLBACK,
    CALLBACK_SUCCESS,
    CALLBACK_FAILURE,
    FALLBACK,
    FALLBACK_SUCCESS,
    FALLBACK_FAILURE,
} from './actionTypes';
import { all, takeEvery, put } from 'redux-saga/effects';

// Actions
export const callback = (callback: () => void, data?: object) => {
    return {
        type: CALLBACK,
        payload: { data, callback },
    };
};

export const callbackSuccess = () => {
    return {
        type: CALLBACK_SUCCESS,
        payload: {},
    };
};

export const callbackFailure = (error: unknown) => {
    return {
        type: CALLBACK_FAILURE,
        payload: { error },
    };
};

export const fallback = (fallback: () => void, data?: object) => {
    return {
        type: FALLBACK,
        payload: { data, fallback },
    };
};

export const fallbackSuccess = () => {
    return {
        type: FALLBACK_SUCCESS,
        payload: {},
    };
};

export const fallbackFailure = (error: unknown) => {
    return {
        type: FALLBACK_FAILURE,
        payload: { error },
    };
};

// Sagas
export function* callbackSaga(action) {
    try {
        const { callback, data } = action.payload;
        if (data) callback(data);
        else callback();
        yield put(callbackSuccess());
    } catch (err) {
        yield put(callbackFailure(err));
    }
}

export function* fallbackSaga(action) {
    try {
        const { fallback, data } = action.payload;
        if (data) fallback(data);
        else fallback();
        yield put(fallbackSuccess());
    } catch (err) {
        yield put(fallbackFailure(err));
    }
}

function* callbacksSaga() {
    yield all([takeEvery(CALLBACK, callbackSaga)]);
    yield all([takeEvery(FALLBACK, fallbackSaga)]);
}

export default callbacksSaga;
