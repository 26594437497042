import React from 'react';

import { PopupWrapper, usePopup } from '../wrappers/modal';
import { Image } from 'react-bootstrap';
import styles from './aboutBedrockPopup.module.scss';
import { useEffect, useRef } from 'react';

export const useAboutBedrockPopup = () => {
    const { open: openPopup } = usePopup();
    // const dispatch = useDispatch();

    const openAboutBedrockPopup = (): void => {
        openPopup('about-bedrock-popup');
    };
    return openAboutBedrockPopup;
};

const AboutBedrockPopup: React.FunctionComponent = () => {
    // const { close } = usePopup();
    const aboutMessageEndRef = useRef(null);

    const scrollToBottom = () => {
        aboutMessageEndRef.current?.scrollIntoView({ behavior: 'smooth', duration: 15000 });
    };

    useEffect(() => {
        scrollToBottom();
    }, []);

    return (
        <PopupWrapper text={'about-bedrock-popup'}>
            <div className={`${styles.aboutBedrockModal}`}>
                <div className={styles.floatingSymbol}>
                    <img src={'/logos/bedrock-symbol-white.svg'} />
                </div>
                <div className={'modalDialogHeader'}>
                    <h6>About Bedrock</h6>
                </div>
                <p>Bedrock is being built by:</p>
                <div className={`${styles.scrollContainer}`}>
                    <div className={styles.crawl}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <div className={styles.scrollContent}>
                            <h4 className={'classicSystemFont'}>Brian Joseff</h4>
                            <div className={styles.headshot}>
                                <Image id="avatarPreview" src={'/images/team/bj2.png'} />
                            </div>
                            <h3>⎶✷⎶</h3>
                            <h4 className={'classicSystemFont'}>Tammer Abiyu</h4>
                            <div className={styles.headshot}>
                                <Image id="avatarPreview" src={'/images/team/tammer.png'} />
                            </div>
                            <h3>⎶✷⎶</h3>
                            <h4 className={'classicSystemFont'}>Tem Abdibali</h4>
                            <div className={styles.headshot}>
                                <Image id="avatarPreview" src={'/images/team/tem2.png'} />
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div className={styles.headshot}>
                                <Image
                                    id="avatarPreview"
                                    src={'/images/bedrock-blue-moon-small.png'}
                                    style={{ width: '400px' }}
                                />
                            </div>
                        </div>
                    </div>
                    <div ref={aboutMessageEndRef} />
                </div>
                <br />
                <h5>Join us!</h5>
                <p className={'textXXS'}>© Bedrock Computer, Inc. 2017-2023</p>
            </div>
        </PopupWrapper>
    );
};

export default AboutBedrockPopup;
