import { includesInstance, instancesAreEqual } from './helpers';
import { find, findIndex, isEmpty, uniqBy, filter, remove, pick } from 'lodash';

const hasSelectedAncestor = (list, clickedInstance, selectedEntities) => {
    const ent = find(list, { id: clickedInstance.id });
    if (!ent) return false;
    const selectedIds = selectedEntities.reduce((acc, { id }) => {
        acc.push(id);
        return acc;
    }, []);
    if (ent.ancestorList?.some((a) => selectedIds.includes(a))) {
        return true;
    }
    return false;
};

export const ctrlClick = (
    clickedInstance: EntityIdentity,
    currentSelected: EntityIdentity[],
    entityList,
    condensedEntities: EntityMap,
    browsedId: string,
) => {
    if (clickedInstance.isIntegrationItem && !clickedInstance.isIntegrationItemInDataspace) return currentSelected;

    const normalizedClickedInstance = pick(clickedInstance, ['id', 'parentId']);

    const removeIfDescendent = (currentSelectedInstance, list, clickedInstance, overrides) => {
        const ent = find(list, { id: currentSelectedInstance.id });
        if (!ent) return overrides;
        if (ent.ancestorList?.includes(clickedInstance.id)) {
            overrides.push({ ...currentSelectedInstance, selected: false });
        }
        return overrides;
    };

    const workingList = [...entityList];
    // let newSelected = currentSelected.length > 0 ? [...currentSelected] : [] as EntityIdentity[];
    if (includesInstance(currentSelected, normalizedClickedInstance)) {
        // If entity was already selected
        // If it's browsed return currentSelected list
        // If not remove clicked from selected
        if (!browsedId || clickedInstance.id !== browsedId) {
            remove(currentSelected, normalizedClickedInstance);
        }
        return currentSelected;
    } else {
        const overrides = [];
        // If entity was not already selected, check if any ancestor was selected:
        const descendentOfSelected = hasSelectedAncestor(workingList, normalizedClickedInstance, currentSelected);
        if (descendentOfSelected) {
            // Do nothing if ancestor is already selected
            return currentSelected;
        } else {
            // If not, set clicked entity to selected, then remove all descendents from selected
            overrides.push({ ...normalizedClickedInstance, selected: true });
            currentSelected.forEach((ent) => {
                removeIfDescendent(ent, workingList, normalizedClickedInstance, overrides);
            });
        }
        const combinedList = uniqBy(overrides.concat(currentSelected), (e) => [e.id, e.parentId].join()) as any[];
        const newSelected = filter(combinedList, (f) => f.selected === undefined || f.selected === true);
        const newSelectedEntities = newSelected.map((e) => {
            return { id: e.id, parentId: e.parentId };
        });
        return newSelectedEntities;
    }
};

export const shiftClick = (
    clickedInstance: EntityIdentity,
    currentSelected: EntityIdentity[],
    anchorInstance: EntityIdentity,
    entityList,
) => {
    if (clickedInstance.isIntegrationItem && !clickedInstance.isIntegrationItemInDataspace) return currentSelected;
    const normalizedClickedInstance = pick(clickedInstance, ['id', 'parentId']);

    const findLastDescendantIndex = (list, parentIndex) => {
        if (!list[parentIndex]?.hasChildren || list.length === parentIndex + 1) return parentIndex;
        const parentZ = list[parentIndex]?.zIndex;
        for (let i = parentIndex + 1; i < list.length; i++) {
            if (list[i]?.zIndex <= parentZ) return i - 1;
        }
        return list.length - 1;
    };

    // Do nothing if first click on anything
    if (isEmpty(anchorInstance)) return currentSelected;

    // Do nothing if ancestor is already selected
    const descendentOfSelected = hasSelectedAncestor(entityList, normalizedClickedInstance, currentSelected);
    if (descendentOfSelected) {
        return currentSelected;
    }

    // Cannot use shift-click to subtract from multiselect
    if (instancesAreEqual(normalizedClickedInstance, anchorInstance)) return currentSelected;
    if (includesInstance(currentSelected, normalizedClickedInstance)) return currentSelected;

    const indexA = findIndex(entityList, { id: anchorInstance.id, parentId: anchorInstance.parentId });
    const indexB = findIndex(entityList, {
        id: normalizedClickedInstance.id,
        parentId: normalizedClickedInstance.parentId,
    });
    const startIndex = Math.min(indexA, indexB);
    const laterIndex = Math.max(indexA, indexB);
    const endIndex = findLastDescendantIndex(entityList, laterIndex);

    let deepestZ = -1;
    let shallowestZ = Infinity;

    const workingList = entityList.slice(startIndex, endIndex + 1);

    workingList.forEach((e) => {
        // Find deepest and shallowest zIndexes, set all entities to unselected
        e.selected = false;
        if (e.zIndex > deepestZ) deepestZ = e.zIndex;
        if (e.zIndex < shallowestZ) shallowestZ = e.zIndex;
    });
    workingList.forEach((e) => {
        // Set lowest level children to selected for now
        if (e.zIndex === deepestZ) e.selected = true;
    });
    for (let z = deepestZ - 1; z >= shallowestZ; z--) {
        // Go up by z-index and set all entities to selected, and if they have children, unselect the children
        workingList.forEach((e, i) => {
            if (e.zIndex === z) {
                e.selected = true;
                if (e.hasChildren) {
                    // Set children to unselected
                    for (let j = i + 1; j < workingList.length; j++) {
                        if (workingList[j]?.zIndex <= e.zIndex) {
                            break;
                        } else if (workingList[j]?.zIndex === e.zIndex + 1 && workingList[j]?.selected === true) {
                            workingList[j].selected = false;
                        }
                    }
                }
            }
        });
    }
    const combinedList = uniqBy(workingList.concat(currentSelected), (e) => [e.id, e.parentId].join()) as any[];
    // Remove unselected items and previously selected items that are now descendents of selected items
    const newSelected = combinedList
        .filter((f) => f.selected === undefined || f.selected === true)
        .filter((f, _i, a) => !hasSelectedAncestor(entityList, f, a));
    const newSelectedEntities = newSelected.map((e) => {
        return { id: e.id, parentId: e.parentId };
    });
    return newSelectedEntities;
};
