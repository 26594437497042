import { all, fork } from 'redux-saga/effects';

import entitiesSaga from './entities/sagas';
import userSaga from './user/sagas';
import uiSaga from './ui/sagas';
import callbacksSaga from './callbacks';
import filesSaga from './files/sagas';
import integrationSaga from './integrations/sagas';
import aiSaga from './ai/sagas';

export function* rootSaga() {
    yield all([
        fork(entitiesSaga),
        fork(userSaga),
        fork(uiSaga),
        fork(callbacksSaga),
        fork(filesSaga),
        fork(integrationSaga),
        fork(aiSaga),
    ]);
}
