import React, { useEffect, useState } from 'react';
import { useCreateEntity } from '@/hooks/reduxHooks';
import Button from '../../DesignSystem/Button';
import { useNewEntityWindow } from '../newEntityWindow';
import AddMdIcon from '@/icons/AddMdIcon';
import { useDispatch, useSelector } from 'react-redux';
import { showMenu } from '@/redux/ui/actions';
import { getMenuSelector } from '@/redux/ui/selectors';

interface Props {
    eid: string;
    label?: string;
    className?: string;
    skipFocus?: boolean;
    show?: boolean;
    type?: EntityType;
    toggleListOpen?: (id: string, open: boolean) => void;
    draft?: boolean;
    showModal?: boolean;
    onClickCallback?: () => void;
    variant?: 'plain' | 'primary' | 'secondary' | 'tertiary' | 'secondaryBlue';
}

export default function QuickCreateButton({
    eid,
    label,
    className,
    toggleListOpen,
    type = 'RichText',
    draft = false,
    showModal = false,
    onClickCallback,
    variant = 'plain',
}: Props): React.ReactElement {
    const createEntity = useCreateEntity(eid);
    const openNewEntityWindow = useNewEntityWindow();
    const dispatch = useDispatch();
    const menuParams = useSelector(getMenuSelector);
    const [isActive, setActive] = useState(false);

    const show = (e) => {
        if (toggleListOpen) toggleListOpen(eid, true);
        if (onClickCallback) onClickCallback();
        if (showModal) {
            createEntity(type, { isDraft: draft });
            openNewEntityWindow();
        } else {
            const el = e.target.getBoundingClientRect();
            dispatch(showMenu({ type: 'QuickCreateMenu', x: el.x, y: el.y + el.height / 1.25, eid }));
            setActive(true);
        }
    };

    useEffect(() => {
        if (eid !== menuParams?.eid) setActive(false);
    }, [menuParams?.eid]);

    return (
        <Button active={isActive} size="m" variant={variant} icon={AddMdIcon} className={className} onClick={show}>
            {label ? label : ''}
        </Button>
    );
}
