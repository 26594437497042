import { useDrag, useDrop } from 'react-dnd';
import { LISTITEM, ENTITY, TAB } from '@/dndTypes';
import React, { ReactElement, useRef, memo, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedEntities,
    setDraggingHListItem,
    removeAudibleContainer,
    removeActiveEntityContainer,
} from '@/redux/ui/actions';
import { getDraggingHListItemSelector, getIsAudibleContainer } from '@/redux/ui/selectors';
import { useEntity } from '@/hooks/reduxHooks';
import styles from './styles.module.scss';
import TabPresentation from './presentation';
import { IntegrationName } from '../../../../integrations/types';

const preventDetailsToggle = function (e): void {
    e.preventDefault();
};

interface TabProps {
    eid: string;
    persisted?: boolean;
    emptyTab?: boolean;
    entity?: Entity;
    isBrowsed?: boolean;
    isSelected?: boolean;
    handleItemClick?: (id: any, event: MouseEvent) => void;
    handleClose?: (id: any) => void;
    dropEntities?: (item, target) => void;
    disableDragDrop?: boolean;
    isIntegrationItem?: boolean;
    app?: IntegrationName;
    appId?: string;
    showClose?: boolean;
    // lastItem?: boolean;
}

const Tab = ({
    eid,
    persisted,
    emptyTab,
    isBrowsed,
    isSelected,
    handleItemClick,
    handleClose,
    dropEntities,
    disableDragDrop,
    isIntegrationItem,
    showClose,
    // lastItem,
    ...rest
}: TabProps & React.HTMLAttributes<HTMLDivElement>): ReactElement => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const entity = useEntity(eid, true, 'HListItem');
    const draggingHListItem = useSelector(getDraggingHListItemSelector);
    const isIntegrationItemInDataspace = entity?.['@type'] === 'IntegrationItem' && !isIntegrationItem;
    const isAudible = useSelector(getIsAudibleContainer(eid));

    const canDrop = useCallback(() => !disableDragDrop, [disableDragDrop]);
    const target = { eid };
    const _accept = [LISTITEM, ENTITY, TAB];
    const dropFunction = (item: DnDMovedItem, monitor): void => {
        if (!monitor.didDrop() && dropEntities) {
            dropEntities(item, target);
        }
    };

    const dropSettings = () => {
        return {
            accept: _accept,
            canDrop: canDrop,
            drop: dropFunction,
            collect: (monitor) => ({
                itemIsHovered: monitor.isOver({ shallow: true }) && monitor.canDrop(),
            }),
        };
    };

    const [{ itemIsHovered }, drop] = useDrop(dropSettings());

    // Drag settings
    const [{ isDragging }, drag] = useDrag({
        type: TAB,
        item: {
            type: TAB,
            eid,
            additionalTypes: entity?.additionalTypes,
        },
        collect: (monitor) => ({
            monitor: monitor,
            isDragging: monitor.isDragging(),
        }),
        end: (item: DnDMovedItem, monitor) => {
            const res = monitor.getDropResult();
            return { res, item };
        },
        canDrag: (_monitor) => {
            return true;
        },
    });

    useEffect(() => {
        if (itemIsHovered && !draggingHListItem) dispatch(setDraggingHListItem(true));
    }, [itemIsHovered]);

    useEffect(() => {
        if (isDragging !== draggingHListItem) dispatch(setDraggingHListItem(isDragging));
        if (isDragging && !isSelected) {
            dispatch(setSelectedEntities([{ id: eid }]));
        }
    }, [isDragging]);

    drag(ref);

    const idAndParentId = {
        id: eid,
        isIntegrationItem: entity?.['@type'] === 'IntegrationItem',
        isIntegrationItemInDataspace,
    } as EntityIdentity;
    if (!isIntegrationItemInDataspace) {
        idAndParentId.app = entity?.app;
        idAndParentId.appId = entity?.appId;
    }

    const onClick = useCallback(
        (e): void => {
            e.stopPropagation();
            handleItemClick(idAndParentId, e);
        },
        [entity],
    );

    const onClose = useCallback(
        (e): void => {
            e.stopPropagation();
            handleClose(idAndParentId);
            if (isAudible) {
                dispatch(removeActiveEntityContainer(eid));
                dispatch(removeAudibleContainer(eid));
            }
        },
        [entity, isAudible],
    );

    return (
        <div className={styles.baseBackground}>
            <div
                ref={ref}
                data-hlistitemid={eid}
                data-eid={eid}
                onClick={onClick}
                className={isDragging ? styles.dragging : ''}
                id={eid}
                {...rest}
            >
                {(entity || isIntegrationItem || emptyTab) && (
                    <TabPresentation
                        drop={drop}
                        eid={eid}
                        emptyTab={emptyTab}
                        persisted={persisted}
                        name={emptyTab ? 'New Tab' : entity?.name}
                        entity={entity}
                        isBrowsed={isBrowsed}
                        isHovered={itemIsHovered}
                        onClick={onClick}
                        onClose={onClose}
                        preventDetailsToggle={preventDetailsToggle}
                        isIntegrationItem={isIntegrationItem}
                        showClose={showClose}
                        mediaPlaying={isAudible}
                    />
                )}
            </div>
        </div>
    );
};

export default memo(Tab);
