import React from 'react';
import styles from './systemBackground.module.scss';
import Image from 'next/image';
import { useAppBackground } from '@/hooks/reduxHooks';

const SystemBackground: React.FunctionComponent = () => {
    const { setBackgroundImage, backgroundOptions } = useAppBackground();

    return (
        <div className={styles.imageGrid}>
            {backgroundOptions.map((image, index) => (
                <div
                    key={index}
                    className={`${styles.imageGridItemSelectionWrapper} ${image.selected && styles.selected}`}
                    onClick={() => setBackgroundImage(image.preview, image.src)}
                >
                    <div className={styles.imageGridItem}>
                        <Image src={image.preview} width={200} height={130} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default SystemBackground;
