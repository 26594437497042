import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@/redux/user/selectors';
import socket from '../../SharedSocket';
import styles from './UserAvatars.module.scss';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getBrowsedInstanceSelector, getSelectedEntitiesSelector } from '@/redux/ui/selectors';
import { getUserColor } from '@/utils/helpers';
// import { getDsidSelector } from '../../../redux/entities/selectors';

export const UserAvatar = ({ user, className }: { user: Entity; className?: string }) => {
    const color = user?.color ? user.color : getUserColor(user?.metadata?.id);
    return (
        <div className={className ? className : styles.avatar} style={{ backgroundColor: color }}>
            {user.avatarUrl ? <img className={styles.avatarImg} src={user.avatarUrl} /> : <span>{user.name[0]}</span>}
        </div>
    );
};

type UserAvatarProps = {
    eid: string;
    showIcons: boolean;
    context: 'EntityFrame' | 'WindowFrame' | 'CondensedEntity';
    isHovered?: boolean;
};

const DROP_THRESHOLD_MS = 10000;

const UserAvatars: React.FunctionComponent<UserAvatarProps> = (props: UserAvatarProps) => {
    const ws = socket;
    const currentUser = useSelector(getUserSelector);
    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const [socketUsers, setSocketUsers] = useState({});
    const entityTarget = `activity-entity-${props.eid}`;

    const wsOnMessage = (d: WebsocketMessage & { entityTarget: string; state: any }): void => {
        if (d.entityTarget !== entityTarget) return;
        const userMessage = JSON.parse(d.state.user);
        const messageEid = d.state.eid;

        // The WebSocket message we received is for this dataspace channel, so
        // we'll filter ourselves out and set the local multi-socketUsers state
        if (props.eid !== messageEid) return;

        setSocketUsers((prev) => ({
            ...prev,
            [userMessage.id]: {
                user: userMessage,
                eid: messageEid,
                focused: d.state.focused,
                timestamp: Date.now(),
            },
        }));
    };

    useEffect(() => {
        const intervalID = setInterval(() => {
            setSocketUsers((prev) => {
                // Filter out all user entries with a timestamp older than the threshold
                const entries = Object.entries(prev);
                const newEntries = entries.filter(([_key, user]: any[]) => {
                    return Date.now() - user?.timestamp < DROP_THRESHOLD_MS;
                });

                if (entries.length === newEntries.length) return prev;
                return Object.fromEntries(newEntries);
            });
        }, DROP_THRESHOLD_MS);

        ws.on('message', wsOnMessage);

        // Clear websocket and interval
        return (): void => {
            clearInterval(intervalID);
            ws.off('message', wsOnMessage);
        };
    }, [props.eid]);

    useEffect(() => {
        if (['EntityFrame', 'WindowFrame'].includes(props.context) && socketUsers) {
            setSocketUsers((prev) => {
                // Filter out all user entries with that are not for the selected entity
                const entries = Object.entries(prev);
                const newEntries = entries.filter(([_key, user]: any[]) => {
                    return user?.eid === props.eid;
                });

                if (entries.length === newEntries.length) return prev;
                return Object.fromEntries(newEntries);
            });
        }
    }, [browsedInstance?.id]);

    if (!props.showIcons || Object.keys(socketUsers).length < 1) return null;
    const showRightBarrier = props.context == 'EntityFrame' || (props.context == 'CondensedEntity' && props.isHovered);
    const containerClass = `${styles.avatarsContainer} ${showRightBarrier ? styles.avatarBarrier : ''}`;

    // Different background color when in highlighted row
    const isInBlue =
        props.context === 'CondensedEntity' && [...selectedEntities, browsedInstance].some((e) => e.id === props.eid);
    const avatarClass = `${styles.avatar} ${isInBlue ? styles.avatarBlueBorder : styles.avatarWhiteBorder}`;

    return (
        <React.Fragment>
            <div className={containerClass}>
                {Object.keys(socketUsers).length > 0 && browsedInstance?.id === props.eid ? (
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 20, hide: 0 }}
                        overlay={
                            <Tooltip id="avatar-current-user">
                                <span className="textXXS">{currentUser.name}</span>
                            </Tooltip>
                        }
                    >
                        <div className={avatarClass} style={{ backgroundColor: currentUser.color }}>
                            {currentUser.avatarUrl ? (
                                <img className={styles.avatarImg} src={currentUser.avatarUrl} />
                            ) : (
                                <span>{currentUser.name?.[0]}</span>
                            )}
                        </div>
                    </OverlayTrigger>
                ) : null}
                {Object.values(socketUsers).map((u: any) => {
                    const focusedStyle = u.focused ? '' : styles.avatarUnfocused;
                    return (
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 20, hide: 0 }}
                            overlay={
                                <Tooltip id={`avatar-${u.user.id}`}>
                                    <span className="textXXS">{u.user?.name && u.user.name}</span>
                                </Tooltip>
                            }
                            key={u.user.id}
                        >
                            <div key={u.user.id} className={avatarClass}>
                                <div
                                    className={`${focusedStyle} ${styles.avatarForeground}`}
                                    style={{ backgroundColor: u.user.color, width: '100%', height: '100%' }}
                                >
                                    {u.user.avatarUrl ? (
                                        <img className={styles.avatarImg} src={u.user.avatarUrl} />
                                    ) : (
                                        <span>{u.user?.name?.[0]}</span>
                                    )}
                                </div>
                            </div>
                        </OverlayTrigger>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default UserAvatars;
