import styles from './styles.module.scss';

type Props = {
    progress: number;
    isFolder: boolean;
};
const LoadingIndicator = ({ progress, isFolder }: Props): JSX.Element => {
    return (
        <>
            {!isFolder && <span>{progress}%</span>}
            <span className={`${styles.loadingSpinner} spinner-border`}></span>
        </>
    );
};

export default LoadingIndicator;
