import React from 'react';
import typeInfo from '@/components/entities/typeInfo';
import BlobMdIcon from '@/icons/BlobMdIcon';
import CodeMdIcon from '@/icons/CodeMdIcon';
import TextMdIcon from '@/icons/TextMdIcon';
import UploadIcon from '@/icons/UploadIcon';
import GlobeMdIcon from '@/icons/GlobeMdIcon';
import FolderIcon from '@/icons/FolderIcons/FolderIcon';
import {
    AppstoreOutlined,
    BankOutlined,
    CarOutlined,
    ContactsOutlined,
    CreditCardOutlined,
    HomeOutlined,
    IdcardOutlined,
    ShopOutlined,
} from '@ant-design/icons';

export const entityTypes = [
    { value: 'HList', icon: <FolderIcon /> },
    { value: 'RichText', icon: <TextMdIcon /> },
    { value: 'Webwindow', icon: <GlobeMdIcon /> },
    { value: 'Blob', icon: <BlobMdIcon /> },
    { value: 'Code', icon: <CodeMdIcon /> },
    { value: 'DigitalDocument', icon: <UploadIcon /> },
].map((item) => ({ ...item, type: 'entityType', label: typeInfo[item.value].fancyName }));

export const objectTypes = [
    {
        label: 'Application Account',
        value: 'account',
        icon: <AppstoreOutlined />,
    },
    {
        label: 'Bank Account',
        value: 'bank_account',
        icon: <BankOutlined />,
    },
    {
        label: "Driver's License",
        value: 'drivers_license',
        icon: <ContactsOutlined />,
    },
    {
        label: 'Legal Entity',
        value: 'legal_entity',
        icon: <ShopOutlined />,
    },
    {
        label: 'Passport',
        value: 'passport',
        icon: <IdcardOutlined />,
    },
    {
        label: 'Payment Card',
        value: 'payment_card',
        icon: <CreditCardOutlined />,
    },
    {
        label: 'Real Estate Property',
        value: 'real_estate',
        icon: <HomeOutlined />,
    },
    {
        label: 'Vehicle',
        value: 'vehicle',
        icon: <CarOutlined />,
    },
].map((item) => ({ ...item, type: 'objectType' }));

export const menuItems: MenuItem[] = [...entityTypes, ...objectTypes];

export const filterItemsByLabel = (items: MenuItem[], filter: string) => {
    const lowerCaseFilter = filter.toLocaleLowerCase();
    const res = items.filter((item) => item.label.toLocaleLowerCase().includes(lowerCaseFilter));
    // sort res by relevance to filter
    res.sort((a, b) => {
        const aIndex = a.label.toLocaleLowerCase().indexOf(lowerCaseFilter);
        const bIndex = b.label.toLocaleLowerCase().indexOf(lowerCaseFilter);
        return aIndex - bIndex;
    });
    return res;
};

export const findMenuItem = (items: MenuItem[], value: string) => {
    return items.find((item) => item.value === value);
};

export const createIntegrationFilesApps = ['drive'];

export const integrationCreateTypes = {
    drive: [
        {
            label: 'Google Docs',
            mimeType: 'application/vnd.google-apps.document',
            iconUrl: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.document',
        },
        {
            label: 'Google Sheets',
            mimeType: 'application/vnd.google-apps.spreadsheet',
            iconUrl: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.spreadsheet',
        },
        {
            label: 'Google Slides',
            mimeType: 'application/vnd.google-apps.presentation',
            iconUrl: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.presentation',
        },
        {
            label: 'Google Drawings',
            mimeType: 'application/vnd.google-apps.drawing',
            iconUrl: 'https://drive-thirdparty.googleusercontent.com/16/type/application/vnd.google-apps.drawing',
        },
    ],
};
