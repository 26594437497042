import { find } from 'lodash';
import { getUserColor } from '@/utils/helpers';
import { v4 as uuidv4 } from 'uuid';

import {
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_FAILURE,
    SET_ANONYMOUS_USER,
    COMPLETE_USER_SIGNUP,
    COMPLETE_USER_SIGNUP_SUCCESS,
    COMPLETE_USER_SIGNUP_FAILURE,
    UPDATE_ENTITY_SUCCESS,
    BULK_CREATE_ENTITIES_SUCCESS,
    BULK_UPDATE_ENTITIES_SUCCESS,
    BULK_REMOVE_CHILD_EIDS_SUCCESS,
    BULK_MOVE_ENTITIES_SUCCESS,
    UPDATE_USER_SUCCESS,
    DISMISS_PROMPT,
    CREATE_DATASPACE_SUCCESS,
} from '../actionTypes';

import { UserState } from '../types';

const initialState = {
    id: null,
    name: '',
    first_name: '',
    last_name: '',
    color: '#e78ef7',
    avatarUrl: null,
    email: null,
    entity: {} as Entity,
    pending: false,
    error: null,
    dsid: null,
    memberships: {},
    initialUserLoad: true,
    dismissedPrompts: [],
    integrationAccounts: {},
} as UserState;

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER: {
            return {
                ...state,
                pending: true,
                initialUserLoad: false,
            };
        }
        case GET_USER_SUCCESS: {
            const user = action.payload.user;
            return {
                ...state,
                id: user.id,
                name: user.name,
                dsid: user.dsid,
                email: user.email,
                color: getUserColor(user.id),
                entity: user.entity,
                avatarUrl: user.entity?.avatarUrl,
                memberships: user.entity?.memberships || { [user.dsid]: 'member' },
                first_name: user.entity?.first_name,
                last_name: user.entity?.last_name,
                dismissedPrompts: user.entity?.dismissedPrompts || [],
                integrationAccounts: user.entity?.integrationAccounts || {},
                pending: false,
                error: null,
            };
        }
        case UPDATE_USER_SUCCESS:
        case UPDATE_ENTITY_SUCCESS:
        case BULK_CREATE_ENTITIES_SUCCESS:
        case BULK_UPDATE_ENTITIES_SUCCESS:
        case BULK_MOVE_ENTITIES_SUCCESS:
        case BULK_REMOVE_CHILD_EIDS_SUCCESS: {
            const user = find(action.payload.entities || action.payload.users, ['id', state.id]);
            if (user) {
                return {
                    ...state,
                    dsid: user.dsid,
                    entity: user.entity,
                    name: user.entity?.name,
                    email: user.entity?.email,
                    avatarUrl: user.entity?.avatarUrl,
                    memberships: user.entity?.memberships,
                    first_name: user.entity?.first_name,
                    last_name: user.entity?.last_name,
                    dismissedPrompts: user.entity?.dismissedPrompts || [],
                    integrationAccounts: user.entity?.integrationAccounts || {},
                    pending: false,
                    error: null,
                };
            } else return state;
        }
        case GET_USER_FAILURE: {
            return {
                ...state,
                pending: false,
                error: action.payload.error,
            };
        }
        case CREATE_USER: {
            return {
                ...state,
                pending: true,
            };
        }
        case CREATE_USER_SUCCESS: {
            const user = action.payload.user;
            if (!state.id) {
                // I am creating my own account
                return {
                    ...state,
                    id: user.id,
                    dsid: user.dsid,
                    entity: user.entity,
                    name: user.entity?.name,
                    email: user.entity?.email,
                    color: getUserColor(user.id),
                    avatarUrl: user.entity?.avatarUrl,
                    memberships: user.entity?.memberships,
                    first_name: user.entity?.first_name,
                    last_name: user.entity?.last_name,
                    dismissedPrompts: user.entity?.dismissedPrompts || [],
                    integrationAccounts: user.entity?.integrationAccounts || {},
                    pending: false,
                    error: null,
                };
            } else {
                // I added someone else
                return {
                    ...state,
                    pending: false,
                    error: null,
                };
            }
        }
        case CREATE_USER_FAILURE: {
            return {
                ...state,
                pending: false,
                error: action.payload.error,
            };
        }
        case COMPLETE_USER_SIGNUP: {
            return {
                ...state,
                pending: true,
            };
        }
        case COMPLETE_USER_SIGNUP_SUCCESS: {
            const user = find(action.payload.results.update_people.returning, ['id', state.id]);
            return {
                ...state,
                id: user.id,
                dsid: user.dsid,
                entity: user.entity,
                color: getUserColor(user.id),
                name: user.entity?.name,
                email: user.entity?.email,
                avatarUrl: user.entity?.avatarUrl,
                memberships: user.entity?.memberships,
                first_name: user.entity?.first_name,
                last_name: user.entity?.last_name,
                dismissedPrompts: user.entity?.dismissedPrompts || [],
                integrationAccounts: user.entity?.integrationAccounts || {},
                pending: false,
                error: null,
            };
        }
        case COMPLETE_USER_SIGNUP_FAILURE: {
            return {
                ...state,
                pending: false,
                error: action.payload.error,
            };
        }
        case SET_ANONYMOUS_USER: {
            const id = 'AAAAAAAA' + uuidv4().substring(8);
            return {
                ...state,
                name: 'Anonymous',
                id: id,
                color: getUserColor(id),
            };
        }
        case DISMISS_PROMPT: {
            const { prompt } = action.payload;
            const dismissedPrompts = state.dismissedPrompts || [];
            if (dismissedPrompts?.includes(prompt)) return state;
            return {
                ...state,
                dismissedPrompts: [...dismissedPrompts, prompt],
            };
        }
        case CREATE_DATASPACE_SUCCESS: {
            const entityArray = action.payload.results.update_people.returning;
            const user = find(entityArray, (e) => e.entity?.['@type'] === 'Person');
            return {
                ...state,
                entity: user.entity,
                memberships: user?.entity?.memberships,
            };
        }
        default:
            return state;
    }
};

export default UserReducer;
