import React from 'react';
import styles from './style.module.scss';
import EntityImageIcon from '@/components/EntityImageIcon';

export default function FolderIcon({ opened, localFolder }: { opened?: boolean; localFolder?: boolean }): JSX.Element {
    const src = opened ? '/icons/folderOpenedIcon.png' : '/icons/folderIcon.png';
    return (
        <EntityImageIcon src={src} className={`${styles.folderIcon} ${!localFolder ? styles.bedrockFolderIcon : ''}`} />
    );
}
