import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getAllCollaborators } from '@/redux/entities/actions';
import { getUserSelector, getPendingSelector } from '@/redux/user/selectors';
import { updateUser } from '@/redux/entities/actions';
import { cloneEntity, uploadFiles } from '@/utils/helpers';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Head from 'next/head';
import { Form, Col } from 'react-bootstrap';
import styles from '../../forms/forms.module.scss';
import Button from '../../DesignSystem/Button';
import AvatarButton from '../../DesignSystem/Button/AvatarButton';
import userAccountStyles from './userAccount.module.scss';

const UserSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
});

const UserAccount = function (): React.ReactElement {
    const user = useSelector(getUserSelector);
    const pending = useSelector(getPendingSelector);
    const dispatch = useDispatch();
    const [isSubmittingUser, setIsSubmittingUser] = useState(pending);
    const [saveUserButtonVisibility, setSaveUserButtonVisibility] = useState(false);
    const [avatarPath, setAvatarPath] = useState(user?.avatarUrl);
    const [avatar, setAvatar] = useState(null);

    useEffect(() => {
        dispatch(getAllCollaborators());
    }, []);

    useEffect(() => {
        setAvatarPath(user.avatarUrl);
    }, [user.avatarUrl]);

    useEffect(() => {
        if (!pending) {
            setIsSubmittingUser(pending);
        }
    }, [pending]);

    const saveUser = (values) => {
        if (avatar) {
            setIsSubmittingUser(true);
            uploadFiles([avatar], {}).then((docEntities) => {
                const avatarUrl = docEntities[0]?.url;
                const newEntity = Object.assign({}, cloneEntity(user.entity), values, { avatarUrl }) as Entity;
                dispatch(updateUser(user.id, newEntity));
                setIsSubmittingUser(false);
            });
        } else {
            const newEntity = Object.assign({}, cloneEntity(user.entity), values) as Entity;
            dispatch(updateUser(user.id, newEntity));
        }

        setSaveUserButtonVisibility(false);
        setAvatar(null);
    };

    const checkUserFieldChange = (fieldName, handler, originalValue, values) => (event) => {
        if (fieldName === 'name') {
            if (event.target.value !== originalValue || avatar) {
                setSaveUserButtonVisibility(true);
            } else setSaveUserButtonVisibility(false);
        } else if ((event?.target?.files?.[0] && avatar) || values.name !== user.name) {
            setSaveUserButtonVisibility(true);
        } else {
            setSaveUserButtonVisibility(false);
        }
        handler(event);
    };

    const handleFileChange = (file: any) => {
        setAvatar(file);
        setAvatarPath(URL.createObjectURL(file));
        setSaveUserButtonVisibility(true);
    };

    return (
        <div className={userAccountStyles.userAccountMainContainer}>
            <Head>
                <meta property="og:title" content={'Settings - Bedrock'} />
                <title>Settings - Bedrock</title>
            </Head>
            <Formik
                validationSchema={UserSchema}
                validateOnChange={true}
                enableReinitialize
                onSubmit={(values) => {
                    saveUser(values);
                }}
                initialValues={{
                    name: user.name,
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors }) => (
                    <Form noValidate onSubmit={handleSubmit} id="settingsForm" style={{ paddingBottom: 20 }}>
                        <div className={userAccountStyles.headerContainer}>
                            <div className={userAccountStyles.avatarNameContainer}>
                                <div className={userAccountStyles.avatarButtonContainer}>
                                    <AvatarButton onChange={handleFileChange} value={avatarPath} />
                                </div>
                                <div>
                                    <Form.Label className={`${styles.formLabel} textXXS medium`}>Your Email</Form.Label>
                                    <div className={'semiBold'}>{user.email}</div>
                                    <Form.Group controlId="formUserName" as={Col} style={{ paddingLeft: 0 }}>
                                        <Form.Label className={`${styles.formLabel} textXXS medium`}>
                                            Your Name
                                        </Form.Label>
                                        <Form.Control
                                            className={styles.formControl}
                                            type="text"
                                            onChange={checkUserFieldChange('name', handleChange, user.name, values)}
                                            name="name"
                                            value={values.name}
                                            isValid={touched.name && !errors.name}
                                            isInvalid={!!errors.name}
                                            style={{ width: '100%' }}
                                        />
                                        {errors.name ? <div className={styles.error}>{errors.name}</div> : null}
                                    </Form.Group>
                                </div>
                            </div>
                            {saveUserButtonVisibility && (
                                <Button variant="primary" size="s" disabled={isSubmittingUser} type="submit">
                                    {isSubmittingUser ? 'Processing' : 'Save'}
                                </Button>
                            )}
                        </div>
                        <div className={userAccountStyles.comingSoon} style={{ padding: 24 }}>
                            <Form.Group controlId="formPassword" as={Col} style={{ paddingLeft: 0 }}>
                                <Form.Label className={`${styles.formLabel} textXXS medium`}>Password</Form.Label>
                                <p>Set a permanent password if you don’t want to use temporary log-in codes.</p>
                            </Form.Group>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default UserAccount;
