import { getActiveEntityContainersSelector, getBrowsedInstanceSelector } from '@/redux/ui/selectors';
import { useSelector } from 'react-redux';
import EntityContainer from './EntityContainer';
import { memo, useEffect, useMemo } from 'react';
import Portal from '../Portal';
import styles from './Entity.module.scss';

const EntityContainerProvider = () => {
    const activeEntityContainers = useSelector(getActiveEntityContainersSelector);
    const { id, app, appId } = useSelector(getBrowsedInstanceSelector);

    const Containers = useMemo(
        () =>
            activeEntityContainers
                ? activeEntityContainers.map((containerProps) => {
                      if (!containerProps) {
                          return null;
                      }
                      return (
                          <EntityContainer
                              {...containerProps}
                              key={`${containerProps.eid}_${containerProps.app}_${containerProps.appId}`}
                          />
                      );
                  })
                : [],
        [activeEntityContainers],
    );

    useEffect(() => {
        const uniqueId = `${id}${app ? `_${app}` : ''}${appId ? `_${appId}` : ''}`;

        const containers = document.querySelectorAll('[data-unique-id]') as NodeListOf<HTMLElement>;

        containers.forEach((container) => {
            if (container.dataset.uniqueId === uniqueId) {
                container.classList.remove(`${styles.inactive}`);
            } else if (!container.classList.contains(`${styles.inactive}`)) {
                container.classList.add(`${styles.inactive}`);
            }
        });
    }, [Containers]);

    return <Portal targetId="entity-container-consumer">{Containers}</Portal>;
};

export default memo(EntityContainerProvider);
