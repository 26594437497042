import React from 'react';
import { Dropdown } from 'react-bootstrap';
import contextMenuStyles from '../../modals/contextMenu.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getDataspaceIdsSelector } from '../../../redux/ui/selectors';
import { useEntities } from '../../../hooks/reduxHooks';
import { saveDrafts } from '../../../redux/entities/actions';
import buttonStyles from './SaveToDataspaceButton.module.scss';
import AddToDataSpaceIcon from '@/icons/AddToDataSpaceIcon';
import { addIntegrationItemToDataspace } from 'redux/integrations/actions';

interface Props {
    eid: string;
    entity?: Entity;
    fullButton?: boolean;
}

export default function SaveToDataspaceButton({ eid, entity, fullButton }: Props): React.ReactElement {
    const dispatch = useDispatch();
    const dataspaceIds = useSelector(getDataspaceIdsSelector);
    const dataspaces = useEntities(dataspaceIds, false, 'SaveToDataspaceButton');
    const title = entity?.name;

    const handleDsClick = (e) => {
        if (entity?.['@type'] === 'IntegrationItem') {
            dispatch(addIntegrationItemToDataspace(entity as IntegrationItem, e.target.getAttribute('data-dsid')));
        } else {
            dispatch(saveDrafts([eid], e.target.getAttribute('data-dsid')));
        }
    };

    return (
        <Dropdown style={{ display: 'flex' }}>
            <Dropdown.Toggle
                className={`listItemMenuBtn textXS ${buttonStyles.saveButton} ${
                    fullButton ? buttonStyles.fullButton : 'g-dropdown-icon-button'
                }`}
            >
                {fullButton ? 'Add to Dataspace...' : <AddToDataSpaceIcon />}
            </Dropdown.Toggle>
            <Dropdown.Menu className={contextMenuStyles.menu}>
                {!fullButton && (
                    <div className={contextMenuStyles.subText} title={title}>
                        Add to dataspace...
                    </div>
                )}
                {dataspaces.map((ds) => {
                    return (
                        <Dropdown.Item
                            onClick={handleDsClick}
                            data-dsid={ds.id}
                            key={`quick-save-${ds.id}`}
                            className={contextMenuStyles.item}
                        >
                            {ds?.name}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    );
}
