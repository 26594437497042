import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import getConfig from 'next/config';

import rootReducer from './rootReducer';
import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();
const { publicRuntimeConfig } = getConfig();

const NODE_ENV = publicRuntimeConfig.nodeEnv;
const REDUX_LOGGER_COLLAPSED = publicRuntimeConfig.reduxLoggerCollapsed;

const logger = createLogger({
    collapsed: REDUX_LOGGER_COLLAPSED,
});

const store =
    NODE_ENV === 'development'
        ? createStore(rootReducer, applyMiddleware(sagaMiddleware, logger))
        : createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSaga);

export default store;
