export default function BedrockDiscoMiniIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="15" height="15" rx="2.57" fill="#fff" />
            <path
                d="M4.89 7.9a6.01 6.01 0 0 1-.53.26v.02a3.18 3.18 0 0 0 .2.58h.02c.34-.07.6-.22.9-.38a4.5 4.5 0 0 1 2.55-.66 12.57 12.57 0 0 0 2.68-.27v-.02a3.21 3.21 0 0 0-.13-.8.04.04 0 0 0-.03 0l-.03.01c-.7.28-1.68.65-3.08.65-1.38 0-2 .33-2.55.6ZM9.2 9.33a16.15 16.15 0 0 1 .94 0 3.2 3.2 0 0 0 .23-.4v-.01a.04.04 0 0 0-.03-.02H8.62c-1.17 0-1.69.31-2.2.61-.28.18-.55.34-.92.43a.04.04 0 0 0-.01.07 3.22 3.22 0 0 0 .68.4c.32-.07.56-.23.82-.4.49-.32 1.04-.68 2.21-.68Z"
                fill="#fff"
            />
            <path
                d="M9.2 9.7c-1.06 0-1.54.32-2 .62l-.4.25a.04.04 0 0 0-.02.06h.02a3.24 3.24 0 0 0 2.97-.86.04.04 0 0 0-.02-.06H9.2ZM4.72 7.57c.28-.15.6-.31 1.03-.44a6 6 0 0 1 1.68-.2c.58 0 1.16-.08 1.72-.22.42-.11.83-.25 1.24-.42l.04-.01a.04.04 0 0 0 .03-.04v-.01A3.21 3.21 0 0 0 4.3 7.75a.04.04 0 0 0 .03 0l.38-.18ZM6.23 9.2c.53-.32 1.12-.67 2.38-.67H10.52a.04.04 0 0 0 .04-.03 3.18 3.18 0 0 0 .12-.64.04.04 0 0 0-.03 0c-.68.13-1.52.23-2.62.23-.57 0-1.05.07-1.47.2-.36.12-.64.27-.9.42a3.6 3.6 0 0 1-.92.41.04.04 0 0 0 0 .03 3.24 3.24 0 0 0 .37.48c.47-.04.78-.23 1.12-.43Z"
                fill="#fff"
            />
            <circle cx="7.54" cy="7.54" r="6.01" fill="#fff" stroke="#fff" />
            <path
                d="M2.93 8.24c-.29.15-.58.3-.89.43a.06.06 0 0 0-.03.07c.07.34.17.67.3.98.01.03.05.04.08.03a5.2 5.2 0 0 0 1.59-.67c.98-.54 2.08-1.16 4.49-1.16 1.98 0 3.5-.2 4.69-.45.02 0 .05-.03.05-.06 0-.48-.08-.94-.2-1.37 0-.04-.05-.05-.08-.04l-.05.01a14 14 0 0 1-5.45 1.15c-2.43.02-3.52.59-4.5 1.08Z"
                fill="#973999"
            />
            <path
                d="M10.54 10.77c.57 0 1.1.02 1.6.05a5.33 5.33 0 0 0 .46-.7c.01-.06-.02-.1-.06-.1H9.51c-2.06 0-2.97.55-3.85 1.09-.5.3-1 .6-1.64.75-.05 0-.06.08-.02.11a5.8 5.8 0 0 0 1.2.73c.56-.14.98-.42 1.45-.73a6.15 6.15 0 0 1 3.9-1.2Z"
                fill="#F78200"
            />
            <path
                d="M10.55 11.43A5.5 5.5 0 0 0 7 12.53c-.23.14-.46.3-.71.43-.06.03-.05.11.01.12a5.7 5.7 0 0 0 5.24-1.53c.04-.04.01-.1-.04-.1-.31-.02-.63-.02-.96-.02Z"
                fill="#FFB900"
            />
            <path
                d="M6.3 12.97a25.94 25.94 0 0 0 5.25-1.42 5.64 5.64 0 0 1-5.24 1.53c-.06 0-.07-.09-.01-.11Z"
                fill="#5EBD3E"
            />
            <path
                d="M2.63 7.66a9.15 9.15 0 0 1 4.8-1.13c1.04 0 2.02-.13 3-.38.86-.22 1.57-.5 2.2-.73.03 0 .05-.02.08-.03.03-.01.05-.06.03-.1A5.66 5.66 0 0 0 1.87 7.55l.01.4c0 .04.06.06.1.05.22-.1.43-.22.65-.33Z"
                fill="#009CDF"
            />
            <path
                d="M5.26 10.46c.92-.55 1.96-1.18 4.2-1.18h3.36c.02 0 .05-.02.06-.05.11-.34.2-.7.24-1.06 0-.05-.04-.08-.08-.07-1.2.24-2.69.42-4.62.42-1 0-1.85.12-2.58.35-.65.2-1.12.47-1.6.73-.49.28-.97.54-1.57.7-.04 0-.06.05-.03.09.18.29.37.56.6.82.02.02.03.03.05.02.82-.07 1.36-.4 1.97-.77Z"
                fill="#E23838"
            />
        </svg>
    );
}
