import React from 'react';
// import { Dropdown as BootstrapDropdown } from 'react-bootstrap';
import DropdownStyles from './BedrockDropdownStyles.module.scss';

interface DropdownProps {
    size?: 's' | 'm' | 'l';
    variant?: 'primary' | 'secondary';
    icon?: JSX.Element;
    className?: string;
    dropdownItems?: Array<JSX.Element>;
    currentlySelectedItem?: JSX.Element;
}
// TODO: implement tippy here
// not worth using atm
const BedrockDropdown = ({
    size,
    variant,
    className,
    // dropdownItems,
    // currentlySelectedItem,
    children,
}: React.PropsWithChildren<DropdownProps>): JSX.Element => {
    const dropdownClasses = [DropdownStyles[variant], DropdownStyles[size], DropdownStyles.dropdown];
    const dropdownClassesString = `${dropdownClasses.join(' ')} ${className}`;

    return (
        <div className={dropdownClassesString}>
            {/* <BootstrapDropdown style={{ height: '100%' }}>
                <BootstrapDropdown.Toggle
                    id="dropdown-autoclose-true"
                    className={`${DropdownStyles.dropdownToggle} ${dropdownClassesString} bold textS`}
                >
                    {currentlySelectedItem}
                    <DownOutlined />
                </BootstrapDropdown.Toggle>
                <BootstrapDropdown.Menu className="menuSmall appDropdown textS">{dropdownItems}</BootstrapDropdown.Menu>
            </BootstrapDropdown> */}
            {children}
        </div>
    );
};

export default BedrockDropdown;
