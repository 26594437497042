import '../wdyr';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/prosemirror.scss';
import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import '../styles.scss';
import '../components/panels/index.scss';
import '../styles/emojiPicker.scss';

import { Provider } from 'react-redux';
import store from '../redux/store';
import { SessionProvider } from 'next-auth/react';

import * as Sentry from '@sentry/browser';
import { configure } from 'react-hotkeys';
import GlobalModal from '../components/modals/GlobalModal';
import AddIntegrationPopup from '../components/modals/addIntegrationPopup';
import GlobalShortcutsHandler from '../components/GlobalShortcutsHandler';
import PanelEntity from '../components/panels/PanelEntity';
import AboutBedrockPopup from '../components/modals/aboutBedrockPopup';
import PanelShortcuts from '../components/panels/PanelShortcuts';
import Auth from '../components/Auth';
import NewEntityWindow from '../components/modals/newEntityWindow';
import Toast from '../components/modals/toast';
import { UploadFilesToast } from '../components/modals/uploadFilesToast';
import { useRouter } from 'next/router';
import { UrlObject } from 'url';
import Menu from '@/components/modals/Menu';
import GoogleAnalytics from '@/components/GoogleAnalytics/GoogleAnalytics';
import EntityContainerProvider from '@/components/entities/EntityContainerProvider';
import Loader from '@/components/Loader';
import { useJune } from '@/hooks/useJune';
import { getNextAuthSession } from 'integrations/helpers';
import useViewportHeight from '@/hooks/useViewportHeight';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://6480b23352d64256af344e29e4df0172@o375011.ingest.sentry.io/5194107',
        release: 'fabric@' + process.env.npm_package_version,
        ignoreErrors: ['ResizeObserver loop limit exceeded', 'GUEST_VIEW_MANAGER_CALL'],
    });
}

posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    loaded: (posthog) => {
        if (process.env.STAGE === 'true' || process.env.NODE_ENV === 'development') posthog.debug();
    },
});

const GLOBAL_STYLES = ':root {--app-height: 100vh;}';

const isProduction = process.env.NODE_ENV === 'production';

export default function App({ Component, pageProps }) {
    const Layout = Component.layout || React.Fragment;
    const router = useRouter();
    const analytics = useJune();
    const [loading, setIsLoading] = useState(false);

    configure({
        ignoreTags: [],
        // ignoreEventsCondition: function() {}
    });

    useEffect(() => {
        if (typeof window !== undefined) {
            (window as any).routerPush = (url: UrlObject | string, as?: UrlObject | string, options?: any) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return router.push(url, as, options);
            };
        }
    }, []);

    useEffect(() => {
        if (typeof window !== undefined) {
            const identify = async () => {
                if (analytics) {
                    const session = await getNextAuthSession();
                    if (session) {
                        analytics.identify(session.id, {
                            email: session.user?.email,
                        });
                    }
                }
            };

            identify();

            const handleRouteChange = (url) => {
                if (analytics) analytics.page(url);
            };

            router.events.on('routeChangeComplete', handleRouteChange);

            return () => {
                router.events.off('routeChangeComplete', handleRouteChange);
            };
        }
    }, [analytics]);

    useEffect(() => {
        const setLoading = () => {
            setIsLoading(true);
        };

        const setLoaded = () => {
            setIsLoading(false);
        };

        router.events.on('routeChangeStart', setLoading);
        router.events.on('routeChangeComplete', setLoaded);

        return () => {
            router.events.off('routeChangeStart', setLoading);
            router.events.off('routeChangeComplete', setLoaded);
        };
    }, []);

    useEffect(() => {
        const handleRouteChange = () => posthog?.capture('$pageview');
        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    useViewportHeight();

    return (
        <SessionProvider session={pageProps.session} refetchOnWindowFocus={false}>
            <PostHogProvider client={posthog}>
                {Component.auth ? (
                    <DndProvider backend={HTML5Backend}>
                        <Provider store={store}>
                            <Auth entityView={Component.entityView} singleNoteView={Component.singleNoteView}>
                                {!Component.singleNoteView && (
                                    <>
                                        <AddIntegrationPopup />
                                        <UploadFilesToast />
                                        <EntityContainerProvider />
                                    </>
                                )}
                                <GlobalModal />
                                <GlobalShortcutsHandler />
                                <PanelEntity />
                                <AboutBedrockPopup />
                                <PanelShortcuts />
                                <NewEntityWindow />
                                <Toast />
                                <Menu />
                                <Layout>
                                    <Head>
                                        <title>Bedrock Computer — Creative team space</title>
                                    </Head>
                                    {loading && <Loader />}
                                    <style>{GLOBAL_STYLES}</style>
                                    <Component {...pageProps} />
                                </Layout>
                            </Auth>
                        </Provider>
                    </DndProvider>
                ) : (
                    <DndProvider backend={HTML5Backend}>
                        <Provider store={store}>
                            <AboutBedrockPopup />
                            <PanelShortcuts />
                            <GlobalModal />
                            {isProduction && <GoogleAnalytics />}
                            <Layout>
                                <Head>
                                    <title>Bedrock</title>
                                </Head>
                                <style>{GLOBAL_STYLES}</style>
                                <Component {...pageProps} />
                            </Layout>
                        </Provider>
                    </DndProvider>
                )}
            </PostHogProvider>
        </SessionProvider>
    );
}
