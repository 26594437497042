export default function BedrockNotesAppIcon(): JSX.Element {
    return (
        <svg width="1024" height="1024" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1315_4978)">
                <g clipPath="url(#clip0_1315_4978)">
                    <rect x="100" y="100" width="824" height="824" rx="184" fill="#5C5C5C" fillOpacity="0.3" />
                    <circle cx="512" cy="512" r="577" fill="url(#paint0_radial_1315_4978)" fillOpacity="0.5" />
                    <g filter="url(#filter1_dd_1315_4978)">
                        <rect
                            x="294.472"
                            y="344.598"
                            width="333.58"
                            height="436.057"
                            rx="57.237"
                            transform="rotate(-15 294.472 344.598)"
                            fill="white"
                        />
                        <path
                            d="M386.8 457.181L508.626 424.537M408.902 539.667L626.453 481.337M431.004 622.154L590.902 579.31"
                            stroke="#666666"
                            strokeWidth="25.619"
                        />
                        <rect
                            x="294.472"
                            y="344.598"
                            width="333.58"
                            height="436.057"
                            rx="57.237"
                            transform="rotate(-15 294.472 344.598)"
                            stroke="#B3B3B3"
                            strokeWidth="8.00593"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_1315_4978"
                    x="78"
                    y="89"
                    width="868"
                    height="868"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1315_4978" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1315_4978" result="shape" />
                </filter>
                <filter
                    id="filter1_dd_1315_4978"
                    x="161.821"
                    y="184.095"
                    width="700.358"
                    height="700.358"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="5.56848"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_1315_4978"
                    />
                    <feOffset dy="22.2739" />
                    <feGaussianBlur stdDeviation="22.2739" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1315_4978" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="5.56848"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect2_dropShadow_1315_4978"
                    />
                    <feOffset />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1315_4978" result="effect2_dropShadow_1315_4978" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1315_4978" result="shape" />
                </filter>
                <radialGradient
                    id="paint0_radial_1315_4978"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(512 512) rotate(-180) scale(577)"
                >
                    <stop stopColor="#DEE2E9" stopOpacity="0.71" />
                    <stop offset="1" stopColor="#D4DBE6" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_1315_4978">
                    <rect x="100" y="100" width="824" height="824" rx="184" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
