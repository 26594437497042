export default function AddMdIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.5 0.875C7.84518 0.875 8.125 1.15482 8.125 1.5V6.875H13.5C13.8452 6.875 14.125 7.15482 14.125 7.5C14.125 7.84518 13.8452 8.125 13.5 8.125H8.125V13.5C8.125 13.8452 7.84518 14.125 7.5 14.125C7.15482 14.125 6.875 13.8452 6.875 13.5V8.125H1.5C1.15482 8.125 0.875 7.84518 0.875 7.5C0.875 7.15482 1.15482 6.875 1.5 6.875H6.875V1.5C6.875 1.15482 7.15482 0.875 7.5 0.875Z"
                fill="black"
            />
        </svg>
    );
}
