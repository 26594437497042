import React, { memo, useEffect } from 'react';
import styles from './Entity.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationPendingSelector } from 'redux/integrations/selectors';
import { getIntegrationItems } from 'redux/integrations/actions';
import { getIntegrationAccountsSelector } from 'redux/user/selectors';
import { INTEGRATIONS } from '../../integrations/constants';
import { useRouter } from 'next/router';
import EntityHList from './EntityHList';

const EntityIntegrationItem: React.FunctionComponent<EntityContainerProps> = (props: EntityContainerProps) => {
    const router = useRouter();
    const { refetch } = router?.query;
    const { eid, entity, passthrough } = props;
    const dispatch = useDispatch();
    const pending = useSelector(getIntegrationPendingSelector);
    const integrationAccounts = useSelector(getIntegrationAccountsSelector);

    const currentIntegration = integrationAccounts?.[INTEGRATIONS[entity?.app]?.getEntityId(entity)];

    useEffect(() => {
        if (entity?.app && entity?.appId && currentIntegration && !pending && refetch) {
            router.replace(window.location.pathname, undefined, { shallow: true });
            dispatch(getIntegrationItems(entity.app, entity.appId));
        }
    }, [entity?.app, entity?.appId, pending, refetch, currentIntegration]);

    return (
        <>
            {INTEGRATIONS[entity?.app]?.isFolder(entity) ? (
                <div className={styles.driveFilesContainer}>
                    <EntityHList eid={eid} app={entity?.app} appId={entity?.appId} passthrough={passthrough} />
                </div>
            ) : (
                INTEGRATIONS[entity?.app]?.renderEntityView(entity)
            )}
        </>
    );
};

export default memo(EntityIntegrationItem);
