export default function StarIcon(): JSX.Element {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.00829 7.6L8.23189 5.68018L8.40258 5.60259L8.48017 5.4319L10.4 1.20831L12.3198 5.4319L12.3974 5.60259L12.5681 5.68018L16.7917 7.6L12.5681 9.51982L12.3974 9.5974L12.3198 9.7681L10.4 13.9917L8.48017 9.7681L8.40258 9.5974L8.23189 9.51982L4.00829 7.6ZM1.2131 16.2048L2.82927 15.4814L3.00341 15.4034L3.08136 15.2293L3.80478 13.6131L4.54525 15.2329L4.62321 15.4034L4.79408 15.4806L6.39646 16.2048L4.79046 16.946L4.6232 17.0232L4.54601 17.1905L3.80478 18.7965L3.08062 17.1941L3.0034 17.0232L2.83287 16.9453L1.2131 16.2048Z"
                fill="#FDDC5C"
                stroke="black"
            />
        </svg>
    );
}
