import React from 'react';

import styles from './AboutAppModal.module.scss';

type Props = {
    entityName: string;
    description: string;
    icon: JSX.Element;
};

const AboutAppModal: React.FunctionComponent = ({ entityName, description, icon }: Props) => {
    return (
        <div className={styles.aboutAppModal}>
            <div className={styles.appHeader}>
                <div className={styles.appIcon}>{icon}</div>
                <h3 className={styles.appTitle}>{entityName}</h3>
            </div>
            <div className={styles.appDescription}>{description}</div>
        </div>
    );
};

export default AboutAppModal;
