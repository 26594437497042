import React, { useRef } from 'react';
import { ModalProps } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/ui/actions';
import { getBrowsedInstanceSelector, getModalSelector } from '../../redux/ui/selectors';
import EntityContainer from '../entities/EntityContainer';
import { usePopup } from '../wrappers/modal';
import styles from './newEntityWindow.module.scss';

export const useNewEntityWindow = () => {
    const { open: openPopup } = usePopup();

    const openNewEntityWindow = (): void => {
        openPopup('new-entity-window');
    };
    return openNewEntityWindow;
};

const NewEntityWindowWrapper: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
    const { text } = props;
    const modalType = text.toLowerCase().split(' ').join('-');
    const dispatch = useDispatch();
    const modal = useSelector(getModalSelector);

    return (
        <>
            {modalType == modal ? (
                <div className="backdrop" onClick={() => dispatch(closeModal())}>
                    <div
                        className={styles.settingsModalWrapper}
                        onClick={function (e): void {
                            e.stopPropagation();
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

const NewEntityWindow: React.FunctionComponent = () => {
    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const ref = useRef(null);

    return (
        <NewEntityWindowWrapper text={'new-entity-window'}>
            <div className={styles.newEntityWindow} ref={ref}>
                <div className={`${styles.browsedContainer} browsed-home`}>
                    <EntityContainer eid={browsedInstance.id} infoMode="browsed" />
                </div>
            </div>
        </NewEntityWindowWrapper>
    );
};

export default NewEntityWindow;
