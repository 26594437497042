export default function PasteSmIcon(): JSX.Element {
    return (
        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 2.08c0-.12.1-.23.23-.23h8.8c.15 0 .24.11.24.23v1.08h1V2.08c0-.69-.56-1.23-1.23-1.23H2.23C1.56.85.99 1.4.99 2.08v6.71c0 .68.57 1.22 1.24 1.22h4.04v-1H2.23a.23.23 0 0 1-.24-.22V2.08Zm5.87 2.88c0-.11.1-.22.23-.22h3.67c.14 0 .24.1.24.22v5.27c0 .12-.1.22-.24.22H8.1a.23.23 0 0 1-.23-.22V8.8a12.43 12.43 0 0 1-1-.07v1.51c0 .69.56 1.22 1.23 1.22h3.67c.68 0 1.24-.53 1.24-1.22V4.96c0-.68-.56-1.22-1.24-1.22H8.1c-.67 0-1.23.54-1.23 1.22V7.1a5.74 5.74 0 0 0 1 .09V4.96Z"
                fill="#333"
            />
            <path
                d="M2.45 5.55a.5.5 0 0 1 0-.7L4.4 2.89l1.96 1.96a.5.5 0 0 1-.71.7l-.7-.7c.03.47.1.85.18 1.16.16.54.38.84.66 1.04.3.2.72.33 1.36.4.65.06 1.44.05 2.45.05a.5.5 0 0 1 0 1c-.99 0-1.84 0-2.55-.06a3.85 3.85 0 0 1-1.83-.57 2.72 2.72 0 0 1-1.05-1.59c-.12-.42-.2-.92-.23-1.5l-.78.77a.5.5 0 0 1-.71 0Z"
                fill="#333"
            />
        </svg>
    );
}
