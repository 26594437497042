import React from 'react';
import Text from '../../DesignSystem/Text';
import Button from '../../DesignSystem/Button';
import welcomeModalStyles from './welcomeModal.module.scss';
import baseOnboardingStyles from '../../baseOnboarding/baseOnboarding.module.scss';
import { Formik } from 'formik';
import { Image } from 'react-bootstrap';
import CheckboxMdIcon from '@/icons/CheckboxMdIcon';
import CheckboxOffMdIcon from '@/icons/CheckboxOffMdIcon';
import StepControlButtons from '@/components/baseOnboarding/StepControlButtons';

const Team = function (props): React.ReactElement {
    const { entity, nextStep, prevStep, isBaseOnboarding } = props;

    return (
        <Formik
            initialValues={{
                team: entity?.team === true,
            }}
            validateOnChange={false}
            onSubmit={nextStep}
        >
            {({ handleSubmit, values, setFieldValue }): JSX.Element => (
                <form onSubmit={handleSubmit} className={welcomeModalStyles.avatarNameJobContainer}>
                    {isBaseOnboarding ? (
                        <div className={baseOnboardingStyles.headerContainer}>
                            <h2>How are you planning to use Bedrock?</h2>
                            <div>We will streamline your onboarding accordingly</div>
                        </div>
                    ) : (
                        <>
                            <Text variant="heading" size="h4" align="center">
                                How are you planning to use Bedrock?
                            </Text>
                            <Text variant="body" size="s" align="center">
                                {`We’ll streamline your onboarding accordingly`}
                            </Text>
                        </>
                    )}
                    <div className={`${welcomeModalStyles.cardsContainer} ${welcomeModalStyles.twoCol}`}>
                        <div
                            className={`${welcomeModalStyles.selectCard} ${
                                values.team ? welcomeModalStyles.activeCard : ''
                            }`}
                            onClick={() => setFieldValue('team', true)}
                        >
                            <Image src="/team.png" className={welcomeModalStyles.teamPic} />
                            <Text weight="bold" align="center">
                                With my team
                            </Text>
                            <div className={welcomeModalStyles.checkboxContainer}>
                                <div className={welcomeModalStyles.checkboxIconContainer}>
                                    {values.team ? <CheckboxMdIcon /> : <CheckboxOffMdIcon />}
                                </div>
                                {!isBaseOnboarding && (
                                    <Text size="xs" align="center" className={welcomeModalStyles.checkboxLabel}>
                                        Try for free
                                    </Text>
                                )}
                            </div>
                        </div>
                        <div
                            className={`${welcomeModalStyles.selectCard} ${
                                !values.team ? welcomeModalStyles.activeCard : ''
                            }`}
                            onClick={() => setFieldValue('team', false)}
                        >
                            <Image src="/solo.png" className={welcomeModalStyles.teamPic} />
                            <Text weight="bold" align="center">
                                For myself
                            </Text>
                            <div className={welcomeModalStyles.checkboxContainer}>
                                <div className={welcomeModalStyles.checkboxIconContainer}>
                                    {!values.team ? <CheckboxMdIcon /> : <CheckboxOffMdIcon />}
                                </div>
                                {!isBaseOnboarding && (
                                    <Text size="xs" align="center" className={welcomeModalStyles.checkboxLabel}>
                                        Free with 1 person
                                    </Text>
                                )}
                            </div>
                        </div>
                    </div>
                    {isBaseOnboarding ? (
                        <div className={baseOnboardingStyles.stickyStepControl}>
                            <StepControlButtons prevStep={prevStep} nextStep={handleSubmit} />
                        </div>
                    ) : (
                        <div className={welcomeModalStyles.stepControlContainer}>
                            <Button onClick={prevStep} size="s" variant="secondary">
                                {'<- Back'}
                            </Button>
                            <Button type="submit" size="s" variant="primary">
                                {'Continue ->'}
                            </Button>
                        </div>
                    )}
                </form>
            )}
        </Formik>
    );
};

export default Team;
