export default function FigmaDesignIcon(): JSX.Element {
    return (
        <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0.666667 16H12.6667C13.0349 16 13.3333 15.7015 13.3333 15.3333V3.94281C13.3333 3.766 13.2631 3.59643 13.1381 3.47141L9.86193 0.19526C9.73693 0.0702401 9.56733 0 9.39053 0H0.666667C0.29848 0 0 0.29848 0 0.666667V15.3333C0 15.7015 0.29848 16 0.666667 16Z"
                fill="#0D99FF"
            />
            <path
                d="M7.2388 5.99687L7.23106 5.99467L4.2958 5.15773L6.46726 7.3292C6.5446 7.30267 6.62746 7.28827 6.7138 7.28827C7.13286 7.28827 7.4726 7.628 7.4726 8.04713C7.4726 8.4662 7.13286 8.80593 6.7138 8.80593C6.29466 8.80593 5.95493 8.4662 5.95493 8.04713C5.95493 7.9608 5.96933 7.87793 5.99586 7.8006L3.82833 5.63307L4.62953 8.42793L4.63653 8.45227L4.64166 8.47707C4.84 9.43947 5.69313 10.1628 6.7138 10.1628C6.9466 10.1628 7.1694 10.1255 7.37726 10.0569L7.76713 9.92827L8.05746 10.2186L8.87806 11.0392L9.72386 10.1934L8.89406 9.36367L8.60713 9.07667L8.73026 8.69C8.79453 8.48807 8.82953 8.27233 8.82953 8.04713C8.82953 7.0634 8.15753 6.23493 7.2466 5.99887L7.2388 5.99687ZM10.1953 9.722L10.6667 10.1934L10.1953 10.6648L9.34946 11.5106L8.87806 11.982L8.40666 11.5106L7.58606 10.69C7.31173 10.7805 7.01846 10.8295 6.7138 10.8295C5.37053 10.8295 4.2496 9.87767 3.98866 8.61167L2.88306 4.75493L2.66666 4L3.42193 4.21533L7.41386 5.35353C8.61166 5.66393 9.4962 6.7522 9.4962 8.04713C9.4962 8.3418 9.4504 8.62573 9.36546 8.89227L10.1953 9.722Z"
                fill="white"
            />
            <path
                d="M9.66667 3V1.60947V0L10.8047 1.13807L12.1953 2.52859L13.3333 3.66667H11.7239H10.3333C9.96514 3.66667 9.66667 3.36819 9.66667 3Z"
                fill="#9BD5FD"
            />
        </svg>
    );
}
