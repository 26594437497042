import { boldMatchingText, getAppDataByName, getEntityIcon } from '@/utils/helpers';
import React, { useRef, useEffect, useMemo } from 'react';

import typeInfo from '../../typeInfo';
import ControlButtons from './ControlButtons';

import styles from './styles.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { clearRenamingInstance, setRenamingInstance, showMenu } from '@/redux/ui/actions';
import UserAvatars from '../UserAvatars';
import { useEntities, useEntity } from '@/hooks/reduxHooks';
import DeletedEntitiesControls from './DeletedEntitiesControls';
import TextField from '@/components/forms/textField';
import { getRenamingInstanceSelector } from '@/redux/ui/selectors';
import LoadingIndicator from '@/components/entities/subcomponents/HListItem/LoadingIndicator';
import DetailedHlistItemView from '@/components/entities/subcomponents/HListItem/DetailedHlistItemView';
import TriangleDownSmIcon from '@/icons/TriangleDownSmIcon';
import CheckboxMdIcon from '@/icons/CheckboxMdIcon';
import CheckboxOffMdIcon from '@/icons/CheckboxOffMdIcon';
import { useMobileQuery } from '@/hooks/useMediaQuery';

interface EntityPresentationProps {
    eid;
    childIndex;
    drop;
    open;
    paddingLeft;
    summaryClass;
    name;
    entity;
    hasChildren;
    infoMode;
    onChipletClick;
    onClick;
    preventDetailsToggle;
    passthrough: EntityPassthroughProps;
    parentId;
    toggleListOpen: (id: string, open: boolean) => void;
    searchText;
    isIntegrationItem?;
    isIntegrationItemInDataspace?;
    isTrashPage: boolean;
    isFocused?: boolean;
    isLocalFileViewedFromBrowser?: boolean;
    uploadProgress?: number;
    isDraft?: boolean;
    isShared?: boolean;
    isAllNotesView?: boolean;
    isChecked?: boolean;
    setIsChecked?: () => void;
    isAppsFolder?: boolean;
    isBaseOnboarding?: boolean;
}

const HListItemPresentation: React.FunctionComponent<EntityPresentationProps> = (props) => {
    const {
        eid,
        entity,
        open,
        drop,
        paddingLeft,
        summaryClass,
        hasChildren,
        infoMode,
        onClick,
        onChipletClick,
        passthrough,
        parentId,
        childIndex,
        name,
        toggleListOpen,
        searchText,
        isIntegrationItem,
        isIntegrationItemInDataspace,
        isTrashPage,
        isLocalFileViewedFromBrowser,
        uploadProgress,
        isDraft,
        isShared,
        isAllNotesView,
        isChecked,
        setIsChecked,
        isAppsFolder,
        isBaseOnboarding,
    } = props;

    const ref = useRef<HTMLElement>(null);
    const schemas = useEntities(entity?.additionalTypes, false, 'HListItem presentation');
    const dispatch = useDispatch();
    const renamingInstance = useSelector(getRenamingInstanceSelector);
    const dataspace = useEntity(entity?.metadata?.dsid, true, 'HListItem presentation dataspace name');

    const isMobile = useMobileQuery();

    useEffect(() => {
        drop(ref);
    }, [entity]);

    const entityType = entity?.['@type'];
    const isFakeAppOrFolder = entityType === 'App' || isAppsFolder;

    const chiplet = getEntityIcon(entity, { isIntegrationItem, isOpen: open, isAppsFolder });

    const getArrow = function (): JSX.Element | null {
        if (searchText?.length > 0) return null;
        return (
            <div className={`${styles.chipletToggle} ${open && styles.chipletToggleOpen}`}>
                <TriangleDownSmIcon />
            </div>
        );
    };

    const getItemName = () => {
        const itemName = name || `Untitled ${typeInfo[entityType]?.fancyName}`;
        if (searchText) {
            return boldMatchingText(itemName, searchText);
        } else return itemName;
    };

    const handleContextMenuClick = function (event): void {
        event.preventDefault();
        event.stopPropagation();
        if (isFakeAppOrFolder) {
            return;
        }
        const { clientX: x, clientY: y } = event;
        const contextMenuEntity = {
            id: eid,
            entity: passthrough?.localDirectory ? passthrough.localDirItemData : entity,
            childIndex,
            ...(!isIntegrationItem && !passthrough?.localDirectory && { parentId }),
            isIntegrationItem: isIntegrationItem || false,
            isLocalDirectory: passthrough?.localDirectory || false,
            ...(Boolean(passthrough?.localDirItemData?.path) && {
                localDirectoryPath: passthrough.localDirItemData.path,
            }),
        };

        dispatch(
            showMenu({
                type: 'ContextMenu',
                x,
                y,
                contextMenuEntity,
            }),
        );
    };

    const controlButtons = ['hlist', 'compact-flat'].includes(infoMode) && !isMobile && (
        <ControlButtons
            eid={eid}
            entity={entity}
            parentId={parentId}
            name={name}
            toggleListOpen={toggleListOpen}
            childIndex={childIndex}
            infoMode={infoMode}
            isIntegrationItemInDataspace={isIntegrationItemInDataspace}
            isLocalFile={
                (isIntegrationItem || isIntegrationItemInDataspace) &&
                entity?.app === 'localFiles' &&
                entity?.foreignId?.startsWith(window?.homedir)
            }
            isLocalFileViewedFromBrowser={isLocalFileViewedFromBrowser}
            canShow={!isTrashPage && !uploadProgress && !isFakeAppOrFolder}
            isAppsFolder={isAppsFolder}
        />
    );

    const schemaTag = infoMode == 'compact-flat' && schemas[0] && (
        <span style={{ marginRight: '20px' }} className="schemaTag">
            {schemas[0]?.name || 'Untitled Schema'}
        </span>
    );

    const childrenNumber = entity?.childrenList?.length !== 0 && !isIntegrationItemInDataspace && (
        <span className={styles.chipletSectionChildrenAmount}>{entity?.childrenList?.length}</span>
    );

    const shouldShowDeleteControls =
        (isTrashPage || isDraft) && (!isIntegrationItem || isIntegrationItemInDataspace) && !isMobile;

    const nameInput = (
        <div className={styles.noStyleInput} style={{ '--leftPadding': `${paddingLeft}px` } as React.CSSProperties}>
            <TextField
                eid={eid}
                propertyKey={'name'}
                placeholder={getItemName()}
                canRename={true}
                className={styles.textFieldInput}
                autoFocus
                callback={() => dispatch(clearRenamingInstance())}
                fallback={() => dispatch(setRenamingInstance(eid, parentId))}
                multiline
            />
        </div>
    );

    const nameDisplay = (
        <div className={styles.name} onClick={onClick}>
            {getItemName()}
        </div>
    );

    const comingSoonLabel = useMemo(() => {
        if (entityType === 'App') {
            const app = getAppDataByName(entity?.name);

            return !app?.available && <span className={styles.comingSoonLabel}>Coming soon...</span>;
        }

        return null;
    }, [entity]);

    const checkboxInput = useMemo(() => {
        return (
            <span onClick={setIsChecked} style={{ marginRight: '5px' }}>
                {isChecked ? <CheckboxMdIcon /> : <CheckboxOffMdIcon />}
            </span>
        );
    }, [isChecked, setIsChecked]);

    return (
        <summary className={summaryClass} ref={ref} onContextMenu={handleContextMenuClick}>
            <span
                className={`${styles.summaryInnerContainer} ${
                    infoMode === 'compact-flat' ? styles.summaryInnerContainerLarge : ''
                }`}
            >
                {infoMode === 'compact-flat' ? (
                    <DetailedHlistItemView
                        entity={entity}
                        dataspace={dataspace}
                        includeDataspace={isAllNotesView}
                        onClick={onClick}
                        isDraft={isDraft}
                        isShared={isShared}
                    />
                ) : (
                    <div className={styles.compactSymbol} style={{ paddingLeft: `${paddingLeft}px` }}>
                        {infoMode === 'checkbox' && checkboxInput}
                        <span className={styles.chiplet} onClick={onChipletClick}>
                            {hasChildren && !isLocalFileViewedFromBrowser && (
                                <>
                                    <div className={styles.chipletSection} data-type="arrow">
                                        {getArrow()}
                                    </div>
                                    <div className={`${styles.chipletSection}`}>{chiplet}</div>
                                </>
                            )}
                            {(!hasChildren || isLocalFileViewedFromBrowser) && (
                                <div
                                    className={styles.chipletSection}
                                    data-type={infoMode !== 'compact-flat' ? 'solo' : ''}
                                >
                                    {chiplet}
                                </div>
                            )}
                        </span>
                        <span className={styles.entityName}>
                            {renamingInstance?.id === eid && renamingInstance?.parentId === parentId
                                ? nameInput
                                : nameDisplay}
                            {schemaTag}
                            {/* TODO: when app type entities will have their account entities generated in redux, remove entity['@type'] !== 'App' */}
                            {hasChildren && entity['@type'] !== 'App' && childrenNumber}
                            {comingSoonLabel}
                        </span>
                    </div>
                )}
                {isTrashPage && (!isIntegrationItem || isIntegrationItemInDataspace) && (
                    <span className={styles.dataspaceName}>{dataspace?.name}</span>
                )}
                {!isBaseOnboarding && (
                    <>
                        <UserAvatars eid={eid} showIcons={true} context="CondensedEntity" />
                        {controlButtons}
                        <DeletedEntitiesControls
                            eid={eid}
                            parentId={parentId}
                            canRestore={isTrashPage}
                            canShow={shouldShowDeleteControls}
                        />
                        {!isNaN(uploadProgress) && (
                            <LoadingIndicator progress={uploadProgress} isFolder={entityType === 'HList'} />
                        )}
                    </>
                )}
            </span>
        </summary>
    );
};

export default React.memo(HListItemPresentation);
