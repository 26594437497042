import { getEntityIcon } from '@/utils/helpers';
import React, { useRef, useEffect } from 'react';

import typeInfo from '../../typeInfo';

import styles from './styles.module.scss';
import { useDispatch } from 'react-redux';
import { showMenu } from '@/redux/ui/actions';
import UserAvatars from '../UserAvatars';
import CloseMdIcon from '@/icons/CloseMdIcon';
import Tooltip from 'components/Tooltip';
import VolumeIcon from '@/icons/VolumeIcon';

interface TabPresentationProps {
    eid;
    persisted;
    emptyTab;
    drop;
    name;
    entity;
    onClick;
    preventDetailsToggle;
    isIntegrationItem?;
    isBrowsed?;
    isHovered?;
    onClose;
    showClose?;
    mediaPlaying?: boolean;
}

const TabPresentation: React.FunctionComponent<TabPresentationProps> = (props) => {
    const {
        eid,
        persisted,
        emptyTab,
        entity,
        drop,
        onClick,
        name,
        isIntegrationItem,
        isBrowsed,
        isHovered,
        onClose,
        showClose,
        mediaPlaying,
    } = props;

    const ref = useRef<HTMLElement>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        drop(ref);
    }, [entity]);

    const entityType = entity?.['@type'];

    const chiplet = getEntityIcon(entity, { isIntegrationItem, emptyTab });

    const getItemName = () => {
        return name || `Untitled ${typeInfo[entityType]?.fancyName}`;
    };

    const handleContextMenuClick = function (event): void {
        event.preventDefault();
        const { clientX: x, clientY: y } = event;
        const contextMenuEntity = {
            id: isIntegrationItem ? entity?.id || entity?.key : eid,
            entity,
            isIntegrationItem: isIntegrationItem || false,
        };

        dispatch(
            showMenu({
                type: 'ContextMenu',
                x,
                y,
                contextMenuEntity,
            }),
        );
    };

    const nameDisplay = (
        <Tooltip
            key={`tabName-${eid}`}
            placement="bottom"
            appendTo={() => document.body}
            delay={[1500, 100]}
            content={
                <div className={'tooltipTippy'} id={`tooltip-tabName-${eid}`}>
                    {getItemName()}
                </div>
            }
            interactive={true}
        >
            <div className={`${styles.tabName} ${persisted ? '' : styles.notPersisted}`} onClick={onClick}>
                {getItemName()}
            </div>
        </Tooltip>
    );

    const closeButton = (
        <Tooltip
            key={`c-el-${eid}`}
            placement="bottom"
            appendTo={() => document.body}
            delay={100}
            content={
                <div className={'tooltipTippy'} id={`tooltip-el-${eid}`}>
                    Close
                </div>
            }
            interactive={true}
        >
            <a
                rel="noreferrer"
                title="Close"
                onClick={onClose}
                className={`${styles.linkchip} ${styles.closeButton} ${!isBrowsed && !showClose && styles.hideClose}`}
            >
                <CloseMdIcon />
            </a>
        </Tooltip>
    );

    return (
        <summary
            className={`${styles.tab} ${isBrowsed ? styles.browsedTab : styles.notBrowsedTab} ${
                isHovered && styles.dropHover
            } ${isBrowsed && styles.hideCloseWhenNarrow}`}
            ref={ref}
            onContextMenu={handleContextMenuClick}
        >
            <span className={styles.summaryInnerContainer}>
                <div className={styles.compactSymbol} onClick={onClick}>
                    <span className={styles.chiplet}>
                        <div className={styles.chipletSection}>{chiplet}</div>
                    </span>
                    <span className={`${styles.entityName} ${styles.fadeText}`}>{nameDisplay}</span>
                </div>
                {mediaPlaying && <VolumeIcon />}
                <UserAvatars eid={eid} showIcons={true} context="CondensedEntity" />
                {closeButton}
            </span>
        </summary>
    );
};

export default React.memo(TabPresentation);
