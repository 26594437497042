import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../redux/entities/actions';
import { getDsidSelector } from '../redux/entities/selectors';
import { getUserSelector } from '../redux/user/selectors';
import { cloneEntity } from '../utils/helpers';
import { useOpenItem } from './reduxHooks';

const useFirstLoad = () => {
    const user = useSelector(getUserSelector);
    const currentDataspaceId = useSelector(getDsidSelector);
    const openItem = useOpenItem();
    const dispatch = useDispatch();

    const disableSonar = () => {
        const newUserEntity = cloneEntity(user.entity);
        newUserEntity.firstLoad['sonar'] = false;
        dispatch(updateUser(user.id, newUserEntity));
    };

    const clearFirstBaseLoad = () => {
        const newUserEntity = cloneEntity(user.entity);
        newUserEntity.firstLoad['firstIdToBrowse'] = null;
        newUserEntity.firstLoad['crisp'] = false;
        const firstIdToBrowse = user.entity?.firstLoad.firstIdToBrowse;
        const crisp = user.entity?.firstLoad.crisp;
        dispatch(
            updateUser(user.id, newUserEntity, () => {
                openItem({ id: firstIdToBrowse, dsid: currentDataspaceId });
                if (crisp === true) {
                    setTimeout(() => {
                        globalThis?.window?.$crisp?.push(['do', 'message:show', ['text', "Hello! I'm here to help."]]);
                    }, 5000);
                }
            }),
        );
    };

    return {
        sonar: user.entity?.firstLoad?.sonar,
        disableSonar,
        firstBaseLoad: user.entity?.firstLoad?.firstIdToBrowse || user.entity?.firstLoad?.crisp,
        clearFirstBaseLoad,
    };
};

export default useFirstLoad;
