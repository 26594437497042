export default function BedrockDiscoIcon(): JSX.Element {
    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M193.329 99.9948C193.329 151.539 151.544 193.323 99.9995 193.323C48.4553 193.323 6.67047 151.539 6.67047 99.9948C6.67047 48.4511 48.4553 6.66656 99.9995 6.66656C151.544 6.66656 193.329 48.4511 193.329 99.9948Z"
                fill="white"
                stroke="white"
                strokeWidth="13.3331"
            />
            <path
                d="M145.876 149.293C154.522 149.293 162.493 149.564 170.194 149.969C170.464 149.969 170.869 149.834 171.004 149.564C173.301 146.184 175.463 142.803 177.219 139.153C177.489 138.477 177.084 137.666 176.408 137.801C167.357 137.936 157.629 137.801 146.821 137.801C141.417 137.801 135.878 137.801 130.069 137.801C98.5902 137.801 84.6748 146.183 71.1647 154.431C63.4639 159.028 56.0333 163.624 46.171 165.923C45.3603 166.058 45.2252 167.14 45.7656 167.68C51.1697 172.007 57.1141 175.792 63.4639 178.767C63.599 178.902 63.8692 178.902 64.1394 178.767C72.7859 176.604 79.1356 172.412 86.296 167.68C99.4008 159.028 114.262 149.293 145.876 149.293Z"
                fill="#F78200"
            />
            <path
                d="M145.876 159.347C117.235 159.347 104.265 167.864 91.8358 176.112C88.3232 178.41 84.8105 180.708 80.8926 182.872C80.082 183.277 80.2171 184.494 81.1628 184.629C87.2424 185.981 93.5921 186.657 100.077 186.657C123.99 186.657 145.606 176.923 161.278 161.239C161.818 160.699 161.413 159.617 160.602 159.617C155.874 159.482 151.01 159.347 145.876 159.347Z"
                fill="#E23838"
            />
            <path
                d="M24.9478 101.723C32.5135 97.8023 41.1599 93.3407 52.6435 90.0958C65.7484 86.3102 80.6095 84.4174 98.1726 84.4174C114.115 84.4174 129.111 82.5246 144.242 78.739C157.347 75.359 168.155 71.1677 177.747 67.5173C178.153 67.3821 178.558 67.2469 178.963 66.9765C179.504 66.8413 179.774 66.1653 179.504 65.6245C166.264 34.7987 135.596 13.3018 99.9289 13.3018C52.1031 13.3018 13.3291 52.1044 13.3291 99.9655C13.3291 101.994 13.4642 103.886 13.4642 105.914C13.4642 106.59 14.2748 106.996 14.8152 106.726C18.3278 105.238 21.5703 103.481 24.9478 101.723Z"
                fill="#009CDF"
            />
            <path
                d="M65.1207 145.351C79.1712 136.968 94.978 127.369 129.159 127.369C134.968 127.369 140.642 127.369 146.046 127.369C158.881 127.369 170.364 127.369 180.632 127.234C181.037 127.234 181.443 126.964 181.578 126.558C183.334 121.285 184.55 115.877 185.226 110.334C185.361 109.658 184.685 109.117 184.01 109.252C165.771 112.903 142.939 115.742 113.352 115.742C98.0853 115.742 85.1156 117.5 73.9022 121.015C64.0398 124.124 56.7444 128.18 49.584 132.236C42.0184 136.428 34.7229 140.484 25.536 142.782C24.8605 142.917 24.5903 143.729 24.9956 144.269C27.6976 148.731 30.6699 152.922 34.1825 156.843C34.4527 157.113 34.7229 157.249 34.9931 157.113C47.5575 156.032 55.7987 151.029 65.1207 145.351Z"
                fill="#FFB900"
            />
            <path
                d="M29.5411 110.638C25.0827 112.937 20.6244 115.235 15.8959 117.128C15.4906 117.263 15.2204 117.804 15.3555 118.209C16.4363 123.482 18.0575 128.485 20.084 133.217C20.2191 133.622 20.7595 133.893 21.1648 133.757C30.4868 131.865 37.6471 127.944 45.483 123.482C60.4792 115.235 77.3668 105.771 114.114 105.771C144.512 105.771 167.749 102.661 185.853 98.8757C186.258 98.7405 186.664 98.3349 186.664 97.9293C186.529 90.6284 185.448 83.598 183.691 76.9732C183.556 76.4323 182.881 76.1619 182.34 76.2971L181.53 76.5676C162.345 84.0036 136.136 94.1437 98.3075 94.1437C61.1547 94.4141 44.4022 103.067 29.5411 110.638Z"
                fill="#5EBD3E"
            />
        </svg>
    );
}
