export default function ErrorRedIcon(): JSX.Element {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M0 8C0 3.85786 3.35786 0.5 7.5 0.5C11.6421 0.5 15 3.85786 15 8C15 12.1421 11.6421 15.5 7.5 15.5C3.35786 15.5 0 12.1421 0 8Z"
                fill="#FF485C"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.25 4.25C8.25 3.83579 7.91421 3.5 7.5 3.5C7.08579 3.5 6.75 3.83579 6.75 4.25V8C6.75 8.41421 7.08579 8.75 7.5 8.75C7.91421 8.75 8.25 8.41421 8.25 8V4.25ZM7.5 12.375C8.19036 12.375 8.75 11.8154 8.75 11.125C8.75 10.4346 8.19036 9.875 7.5 9.875C6.80964 9.875 6.25 10.4346 6.25 11.125C6.25 11.8154 6.80964 12.375 7.5 12.375Z"
                fill="white"
            />
        </svg>
    );
}
