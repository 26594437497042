import { useCallback, useEffect, useState } from 'react';

const isBrowser = () => typeof window !== undefined;

const useSessionStorage = (name): [value: string, setValue: (nextValue) => void, refresh: () => void] => {
    const [value, _setValue] = useState('');
    const refresh = () => _setValue(sessionStorage.getItem(name) || '');

    useEffect(() => {
        refresh(); // initial refresh
        const handleStorageChange = (e) => {
            if (e.key === name) {
                refresh();
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, [name, refresh]);

    const setValue = useCallback(
        (nextValue) => {
            if (nextValue === null) {
                _setValue('');
                if (isBrowser()) {
                    sessionStorage.removeItem(name);
                }
                return;
            }

            _setValue(nextValue);

            if (isBrowser()) {
                sessionStorage.setItem(name, nextValue);
            }
        },
        [name],
    );

    return [value, setValue, refresh];
};

export default useSessionStorage;
