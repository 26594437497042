import axios from 'axios';
import { BASE_ORIGIN } from '@/config';

const instance = axios.create({
    baseURL: BASE_ORIGIN,
});

instance.defaults.xsrfCookieName = 'csrftoken';
instance.defaults.xsrfHeaderName = 'X-CSRFToken';
// axios.defaults.withCredentials = true

function config() {
    return {
        headers: {
            'Content-Type': 'application/json',
        },
    };
}

function slackWebhookConfig() {
    return {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
    };
}

function handleError(err) {
    console.log('Error: ', err);
    throw err.response;
}

function handleResponse(response) {
    return response.data;
}

export function postRequest(url, data) {
    return instance.post(url, data, config()).then(handleResponse).catch(handleError);
}

export function slackWebhookRequest(url, data) {
    return instance.post(url, data, slackWebhookConfig()).then(handleResponse).catch(handleError);
}
