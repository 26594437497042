import styles from '../contextMenu.module.scss';
import TriangleRightIcon from '@/icons/TriangleRightIcon';
import CheckboxMdIcon from '@/icons/CheckboxMdIcon';
import clsx from 'clsx';
import { useRef } from 'react';
import { MouseSubmenuSafeArea } from '@/components/MouseSubmenuSafeArea';

const SubMenuItem = ({
    item,
    currentSelectedItem,
    setCurrentSelectedItem,
    subMenuPositionCSS,
    currentSelectedSubmenuItem,
    setCurrentSelectedSubmenuItem,
    currentSubmenuList,
    setCurrentSubmenuList,
    customSubmenuOpened,
    setCustomSubmenuOpened,
}) => {
    const itemRef = useRef(null);
    return (
        <li
            key={item.name}
            className={clsx(styles.submenuParent, currentSelectedItem === item.name && styles.selected, {
                [styles.submenuParentExpanded]:
                    currentSelectedItem === item.name && (currentSubmenuList || customSubmenuOpened),
            })}
            onMouseEnter={() => {
                setCurrentSelectedItem(item.name);
                !!item.customSubmenu && setCustomSubmenuOpened(true);
            }}
            data-action-name={item.name}
        >
            <span className={clsx(styles.item, currentSelectedItem === item.name && styles.selected)}>
                {item.icon && <div className={styles.itemIcon}>{item.icon}</div>}
                <span className={styles.itemLabel}>{item.name}</span>
                {item.hotkey && <div className={styles.itemHotkey}>{item.hotkey}</div>}
                <TriangleRightIcon />
            </span>
            <menu
                ref={itemRef}
                className={clsx(styles.submenu, styles.menu)}
                style={subMenuPositionCSS}
                onMouseEnter={() => item?.submenuItems && setCurrentSubmenuList(item.submenuItems)}
                onMouseLeave={() => {
                    setCurrentSelectedSubmenuItem(null);
                    setCurrentSubmenuList(null);
                }}
            >
                <MouseSubmenuSafeArea parentRef={itemRef} className={styles.safeZone} />
                {customSubmenuOpened && !!item?.customSubmenu
                    ? item.customSubmenu
                    : item?.submenuItems?.map((submenuItem) => {
                          return (
                              <li
                                  key={submenuItem.id}
                                  onClick={submenuItem.onClick}
                                  onMouseEnter={() => setCurrentSelectedSubmenuItem(submenuItem.id)}
                                  className={clsx(
                                      styles.item,
                                      currentSelectedSubmenuItem === submenuItem.id
                                          ? styles.selected
                                          : submenuItem?.checked && styles.itemChecked,
                                  )}
                                  data-action-item-id={submenuItem.id}
                              >
                                  <span className={styles.itemIcon}>{submenuItem.icon}</span>
                                  <span className={styles.itemLabel}>{submenuItem.name}</span>
                                  {submenuItem.checked && (
                                      <span className={styles.checkedIcon}>
                                          <CheckboxMdIcon />
                                      </span>
                                  )}
                              </li>
                          );
                      })}
            </menu>
        </li>
    );
};

export default SubMenuItem;
