import React, { useState } from 'react';
import { FigmaMeResponse, IntegrationAdapter, IntegrationName } from '../types';
import FigmaIcon from '@/icons/FigmaIcon';
import FigmaDesignIcon from '@/icons/FigmaDesignIcon';
import View from '@/components/entities/subcomponents/View';
import { useDispatch } from 'react-redux';
import { toast } from '@/redux/ui/actions';
import { MessageType } from '../../types/toasts';
import styles from '@/components/modals/addIntegrationPopup.module.scss';
import Button from '@/components/DesignSystem/Button';
import FigmaFolderIcon from '../icons/FigmaFolderIcon';
import FolderIcon from '@/icons/FolderIcon';

const FigmaMoreScreen = ({ authorize }: { authorize: (name: IntegrationName, props?: any) => void }) => {
    const dispatch = useDispatch();
    const [personalAccessToken, setPersonalAccessToken] = useState<string>('');
    const [teamId, setTeamId] = useState<string>('');

    const onAddIntegration = () => {
        if (!personalAccessToken || !teamId) {
            dispatch(
                toast({
                    text: 'Both "Personal access token" and "Team ID" are required',
                    msgType: MessageType.WARNING,
                }),
            );
            return;
        }

        authorize('figma', { personalAccessToken, teamId });
    };

    return (
        <>
            <div>
                <h3>Provide Figma Personal access token</h3>
                <ul className={styles.instructionList}>
                    <li>Login to Figma</li>
                    <li>Click in right top corner on your avatar image</li>
                    <li>Choose &quot;Settings&quot;</li>
                    <li>
                        In modal opened go to &quot;Account&quot; tab and scroll down to &quot;Personal access
                        tokens&quot; section
                    </li>
                    <li>In &quot;Add token description&quot; field type-in the name for your token and hit Enter</li>
                    <li>
                        Then click &quot;Copy this token&quot;, the key shown to you is your new personal access token
                    </li>
                    <li>
                        Come back to Bedrock and paste copied key into &quot;Personal access token&quot; field below
                    </li>
                </ul>

                <input
                    className={`formControl ${styles.userFriendlyInput}`}
                    type="text"
                    placeholder="Personal Access Token *"
                    value={personalAccessToken}
                    onChange={(event) => setPersonalAccessToken(event?.target?.value || '')}
                />
            </div>
            <div>
                <h3>Provide Figma Team IDs</h3>
                <ul className={styles.instructionList}>
                    <li>Login to Figma</li>
                    <li>Click on team name on the right panel</li>
                    <li>
                        Copy team ID from URL, that goes after <code>/team/</code> part. For instance, in this URL{' '}
                        <code>
                            https://www.figma.com/files/team/<strong>11223344</strong>/Bedrock?fuid=66778899
                        </code>
                        , the <code>11223344</code> part is the one you will have to copy
                    </li>
                    <li>You can add multiple team IDs, comma separated</li>
                </ul>

                <input
                    className={`formControl ${styles.userFriendlyInput}`}
                    type="text"
                    placeholder="Team IDs *"
                    value={teamId}
                    onChange={(event) => setTeamId(event?.target?.value || '')}
                />
            </div>
            <br />
            <div style={{ textAlign: 'right' }}>
                <Button size="m" variant="primary" onClick={onAddIntegration} style={{ display: 'inline-block' }}>
                    Add Figma Account Integration
                </Button>
            </div>
        </>
    );
};

export class FigmaAdapter implements IntegrationAdapter {
    name = 'figma';
    icon = FigmaIcon;
    label = 'Figma';

    getEntityId = (entity) => `${entity?.app}_${entity?.appId}`;
    getEntityPath = (entity) => `${entity?.app}/${entity?.appId}`;
    getEntityIcon = (entity) =>
        entity?.id === 'root' ? (
            <FigmaIcon />
        ) : entity?.metadata?.dsid && (!entity?.key || entity?.childrenList?.length > 0) ? (
            <FigmaFolderIcon />
        ) : !entity?.key || entity?.childrenList?.length > 0 ? (
            <FolderIcon />
        ) : (
            <FigmaDesignIcon />
        );

    renderEntityView = (entity) => (
        <View
            src={`https://www.figma.com/embed?embed_host=astra&url=https://www.figma.com/file/${entity?.key}`}
            height="100%"
            width="100%"
        />
    );
    renderMoreScreen = (authorize) => <FigmaMoreScreen authorize={authorize} />;
    isFolder = (entity) => !entity?.key || entity?.childrenList?.length > 0;

    constructor() {
        this.getAuthorizationUrl = this.getAuthorizationUrl.bind(this);
        this.getItems = this.getItems.bind(this);
        this.revoke = this.revoke.bind(this);
    }

    async getAuthorizationUrl({ personalAccessToken, teamId }) {
        const url = `/api/integrations/apps/${this.name}/getUser?tokens=${JSON.stringify({
            personalAccessToken,
            teamId,
        })}`;
        const { me, tokens } = (await fetch(url).then((res) => res.json()))?.data as FigmaMeResponse;

        return `${window.location.origin}/integrations/complete?data=${encodeURIComponent(
            JSON.stringify({
                app: 'figma',
                appId: me.email,
                tokens,
                profile: {
                    email: me.email,
                    name: me.handle,
                    picture: me.img_url,
                },
            }),
        )}`;
    }

    async getItems(tokens): Promise<ServerResponseWithItemsData> {
        const url = `/api/integrations/apps/${this.name}/getFiles?tokens=${encodeURIComponent(tokens)}`;

        try {
            const appResponse = await fetch(url).then((res) => res.json());
            if (appResponse.ok) {
                return {
                    ...appResponse,
                    itemsData: { items: appResponse.data },
                };
            } else {
                throw appResponse;
            }
        } catch (error) {
            return error;
        }
    }

    async revoke(): Promise<ServerResponseWithItemsData> {
        return { ok: true } as ServerResponseWithItemsData;
    }
}
