export default function AddSmIcon(): JSX.Element {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 0.875C6.34518 0.875 6.625 1.15482 6.625 1.5V5.375H10.5C10.8452 5.375 11.125 5.65482 11.125 6C11.125 6.34518 10.8452 6.625 10.5 6.625H6.625V10.5C6.625 10.8452 6.34518 11.125 6 11.125C5.65482 11.125 5.375 10.8452 5.375 10.5V6.625H1.5C1.15482 6.625 0.875 6.34518 0.875 6C0.875 5.65482 1.15482 5.375 1.5 5.375H5.375V1.5C5.375 1.15482 5.65482 0.875 6 0.875Z"
                fill="black"
            />
        </svg>
    );
}
