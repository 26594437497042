import { QUERY_AI, QUERY_AI_SUCCESS, QUERY_AI_FAILURE } from '../actionTypes';

export const queryAI = (query: string): ReduxAction => {
    return {
        type: QUERY_AI,
        payload: { query },
    };
};

export const queryAISuccess = (response): ReduxAction => {
    return {
        type: QUERY_AI_SUCCESS,
        payload: { response },
    };
};

export const queryAIFailure = (error): ReduxAction => {
    return {
        type: QUERY_AI_FAILURE,
        payload: { error },
    };
};
