import styles from './HListPreview.module.scss';
import Text from '@/components/DesignSystem/Text';
import QuickCreateButton from '@/components/modals/contextComponents/quickCreateButton';
import { useEntities } from '@/hooks/reduxHooks';
import { getEntityIcon, isImage } from '@/utils/helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getIntegrationItemsListSelector } from '@/redux/integrations/selectors';
import typeInfo from '../typeInfo';
import EntityTopBar from './EntityTopBar';
import { hideGlobalModal, showGlobalModal } from '@/redux/ui/actions';
import { noop } from 'lodash';
import { clsx } from 'clsx';
import { useFileDrop } from '@/hooks/remoteHooks';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { LISTITEM } from '@/dndTypes';
import { bulkMoveEntities } from '@/redux/entities/actions';

const HListPreview: React.FunctionComponent<EntityContainerProps> = (props: EntityContainerProps) => {
    const dispatch = useDispatch();
    const { eid, entity } = props;
    const isIntegrationItem = entity?.['@type'] === 'IntegrationItem';

    const showChildrenAmount = isIntegrationItem ? isIntegrationItem && entity?.id === entity?.foreignId : false;
    const previewType = entity.previewType || 'METADATA';

    const handleFileDrop = useFileDrop(eid);
    const [{ hovered }, drop] = useDrop({
        accept: [LISTITEM, NativeTypes.FILE],
        canDrop: (_, monitor) => {
            return !isIntegrationItem && monitor.isOver({ shallow: true });
        },
        drop: (item: DnDMovedItem, monitor) => {
            if (!monitor.didDrop()) {
                if (item.files) {
                    handleFileDrop(item);
                    return;
                }
                const childEidsToAdd = [{ id: item.eid, parentId: item.parentId }];
                dispatch(bulkMoveEntities(eid, childEidsToAdd, 0));
            }
        },
        collect: (monitor) => {
            return {
                hovered: monitor.isOver({ shallow: true }),
            };
        },
    });

    return (
        <div className={styles.container}>
            <div className={styles.previewTopBarConntainer}>
                <EntityTopBar entity={entity} eid={eid} showPreviewTypeToggler />
            </div>
            {previewType === 'GALLERY' && <GalleryViewPreview {...props} />}
            {previewType === 'METADATA' && (
                <div className={styles.position}>
                    {entity?.childrenList?.length ? (
                        <div
                            className={clsx(styles.box, hovered && !isIntegrationItem && styles.boxHovered)}
                            ref={drop}
                        >
                            {isIntegrationItem ? (
                                <IntegrationHListChildrenIcons entity={entity} />
                            ) : (
                                <HListChildrenIcons entity={entity} />
                            )}
                        </div>
                    ) : (
                        <div
                            className={clsx(styles.box, hovered && !isIntegrationItem && styles.boxHovered)}
                            ref={drop}
                        />
                    )}

                    {showChildrenAmount && (
                        <Text align="center" variant="inactive" size="xs">
                            {entity?.childrenList?.length} {entity?.childrenList?.length === 1 ? 'item' : 'items'}
                        </Text>
                    )}

                    {entity?.childrenList?.length === 0 ? (
                        <div className={styles.details}>
                            <Text align="center" variant="inactive">
                                Folder is empty
                            </Text>
                            {!isIntegrationItem && <QuickCreateButton eid={eid} variant="primary" label="Add" />}
                        </div>
                    ) : (
                        <div className={styles.details}>
                            <Text align="center" className={styles.title}>
                                {entity?.name || 'Untitled Folder'}
                            </Text>
                            <HListMetadata entity={entity} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default HListPreview;

const ImagePreview = ({ entity }: { entity: Entity }) => {
    const title = entity.name || `Untitled ${typeInfo[entity['@type']]?.fancyName}`;
    let url = entity.thumbnailUrl;
    const isEntityImage = !!isImage(entity.url);
    if (isEntityImage) url = entity.url;
    const dispatch = useDispatch();

    const closeImageModal = () => dispatch(hideGlobalModal('ImageModal'));

    const showImageModal = () =>
        dispatch(
            showGlobalModal('ImageModal', { entity, onClose: closeImageModal }, { canClose: true, backdrop: 'darken' }),
        );

    return (
        <div
            className={`${styles.galleryItem} ${isEntityImage ? styles.actualImage : ''}`}
            onClick={isEntityImage ? showImageModal : noop}
        >
            {url ? (
                // eslint-disable-next-line @next/next/no-img-element
                <img className={styles.image} width={500} height={500} src={url} data-title={title} alt={title} />
            ) : (
                <span className={styles.imageIcon}>{getEntityIcon(entity)}</span>
            )}
            <div className={styles.metadata}>
                <span className={styles.metadataRow}>
                    <span className={styles.metadataIcon}>{getEntityIcon(entity)}</span>
                    <span className={styles.metadataTitle}>{title}</span>
                </span>
            </div>
        </div>
    );
};

const GalleryViewPreview: React.FunctionComponent<EntityContainerProps> = (props: EntityContainerProps) => {
    const { entity } = props;
    const children = useEntities(entity?.childrenList || [], true, 'GalleryViewPreview');

    return (
        <div className={styles.galleryContainer}>
            {children.map((child) => (
                <ImagePreview key={child?.id} entity={child} />
            ))}
        </div>
    );
};

const PREVIEW_AMOUNT = 9;

const HListChildrenIcons = ({ entity }: { entity: Entity }) => {
    const childrenEntities = useEntities(
        entity?.childrenList?.slice(0, PREVIEW_AMOUNT) || [],
        true,
        'HListChildrenIcons',
    );
    const extraAmount =
        entity?.childrenList?.length > PREVIEW_AMOUNT ? entity?.childrenList?.length - PREVIEW_AMOUNT + 1 : 0;

    return (
        <>
            {childrenEntities?.map((child, i) => (
                <div key={'icon-' + i + child?.id}>
                    {extraAmount && i + 1 === childrenEntities.length ? `+${extraAmount}` : getEntityIcon(child)}
                </div>
            ))}
        </>
    );
};

const IntegrationHListChildrenIcons = ({ entity }: { entity: Entity }) => {
    const extraAmount =
        entity?.childrenList?.length > PREVIEW_AMOUNT ? entity?.childrenList?.length - PREVIEW_AMOUNT + 1 : 0;

    const integrationItemsList = useSelector(getIntegrationItemsListSelector(entity?.app, entity?.appId));
    const getChildren = (items: IntegrationItem[]) => {
        return items?.filter((item) => entity?.childrenList?.includes(item.id)).slice(0, PREVIEW_AMOUNT);
    };

    return (
        <>
            {getChildren(integrationItemsList)?.map((child, i) => (
                <div key={'icon-' + i + child?.id}>
                    {extraAmount && i + 1 === PREVIEW_AMOUNT
                        ? `+${extraAmount}`
                        : getEntityIcon({ ...child, app: entity?.app, appId: entity?.appId })}
                </div>
            ))}
        </>
    );
};

const getHumanReadableTime = (timestamp: string) => {
    return `${new Date(timestamp).toLocaleDateString()} ${new Date(timestamp).toLocaleTimeString()}`;
};

const buildMetadata = (source: {
    owner: string;
    place: string;
    placeLabel?: string;
    created_at?: string;
    updated_at?: string;
}) => {
    const metadata = [
        {
            label: 'Owned by',
            value: source.owner,
        },
        {
            label: source.placeLabel ? source.placeLabel : 'Dataspace',
            value: source.place.charAt(0).toUpperCase() + source.place.slice(1),
        },
    ];
    if (source.created_at) {
        metadata.push({
            label: 'Created',
            value: getHumanReadableTime(source.created_at),
        });
    }
    if (source.updated_at) {
        metadata.push({
            label: 'Modified',
            value: getHumanReadableTime(source.updated_at),
        });
    }
    return metadata;
};

const HListMetadata = ({ entity }: { entity: Entity }) => {
    const [dataspaceEntity, ownerEntity] = useEntities(
        [entity?.metadata?.dsid, entity?.metadata?.owned_by],
        false,
        'HListMetadata',
    );
    const isIntegrationItem = entity?.['@type'] === 'IntegrationItem';

    const metadata = buildMetadata({
        owner: isIntegrationItem ? entity?.appId : ownerEntity?.name || 'Unknown',
        place: isIntegrationItem ? entity?.app : dataspaceEntity?.name || 'Unknown',
        placeLabel: isIntegrationItem ? 'App' : undefined,
        created_at: entity?.createdTime || entity?.metadata?.created_at,
        updated_at: entity?.metadata?.updated_at,
    });

    return (
        <div className={styles.hlistMetadata}>
            {metadata?.length &&
                metadata.map((item, i) => (
                    <span className={styles.row} key={'metadata-' + i + entity?.metadata?.id}>
                        <Text align="center" variant="inactive" size="xs" className={styles.label}>
                            {item.label}
                        </Text>
                        <Text align="center" size="xs" className={styles.value}>
                            {item.value}
                        </Text>
                    </span>
                ))}
        </div>
    );
};
