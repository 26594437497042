export default function SearchIcon(): JSX.Element {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6 .61a5 5 0 1 0 3.16 8.87l2.98 2.99a.5.5 0 0 0 .71-.71L9.87 8.78A5 5 0 0 0 6 .62Zm-4 5a4 4 0 1 1 8 0 4 4 0 0 1-8 0Z"
                fill="#333"
            />
        </svg>
    );
}
