import styles from '../contextMenu.module.scss';
import clsx from 'clsx';
import Tooltip from 'components/Tooltip';

const FlatListItems = ({ items, currentSelectedSubmenuItem, setCurrentSelectedSubmenuItem }) => {
    return (
        <div className={styles.entityTypeRow}>
            {items.map((entityType) => (
                <Tooltip
                    key={'tippy-menu-entity-type-' + entityType.value}
                    placement="bottom"
                    content={
                        <div className={'tooltipTippy'} id={'tooltip-menu-entity-type-' + entityType.value}>
                            {entityType.name}
                        </div>
                    }
                    appendTo={() => document.body}
                >
                    <div
                        className={clsx(styles.item, currentSelectedSubmenuItem === entityType.name && styles.selected)}
                        onClick={entityType.onClick}
                        data-item-type={entityType.type}
                        data-item-value={entityType.value}
                        onMouseEnter={() => setCurrentSelectedSubmenuItem(entityType.name)}
                        onMouseLeave={() => setCurrentSelectedSubmenuItem(null)}
                        data-action-name={currentSelectedSubmenuItem === entityType.name && entityType.name}
                    >
                        {entityType.icon}
                    </div>
                </Tooltip>
            ))}
        </div>
    );
};

export default FlatListItems;
