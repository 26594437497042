import styles from '@/components/sidebar/styles.module.scss';

const UserIcon = ({ src = '' }) => {
    return (
        <div>
            <img className={styles.userAvatar} src={src || '/defaultAvatar.png'} alt="avatar" />
        </div>
    );
};

export default UserIcon;
