import { QUERY_AI, QUERY_AI_SUCCESS, QUERY_AI_FAILURE } from '../actionTypes';
import { AIState } from '../types';

const initialState = {
    chatLog: [],
} as AIState;

const AIReducer = (state = initialState, action) => {
    const getNewState = () => {
        switch (action.type) {
            case QUERY_AI: {
                return {
                    ...state,
                    pending: true,
                    chatLog: [...state.chatLog, { text: action.payload.query, fromUser: true }],
                };
            }
            case QUERY_AI_SUCCESS: {
                return {
                    ...state,
                    pending: false,
                    chatLog: [
                        ...state.chatLog,
                        {
                            text: action.payload.response.text,
                            fromUser: false,
                            sourceId: action.payload.response.sourceDocuments?.[0]?.metadata.id,
                        },
                    ],
                };
            }
            case QUERY_AI_FAILURE: {
                return {
                    ...state,
                    pending: false,
                };
            }
            default:
                return state;
        }
    };

    // const newState = getNewState();
    // if (typeof window !== 'undefined') window.localStorage.setItem('AIState', cleanUIState(newState));

    return getNewState();
};

export default AIReducer;
