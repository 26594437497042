import { MouseSubmenuSafeArea } from '@/components/MouseSubmenuSafeArea';
import styles from '@/components/modals/contextMenu.module.scss';
import TriangleRightIcon from '@/icons/TriangleRightIcon';
import clsx from 'clsx';
import { useRef } from 'react';

// TODO: replace with one component
const SubMenuItem = ({
    item,
    currentSelectedItem,
    setCurrentSelectedItem,
    currentSubmenuList,
    setCurrentSubmenuList,
    currentSelectedSubmenuItem,
    setCurrentSelectedSubmenuItem,
    subMenuPositionCSS,
}) => {
    const itemRef = useRef(null);
    return (
        <li
            className={clsx(
                styles.submenuParent,
                currentSelectedItem === item.name && styles.selected,
                // styles.selected,
                currentSelectedItem === item.name && currentSubmenuList && styles.submenuParentExpanded,
            )}
            key={item.name}
            onMouseEnter={() => setCurrentSelectedItem(item.name)}
            onMouseLeave={() => setCurrentSelectedItem(null)}
        >
            <span className={clsx(styles.item, currentSelectedItem === item.name && styles.selected)}>
                {item.icon && <div className={styles.submenuParentIcon}>{item.icon}</div>}
                {!item?.helperLabel ? (
                    <span className={styles.submenuParentLabel}>{item.name}</span>
                ) : (
                    <span className={styles.submenuParentLabel}>
                        <span className={styles.submenuBaseLabelPart}>{item.helperLabel.base}</span>
                        <span className={styles.submenuExtraLabelPart}>{item.helperLabel.extra}</span>
                    </span>
                )}
                <div className={styles.submenuParentExpandIcon}>
                    <TriangleRightIcon />
                </div>
            </span>
            <menu
                ref={itemRef}
                className={styles.submenu}
                style={subMenuPositionCSS}
                onMouseEnter={() => setCurrentSubmenuList(item.subMenuItems)}
                onMouseLeave={() => {
                    setCurrentSelectedSubmenuItem(null);
                    setCurrentSubmenuList(null);
                }}
            >
                <MouseSubmenuSafeArea parentRef={itemRef} className={styles.safeZone} />
                {item.subMenuItems.map((subMenuItem, idx) => {
                    return (
                        <div
                            key={idx + subMenuItem.value}
                            onClick={subMenuItem.onClick}
                            onMouseEnter={() => setCurrentSelectedSubmenuItem(subMenuItem.name)}
                            data-item-type={subMenuItem.type}
                            data-item-value={subMenuItem.value}
                            data-item-app={subMenuItem.app}
                            data-item-app-id={subMenuItem.appId}
                            data-item-mime-type={subMenuItem.value}
                            className={clsx(
                                styles.item,
                                currentSelectedSubmenuItem === subMenuItem.name && styles.selected,
                            )}
                            data-action-name={currentSelectedSubmenuItem === subMenuItem.name && subMenuItem.name}
                        >
                            <div className={styles.itemIcon}>
                                {subMenuItem.icon}
                                {subMenuItem.iconUrl && <img src={subMenuItem.iconUrl} alt={subMenuItem.name} />}
                            </div>
                            <span className={styles.itemLabel}>{subMenuItem.name}</span>
                        </div>
                    );
                })}
            </menu>
        </li>
    );
};

export default SubMenuItem;
