import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { toast } from '@/redux/ui/actions';
import styles from './inviteLink.module.scss';

const InviteLink = (props) => {
    const { dsid, inviteHash, style, className } = props;
    const link = `${location.origin}/invite/${dsid}/${inviteHash}`;
    const dispatch = useDispatch();
    const copyToClipboard = () => {
        navigator.clipboard.writeText(link);
        dispatch(
            toast({
                msgType: 'success',
                text: `Successfully copied invite link`,
            }),
        );
    };
    if (!dsid || !inviteHash) return null;
    return (
        <Form className={className} style={style}>
            <div className={styles.inviteLinkField}>
                <Form.Control className={styles.input} type="text" disabled={true} value={link}></Form.Control>
                <Button className={styles.button} onClick={copyToClipboard}>
                    Copy Link
                </Button>
            </div>
        </Form>
    );
};

export default InviteLink;
