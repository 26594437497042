import { v4 as uuidv4 } from 'uuid';

const buildFields = (
    fields: { name: string; type: string; canonical?: boolean; hint?: string; subType?: string }[],
) => {
    return fields.map((field) => {
        const uniqueId = 'Field_' + uuidv4();
        return {
            id: uniqueId,
            name: field.name,
            value: '',
            fieldType: field.type,
            subType: field.subType,
            canonical: field.canonical || false,
            hint: field.hint || undefined,
        };
    });
};
// ✓ Vehicle
// ✓ Real Estate
// ✓ Passport
// Insurance
// ✓ Membership
// ✓ Login/Account
// ✓ Credit Card
// ✓ Bank Account
// Legal Entity
// Crypto Wallet?
// Dog
const objectTemplateSchema = {
    account: {
        name: 'Application Account',
        fields: buildFields([
            { name: 'Application Name', type: 'string', canonical: true },
            { name: 'Email', type: 'string', canonical: true },
            { name: 'Username', type: 'string', canonical: true },
            { name: 'Password', type: 'string', canonical: true },
            { name: 'Website', type: 'string', canonical: true },
            { name: 'Category', type: 'string', canonical: true },
            { name: 'Card', type: 'entity', canonical: true },
        ]),
    },
    bank_account: {
        name: 'Bank Account',
        fields: buildFields([
            { name: 'Bank Name', type: 'bankName', canonical: true },
            { name: 'Account Type', type: 'string', canonical: true },
            { name: 'Account Number', type: 'string', canonical: true },
            { name: 'Routing Number', type: 'string', canonical: true },
            { name: 'Account Holder', type: 'entity', canonical: true },
        ]),
    },
    drivers_license: {
        name: "Driver's License",
        fields: buildFields([
            { name: "Driver's License #", type: 'string' },
            { name: 'Class', type: 'string' },
            { name: 'State', type: 'string' },
            { name: 'Expiration Date', type: 'date' },
            { name: 'First Name', type: 'string' },
            { name: 'Last Name', type: 'string' },
            { name: 'Address', type: 'string' },
            { name: 'Date of Birth', type: 'date' },
            { name: 'Restrictions', type: 'string' },
        ]),
    },
    legal_entity: {
        name: 'Legal Entity',
        fields: buildFields([
            { name: 'Company Type', type: 'companyType', canonical: true },
            { name: 'Legal Name', type: 'string', canonical: true },
            { name: 'Business address', type: 'string', canonical: true },
            { name: 'Contact Phone', type: 'string', canonical: true },
            { name: 'Contact Email', type: 'string', canonical: true },
            { name: 'FEIN', type: 'string', canonical: true, hint: 'Fed Employer Identification Number' },
            { name: 'Entity Number', type: 'string', canonical: true },
        ]),
    },
    membership: {
        name: 'Membership',
        fields: buildFields([
            { name: 'Product', type: 'string' },
            { name: 'Subscription/Plan', type: 'string' },
            { name: 'Membership #', type: 'string' },
            { name: 'Membership status', type: 'string' },
            { name: 'Date started', type: 'date' },
            { name: 'Membership expiration', type: 'date' },
            { name: 'Billing History', type: 'Collection' },
        ]),
    },
    passport: {
        name: 'Passport',
        fields: buildFields([
            { name: 'Type', type: 'string', canonical: true },
            { name: 'Code', type: 'string', canonical: true },
            { name: 'Passport Number', type: 'number', canonical: true },
            { name: 'Surname', type: 'string', canonical: true },
            { name: 'Given Name', type: 'string', canonical: true },
            { name: 'Nationality', type: 'string', canonical: true },
            { name: 'Date of Birth', type: 'date', canonical: true },
            { name: 'Place of Birth', type: 'string', canonical: true },
            { name: 'Gender', type: 'gender', canonical: true },
            { name: 'Date of Issue', type: 'date', canonical: true },
            { name: 'Date of Expiration', type: 'date', canonical: true },
        ]),
    },
    payment_card: {
        name: 'Payment Card',
        fields: buildFields([
            { name: '', type: 'paymentCard', canonical: true },
            { name: 'Card Number', type: 'string', canonical: true },
            { name: 'Name on Card', type: 'string', canonical: true },
            { name: 'Expiration Date', type: 'string', canonical: true },
            { name: 'Security Code', type: 'string', canonical: true },
            { name: 'Billing Address', type: 'string' },
        ]),
    },
    real_estate: {
        name: 'Real Estate Property',
        fields: buildFields([
            { name: 'Address', type: 'string' },
            { name: 'Year Built', type: 'string' },
            { name: 'Lot Size', type: 'string' },
            { name: 'Purchase Date', type: 'date' },
            { name: 'Purchase Price', type: 'number' },
            { name: 'Owner', type: 'entity' },
            { name: 'Deed', type: 'entity' },
            { name: 'Insurances', type: 'entity' },
            { name: 'Manager', type: 'entity' },
            { name: 'Realtor', type: 'entity' },
        ]),
    },
    vehicle: {
        name: 'Vehicle',
        fields: buildFields([
            { name: 'Make', type: 'vehicleMake', canonical: true },
            { name: 'Model', type: 'vehicleModel', canonical: true },
            { name: 'Year', type: 'vehicleYear', canonical: true },
            { name: 'Lease', type: 'entity' },
            { name: 'Insurance', type: 'entity' },
            { name: 'License Plate #', type: 'string' },
            { name: 'VIN', type: 'string' },
            { name: 'Purchase Date', type: 'date' },
            { name: 'Mileage', type: 'number' },
            { name: 'Title', type: 'entity' },
        ]),
    },
};

export const subObjectTemplateSchema = {
    companyType: {
        LLC: {
            fields: buildFields([
                { name: 'Service of Process Entity', type: 'string', subType: 'companyType', canonical: true },
            ]),
        },
        LP: {
            fields: buildFields([
                { name: 'California SOS File Number', type: 'string', subType: 'companyType', canonical: true },
            ]),
        },
        'S-Corp': {
            fields: buildFields([
                { name: 'Date Incorporated', type: 'date', subType: 'companyType', canonical: true },
                { name: 'Business Activity Code', type: 'string', subType: 'companyType', canonical: true },
            ]),
        },
    },
};

export default objectTemplateSchema;
