import { GET_DIR_CONTENT, GET_DIR_CONTENT_FAILURE, GET_DIR_CONTENT_SUCCESS } from '../actionTypes';

export const getDirContent = (path: string): ReduxAction => {
    return {
        type: GET_DIR_CONTENT,
        payload: { path },
    };
};

export const getDirContentSuccess = (tree): ReduxAction => {
    return {
        type: GET_DIR_CONTENT_SUCCESS,
        payload: { tree },
    };
};

export const getDirContentFailure = (error): ReduxAction => {
    return {
        type: GET_DIR_CONTENT_FAILURE,
        payload: { error },
    };
};
