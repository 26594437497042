export default function SharedIcon(): JSX.Element {
    return (
        <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.8275 3.65355L8.84052 5.64052L8.48697 5.99407L8.13341 5.64052L6.14644 3.65355C5.95118 3.45829 5.95118 3.14171 6.14644 2.94644C6.34171 2.75118 6.65829 2.75118 6.85355 2.94644L7.99427 4.08716L7.99428 1.45085C7.99428 1.17471 8.21814 0.950851 8.49428 0.950851C8.77042 0.950851 8.99428 1.17471 8.99428 1.45085L8.99427 4.07256L10.1204 2.94644C10.3156 2.75118 10.6322 2.75118 10.8275 2.94644C11.0228 3.14171 11.0228 3.45829 10.8275 3.65355ZM1.5 0.999994C0.671573 0.999994 0 1.67157 0 2.49999V4.49999C0 5.32842 0.671573 5.99999 1.5 5.99999H3.5C4.32843 5.99999 5 5.32842 5 4.49999V2.49999C5 1.67157 4.32843 0.999994 3.5 0.999994H1.5ZM1 2.49999C1 2.22385 1.22386 1.99999 1.5 1.99999H3.5C3.77614 1.99999 4 2.22385 4 2.49999V4.49999C4 4.77614 3.77614 4.99999 3.5 4.99999H1.5C1.22386 4.99999 1 4.77614 1 4.49999V2.49999ZM7.5 6.99999C6.67157 6.99999 6 7.67157 6 8.49999V10.5C6 11.3284 6.67157 12 7.5 12H9.5C10.3284 12 11 11.3284 11 10.5V8.49999C11 7.67157 10.3284 6.99999 9.5 6.99999H7.5ZM7 8.49999C7 8.22385 7.22386 7.99999 7.5 7.99999H9.5C9.77614 7.99999 10 8.22385 10 8.49999V10.5C10 10.7761 9.77614 11 9.5 11H7.5C7.22386 11 7 10.7761 7 10.5V8.49999ZM2.13342 7.35947L0.146447 9.34644C-0.0488155 9.54171 -0.0488155 9.85829 0.146447 10.0536C0.341709 10.2488 0.658291 10.2488 0.853553 10.0536L1.97967 8.92744L1.97966 11.5491C1.97966 11.8253 2.20352 12.0491 2.47966 12.0491C2.7558 12.0491 2.97966 11.8253 2.97966 11.5491L2.97967 8.91283L4.12039 10.0536C4.31565 10.2488 4.63223 10.2488 4.82749 10.0536C5.02276 9.85829 5.02276 9.54171 4.82749 9.34644L2.84052 7.35947L2.48697 7.00592L2.13342 7.35947Z"
                fill="#333333"
            />
        </svg>
    );
}
