export default function DeleteIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.51136 1C4.51136 0.654822 4.79119 0.375 5.13636 0.375H9.86364C10.2088 0.375 10.4886 0.654822 10.4886 1V2.54167H14C14.3452 2.54167 14.625 2.82149 14.625 3.16667C14.625 3.51184 14.3452 3.79167 14 3.79167H13.3787L12.2577 14.0678C12.2231 14.3848 11.9553 14.625 11.6364 14.625H3.36364C3.04469 14.625 2.77691 14.3848 2.74232 14.0678L1.62129 3.79167H1C0.654822 3.79167 0.375 3.51184 0.375 3.16667C0.375 2.82149 0.654822 2.54167 1 2.54167H4.51136V1ZM5.76136 2.54167H9.23864V1.625H5.76136V2.54167ZM2.87871 3.79167L3.92416 13.375H11.0758L12.1213 3.79167H2.87871Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.9555 4.37658C5.2998 4.35199 5.59885 4.61117 5.62344 4.95547L6.12344 11.9555C6.14803 12.2998 5.88886 12.5988 5.54456 12.6234C5.20026 12.648 4.90121 12.3888 4.87662 12.0445L4.37662 5.04453C4.35202 4.70022 4.6112 4.40118 4.9555 4.37658ZM10.0446 4.37658C10.3889 4.40118 10.648 4.70022 10.6234 5.04453L10.1234 12.0445C10.0988 12.3888 9.7998 12.648 9.4555 12.6234C9.1112 12.5988 8.85202 12.2998 8.87662 11.9555L9.37662 4.95547C9.40121 4.61117 9.70026 4.35199 10.0446 4.37658ZM7.50003 4.375C7.8452 4.375 8.12503 4.65482 8.12503 5V12C8.12503 12.3452 7.8452 12.625 7.50003 12.625C7.15485 12.625 6.87503 12.3452 6.87503 12V5C6.87503 4.65482 7.15485 4.375 7.50003 4.375Z"
                fill="#333333"
            />
        </svg>
    );
}
