import Button from '../../DesignSystem/Button';
import Text from '../../DesignSystem/Text';
import { clearBrowsed, hideGlobalModal } from '@/redux/ui/actions';
import { hardDeleteEntities, emptyTrash } from '@/redux/entities/actions';
import { useDispatch } from 'react-redux';
import { useEntities } from '@/hooks/reduxHooks';
import typeInfo from '@/components/entities/typeInfo';

type Props = {
    entities?: EntityIdentity[];
    allFiles?: boolean;
};

const ConfirmHardRemoveModal = ({ entities, allFiles }: Props): JSX.Element => {
    const dispatch = useDispatch();
    const removingEntities = useEntities(entities?.map(({ id }) => id) || [], true, 'ConfirmRemoveModal');

    const entityNames = removingEntities
        .map((entity) => (entity?.name ? entity.name : typeInfo[entity['@type']].fancyName))
        .join(', ');

    const closeModal = () => dispatch(hideGlobalModal('ConfirmHardRemoveModal'));

    const handleHardDelete = () => {
        dispatch(clearBrowsed());
        if (allFiles) {
            dispatch(emptyTrash());
        } else {
            dispatch(hardDeleteEntities(entities));
        }
        closeModal();
    };

    return (
        <div style={{ padding: 20, maxWidth: '90vw' }}>
            <div>
                <Text size="h3" weight="semiBold">
                    Delete forever?
                </Text>
                <Text>
                    {allFiles ? (
                        'All Entities from the trash will be deleted forever.'
                    ) : (
                        <>
                            <span className="font-italic">{entityNames}</span> will be deleted forever.
                        </>
                    )}
                </Text>
                <Text>You won&#39;t be able to restore it.</Text>
            </div>
            <div className="d-flex justify-content-end">
                <Button variant="plain" size="s" onClick={closeModal} className="mr-2">
                    Cancel
                </Button>
                <Button variant="primary" size="s" onClick={handleHardDelete}>
                    Delete forever
                </Button>
            </div>
        </div>
    );
};

export default ConfirmHardRemoveModal;
