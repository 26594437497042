import Head from 'next/head';
import { useState, useEffect, useRef, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { addMembers, getAllCollaborators, updateMembersRole } from '@/redux/entities/actions';
import { getDataspaceSelector, getUsersInDSSelector } from '@/redux/entities/selectors';
import styles from './Members.module.scss';
import bedrockDropdownStyles from '../../../DesignSystem/Dropdown/BedrockDropdownStyles.module.scss';
import Button from '../../../DesignSystem/Button';
import UserAvatar from '../../../entities/subcomponents/UserAvatar';
import useOutsideClick from '@/hooks/outsideClick';
import CreatableSelect from 'react-select/creatable';
import * as yup from 'yup';
import { signIn } from 'next-auth/react';
import { getUserSelector } from '@/redux/user/selectors';
import 'tippy.js/dist/tippy.css';
import Text from '../../../DesignSystem/Text';
import CaretDownSmIcon from '@/icons/CaretDownSmIcon';
import AddSmIcon from '@/icons/AddSmIcon';
import { without } from 'lodash';
import Switch from 'react-switch';
import InviteLink from '@/components/modals/SettingsModal/subcomponents/InviteLink';
import { updateEntity } from '@/redux/entities/actions';
import { cloneEntity } from '@/utils/helpers';
import Tooltip from 'components/Tooltip';

const schema = yup.object().shape({
    email: yup.string().email(),
});
const components = {
    DropdownIndicator: null,
};
const createOption = (label: string) => ({
    label,
    value: label,
});

const InviteUsersPopover: React.FunctionComponent<{ closePopover: any; existingEmails: any[] }> = ({
    closePopover,
    existingEmails,
}) => {
    const dispatch = useDispatch();
    const popoverRef = useRef<HTMLDivElement>(null);
    useOutsideClick(popoverRef, closePopover);

    const dataspace = useSelector(getDataspaceSelector);
    const { id, entity, dsid } = useSelector(getUserSelector);
    const [emails, setEmails] = useState([]);
    const [values, setValues] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isFormActive, setFormActive] = useState(false);

    const isSingleEmail = inputValue && schema.isValidSync({ email: inputValue });
    const shouldResendInvite =
        (isSingleEmail && existingEmails.includes(inputValue)) ||
        (emails.length && emails.every((email) => existingEmails.includes(email)));

    useEffect(() => {
        if (emails.length > 0 || isSingleEmail) {
            setFormActive(true);
        } else {
            setFormActive(false);
        }
    }, [emails, isSingleEmail]);

    const multiSelectStyles = {
        valueContainer: (provided) => ({
            ...provided,
            padding: '1px 3px',
        }),
        multiValue: (provided) => ({
            ...provided,
            lineHeight: '1.3',
            margin: 1,
            '& div:first-of-type': {
                paddingRight: 1,
            },
        }),
        control: (provided) => ({
            ...provided,
            minHeight: 31,
        }),
        input: (provided) => ({
            ...provided,
            fontSize: '85%',
            lineHeight: '1.3',
            margin: '1px 2px',
        }),
        indicatorsContainer: (provided) => ({
            ...provided,
            '& div': {
                padding: '0 8px',
            },
        }),
        option: (provided) => ({
            ...provided,
            fontSize: '85%',
            lineHeight: '1.3',
        }),
    };

    const emailParams = JSON.stringify({ type: 'invitation', from: entity?.name, dataspace: dataspace?.name });
    const sendInviteEmailFunction = (email: string) => {
        signIn(
            'email',
            {
                email,
                callbackUrl: `${window.location.origin}/welcome?dsid=${dsid}&inviteFrom=${id}`,
                redirect: false,
            },
            { emailParams },
        );
    };

    const handleInviteClick = () => {
        if (!emails.length && !isSingleEmail) return;
        if (isSingleEmail) emails.push(inputValue);
        if (shouldResendInvite) {
            emails.forEach((email) => sendInviteEmailFunction(email));
        } else {
            dispatch(addMembers(emails, () => emails.forEach((email) => sendInviteEmailFunction(email))));
        }
        closePopover();
    };

    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'Backspace':
                if (inputValue.length === 0) {
                    setEmails((prevData) => {
                        prevData.pop();
                        return [...prevData];
                    });
                    setValues((prevData) => {
                        prevData.pop();
                        return [...prevData];
                    });
                }
                break;
            case ',':
            case ' ':
                event.preventDefault();
            case 'Enter':
            case 'Tab':
                if (inputValue.length === 0) {
                    if (emails.length > 0 && event.key === 'Enter') {
                        handleInviteClick();
                    }
                    break;
                }
                if (!schema.isValidSync({ email: inputValue })) {
                    setErrorMessage('Invalid Email');
                    break;
                }
                if (emails.indexOf(inputValue) !== -1) {
                    setErrorMessage('Already added');
                    break;
                }
                setEmails((prevData) => [...prevData, inputValue]);
                setValues((es) => [...es, createOption(inputValue)]);
                setInputValue('');
                event.preventDefault();
        }
    };

    const handleInputChange = (newInputValue, actionMeta) => {
        if (['input-blur', 'menu-close'].includes(actionMeta.action)) return;
        setInputValue(newInputValue);
        setErrorMessage('');
    };

    const handleChange = (value) => {
        setValues(value);
    };

    return (
        <div className={styles.inviteUsers} ref={popoverRef}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span>Type email and hit enter or tab...</span>
                {errorMessage?.length > 0 && <span style={{ color: 'red' }}>{errorMessage}</span>}
            </div>
            <CreatableSelect
                isMulti
                isClearable
                autoFocus
                styles={multiSelectStyles}
                components={components}
                onKeyDown={handleKeyDown}
                placeholder=""
                menuIsOpen={false}
                onChange={handleChange}
                value={values}
                inputValue={inputValue}
                onInputChange={handleInputChange}
            />
            <Button size="s" className={styles.inviteButton} onClick={handleInviteClick} disabled={!isFormActive}>
                {shouldResendInvite ? 'Resend Invite' : 'Invite'}
            </Button>
        </div>
    );
};

const RoleMenu = (props) => {
    const { users, handleRoleClick } = props;
    return (
        <div
            className={`${bedrockDropdownStyles.dropdownMenu} ${styles.membersRoleDropdownItems} ${styles.dataspaceSelectorDropdown}`}
        >
            {/*<div className={styles.dropdownItemContainer} onClick={handleRoleClick(users, 'admin')}>*/}
            {/*    <div className={styles.dropdownItemText}>Admin</div>*/}
            {/*    <span className={styles.dropdownItemSubtext}>Can change Dataspace settings and invite new members</span>*/}
            {/*</div>*/}
            {/*<div className={styles.dropdownItemContainer} onClick={handleRoleClick(users, 'member')}>*/}
            {/*    <div className={styles.dropdownItemText}>Member</div>*/}
            {/*    <span className={styles.dropdownItemSubtext}>*/}
            {/*        Cannot change Dataspace settings nor invite new members*/}
            {/*    </span>*/}
            {/*</div>*/}
            {/*<div className={styles.dropdownItemContainer} onClick={handleRoleClick(users, 'guest')}>*/}
            {/*    <div className={styles.dropdownItemText}>Guest</div>*/}
            {/*    <span className={styles.dropdownItemSubtext}>Super limited permission set TBD</span>*/}
            {/*</div>*/}
            <div
                className={`${styles.dropdownItemContainer} ${styles.danger}`}
                onClick={handleRoleClick(users, 'remove')}
            >
                <div className={styles.dropdownItemText}>Remove from Dataspace</div>
                {/*<span className={styles.dropdownItemSubtext}>*/}
                {/*    Cannot change Dataspace settings nor invite new members*/}
                {/*</span>*/}
            </div>
        </div>
    );
};

const DataspaceMembers = function (): React.ReactElement {
    const dataspace = useSelector(getDataspaceSelector);
    const users = useSelector(getUsersInDSSelector);
    const currentUser = useSelector(getUserSelector);
    const dispatch = useDispatch();
    const [isInviteLinkEnabled, setInviteLinkEnabled] = useState(dataspace.invite?.enabled || false);
    const inviteLinkSwitch = () => {
        if (isInviteLinkEnabled) {
            const newEntity = cloneEntity(dataspace);
            newEntity.invite.enabled = false;
            dispatch(updateEntity(dataspace.metadata.id, newEntity, 'dataspaceMembers1'));
        } else {
            const newEntity = cloneEntity(dataspace);
            newEntity.invite = {
                enabled: true,
                hash: newEntity.invite?.hash || crypto.randomUUID().replaceAll('-', ''),
            };
            dispatch(updateEntity(dataspace.metadata.id, newEntity, 'dataspaceMembers2'));
        }
    };
    useEffect(() => {
        setInviteLinkEnabled(dataspace.invite?.enabled || false);
    }, [dataspace.invite?.enabled]);
    const resetInviteLink = () => {
        const newEntity = cloneEntity(dataspace);
        newEntity.invite.hash = crypto.randomUUID().replaceAll('-', '');
        dispatch(updateEntity(dataspace.metadata.id, newEntity, 'dataspaceMembers3'));
    };
    const existingEmails = useMemo(() => {
        return users.map((u) => u.email);
    }, [users]);

    const usersWithoutDataspaceOwner = useMemo(() => {
        return users.filter((user) => user.memberships[dataspace?.metadata?.id] !== 'owner');
    }, [dataspace?.metadata?.id, users]);

    const isCurrentUserDataspaceOwner = useMemo(() => {
        const dataspaceOwner = users.find((user) => user.memberships[dataspace?.metadata?.id] === 'owner');
        return currentUser?.id === dataspaceOwner?.metadata?.id;
    }, [currentUser?.id, dataspace?.metadata?.id, users]);

    const [isInviteMembersVisible, setInviteMembersVisible] = useState(false);
    const showInviteMembers = (): void => setInviteMembersVisible(true);
    const hideInviteMembers = (): void => setInviteMembersVisible(false);

    const [tippyInstance, setTippyInstance] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);

    useEffect(() => {
        dispatch(getAllCollaborators());
    }, [dataspace?.id]);

    const handleRoleClick = (users, role) => () => {
        dispatch(
            updateMembersRole(
                users.map((u) => u.email),
                role,
                dataspace.metadata.id,
                () => dispatch(getAllCollaborators()),
            ),
        );
        tippyInstance?.hide();
        setSelectedUsers([]);
    };

    const userRows = users?.map((user, idx) => {
        const id = user.metadata.id;
        const isSelected = selectedUsers.includes(id);
        const visibility = isSelected ? 'visible' : undefined;

        return (
            <tr key={`User-${idx}`} className={styles.membersTableRow}>
                <td>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
                        <input
                            type="checkbox"
                            className={styles.membersCheckbox}
                            checked={isSelected}
                            onChange={() => {
                                if (isSelected) {
                                    setSelectedUsers(without(selectedUsers, id));
                                } else setSelectedUsers(selectedUsers.concat([id]));
                            }}
                            style={{ visibility }}
                            disabled={
                                !isCurrentUserDataspaceOwner || user.memberships[dataspace?.metadata?.id] === 'owner'
                            }
                        />
                        <UserAvatar name={user.name} avatarUrl={user.avatarUrl} />
                        {user.name ? user.name : <span style={{ color: 'lightgrey' }}>No name yet...</span>}
                    </div>
                </td>
                <td>{user.email}</td>
                <td>
                    <Tooltip
                        key={'full-' + idx}
                        placement="bottom-end"
                        appendTo={() => document.body}
                        trigger="click"
                        interactive
                        ignoreAttributes
                        onShow={setTippyInstance}
                        disabled={!isCurrentUserDataspaceOwner || user.memberships[dataspace?.metadata?.id] === 'owner'}
                        content={<RoleMenu users={[user]} handleRoleClick={handleRoleClick} />}
                    >
                        <div className={styles.dropdownToggler}>
                            <span>{user.memberships[dataspace?.metadata?.id]}</span>
                            {!isCurrentUserDataspaceOwner ||
                                (user.memberships[dataspace?.metadata?.id] !== 'owner' && <CaretDownSmIcon />)}
                        </div>
                    </Tooltip>
                </td>
            </tr>
        );
    });

    return (
        <>
            <Head>
                <meta property="og:title" content={'Settings - Bedrock'} />
                <title>Members - Bedrock</title>
            </Head>
            <Container>
                {isCurrentUserDataspaceOwner && (
                    <>
                        <div className={styles.headingBlock}>
                            <Text size="m" style={{ display: 'block' }}>
                                Invite Link
                            </Text>
                            <Switch
                                checked={isInviteLinkEnabled}
                                onChange={inviteLinkSwitch}
                                className={styles.switch}
                                onColor="#00A0F7"
                                onHandleColor="#fff"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                height={22}
                                width={40}
                            />
                        </div>
                        {isInviteLinkEnabled && (
                            <>
                                <Text variant="inactive" size="s" style={{ whiteSpace: 'unset' }}>
                                    Share this secret link to invite people to this dataspace. Only users who can invite
                                    members can see this.
                                    <br />
                                    {isCurrentUserDataspaceOwner && (
                                        <>
                                            You can{' '}
                                            <a href="#" onClick={resetInviteLink}>
                                                reset the link
                                            </a>
                                            to generate new one.
                                        </>
                                    )}
                                </Text>
                                <InviteLink
                                    dsid={dataspace.metadata.id}
                                    inviteHash={dataspace.invite?.hash}
                                    style={{ marginTop: 13 }}
                                />
                            </>
                        )}
                    </>
                )}

                <div className={styles.headingBlock}>
                    <Text size="m">Members</Text>
                    <Button size="s" icon={AddSmIcon} variant="primary" onClick={showInviteMembers}>
                        <span>Add Members</span>
                    </Button>
                    {isInviteMembersVisible && (
                        <InviteUsersPopover closePopover={hideInviteMembers} existingEmails={existingEmails} />
                    )}
                </div>
                <table className={styles.membersTable}>
                    <thead>
                        <tr>
                            <th>
                                <input
                                    ref={(i) => {
                                        if (!i) return;
                                        i.indeterminate =
                                            selectedUsers.length > 0 &&
                                            selectedUsers.length < usersWithoutDataspaceOwner.length;
                                    }}
                                    type="checkbox"
                                    className={styles.membersCheckbox}
                                    checked={selectedUsers.length > 0}
                                    onChange={() => {
                                        if (selectedUsers.length === usersWithoutDataspaceOwner.length) {
                                            setSelectedUsers([]);
                                        } else setSelectedUsers(usersWithoutDataspaceOwner.map((u) => u.metadata.id));
                                    }}
                                    disabled={!isCurrentUserDataspaceOwner}
                                />
                                <span className="textS medium tableHeader">User</span>
                            </th>
                            <th>
                                <span className="textS medium tableHeader">Email</span>
                            </th>
                            <th>
                                <span style={{ paddingLeft: '1px' }} className="textS medium tableHeader">
                                    Role
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {users ? (
                            userRows
                        ) : (
                            <tr>
                                <td colSpan={3}>Loading</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </Container>
            {selectedUsers.length > 0 && (
                <div className={styles.membersBulkSection}>
                    <div className={styles.memberSelectionCount}>
                        {selectedUsers.length} user{selectedUsers.length % 10 === 1 ? '' : 's'} selected
                    </div>
                    <Tooltip
                        className={styles.membersBulkButton}
                        placement="bottom-end"
                        appendTo={() => document.body}
                        trigger="click"
                        interactive
                        ignoreAttributes
                        onShow={setTippyInstance}
                        content={
                            <RoleMenu
                                users={users.filter((u) => selectedUsers.includes(u.metadata.id))}
                                handleRoleClick={handleRoleClick}
                            />
                        }
                    >
                        <div>
                            <Button size="s" icon={CaretDownSmIcon} variant="primary">
                                <span>Change Role</span>
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            )}
        </>
    );
};

export default DataspaceMembers;
