import { cloneEntity } from '../../utils/helpers';

import { ReactElement, useState, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { updateEntity } from '../../redux/entities/actions';
import { useEntity } from '../../hooks/reduxHooks';

interface TextFieldProps {
    eid: string;
    propertyKey: string;
    placeholder?: string;
    className?: string;
    canRename?: boolean;
    autoFocus?: boolean;
    name?: string;
    inputEventHandler?: (event: MouseEvent) => void;
    entitySideEffect?: (entity: Entity) => Entity;
    condensedOk?: boolean;
    callback?: () => void;
    fallback?: () => void;
    multiline?: boolean;
    onActiveCallback?: (isActive: boolean) => void;
}

export default function TextField({
    eid,
    propertyKey,
    placeholder,
    className,
    canRename,
    autoFocus,
    inputEventHandler,
    entitySideEffect,
    condensedOk,
    callback,
    fallback,
    multiline,
    onActiveCallback,
}: TextFieldProps): ReactElement {
    const entity = useEntity(eid, condensedOk, 'TextField');
    const [state, setState] = useState(entity?.[propertyKey]);
    const dispatch = useDispatch();

    const [active, setActive] = useState(false);
    const [lockField, setLockField] = useState(false);

    const handleChangeActive = (isActive: boolean) => {
        setActive(isActive);
        onActiveCallback && onActiveCallback(isActive);
    };

    useEffect(() => {
        if (!lockField) {
            setState(entity?.[propertyKey]);
            handleChangeActive(autoFocus);
        }
    }, [entity?.[propertyKey]]);

    const submit = function (e): void {
        if (e) e.preventDefault();
        if (state !== entity?.[propertyKey]) {
            let newEntity = cloneEntity(entity);
            newEntity[propertyKey] = state;
            if (newEntity['@type'] === 'Webwindow' && propertyKey === 'name') {
                newEntity.ignoreWebTitle = state.length > 0;
            }
            if (entitySideEffect) newEntity = entitySideEffect(newEntity);
            dispatch(updateEntity(eid, newEntity, 'textField', callback, fallback));
        } else if (callback) {
            callback();
        }
        handleChangeActive(false);
        setLockField(false);
    };

    const handleChange = function (e): void {
        e.preventDefault();
        setState(e.currentTarget.value);
    };

    const handleRename = function (): void {
        if (canRename) handleChangeActive(true);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const onFocus = (e) => {
        e.target.select();
        handleHeight(e);
        setLockField(true);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
            e.stopPropagation();
            if (inputEventHandler) inputEventHandler(e);
        }
        if (e.key === 'Enter') {
            e.stopPropagation();
            submit(e);
        }
        if (e.key === 'Escape') {
            e.stopPropagation();
            handleChangeActive(false);
            setState(entity?.[propertyKey]);
        }
    };

    const handleHeight = (e) => {
        e.target.style.height = 'auto';
        e.target.style.height = e.target.scrollHeight + 'px';
    };

    return active ? (
        <form onSubmit={submit} className={className}>
            {multiline ? (
                <textarea
                    rows={1}
                    onClick={handleClick}
                    onFocus={onFocus}
                    onChange={handleChange}
                    onBlur={submit}
                    onKeyDown={handleKeyPress}
                    value={state}
                    autoFocus={true}
                    placeholder={placeholder || 'Text here...'}
                    onInput={handleHeight}
                />
            ) : (
                <input
                    type="text"
                    onClick={handleClick}
                    onFocus={onFocus}
                    onChange={handleChange}
                    onBlur={submit}
                    onKeyDown={handleKeyPress}
                    value={state}
                    autoFocus={true}
                    placeholder={placeholder || 'Text here...'}
                />
            )}
        </form>
    ) : (
        <div
            onClick={handleRename}
            onContextMenu={canRename ? handleRename : handleClick}
            className={entity?.[propertyKey] ? className : className}
        >
            {state || placeholder || 'Text here...'}
        </div>
    );
}
