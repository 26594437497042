import React, { forwardRef } from 'react';
import TextStyles from './BedrockTextStyles.module.scss';

type TextType = HTMLDivElement | HTMLHeadingElement | HTMLParagraphElement;

interface TextProps {
    variant?: 'heading' | 'body' | 'inactive';
    size?: 'm' | 's' | 'xs' | 'xxs' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    weight?: 'normal' | 'semiBold' | 'bold';
    align?: 'center' | 'left' | 'right';
    onClick?: (event: MouseEvent) => void;
    className?: string;
    disabled?: boolean;
    noMargin?: boolean;
    style?: { [key: string]: any };
}

const Text = forwardRef<TextType, React.PropsWithChildren<TextProps>>(function TextWithRef(
    {
        variant = 'body',
        align = 'left',
        size = 'm',
        weight = 'normal',
        noMargin = false,
        onClick,
        className,
        children,
        style = undefined,
    },
    ref,
) {
    const handleClick = (e): void => {
        if (onClick) onClick(e);
    };
    const textClasses = [TextStyles[variant], TextStyles[align]];

    let Tag;
    if (variant === 'heading') {
        Tag = size as keyof JSX.IntrinsicElements;
    } else {
        Tag = `div` as keyof JSX.IntrinsicElements;
        textClasses.push(TextStyles[weight]);
    }
    textClasses.push(TextStyles[size], TextStyles[weight]);
    if (noMargin) textClasses.push(TextStyles.noMargin);

    if (className) textClasses.push(className);
    const TextComponent = (
        <Tag onClick={handleClick} className={textClasses.join(' ')} style={style}>
            {className && <span className={className}>{children}</span>}
            {!className && children}
        </Tag>
    );

    // wrap with ref if needed
    if (ref) {
        return <span ref={ref}>{TextComponent}</span>;
    } else {
        return TextComponent;
    }
});

export default Text;
