import { newEntity } from './helpers';
import { v4 as uuidv4 } from 'uuid';
import objectTemplateSchema from './objectTemplateSchemas';

const schema = objectTemplateSchema;

export const buildObjectByType = (type) => {
    // const id = uuidv4();
    const entity = { ...newEntity('Blob'), ...schema[type] };

    return entity;
};

export const createObjectVehicle = () => {
    const id = uuidv4();
    const objectEntity = newEntity('Blob');
    objectEntity.name = 'Vehicle';
    return {
        id,
        objectEntity,
    };
};
