import { DocumentNode } from 'graphql';
import { print as gqlToStr } from 'graphql/language/printer';
import cloneDeep from 'clone-deep';
import { BASE_DOMAIN, BASE_ORIGIN } from '@/config';
import React, { ReactElement } from 'react';
import typeInfo from '../components/entities/typeInfo';
import genericEntitySkeleton from '../components/entities/entitySkeleton';
import { v4 as uuidv4, validate } from 'uuid';
import parse from 'html-react-parser';
import { every, filter, find, omit, pull } from 'lodash';
import { prepareWelcomeEntities } from './welcomeEntities';
import axios from 'axios';
import Compressor from 'compressorjs';
import Thumbnail from '@/components/entities/subcomponents/Thumbnail/Thumbnail';
import ImageIcon from '@/icons/ImageIcon';
import VideoMdIcon from '@/icons/VideoMdIcon';
import PDFIcon from '@/icons/PDFIcon';
import { INTEGRATIONS, INTEGRATIONS_LIST } from 'integrations/constants';
import getConfig from 'next/config';
import { DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID } from '@/redux/constants';
import BedrockInvertBlackIcon from '@/icons/BedrockInvertBlackIcon';
import GoogleIcon from '@/icons/GoogleIcon';
import BedrockIcon from '@/icons/BedrockIcon';
import FolderIcon from '@/icons/FolderIcons/FolderIcon';
import EntityImageIcon from '@/components/EntityImageIcon';
import appsData from '@/components/baseOnboarding/Import/AppList/apps.json';
import HomeIcon from '@/icons/HomeIcon';
import AppEntityIcon from '@/components/baseOnboarding/Import/AppList/AppEntityIcon';
import { buildUID, parseUID } from './IDManager';
import { Option } from '@/components/Omnibox/types';
import dynamic from 'next/dynamic';

const { publicRuntimeConfig } = getConfig();
export const NODE_ENV = publicRuntimeConfig.nodeEnv;

export function isServer(): boolean {
    return !(typeof window != 'undefined' && window.document);
}

export function isEid(id): any {
    return validate(id);
}

export function cloneEntity(object): Entity {
    const obj = cloneDeep(object);
    if (obj?.metadata) delete obj.metadata;
    return obj;
}

export function newEntity(entityType: string): Entity {
    const skeleton = genericEntitySkeleton();
    return Object.assign(cloneEntity(skeleton), typeInfo[entityType].skeleton) as Entity;
}

function convertArraysToHasuraReadableArrays(obj: object): void {
    Object.keys(obj).map((key) => {
        if (Array.isArray(obj[key])) {
            obj[key] = `{${obj[key].join(',')}}`;
        }
    });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncGQLAbortable(
    query: DocumentNode | string,
    variables: object,
    convertArrays = true,
    headers?: Record<string, string>,
): [AbortController, Promise<any>] {
    const queryString = typeof query === 'string' ? query : gqlToStr(query);

    if (convertArrays) convertArraysToHasuraReadableArrays(variables);

    const controller = new AbortController();
    const signal = controller.signal;

    return [controller, asyncGQLRequest({ query: queryString, variables }, headers, signal)];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncGQL(
    query: DocumentNode | string,
    variables: object,
    convertArrays = true,
    headers?: Record<string, string>,
): Promise<any> {
    const queryString = typeof query === 'string' ? query : gqlToStr(query);

    if (convertArrays) convertArraysToHasuraReadableArrays(variables);

    return asyncGQLRequest({ query: queryString, variables }, headers);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function asyncGQLRequest(body: any, headers: Record<string, string>, signal = undefined): Promise<any> {
    return fetch(`${BASE_ORIGIN}/v1/graphql`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...headers },
        body: JSON.stringify(body),
        signal,
    }).then((res) => res.json());
}

export function getFavicon(url: string): ReactElement {
    if (!url) return <EntityImageIcon className="favicon" src="/favicons/faviconBrowser.png" />;
    let urlDomain;
    if (url && url !== '') {
        urlDomain = url.match(/(?:https?:\/\/)*([^\/]+\.[^\/]+)+\/?/)?.[1];
        switch (urlDomain) {
            case 'docs.google.com':
                const googleUrlDomain = url.match(/(?:https?:\/\/)*(?:[^\/]+\.[^\/]+)*\/([^?\/]+)\/?/)?.[1];
                // [x] Documents: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png
                // [x] Spreadsheet: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_spreadsheet_x16.png
                // [x] Forms: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_2_form_x16.png
                // [x] Drawings: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_drawing_x16.png
                // [x] Presentations: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_presentation_x16.png
                // Map: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_map_x16.png
                // Folder: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_folder_x16.png
                // Word: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_word_x16.png
                // Excel: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_excel_x16.png
                // Powerpoint: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_powerpoint_x16.png
                // PDF: https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_3_pdf_x16.png
                switch (googleUrlDomain) {
                    case 'document':
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png`}
                            />
                        );
                    case 'spreadsheets':
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_spreadsheet_x16.png`}
                            />
                        );
                    case 'presentation':
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_presentation_x16.png`}
                            />
                        );
                    case 'forms':
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_2_form_x16.png`}
                            />
                        );
                    case 'drawings':
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_drawing_x16.png`}
                            />
                        );
                    default:
                        return (
                            <EntityImageIcon
                                className="favicon"
                                src={`https://ssl.gstatic.com/docs/doclist/images/mediatype/icon_1_document_x16.png`}
                            />
                        );
                }
            case 'presentation':
                break;
            default:
                return (
                    <EntityImageIcon
                        className="favicon"
                        src={`https://icons.duckduckgo.com/ip2/${urlDomain || BASE_DOMAIN}.ico`}
                    />
                );
        }
    }
}

// export const lazyLoadFavicons = () => {
//     let lazyloadImages;
//     let anyContent;
//     let attempts = 0;
//     const check = () => {
//         anyContent = document.querySelector('.lazy');
//         if (attempts < 100 && !anyContent) {
//             setTimeout(check, 20);
//         } else {
//             if ('IntersectionObserver' in window) {
//                 lazyloadImages = document.querySelectorAll('.lazy');
//                 const imageObserver = new IntersectionObserver(function (entries) {
//                     entries.forEach(function (entry) {
//                         if (entry.isIntersecting) {
//                             const image = entry.target as any;
//                             image.src = image.dataset.src;
//                             image.classList.remove('lazy');
//                             imageObserver.unobserve(image);
//                         }
//                     });
//                 });

//                 lazyloadImages.forEach(function (image) {
//                     imageObserver.observe(image);
//                 });
//             }
//         }
//         attempts++;
//     };
//     check();
// };

export function eidsAreEqual(oldProps, newProps): boolean {
    return oldProps.eid === newProps.eid;
}

export function debounce(func, wait): (...args) => void {
    let timeout;

    return function executedFunction(...args): void {
        const later = function (): void {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

export function throttle(callback, limit): (...args) => void {
    let waiting = false;
    return function (...args): void {
        if (!waiting) {
            callback(...args);
            waiting = true;
            setTimeout(function () {
                waiting = false;
            }, limit);
        }
    };
}

export function isElectron(): boolean {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true;
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    if (
        typeof navigator === 'object' &&
        typeof navigator.userAgent === 'string' &&
        navigator.userAgent.indexOf('Electron') >= 0
    ) {
        return true;
    }

    return false;
}

export const getItemName = (entity: CondensedEntity | Entity) => {
    return entity.name || `Untitled ${typeInfo[entity['@type']]?.fancyName}`;
};

export function isBase(): boolean {
    return !!(typeof window !== 'undefined' && window?.appWrapper === 'base');
}

export function isTauri() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return !!(typeof window !== 'undefined' && window?.__TAURI__);
}

export const getSafeFileName = (file): string => {
    const fileExtension = file.name.split('.')?.pop().toLowerCase();
    const safeFileName = file.name.replace(/[^a-z0-9]/gi, '_').toLowerCase();
    return `${safeFileName}${Date.now()}.${fileExtension}`;
};

export const uploadSingleFile = (
    file,
    name,
    dsid?: string,
    onUploadProgressFn?: (p?: ProgressEvent, eid?: string) => void,
    eid?: string,
): Promise<{ url: string }> => {
    const formData = new FormData();
    formData.append(file.name, file, name);
    if (dsid) formData.append('dsid', dsid);
    return axios
        .post('/api/upload', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onUploadProgressFn ? (e) => onUploadProgressFn(e, eid) : null,
        })
        .then((res) => res.data);
};

const compressImageFile = async (file, quality): Promise<File | Blob> => {
    return new Promise((res) => {
        new Compressor(file, {
            quality,
            maxWidth: 200,
            maxHeight: 200,
            success: (compressedResult) => {
                res(compressedResult);
            },
        });
    });
};

type OnCompleteOrErrorFn = (fileName: string, errorMessage?: string) => void;

export async function uploadFiles(
    entries,
    options,
    dsid?: string,
    onSuccessFn?: OnCompleteOrErrorFn,
    onErrorFn?: OnCompleteOrErrorFn,
    onUploadProgress: (p: ProgressEvent, eid?: string) => void = () => false,
): Promise<Array<Entity>> {
    let allEntities = [];
    for (const entry of entries) {
        const isFileEntry = entry instanceof File || entry instanceof Blob;
        if (isFileEntry || entry.type === 'file') {
            const file = isFileEntry ? entry : entry.content;
            const safeName = getSafeFileName(file);
            const isImageFile = isImage(file.name);
            let uploadedThumbnail;
            // if (file.size > 26214400) {
            //     onErrorFn && onErrorFn(file.name, `File "${file.name}" is larger than 25MB`);
            //     throw new Error('Max file size exceeded');
            // }
            try {
                if (isImageFile) {
                    const compressedImage = await compressImageFile(file, 0.6);
                    uploadedThumbnail = await uploadSingleFile(compressedImage, `thumbnail__${safeName}`);
                }
                const uploadedFile = await uploadSingleFile(file, safeName, dsid, onUploadProgress, entry?.id);
                onSuccessFn && onSuccessFn(file.name);
                const fileEntity = {
                    id: entry?.id || uuidv4(),
                    '@type': 'DigitalDocument',
                    name: file.name,
                    url: uploadedFile?.url,
                    ...(uploadedThumbnail && { thumbnailUrl: uploadedThumbnail?.url }),
                    ...options,
                };
                allEntities.push(fileEntity);
            } catch (err) {
                onErrorFn && onErrorFn(file.name);
            }
        } else if (entry.type === 'directory') {
            const childrenEntities = await uploadFiles(
                entry.children,
                options,
                dsid,
                onSuccessFn,
                onErrorFn,
                onUploadProgress,
            );
            const dirEntity = {
                id: entry?.id || uuidv4(),
                '@type': 'HList',
                name: entry.name || 'Untitled Folder',
                childrenList: childrenEntities
                    .filter((e) => entry.children.some((ch) => ch.name === e.name))
                    .map((e) => e.id),
                ...options,
            };
            allEntities = [...allEntities, dirEntity, ...childrenEntities];

            if (onSuccessFn) {
                onSuccessFn(entry.name);
            }
        }
    }
    return allEntities;
}

export async function getEntriesAsync(items: DataTransferItem[]): Promise<Entry[]> {
    const entriesPromises = items.map(async (item) => {
        if (item.kind === 'file') {
            let entryContent;

            if (typeof item.webkitGetAsEntry === 'function') {
                const entry = item.webkitGetAsEntry();
                entryContent = await readEntryContentAsync(entry);
            } else {
                const file = item.getAsFile();
                if (file) {
                    entryContent = { name: file.name, type: 'file', content: file };
                }
            }

            if (entryContent && entryContent.name !== '.DS_Store') {
                return entryContent;
            }
        }
        return null;
    });

    const entries = await Promise.all(entriesPromises);
    return entries.filter((entry) => entry !== null) as Entry[];
}

function readEntryContentAsync(entry: FileSystemEntry): Promise<Entry> {
    return new Promise<Entry>((resolve, _reject) => {
        if (isFile(entry)) {
            entry.file((file) => {
                if (file.name !== '.DS_Store') {
                    resolve({ name: file.name, type: 'file', content: file });
                } else {
                    resolve(null);
                }
            });
        } else if (isDirectory(entry)) {
            const directory: Entry = { name: entry.name, type: 'directory', children: [] };
            const reader = entry.createReader();

            const readEntries = () => {
                reader.readEntries(function (entries) {
                    if (!entries.length) {
                        resolve(directory);
                    } else {
                        const promises = [];
                        for (const entry of entries) {
                            promises.push(
                                readEntryContentAsync(entry).then((childEntry) => {
                                    if (childEntry !== null) {
                                        directory.children!.push(childEntry);
                                    }
                                }),
                            );
                        }
                        Promise.all(promises).then(readEntries); // Continue reading
                    }
                });
            };

            readEntries();
        }
    });
}

function isDirectory(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
    return entry.isDirectory;
}

function isFile(entry: FileSystemEntry): entry is FileSystemFileEntry {
    return entry.isFile;
}

export const parsePdf = (file: File, eid: string, entity: Entity): void => {
    const formData = new FormData();
    formData.append(eid, file, eid);
    formData.append('entity', JSON.stringify(entity));
    fetch('/api/parsePdf', {
        method: 'POST',
        body: formData,
    }).catch((e) => {
        console.log('Error in parsePdfs', e);
    });
};

export function addToCustomerio(id: string, newUser: Entity, created_at: string): void {
    const body = JSON.stringify({ id, newUser, created_at });
    fetch(`/api/addToCustomerio`, {
        method: 'POST',
        body: body,
    }).catch((e) => {
        console.log('Error in addToCustomerio', e);
    });
}

export function parsePdfs(files, entities): void {
    files.map(async (file, i) => {
        if (file.type === 'application/pdf') {
            parsePdf(file, entities[i].id, entities[i]);
        }
    });
}

export function removeDuplicates(array): Array<string> {
    return Array.from(new Set(array));
}

export function instancesAreEqual(ref1: EntityIdentity, ref2: EntityIdentity): boolean {
    if (ref1 && ref2) {
        return ref1.id === ref2.id && ref1.parentId === ref2.parentId;
        // return (
        //     (ref1.eid === ref2.eid && ref1.parentId === ref2.parentId) ||
        //     (ref1.id === ref2.id && ref1.parentId === ref2.parentId)
        // );
    } else {
        return null;
    }
}

export function includesInstance(instances: EntityIdentity[], ref: EntityIdentity): boolean {
    return Boolean(find(instances, ref));
}

export function pushEidToEntity(entity: Entity, eid: string, index?: number): Entity {
    const pushOrSplice = (list: string[], eid: string, index?: number): string[] => {
        const newList = list?.length ? list.slice() : [];
        if (index || index === 0) {
            newList.splice(index + 1, 0, eid);
        } else {
            newList.push(eid);
        }
        return newList;
    };

    entity.childrenList = pushOrSplice(entity.childrenList, eid, index);
    if (entity['@type'] === 'Page') {
        entity.contentList = pushOrSplice(entity.contentList, eid, index);
    }
    return entity;
}

export const getChildrenLists = (entity: Entity | EntityChildren): EntityChildren => {
    const lists = { childrenList: entity.childrenList || [] };
    if (entity.contentList || (entity['@type'] && entity['@type'] === 'Page')) {
        lists['contentList'] = entity.contentList || [];
    }
    return lists;
};

export const getAdditionalTypeFieldsFromBlob = (
    additionalTypeEntity: Entity,
    blobEntity: Entity,
): Array<ObjectField> => {
    const typeFields = additionalTypeEntity.fields; // array
    const blobTypedFields = blobEntity.typedFields; // object
    const typeId = additionalTypeEntity.id;

    const emptyByType = {
        string: '',
        date: '',
        number: '',
        boolean: false,
        collection: [],
    };

    if (blobTypedFields?.[typeId]) {
        // return fields for saved Blob Type
        const fields = typeFields?.map((field) => {
            const newField = Object.assign({}, field);
            newField.value = blobTypedFields[typeId][field.id] || emptyByType[field.fieldType];
            return newField;
        });
        return fields;
    } else {
        // return starting empty field for a new Blob without Type
        const fields = typeFields?.map((field) => {
            const newField = Object.assign({}, field);
            newField.value = emptyByType[field.fieldType];
            return newField;
        });
        return fields;
    }
};

export const prepareNewDataspaceAndAssociatedEntities = (action) => {
    const userId = action.payload?.user.id;
    const creator = action.payload?.user; //dataspace creator & owner
    const user = cloneEntity(creator);
    const dataspaceId = uuidv4();
    const dataspaceEntity = newEntity('Dataspace');
    dataspaceEntity.members = { [userId]: 'owner' };
    dataspaceEntity.name = action.payload?.name;
    const finderEntity = newEntity('Finder');
    const userEntity = user.entity;
    if (userEntity.memberships) userEntity.memberships[dataspaceId] = 'owner';
    else userEntity.memberships = { [dataspaceId]: 'owner' };

    action.payload.nextQueryVariables = {
        dataspaceId,
        dataspaceEntity,
        finderEntity,
        userId,
        userEntity,
    };

    return action;
};

export const prepareCompletedUserAndAssociatedEntities = (action, user) => {
    // Create new user, and new user's Dataspace, Finder.
    const welcomeEntities = prepareWelcomeEntities();
    const userId = user.id;
    const dataspaceId = uuidv4();
    const dataspaceEntity = newEntity('Dataspace');
    dataspaceEntity.members = { [userId]: 'owner' };
    dataspaceEntity.name = action.payload?.dataspaceName || 'My Dataspace';
    const finderEntity = newEntity('Finder');
    finderEntity.childrenList = [welcomeEntities?.id4];
    const userEntity = cloneEntity(user.entity);
    const parsedNameFromEmail = userEntity.email?.split('@')[0] || action.payload?.email?.split('@')[0];
    userEntity.name = action.payload?.name || userEntity.name || parsedNameFromEmail;
    userEntity.avatarUrl = action.payload?.avatarUrl;
    userEntity.memberships = { ...userEntity?.memberships, [dataspaceId]: 'owner' };
    userEntity.complete = true;
    userEntity.firstLoad = {
        sonar: true,
        firstIdToBrowse: welcomeEntities?.id1,
        crisp: true,
    };
    if (action.payload.inviteDataspaceId) userEntity.memberships[action.payload.inviteDataspaceId] = 'member';
    userEntity.memberships = omit(userEntity.memberships, [SHARED_DATASPACE_ID, DRAFTS_DATASPACE_ID]);
    action.payload.nextQueryVariables = {
        dataspaceId,
        dataspaceEntity,
        finderEntity,
        userId,
        userEntity,
        ...welcomeEntities,
    };
    return action;
};

export const prepareNewUser = (action) => {
    // Create new user without associated entities like Dataspace.
    let userEntity = newEntity('Person');
    userEntity.name = action.payload?.name;
    userEntity.email = action.payload?.email;
    userEntity.memberships = {};
    userEntity.complete = false;
    userEntity.waitlist = true;
    if (action.payload.galacticaInterest) userEntity.galacticaInterest = true;
    if (action.payload.inviteDataspaceId) userEntity.memberships[action.payload.inviteDataspaceId] = 'member';
    if (action.payload.options) userEntity = Object.assign({}, userEntity, action.payload.options);
    action.payload.nextQueryVariables = {
        userEntity,
    };

    return action;
};

export function simpleTextMatch(name: string, searchText: string) {
    if (!name || !searchText) return false;
    return name.toLowerCase().includes(searchText.toLowerCase());
}

function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export const boldMatchingText = (text, shouldBeBold) => {
    const safeRegex = escapeRegExp(shouldBeBold);
    text = text.replace(/(\r\n|\n|\r)+/gm, ' '); //Ignore newline chars. Should probably be done before name is saved
    const textArray = parse(text.replace(new RegExp(safeRegex, 'gi'), (match) => `<mark>${match}</mark>`)) as
        | JSX.Element[]
        | JSX.Element;
    return textArray;
};

export function getSnippets(searchStr, str, snippetRadius) {
    searchStr = searchStr.toLowerCase();
    const lowercaseStr = str.toLowerCase();
    const searchStrLen = searchStr.length;
    if (searchStrLen == 0) {
        return [];
    }
    let startIndex = 0,
        index;
    const snippets = [];
    let snippetIndex = 0;
    while ((index = lowercaseStr.indexOf(searchStr, startIndex)) > -1) {
        const start = index - snippetRadius;
        const end = index + searchStrLen + snippetRadius;
        const startEllipsis = start > 0 ? '...' : '';
        const endEllipsis = lowercaseStr.length - end > 1 ? '...' : '';
        if (snippetIndex === 0 || start > snippets[snippetIndex - 1]?.end) {
            snippets.push({
                start: start,
                end: end,
                text: startEllipsis + str.substring(start, end) + endEllipsis,
            });
            snippetIndex++;
        } else if (snippetIndex > 0 && start <= snippets[snippetIndex - 1]?.end) {
            const prevSnippet = snippets[snippetIndex - 1];
            const prevStartEllipsis = snippets[snippetIndex - 1].start > 0 ? '...' : '';
            snippets[snippetIndex - 1].end = end;
            snippets[snippetIndex - 1].text = prevStartEllipsis + str.substring(prevSnippet.start, end) + endEllipsis;
        } else {
        }
        startIndex = index + searchStrLen;
    }
    return snippets;
}

export function getTextWithMatches(text, query) {
    if (!(typeof text === 'string')) return [];
    if (!query) return text.substring(0, 100);
    const regex = /(<([^>]+)>)/gi;
    const res = (text || '').replace(regex, '');

    // Change to change size of snippet around query match
    const snippetRadius = 100;
    const snippets = getSnippets(query, res, snippetRadius);
    return snippets.map((snippet, i) => {
        return (
            <div key={i}>
                {parse(snippet.text.replace(new RegExp(query, 'gi'), (match) => `<mark>${match}</mark>`))}
            </div>
        );
    });
}

export const addEidsToChildrenListAndClone = (entity: Entity, childrenEids: string[], index?: number): Entity => {
    const newEntity: Entity = cloneEntity(entity);
    const offset = index === undefined || index === -1 ? entity?.childrenList?.length || 0 : index;
    if (newEntity.childrenList) {
        newEntity.childrenList.splice(offset, 0, ...childrenEids);
    } else {
        newEntity['childrenList'] = childrenEids;
    }
    return newEntity;
};

type EntityWithId = { id: string; entity: Entity };

export const cloneParentsAndRemoveChildrenEids = (
    selectedEntities: EntityIdentity[],
    parentEntitiesWithId: RawEntity[],
): RawEntity[] => {
    const parentChildrenMap = selectedEntities.reduce((acc, entity) => {
        if (!acc[entity.parentId]) acc[entity.parentId] = [];

        acc[entity.parentId].push(entity.id);

        return acc;
    }, {});

    return parentEntitiesWithId.map((parentEntity) => {
        const newParentEntity = cloneEntity(parentEntity.entity);
        parentChildrenMap[parentEntity.id].forEach((targetChild) => {
            pull(newParentEntity['childrenList'], targetChild);
        });
        return { ...parentEntity, entity: newParentEntity };
    });
};

export const buildEntityUpdatePairs = (entitiesWithId: EntityWithId[]) => {
    return entitiesWithId.map(({ id, entity }) => {
        return { where: { id: { _eq: id } }, _set: { entity } };
    });
};

export const buildChildrenListUpdatePairs = (condensedEntities: CondensedEntity[]) => {
    return condensedEntities.map((entity) => {
        return {
            where: { id: { _eq: entity.metadata.id } },
            _append: { entity: { childrenList: entity.childrenList } },
        };
    });
};

type CondensedEntityBuildConfig = {
    entityType: string;
    id: string;
    owner: string;
    dsid: string;
    childrenList: string[];
    name?: string;
};

export const buildCondensedEntity = ({
    entityType,
    id,
    owner,
    dsid,
    childrenList,
    name,
}: CondensedEntityBuildConfig): CondensedEntity => {
    return {
        ...typeInfo[entityType].skeleton,
        deleted: false,
        childrenList: childrenList,
        name: name || '',
        metadata: {
            id,
            owned_by: owner,
            dsid,
        },
    };
};

export const buildEntity = (entityType: string, id: string, owner: string, dsid: string, childrenList: string[]) => {
    const skeleton = genericEntitySkeleton();
    return {
        id,
        owned_by: owner,
        dsid,
        entity: {
            ...cloneEntity(skeleton),
            ...typeInfo[entityType].skeleton,
            childrenList,
        } as Entity,
    } as RawEntity;
};

export function replaceChildId(entity: CondensedEntity, childIdToReplace: string, replacementChildId: string) {
    const index = entity.childrenList.indexOf(childIdToReplace);
    entity.childrenList[index] = replacementChildId;
    return entity;
}

export function removeChildrenIdFromParents(parent: CondensedEntity, idsToRemove: string[]) {
    parent.childrenList = parent.childrenList.filter((childId) => !idsToRemove.includes(childId));
    return parent;
}

const colors = [
    '#e78ef7',
    '#ca3aa6',
    '#a42dff',
    '#657bff',
    '#009CDF',
    '#027c85',
    '#00b0a4',
    '#00d7bc',
    '#e2ce19',
    '#e9a170',
    '#bc5c3d',
];
export const getUserColor = (id: string): string => {
    const charCode = id.length > 10 ? id.charCodeAt(10) : 0;
    return colors[charCode % 11];
};

export const filterPeopleOut = (entities) => {
    return Object.keys(entities)
        .map((eid) => entities[eid])
        .filter((e) => e['@type'] !== 'Person');
};

export function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        atob(base64)
            .split('')
            .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
    );

    return JSON.parse(jsonPayload);
}

export const normalizeDriveFiles = (files, name, email) => {
    const rootUid = buildUID({ fid: 'root', app: 'drive', appId: email });
    const normalized: any = { [rootUid]: { '@type': 'IntegrationItem', name, childrenList: [] } };

    // fill "normalized" object
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uid = buildUID({ fid: file.id, app: 'drive', appId: email });
        normalized[uid] = { ...file, '@type': 'IntegrationItem', id: uid, foreignId: file.id };
    }

    // fill normalized.root list
    for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const uid = buildUID({ fid: file.id, app: 'drive', appId: email });

        if (!file?.parents) {
            normalized[rootUid].childrenList.push(uid);
        } else {
            for (let j = 0; j < file.parents.length; j++) {
                const parentId = file.parents[j];
                const compoundParentUid = buildUID({ fid: parentId, app: 'drive', appId: email });

                if (normalized[compoundParentUid]) {
                    normalized[compoundParentUid] = {
                        ...(normalized[compoundParentUid] || {}),
                        childrenList: [...(normalized[compoundParentUid].childrenList || []), uid],
                    };
                } else {
                    normalized[rootUid].childrenList.push(uid);
                }
            }
        }
    }

    return normalized;
};

export const normalizeFigmaFiles = (dirs, name, prevObject: any = {}, email: string) => {
    const rootUid = buildUID({ fid: 'root', app: 'figma', appId: email });
    const nameUid = buildUID({ fid: name, app: 'figma', appId: email });
    const normalized: any = {
        ...prevObject,
        [rootUid]: prevObject[rootUid] || {
            '@type': 'IntegrationItem',
            name: 'All Teams',
            childrenList: [],
            id: rootUid,
        },
        [nameUid]: { '@type': 'IntegrationItem', name, id: nameUid, childrenList: [] },
    };

    dirs.forEach((dir) => {
        const dirUid = buildUID({ fid: dir.id, app: 'figma', appId: email });
        normalized[dirUid] = {
            ...dir,
            id: dirUid,
            childrenList: dir.files.map((file) => buildUID({ fid: file.key, app: 'figma', appId: email })),
            parents: [],
            iconLink: dir.thumbnail_url,
            '@type': 'IntegrationItem',
        };

        normalized[nameUid].childrenList.push(dirUid);

        dir.files.forEach((file) => {
            const id = buildUID({ fid: file.key, app: 'figma', appId: email });
            normalized[id] = {
                ...file,
                childrenList: [],
                parents: [dirUid],
                iconLink: file.thumbnail_url,
                '@type': 'IntegrationItem',
                id,
            };
        });
    });

    normalized[rootUid].childrenList.push(nameUid);

    return normalized;
};

export const pingUrl = (url: string): Promise<boolean> => {
    const formData = new FormData();
    formData.append('url', url);
    return axios
        .post('/api/pingUrl', formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((res) => res.data);
};

export const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const abbreviate = (text: string, maxLength: number) => {
    if (text.length < maxLength) return text;
    else return `${text.substring(0, maxLength)}...`;
};

export const buildBreadcrumb = (dataspace, entities, pageName?, isDraft?) => {
    const pathSteps = [];
    if (dataspace?.name) pathSteps.push(dataspace?.name);
    if (isDraft || pageName === 'drafts') pathSteps.push('Drafts');
    if (pageName && !['base', 'drafts', 'entity', 'workspace', 'notes', 'objects'].includes(pageName))
        pathSteps.push(pageName.charAt(0).toUpperCase() + pageName.slice(1));
    else if (every(entities, (e) => e.name || e['@type'])) {
        pathSteps.push(
            ...entities.map((e) => {
                if (e.name) return e.name;
                return `Untitled ${typeInfo[e['@type']]?.fancyName}`;
            }),
        );
    }

    return pathSteps;
};

export const abbreviateBreadcrumb = (path: string, abbreviateStartLength = 20, abbreviateEndLength = 20) => {
    if (path.length < abbreviateStartLength + abbreviateEndLength) return path;

    const sep = '/';
    const pathSteps = path.split(sep).map((step) => step.trim());
    if (pathSteps.length === 1) return path;

    let startStep = pathSteps.shift();
    let endStep = pathSteps.pop();
    const startStepOverflow = startStep.length > abbreviateStartLength;
    const endStepOverflow = endStep.length > abbreviateEndLength;
    if (startStepOverflow) startStep = startStep.slice(0, abbreviateStartLength) + '...';
    if (endStepOverflow) endStep = endStep.slice(0, abbreviateEndLength) + '...';

    if (pathSteps.length === 0) return `${startStep} / ${endStep}`;
    else if (startStepOverflow && endStepOverflow) return `${startStep} / ... / ${endStep}`;

    if (!startStepOverflow) {
        const startSlice = path.slice(0, abbreviateStartLength);
        const startTextEndIndex = startSlice.includes(sep) ? startSlice.lastIndexOf(sep) : abbreviateStartLength;
        startStep = path.slice(0, startTextEndIndex);
    }

    if (!endStepOverflow) {
        const endSlice = path.slice(-abbreviateEndLength);
        const endTextStartIndexOffset = endSlice.includes(sep) ? endSlice.indexOf(sep) + sep.length : 0;
        endStep = path.slice(-abbreviateEndLength + endTextStartIndexOffset);
    }

    return `${startStep} / ... / ${endStep}`;
};

export const getStateFromLocalStorage = (stateName: string, initialState) => {
    if (typeof window !== 'undefined' && window?.localStorage?.getItem(stateName)) {
        const state = window?.localStorage?.getItem(stateName) || '{}';
        return Object.assign({}, initialState, JSON.parse(state));
    } else {
        return initialState;
    }
};

export const setStateToLocalStorage = (stateName: string, newState) => {
    if (typeof window !== 'undefined') window.localStorage.setItem(stateName, JSON.stringify(newState));
};

export const isImage = (url: string) => url?.match(/.(jpg|jpeg|png|gif)$/i);
export const isVideo = (url: string) => url?.match(/.(webm|mkv|flv|avi|mov|wmv|mpeg|m4v|mp4)$/i);
export const isPdf = (url: string) => url?.match(/.pdf/i);

export const checkPresetEnabled = (action: ReduxAction) => {
    return (
        (action.payload.callback && action.payload.fallback) || (!action.payload.callback && !action.payload.fallback)
    );
};

export const findNonChildEntities = (entities) => {
    const childIds = entities.flatMap((entity) => entity.childrenList || []);
    return entities.filter((entity) => !childIds.includes(entity.id));
};

export const getAllDescendantIds = (entities: Entity[], allDataspaceEntities: Entity[]) => {
    let ids = [];

    const addIdAndChildIds = (eid: string): string[] => {
        const entity = allDataspaceEntities[eid];
        let childIds = [];
        if (entity?.childrenList?.length > 0) {
            entity.childrenList.forEach((childId) => {
                if (childId) childIds = childIds.concat(addIdAndChildIds(childId));
            });
        }
        if (eid) childIds.push(eid);
        return childIds;
    };

    entities.forEach((e) => {
        ids = ids.concat(addIdAndChildIds(e.metadata?.id));
    });
    return ids;
};

// when richtext is just created and nothing is typed content will be empty string
// if user types something, and deletes it, then content will probably be "<paragraph></paragraph>"
export const isRichTextBlank = (entity) => entity?.['@type'] === 'RichText' && entity?.content?.length === 0;

export const getEntityLink = (url: string): string => {
    if (typeof url !== 'string') return null;
    if (!(url?.length > 0)) return null;

    const baseUrl = `${BASE_ORIGIN}/entity/`;
    if (!url.startsWith(baseUrl)) return null;

    const cleanUrl = url.split('?')?.[0];
    return cleanUrl;
};

export const getEidFromUri = (uri: string) => {
    const parts = uri?.split('-');

    if (parts?.length <= 4) return uri;
    return parts?.slice(-5).join('-');
};

export const getHumanReadableUri = (name: string, eid: string) => {
    const maxLength = 40;

    if (!name) return eid;
    const cleanName = name
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '') // Remove accents
        .replace(/([^\w]+|\s+)/g, '-') // Replace space and other characters by hyphen
        .replace(/\-\-+/g, '-') // Replaces multiple hyphens by one hyphen
        .replace(/(^-+|-+$)/g, ''); // Remove extra hyphens from beginning or end of the string
    const parts = cleanName.split('-');
    const nameParts = [parts[0]];
    for (
        let len = parts[0].length, i = 1;
        i < parts.length && len + parts[i].length < maxLength;
        len += parts[i++].length
    ) {
        nameParts.push(parts[i]);
    }

    if (nameParts.length === 1) return nameParts[0].slice(0, maxLength) + '-' + eid;

    return nameParts.join('-') + '-' + eid;
};

export const delay = (time: number): Promise<unknown> => new Promise((resolve) => setTimeout(resolve, time));

export function getElementByXpath(path: string): HTMLElement {
    return document.evaluate(path, document, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null)
        .singleNodeValue as HTMLElement;
}

export const buildFeedbackBody = (feedback, user) => {
    return {
        username: 'Feedback Bot',
        blocks: [
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: `<!here> \nNew feedback from: \n\`${user.name || 'New User'}\` \n${user.email || ''}`,
                },
            },
            {
                type: 'divider',
            },
            {
                type: 'section',
                text: {
                    type: 'mrkdwn',
                    text: feedback.text,
                },
            },
            ...(feedback.attachments?.length > 0
                ? feedback.attachments.map((attachment) => {
                      return {
                          type: 'image',
                          image_url: attachment.url,
                          alt_text: 'image',
                      };
                  })
                : []),
        ],
    };
};

export function membersToFullAcl(members: Record<string, string>): Record<string, string[]> {
    const acl = {};
    for (const userId in members) {
        acl[userId] = ['read', 'write', 'delete', 'share'];
    }
    return acl;
}

export function purifyEntity(entity: Entity): Entity {
    delete entity.metadata;
    delete entity.entity?.metadata;
    delete entity.entity?.id;

    return entity;
}

export const getShortcutLabels = () => {
    const isMac = navigator.platform.toUpperCase().indexOf('MAC') !== -1;

    const ctrlOrCommandButton = isMac ? '⌘' : 'Ctrl';

    return {
        Add: `${ctrlOrCommandButton} ⇧ Enter`,
        Copy: `${ctrlOrCommandButton} C`,
        Paste: `${ctrlOrCommandButton} V`,
        Group: `${ctrlOrCommandButton} G`,
        CopyLink: isElectron() ? `${ctrlOrCommandButton} L` : '',
        OpenInNewTab: `${ctrlOrCommandButton} Enter`,
        Delete: `${ctrlOrCommandButton} Delete`,
        AddInScoped: `${ctrlOrCommandButton} Enter`,
        CreateNote: `${ctrlOrCommandButton} N`,
        ToggleShowHlist: `${ctrlOrCommandButton} /`,
        Omnibox: `${ctrlOrCommandButton} K`,
    };
};

export const extractParentEntitiesFromRawEntityArray = (entityList: RawEntity[]): RawEntity[] => {
    const { childrenIds, ids, parentEntities } = entityList.reduce(
        (acc, entry) => ({
            childrenIds: [...acc.childrenIds, ...(entry.entity?.childrenList || [])],
            ids: [...acc.ids, entry.id],
            parentEntities: { ...acc.parentEntities, [entry.id]: entry },
        }),
        { childrenIds: [], ids: [], parentEntities: {} },
    );
    return ids.filter((id) => !childrenIds.includes(id)).map((id) => parentEntities[id]);
};

export const isURL = (uri: string): boolean => {
    return uri?.split(' ')?.length === 1 && uri?.includes('.') && uri[0] !== '.' && uri[uri.length - 1] !== '.';
};

export function getNameFromContent(content: string) {
    const NAME_LENGTH = 100;
    let contentAsText = getFirstTagText(content);
    if (contentAsText.replaceAll(' ', '').length === 0) {
        contentAsText = getTextFromRange(content, NAME_LENGTH * 10);
    }
    return getFirstSentenceOrRange(contentAsText, NAME_LENGTH);
}

const getFirstTagText = (content: string) => content?.substring(0, content?.indexOf('</')).replace(/<[^>]*(>|$)/g, ' ');
const getTextFromRange = (content: string, range: number) => content?.substring(0, range).replace(/<[^>]*(>|$)/g, ' ');
const getFirstSentenceOrRange = (content: string, range: number) => {
    const punctuationMarks = /[.?!]/;
    const punctuationMarksIndex = content.search(punctuationMarks);
    return content.substring(0, punctuationMarksIndex + 1 || range);
};

export const Emoji = dynamic(() => import('emoji-mart').then((mod) => mod.Emoji), { ssr: false });

// Fields that can determine entity icon:
// emoji, @type, url, thumbnailUrl, app, iconLink, mimeType, emptyTab
export const getEntityIcon = (
    entity: Entity,
    params?: {
        isIntegrationItem?: boolean;
        emptyTab?: boolean;
        command?: string;
        isOpen?: boolean;
        isAppsFolder?: boolean;
    },
): string | ReactElement => {
    const entityType = entity?.['@type'];

    if (params?.isAppsFolder) {
        return <HomeIcon />;
    }

    if (entityType === 'App') {
        const app = getAppDataByName(entity?.name);
        return <AppEntityIcon app={app} />;
    }

    let chiplet = typeInfo[entityType]?.icon;

    if (entity?.emoji) {
        return <Emoji emoji={entity?.emoji.id} size={16} sheetSize={64} skin={entity?.emoji.skin} />;
    }

    if (entityType === 'HList' && params?.isOpen) {
        chiplet = <FolderIcon opened />;
    }

    if (entityType === 'DigitalDocument') {
        if (isImage(entity?.url)) {
            if (entity.thumbnailUrl) {
                return <Thumbnail thumbnailUrl={entity?.thumbnailUrl} />;
            } else chiplet = <ImageIcon />;
        } else if (isVideo(entity?.url)) {
            return <VideoMdIcon />;
        } else if (isPdf(entity?.url)) {
            return <PDFIcon />;
        }
    }

    if (entityType === 'Webwindow') {
        return getFavicon(entity?.url);
    }

    if (entityType === 'IntegrationItem' || params?.isIntegrationItem) {
        const integrationApp = parseUID(entity?.id).app || entity?.app;
        return INTEGRATIONS[integrationApp]?.getEntityIcon(entity, { isOpen: params?.isOpen });
    }

    if (params?.emptyTab) {
        return <BedrockInvertBlackIcon />;
    }

    // search commands
    if (params?.command === 'SearchGoogle') {
        return <GoogleIcon />;
    } else if (params?.command === 'SearchBedrock') {
        return <BedrockIcon />;
    }

    return chiplet;
};

export const shortenString = (str: string, length: number) => {
    if (str.length <= length) return str;
    const firstHalf = str.slice(0, length / 2 - 1);
    const secondHalf = str.slice(str.length - length / 2 - 1);
    return `${firstHalf}...${secondHalf}`;
};

export const isThumbnailImage = (filename: string): boolean => {
    const thumbnailImageRegExp = /^thumbnail_[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}\.jpeg$/;
    return thumbnailImageRegExp.test(filename);
};

export const getBedrockFavicon = (filename: string, extension: string): string => {
    if (NODE_ENV === 'development') return `${BASE_ORIGIN}/${filename}.dev.${extension}`;
    else return `${BASE_ORIGIN}/${filename}.${extension}`;
};

export const getAllDescendantEids = (condensedEntities: CondensedEntityMap, eids: string[]): string[] => {
    const childEids: string[] = [];

    const fillChildrenList = (ids: string[]) => {
        ids.forEach((id) => {
            if (condensedEntities[id]) {
                childEids.push(id);
                if (condensedEntities[id]?.childrenList?.length) {
                    const childrenEntities = condensedEntities[id].childrenList;
                    fillChildrenList(childrenEntities);
                }
            }
        });
    };

    fillChildrenList(eids);
    return childEids;
};

export const removeChildrenFromParentChildrenList = (
    childrenEntities: EntityIdentity[],
    parentEntities: RawEntity[],
): EntityWithId[] => {
    const removedChildrenMap = childrenEntities.reduce((acc, { id, parentId }) => {
        if (!acc[parentId]) {
            acc[parentId] = [];
        }
        acc[parentId].push(id);
        return acc;
    }, {});

    return parentEntities.map((parent) => {
        return {
            id: parent.id,
            entity: {
                ...parent.entity,
                childrenList: parent.entity.childrenList.filter(
                    (childId) => !removedChildrenMap[parent.id].includes(childId),
                ),
            },
        };
    });
};

export const getExpandedItems = (expandedHListItems, integrationItems) => {
    const expandedLocalFileIds = [];
    Object.keys(expandedHListItems).map((id) => {
        if (expandedHListItems[id] && integrationItems?.[id] && id !== 'root') {
            expandedLocalFileIds.push(id);
        }
    });
    return expandedLocalFileIds;
};

export const personOwnsDataspace = (entity: Entity): boolean =>
    Object.values(entity?.memberships || {}).includes('owner');

export const pushToUniqueStack = (stack: string[], item: string | string[]): string[] => {
    const items = Array.isArray(item) ? item : [item];

    return [...new Set([...stack, ...items])];
};

export const buildCondensedEntityListFromUploads = (
    entries: File[] | Entry[],
    userId: string,
    dsid: string,
): { fullList: CondensedEntity[]; topLevelChildren: string[] } => {
    const condensedList = [];
    const topLevelChildren = [];

    const buildEntityAndAddId = (entry) => {
        const entityId = uuidv4();
        entry.id = entityId;

        if (entry instanceof File || entry.type === 'file') {
            condensedList.push(
                buildCondensedEntity({
                    entityType: 'DigitalDocument',
                    id: entityId,
                    owner: userId,
                    dsid,
                    childrenList: null,
                    name: entry.name || 'Untitled',
                }),
            );
        } else if (entry.type === 'directory') {
            const childrenList = entry.children.map((child) => buildEntityAndAddId(child).id);

            condensedList.push(
                buildCondensedEntity({
                    entityType: 'HList',
                    id: entityId,
                    owner: userId,
                    dsid,
                    childrenList,
                    name: entry.name || 'Untitled Folder',
                }),
            );
        }

        return { id: entityId };
    };

    entries.forEach((entry) => {
        const child = buildEntityAndAddId(entry);
        topLevelChildren.push(child.id);
    });

    return { fullList: condensedList, topLevelChildren };
};

export const isEmptyTab = (id: string) => {
    return id?.startsWith('EMPTY_TAB_');
};

export const convertTimestampToLocaleString = (timestamp: string): string => {
    const date = new Date(timestamp);

    return date.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    });
};

export const filterOutSpecialDataspaceIds = (dataspaceIds: string[]): string[] => {
    return filter(dataspaceIds, (id) => ![SHARED_DATASPACE_ID, DRAFTS_DATASPACE_ID].includes(id));
};

export const openSingleNoteWindow = (eid: string): void => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window?.__TAURI__?.event?.emit('open-in-new-window', `${BASE_ORIGIN}/notes/note/${eid}`);
};

export const getAppDataByName = (name: string) => {
    return appsData.flatMap((group) => group.apps).find((app) => app.name === name);
};

export const matchAvailableIntegrationByName = (name: string) => {
    const appData = getAppDataByName(name);
    const integrations = INTEGRATIONS_LIST.map((key) => INTEGRATIONS[key]);
    return integrations.find((integration) => integration.label === appData.name);
};

export const fillAppEntityWithAccounts = (appEntity, integrationState) => {
    const appData = getAppDataByName(appEntity.name);
    appEntity.disabled = true;
    if (!appData?.available) {
        return appEntity;
    }

    const matchedIntegration = matchAvailableIntegrationByName(appEntity.name);
    const integrationType = matchedIntegration?.name;
    const integrationContent = integrationState?.[integrationType];
    if (!integrationContent) {
        return appEntity;
    }

    const addedAccounts = Object.values<any>(integrationContent);
    if (addedAccounts.length === 0) {
        return appEntity;
    }

    appEntity.childrenList = [];
    appEntity.hasChildren = true;
    for (const account in integrationContent) {
        const rootUid = buildUID({ fid: 'root', app: integrationType, appId: account });
        appEntity.childrenList.push(rootUid);
    }
    appEntity.disabled = false;
    return appEntity;
};

export const getIsAppFolderId = (eid: string): boolean => {
    return eid?.includes('_apps');
};

export const filterOmniboxOptionsByValue = (options: Option[]): ((value: string) => Option[]) => {
    return (inputValue: string) => {
        return !inputValue?.length
            ? options
            : options.filter((opt) => opt.value.toLocaleLowerCase()?.includes(inputValue?.toLocaleLowerCase()));
    };
};
