import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createDataspace, setDataspace } from '@/redux/entities/actions';
import 'tippy.js/dist/tippy.css';
import { useRouter } from 'next/router';
import Button from '../../../DesignSystem/Button';
import Text from '../../../DesignSystem/Text';
import styles from '../Dataspace/General.module.scss';
import formStyles from '../../../forms/forms.module.scss';
import dataspaceControlStyles from './DataspaceControl.module.scss';
import { Form as BootstrapForm } from 'react-bootstrap';
import useOutsideClick from '@/hooks/outsideClick';
import { getUserSelector } from '@/redux/user/selectors';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

const CreateDataspacePopover = ({ close }) => {
    const user = useSelector(getUserSelector);
    const router = useRouter();
    const ref = useRef(null);
    const dispatch = useDispatch();
    useOutsideClick(ref, close);

    const Schema = Yup.object().shape({
        name: Yup.string().min(1, 'Too Short').max(50, 'Max 50 characters').required('Please enter a name'),
    });

    const handleSubmit = (values) => {
        dispatch(
            createDataspace({ name: values.name, user }, (newDataspace) => {
                const dsid = newDataspace?.id;
                router.push('/base/' + dsid, `/base/${dsid}`, { shallow: true });
                dispatch(setDataspace(dsid));
                close();
            }),
        );
    };

    return (
        <Formik
            validationSchema={Schema}
            validateOnChange={true}
            enableReinitialize
            onSubmit={(values) => {
                handleSubmit(values);
            }}
            initialValues={{
                name: '',
            }}
        >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form ref={ref} className={dataspaceControlStyles.formContainer} onSubmit={handleSubmit}>
                    <BootstrapForm.Control
                        name="name"
                        value={values.name}
                        isValid={touched.name && !errors.name}
                        isInvalid={!!errors.name}
                        onChange={handleChange}
                        className={formStyles.formControl}
                        type="text"
                        placeholder="New Dataspace Name"
                        autoFocus
                        maxLength={50}
                    />
                    {values.name?.trim().length > 0 && (
                        <div className={dataspaceControlStyles.formSecondRow}>
                            <Button type="submit" size="s" variant="primary">
                                Create
                            </Button>
                            {values.name?.length === 50 && <div className={formStyles.error}>Max 50 characters</div>}
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export const DataspaceControlHeader = () => {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const showForm = useCallback(() => setIsFormVisible(true), []);
    const closeForm = useCallback(() => setIsFormVisible(false), []);
    const positionRelative = useMemo(() => ({ position: 'relative' as any }), []);

    return (
        <div className={styles.dataspaceControlContainer}>
            <div className={styles.dropdownContainer}>
                <Text weight="bold" className={styles.dropdownLabel}>
                    Dataspace
                </Text>
            </div>
            <div style={positionRelative}>
                <Button size="s" variant="secondary" onClick={showForm}>
                    Create New Dataspace
                </Button>
                {isFormVisible && <CreateDataspacePopover close={closeForm} />}
            </div>
        </div>
    );
};
