import React from 'react';
import { useDropzone } from 'react-dropzone';

import { useDispatch, useSelector } from 'react-redux';
import { closeModal, hideGlobalModal, setUI, showGlobalModal } from '../../redux/ui/actions';
import { getUploadEntityPopupParentIdSelector } from '../../redux/ui/selectors';
import { getUserSelector } from '../../redux/user/selectors';

import { useCallback } from 'react';

import Adder from '../wrappers/adder';
import { PopupWrapper, usePopup } from '../wrappers/modal';
import styles from '../sidebar/styles.module.scss';
import { parsePdfs, uploadFiles } from '../../utils/helpers';
import { toggleExpandedHListItems } from '../../redux/ui/actions';
import { bulkCreateEntities } from '../../redux/entities/actions';
import { getDsidSelector } from '@/redux/entities/selectors';

const UploadEntityCore: React.FunctionComponent<FormChildProps> = () => {
    const dispatch = useDispatch();
    const uploadEntityPopupParentId = useSelector(getUploadEntityPopupParentIdSelector);

    const { name } = useSelector(getUserSelector);
    const dsid = useSelector(getDsidSelector);

    const onDrop = useCallback((acceptedFiles) => {
        dispatch(closeModal());
        dispatch(showGlobalModal('UploadProgressModal', { files: acceptedFiles }));
        uploadFiles(acceptedFiles, { author: name }, dsid)
            .then((docEntities) => {
                dispatch(hideGlobalModal());
                dispatch(bulkCreateEntities({ newEntities: docEntities, parentId: uploadEntityPopupParentId }));
                dispatch(toggleExpandedHListItems([uploadEntityPopupParentId], true));
                parsePdfs(acceptedFiles, docEntities);
            })
            .catch(() => dispatch(hideGlobalModal()));
    }, []);
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <p>Drop the files here ...</p>
                ) : (
                    <div>
                        <p>Drag and drop files here.</p>
                        <p>or click to open your system file browser</p>
                    </div>
                )}
            </div>
        </div>
    );
};

type OpenUploadEntityPopup = (entity?: Entity) => void;

export const useUploadEntityPopup = (): OpenUploadEntityPopup => {
    const { open: openPopup } = usePopup();
    const dispatch = useDispatch();

    const openUploadEntityPopup: OpenUploadEntityPopup = (entity) => {
        if (entity) dispatch(setUI({ uploadEntityPopupParentId: entity.id }));
        openPopup('upload-entity-popup');
    };
    return openUploadEntityPopup;
};

const UploadEntityPopup: React.FunctionComponent = () => {
    return (
        <PopupWrapper text={'upload-entity-popup'} className={styles.modalButton}>
            <Adder WrappedComponent={UploadEntityCore} entity={{ '@type': 'DigitalDocument', draft: true }} />
        </PopupWrapper>
    );
};

export default UploadEntityPopup;
