// -- Entities --
export const SET_ENTITIES = 'SET_ENTITIES';
export const SET_ENTITIES_SUCCESS = 'SET_ENTITIES_SUCCESS';
export const SET_ENTITIES_FAILURE = 'SET_ENTITIES_FAILURE';
export const SET_ENTITIES_STATE = 'SET_ENTITIES_STATE';

export const PRESET_ENTITIES = 'PRESET_ENTITIES';
export const UNDO_PRESET_ENTITIES = 'UNDO_PRESET_ENTITIES';

export const PRESET_CONDENSED_ENTITIES = 'PRESET_CONDENSED_ENTITIES';
export const UNDO_PRESET_CONDENSED_ENTITIES = 'UNDO_PRESET_CONDENSED_ENTITIES';
export const INSERT_ENTITY = 'INSERT_ENTITY';

export const CREATE_ENTITY = 'CREATE_ENTITY';
export const CREATE_ENTITY_SUCCESS = 'CREATE_ENTITY_SUCCESS';
export const CREATE_ENTITY_FAILURE = 'CREATE_ENTITY_FAILURE';

export const CREATE_DATASPACE = 'CREATE_DATASPACE';
export const CREATE_DATASPACE_SUCCESS = 'CREATE_DATASPACE_SUCCESS';
export const CREATE_DATASPACE_FAILURE = 'CREATE_DATASPACE_FAILURE';

export const CREATE_ENTITY_AND_CHILD = 'CREATE_ENTITY_AND_CHILD';
export const CREATE_ENTITY_AND_UPDATE_PARENT = 'CREATE_ENTITY_AND_UPDATE_PARENT';
export const CREATE_ENTITY_AND_CHILD_AND_UPDATE_PARENT = 'CREATE_ENTITY_AND_CHILD_AND_UPDATE_PARENT';

export const GET_ENTITY = 'GET_ENTITY';
export const GET_ENTITY_SUCCESS = 'GET_ENTITY_SUCCESS';
export const GET_ENTITY_FAILURE = 'GET_ENTITY_FAILURE';

export const GET_PUBLIC_ENTITY = 'GET_PUBLIC_ENTITY';

export const GET_ENTITIES = 'GET_ENTITIES';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const GET_ENTITIES_FAILURE = 'GET_ENTITIES_FAILURE';

export const SOFT_DELETE_ENTITIES = 'SOFT_DELETE_ENTITIES';

export const RESTORE_ENTITIES_FROM_TRASH = 'RESTORE_ENTITIES_FROM_TRASH';
export const RESTORE_ENTITIES_FROM_TRASH_SUCCESS = 'RESTORE_ENTITIES_FROM_TRASH_SUCCESS';
export const RESTORE_ENTITIES_FROM_TRASH_FAILURE = 'RESTORE_ENTITIES_FROM_TRASH_FAILURE';

export const INITIAL_LOAD = 'INITIAL_LOAD';
export const INITIAL_LOAD_SUCCESS = 'INITIAL_LOAD_SUCCESS';
export const INITIAL_LOAD_FAILURE = 'INITIAL_LOAD_FAILURE';

export const UPDATE_ENTITY = 'UPDATE_ENTITY';
export const UPDATE_ENTITY_SUCCESS = 'UPDATE_ENTITY_SUCCESS';
export const UPDATE_ENTITY_FAILURE = 'UPDATE_ENTITY_FAILURE';

export const UPDATE_ENTITIES_PUBLIC_STATE = 'UPDATE_ENTITIES_PUBLIC_STATE';
export const UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS = 'UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS';
export const UPDATE_ENTITIES_PUBLIC_STATE_FAILURE = 'UPDATE_ENTITIES_PUBLIC_STATE_FAILURE';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const HARD_DELETE_ENTITIES = 'HARD_DELETE_ENTITIES';
export const HARD_DELETE_ENTITIES_SUCCESS = 'HARD_DELETE_ENTITIES_SUCCESS';
export const HARD_DELETE_ENTITIES_FAILURE = 'HARD_DELETE_ENTITIES_FAILURE';

export const EMPTY_TRASH = 'EMPTY_TRASH';
export const EMPTY_TRASH_FAILURE = 'EMPTY_TRASH_FAILURE';

export const ADD_MEMBERS = 'ADD_MEMBERS';
export const ADD_MEMBERS_SUCCESS = 'ADD_MEMBERS_SUCCESS';
export const ADD_MEMBERS_FAILURE = 'ADD_MEMBERS_FAILURE';

export const GET_ALL_COLLABORATORS = 'GET_ALL_COLLABORATORS';
export const GET_ALL_COLLABORATORS_SUCCESS = 'GET_ALL_COLLABORATORS_SUCCESS';
export const GET_ALL_COLLABORATORS_FAILURE = 'GET_ALL_COLLABORATORS_FAILURE';

export const GET_RECENT_ENTITIES = 'GET_RECENT_ENTITIES';
export const GET_RECENT_ENTITIES_SUCCESS = 'GET_RECENT_ENTITIES_SUCCESS';
export const GET_RECENT_ENTITIES_FAILURE = 'GET_RECENT_ENTITIES_FAILURE';

export const REMOVE_CHILD_EID = 'REMOVE_CHILD_EID';
export const REMOVE_CHILD_EID_SUCCESS = 'REMOVE_CHILD_EID_SUCCESS';
export const REMOVE_CHILD_EID_FAILURE = 'REMOVE_CHILD_EID_FAILURE';

export const CREATE_SCHEMA_FROM_OBJECT = 'CREATE_SCHEMA_FROM_OBJECT';
export const CREATE_SCHEMA_FROM_OBJECT_SUCCESS = 'CREATE_SCHEMA_FROM_OBJECT_SUCCESS';
export const CREATE_SCHEMA_FROM_OBJECT_FAILURE = 'CREATE_SCHEMA_FROM_OBJECT_FAILURE';

export const SAVE_DRAFTS = 'SAVE_DRAFTS';
export const SAVE_DRAFTS_SUCCESS = 'SAVE_DRAFTS_SUCCESS';
export const SAVE_DRAFTS_FAILURE = 'SAVE_DRAFTS_FAILURE';

export const SAVE_DRIVE_FILE_TO_DATASPACE = 'SAVE_DRIVE_FILE_TO_DATASPACE';
export const SAVE_DRIVE_FILE_TO_DATASPACE_SUCCESS = 'SAVE_DRIVE_FILE_TO_DATASPACE_SUCCESS';
export const SAVE_DRIVE_FILE_TO_DATASPACE_FAILURE = 'SAVE_DRIVE_FILE_TO_DATASPACE_FAILURE';

export const SET_LOADING_IMAGES_DATA_URL = 'SET_LOADING_IMAGES_DATA_URL';

export const GET_PUBLIC_ENTITY_DESCENDANTS = 'GET_PUBLIC_ENTITY_DESCENDANTS';
export const GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS = 'GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS';
export const GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE = 'GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE';

export const DELETE_ENTITIES_WITHOUT_READ_ACCESS = 'DELETE_ENTITIES_WITHOUT_READ_ACCESS';

export const ADD_APP = 'ADD_APP';
export const ADD_APP_SUCCESS = 'ADD_APP_SUCCESS';
export const ADD_APP_FAILURE = 'ADD_APP_FAILURE';

export const REMOVE_APP = 'REMOVE_APP';
export const REMOVE_APP_SUCCESS = 'REMOVE_APP_SUCCESS';
export const REMOVE_APP_FAILURE = 'REMOVE_APP_FAILURE';

// -- Bulk Entities --
export const BULK_INSERT_ENTITIES = 'BULK_INSERT_ENTITIES';

export const BULK_CREATE_ENTITIES = 'BULK_CREATE_ENTITIES';
export const BULK_CREATE_ENTITIES_SUCCESS = 'BULK_CREATE_ENTITIES_SUCCESS';
export const BULK_CREATE_ENTITIES_FAILURE = 'BULK_CREATE_ENTITIES_FAILURE';

export const BULK_UPDATE_ENTITIES = 'BULK_UPDATE_ENTITIES';
export const BULK_UPDATE_ENTITIES_SUCCESS = 'BULK_UPDATE_ENTITIES_SUCCESS';
export const BULK_UPDATE_ENTITIES_FAILURE = 'BULK_UPDATE_ENTITIES_FAILURE';

export const BULK_MOVE_ENTITIES = 'BULK_MOVE_ENTITIES';
export const BULK_MOVE_ENTITIES_SUCCESS = 'BULK_MOVE_ENTITIES_SUCCESS';
export const BULK_MOVE_ENTITIES_FAILURE = 'BULK_MOVE_ENTITIES_FAILURE';

export const BULK_GROUP_ENTITIES = 'BULK_GROUP_ENTITIES';
export const BULK_GROUP_ENTITIES_SUCCESS = 'BULK_GROUP_ENTITIES_SUCCESS';
export const BULK_GROUP_ENTITIES_FAILURE = 'BULK_GROUP_ENTITIES_FAILURE';

export const UNDO_BULK_REMOVE_CHILD_EIDS = 'UNDO_BULK_REMOVE_CHILD_EIDS';
export const BULK_REMOVE_CHILD_EIDS = 'BULK_REMOVE_CHILD_EIDS';
export const BULK_REMOVE_CHILD_EIDS_SUCCESS = 'BULK_REMOVE_CHILD_EIDS_SUCCESS';
export const BULK_REMOVE_CHILD_EIDS_FAILURE = 'BULK_REMOVE_CHILD_EIDS_FAILURE';

// -- User --
export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const SET_ANONYMOUS_USER = 'SET_ANONYMOUS_USER';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';

export const COMPLETE_USER_SIGNUP = 'COMPLETE_USER_SIGNUP';
export const COMPLETE_USER_SIGNUP_SUCCESS = 'COMPLETE_USER_SIGNUP_SUCCESS';
export const COMPLETE_USER_SIGNUP_FAILURE = 'COMPLETE_USER_SIGNUP_FAILURE';

export const DISMISS_PROMPT = 'DISMISS_PROMPT';

// -- UI --
export const SET_UI = 'SET_UI';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const SET_SELECTED_ENTITIES = 'SET_SELECTED_ENTITIES';
export const CLEAR_SELECTED_ENTITIES = 'CLEAR_SELECTED_ENTITIES';
export const SET_BROWSED = 'SET_BROWSED';
export const CLEAR_BROWSED = 'CLEAR_BROWSED';
export const SET_COPIED_ENTITIES = 'SET_COPIED_ENTITIES';
export const SET_FINDER = 'SET_FINDER';
export const CLOSE_FINDER = 'CLOSE_FINDER';
export const SET_DATASPACE = 'SET_DATASPACE';
export const SET_DRAGGING_HLIST_ITEM = 'SET_DRAGGING_HLIST_ITEM';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_SHOW_HLIST = 'TOGGLE_SHOW_HLIST';
export const TOGGLE_EXPANDED_HLIST_ITEMS = 'TOGGLE_EXPANDED_HLIST_ITEMS';
export const SET_EXPANDED_HLIST_ITEMS = 'SET_EXPANDED_HLIST_ITEMS';
export const SHOW_GLOBAL_MODAL = 'SHOW_GLOBAL_MODAL';
export const HIDE_GLOBAL_MODAL = 'HIDE_GLOBAL_MODAL';
export const LOG_QUICK_CREATE_MENU_ITEM = 'LOG_QUICK_CREATE_MENU_ITEM';
export const SET_REROUTING = 'SET_REROUTING';
export const SET_RECENT_QUICK_NOTES = 'SET_RECENT_QUICK_NOTES';
export const ADD_RECENT_QUICK_NOTE = 'ADD_RECENT_QUICK_NOTE';
export const SET_PANEL_OPEN = 'SET_PANEL_OPEN';
export const TOAST = 'TOAST';
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const CLEAR_ALL_TOASTS = 'CLEAR_ALL_TOASTS';
export const SHOW_FILES_UPLOAD_TOAST = 'SHOW_FILES_UPLOAD_TOAST';
export const HIDE_FILES_UPLOAD_TOAST = 'HIDE_FILES_UPLOAD_TOAST';
export const SET_FILE_UPLOAD_SUCCESS = 'SET_FILE_UPLOAD_SUCCESS';
export const SET_FILE_UPLOAD_FAIL = 'SET_FILE_UPLOAD_FAIL';
export const SET_IS_TRASH_PAGE = 'SET_IS_TRASH_PAGE';
export const SHOW_MENU = 'SHOW_MENU';
export const HIDE_MENU = 'HIDE_MENU';
export const SET_RENAMING_INSTANCE = 'SET_RENAMING_INSTANCE';
export const CLEAR_RENAMING_INSTANCE = 'CLEAR_RENAMING_INSTANCE';
export const ADD_ACTIVE_ENTITY_CONTAINER = 'ADD_ACTIVE_ENTITY_CONTAINER';
export const REMOVE_ACTIVE_ENTITY_CONTAINER = 'REMOVE_ACTIVE_ENTITY_CONTAINER';
export const UNPROTECT_SELECTION = 'UNPROTECT_SELECTION';
export const SET_FINDER_TABS = 'SET_FINDER_TABS';
export const SET_UPLOADING_ENTITIES = 'SET_UPLOADING_ENTITIES';
export const CLEAR_UPLOADING_ENTITIES = 'CLEAR_UPLOADING_ENTITIES';
export const SET_UPLOADING_ENTITY_PROGRESS = 'SET_UPLOADING_ENTITY_PROGRESS';
export const CHANGE_HLIST_ROOT = 'CHANGE_HLIST_ROOT';
export const SET_IS_ALL_NOTES_VIEW = 'SET_IS_ALL_NOTES_VIEW';
export const TOGGLE_CHAT_PANEL = 'TOGGLE_CHAT_PANEL';
export const ADD_AUDIBLE_CONTAINER = 'ADD_AUDIBLE_CONTAINER';
export const REMOVE_AUDIBLE_CONTAINER = 'REMOVE_AUDIBLE_CONTAINER';

// -- Files --
export const GET_DIR_CONTENT = 'GET_DIR_CONTENT';
export const GET_DIR_CONTENT_SUCCESS = 'GET_DIR_CONTENT_SUCCESS';
export const GET_DIR_CONTENT_FAILURE = 'GET_DIR_CONTENT_FAILURE';

// -- Callbacks --
export const CALLBACK = 'CALLBACK';
export const CALLBACK_SUCCESS = 'CALLBACK_SUCCESS';
export const CALLBACK_FAILURE = 'CALLBACK_FAILURE';

export const FALLBACK = 'FALLBACK';
export const FALLBACK_SUCCESS = 'FALLBACK_SUCCESS';
export const FALLBACK_FAILURE = 'FALLBACK_FAILURE';

// -- Integrations --

export const GET_CACHED_INTEGRATION_DATA = 'GET_CACHED_INTEGRATION_DATA';
export const GET_CACHED_INTEGRATION_DATA_SUCCESS = 'GET_CACHED_INTEGRATION_DATA_SUCCESS';
export const GET_CACHED_INTEGRATION_DATA_FAILURE = 'GET_CACHED_INTEGRATION_DATA_FAILURE';

export const AUTHORIZE_INTEGRATION = 'AUTHORIZE_INTEGRATION';
export const AUTHORIZE_INTEGRATION_SUCCESS = 'AUTHORIZE_INTEGRATION_SUCCESS';
export const AUTHORIZE_INTEGRATION_FAILURE = 'AUTHORIZE_INTEGRATION_FAILURE';

export const GET_INTEGRATION_ITEMS = 'GET_INTEGRATION_ITEMS';
export const GET_INTEGRATION_ITEMS_SUCCESS = 'GET_INTEGRATION_ITEMS_SUCCESS';
export const GET_INTEGRATION_ITEMS_FAILURE = 'GET_INTEGRATION_ITEMS_FAILURE';

export const REVOKE_INTEGRATION = 'REVOKE_INTEGRATION';
export const REVOKE_INTEGRATION_SUCCESS = 'REVOKE_INTEGRATION_SUCCESS';
export const REVOKE_INTEGRATION_FAILURE = 'REVOKE_INTEGRATION_FAILURE';

export const ADD_INTEGRATION_ITEM_TO_DATASPACE = 'ADD_INTEGRATION_ITEM_TO_DATASPACE';
export const ADD_INTEGRATION_ITEM_TO_DATASPACE_FAILURE = 'ADD_INTEGRATION_ITEM_TO_DATASPACE_FAILURE';
export const ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS = 'ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS';

export const CREATE_INTEGRATION_ITEM = 'CREATE_INTEGRATION_ITEM';
export const CREATE_INTEGRATION_ITEM_SUCCESS = 'CREATE_INTEGRATION_ITEM_SUCCESS';
export const CREATE_INTEGRATION_ITEM_FAILURE = 'CREATE_INTEGRATION_ITEM_FAILURE';

export const IMPORT_INTEGRATION_FILES_TO_DATASPACE = 'IMPORT_INTEGRATION_FILES_TO_DATASPACE';

export const UPDATE_INTEGRATION_ITEM_SUCCESS = 'UPDATE_INTEGRATION_ITEM_SUCCESS';

export const DELETE_INTEGRATION_ITEM_SUCCESS = 'DELETE_INTEGRATION_ITEM_SUCCESS';

export const TRIGGER_SCROLL_TO_BROWSED = 'TRIGGER_SCROLL_TO_BROWSED';

// -- AI --
export const QUERY_AI = 'QUERY_AI';
export const QUERY_AI_SUCCESS = 'QUERY_AI_SUCCESS';
export const QUERY_AI_FAILURE = 'QUERY_AI_FAILURE';
