import { useDispatch, useSelector } from 'react-redux';
import platformOS from 'platform-detect/os.mjs';
import AvatarNameJob from './AvatarNameJob';
import { getUserSelector } from '@/redux/user/selectors';
import { useEffect, useState } from 'react';
import ElectronClient from './ElectronClient';
import Team from './Team';
import TeamDataspace from './TeamDataspace';
import welcomeModalStyles from './welcomeModal.module.scss';
import { addMembers, updateEntity } from '@/redux/entities/actions';
import { getDataspaceSelector } from '@/redux/entities/selectors';
import Invite from './Invite';
import { signIn } from 'next-auth/react';
import { completeUserSignup } from '@/redux/user/actions';
import { cloneEntity, personOwnsDataspace, isElectron } from '@/utils/helpers';
import { uploadFiles } from '@/utils/helpers';

const dimensions = [
    [708, 650],
    // [865, 579],
    [708, 600],
    [708, 620],
    [708, 712],
    [708, 416],
    [708, 560],
];

const WelcomeModal = ({ hideModal }): JSX.Element => {
    const dispatch = useDispatch();
    const { id: userId, entity, dsid } = useSelector(getUserSelector);
    const dataspace = useSelector(getDataspaceSelector);
    const [step, setStep] = useState(1);
    const ownsDataspace = personOwnsDataspace(entity);

    const [latestVersion, setLatestVersion] = useState<undefined | null | BinaryVersion>(undefined);

    useEffect(() => {
        (async () => {
            const versions = await fetch('/api/downloads/versions').then((res) => res.json());

            if (Array.isArray(versions)) {
                setLatestVersion(versions[0]);
            } else {
                setLatestVersion(null);
            }
        })();
    }, []);

    const update =
        (isDataspaceValues = false) =>
        (values) => {
            let callback = null;
            if (isDataspaceValues) {
                const newDataspaceEntity = Object.assign({}, cloneEntity(dataspace), values) as Entity;
                dispatch(updateEntity(dataspace?.metadata?.id, newDataspaceEntity, 'WelcomeModal1'));
            } else {
                if ('team' in values && values.team === false) {
                    callback = hideModal;
                }
                if (entity?.complete === true && ownsDataspace && dsid) {
                    dispatch(updateEntity(userId, { ...entity, ...values }, 'WelcomeModal2', callback));
                } else {
                    dispatch(completeUserSignup(values));
                }
            }
            if (callback === null) {
                setStep((s) => s + 1);
            }
        };

    const skipStep = (e): void => {
        e.preventDefault();
        if (step === 6) {
            hideModal();
        } else {
            setStep((s) => s + 1);
        }
    };
    const prevStep = (e): void => {
        e.preventDefault();
        setStep((step) => {
            return step === 3 && (isElectron() || (!platformOS.windows && !platformOS.macos)) ? 1 : step - 1;
        });
    };

    // INVITEES
    const [emails, setEmails] = useState<string[]>([]);

    const addEmail = (email: string) =>
        setEmails((list) => {
            list.unshift(email);
            return [...list];
        });
    const removeEmail = (index) =>
        setEmails((list) => {
            list.splice(index, 1);
            return [...list];
        });

    const emailParams = JSON.stringify({ type: 'invitation', from: entity?.name, dataspace: dataspace?.name });
    const sendInviteEmailFunction = (email: string) => () => {
        signIn(
            'email',
            {
                email,
                callbackUrl: `${window.location.origin}/welcome?dsid=${dsid}&inviteFrom=${userId}`,
                redirect: false,
            },
            { emailParams },
        );
    };

    const handleInviteClick = () => {
        if (!emails || !emails.length) return;

        dispatch(addMembers(emails, () => emails.forEach((email) => sendInviteEmailFunction(email))));

        hideModal();
    };

    useEffect(() => {
        if (step === 2 && (isElectron() || (!platformOS.windows && !platformOS.macos))) {
            setStep(3);
        }
    }, [step]);

    const getModalDimensions = () => {
        if (step === 5 && emails?.length > 0) {
            return { width: dimensions[5][0], height: dimensions[step][1] };
        }
        return { width: dimensions[step - 1][0], height: dimensions[step - 1][1] };
    };

    const handlePictureUpload =
        (setFieldValue, field) =>
        (avatar: File): void => {
            uploadFiles([avatar], {}).then((docEntities) => {
                const url = docEntities[0]?.url;
                setFieldValue(field, url);
                if (field === 'avatarUrl') {
                    dispatch(updateEntity(userId, { ...entity, [field]: url }, 'WelcomeModal3'));
                } else if (field === 'iconUrl') {
                    dispatch(updateEntity(dataspace?.metadata?.id, { ...dataspace, iconUrl: url }, 'WelcomeModal4'));
                }
            });
        };

    return (
        <div className={welcomeModalStyles.mainContainer} style={getModalDimensions()}>
            {step === 1 && (
                <AvatarNameJob handleAvatarChange={handlePictureUpload} entity={entity} nextStep={update()} />
            )}
            {/* {step === 2 && <Cloud entity={entity} nextStep={update()} prevStep={prevStep} />} */}
            {step === 2 && (
                <ElectronClient entity={entity} skipStep={skipStep} prevStep={prevStep} latestVersion={latestVersion} />
            )}
            {step === 3 && <Team entity={entity} nextStep={update()} prevStep={prevStep} />}
            {step === 4 && (
                <TeamDataspace
                    dataspace={dataspace}
                    handleIconChange={handlePictureUpload}
                    nextStep={update(true)}
                    prevStep={prevStep}
                />
            )}
            {step >= 5 && (
                <Invite
                    entity={entity}
                    emails={emails}
                    addEmail={addEmail}
                    removeEmail={removeEmail}
                    skipStep={skipStep}
                    prevStep={prevStep}
                    handleInviteClick={handleInviteClick}
                />
            )}
        </div>
    );
};

export default WelcomeModal;
