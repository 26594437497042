import React, { useEffect, useRef, useState, memo } from 'react';
import styles from './imageModal.module.scss';
import Button from '../DesignSystem/Button';
import ControlMenu from '../entities/subcomponents/ControlMenu';
import CloseMdIcon from '@/icons/CloseMdIcon';
import RefreshMdIcon from '@/icons/RefreshMdIcon';

type Props = {
    entity?: Entity;
    imgSrc?: string;
    onClose: () => void;
};

const DEFAULT_ZOOM = 1;
const MAX_ZOOM = 3;
const ZOOM_STEP = 0.1;
const DEFAULT_ROTATE = 0;
const MAX_ROTATE = 270;
const ROTATE_DEGREE = 90;

const ImageModal = ({ entity, imgSrc, onClose }: Props): JSX.Element => {
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [rotateValue, setRotateValue] = useState<number>(DEFAULT_ROTATE);
    const imageRef = useRef(null);
    const parentRef = useRef(null);

    useEffect(() => {
        imageRef.current.style.transform = `scale(${zoom}) rotate(${rotateValue}deg)`;
    }, [zoom, rotateValue]);

    const zoomIn = () => setZoom((zoom) => zoom + ZOOM_STEP);
    const zoomOut = () => setZoom((zoom) => zoom - ZOOM_STEP);

    const handleZoomChange = (e) => {
        setZoom(+Number(e.target.value).toFixed(1));
    };

    const handleRotate = () => {
        if (rotateValue === MAX_ROTATE) {
            setRotateValue(0);
        } else {
            setRotateValue((value) => value + ROTATE_DEGREE);
        }
    };

    return (
        <div className={styles.modalContainer} ref={parentRef}>
            <Button size="m" variant="plain" icon={CloseMdIcon} className={styles.closeButton} onClick={onClose} />

            {entity && (
                <div className={styles.optionsMenu}>
                    <ControlMenu eid={entity.metadata.id} entity={entity} />
                </div>
            )}

            <MemoizedDraggableContainer>
                <img
                    src={entity ? entity.url : imgSrc}
                    className={styles.image}
                    ref={imageRef}
                    alt="modal image"
                    draggable={false}
                />
            </MemoizedDraggableContainer>

            <div className={styles.modalControls}>
                <Button
                    size="m"
                    variant="plain"
                    onClick={handleRotate}
                    icon={RefreshMdIcon}
                    className={styles.refreshButton}
                />
                <Button size="s" variant="plain" onClick={zoomOut} disabled={zoom === DEFAULT_ZOOM}>
                    <span className={styles.zoomSign}>-</span>
                </Button>
                <input
                    type="range"
                    className={styles.zoomRange}
                    onChange={handleZoomChange}
                    value={zoom}
                    min={DEFAULT_ZOOM}
                    max={MAX_ZOOM}
                    step={0.1}
                />
                <Button size="s" variant="plain" onClick={zoomIn} disabled={zoom === MAX_ZOOM}>
                    <span className={styles.zoomSign}>+</span>
                </Button>
            </div>
        </div>
    );
};

type DraggableProps = {
    children: JSX.Element;
};

const DraggableContainer = ({ children }: DraggableProps) => {
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const ref = useRef(null);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`;
        }
    }, [position]);

    const onMouseMove = (event) => {
        if (ref.current?.isPressed) {
            setPosition({
                x: position.x + event.movementX,
                y: position.y + event.movementY,
            });
        }
    };

    const setIsMousePressed = () => {
        ref.current.isPressed = !ref.current.isPressed;
    };

    return (
        <div
            ref={ref}
            onMouseMove={onMouseMove}
            onMouseDown={setIsMousePressed}
            onMouseUp={setIsMousePressed}
            className={styles.draggableContainer}
        >
            {children}
        </div>
    );
};

const MemoizedDraggableContainer = memo(DraggableContainer);

export default ImageModal;
