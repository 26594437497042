import React, { ReactElement } from 'react';
import { useFileDrop } from '@/hooks/remoteHooks';
import { useEntity } from '@/hooks/reduxHooks';
import { EdgeDropZone } from './DropZones';

export default function HListBottomDropZone({
    rootEid,
    isTrashPage,
    dropEntities,
    parentId,
    edgeMaxAdditionalIndent,
    childPadding,
    monitor,
    target,
    draggingHListItem,
    includeRootInHList,
}): ReactElement {
    const rootHlist = useEntity(rootEid, true, 'HListBottomDropZone');
    const fakeChildIndex = rootHlist?.childrenList?.length;
    const handleFileDrop = useFileDrop(parentId, fakeChildIndex);
    const indentLevel = 0;
    const edgeId = `edge-${rootEid}-bottom`;

    target = Object.assign({}, target, { hlistBottom: true });

    return (
        <EdgeDropZone
            hlistBottom={true}
            isTrashPage={isTrashPage}
            dropEntities={dropEntities}
            edgeMaxAdditionalIndent={edgeMaxAdditionalIndent}
            childPadding={childPadding}
            monitor={monitor}
            target={target}
            indentLevel={indentLevel}
            edgeId={edgeId}
            draggingHListItem={draggingHListItem}
            handleFileDrop={handleFileDrop}
            includeRootInHList={includeRootInHList}
        />
    );
}
