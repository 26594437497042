export default function FolderIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 5L7.64645 5.35355L7.79289 5.5H8V5ZM6.29289 3.29289L6.64645 2.93934L6.29289 3.29289ZM1 4V12H2V4H1ZM2.5 13.5H13.5V12.5H2.5V13.5ZM15 12V6H14V12H15ZM13.5 4.5H8V5.5H13.5V4.5ZM8.35355 4.64645L6.64645 2.93934L5.93934 3.64645L7.64645 5.35355L8.35355 4.64645ZM5.58579 2.5H2.5V3.5H5.58579V2.5ZM15 6C15 5.17157 14.3284 4.5 13.5 4.5V5.5C13.7761 5.5 14 5.72386 14 6H15ZM13.5 13.5C14.3284 13.5 15 12.8284 15 12H14C14 12.2761 13.7761 12.5 13.5 12.5V13.5ZM1 12C1 12.8284 1.67157 13.5 2.5 13.5V12.5C2.22386 12.5 2 12.2761 2 12H1ZM6.64645 2.93934C6.36514 2.65803 5.98361 2.5 5.58579 2.5V3.5C5.71839 3.5 5.84557 3.55268 5.93934 3.64645L6.64645 2.93934ZM2 4C2 3.72386 2.22386 3.5 2.5 3.5V2.5C1.67157 2.5 1 3.17157 1 4H2Z"
                fill="#333333"
            />
        </svg>
    );
}
