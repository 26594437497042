import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import Iframe from 'react-iframe';
import { cloneEntity, isElectron } from '@/utils/helpers';
import { useEntity } from '@/hooks/reduxHooks';
import { useDispatch, useSelector } from 'react-redux';
import { updateEntity } from '@/redux/entities/actions';
import { getUserSelector } from '@/redux/user/selectors';
import { INTEGRATIONS } from '../../../integrations/constants';
import { getDriveFileMetadata } from '../../../integrations/helpers';
import useOnScreen from '@/hooks/useOnScreen';

interface ViewProps {
    src: string;
    width: string;
    height: string;
    entity?: Entity;
}

const DRIVE_SYNC_INTERVAL = 5000;

const useUpdateDriveFileName = (entity: Entity, shouldSubscribe: boolean): null => {
    const dispatch = useDispatch();
    const user = useSelector(getUserSelector);
    const fullEntity = useEntity(entity?.metadata?.id, true, 'IntegrationEntityView');
    const integration =
        user?.entity?.integrationAccounts?.[
            INTEGRATIONS[entity?.app]?.getEntityId({ app: entity?.app, appId: entity?.appId })
        ];
    const tokens = integration?.tokens;
    const driveId = entity?.foreignId || entity?.id;

    const updateDriveEntityTitle = useCallback(
        (newTitle: string): void => {
            if (fullEntity && newTitle && fullEntity?.name !== newTitle) {
                const newEntity = cloneEntity(fullEntity);
                newEntity.name = newTitle;
                dispatch(updateEntity(entity?.metadata.id, newEntity, 'updateIntegrationEntityName'));
            }
        },
        [entity?.metadata?.id, fullEntity],
    );

    useEffect(() => {
        const fetchDriveFileMetadata = async () => {
            if (tokens && driveId && entity?.app === 'drive') {
                try {
                    const metadata = await getDriveFileMetadata(tokens, driveId);
                    if (metadata?.name) {
                        updateDriveEntityTitle(metadata?.name);
                    }
                } catch (error) {
                    console.error('Error fetching Drive file metadata:', error);
                }
            }
        };

        if (shouldSubscribe) {
            const intervalId = setInterval(fetchDriveFileMetadata, DRIVE_SYNC_INTERVAL);

            return () => clearInterval(intervalId);
        }
    }, [tokens, driveId, shouldSubscribe, entity?.app, updateDriveEntityTitle]);

    return null;
};

function View({ src, width, height, entity }: ViewProps) {
    const containerRef = useRef(null);
    const isOnScreen = useOnScreen(containerRef);
    useUpdateDriveFileName(entity, isOnScreen);

    const onElectron = isElectron();
    const view = useMemo(() => {
        return onElectron ? (
            <webview src={src} style={{ width, height }} />
        ) : (
            <Iframe url={src} width={width} height={height} />
        );
    }, [src, width, height, onElectron]);

    return (
        <div style={{ width: '100%', height: '100%' }} ref={containerRef}>
            {view}
        </div>
    );
}

export default memo(View);
