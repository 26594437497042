export default function FigmaFolderIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.7251 11.9501C7.45648 11.9501 8.05017 11.3564 8.05017 10.625V9.29993H6.7251C5.99372 9.29993 5.40002 9.89362 5.40002 10.625C5.40002 11.3564 5.99372 11.9501 6.7251 11.9501Z"
                fill="#0ACF83"
            />
            <path
                d="M5.40002 7.97505C5.40002 7.24367 5.99372 6.64998 6.7251 6.64998H8.05017V9.2998H6.7251C5.99372 9.30012 5.40002 8.70643 5.40002 7.97505Z"
                fill="#A259FF"
            />
            <path
                d="M5.40002 5.32507C5.40002 4.59369 5.99372 4 6.7251 4H8.05017V6.64983H6.7251C5.99372 6.64983 5.40002 6.05645 5.40002 5.32507Z"
                fill="#F24E1E"
            />
            <path
                d="M8.0498 4H9.37488C10.1063 4 10.6999 4.59369 10.6999 5.32507C10.6999 6.05645 10.1063 6.64983 9.37488 6.64983H8.0498V4Z"
                fill="#FF7262"
            />
            <path
                d="M10.6999 7.97505C10.6999 8.70643 10.1063 9.30012 9.37488 9.30012C8.6435 9.30012 8.0498 8.70643 8.0498 7.97505C8.0498 7.24367 8.6435 6.64998 9.37488 6.64998C10.1063 6.64998 10.6999 7.24367 10.6999 7.97505Z"
                fill="#1ABCFE"
            />
            <path
                d="M3.30004 14C2.58205 14 2 13.418 2 12.7V8.65573C1.17103 8.65573 0.499023 9.32774 0.499023 10.1567V12.7C0.499023 14.2469 1.75308 15.501 3.30004 15.501H5.8433C6.67226 15.501 7.34427 14.829 7.34427 14H3.30004ZM8.65573 14C8.65573 14.829 9.32774 15.501 10.1567 15.501H12.7C14.2469 15.501 15.501 14.2469 15.501 12.7V10.1567C15.501 9.32774 14.829 8.65573 14 8.65573V12.7C14 13.418 13.418 14 12.7 14H8.65573ZM14 7.34427C14.829 7.34427 15.501 6.67226 15.501 5.8433V3.30004C15.501 1.75308 14.2469 0.499023 12.7 0.499023H10.1567C9.32774 0.499023 8.65573 1.17103 8.65573 2H12.7C13.418 2 14 2.58205 14 3.30004V7.34427ZM7.34427 2C7.34427 1.17103 6.67226 0.499023 5.8433 0.499023H3.30004C1.75308 0.499023 0.499023 1.75308 0.499023 3.30004V5.8433C0.499023 6.67226 1.17103 7.34427 2 7.34427V3.30004C2 2.58205 2.58205 2 3.30004 2H7.34427Z"
                fill="#333333"
            />
        </svg>
    );
}
