import React from 'react';
import { PopupWrapper, usePopup } from '../wrappers/modal';
import styles from './addIntegrationPopup.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createEntity } from '../../redux/entities/actions';
import { useRouter } from 'next/router';
import { useUpdateBrowsed } from '../../hooks/reduxHooks';
import { newEntity } from '../../utils/helpers';
import { useCallback, useEffect } from 'react';
import typeInfo from '../entities/typeInfo';
import { getModalSelector } from '../../redux/ui/selectors';

export const useQuickCreatePopup = () => {
    const { open: openPopup } = usePopup();

    const openQuickCreatePopup = (): void => {
        openPopup('quick-create-popup');
    };
    return openQuickCreatePopup;
};

const entityTypeKeys = {
    RichText: 'T',
    Webwindow: 'W',
    Blob: 'O',
    Spreadsheet: 'S',
    Code: 'C',
};

function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
}

const QuickCreatePopup: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { close } = usePopup();
    const router = useRouter();
    const updateBrowsed = useUpdateBrowsed(true);
    const modal = useSelector(getModalSelector);

    const redirect = (data) => {
        if (data?.id) {
            updateBrowsed(data);
            router.push('/entity/' + data.id);
        }
    };

    const createEntityByType = (type: EntityType) => {
        dispatch(
            createEntity(
                {
                    type,
                    draft: true,
                    entity: newEntity(type),
                },
                redirect,
            ),
        );
        close();
    };

    const handleNewEntityClick = (e) => {
        const newEntityType = e.currentTarget.getAttribute('data-type');
        if (newEntityType) {
            createEntityByType(newEntityType);
        }
    };

    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        const keys = Object.values(entityTypeKeys);
        if (event.code.length === 4 && keys.includes(event.code[3])) {
            const selectedEntityType = getKeyByValue(entityTypeKeys, event.code[3]) as EntityType;
            createEntityByType(selectedEntityType);
        }
        if (event.key === 'Escape') close();
    }, []);

    useEffect(() => {
        if (modal === 'quick-create-popup') {
            // attach the event listener
            document.addEventListener('keydown', handleKeyPress);
        }

        // remove the event listener
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress, modal]);
    // type EntityType = 'RichText' | 'Webwindow' | 'Blob' | 'DigitalDocument' | 'Spreadsheet' | 'Code' | 'Tab';

    return (
        <PopupWrapper text={'quick-create-popup'}>
            <div className={styles.quickCreateWrapper}>
                <div className={styles.header}>
                    <h6>New entity</h6>
                </div>
                {Object.keys(entityTypeKeys).map((entityType) => {
                    return (
                        <div
                            className={styles.integration}
                            data-type={entityType}
                            key={`quick-${entityType}`}
                            onClick={handleNewEntityClick}
                        >
                            <div className={styles.integrationIconTitleWrapper}>
                                <div className={styles.integrationIcon}>{typeInfo[entityType].icon}</div>
                                <div>
                                    <u>{entityTypeKeys[entityType]}</u>
                                    {`${typeInfo[entityType].fancyName.slice(1)}`}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </PopupWrapper>
    );
};

export default QuickCreatePopup;
