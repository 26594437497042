export default function RefreshMdIcon(): JSX.Element {
    return (
        <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.34 7.2A.75.75 0 1 0 7.4 8.26l3.13-3.12.53-.53-.53-.53L7.4.95a.75.75 0 0 0-1.06 1.06l1.72 1.72a7 7 0 1 0 6.44 6.98.75.75 0 0 0-1.5 0 5.5 5.5 0 1 1-4.72-5.45L6.34 7.2Z"
                fill="#333"
            />
        </svg>
    );
}
