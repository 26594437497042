type Props = {
    src: string;
    className?: string;
};

const EntityImageIcon = ({ src, className }: Props): JSX.Element => {
    return <img src={src} alt="icon" width={16} height={16} className={className ? className : ''} />;
};

export default EntityImageIcon;
