import useSessionStorage from '@/hooks/useSessionStorage';
import { useRouter } from 'next/router';

interface UnauthorizedRedirect {
    redirectOnLoginUrl?: string;
    redirectToLogin: () => Promise<boolean>;
    clearRedirectOnLoginUrl: () => void;
}

const useUnauthorizedRedirect = (): UnauthorizedRedirect => {
    const router = useRouter();
    const [redirectOnLoginUrl, setRedirectOnLoginUrl] = useSessionStorage('redirectOnLoginUrl');

    const redirectToLogin = () => {
        setRedirectOnLoginUrl(router.asPath);
        return router.push('/login');
    };

    const clearRedirectOnLoginUrl = () => setRedirectOnLoginUrl(null);

    return {
        redirectOnLoginUrl,
        redirectToLogin,
        clearRedirectOnLoginUrl,
    };
};

export default useUnauthorizedRedirect;
