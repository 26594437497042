import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { PanelWrapper, usePanel } from '../wrappers/modal';

const FlexiblePanel = dynamic(() => import('./FlexiblePanel'), { ssr: false });
import styles from '../../pages/shortcuts.module.scss';
import { getOpenPanelSelector } from '../../redux/ui/selectors';
import Button from '../DesignSystem/Button';
import CloseMdIcon from '@/icons/CloseMdIcon';

const PanelShortcuts = () => {
    // const [panelIsOpen, setpanelIsOpen] = useState(true);
    const panelName = 'shortcuts-panel';
    const panelOpen = useSelector(getOpenPanelSelector(panelName));

    // const openPanel = () => {
    //     setpanelIsOpen(true);
    // };
    const { close } = usePanel(panelName);

    const OSName = (() => {
        if (typeof navigator !== 'undefined') {
            if (navigator.appVersion.indexOf('Win') != -1) {
                return 'Windows';
            }
            if (navigator.appVersion.indexOf('Mac') != -1) return 'MacOS';
            if (navigator.appVersion.indexOf('X11') != -1) return 'UNIX';
            if (navigator.appVersion.indexOf('Linux') != -1) {
                return 'Linux';
            }
        }
        return 'Unknown OS';
    })();
    const modKey = (() => {
        if (typeof navigator !== 'undefined') {
            if (navigator.appVersion.indexOf('Win') != -1) {
                return '⎇Alt';
            }
            if (navigator.appVersion.indexOf('Linux') != -1) {
                return '⎇Alt';
            }
        }
        return '⌘';
    })();

    return (
        <PanelWrapper panelName={panelName}>
            <FlexiblePanel
                initWidth={325}
                initHeight={560}
                left={50}
                disableKeyboardMove={true}
                onFocus={() => console.log('Panel is clicked')}
                className={'my-panel-custom-class'}
                onRequestClose={close}
                isOpen={panelOpen}
            >
                <div className="panel">
                    <div className={'panelHeader'}>
                        <div className={'panelTitle'}>
                            <h6>Shortcuts</h6>
                            <span className="secondary">{OSName}</span>
                        </div>

                        <Button size="s" variant="plain" icon={CloseMdIcon} onClick={close} />
                    </div>
                    <div className={'panelShortcuts'}>
                        <div className={'textXS'}>
                            <div className={'verticalStack'}>
                                <h6>Text</h6>
                                <div className={`${styles.shortcut}`}>
                                    <span>Checkbox Toggle</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.multiCharKey} ${styles.key}`}>⏎ ENTER</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Bold</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>B</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Italic</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>I</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Underline</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>U</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Header 1</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>1</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Header 2</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>2</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Header 3</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>3</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Checkbox</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>4</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Bulleted List</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>5</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Ordered List</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>⌥</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>6</div>
                                    </div>
                                </div>
                                <div className={`${styles.shortcut}`}>
                                    <span>Move lines up/down</span>
                                    <div className={styles.keys}>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>↑</div>
                                        <div>and</div>
                                        <div className={`${styles.singleCharKey} ${styles.key}`}>↓</div>
                                    </div>
                                </div>
                            </div>
                            {/*
                                SHORTCUTS FOR FUTURE FEATURES
                                <br />
                                <div className={'verticalStack'}>
                                    <h6>
                                        Objects<span className={'dim'}>.app</span>
                                    </h6>
                                    <div className={`${styles.shortcut}`}>
                                        <span>Create New</span>
                                        <div className={styles.keys}>
                                            <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                            <div className={`${styles.singleCharKey} ${styles.key}`}>N</div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <div className={'verticalStack'}>
                                    <h6>SpaceOS</h6>
                                    <div className={`${styles.shortcut}`}>
                                        <span>Command Palette</span>
                                        <div className={styles.keys}>
                                            <div className={`${styles.singleCharKey} ${styles.key}`}>{modKey}</div>
                                            <div className={`${styles.multiCharKey} ${styles.key}`}>⇧ SHIFT</div>
                                            <div className={`${styles.singleCharKey} ${styles.key}`}>P</div>
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </FlexiblePanel>
        </PanelWrapper>
    );
};

export default PanelShortcuts;
