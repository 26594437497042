export default function TriangleUpIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.08104 3.69922C8.27896 3.40236 8.71518 3.40236 8.91309 3.69922L12.9788 9.79787C13.2004 10.1302 12.9622 10.5752 12.5628 10.5752L4.43133 10.5752C4.03198 10.5752 3.79379 10.1302 4.0153 9.79787L8.08104 3.69922Z"
                fill="black"
                fillOpacity="1"
            />
        </svg>
    );
}
