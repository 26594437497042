import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';

export function useJune() {
    const juneKey = process.env.NEXT_PUBLIC_JUNE_WRITE_KEY;
    const nodeEnv = process.env.NODE_ENV;
    const [analytics, setAnalytics] = useState(undefined);

    useEffect(() => {
        if (nodeEnv !== 'development' && juneKey && typeof window !== 'undefined') {
            const loadAnalytics = async () => {
                const [response] = await AnalyticsBrowser.load({
                    writeKey: juneKey,
                });
                setAnalytics(response);
            };
            loadAnalytics();
        }
    }, [juneKey]);

    return analytics;
}
