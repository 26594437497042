export default function ElectronAppIcon(): JSX.Element {
    return (
        <svg viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_1315_4952)">
                <g clipPath="url(#clip0_1315_4952)">
                    <rect x="100" y="100" width="824" height="824" rx="184" fill="black" fillOpacity="0.3" />
                    <circle cx="512" cy="512" r="577" fill="url(#paint0_radial_1315_4952)" fillOpacity="0.5" />
                    <g filter="url(#filter1_dd_1315_4952)">
                        <path
                            d="M740.666 511.993C740.666 638.283 638.289 740.661 512 740.661C385.711 740.661 283.334 638.283 283.334 511.993C283.334 385.704 385.711 283.326 512 283.326C638.289 283.326 740.666 385.704 740.666 511.993Z"
                            fill="white"
                            stroke="white"
                            strokeWidth="32.6678"
                        />
                        <path
                            d="M624.402 632.914C645.587 632.914 665.117 633.577 683.984 634.571C684.647 634.571 685.64 634.239 685.971 633.577C691.598 625.295 696.894 617.014 701.197 608.07C701.859 606.414 700.866 604.426 699.211 604.757C677.033 605.088 653.2 604.757 626.719 604.757C613.479 604.757 599.907 604.757 585.674 604.757C508.548 604.757 474.454 625.295 441.352 645.502C422.485 656.765 404.279 668.028 380.115 673.659C378.129 673.991 377.798 676.641 379.122 677.966C392.363 688.566 406.927 697.842 422.485 705.129C422.816 705.461 423.478 705.461 424.14 705.129C445.325 699.829 460.882 689.56 478.426 677.966C510.534 656.765 546.945 632.914 624.402 632.914Z"
                            fill="#F78200"
                        />
                        <path
                            d="M624.427 657.457C554.253 657.457 522.475 678.326 492.022 698.533C483.416 704.165 474.81 709.796 465.21 715.096C463.224 716.09 463.555 719.072 465.872 719.403C480.768 722.715 496.325 724.372 512.214 724.372C570.803 724.372 623.765 700.521 662.163 662.095C663.487 660.77 662.494 658.12 660.508 658.12C648.922 657.788 637.006 657.457 624.427 657.457Z"
                            fill="#E23838"
                        />
                        <path
                            d="M328.135 516.307C346.672 506.7 367.857 495.768 395.993 487.818C428.101 478.543 464.512 473.905 507.544 473.905C546.603 473.905 583.346 469.268 620.419 459.992C652.527 451.711 679.008 441.442 702.51 432.498C703.503 432.166 704.496 431.835 705.489 431.173C706.813 430.841 707.475 429.185 706.813 427.86C674.374 352.333 599.234 299.662 511.847 299.662C394.669 299.662 299.668 394.734 299.668 512C299.668 516.969 299.999 521.607 299.999 526.576C299.999 528.232 301.985 529.226 303.309 528.563C311.915 524.919 319.86 520.613 328.135 516.307Z"
                            fill="#009CDF"
                        />
                        <path
                            d="M426.536 623.172C460.961 602.633 499.689 579.114 583.435 579.114C597.669 579.114 611.571 579.114 624.812 579.114C656.258 579.114 684.394 579.114 709.551 578.783C710.544 578.783 711.537 578.12 711.868 577.126C716.171 564.207 719.15 550.957 720.806 537.375C721.137 535.719 719.481 534.394 717.826 534.725C673.14 543.669 617.199 550.625 544.707 550.625C507.303 550.625 475.525 554.932 448.051 563.545C423.887 571.164 406.013 581.101 388.469 591.039C369.932 601.308 352.058 611.246 329.549 616.878C327.894 617.209 327.232 619.196 328.225 620.521C334.845 631.453 342.127 641.722 350.734 651.329C351.396 651.991 352.058 652.323 352.72 651.991C383.504 649.341 403.696 637.085 426.536 623.172Z"
                            fill="#FFB900"
                        />
                        <path
                            d="M339.383 538.175C328.46 543.806 317.536 549.438 305.951 554.075C304.958 554.407 304.296 555.732 304.627 556.725C307.275 569.645 311.247 581.901 316.212 593.495C316.543 594.489 317.867 595.152 318.86 594.82C341.7 590.183 359.244 580.576 378.442 569.645C415.185 549.438 456.561 526.249 546.597 526.249C621.074 526.249 678.008 518.63 722.364 509.355C723.357 509.024 724.35 508.03 724.35 507.036C724.019 489.148 721.371 471.923 717.068 455.691C716.737 454.366 715.082 453.703 713.758 454.035L711.772 454.697C664.768 472.916 600.552 497.761 507.868 497.761C416.84 498.424 375.794 519.624 339.383 538.175Z"
                            fill="#5EBD3E"
                        />
                    </g>
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_1315_4952"
                    x="78"
                    y="89"
                    width="868"
                    height="868"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="11" />
                    <feGaussianBlur stdDeviation="11" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.28 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1315_4952" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1315_4952" result="shape" />
                </filter>
                <filter
                    id="filter1_dd_1315_4952"
                    x="216.884"
                    y="239.15"
                    width="590.233"
                    height="590.263"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="5.56848"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_1315_4952"
                    />
                    <feOffset dy="22.2739" />
                    <feGaussianBlur stdDeviation="22.2739" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1315_4952" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="5.56848"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect2_dropShadow_1315_4952"
                    />
                    <feOffset />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_1315_4952" result="effect2_dropShadow_1315_4952" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1315_4952" result="shape" />
                </filter>
                <radialGradient
                    id="paint0_radial_1315_4952"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(512 512) rotate(-180) scale(577)"
                >
                    <stop stopColor="#DEE2E9" stopOpacity="0.71" />
                    <stop offset="1" stopColor="#D4DBE6" stopOpacity="0" />
                </radialGradient>
                <clipPath id="clip0_1315_4952">
                    <rect x="100" y="100" width="824" height="824" rx="184" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
