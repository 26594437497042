import { useMousePosition } from '@/hooks/remoteHooks';
import * as React from 'react';

/** Component to cover the area between the mouse cursor and the sub-menu, to allow moving cursor to lower parts of sub-menu without the sub-menu disappearing. */
export function MouseSubmenuSafeArea(
    props: { parentRef: React.RefObject<HTMLDivElement> } & React.HTMLAttributes<HTMLDivElement>,
) {
    const { x = 0, y = 0, height: h = 0, width: w = 0 } = props.parentRef.current?.getBoundingClientRect() || {};
    const { x: mouseX, y: mouseY, flush } = useMousePosition(200);
    const positions = { x, y, h, w, mouseX, mouseY };

    React.useEffect(() => {
        const element = props.parentRef.current;

        const handleIntersection = function ([entry]) {
            if (!entry.isIntersecting) return;
            flush?.();
        };

        const observer = new IntersectionObserver(handleIntersection);

        observer.observe(element);

        return () => observer.disconnect();
    }, [flush, props.parentRef]);

    return (
        <div
            {...props}
            style={{
                position: 'absolute',
                top: 0,
                right: getRight(positions),
                left: getLeft(positions),
                height: h,
                width: getWidth(positions),
                clipPath: getClipPath(positions),
            }}
        />
    );
}

interface Positions {
    /* Sub-menu x */
    x: number;
    /* Sub-menu y */
    y: number;
    /* Sub-menu height */
    h: number;
    /* Sub-menu width */
    w: number;
    /* Mouse x */
    mouseX: number;
    /* Mouse y */
    mouseY: number;
}

const maxX = 0;
const getLeft = ({ x, mouseX }: Positions) => (mouseX > x ? undefined : -Math.max(x - mouseX, maxX) + 'px');
const getRight = ({ x, w, mouseX }: Positions) => (mouseX > x ? -Math.max(mouseX - (x + w), maxX) + 'px' : undefined);
const getWidth = ({ x, w, mouseX }: Positions) =>
    mouseX > x ? Math.max(mouseX - (x + w), maxX) + 'px' : Math.max(x - mouseX, maxX) + 'px';
const getClipPath = ({ x, y, h, mouseX, mouseY }: Positions) =>
    mouseX > x
        ? `polygon(0% 0%, 0% 100%, 100% ${(100 * (mouseY - y)) / h}%)`
        : `polygon(100% 0%, 0% ${(100 * (mouseY - y)) / h}%, 100% 100%)`;
