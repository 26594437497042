import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuSelector } from '@/redux/ui/selectors';
import { hideMenu } from '@/redux/ui/actions';
import useOutsideClick from '@/hooks/outsideClick';
import { QuickCreateMenu } from '@/components/modals/QuickCreateMenu/QuickCreateMenu';
import ContextMenu from '@/components/modals/ContextMenu';
import styles from '@/components/modals/contextMenu.module.scss';
import clsx from 'clsx';

const OFFSET = 10;
const Menu = (): React.ReactElement => {
    const menuParams = useSelector(getMenuSelector);
    const menuRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const [subMenuPositionCSS, setSubMenuPositionCSS] = useState<CSSProperties>({});

    const closeMenu = useCallback((): void => {
        if (!isVisible) return;
        setIsVisible(false);
        dispatch(hideMenu());
        setSubMenuPositionCSS({});
    }, [isVisible, menuParams]);

    const updateSubmenuPositions = (newPositions: CSSProperties) => {
        setSubMenuPositionCSS((positions) => ({
            ...positions,
            ...newPositions,
        }));
    };

    const hideOnClick = (action) => (e) => {
        e?.target.classList.add(styles.blink);
        e?.target.addEventListener(
            'animationend',
            () => {
                action(e);
                closeMenu();
                e.target.classList.remove(styles.blink);
            },
            { once: true },
        );
    };

    useOutsideClick(menuRef, closeMenu, 'menu-modal');

    useEffect(() => {
        const menuWidth = 300;
        const submenuWidth = 300;

        if (menuRef?.current && (menuParams?.eid || menuParams?.contextMenuEntity)) {
            menuRef.current.style.setProperty(
                '--pos-x',
                menuParams?.type === 'ContextMenu' ? menuParams.x + OFFSET + 'px' : menuParams.x + 'px',
            );
            menuRef.current.style.setProperty('--pos-y', menuParams.y + OFFSET + 'px');
            setIsVisible(true);

            if (menuParams?.x > window?.innerWidth - ((menuRef?.current?.clientWidth || menuWidth) + submenuWidth)) {
                updateSubmenuPositions({ left: 'calc(-100% + 2px)' });
            }
        } else {
            setIsVisible(false);
        }
    }, [menuParams, menuRef]);

    const menuComponent = useMemo(() => {
        if (menuParams?.type === 'QuickCreateMenu') {
            return (
                <QuickCreateMenu
                    menuParams={menuParams}
                    menuRef={menuRef}
                    onSelect={hideOnClick}
                    closeMenu={closeMenu}
                    isVisible={isVisible}
                    subMenuPositionCSS={subMenuPositionCSS}
                    updateSubmenuPositions={updateSubmenuPositions}
                />
            );
        }
        if (menuParams?.type === 'ContextMenu') {
            return (
                <ContextMenu
                    isVisible={isVisible}
                    menuParams={menuParams}
                    onSelect={hideOnClick}
                    closeMenu={closeMenu}
                    menuRef={menuRef}
                    subMenuPositionCSS={subMenuPositionCSS}
                    updateSubmenuPositions={updateSubmenuPositions}
                />
            );
        }
    }, [menuParams, subMenuPositionCSS, closeMenu]);

    return (
        <menu className={clsx(styles.contextMenu, styles.menu, isVisible && styles.visible)} ref={menuRef}>
            {menuComponent}
        </menu>
    );
};

export default Menu;
