import { GlobalModalParams, GlobalModalTypes } from '../../components/modals/GlobalModal';
import {
    SET_UI,
    CLOSE_MODAL,
    SET_SELECTED_ENTITIES,
    CLEAR_SELECTED_ENTITIES,
    SET_BROWSED,
    CLEAR_BROWSED,
    SET_COPIED_ENTITIES,
    SET_FINDER,
    CLOSE_FINDER,
    SET_DRAGGING_HLIST_ITEM,
    TOGGLE_SIDEBAR,
    TOGGLE_EXPANDED_HLIST_ITEMS,
    TOGGLE_SHOW_HLIST,
    SET_EXPANDED_HLIST_ITEMS,
    SHOW_GLOBAL_MODAL,
    HIDE_GLOBAL_MODAL,
    SET_REROUTING,
    SET_RECENT_QUICK_NOTES,
    ADD_RECENT_QUICK_NOTE,
    SET_PANEL_OPEN,
    ADD_TOAST,
    REMOVE_TOAST,
    CLEAR_ALL_TOASTS,
    TOAST,
    SHOW_FILES_UPLOAD_TOAST,
    HIDE_FILES_UPLOAD_TOAST,
    SET_FILE_UPLOAD_SUCCESS,
    SET_FILE_UPLOAD_FAIL,
    SET_IS_TRASH_PAGE,
    LOG_QUICK_CREATE_MENU_ITEM,
    TRIGGER_SCROLL_TO_BROWSED,
    SHOW_MENU,
    HIDE_MENU,
    SET_RENAMING_INSTANCE,
    CLEAR_RENAMING_INSTANCE,
    ADD_ACTIVE_ENTITY_CONTAINER,
    UNPROTECT_SELECTION,
    SET_FINDER_TABS,
    SET_UPLOADING_ENTITIES,
    CLEAR_UPLOADING_ENTITIES,
    SET_UPLOADING_ENTITY_PROGRESS,
    CHANGE_HLIST_ROOT,
    SET_IS_ALL_NOTES_VIEW,
    TOGGLE_CHAT_PANEL,
    ADD_AUDIBLE_CONTAINER,
    REMOVE_AUDIBLE_CONTAINER,
    REMOVE_ACTIVE_ENTITY_CONTAINER,
} from '../actionTypes';

import { UIState, Finder, MenuType } from '../types';
import { v4 as uuidv4 } from 'uuid';

export const setUI = (UI: UIState, debugMessage?: string): ReduxAction => {
    return {
        type: SET_UI,
        payload: { UI, debugMessage },
    };
};

export const closeModal = (): ReduxAction => {
    return {
        type: CLOSE_MODAL,
        payload: {},
    };
};

export const setSelectedEntities = (ids: EntityIdentity[], clickType?: string): ReduxAction => {
    return {
        type: SET_SELECTED_ENTITIES,
        payload: { ids, clickType },
    };
};

export const clearSelectedEntities = (): ReduxAction => {
    return {
        type: CLEAR_SELECTED_ENTITIES,
        payload: {},
    };
};

export const setBrowsed = (
    id: string,
    parentId?: string | boolean,
    setAsSelectedEntities = false,
    tabFinderId?: string,
    debugMessage?: string,
): ReduxAction => {
    return {
        type: SET_BROWSED,
        payload: { id, parentId, setAsSelectedEntities, tabFinderId, debugMessage },
    };
};

export const clearBrowsed = (): ReduxAction => {
    return {
        type: CLEAR_BROWSED,
        payload: {},
    };
};

export const setCopiedEntities = (copiedEntities: EntityIdentity[]): ReduxAction => {
    return {
        type: SET_COPIED_ENTITIES,
        payload: { copiedEntities },
    };
};

export const setFinder = (finder: Finder): ReduxAction => {
    return {
        type: SET_FINDER,
        payload: { finder },
    };
};

export const closeFinder = (): ReduxAction => {
    return {
        type: CLOSE_FINDER,
        payload: {},
    };
};

export const setDraggingHListItem = (dragged: boolean): ReduxAction => {
    return {
        type: SET_DRAGGING_HLIST_ITEM,
        payload: { draggingHListItem: dragged },
    };
};

export const toggleSidebar = (): ReduxAction => {
    return {
        type: TOGGLE_SIDEBAR,
        payload: {},
    };
};

export const toggleExpandedHListItems = (targetEids: string[], open: boolean): ReduxAction => {
    return {
        type: TOGGLE_EXPANDED_HLIST_ITEMS,
        payload: { targetEids, open },
    };
};

export const setExpandedHListItems = (expandedHListItems: { [key: string]: boolean }): ReduxAction => {
    return {
        type: SET_EXPANDED_HLIST_ITEMS,
        payload: { expandedHListItems },
    };
};

export const toggleShowHList = (): ReduxAction => {
    return {
        type: TOGGLE_SHOW_HLIST,
        payload: {},
    };
};

export const showGlobalModal = (type: GlobalModalTypes, props, globalModalParams?: GlobalModalParams) => {
    return {
        type: SHOW_GLOBAL_MODAL,
        payload: { type, props, globalModalParams },
    };
};

export const hideGlobalModal = (type?: GlobalModalTypes) => {
    return {
        type: HIDE_GLOBAL_MODAL,
        payload: { type },
    };
};

export const logQuickCreateMenuItem = (value: string) => {
    return {
        type: LOG_QUICK_CREATE_MENU_ITEM,
        payload: { value },
    };
};

export const setRerouting = (rerouting: boolean) => {
    return {
        type: SET_REROUTING,
        payload: { rerouting },
    };
};

export const setRecentQuickNotes = (recentQuickNotes: string[]) => {
    return {
        type: SET_RECENT_QUICK_NOTES,
        payload: { recentQuickNotes },
    };
};

export const addRecentQuickNote = (eid: string) => {
    return {
        type: ADD_RECENT_QUICK_NOTE,
        payload: { eid },
    };
};

export const setPanelOpen = (panelName: string, open: boolean) => {
    return {
        type: SET_PANEL_OPEN,
        payload: { panelName, open },
    };
};

export const toast = (payload: MessageBlockAction): ReduxAction => {
    return {
        type: TOAST,
        payload: { ...payload, id: uuidv4() },
    };
};

export const addToast = (toast: MessageBlockAction): ReduxAction => {
    return {
        type: ADD_TOAST,
        payload: { toast },
    };
};

export const removeToast = (id = null): ReduxAction => {
    return {
        type: REMOVE_TOAST,
        payload: { id },
    };
};

export const clearAllToasts = (): ReduxAction => {
    return {
        type: CLEAR_ALL_TOASTS,
        payload: {},
    };
};

export const showUploadFilesToast = (payload: string[]): ReduxAction => {
    return {
        type: SHOW_FILES_UPLOAD_TOAST,
        payload,
    };
};

export const hideUploadFilesToast = (): ReduxAction => {
    return {
        type: HIDE_FILES_UPLOAD_TOAST,
        payload: {},
    };
};

export const setFileUploadSuccess = (payload: string): ReduxAction => {
    return {
        type: SET_FILE_UPLOAD_SUCCESS,
        payload,
    };
};

export const setFileUploadFail = (payload: string): ReduxAction => {
    return {
        type: SET_FILE_UPLOAD_FAIL,
        payload,
    };
};

export const setIsTrashPage = (isTrashPage: boolean): ReduxAction => {
    return {
        type: SET_IS_TRASH_PAGE,
        payload: { isTrashPage },
    };
};

export const triggerScrollToBrowsed = (value: boolean): ReduxAction => {
    return {
        type: TRIGGER_SCROLL_TO_BROWSED,
        payload: { value },
    };
};

export const showMenu = ({ type, x, y, eid, contextMenuEntity }: MenuType): ReduxAction => {
    return {
        type: SHOW_MENU,
        payload: { type, x, y, eid, contextMenuEntity },
    };
};

export const hideMenu = () => {
    return {
        type: HIDE_MENU,
        payload: {},
    };
};

export const setRenamingInstance = (id: string, parentId?: string | boolean): ReduxAction => {
    return {
        type: SET_RENAMING_INSTANCE,
        payload: { id, parentId },
    };
};

export const clearRenamingInstance = (): ReduxAction => {
    return {
        type: CLEAR_RENAMING_INSTANCE,
        payload: {},
    };
};

export const addActiveEntityContainer = (containerProps: EntityContainerProps): ReduxAction => {
    return {
        type: ADD_ACTIVE_ENTITY_CONTAINER,
        payload: { containerProps },
    };
};

export const removeActiveEntityContainer = (eid: string): ReduxAction => {
    return {
        type: REMOVE_ACTIVE_ENTITY_CONTAINER,
        payload: { eid },
    };
};

export const unprotectSelection = (): ReduxAction => {
    return {
        type: UNPROTECT_SELECTION,
        payload: {},
    };
};

export const setFinderTabs = (
    finderId: string,
    tabsState: { tabsArray: Tab[]; tabsHistory: string[] },
): ReduxAction => {
    return {
        type: SET_FINDER_TABS,
        payload: { finderId, tabsState },
    };
};

export const setUploadingEntities = (entityIds: string[]): ReduxAction => {
    return {
        type: SET_UPLOADING_ENTITIES,
        payload: { entityIds },
    };
};

export const clearUploadingEntities = (): ReduxAction => {
    return {
        type: CLEAR_UPLOADING_ENTITIES,
        payload: {},
    };
};

export const setUploadingEntityProgress = (eid: string, progress: number): ReduxAction => {
    return {
        type: SET_UPLOADING_ENTITY_PROGRESS,
        payload: { eid, progress },
    };
};

export const singleUseHListRoot = (instance: EntityIdentity): ReduxAction => {
    return {
        type: CHANGE_HLIST_ROOT,
        payload: { instance },
    };
};

export const setIsAllNotesView = (value: boolean): ReduxAction => {
    return {
        type: SET_IS_ALL_NOTES_VIEW,
        payload: { value },
    };
};

export const toggleChatPanel = (): ReduxAction => {
    return {
        type: TOGGLE_CHAT_PANEL,
        payload: {},
    };
};

export const addAudibleContainer = (eid: string): ReduxAction => ({
    type: ADD_AUDIBLE_CONTAINER,
    payload: { eid },
});

export const removeAudibleContainer = (eid: string): ReduxAction => ({
    type: REMOVE_AUDIBLE_CONTAINER,
    payload: { eid },
});
