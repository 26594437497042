export default function SpreadsheetMdIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 8.5h13v-1h-13v1Zm12-7.5H8v1h5.5V1ZM8 1H2.5v1H8V1ZM2.5 15H8v-1H2.5v1ZM8 15h5.5v-1H8v1ZM7.5 1.5v13h1v-13h-1ZM1 2.5v2.25h1V2.5H1Zm0 2.25V8h1V4.75H1ZM15 8V4.75h-1V8h1Zm0-3.25V2.5h-1v2.25h1Zm-13.5.5h13v-1h-13v1ZM1 8v3.25h1V8H1Zm0 3.25v2.25h1v-2.25H1Zm14 2.25v-2.25h-1v2.25h1Zm0-2.25V8h-1v3.25h1Zm-13.5.5h13v-1h-13v1ZM13.5 2c.28 0 .5.22.5.5h1c0-.83-.67-1.5-1.5-1.5v1Zm0 13c.83 0 1.5-.67 1.5-1.5h-1a.5.5 0 0 1-.5.5v1Zm-11-1a.5.5 0 0 1-.5-.5H1c0 .83.67 1.5 1.5 1.5v-1Zm0-13C1.67 1 1 1.67 1 2.5h1c0-.28.22-.5.5-.5V1Z"
                fill="#333"
            />
        </svg>
    );
}
