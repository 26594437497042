export default function ArrowRightIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.15332 13.7373L11.7783 8.1123L6.15332 2.4873"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
