import React from 'react';
import styles from './UserAvatar.module.scss';

export default function UserAvatar({ name, avatarUrl, size = '22px' }): React.ReactElement {
    return (
        <div className={`${styles.avatarForeground}`} style={{ width: size, height: size }}>
            {avatarUrl ? <img className={styles.avatarImg} src={avatarUrl} /> : <span>{name && name[0]}</span>}
        </div>
    );
}
