export const multiSelectStyles = {
    container: (provided) => ({
        ...provided,
        width: '100%',
        minWidth: '279px',
        minHeight: '32px',
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '2px 12px',
    }),
    multiValue: (provided) => ({
        ...provided,
        lineHeight: '1.3',
        margin: 1,
        '& div:first-of-type': {
            paddingRight: 1,
        },
    }),
    control: (provided) => ({
        ...provided,
        minHeight: '32px',
        borderColor: '#DBDBDB80',
        borderRadius: '8px',
        backgroundColor: '#DBDBDB33',
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '20px',
        margin: '0',
        padding: '0',
    }),
    placeholder: (provided) => ({
        ...provided,
        fontSize: '13px',
        fontWeight: '600',
        lineHeight: '20px',
        color: '#8B979C',
        padding: 0,
        margin: 0,
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        '& div': {
            padding: '0 8px',
        },
    }),
    option: (provided) => ({
        ...provided,
        fontSize: '85%',
        lineHeight: '1.3',
    }),
};

export const selectStyles = {
    container: (provided) => ({
        ...provided,
        width: '121px',
        height: '32px',
    }),
    input: (provided) => ({
        ...provided,
        fontSize: '12px',
        paddingBottom: 0,
        paddingTop: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(0, 160, 247, 0.15)' : 'none',
        borderRadius: 3,
        color: state.isSelected ? '#00A0F7' : 'black',
        fontSize: 12,
        marginLeft: 6,
        marginRight: 6,
        paddingBottom: 0,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 0,
        width: 'calc(100% - 12px)',
    }),
    control: (provided) => ({
        ...provided,
        borderColor: '#DBDBDB80',
        borderRadius: '8px',
        height: '32px',
        minHeight: '32px',
        cursor: 'pointer',
        boxShadow: 'none',
        backgroundColor: '#F5F5F5',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        paddingLeft: 0,
        svg: {
            width: '12px',
            height: '12px',
            fill: '#999999',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0 0 0 12px',
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: '13px',
        lineHeight: '16px',
        fontWeight: 600,
    }),
    menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 5,
        boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
        width: '90px',
        overflow: 'hidden',
        padding: '2px 0',
    }),
    indicatorsContainer: (provided) => ({
        ...provided,
        ' div svg': {
            fill: '#000000',
        },
    }),
};

export const disabledSelectStyles = {
    indicatorsContainer: (provided) => ({
        ...provided,
        ' div svg': {
            fill: '#999999',
        },
    }),
};

export const multiSelectComponents = { DropdownIndicator: null };

export const selectComponents = { IndicatorSeparator: null };
