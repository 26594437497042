import React, { useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import styles from './settingsModal.module.scss';
import UserAccount from './userAccount';
// import EarnCredit from './earnCredit';
import DataspaceGeneral from './Dataspace/General';
import DataspaceMembers from './Dataspace/Members';
import SystemBackground from './systemBackground';
import DataspacePlans from './Dataspace/Plans';
import { DataspaceControlHeader } from './subcomponents/DataspaceControl';
import AppSettingsIcon from '@/icons/AppSettingsIcon';
import DataspaceSelector from '@/components/DataspaceSelector/DataspaceSelector';
import CloseMdIcon from '@/icons/CloseMdIcon';
import Button from '@/components/DesignSystem/Button';
import UserSelector from '@/components/UserSelector/UserSelector';
import StarIcon from '@/icons/StarIcon';
import { useSelector } from 'react-redux';
import { getDsidSelector } from '@/redux/entities/selectors';
import { asyncGQL } from '@/utils/helpers';
import { GET_DATASPACE_WITH_SUBSCRIPTION } from '@/queries';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

const SettingsModal: React.FunctionComponent = ({ hideModal }: { hideModal: () => void }) => {
    const dsid = useSelector(getDsidSelector);
    const [subscription, setSubscription] = useState({} as Subscription);

    useEffect(() => {
        if (dsid) {
            asyncGQL(GET_DATASPACE_WITH_SUBSCRIPTION, { dsid: dsid }).then((resp) => {
                setSubscription(resp.data?.dataspaces[0]?.subscription);
            });
        }
    }, [dsid]);

    const sidebarAccountMenu = [
        {
            title: <UserSelector />,
            eventKey: 'my_account',
            content: <UserAccount />,
        },
        // {
        //     title: 'Notifications',
        //     icon: <span>X</span>,
        //     eventKey: 'notifications',
        //     content: <Notifications />,
        // },
        // {
        //     title: 'Applications',
        //     icon: <span>X</span>,
        //     eventKey: 'applications',
        //     content: 'CONTENT',
        // },
        // {
        //     title: 'Language & Region',
        //     icon: <span>X</span>,
        //     eventKey: 'lang_and_region',
        //     content: 'CONTENT',
        // },
        // {
        //     title: 'Earn Credit',
        //     icon: <span>X</span>,
        //     eventKey: 'earn_credit',
        //     content: <EarnCredit />,
        // },
    ];

    const sidebarSystemMenu = [
        // {
        //     title: 'General',
        //     icon: <span>X</span>,
        //     eventKey: 'system_general',
        //     content: <SystemGeneral />,
        // },
        {
            title: 'Background',
            icon: <span>X</span>,
            eventKey: 'system_background',
            content: <SystemBackground />,
        },
        // {
        //     title: 'Preferences',
        //     icon: <span>X</span>,
        //     eventKey: 'system_preferences',
        //     content: <SystemPreferences />,
        // },
        // {
        //     title: 'Passcode',
        //     icon: <span>X</span>,
        //     eventKey: 'system_passcode',
        //     content: 'CONTENT',
        // },
        // {
        //     title: 'Privacy',
        //     icon: <span>X</span>,
        //     eventKey: 'system_privacy',
        //     content: 'CONTENT',
        // },
        // {
        //     title: 'Accessibility',
        //     icon: <span>X</span>,
        //     eventKey: 'system_accessibility',
        //     content: 'CONTENT',
        // },
    ];

    const sidebarDataspaceMenu = [
        {
            title: <DataspaceSelector disableSpecialDataspaces />,
            eventKey: 'dataspace_settings',
            content: <DataspaceGeneral />,
        },
        {
            title: 'Members',
            icon: <span>X</span>,
            eventKey: 'dataspace_members',
            content: <DataspaceMembers />,
        },
        {
            title:
                subscription?.stripe_plan_id !== publicRuntimeConfig.stripeProPlanId ? (
                    <span>
                        Upgrade&nbsp;&nbsp;
                        <StarIcon />
                    </span>
                ) : (
                    'Plans'
                ),
            icon: <span>X</span>,
            eventKey: 'dataspace_plans',
            content: <DataspacePlans dsid={dsid} subscription={subscription} />,
        },
    ];

    return (
        <div className={styles.settingsModalWrapper}>
            <div className={`${styles.settingsModal}`}>
                <div className={styles.settingsHeader}>
                    <div className={styles.settingsHeaderTitle}>
                        <div className={'appIconStyles'}>
                            <AppSettingsIcon />
                        </div>
                        <div className={styles.settingsHeaderText}>Settings</div>
                    </div>
                    <Button variant="plain" icon={CloseMdIcon} onClick={hideModal} />
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="dataspace_members">
                    <div className={styles.sidebar}>
                        <div className={styles.menuSubheading}>Account</div>
                        <Nav variant="pills" className="flex-column">
                            {sidebarAccountMenu.map((value, key) => (
                                <Nav.Item key={key} style={{ width: '100%' }}>
                                    <Nav.Link
                                        eventKey={value.eventKey}
                                        href="#"
                                        className={`${styles.menuSubItem} ${styles.userSubMenu}`}
                                    >
                                        {typeof value.title === 'string' ? <span>{value.title}</span> : value.title}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <div className={styles.menuSubheading}>System</div>
                        <Nav variant="pills" className="flex-column">
                            {sidebarSystemMenu.map((value, key) => (
                                <Nav.Item key={key}>
                                    <Nav.Link eventKey={value.eventKey} href="#" className={styles.menuSubItem}>
                                        {typeof value.title === 'string' ? <span>{value.title}</span> : value.title}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                        <div className={styles.menuSubheading}>Dataspaces</div>
                        <Nav variant="pills" className="flex-column">
                            {sidebarDataspaceMenu.map((value, key) => (
                                <Nav.Item key={key} className={styles.menuSubItemWrapper}>
                                    <Nav.Link eventKey={value.eventKey} href="#" className={styles.menuSubItem}>
                                        {typeof value.title === 'string' ? <span>{value.title}</span> : value.title}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </div>
                    <div className={styles.settingsTabContent}>
                        <Tab.Content>
                            {sidebarAccountMenu.map((value, key) => (
                                <Tab.Pane key={key} eventKey={value.eventKey}>
                                    {value.content}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                        <Tab.Content>
                            {sidebarSystemMenu.map((value, key) => (
                                <Tab.Pane key={key} eventKey={value.eventKey}>
                                    {value.content}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                        <Tab.Content>
                            {sidebarDataspaceMenu.map((value, key) => (
                                <Tab.Pane key={key} eventKey={value.eventKey}>
                                    <DataspaceControlHeader />
                                    {value.content}
                                </Tab.Pane>
                            ))}
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    );
};

export default SettingsModal;
