import { NODE_ENV } from '@/config';
import {
    SET_ENTITIES_STATE,
    SET_ENTITIES,
    CREATE_ENTITY,
    CREATE_ENTITY_SUCCESS,
    CREATE_ENTITY_FAILURE,
    GET_ENTITY,
    GET_ENTITY_SUCCESS,
    GET_ENTITY_FAILURE,
    GET_ENTITIES,
    GET_ENTITIES_SUCCESS,
    GET_ENTITIES_FAILURE,
    GET_RECENT_ENTITIES,
    GET_RECENT_ENTITIES_SUCCESS,
    GET_RECENT_ENTITIES_FAILURE,
    INITIAL_LOAD,
    INITIAL_LOAD_SUCCESS,
    INITIAL_LOAD_FAILURE,
    UPDATE_ENTITY,
    UPDATE_ENTITY_SUCCESS,
    UPDATE_ENTITY_FAILURE,
    CREATE_DATASPACE,
    CREATE_DATASPACE_SUCCESS,
    CREATE_DATASPACE_FAILURE,
    ADD_MEMBERS,
    ADD_MEMBERS_SUCCESS,
    ADD_MEMBERS_FAILURE,
    GET_ALL_COLLABORATORS,
    GET_ALL_COLLABORATORS_SUCCESS,
    GET_ALL_COLLABORATORS_FAILURE,
    SET_DATASPACE,
    REMOVE_CHILD_EID,
    REMOVE_CHILD_EID_FAILURE,
    REMOVE_CHILD_EID_SUCCESS,
    SET_ENTITIES_SUCCESS,
    SET_ENTITIES_FAILURE,
    CREATE_SCHEMA_FROM_OBJECT,
    CREATE_SCHEMA_FROM_OBJECT_SUCCESS,
    CREATE_SCHEMA_FROM_OBJECT_FAILURE,
    BULK_CREATE_ENTITIES,
    BULK_CREATE_ENTITIES_SUCCESS,
    BULK_CREATE_ENTITIES_FAILURE,
    BULK_UPDATE_ENTITIES,
    BULK_UPDATE_ENTITIES_SUCCESS,
    BULK_UPDATE_ENTITIES_FAILURE,
    BULK_MOVE_ENTITIES,
    BULK_MOVE_ENTITIES_SUCCESS,
    BULK_MOVE_ENTITIES_FAILURE,
    BULK_GROUP_ENTITIES,
    BULK_GROUP_ENTITIES_SUCCESS,
    BULK_GROUP_ENTITIES_FAILURE,
    BULK_REMOVE_CHILD_EIDS,
    BULK_REMOVE_CHILD_EIDS_SUCCESS,
    BULK_REMOVE_CHILD_EIDS_FAILURE,
    PRESET_ENTITIES,
    UNDO_PRESET_ENTITIES,
    SAVE_DRAFTS_SUCCESS,
    SAVE_DRAFTS,
    SAVE_DRAFTS_FAILURE,
    RESTORE_ENTITIES_FROM_TRASH_SUCCESS,
    RESTORE_ENTITIES_FROM_TRASH,
    RESTORE_ENTITIES_FROM_TRASH_FAILURE,
    SET_LOADING_IMAGES_DATA_URL,
    SAVE_DRIVE_FILE_TO_DATASPACE,
    SAVE_DRIVE_FILE_TO_DATASPACE_SUCCESS,
    SAVE_DRIVE_FILE_TO_DATASPACE_FAILURE,
    UPDATE_USER_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_FAILURE,
    UPDATE_ENTITIES_PUBLIC_STATE,
    UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS,
    UPDATE_ENTITIES_PUBLIC_STATE_FAILURE,
    HARD_DELETE_ENTITIES,
    HARD_DELETE_ENTITIES_SUCCESS,
    HARD_DELETE_ENTITIES_FAILURE,
    EMPTY_TRASH,
    EMPTY_TRASH_FAILURE,
    GET_PUBLIC_ENTITY_DESCENDANTS,
    GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE,
    GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS,
    PRESET_CONDENSED_ENTITIES,
    UNDO_PRESET_CONDENSED_ENTITIES,
    DELETE_ENTITIES_WITHOUT_READ_ACCESS,
    ADD_APP,
    ADD_APP_SUCCESS,
    ADD_APP_FAILURE,
    REMOVE_APP,
    REMOVE_APP_FAILURE,
    REMOVE_APP_SUCCESS,
} from '../actionTypes';
import { EntitiesState, EntityWithId } from '../types';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { getNextAuthSession } from 'integrations/helpers';

let analytics;
const juneKey = process.env.NEXT_PUBLIC_JUNE_WRITE_KEY;

if (NODE_ENV !== 'development' && juneKey && typeof window !== 'undefined') {
    AnalyticsBrowser.load({
        writeKey: juneKey,
    }).then(([resolvedAnalytics]) => {
        analytics = resolvedAnalytics;
    });
}
const session: any = getNextAuthSession();

export const setEntitiesState = (state: EntitiesState): ReduxAction => {
    return {
        type: SET_ENTITIES_STATE,
        payload: { state },
    };
};

export const setEntities = (entities: Entity[], debugMessage?: string): ReduxAction => {
    return {
        type: SET_ENTITIES,
        payload: { entities },
        debugMessage,
    };
};

export const setEntitiesSuccess = (entities: Entity[], rebuildEntityList?: boolean, userId?: string): ReduxAction => {
    return {
        type: SET_ENTITIES_SUCCESS,
        payload: { entities, rebuildEntityList, userId },
    };
};

export const setEntitiesFailure = (error: unknown): ReduxAction => {
    return {
        type: SET_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const presetEntities = (entities: Entity[], rebuildEntityList?: boolean, debugMessage?: string): ReduxAction => {
    return {
        type: PRESET_ENTITIES,
        payload: { entities, rebuildEntityList },
        debugMessage,
    };
};

export const undoPresetEntities = (entities: Entity[], rebuildEntityList?: boolean): ReduxAction => {
    return {
        type: UNDO_PRESET_ENTITIES,
        payload: { entities, rebuildEntityList },
    };
};

export const presetCondensedEntities = (
    condensedEntities: CondensedEntity[],
    rebuildEntityList?: boolean,
    debugMessage?: string,
): ReduxAction => {
    return {
        type: PRESET_CONDENSED_ENTITIES,
        payload: { condensedEntities, rebuildEntityList },
        debugMessage,
    };
};

export const undoPresetCondensedEntities = (
    condensedEntities: CondensedEntity[],
    rebuildEntityList?: boolean,
    debugMessage?: string,
): ReduxAction => {
    return {
        type: UNDO_PRESET_CONDENSED_ENTITIES,
        payload: { condensedEntities, rebuildEntityList },
        debugMessage,
    };
};

export const createEntity = (
    params: CreateEntityParams,
    callback?: (res: EntityWithId) => void,
    fallback?: () => void,
): ReduxAction => {
    analytics?.track(`Create ${params?.type} Entity`, { userId: session?.id });
    return {
        type: CREATE_ENTITY,
        payload: { ...params, callback, fallback },
    };
};

export const createEntitySuccess = (results: object, rebuildEntityList?: boolean, userId?: string): ReduxAction => {
    return {
        type: CREATE_ENTITY_SUCCESS,
        payload: { results, rebuildEntityList, userId },
    };
};

export const createEntityFailure = (error: unknown): ReduxAction => {
    return {
        type: CREATE_ENTITY_FAILURE,
        payload: { error },
    };
};

export const createSchemaFromObject = (blobId: string, blobEntity: Entity, schemaName: string): ReduxAction => {
    analytics?.track('Create Schema', { userId: session?.id });
    return {
        type: CREATE_SCHEMA_FROM_OBJECT,
        payload: { blobId, blobEntity, schemaName },
    };
};

export const createSchemaFromObjectSuccess = (entities: Entity[]): ReduxAction => {
    return {
        type: CREATE_SCHEMA_FROM_OBJECT_SUCCESS,
        payload: { entities },
    };
};

export const createSchemaFromObjectFailure = (error): ReduxAction => {
    return {
        type: CREATE_SCHEMA_FROM_OBJECT_FAILURE,
        payload: { error },
    };
};

export const getEntity = (id: string, debugMessage?: string): ReduxAction => {
    return {
        type: GET_ENTITY,
        payload: { id },
        debugMessage,
    };
};

export const getEntitySuccess = (entities: Entity[], invalidIds?: string[]): ReduxAction => {
    return {
        type: GET_ENTITY_SUCCESS,
        payload: { entities, invalidIds },
    };
};

export const getEntityFailure = (error: unknown, id: string): ReduxAction => {
    return {
        type: GET_ENTITY_FAILURE,
        payload: { error, id },
    };
};

export const getEntities = (ids?: string[], debugMessage?: string): ReduxAction => {
    return {
        type: GET_ENTITIES,
        payload: { ids },
        debugMessage,
    };
};

export const restoreEntitiesFromTrash = (entities: EntityIdentity[]): ReduxAction<RestoreEntitiesFromTrashPayload> => {
    analytics?.track('Restore Trash', { userId: session?.id });
    return {
        type: RESTORE_ENTITIES_FROM_TRASH,
        payload: { entityIdentities: entities },
    };
};

export const restoreEntitiesFromTrashSuccess = (
    entities: Entity[],
    rebuildEntityList?: boolean,
    userId?: string,
): ReduxAction => {
    return {
        type: RESTORE_ENTITIES_FROM_TRASH_SUCCESS,
        payload: { entities, rebuildEntityList, userId },
    };
};

export const restoreEntitiesFromTrashFailure = (error: unknown): ReduxAction => {
    return {
        type: RESTORE_ENTITIES_FROM_TRASH_FAILURE,
        payload: { error },
    };
};

export const getEntitiesSuccess = (entities: Entity[], invalidIds?: string[]): ReduxAction => {
    return {
        type: GET_ENTITIES_SUCCESS,
        payload: { entities, invalidIds },
    };
};

export const getEntitiesFailure = (error: unknown, ids: string[]): ReduxAction => {
    return {
        type: GET_ENTITIES_FAILURE,
        payload: { error, ids },
    };
};

export const getRecentEntities = ({ dataspaceId, limit = 50 }): ReduxAction => {
    return {
        type: GET_RECENT_ENTITIES,
        payload: { dataspaceId, limit },
    };
};

export const getRecentEntitiesSuccess = (entities: RawCondensedEntity[]): ReduxAction => {
    return {
        type: GET_RECENT_ENTITIES_SUCCESS,
        payload: { entities },
    };
};

export const getRecentEntitiesFailure = (error: unknown): ReduxAction => {
    return {
        type: GET_RECENT_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const initialLoad = (): ReduxAction => {
    return {
        type: INITIAL_LOAD,
        payload: {},
    };
};

export const initialLoadSuccess = (
    finders: Entity[],
    dataspaces: Entity[],
    idsToFetch: string[],
    condensedEntities: Entity[],
    draftCondensedEntities: Entity[],
    sharedCondensedEntities: Entity[],
    additionalTypes: Entity[],
    dsid: string,
    userId?: string,
): ReduxAction => {
    return {
        type: INITIAL_LOAD_SUCCESS,
        payload: {
            finders,
            dataspaces,
            idsToFetch,
            condensedEntities,
            draftCondensedEntities,
            sharedCondensedEntities,
            additionalTypes,
            dsid,
            userId,
        },
    };
};

export const initialLoadFailure = (error: unknown): ReduxAction => {
    return {
        type: INITIAL_LOAD_FAILURE,
        payload: { error },
    };
};

export const updateEntity = (
    id: string,
    updatedEntity: Entity,
    debugMessage?: string,
    callback?: (entity: any) => void,
    fallback?: (entity: any) => void,
): ReduxAction => {
    return {
        type: UPDATE_ENTITY,
        payload: { id, updatedEntity, callback, fallback },
        debugMessage,
    };
};

export const updateEntitySuccess = (entities: Entity[]): ReduxAction => {
    return {
        type: UPDATE_ENTITY_SUCCESS,
        payload: { entities },
    };
};

export const updateEntityFailure = (error: unknown): ReduxAction => {
    return {
        type: UPDATE_ENTITY_FAILURE,
        payload: { error },
    };
};

export const updateEntityPublicState = (id: string, publicState: boolean): ReduxAction => {
    analytics?.track('Make Entity Public', { userId: session?.id });
    return {
        type: UPDATE_ENTITIES_PUBLIC_STATE,
        payload: { id, publicState },
    };
};

export const updateEntityPublicStateSuccess = (entities: Entity[]): ReduxAction => {
    return {
        type: UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS,
        payload: { entities },
    };
};

export const updateEntityPublicStateFailure = (error: unknown): ReduxAction => {
    return {
        type: UPDATE_ENTITIES_PUBLIC_STATE_FAILURE,
        payload: { error },
    };
};

export const updateUser = (id: string, updatedUser: Entity, callback?: (entity: any) => void): ReduxAction => {
    return {
        type: UPDATE_USER,
        payload: { id, updatedUser, callback },
    };
};

export const updateUserSuccess = (users: Entity[]): ReduxAction => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: { users },
    };
};

export const updateUserFailure = (error: unknown): ReduxAction => {
    return {
        type: UPDATE_USER_FAILURE,
        payload: { error },
    };
};

export const hardDeleteEntities = (entities: EntityIdentity[]): ReduxAction => {
    analytics?.track('Hard Delete Entity', { userId: session?.id });
    return {
        type: HARD_DELETE_ENTITIES,
        payload: { entities },
    };
};

export const hardDeleteEntitiesSuccess = (
    deletedEids: string[],
    parentEntities?: Entity[],
    userId?: string,
): ReduxAction => {
    return {
        type: HARD_DELETE_ENTITIES_SUCCESS,
        payload: { deletedEids, parentEntities, userId },
    };
};

export const hardDeleteEntitiesFailure = (error: unknown): ReduxAction => {
    return {
        type: HARD_DELETE_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const emptyTrash = (): ReduxAction => {
    analytics?.track('Empty Trash', { userId: session?.id });
    return {
        type: EMPTY_TRASH,
        payload: {},
    };
};

export const emptyTrashFailure = (error: unknown): ReduxAction => {
    return {
        type: EMPTY_TRASH_FAILURE,
        payload: { error },
    };
};

export const createDataspace = (values, callback?: (newDataspace: any) => void): ReduxAction => {
    analytics?.track('Create Dataspace', { userId: session?.id });
    return {
        type: CREATE_DATASPACE,
        payload: { ...values, callback },
    };
};

export const createDataspaceSuccess = (results: object, userId: string): ReduxAction => {
    return {
        type: CREATE_DATASPACE_SUCCESS,
        payload: { results, userId },
    };
};

export const createDataspaceFailure = (error: unknown): ReduxAction => {
    return {
        type: CREATE_DATASPACE_FAILURE,
        payload: { error },
    };
};

export const addMembers = (emails: string[], callback?: () => void): ReduxAction => {
    analytics?.track('Add Members', { userId: session?.id });
    return {
        type: ADD_MEMBERS,
        payload: { emails, callback },
    };
};

export const addMembersRole = (
    emails: string[],
    role: string,
    dataspaceId: string,
    callback?: () => void,
): ReduxAction => {
    return {
        type: ADD_MEMBERS,
        payload: { emails, role, callback },
    };
};

export const addMembersSuccess = (entities?: Entity[]): ReduxAction => {
    return {
        type: ADD_MEMBERS_SUCCESS,
        payload: { entities },
    };
};

export const addMembersFailure = (error: unknown): ReduxAction => {
    return {
        type: ADD_MEMBERS_FAILURE,
        payload: { error },
    };
};

export const updateMembersRole = (
    emails: string[],
    role: string,
    dataspaceId: string,
    callback?: () => void,
): ReduxAction => {
    return {
        type: ADD_MEMBERS,
        payload: { emails, role, dataspaceId, callback },
    };
};

export const getAllCollaborators = (): ReduxAction => {
    return {
        type: GET_ALL_COLLABORATORS,
        payload: {},
    };
};

export const getAllCollaboratorsSuccess = (entities: Entity[]): ReduxAction => {
    return {
        type: GET_ALL_COLLABORATORS_SUCCESS,
        payload: { entities },
    };
};

export const getAllCollaboratorsFailure = (error: unknown): ReduxAction => {
    return {
        type: GET_ALL_COLLABORATORS_FAILURE,
        payload: { error },
    };
};

export const setDataspace = (dsid: string, isNotes?: boolean): ReduxAction => {
    return {
        type: SET_DATASPACE,
        payload: { dsid, isNotes },
    };
};

// DEPRECATED - use bulkRemoveChildEids instead
export const removeChildEid = (parentId: string, childEid: string, callback?: () => void): ReduxAction => {
    analytics?.track('Delete Entity', { userId: session?.id });
    return {
        type: REMOVE_CHILD_EID,
        payload: { parentId, childEid, callback },
    };
};

export const removeChildEidFailure = (error): ReduxAction => {
    return {
        type: REMOVE_CHILD_EID_FAILURE,
        payload: { error },
    };
};

export const removeChildEidSuccess = (
    entities: Entity[],
    tabFinderId: string,
    rebuildEntityList?: boolean,
): ReduxAction => {
    return {
        type: REMOVE_CHILD_EID_SUCCESS,
        payload: { entities, tabFinderId, rebuildEntityList },
    };
};

//
// Bulk actions
//

type BulkCreateEntitiesActionType = {
    newEntities: Entity[];
    parentId: string;
    index?: number;
    callback?: () => void;
    fallback?: () => void;
    isPasted?: boolean;
    allEntitiesIncluded?: boolean;
    skipBrowseOnCreate?: boolean;
};

export const bulkCreateEntities = ({
    newEntities,
    parentId,
    index,
    callback,
    fallback,
    isPasted,
    allEntitiesIncluded,
    skipBrowseOnCreate,
}: BulkCreateEntitiesActionType): ReduxAction => {
    analytics?.track('Bulk Create Entities', { userId: session?.id });
    return {
        type: BULK_CREATE_ENTITIES,
        payload: {
            newEntities,
            parentId,
            index,
            callback,
            fallback,
            isPasted,
            allEntitiesIncluded,
            skipBrowseOnCreate,
        },
    };
};

export const bulkCreateEntitiesSuccess = (
    entities: Entity[],
    selectedEntities: EntityIdentity[],
    rebuildEntityList?: boolean,
    userId?: string,
): ReduxAction => {
    return {
        type: BULK_CREATE_ENTITIES_SUCCESS,
        payload: { entities, selectedEntities, rebuildEntityList, userId },
    };
};

export const bulkCreateEntitiesFailure = (error): ReduxAction => {
    return {
        type: BULK_CREATE_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const bulkUpdateEntities = (entities: Entity[]): ReduxAction => {
    return {
        type: BULK_UPDATE_ENTITIES,
        payload: { entities },
    };
};

export const bulkUpdateEntitiesSuccess = (entities: Entity[]): ReduxAction => {
    return {
        type: BULK_UPDATE_ENTITIES_SUCCESS,
        payload: { entities },
    };
};

export const bulkUpdateEntitiesFailure = (error): ReduxAction => {
    return {
        type: BULK_UPDATE_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const bulkMoveEntities = (destinationEid: string, entities: EntityIdentity[], index: number): ReduxAction => {
    analytics?.track('Move Entity', { userId: session?.id });
    return {
        type: BULK_MOVE_ENTITIES,
        payload: { destinationEid, entities, index },
    };
};

export const bulkMoveEntitiesSuccess = (
    entities: Entity[],
    destinationEid: string,
    rebuildEntityList?: boolean,
): ReduxAction => {
    return {
        type: BULK_MOVE_ENTITIES_SUCCESS,
        payload: { entities, destinationEid, rebuildEntityList },
    };
};

export const bulkMoveEntitiesFailure = (error): ReduxAction => {
    return {
        type: BULK_MOVE_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const bulkGroupEntities = (
    selectedEntities: EntityIdentity[],
    contextParentId: string,
    contextId: string,
): ReduxAction => {
    analytics?.track('Group Entities', { userId: session?.id });
    return {
        type: BULK_GROUP_ENTITIES,
        payload: { selectedEntities, contextParentId, contextId },
    };
};

export const bulkGroupEntitiesSuccess = (
    entities: Entity[],
    targetId: string,
    rebuildEntityList?: boolean,
): ReduxAction => {
    return {
        type: BULK_GROUP_ENTITIES_SUCCESS,
        payload: { entities, targetId, rebuildEntityList },
    };
};

export const bulkGroupEntitiesFailure = (error): ReduxAction => {
    return {
        type: BULK_GROUP_ENTITIES_FAILURE,
        payload: { error },
    };
};

export const bulkRemoveChildEids = (selectedEntities: EntityIdentity[]): ReduxAction => {
    analytics?.track('Bulk Delete Entities', { userId: session?.id });
    return {
        type: BULK_REMOVE_CHILD_EIDS,
        payload: { selectedEntities },
    };
};

export const bulkRemoveChildEidsSuccess = (
    entities: Entity[],
    tabFinderId: string,
    rebuildEntityList?: boolean,
): ReduxAction => {
    return {
        type: BULK_REMOVE_CHILD_EIDS_SUCCESS,
        payload: { entities, tabFinderId, rebuildEntityList },
    };
};

export const bulkRemoveChildEidsFailure = (error): ReduxAction => {
    return {
        type: BULK_REMOVE_CHILD_EIDS_FAILURE,
        payload: { error },
    };
};

export const saveDrafts = (ids, dsid): ReduxAction => {
    analytics?.track('Save Draft', { userId: session?.id });
    return {
        type: SAVE_DRAFTS,
        payload: { ids, dsid },
    };
};

export const saveDraftsSuccess = (entities: Entity[], rebuildEntityList?: boolean, userId?: string): ReduxAction => {
    return {
        type: SAVE_DRAFTS_SUCCESS,
        payload: { entities, rebuildEntityList, userId },
    };
};

export const saveDraftsFailure = (error): ReduxAction => {
    return {
        type: SAVE_DRAFTS_FAILURE,
        payload: { error },
    };
};

export const saveDriveFileToDataspace = (entity, dsid): ReduxAction => {
    analytics?.track('Save Drive File To Dataspace', { userId: session?.id });
    return {
        type: SAVE_DRIVE_FILE_TO_DATASPACE,
        payload: { entity, dsid },
    };
};

export const saveDriveFileToDataspaceSuccess = (entities: Entity[], rebuildEntityList?: boolean): ReduxAction => {
    return {
        type: SAVE_DRIVE_FILE_TO_DATASPACE_SUCCESS,
        payload: { entities, rebuildEntityList },
    };
};

export const saveDriveFileToDataspaceFailure = (error): ReduxAction => {
    return {
        type: SAVE_DRIVE_FILE_TO_DATASPACE_FAILURE,
        payload: { error },
    };
};

export const setImageDataUrlsWithIds = (imageDataUrlsWithIds): ReduxAction => {
    return {
        type: SET_LOADING_IMAGES_DATA_URL,
        payload: { imageDataUrlsWithIds },
    };
};

export const restoreDeletedEntities = (entities: EntityIdentity[]): ReduxAction => {
    analytics?.track('Restore Deleted Entities', { userId: session?.id });
    return {
        type: RESTORE_ENTITIES_FROM_TRASH,
        payload: { entities },
    };
};

export const restoreDeletedEntitiesSuccess = (
    entities: Entity[],
    rebuildEntityList?: boolean,
    userId?: string,
): ReduxAction => {
    return {
        type: RESTORE_ENTITIES_FROM_TRASH_SUCCESS,
        payload: { entities, rebuildEntityList, userId },
    };
};

export const restoreDeletedEntitiesFailure = (error: unknown): ReduxAction => {
    return {
        type: RESTORE_ENTITIES_FROM_TRASH_FAILURE,
        payload: { error },
    };
};

export const getPublicEntityDescendants = (dsid: string, eid: string): ReduxAction => {
    return {
        type: GET_PUBLIC_ENTITY_DESCENDANTS,
        payload: { dsid, eid },
    };
};

export const getPublicEntityDescendantsSuccess = (entities: Entity[], publicEntityId: string): ReduxAction => {
    return {
        type: GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS,
        payload: { entities, publicEntityId },
    };
};

export const getPublicEntityDescendantsFailure = (error: unknown): ReduxAction => {
    return {
        type: GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE,
        payload: { error },
    };
};

export const deleteEntitiesWithoutReadAccess = (eids: string[]): ReduxAction => {
    return {
        type: DELETE_ENTITIES_WITHOUT_READ_ACCESS,
        payload: { entitiesWithoutReadAccess: eids },
    };
};

export const addAppToDataspace = (dsid: string, appEntity: Entity, callback?: () => void): ReduxAction => {
    return {
        type: ADD_APP,
        payload: { dsid, appEntity, callback },
    };
};

export const addAppToDataspaceSuccess = (entities: Entity[], rebuildEntityList?: boolean): ReduxAction => {
    return {
        type: ADD_APP_SUCCESS,
        payload: { entities, rebuildEntityList },
    };
};

export const addAppToDataspaceFailure = (error: unknown): ReduxAction => {
    return {
        type: ADD_APP_FAILURE,
        payload: { error },
    };
};

export const removeAppFromDataspace = (dsid: string, appEntityId: string, callback?: () => void): ReduxAction => {
    return {
        type: REMOVE_APP,
        payload: { dsid, appEntityId, callback },
    };
};

export const removeAppFromDataspaceSuccess = (entities: Entity[], rebuildEntityList?: boolean): ReduxAction => {
    return {
        type: REMOVE_APP_SUCCESS,
        payload: { entities, rebuildEntityList },
    };
};

export const removeAppFromDataspaceFailure = (error: unknown): ReduxAction => {
    return {
        type: REMOVE_APP_FAILURE,
        payload: { error },
    };
};
