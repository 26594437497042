export default function DuplicateIcon(): JSX.Element {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 15.17h12v-1h-12v1Zm13.5-1.5v-9.3h-1v9.3h1Zm-1.5-10.8h-12v1h12v-1Zm1.5 1.5c0-.83-.67-1.5-1.5-1.5v1c.28 0 .5.22.5.5h1Zm-1.5 10.8c.83 0 1.5-.67 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM0 13.67c0 .83.67 1.5 1.5 1.5v-1a.5.5 0 0 1-.5-.5H0Zm1-9.3c0-.28.22-.5.5-.5v-1c-.83 0-1.5.67-1.5 1.5h1ZM.81 2.25v.12h1v-.12h-1Zm12-1.38H2.19v1h10.62v-1Zm1.38 1.5v-.12h-1v.12h1Zm-1.38-.5c.2 0 .38.17.38.38h1c0-.76-.62-1.38-1.38-1.38v1Zm-11 .38c0-.21.17-.38.38-.38v-1C1.43.87.81 1.49.81 2.25h1ZM7 5.37v3.5h1v-3.5H7Zm0 3.5v3.5h1v-3.5H7Zm.5.5H11v-1H7.5v1Zm0-1H4v1h3.5v-1Zm-7.5-4v9.3h1v-9.3H0Z"
                fill="#4D4D4D"
            />
        </svg>
    );
}
