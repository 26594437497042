import { useCallback, useEffect, useMemo, useRef, useState, memo, FunctionComponent } from 'react';
import Tab from './container';
import { useDispatch, useSelector } from 'react-redux';
import {
    getBrowsedInstanceSelector,
    getFinderSelector,
    getSelectedEntitiesSelector,
    getTabsSelector,
} from '@/redux/ui/selectors';
import styles from './styles.module.scss';
import { findIndex, merge, remove } from 'lodash';
import { includesInstance, isEmptyTab, pushToUniqueStack } from '@/utils/helpers';
import { setUI } from '@/redux/ui/actions';
import { UIState } from '@/redux/types';
import { useDrop } from 'react-dnd';
import { LISTITEM, ENTITY, TAB } from '@/dndTypes';
import Button from '@/components/DesignSystem/Button';
import AddMdIcon from '@/icons/AddMdIcon';
import { v4 as uuidv4 } from 'uuid';
import Tooltip from 'components/Tooltip';

const TabsBar: FunctionComponent = () => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const tabsState = useSelector(getTabsSelector) || {};
    const { tabsArray = [] } = tabsState;
    const finder = useSelector(getFinderSelector);
    const [showCloseButtons, setShowCloseButtons] = useState(true);

    // For handleItemClick to access latest state;
    const browsedInstanceRef = useRef(browsedInstance);
    const selectedEntitiesRef = useRef(selectedEntities);
    const tabsStateRef = useRef(tabsState);
    const finderRef = useRef(finder);

    useEffect(() => {
        tabsStateRef.current = tabsState;
    }, [tabsState]);
    useEffect(() => {
        browsedInstanceRef.current = browsedInstance;
    }, [browsedInstance]);
    useEffect(() => {
        finderRef.current = finder;
    }, [finder]);
    useEffect(() => {
        selectedEntitiesRef.current = selectedEntities;
    }, [selectedEntities]);

    const handleItemClick = useCallback((clickedInstance: EntityIdentity, e: any) => {
        // 1 click = set browsed, 2 clicks = save as persisted tab
        const clickType = e.detail == 1 ? 'single' : 'double';
        if (clickType === 'double') {
            const newTabsState = insertTab(
                tabsStateRef.current,
                clickedInstance,
                browsedInstanceRef.current?.id,
                clickType === 'double',
            );
            dispatch(
                setUI({
                    browsedInstance: clickedInstance,
                    selectedEntities: [clickedInstance],
                    finder: merge({}, finderRef.current, { tabs: { [finderRef.current?.id]: newTabsState } }),
                }),
            );
        } else {
            const newTabsHistory = pushToUniqueStack(tabsStateRef.current?.tabsHistory, clickedInstance?.id);
            dispatch(
                setUI({
                    browsedInstance: clickedInstance,
                    selectedEntities: [clickedInstance],
                    finder: merge({}, finderRef.current, {
                        tabs: { [finderRef.current?.id]: { tabsHistory: newTabsHistory } },
                    }),
                }),
            );
        }
    }, []);

    const handleNewTab = useCallback(() => {
        const tabId = 'EMPTY_TAB_' + uuidv4();
        const newTabsState = insertTab(tabsStateRef.current, { id: tabId }, browsedInstanceRef.current?.id, true, true);
        dispatch(
            setUI({
                browsedInstance: { id: tabId },
                selectedEntities: [],
                finder: merge({}, finderRef.current, { tabs: { [finderRef.current?.id]: newTabsState } }),
            }),
        );
    }, []);

    const handleClose = useCallback((clickedInstance: EntityIdentity) => {
        const { tabsArray, tabsHistory, handleBrowsed } = removeTab(
            tabsStateRef.current,
            clickedInstance?.id,
            browsedInstanceRef.current?.id,
        );
        const uiUpdate = {
            finder: {
                ...finderRef.current,
                tabs: {
                    ...finderRef.current?.tabs,
                    [finderRef.current?.id]: {
                        tabsArray,
                        tabsHistory,
                    },
                },
            },
        } as UIState;
        if (handleBrowsed === 'PREVIOUS_TAB') {
            const previousTab =
                tabsArray[findIndex(tabsArray, (tab) => tab.id === tabsHistory[tabsHistory.length - 1])];
            uiUpdate.browsedInstance = previousTab;
            uiUpdate.selectedEntities = [{ id: tabsHistory[tabsHistory.length - 1] }];
        } else if (handleBrowsed === 'CLEAR' || tabsArray.length === 0) {
            uiUpdate.browsedInstance = {};
            uiUpdate.selectedEntities = [];
        }
        dispatch(setUI(uiUpdate));
    }, []);

    useEffect(() => {
        const handleResize = (entries) => {
            if (!Array.isArray(entries) || !entries.length) {
                return;
            }
            const shouldShowClose = entries[0].contentRect.width / tabsArray.length > 150;
            setShowCloseButtons(shouldShowClose);
        };

        const resizeObserver = new ResizeObserver(handleResize);

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }

        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
        };
    }, []);

    const target = { tabsBar: true };
    const _accept = [LISTITEM, ENTITY, TAB];
    const dropFunction = (item: DnDMovedItem, monitor): void => {
        if (!monitor.didDrop() && dropEntities) {
            dropEntities(item, target);
        }
    };

    const dropSettings = () => {
        return {
            accept: _accept,
            drop: dropFunction,
            collect: (monitor) => ({
                itemIsHovered: monitor.isOver({ shallow: true }) && monitor.canDrop(),
            }),
        };
    };

    const [{ itemIsHovered }, drop] = useDrop(dropSettings());

    const dropEntities = useCallback((item: DnDMovedItem, target: DnDMovedItem) => {
        const dropItem = { id: item.eid, parentId: item.parentId };
        const droppedEntities = includesInstance(selectedEntitiesRef.current, dropItem)
            ? selectedEntitiesRef.current
            : [dropItem];

        const { tabsArray, tabsHistory } = dropTabs(tabsStateRef.current, droppedEntities, target);
        dispatch(
            setUI({
                browsedInstance: droppedEntities[0],
                selectedEntities: [droppedEntities[0]],
                finder: {
                    ...finderRef.current,
                    tabs: {
                        ...finderRef.current?.tabs,
                        [finderRef.current?.id]: {
                            tabsArray,
                            tabsHistory,
                        },
                    },
                },
            }),
        );
    }, []);

    drop(ref);

    const renderTabs = useMemo(() => {
        return tabsArray?.map((item) => {
            const isBrowsed = browsedInstance?.id === item.id;
            const isSelected = includesInstance(selectedEntities, { id: item.id });
            return (
                <Tab
                    key={`tab_${item.id}`}
                    eid={item.id}
                    emptyTab={isEmptyTab(item.id)}
                    persisted={item.persisted}
                    isBrowsed={isBrowsed}
                    isSelected={isSelected}
                    handleItemClick={handleItemClick}
                    handleClose={handleClose}
                    dropEntities={dropEntities}
                    disableDragDrop={false}
                    app={item.app}
                    appId={item.appId}
                    isIntegrationItem={item.isIntegrationItem}
                    showClose={showCloseButtons}
                    // lastItem={index == tabsArray.length - 1}
                />
            );
        });
    }, [tabsArray, browsedInstance, showCloseButtons, selectedEntities]);

    return (
        <div ref={ref} id="entity-tabs-bar" className={`${styles.tabsContainer} ${itemIsHovered && styles.dropHover}`}>
            {renderTabs}
            {renderTabs?.length > 0 && (
                <Tooltip
                    key={`new-tab-button`}
                    placement="bottom"
                    appendTo={() => document.body}
                    delay={100}
                    content={
                        <div className={'tooltipTippy tooltipTippyCol'} id={`new-tab-tooltip`}>
                            New tab
                        </div>
                    }
                >
                    <Button
                        style={{ marginLeft: '4px', height: '32px', width: '32px' }}
                        size="m"
                        variant="plain"
                        icon={AddMdIcon}
                        className="controlMenuButton"
                        onClick={handleNewTab}
                    />
                </Tooltip>
            )}
        </div>
    );
};

// You can import this directly into another component, or use the useAddTabToFinder hook
export const insertTab = (
    tabsState: TabsState,
    entityIdentity: EntityIdentity,
    browsedId: string,
    persistTab: boolean,
    addToEnd?: boolean,
    replaceEmptyTab?: boolean,
): TabsState => {
    const [tabsArray, tabsHistory] = [tabsState?.tabsArray, tabsState?.tabsHistory];

    if (!entityIdentity?.id) return tabsState;

    const { id: newId, app, appId, isIntegrationItem } = entityIdentity;

    const newTab = {
        id: newId,
        persisted: persistTab,
        app,
        appId,
        isIntegrationItem,
    };

    if (!tabsHistory && !tabsArray) {
        const newTabsArray = [newTab];
        const newTabsHistory = [newId];
        return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
    }

    const newTabsHistory = pushToUniqueStack(tabsHistory, newId);

    if (newId === browsedId && !persistTab) return { tabsArray, tabsHistory: newTabsHistory };

    const prevTabIndex = findIndex(tabsArray, (tab) => tab.id === newId);
    if (prevTabIndex > -1 && (tabsArray[prevTabIndex].persisted || !persistTab)) {
        return { tabsArray, tabsHistory: newTabsHistory };
    }

    const newTabsArray = [...tabsArray];

    // Five non-trivial scenarios for tabsArray:
    // 1) Persist currently viewed unpersisted tab -> replace current tab
    // 2) Insert empty tab at end or next to currently viewed tab
    // 3) Convert empty tab to entity tab -> replace current tab
    // 4) New unpersisted/persisted tab (currently viewing different unpersisted tab) -> replace current tab
    // 5) New unpersisted/persisted tab (not currently viewing unpersisted tab) -> remove any previous unpersisted tab & add new tab next to current tab

    // 1)
    if (prevTabIndex > -1 && !tabsArray[prevTabIndex].persisted && persistTab) {
        newTabsArray[prevTabIndex].persisted = true;
        return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
    }
    // ---

    let currentIndex = findIndex(tabsArray, (tab) => tab.id === browsedId);

    // 2)
    if (isEmptyTab(newId)) {
        if (addToEnd) {
            newTabsArray.push(newTab);
        } else if (currentIndex > -1) {
            newTabsArray.splice(currentIndex + 1, 0, newTab);
        } else {
            newTabsArray.push(newTab);
        }
        return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
    }

    // 3)
    if (replaceEmptyTab && isEmptyTab(browsedId)) {
        newTabsArray[currentIndex] = newTab;
        remove(newTabsHistory, (id) => id === browsedId);
        return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
    }

    // 4)
    if (currentIndex > -1 && tabsArray[currentIndex].persisted === false) {
        newTabsArray[currentIndex] = newTab;
        remove(newTabsHistory, (id) => id === browsedId);
        return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
    }

    // 5)
    const prevTempTabIndex = findIndex(tabsArray, (tab) => tab.persisted === false);
    if (prevTempTabIndex > -1) {
        remove(newTabsHistory, (id) => id === tabsArray[prevTempTabIndex].id);
        newTabsArray.splice(prevTempTabIndex, 1);
        if (prevTempTabIndex < currentIndex) {
            currentIndex--;
        }
    }
    if (currentIndex > -1) {
        newTabsArray.splice(currentIndex + 1, 0, newTab);
    } else {
        newTabsArray.push(newTab);
    }
    return { tabsArray: newTabsArray, tabsHistory: newTabsHistory };
};

export const removeTab = (
    tabsState: TabsState,
    removedIds: string[] | string,
    browsedId: string,
): { tabsArray: Tab[]; tabsHistory: string[]; handleBrowsed?: string } => {
    if (!removedIds || removedIds.length < 1) return tabsState;
    const { tabsArray, tabsHistory } = tabsState;
    const ids = removedIds instanceof Array ? removedIds : [removedIds];

    const newTabsArray = tabsArray.filter((tab) => !ids.includes(tab.id));
    let newTabsHistory = tabsHistory.filter((stackId) => !ids.includes(stackId));

    let handleBrowsed = 'NO_CHANGE';
    if (ids.includes(browsedId)) {
        if (newTabsArray.length > 0 && newTabsHistory.length > 0) {
            handleBrowsed = 'PREVIOUS_TAB';
        } else {
            handleBrowsed = 'CLEAR';
        }
    }

    //Failsafe
    if (newTabsArray.length === 0) newTabsHistory = [];

    return { tabsArray: newTabsArray, tabsHistory: newTabsHistory, handleBrowsed };
};

export const deleteTabs = (
    allTabsState: AllTabsState,
    removedIds: string[] | string,
    browsedId: string,
): { allTabsState: AllTabsState; handleBrowsedMap: { [key: string]: string } } => {
    const ids = removedIds instanceof Array ? removedIds : [removedIds];

    const newAllTabsState: AllTabsState = {};
    const handleBrowsedMap: { [key: string]: string } = {};
    for (const key in allTabsState) {
        if (allTabsState.hasOwnProperty(key)) {
            const { handleBrowsed, ...newTabsState } = removeTab(allTabsState[key], ids, browsedId);
            newAllTabsState[key] = newTabsState;
            handleBrowsedMap[key] = handleBrowsed;
        }
    }

    return { allTabsState: newAllTabsState, handleBrowsedMap };
};

const dropTabs = (tabsState: TabsState, droppedEntities: EntityIdentity[], target: DnDMovedItem): TabsState => {
    const { tabsArray, tabsHistory } = tabsState;

    const newEntities = droppedEntities.filter((entity) => !tabsHistory.some((id) => id === entity.id));
    const sourceIndex = tabsArray.findIndex((tab) => tab.id === droppedEntities[0].id);
    const targetOriginalIndex = tabsArray.findIndex((tab) => tab.id === target.eid);

    const newTabsArray = tabsArray.filter((tab) => !droppedEntities.some((entity) => entity.id === tab.id));

    // Weird stuff at the end is to put the dropped tab after the target if the dragged tab was a currently viewed tab that was originally to the left of the target.
    const targetIndex = target.tabsBar
        ? newTabsArray.length
        : newTabsArray.findIndex((tab) => tab.id === target.eid) +
          (droppedEntities.length === 1 && sourceIndex > -1 && sourceIndex < targetOriginalIndex ? 1 : 0);
    const entitiesToInsert = droppedEntities.map((entity) => ({ id: entity.id, persisted: true }));
    newTabsArray.splice(targetIndex, 0, ...entitiesToInsert);

    const newEntityIds = newEntities.map((entity) => entity.id);
    const newTabsHistory = pushToUniqueStack(tabsHistory, [...newEntityIds, droppedEntities[0].id]);

    return {
        tabsArray: newTabsArray,
        tabsHistory: newTabsHistory,
    };
};

export default memo(TabsBar);
