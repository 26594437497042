import genericEntitySkeleton from 'components/entities/entitySkeleton';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import gqlRequest from 'redux/api';
import { setEntities, updateUser } from 'redux/entities/actions';
import { getAllExpandedHListItemsSelector, getFinderEntityByDsidSelector, getFinderSelector } from 'redux/ui/selectors';
import { getUserSelector } from 'redux/user/selectors';
import { cloneEntity, getExpandedItems } from 'utils/helpers';
import {
    ADD_INTEGRATION_ITEM_TO_DATASPACE,
    AUTHORIZE_INTEGRATION,
    BULK_INSERT_ENTITIES,
    CREATE_INTEGRATION_ITEM,
    GET_CACHED_INTEGRATION_DATA,
    GET_INTEGRATION_ITEMS,
    IMPORT_INTEGRATION_FILES_TO_DATASPACE,
    REVOKE_INTEGRATION,
    UPDATE_ENTITY,
} from '../actionTypes';
import {
    addIntegrationItemToDataspace,
    addIntegrationItemToDataspaceFailure,
    addIntegrationItemToDataspaceSuccess,
    authorizeIntegration,
    authorizeIntegrationFailure,
    authorizeIntegrationSuccess,
    createIntegrationFailure,
    getCachedIntegrationDataFailure,
    getCachedIntegrationDataSuccess,
    getIntegrationItems,
    getIntegrationItemsFailure,
    getIntegrationItemsSuccess,
    revokeIntegrationAccountFailure,
    revokeIntegrationAccountSuccess,
} from './actions';
import { v4 as uuidv4 } from 'uuid';
import typeInfo from 'components/entities/typeInfo';
import { removeToast, setBrowsed, toast } from 'redux/ui/actions';
import { MessageType } from 'types/toasts';
import { INTEGRATIONS } from '../../integrations/constants';
import { getCondensedEntitySelector, getDsidSelector } from '@/redux/entities/selectors';
import { getIntegrationItemsSelector } from './selectors';
import { readIntegrationsFromIndexedDB } from '../../integrations/helpers';

export function* authorizeIntegrationSaga(action) {
    const { integrationAccount } = action.payload;
    try {
        const uniqueIntegrationId = INTEGRATIONS[integrationAccount.app]?.getEntityId(integrationAccount);

        const { entity: userEntity, id: userId } = yield select(getUserSelector);
        const newUserEntity: Entity = cloneEntity(userEntity);
        newUserEntity.integrationAccounts = newUserEntity.integrationAccounts || {};
        newUserEntity.integrationAccounts[uniqueIntegrationId] = integrationAccount;

        yield put(updateUser(userId, newUserEntity));
        yield put(authorizeIntegrationSuccess(integrationAccount));
    } catch (error) {
        console.error(`authorizeIntegrationSaga ERROR: `, error);
        yield put(authorizeIntegrationFailure(integrationAccount));
    }
}

export function* getIntegrationItemsSaga(action) {
    const { app, appId } = action.payload;
    const props = action.payload.props || {};
    try {
        const { entity: userEntity } = yield select(getUserSelector);
        const expandedHListItems = yield select(getAllExpandedHListItemsSelector);
        const integrationItems = yield select(getIntegrationItemsSelector(app, appId));
        const integration = userEntity?.integrationAccounts[INTEGRATIONS[app]?.getEntityId({ app, appId })];

        if (!integration) throw { msg: `${app}: ${appId} not added yet` };

        const { tokens } = integration;
        if (app === 'localFiles' && !props?.paths) {
            props['paths'] = getExpandedItems(expandedHListItems, integrationItems) || [];
        }

        const appResponse: ServerResponseWithItemsData = yield call(INTEGRATIONS[app].getItems, tokens, props);
        if (appResponse.ok) {
            yield put(getIntegrationItemsSuccess(appResponse.itemsData, app, appId, props));
            if (appResponse?.accountData) {
                yield put(authorizeIntegration(appResponse.accountData));
            }
        } else {
            throw appResponse;
        }
    } catch (error) {
        console.error(`getIntegrationItemsSaga ERROR: `, error);
        yield put(getIntegrationItemsFailure(error));
        yield put(
            toast({
                text:
                    error?.data?.msg ||
                    error?.data?.message ||
                    error?.msg ||
                    error?.message ||
                    'Unknown error during fetching integration items',
                msgType: MessageType.WARNING,
            }),
        );
    }
}

export function* revokeIntegrationSaga(action) {
    const { app, appId } = action.payload;

    const { entity: userEntity, id: userId } = yield select(getUserSelector);
    const integration = userEntity?.integrationAccounts[INTEGRATIONS[app]?.getEntityId({ app, appId })];

    if (!integration) throw { msg: `${app}: ${appId} not added yet` };

    const { tokens } = integration;

    try {
        if (tokens) {
            yield put(toast({ text: `Revoking ${app}: ${appId}`, msgType: MessageType.LOADING }));
            const appResponse = yield call(INTEGRATIONS[app].revoke, tokens);

            yield put(removeToast());
            if (appResponse?.ok) {
                yield put(revokeIntegrationAccountSuccess(app, appId));
                yield put(toast({ text: `Revoked access to ${app}: ${appId}`, msgType: MessageType.SUCCESS }));
            } else {
                throw appResponse;
            }
        } else {
            throw `${app}_${appId} account not found`;
        }
    } catch (error) {
        yield put(revokeIntegrationAccountFailure(error));
        yield put(
            toast({
                text: `Revoked access to ${app}: ${appId}, however there was an error from integration APIs`,
                msgType: MessageType.WARNING,
            }),
        );
    }

    const newUserEntity: Entity = cloneEntity(userEntity);
    delete newUserEntity.integrationAccounts[`${app}_${appId}`];
    yield put(updateUser(userId, newUserEntity));
}

export function* addToDataspaceSaga(action) {
    const { integrationItem: item, dsid, parentId, updateBrowsed } = action.payload;
    let integrationItem = item;
    if (item.app === 'localFiles') {
        yield put(getIntegrationItems(item.app, item.appId, { paths: [item.id] }));
        const integrationItems = yield select(getIntegrationItemsSelector(item.app, item.appId));
        integrationItem = integrationItems[item.id];
    }
    const finderEntity = yield select(getFinderEntityByDsidSelector(dsid));
    const parentEntity = yield select(getCondensedEntitySelector(parentId));
    const skeleton = genericEntitySkeleton();

    const user = yield select(getUserSelector);

    const targetParent = parentEntity ? parentEntity : finderEntity;

    try {
        const entities = [];
        const id = uuidv4();
        const newIntegrationItemEntity = {
            ...cloneEntity(skeleton),
            ...typeInfo['IntegrationItem'].skeleton,
            ...cloneEntity(integrationItem),
        } as Entity;
        newIntegrationItemEntity.foreignId = newIntegrationItemEntity.id || newIntegrationItemEntity.key;
        delete newIntegrationItemEntity.id;
        const owner = user?.id;
        entities.push({ id, entity: newIntegrationItemEntity, owned_by: owner, dsid });

        const targetParentId = targetParent.metadata.id;
        const newParentEntity = cloneEntity(targetParent);
        if (newParentEntity.childrenList) {
            newParentEntity.childrenList.push(id);
        } else {
            newParentEntity.childrenList = [id];
        }

        action.queryType = BULK_INSERT_ENTITIES;
        action.payload.nextQueryVariables = { entities, parentId: targetParentId };
        const bulkInsertResponse = yield call(gqlRequest, action);

        action.queryType = UPDATE_ENTITY;
        action.payload.nextQueryVariables = { id: targetParentId, entity: newParentEntity };
        const parentUpdateResponse = yield call(gqlRequest, action);

        yield put(addIntegrationItemToDataspaceSuccess());
        yield put(
            setEntities([
                ...bulkInsertResponse.data.insert_Entities_bulk.returning,
                ...parentUpdateResponse?.data.update_Entities.returning,
            ]),
        );
        yield put(removeToast());
        yield put(toast({ text: `Item was added to dataspace`, msgType: MessageType.SUCCESS }));
        if (updateBrowsed) {
            const { id: tabFinderId } = yield select(getFinderSelector);
            yield put(setBrowsed(id, targetParentId, true, tabFinderId));
        }
    } catch (err) {
        yield put(addIntegrationItemToDataspaceFailure({ error: err }));
        yield put(toast({ text: `Failed to add to dataspace`, msgType: MessageType.WARNING }));
    }
}

export function* createIntegrationItemSaga(action) {
    const { app, appId, fileMetadata, parentId } = action.payload;
    const user = yield select(getUserSelector);
    const dsid = yield select(getDsidSelector);

    yield put(
        toast({
            msgType: MessageType.LOADING,
            text: `Creating new ${app} Entity`,
        }),
    );

    try {
        const integration = user?.entity?.integrationAccounts[INTEGRATIONS[app].getEntityId({ app, appId })];

        if (!integration) throw { msg: `${app}: ${appId} not added yet` };

        const { tokens } = integration;

        const appResponse: ServerResponseWithItemsData = yield call(INTEGRATIONS[app].createItem, tokens, fileMetadata);

        if (appResponse.ok) {
            const newIntegrationItem = { ...appResponse.data.data, app, appId };
            yield put(addIntegrationItemToDataspace(newIntegrationItem, dsid, parentId, true));
            if (appResponse?.accountData) {
                yield put(authorizeIntegration(appResponse.accountData));
            }
        }
    } catch (error) {
        console.error(`createIntegrationItemSaga ERROR: `, error);
        yield put(createIntegrationFailure(error));
        yield put(
            toast({
                text:
                    error?.data?.msg ||
                    error?.data?.message ||
                    error?.msg ||
                    error?.message ||
                    'Unknown error during creating integration item',
                msgType: MessageType.WARNING,
            }),
        );
    }
}

export function* getCachedIntegrationDataSaga(action) {
    const { userId } = action.payload;
    try {
        const cachedUserIntegrationData = yield readIntegrationsFromIndexedDB(userId);
        yield put(getCachedIntegrationDataSuccess(cachedUserIntegrationData));
    } catch (error) {
        console.error(`getCachedIntegrationDataSaga ERROR: `, error);
        yield put(getCachedIntegrationDataFailure(error));
        yield put(
            toast({
                msgType: MessageType.WARNING,
                text: error?.message || 'Error while getting integrations data from cache',
            }),
        );
    }
}

export function* importIntegrationFilesToDataspaceSaga(action) {
    const { ids: importedIdList, app, appId } = action.payload;
    const user = yield select(getUserSelector);
    const dsid = yield select(getDsidSelector);
    const finderEntity = yield select(getFinderEntityByDsidSelector(dsid));
    const integrationItems = yield select(getIntegrationItemsSelector(app, appId));
    const skeleton = genericEntitySkeleton();

    const newEntitiesIdMap = importedIdList.reduce((acc, curr) => {
        acc[curr] = uuidv4();
        return acc;
    }, {});

    try {
        const nestedEntitiesId = [];
        const newEntityList = importedIdList.map((foreignId) => {
            const integrationEntity = integrationItems[foreignId];
            const newChildrenList =
                integrationEntity?.childrenList?.length > 0
                    ? integrationEntity.childrenList
                          .filter((childId) => newEntitiesIdMap[childId])
                          .map((childId) => {
                              nestedEntitiesId.push(childId);
                              return newEntitiesIdMap[childId];
                          })
                    : null;

            const entityType = newChildrenList ? 'HList' : 'IntegrationItem';

            return {
                id: newEntitiesIdMap[foreignId],
                owned_by: user?.id,
                dsid,
                entity: {
                    app,
                    appId,
                    ...cloneEntity(skeleton),
                    ...cloneEntity(integrationEntity),
                    '@type': entityType,
                    childrenList: newChildrenList,
                },
            };
        });

        const topLevelChildren = importedIdList
            .filter((foreignId) => !nestedEntitiesId.includes(foreignId))
            .map((oldId) => newEntitiesIdMap[oldId]);

        const updatedFinderEntity = cloneEntity(finderEntity);
        updatedFinderEntity.childrenList = updatedFinderEntity.childrenList.concat(topLevelChildren);

        action.queryType = BULK_INSERT_ENTITIES;
        action.payload.nextQueryVariables = { entities: newEntityList, parentId: finderEntity?.metadata?.id };
        const bulkInsertResponse = yield call(gqlRequest, action);

        action.queryType = UPDATE_ENTITY;
        action.payload.nextQueryVariables = { id: finderEntity?.metadata?.id, entity: updatedFinderEntity };
        const finderUpdateResponse = yield call(gqlRequest, action);

        yield put(
            setEntities([
                ...bulkInsertResponse.data.insert_Entities_bulk.returning,
                ...finderUpdateResponse?.data.update_Entities.returning,
            ]),
        );

        yield put(toast({ text: 'Successfully imported items to your dataspace', msgType: MessageType.SUCCESS }));
    } catch (error) {
        console.error(`importIntegrationFilesToDataspaceSaga ERROR: `, error);
        yield put(toast({ text: 'Error while importing files to dataspace', msgType: MessageType.ERROR }));
    }
}

function* integrationSaga() {
    yield all([
        takeEvery(AUTHORIZE_INTEGRATION, authorizeIntegrationSaga),
        takeLatest(CREATE_INTEGRATION_ITEM, createIntegrationItemSaga),
        takeLatest(GET_INTEGRATION_ITEMS, getIntegrationItemsSaga),
        takeLatest(REVOKE_INTEGRATION, revokeIntegrationSaga),
        takeLatest(ADD_INTEGRATION_ITEM_TO_DATASPACE, addToDataspaceSaga),
        takeEvery(GET_CACHED_INTEGRATION_DATA, getCachedIntegrationDataSaga),
        takeEvery(IMPORT_INTEGRATION_FILES_TO_DATASPACE, importIntegrationFilesToDataspaceSaga),
    ]);
}

export default integrationSaga;
