import React, { useEffect, useState } from 'react';

type Props = {
    url: string;
};

const Iframely = ({ url }: Props): React.ReactElement => {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [html, setHtml] = useState({
        __html: '<div />',
    });

    useEffect(() => {
        if (url) {
            setError(null);
            setIsLoaded(false);
            fetch('/api/iframely', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ url }),
            })
                .then((res) => res.json())
                .then(
                    (res) => {
                        setIsLoaded(true);
                        if (res.html) {
                            setHtml({ __html: res.html });
                        } else if (res.error) {
                            setError({ code: res.error, message: res.message });
                        }
                    },
                    (error) => {
                        setIsLoaded(true);
                        setError(error);
                    },
                );
        } else {
            setError({ code: 400, message: 'Provide url attribute for the element' });
        }
    }, [url]);

    useEffect(() => {
        !isLoaded && window?.iframely && window?.iframely.load();
    });

    if (error) {
        return (
            <div>
                Error: {error.code} - {error.message}
            </div>
        );
    }

    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return <div dangerouslySetInnerHTML={html} />;
};

export default Iframely;
