export default function GoogleIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.83 8.17984C15.83 7.65317 15.7833 7.15317 15.7033 6.6665H8.17V9.67317H12.4833C12.29 10.6598 11.7233 11.4932 10.8833 12.0598V14.0598H13.4567C14.9633 12.6665 15.83 10.6132 15.83 8.17984Z"
                fill="#4285F4"
            />
            <path
                d="M8.17 16.0002C10.33 16.0002 12.1367 15.2802 13.4567 14.0602L10.8833 12.0602C10.1633 12.5402 9.25 12.8335 8.17 12.8335C6.08333 12.8335 4.31667 11.4269 3.68333 9.52686H1.03V11.5869C2.34333 14.2002 5.04333 16.0002 8.17 16.0002Z"
                fill="#34A853"
            />
            <path
                d="M3.68333 9.52642C3.51667 9.04642 3.43 8.53308 3.43 7.99975C3.43 7.46642 3.52333 6.95309 3.68333 6.47309V4.41309H1.03C0.483331 5.49309 0.169998 6.70642 0.169998 7.99975C0.169998 9.29308 0.483331 10.5064 1.03 11.5864L3.68333 9.52642Z"
                fill="#FBBC05"
            />
            <path
                d="M8.17 3.16667C9.35 3.16667 10.4033 3.57334 11.2367 4.36667L13.5167 2.08667C12.1367 0.793334 10.33 0 8.17 0C5.04333 0 2.34333 1.8 1.03 4.41334L3.68333 6.47334C4.31667 4.57334 6.08333 3.16667 8.17 3.16667Z"
                fill="#EA4335"
            />
        </svg>
    );
}
