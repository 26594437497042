export default function AddToDataSpaceIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.30004 14C2.58205 14 2 13.418 2 12.7V8.65573C1.17103 8.65573 0.499023 9.32774 0.499023 10.1567V12.7C0.499023 14.2469 1.75308 15.501 3.30004 15.501H5.8433C6.67226 15.501 7.34427 14.829 7.34427 14H3.30004ZM14 7.34427C14.829 7.34427 15.501 6.67226 15.501 5.8433V3.30004C15.501 1.75308 14.2469 0.499023 12.7 0.499023H10.1567C9.32774 0.499023 8.65573 1.17103 8.65573 2H12.7C13.418 2 14 2.58205 14 3.30004V7.34427ZM7.34427 2C7.34427 1.17103 6.67226 0.499023 5.8433 0.499023H3.30004C1.75308 0.499023 0.499023 1.75308 0.499023 3.30004V5.8433C0.499023 6.67226 1.17103 7.34427 2 7.34427V3.30004C2 2.58205 2.58205 2 3.30004 2H7.34427Z"
                fill="#333333"
            />
            <path
                d="M12.0539 15.75C12.5571 15.75 13.0319 15.6528 13.4782 15.4583C13.9245 15.2638 14.3185 14.9958 14.6604 14.6543C15.0022 14.3128 15.2681 13.9191 15.458 13.4733C15.6527 13.0274 15.75 12.5531 15.75 12.0503C15.75 11.538 15.655 11.059 15.4651 10.6131C15.2752 10.1672 15.0093 9.77592 14.6675 9.43915C14.3257 9.09764 13.9316 8.83202 13.4853 8.64229C13.039 8.44782 12.5619 8.35059 12.0539 8.35059C11.5458 8.35059 11.0687 8.44782 10.6224 8.64229C10.1761 8.83202 9.78204 9.09764 9.4402 9.43915C9.10311 9.78066 8.83723 10.1744 8.64257 10.6202C8.44792 11.0613 8.35059 11.538 8.35059 12.0503C8.35059 12.5578 8.44792 13.0345 8.64257 13.4804C8.83723 13.9262 9.10311 14.3199 9.4402 14.6614C9.78204 14.9982 10.1761 15.2638 10.6224 15.4583C11.0687 15.6528 11.5458 15.75 12.0539 15.75ZM12.0539 14.4124C11.8972 14.4124 11.7714 14.365 11.6764 14.2701C11.5814 14.1753 11.534 14.0496 11.534 13.893V12.5697H10.2093C10.0574 12.5697 9.9316 12.5222 9.83189 12.4274C9.73694 12.3325 9.68946 12.2068 9.68946 12.0503C9.68946 11.8938 9.73694 11.7681 9.83189 11.6732C9.92685 11.5783 10.0527 11.5309 10.2093 11.5309H11.534V10.2147C11.534 10.0581 11.5814 9.93245 11.6764 9.83758C11.7714 9.74272 11.8972 9.69529 12.0539 9.69529C12.2105 9.69054 12.3363 9.73798 12.4313 9.83758C12.5263 9.93245 12.5737 10.0581 12.5737 10.2147V11.5309H13.8984C14.0503 11.5309 14.1737 11.5783 14.2687 11.6732C14.3684 11.7681 14.4182 11.8938 14.4182 12.0503C14.4182 12.2068 14.3684 12.3325 14.2687 12.4274C14.1737 12.5222 14.0503 12.5697 13.8984 12.5697H12.5737V13.893C12.5737 14.0496 12.5263 14.1753 12.4313 14.2701C12.3363 14.365 12.2105 14.4124 12.0539 14.4124Z"
                fill="white"
            />
            <path
                d="M12.0539 15.75C12.5571 15.75 13.0319 15.6528 13.4782 15.4583C13.9245 15.2638 14.3185 14.9958 14.6604 14.6543C15.0022 14.3128 15.2681 13.9191 15.458 13.4733C15.6527 13.0274 15.75 12.5531 15.75 12.0503C15.75 11.538 15.655 11.059 15.4651 10.6131C15.2752 10.1672 15.0093 9.77592 14.6675 9.43915C14.3257 9.09764 13.9316 8.83202 13.4853 8.64229C13.039 8.44782 12.5619 8.35059 12.0539 8.35059C11.5458 8.35059 11.0687 8.44782 10.6224 8.64229C10.1761 8.83202 9.78204 9.09764 9.4402 9.43915C9.10311 9.78066 8.83723 10.1744 8.64257 10.6202C8.44792 11.0613 8.35059 11.538 8.35059 12.0503C8.35059 12.5578 8.44792 13.0345 8.64257 13.4804C8.83723 13.9262 9.10311 14.3199 9.4402 14.6614C9.78204 14.9982 10.1761 15.2638 10.6224 15.4583C11.0687 15.6528 11.5458 15.75 12.0539 15.75ZM12.0539 14.4124C11.8972 14.4124 11.7714 14.365 11.6764 14.2701C11.5814 14.1753 11.534 14.0496 11.534 13.893V12.5697H10.2093C10.0574 12.5697 9.9316 12.5222 9.83189 12.4274C9.73694 12.3325 9.68946 12.2068 9.68946 12.0503C9.68946 11.8938 9.73694 11.7681 9.83189 11.6732C9.92685 11.5783 10.0527 11.5309 10.2093 11.5309H11.534V10.2147C11.534 10.0581 11.5814 9.93245 11.6764 9.83758C11.7714 9.74272 11.8972 9.69529 12.0539 9.69529C12.2105 9.69054 12.3363 9.73798 12.4313 9.83758C12.5263 9.93245 12.5737 10.0581 12.5737 10.2147V11.5309H13.8984C14.0503 11.5309 14.1737 11.5783 14.2687 11.6732C14.3684 11.7681 14.4182 11.8938 14.4182 12.0503C14.4182 12.2068 14.3684 12.3325 14.2687 12.4274C14.1737 12.5222 14.0503 12.5697 13.8984 12.5697H12.5737V13.893C12.5737 14.0496 12.5263 14.1753 12.4313 14.2701C12.3363 14.365 12.2105 14.4124 12.0539 14.4124Z"
                fill="#333333"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M3.75746 6.93919C3.17167 7.52498 3.17167 8.47473 3.75745 9.06051L6.93944 12.2425C6.94877 12.2518 6.95819 12.261 6.96771 12.27C6.96467 12.1971 6.96315 12.1238 6.96315 12.0503C6.96315 11.3584 7.09555 10.6904 7.37211 10.0627C7.63386 9.46393 7.99496 8.92836 8.45276 8.46456L8.45957 8.45766C8.9261 7.99159 9.46702 7.62677 10.0739 7.36794C10.7009 7.09566 11.3657 6.96323 12.0538 6.96323C12.1263 6.96323 12.1985 6.9647 12.2705 6.96765C12.2614 6.95807 12.2521 6.94858 12.2427 6.93919L9.06076 3.75721C8.47497 3.17142 7.52522 3.17142 6.93944 3.75721L3.75746 6.93919ZM12.6296 8.39428C12.4423 8.36518 12.2503 8.35062 12.0538 8.35062L12.0131 8.35083C11.8218 8.3528 11.6348 8.36865 11.4523 8.39838C11.1647 8.44523 10.8881 8.52654 10.6224 8.64233C10.1761 8.83206 9.782 9.09768 9.44016 9.43919C9.10307 9.7807 8.83719 10.1744 8.64253 10.6202C8.53082 10.8734 8.45117 11.1382 8.40357 11.4148C8.36822 11.6202 8.35054 11.832 8.35054 12.0503C8.35054 12.248 8.36531 12.4409 8.39483 12.6292C8.63875 12.5629 8.86921 12.434 9.06076 12.2425L12.2427 9.06051C12.4344 8.86889 12.5633 8.63832 12.6296 8.39428Z"
                fill="#333333"
            />
        </svg>
    );
}
