import Button from '../../DesignSystem/Button';
import Text from '../../DesignSystem/Text';
import BedrockIcon from '@/icons/BedrockIcon';
import { useDispatch } from 'react-redux';
import { hideGlobalModal } from '@/redux/ui/actions';
import { useRouter } from 'next/router';

const NoAccessMessageModal = (): JSX.Element => {
    const dispatch = useDispatch();
    const router = useRouter();

    const handleClick = () => {
        router.push('/').then(() => dispatch(hideGlobalModal('NoAccessMessageModal')));
    };
    return (
        <div style={{ padding: 20 }}>
            <Text variant="heading" size="h3" weight="bold" align="center">
                No Access
            </Text>
            <Text>You don&#39;t have access to this entity.</Text>
            <Button icon={BedrockIcon} variant="plain" size="s" onClick={handleClick} style={{ margin: '0 auto' }}>
                Bedrock
            </Button>
        </div>
    );
};

export default NoAccessMessageModal;
