import { buildIntegrationItemList, writeIntegrationsToIndexedDB } from 'integrations/helpers';
import { produce } from 'immer';
import {
    ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS,
    AUTHORIZE_INTEGRATION,
    AUTHORIZE_INTEGRATION_FAILURE,
    AUTHORIZE_INTEGRATION_SUCCESS,
    CREATE_INTEGRATION_ITEM,
    CREATE_INTEGRATION_ITEM_FAILURE,
    CREATE_INTEGRATION_ITEM_SUCCESS,
    DELETE_INTEGRATION_ITEM_SUCCESS,
    GET_INTEGRATION_ITEMS,
    GET_INTEGRATION_ITEMS_FAILURE,
    GET_INTEGRATION_ITEMS_SUCCESS,
    REVOKE_INTEGRATION,
    REVOKE_INTEGRATION_FAILURE,
    REVOKE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_ITEM_SUCCESS,
    GET_CACHED_INTEGRATION_DATA_SUCCESS,
    GET_CACHED_INTEGRATION_DATA_FAILURE,
    GET_CACHED_INTEGRATION_DATA,
} from '../actionTypes';
import { INTEGRATIONS_LIST } from '../../integrations/constants';
import { debounce } from '@/utils/helpers';
import { buildUID } from '@/utils/IDManager';

const DEBOUNCE_INTERVAL = 5000;

const syncStateWithIdb = async (userId, state) => {
    return await writeIntegrationsToIndexedDB(userId, JSON.stringify(state));
};

const initialState = {
    pending: false,
    ...INTEGRATIONS_LIST.reduce((acc, app) => {
        acc[app] = {};
        return acc;
    }, {}),
};

const IntegrationReducer = (state = initialState, action) => {
    const newState = produce(state, (draftState) => {
        switch (action.type) {
            case GET_INTEGRATION_ITEMS:
            case REVOKE_INTEGRATION:
            case AUTHORIZE_INTEGRATION:
            case CREATE_INTEGRATION_ITEM:
            case GET_CACHED_INTEGRATION_DATA: {
                draftState.pending = true;
                break;
            }
            case REVOKE_INTEGRATION_SUCCESS: {
                const { app, appId } = action.payload;
                delete draftState[app][appId];
                draftState.pending = false;
                break;
            }
            case REVOKE_INTEGRATION_FAILURE:
            case AUTHORIZE_INTEGRATION_SUCCESS:
            case AUTHORIZE_INTEGRATION_FAILURE:
            case GET_INTEGRATION_ITEMS_FAILURE:
            case CREATE_INTEGRATION_ITEM_FAILURE:
            case ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS:
            case GET_CACHED_INTEGRATION_DATA_FAILURE: {
                draftState.pending = false;
                break;
            }
            case GET_INTEGRATION_ITEMS_SUCCESS: {
                const { data, app, appId, props } = action.payload;
                const { items: newItems } = data as IntegrationItemsData;
                const rootId = buildUID({ fid: 'root', app, appId });

                let items = {};

                if (props?.mergeItems) {
                    delete newItems[rootId];
                    items = Object.assign({}, draftState[app][appId]?.items, newItems);
                } else {
                    items = {
                        ...newItems,
                        [rootId]: {
                            ...newItems[rootId],
                            id: rootId,
                            app,
                            appId,
                        },
                    };
                }

                const itemsList = buildIntegrationItemList(items, rootId);

                draftState[app][appId] = {
                    itemsList,
                    items,
                    lastFetch: Date.now(),
                };
                draftState.pending = false;
                break;
            }
            case UPDATE_INTEGRATION_ITEM_SUCCESS: {
                const { app, appId, updatedItems } = action.payload;
                const rootId = buildUID({ fid: 'root', app, appId });
                const newItems = Object.assign({}, draftState[app][appId].items);
                updatedItems.forEach((item) => {
                    newItems[item.id] = item;
                });
                draftState[app][appId].itemsList = buildIntegrationItemList(newItems, rootId);
                draftState[app][appId].items = newItems;
                break;
            }
            case CREATE_INTEGRATION_ITEM_SUCCESS: {
                const { app, appId, createdItems } = action.payload;
                const rootId = buildUID({ fid: 'root', app, appId });
                const newItems = Object.assign({}, draftState[app][appId].items);
                createdItems.forEach(({ parentId, item }) => {
                    newItems[item.id] = item;
                    if (parentId && newItems[parentId]) {
                        const parent = newItems[parentId];
                        parent.childrenList = parent.childrenList || [];
                        parent.childrenList.unshift(item.id);
                    }
                });
                draftState[app][appId].itemsList = buildIntegrationItemList(newItems, rootId);
                draftState[app][appId].items = newItems;
                break;
            }
            case DELETE_INTEGRATION_ITEM_SUCCESS: {
                const { app, appId, deletedItemIds } = action.payload;
                const rootId = buildUID({ fid: 'root', app, appId });
                const newItems = Object.assign({}, draftState[app][appId].items);
                deletedItemIds.forEach(({ parentId, id }) => {
                    delete newItems[id];
                    if (parentId && newItems[parentId]?.childrenList) {
                        const parent = newItems[parentId];
                        parent.childrenList = parent.childrenList.filter((childId) => childId !== id);
                    }
                });
                const newItemList = buildIntegrationItemList(newItems, rootId);

                draftState[app][appId].itemsList = newItemList;
                draftState[app][appId].items = newItems;
                break;
            }
            case GET_CACHED_INTEGRATION_DATA_SUCCESS: {
                const { data } = action.payload;
                for (const key in data) {
                    draftState[key] = data[key];
                }
                draftState.pending = false;
                break;
            }
            default:
                break;
        }
    });

    const debouncedSync = debounce((userId, state) => syncStateWithIdb(userId, state), DEBOUNCE_INTERVAL);

    if (typeof window !== 'undefined' && window.indexedDB && !newState.pending) {
        const currentUserId = window?.localStorage?.getItem('currentUserId');

        if (currentUserId) {
            debouncedSync(currentUserId, newState);
        }
    }

    return newState;
};

export default IntegrationReducer;
