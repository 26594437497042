import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import Omnibox from '.';
import { OpenItemParams, useEntities, useOpenItem } from '@/hooks/reduxHooks';
import { getRecentlyBrowsedSelector } from '@/redux/ui/selectors';
import typeInfo from '../entities/typeInfo';
import { isEmptyTab } from '@/utils/helpers';
import { merge, pick } from 'lodash';
import { Option } from './types';
import useAppWrapper from '@/hooks/useAppWrapper';
import { getIntegrationStateSelector } from '@/redux/integrations/selectors';
import { parseUID } from '@/utils/IDManager';
import styles from './styles.module.scss';

export const GlobalSearchOmniboxStyles = {
    input: (provided) => ({
        ...provided,
        paddingLeft: '30px',
        paddingBottom: 0,
        paddingTop: 0,
        fontSize: '16px',
        outline: 'none',
    }),
    control: (provided, state) => ({
        ...provided,
        border: '1px solid rgba(0, 0, 0, 0.05)',
        borderRadius: state.menuIsOpen ? '12px 12px 0px 0px' : '12px',
        fontSize: '16px',
        outline: 'none',
        boxShadow: 'none',
        transition: 'box-shadow 0.3s ease',
        width: 'clamp(300px, 100%, 600px)',
        height: '52px',
        minHeight: '0px',
        cursor: 'text',
        backgroundColor: '#FFF',
        zIndex: 20,
        //override default hover
        ':hover': {
            outline: 'none',
            border: '1px solid rgba(0, 0, 0, 0.05)',
            boxShadow: 'none',
            borderRadius: state.menuIsOpen ? '12px 12px 0px 0px' : '12px',
        },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? '#F5F5F5' : 'none',
        fontSize: 14,
        paddingBottom: 0,
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 0,
        height: '30px',
        lineHeight: '30px',
    }),
    group: (provided) => ({
        ...provided,
        paddingTop: 0,
    }),
    groupHeading: (provided) => ({
        ...provided,
        paddingLeft: '12px',
    }),
    placeholder: (provided) => ({
        ...provided,
        paddingLeft: '30px',
        fontSize: '16px',
    }),
    menu: (provided) => ({
        ...provided,
        marginTop: '-1px',
        borderRadius: '0px 0px 12px 12px',
        outline: 'none',
        border: 'none',
        borderLeft: '1px solid rgba(0, 0, 0, 0.05)',
        borderRight: '1px solid rgba(0, 0, 0, 0.05)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)',
        boxShadow: '0px 30px 65px -10px rgba(0, 0, 0, 0.38), 0px 0px 15px 0px rgba(0, 0, 0, 0.15)',
        overflow: 'hidden',
        padding: '2px 0',
        width: 'clamp(300px, 100%, 600px)',
    }),
};

interface Props {
    onBlur?: () => void;
    styleOverrides?: { [key: string]: any };
    data?: { [key: string]: any };
    searchIcon?: boolean;
    show?: boolean;
    componentOverrides?: { [key: string]: any };
    showMenuOnFocus?: boolean;
    onSelectSideEffect?: () => void;
}

const GlobalSearchOmnibox = ({
    onBlur,
    styleOverrides = {},
    data = {},
    searchIcon = true,
    show = true,
    componentOverrides,
    showMenuOnFocus,
    onSelectSideEffect,
}: Props) => {
    const openItem = useOpenItem();
    const ref = useRef(null);
    // const { isLightBackground } = useAppBackground();
    const { isBaseApp } = useAppWrapper();

    const recentlyBrowsed = useSelector(getRecentlyBrowsedSelector);
    const recentlyBrowsedIds = useMemo(() => {
        return recentlyBrowsed?.map((entityIdentity) => entityIdentity?.id);
    }, [recentlyBrowsed]);
    const recentlyBrowsedEntities = useEntities(
        recentlyBrowsedIds.filter((id) => !isEmptyTab(id)),
        true,
        'GlobalSearchOmnibox3',
    );

    const integrationState = useSelector(getIntegrationStateSelector);
    const combinedRecentList = useMemo(() => {
        return recentlyBrowsedEntities?.map((entity, index) => {
            if (entity?.metadata?.id) return entity;
            const identity = recentlyBrowsed?.[index];
            if (identity?.app && identity?.appId) {
                const id = parseUID(identity.id).fid;
                const integrationItem = integrationState?.[identity?.app]?.[identity?.appId]?.items?.[id];
                return { ...integrationItem, id, app: identity.app, appId: identity.appId };
            }
            return null;
        });
    }, [recentlyBrowsedEntities, integrationState, recentlyBrowsed]);

    const recentlyBrowsedAsOptions = useMemo(() => {
        const recentlyBrowsedWithoutUndefineds = combinedRecentList?.filter((entity) => !!entity);
        return recentlyBrowsedWithoutUndefineds?.map((entity) => {
            return {
                label: entity.name?.length ? entity.name : 'Untitled ' + typeInfo[entity['@type']]?.fancyName,
                id: entity.id || entity.metadata?.id,
                dsid: entity.metadata?.dsid,
                app: entity.app,
                appId: entity.appId,
                entity: pick(entity, [
                    'metadata',
                    'emoji',
                    '@type',
                    'name',
                    'url',
                    'thumbnailUrl',
                    'app',
                    'iconLink',
                    'mimeType',
                    'key',
                ]),
            } as Option;
        });
    }, [combinedRecentList]);

    useEffect(() => {
        if (show) ref.current.focus();
    }, [show]);

    const omniboxStyles = merge({}, GlobalSearchOmniboxStyles, styleOverrides);

    const handleEntitySelect = (params: OpenItemParams) => {
        openItem({ ...params, emptyTab: data?.emptyTab, globalSearch: true });
    };

    return (
        <div className={styles.globalSearchOmniboxContainer}>
            <Omnibox
                forwardRef={ref}
                onEntitySelect={handleEntitySelect}
                isGlobal
                canCreate
                canDeepSearch={!isBaseApp}
                hideIcon
                autoFocus
                placeholder="Search or create..."
                defaultOptions={recentlyBrowsedAsOptions}
                defaultOptionsLabel="Recent"
                onBlur={onBlur}
                instanceId={'window-frame'}
                styleOverrides={omniboxStyles}
                selectedOptionComponent={() => null}
                searchIcon={searchIcon}
                componentOverrides={componentOverrides}
                showMenuOnFocus={showMenuOnFocus}
                onSelectSideEffect={onSelectSideEffect}
            />
        </div>
    );
};

export default GlobalSearchOmnibox;
