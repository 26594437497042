import styles from './upgradePlanModal.module.scss';
// import { usePopup, PopupWrapper } from '../../wrappers/modal';

// export const useUpgradePlanModal = () => {
//     const { open: openPopup } = usePopup();
//     // const dispatch = useDispatch();

//     const openUpgradePlanModal = (): void => {
//         openPopup('upgrade-plan-popup');
//     };
//     return openUpgradePlanModal;
// };

const UpgradePlanModal: React.FunctionComponent = () => {
    return (
        // <PopupWrapper text={'upgrade-plan-popup'}>
        <div className={`${styles.aboutBedrockModal}`}>
            <h3>Upgrade</h3>
        </div>
        // </PopupWrapper>
    );
};

export default UpgradePlanModal;
