import { useCallback, useRef, useState } from 'react';
import { signOut } from 'next-auth/react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { Dropdown } from 'react-bootstrap';
import AppSettingsIcon from '@/icons/AppSettingsIcon';
import { showGlobalModal } from '@/redux/ui/actions';
import { useAboutBedrockPopup } from '@/components/modals/aboutBedrockPopup';
import { switchUser } from '@/utils/apiHelpers';
import useOutsideClick from '@/hooks/outsideClick';
import styles from '@/components/page.module.scss';
import contextMenuStyles from '@/components/modals/contextMenu.module.scss';
import BedrockDiscoIcon from '@/icons/BedrockDiscoIcon';
import useAppWrapper from '@/hooks/useAppWrapper';
import Image from 'next/image';
import useCrisp from '@/hooks/useCrisp';

const BaseSystemMenu: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const [show, setShow] = useState(false);
    const menuRef = useRef(null);
    const openAboutBedrockPopup = useAboutBedrockPopup();
    const { isBaseApp } = useAppWrapper();
    const { openChat } = useCrisp();

    const toggleShow = useCallback(() => setShow((val) => !val), []);
    const handleClose = useCallback(() => setShow(false), []);

    useOutsideClick(menuRef, handleClose, 'base-system-dropdown');

    const openSettingsModal = () => {
        handleClose();
        dispatch(showGlobalModal('SettingsModal', {}));
    };

    const handleOpenAboutBedrock = () => {
        handleClose();
        openAboutBedrockPopup();
    };

    const onChatOpen = () => {
        openChat();
        handleClose();
    };

    const handleSignOutClick = async () => {
        const sessions = JSON.parse(localStorage.getItem('sessions') || '{}');
        const currentUserId = localStorage.getItem('currentUserId');
        handleClose();
        await signOut({ redirect: false });
        delete sessions[currentUserId];
        const anotherUserId = Object.keys(sessions)[0];
        if (anotherUserId) await switchUser({ id: anotherUserId });
        localStorage.setItem('sessions', JSON.stringify(sessions));
        router.push('/login');
        window?.sendToElectron('bedrock-event-signOut', {});
    };
    return (
        <Dropdown className={`${styles.dropdown}`} show={show} ref={menuRef}>
            <Dropdown.Toggle id="base-system-dropdown" onClick={toggleShow}>
                <div className={styles.systemDropdownToggle}>
                    <div className={styles.bottomBarIcon}>
                        <BedrockDiscoIcon />
                    </div>
                </div>
            </Dropdown.Toggle>
            <Dropdown.Menu
                className={`${contextMenuStyles.menu} ${isBaseApp ? 'baseSystemDropdown' : 'systemDropdown'}`}
            >
                <Dropdown.Item
                    value="settingsApp"
                    onSelect={openSettingsModal}
                    className={contextMenuStyles.itemSystemMenu}
                >
                    <div className="appIconStyles">
                        <AppSettingsIcon />
                    </div>
                    Settings
                </Dropdown.Item>
                <Dropdown.Item value="chat" className={contextMenuStyles.itemSystemMenu} onSelect={onChatOpen}>
                    <div className={contextMenuStyles.itemIcon}>💬</div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: '1' }}>
                        <div>Chat with us</div>
                        <Image
                            alt="icon"
                            height={23}
                            width={23}
                            className={styles.chatWithAvatar}
                            src="/chatwithBJ.gif"
                        />
                    </div>
                </Dropdown.Item>
                <Dropdown.Item
                    value="about"
                    onSelect={handleOpenAboutBedrock}
                    className={contextMenuStyles.itemSystemMenu}
                >
                    About Bedrock
                </Dropdown.Item>
                <Dropdown.Item
                    key={`twitter`}
                    eventKey={'twitter'}
                    className={contextMenuStyles.itemSystemMenu}
                    onClick={handleClose}
                    href="https://twitter.com/bedrockcomputer"
                    target="_blank"
                >
                    <div>Twitter • @bedrockcomputer</div>
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                    key={`signOut`}
                    onClick={handleSignOutClick}
                    eventKey={'signOut'}
                    className={contextMenuStyles.itemSystemMenu}
                >
                    Sign out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default BaseSystemMenu;
