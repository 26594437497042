import React from 'react';
import Link from 'next/link';
import platformOS from 'platform-detect/os.mjs';
import Text from '../../DesignSystem/Text';
import Button from '../../DesignSystem/Button';
import welcomeModalStyles from './welcomeModal.module.scss';
import { Dropdown, Image } from 'react-bootstrap';
import MacOsIcon from '@/icons/MacOsIcon';
import WindowsIcon from '@/icons/WindowsIcon';
import ElectronAppIcon from '@/icons/ElectronAppIcon';
import { useJune } from '@/hooks/useJune';
import { useSelector } from 'react-redux';
import { getUserSelector } from '@/redux/user/selectors';

const ElectronClient = function (props): React.ReactElement {
    const { skipStep, prevStep, latestVersion } = props;
    const analytics = useJune();
    const user = useSelector(getUserSelector);

    if (!platformOS.windows && !platformOS.macos) {
        return null;
    }

    const handleDownloadClick = (platform: string): void => {
        analytics?.track(`Download Electron Client - ${platform}`, { userId: user?.id });
    };

    return (
        <div className={`${welcomeModalStyles.avatarNameJobContainer} ${welcomeModalStyles.downloadsStep}`}>
            <div className={welcomeModalStyles.downloadsHeader}>
                <Text variant="heading" size="h4" align="center">
                    Get our {(platformOS.macos && 'macOS') || (platformOS.windows && 'Windows') || ''} App
                </Text>
                <Text variant="body" size="s" align="center">
                    Highly recommended so you can view web pages!
                </Text>
            </div>

            {latestVersion ? (
                <div className={welcomeModalStyles.downloadsContainer}>
                    <div className={welcomeModalStyles.appIconWrapper}>
                        <ElectronAppIcon />
                        <span>Bedrock</span>
                    </div>
                    <div
                        className={welcomeModalStyles.downloadButtonsContainer}
                        style={{ flexDirection: platformOS.macos ? 'unset' : 'row-reverse' }}
                    >
                        <Dropdown>
                            <Dropdown.Toggle
                                className={`${welcomeModalStyles.downloadButton} ${welcomeModalStyles.center} ${
                                    platformOS.macos ? welcomeModalStyles.primary : ''
                                }`}
                            >
                                <MacOsIcon />
                                <span>Download for Mac </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu rootCloseEvent="mousedown" className={welcomeModalStyles.dropdownMenu}>
                                <Dropdown.Item
                                    href={latestVersion.binaries.macosArm64}
                                    className={welcomeModalStyles.dropdownLink}
                                    onClick={() => handleDownloadClick('macOS - Apple Silicon')}
                                >
                                    <span className={welcomeModalStyles.dropdownLinkTitle}>Apple Silicon</span>
                                    <span className={welcomeModalStyles.dropdownLinkSubtitle}>
                                        2020 & Later (M1, M2)
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Item
                                    href={latestVersion.binaries.macos64bit}
                                    className={welcomeModalStyles.dropdownLink}
                                    onClick={() => handleDownloadClick('macOS - Intel')}
                                >
                                    <span className={welcomeModalStyles.dropdownLinkTitle}>Intel Processor</span>
                                    <span className={welcomeModalStyles.dropdownLinkSubtitle}>Before 2020</span>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>

                        <a
                            href={latestVersion.binaries.windows}
                            style={{ textDecoration: 'none' }}
                            onClick={() => handleDownloadClick('Windows')}
                        >
                            <div
                                className={`${welcomeModalStyles.downloadButton} ${welcomeModalStyles.center} ${
                                    platformOS.windows ? welcomeModalStyles.primary : ''
                                }`}
                            >
                                <WindowsIcon />
                                <span>Download for Windows</span>
                            </div>
                        </a>
                    </div>
                </div>
            ) : (
                <Link href="/downloads">
                    <a target="_blank" rel="noreferrer" className={welcomeModalStyles.cardButton}>
                        <Image src="/BedrockDisco.png" />
                        <Text weight="bold" align="center">
                            Visit App Downloads
                        </Text>
                    </a>
                </Link>
            )}

            <div className={welcomeModalStyles.stepControlContainer}>
                <Button onClick={prevStep} size="s" variant="secondary">
                    {'<- Back'}
                </Button>
                <Button onClick={skipStep} size="s" variant="primary" style={{ minWidth: 206 }}>
                    {'Continue ->'}
                </Button>
                <div />
                <Text onClick={skipStep} size="xs" align="center" className={welcomeModalStyles.blueText}>
                    {'Set up later'}
                </Text>
            </div>
        </div>
    );
};

export default ElectronClient;
