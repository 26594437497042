import { hideGlobalModal } from '@/redux/ui/actions';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@/components/DesignSystem/Button';
import { revokeIntegrationAccount } from '@/redux/integrations/actions';
import { useRouter } from 'next/router';
import { getIntegrationAccountsSelector, getUserSelector } from '@/redux/user/selectors';

import styles from './ConfirmRevokeIntegrationModal.module.scss';
import IntegrationAccountIcon from '@/components/entities/subcomponents/IntegrationAccountIcon';
import { INTEGRATIONS } from '../../../integrations/constants';

type Props = {
    entity: Entity;
};

const ConfirmRevokeIntegrationModal = ({ entity }: Props) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const integrationList = useSelector(getIntegrationAccountsSelector);
    const { id: userId, entity: userEntity } = useSelector(getUserSelector);

    const targetIntegrationId = Object.keys(integrationList).find(
        (key) => integrationList[key].app === entity.app && integrationList[key].appId === entity.appId,
    );

    const integrationLabel = INTEGRATIONS[entity.app].label;

    const closeModal = () => dispatch(hideGlobalModal('ConfirmRevokeIntegrationModal'));

    const handleRevokeIntegration = () => {
        router.push('/base');
        dispatch(revokeIntegrationAccount(userId, userEntity, entity.app, entity.appId));
        closeModal();
    };

    return (
        <div className={styles.modalWrapper}>
            <h3 className={styles.title}>Are you sure you want to revoke integration?</h3>
            <div className={styles.infoContainer}>
                <div className={styles.icon}>
                    <IntegrationAccountIcon id={targetIntegrationId} />
                </div>
                <span>
                    {integrationLabel} - {entity.appId}
                </span>
            </div>
            <div className={styles.buttonContainer}>
                <Button variant="plain" size="s" onClick={closeModal} className="mr-2">
                    Cancel
                </Button>
                <Button variant="primary" size="s" onClick={handleRevokeIntegration}>
                    Revoke
                </Button>
            </div>
        </div>
    );
};

export default ConfirmRevokeIntegrationModal;
