import { useDispatch, useSelector } from 'react-redux';
import { createEntity, getEntities, getEntity, updateEntity } from '../redux/entities/actions';
import {
    getAllFullEntitiesSelector,
    getCondensedEntitiesSelector,
    getCondensedEntitySelector,
    getDataspaceSelector,
    getDsidSelector,
    getEntityListSelector,
    getEntityLists,
    getFetchErrorCountSelector,
    getFetchingSelector,
    getFullEntitiesSelector,
    getFullEntitySelector,
    getInitialLoadSelector,
    getInvalidIdsSelector,
    getMultiFetchingSelector,
    getPendingSelector,
} from '../redux/entities/selectors';
import { setUI, triggerScrollToBrowsed } from '../redux/ui/actions';
import { findIndex, keys, merge, pickBy, reverse } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { cloneEntity, isEid, newEntity } from '../utils/helpers';
import { getAppBackground, getUserSelector } from '../redux/user/selectors';
import { EntityWithId, Finder } from '../redux/types';
import {
    getAllExpandedHListItemsSelector,
    getBrowsedInstanceSelector,
    getFinderSelector,
    getSelectedEntitiesSelector,
    getTabsSelector,
} from '../redux/ui/selectors';
import { insertTab } from '@/components/entities/subcomponents/Tabs/TabsBar';
import { getIntegrationItemSelector, getIntegrationStateSelector } from '@/redux/integrations/selectors';
import { buildUID, parseUID } from '@/utils/IDManager';
import { useMobileQuery } from '@/hooks/useMediaQuery';

export const useEntity = (uid: string, condensedOk: boolean, debugMessage?: string): Entity => {
    const { eid, app, appId } = parseUID(uid);

    const entity = useSelector(getFullEntitySelector(eid));
    const condensedEntity = useSelector(getCondensedEntitySelector(eid));

    const integrationItem = useSelector(getIntegrationItemSelector(uid));

    const fetching = useSelector(getFetchingSelector(eid));
    const errorCount = useSelector(getFetchErrorCountSelector(eid));
    const invalidIds = useSelector(getInvalidIdsSelector);
    const initialLoad = useSelector(getInitialLoadSelector);
    const dispatch = useDispatch();

    useEffect(() => {
        if (
            !(app && appId) &&
            !initialLoad &&
            !entity &&
            !fetching &&
            errorCount < 3 &&
            isEid(eid) &&
            !invalidIds[eid] &&
            !condensedOk
        ) {
            dispatch(getEntity(eid, debugMessage));
        }
    }, [initialLoad, entity, fetching, eid, condensedOk]);

    return integrationItem && entity
        ? Object.assign({}, integrationItem, entity)
        : integrationItem
        ? integrationItem
        : entity
        ? entity
        : condensedEntity;
};

export const useEntities = (uids: string[], condensedOk: boolean, debugMessage?: string) => {
    const dispatch = useDispatch();
    const condensedEntities = useSelector(getCondensedEntitiesSelector(uids)) || [];
    const fullEntities = useSelector(getFullEntitiesSelector(uids)) || [];
    const fetchings = useSelector(getMultiFetchingSelector(uids));
    const isPending = useSelector(getPendingSelector);
    const invalidIds = useSelector(getInvalidIdsSelector);
    const initialLoad = useSelector(getInitialLoadSelector);

    const integrationState = useSelector(getIntegrationStateSelector);
    if (uids) {
        for (let i = 0; i < uids.length; i++) {
            const uid = uids[i];
            const { fid, app, appId } = parseUID(uid);

            if (!app || !appId) continue; // Skip if either 'app' or 'appId' is missing

            const integrationItemId = buildUID({ fid, app, appId });
            const integrationItem = integrationState?.[app]?.[appId]?.items?.[integrationItemId];

            if (integrationItem) {
                fullEntities[i] = integrationItem;
                condensedEntities[i] = integrationItem;
            }
        }
    }

    if (!condensedOk) {
        const eidsToFetch = [];
        const entities = fullEntities.map((entity, i) => {
            // If the full entity is not there and it isn't fetching and it isn't an already-attempted invalid id, fetch it.
            if (!('@type' in entity) && !fetchings[i] && !invalidIds[uids[i]]) {
                eidsToFetch.push(entity.id);
                return entity;
            } else {
                return entity;
            }
        });
        if (uids?.length && !isPending && !initialLoad) {
            if (eidsToFetch.length) dispatch(getEntities(eidsToFetch, debugMessage));
        }
        return entities;
    } else {
        return condensedEntities;
    }
};

export const useUpdateBrowsed = (parentId) => {
    const dispatch = useDispatch();
    const router = useRouter();
    const isBase = router.pathname.includes('base');
    const addTabToFinder = useAddTabToFinder();
    const expandedHListItems = useSelector(getAllExpandedHListItemsSelector);

    return (res: EntityWithId) => {
        const entityId = res?.id;

        dispatch(
            setUI(
                {
                    browsedInstance: { id: entityId, parentId },
                    selectedEntities: [{ id: entityId, parentId }],
                    expandedHListItems: merge({}, expandedHListItems, { [parentId]: true }),
                    ...(isBase && { finder: addTabToFinder(res) }),
                },
                'reduxHooks1',
            ),
        );
    };
};

export const useUpdateBrowsedFallback = (browsedInstance, selectedEntities, expandedHListItems, finder) => {
    const dispatch = useDispatch();

    return () => {
        dispatch(
            setUI(
                {
                    browsedInstance,
                    selectedEntities,
                    expandedHListItems,
                    finder,
                },
                'reduxHooks2',
            ),
        );
    };
};

// You can use this hook, or import insertTab directly into another component
export const useAddTabToFinder = () => {
    const finder = useSelector(getFinderSelector);
    const tabs = useSelector(getTabsSelector);
    const browsedInstance = useSelector(getBrowsedInstanceSelector);

    return (entityIdentity: EntityIdentity, replaceTab?: boolean): Finder => {
        const newTabs = insertTab(tabs, entityIdentity, browsedInstance?.id, true, false, replaceTab);
        return merge({}, finder, { tabs: { [finder.id]: newTabs } });
    };
};

export const useDescendants = (eids: string[]): string[] => {
    // Includes base eids
    const allEntities = useSelector(getAllFullEntitiesSelector);
    let ids = [];

    const addChildIds = useCallback((eid: string): string[] => {
        const entity = allEntities[eid];
        let childIds = [];
        if (entity?.childrenList?.length > 0) {
            entity.childrenList.forEach((childId) => {
                childIds = childIds.concat(addChildIds(childId));
            });
        }
        childIds.push(eid);
        return childIds;
    }, []);

    eids.forEach((id) => {
        ids = ids.concat(addChildIds(id));
    });
    return ids;
};

// Includes all ancestors of all mirrors of entity (Until Aliases replace Mirror Entities)
export const useAncestors = (eid: string): string[] => {
    // Includes base eid
    const dsid = useSelector(getDsidSelector);
    const entityList = useSelector(getEntityListSelector(dsid));

    if (!eid) return [];
    const listIndexes = keys(pickBy(entityList, ['id', eid]));
    const ids = [];
    listIndexes.forEach((i) => {
        let listIndex = parseInt(i);
        ids.push(entityList[listIndex].id);
        let zIndex = entityList[listIndex].zIndex || 0;
        while (zIndex > 0 && listIndex >= 0) {
            if (entityList[listIndex]?.zIndex < zIndex) {
                ids.push(entityList[listIndex].id);
                zIndex--;
            }
            listIndex--;
        }
    });

    return ids;
};

//Includes direct ancestors of exactly one entity (first instance of entity until Mirror Entities are replaced)
export const useFinderPath = (eid: string, dsid: string): string[] => {
    const ancestors = [eid];
    const entityList = useSelector(getEntityListSelector(dsid));
    const startIndex = findIndex(entityList, (e: any) => e.id === eid);
    const start = entityList?.[startIndex];
    if (startIndex > -1) {
        let zIndex = start?.zIndex;
        for (let i = startIndex; i >= 0; i--) {
            if (entityList[i]?.zIndex < zIndex) {
                ancestors.push(entityList[i].id);
                zIndex--;
            } else if (zIndex < 0) break;
        }
    }
    return reverse(ancestors);
};

export type OpenItemParams = {
    id?: string;
    dsid?: string;
    app?: string;
    appId?: string;
    emptyTab?: boolean;
    globalSearch?: boolean;
};

export const useOpenItem = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const currentDsid = useSelector(getDsidSelector);
    const [params, setParams] = useState({} as OpenItemParams);
    const addTabToFinder = useAddTabToFinder();
    const expandedHListItems = useSelector(getAllExpandedHListItemsSelector);
    const entityLists = useSelector(getEntityLists);
    const isMobile = useMobileQuery();

    const browseAndExpandItems = (browsedId, list) => {
        const idsToOpen = { [browsedId]: true };
        const browsedIndex = findIndex(list, (e: any) => e.id?.startsWith(browsedId));
        const browsed = list?.[browsedIndex];
        const isFolder = browsed?.['@type'] === 'HList';
        if (browsedIndex > -1) {
            let zIndex = browsed?.zIndex;
            for (let i = browsedIndex; i >= 0; i--) {
                if (list[i]?.zIndex < zIndex) {
                    idsToOpen[list[i].id] = true;
                    zIndex--;
                } else if (zIndex < 0) break;
            }
        }
        const instance = {
            id: browsed?.id,
            parentId: browsed?.parentId,
            app: params.app,
            appId: params.appId,
        };
        dispatch(
            setUI(
                {
                    browsedInstance: instance,
                    selectedEntities: [instance],
                    expandedHListItems: merge({}, expandedHListItems, idsToOpen),
                    ...(router.pathname.includes('base') && {
                        finder: addTabToFinder({ id: browsed?.id }, params.emptyTab),
                    }),
                    ...(isFolder &&
                        !isMobile && {
                            singleUseHListRoot: instance,
                        }),
                },
                'reduxHooks3',
            ),
        );
        dispatch(triggerScrollToBrowsed(true));
    };

    const openItem = async (params: OpenItemParams) => {
        // Workaround for IntegrationItem entities that still have app/appId, which messes with list selectors
        if (isEid(params.id) && (params.app || params.appId)) {
            delete params.app;
            delete params.appId;
        }
        const shouldSwitch =
            (!router.pathname.includes('onboarding') &&
                params.dsid &&
                (currentDsid !== params.dsid || !router.pathname.includes('base'))) ||
            (!params.dsid &&
                params.app &&
                params.appId &&
                router?.query?.app !== params.app &&
                router?.query?.appId !== params.appId);

        // First switch ds if needed
        if (shouldSwitch && params.dsid) {
            await router.push('/base/' + params.dsid + '?b=' + params.id?.substring(0, 8), undefined, {
                shallow: true,
            });
            // Or switch to integrations if needed
        } else if (shouldSwitch && params.app && params.appId) {
            const encodedSegment = encodeURIComponent(params.app) + '/' + encodeURIComponent(params.appId);
            router.push(`/integrations/${encodedSegment}?b=${params.id}`, undefined, { shallow: true });
            // Then open item after clearing dsid
        } else if (!shouldSwitch && entityLists[params?.dsid]?.length && params.id) {
            browseAndExpandItems(params.id, entityLists[params?.dsid]);
        } else {
            setParams(params);
        }
    };

    return openItem;
};

interface CreateEntityHookParams {
    parentEntity?: Entity;
    isDraft?: boolean;
    customCallback?: {
        callback: (res: EntityWithId) => void;
        fallback: () => void;
    };
    entity?: Entity;
}

export const useCreateEntity = (eid) => {
    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const allExpandedHListItems = useSelector(getAllExpandedHListItemsSelector);
    const finder = useSelector(getFinderSelector);
    const updateBrowsedFallback = useUpdateBrowsedFallback(
        browsedInstance,
        selectedEntities,
        allExpandedHListItems,
        finder,
    );
    const updateBrowsed = useUpdateBrowsed(eid);
    const dispatch = useDispatch();
    let callback = updateBrowsed;
    let fallback = updateBrowsedFallback;

    return (type: EntityType, { parentEntity, isDraft, customCallback, entity }: CreateEntityHookParams = {}) => {
        const params: CreateEntityParams = { type, parentEntity, parentId: eid };
        if (entity) params['entity'] = entity;

        if (isDraft) {
            params.draft = true;
            params.entity = newEntity(type);
            delete params.parentEntity;
            delete params.parentId;
        }

        if (customCallback) {
            callback = customCallback.callback;
            fallback = customCallback.fallback;
        }

        dispatch(createEntity(params, callback, fallback));
    };
};

type BackgroundOption = {
    src: string;
    preview: string;
    selected: boolean;
    lightBackground?: boolean;
};

type AppBackground = {
    backgroundImage: string;
    backgroundImagePreview: string;
    setBackgroundImage: (preview?: string, fullSizedImage?: string) => void;
    backgroundOptions: BackgroundOption[];
    isLightBackground: boolean;
};

const DEFAULT_BACKGROUND = '/OS_system_bg/bg.svg';

export const useAppBackground = (): AppBackground => {
    const [backgroundImagePreview, setBackgroundImagePreview] = useState(null);
    const dispatch = useDispatch();
    const backgroundImage = useSelector(getAppBackground) || DEFAULT_BACKGROUND;
    const user = useSelector(getUserSelector);

    useEffect(() => {
        if (backgroundImage) {
            const preview = memoizedOptions.find((item) => item.src === backgroundImage).preview;
            setBackgroundImagePreview(preview);
        }
    }, [backgroundImage]);

    const backgrounds = [
        {
            src: '/OS_system_bg/bg.svg',
            preview: '/OS_system_bg/bg-preview.svg',
            selected: false,
        },
        {
            src: '/OS_system_bg/cabeach.jpg',
            preview: '/OS_system_bg/cabeach-preview.jpg',
            selected: false,
            lightBackground: true,
        },
        {
            src: '/OS_system_bg/cherryblossoms.png',
            preview: '/OS_system_bg/cherryblossoms-preview.png',
            selected: false,
            lightBackground: true,
        },
        {
            src: '/OS_system_bg/dark_sunset.png',
            preview: '/OS_system_bg/dark_sunset-preview.png',
            selected: false,
        },
        {
            src: '/OS_system_bg/night-dune.jpg',
            preview: '/OS_system_bg/night-dune-preview.jpg',
            selected: false,
        },
        {
            src: '/OS_system_bg/forest.jpg',
            preview: '/OS_system_bg/forest-preview.jpg',
            selected: false,
        },
        {
            src: '/OS_system_bg/greenfish.png',
            preview: '/OS_system_bg/greenfish-preview.png',
            selected: false,
        },
        {
            src: '/OS_system_bg/cave.jpg',
            preview: '/OS_system_bg/cave-preview.jpg',
            selected: false,
        },
        {
            src: '/OS_system_bg/saltflat.png',
            preview: '/OS_system_bg/saltflat-preview.png',
            selected: false,
            lightBackground: true,
        },
        {
            src: '/OS_system_bg/santorini.png',
            preview: '/OS_system_bg/santorini-preview.png',
            selected: false,
            lightBackground: true,
        },
        {
            src: '/OS_system_bg/yellowcar.png',
            preview: '/OS_system_bg/yellowcar-preview.png',
            selected: false,
        },
        {
            src: '/OS_system_bg/ayers-rock.jpg',
            preview: '/OS_system_bg/ayers-rock-preview.jpg',
            selected: false,
        },
    ];

    const memoizedOptions = useMemo(() => {
        return backgrounds.map((bg) => ({
            ...bg,
            selected: backgroundImage === bg.src,
        }));
    }, [backgroundImage]);

    const isLightBackground = useMemo(() => {
        return backgrounds.find((item) => item.src === backgroundImage).lightBackground || false;
    }, [backgroundImage]);

    const setBackgroundImage = (preview: string, fullSizedImage: string) => {
        setBackgroundImagePreview(preview);
        const newEntity = Object.assign({}, cloneEntity(user.entity), { appBackground: fullSizedImage }) as Entity;
        dispatch(updateEntity(user.id, newEntity, 'reduxHooks'));
    };

    return {
        backgroundImage,
        backgroundImagePreview,
        setBackgroundImage,
        backgroundOptions: memoizedOptions,
        isLightBackground,
    };
};

export const useAppsFolderList = () => {
    const dataspace = useSelector(getDataspaceSelector);
    const appIds = dataspace?.apps || [];
    const apps = useEntities(appIds, true);
    const appsFolderId = dataspace?.metadata?.id + '_apps';

    return { apps, appsFolderId };
};

// export const useElectronLocalDirectoryEventCatcher = (eventType: ElectronLocalDirectoryEventType, handler: Function) => {
//     const handleEvent = (e, data) => {
//         const { type } = data;
//         if (type === eventType) {
//             handler(data);
//         }
//     };

//     useEffect(() => {
//         if (isElectron()) {
//             window.addElectronListener(window.dirEventsChannelName, handleEvent);
//             // Clean the listener after the component is dismounted
//             return () => {
//                 window.removeElectronListener(window.dirEventsChannelName, handleEvent);
//             };
//         }
//     }, [window]);
// };

// export const useDirEventsCatcher = () => {
//     const dispatch = useDispatch();
//     const tree = useSelector(getLocalDirectoryTreeSelector);

//     const dirEventsHandler = (data) => {
//         const { events: dirEvents } = data;
//         dirEvents.map((e) => {
//             if (tree[e?.directory]) {
//                 dispatch(getDirContent(e.directory));
//             }
//             if (tree[e?.newDirectory]) {
//                 dispatch(getDirContent(e.newDirectory));
//             }
//         });
//     }

//     useElectronLocalDirectoryEventCatcher('DIR_EVENT', dirEventsHandler);
// };

export const useSelectorFactory = <T extends unknown[]>(selectorFactory: (...args: T) => any, ...args: T) => {
    const selector = useMemo(() => selectorFactory(...args), args);
    const selected = useSelector(selector);
    return [selector, selected];
};
