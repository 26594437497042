export default function ImageIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="m9.8 8.2.35.36-.36-.35Zm-7.95.65 2.3-2.29-.71-.7-2.3 2.29.71.7Zm3-2.29 3.3 3.3.7-.71-3.29-3.3-.7.71Zm3.3 3.3 4 4 .7-.71-4-4-.7.7Zm.7 0 1.3-1.3-.71-.7-1.3 1.29.71.7Zm2-1.3 3.3 3.3.7-.71-3.29-3.3-.7.71ZM1 4v4.5h1V4H1Zm0 4.5v4h1v-4H1ZM2.5 14h11v-1h-11v1ZM15 12.5V4h-1v8.5h1Zm-1.5-10h-11v1h11v-1ZM15 4c0-.83-.67-1.5-1.5-1.5v1c.28 0 .5.22.5.5h1Zm-1.5 10c.83 0 1.5-.67 1.5-1.5h-1a.5.5 0 0 1-.5.5v1ZM1 12.5c0 .83.67 1.5 1.5 1.5v-1a.5.5 0 0 1-.5-.5H1Zm9.15-3.94c.2-.2.5-.2.7 0l.71-.7a1.5 1.5 0 0 0-2.12 0l.7.7ZM2 4c0-.28.22-.5.5-.5v-1C1.67 2.5 1 3.17 1 4h1Zm2.15 2.56c.2-.2.5-.2.7 0l.71-.7a1.5 1.5 0 0 0-2.12 0l.7.7Z"
                fill="#333"
            />
            <circle cx="9" cy="5" r="1" fill="#333" />
        </svg>
    );
}
