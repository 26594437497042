export default function BlobMdIcon(): JSX.Element {
    return (
        <svg
            width="16"
            height="17"
            viewBox="0 0 16 17"
            data-3d-icon={true}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.03495 0.332054C8.23435 0.222651 8.4785 0.222645 8.67789 0.332059L15.1565 3.89519C15.3528 4.00328 15.4829 4.20687 15.4829 4.43669V11.5634C15.4829 11.7932 15.3529 11.9967 15.1566 12.1048L8.67812 15.6679C8.47872 15.7773 8.23434 15.7774 8.03494 15.668L1.55634 12.1049C1.36007 11.9968 1.22998 11.7932 1.22998 11.5634V4.43669C1.22998 4.20688 1.35992 4.00336 1.55621 3.89526L8.03495 0.332054Z"
                fill="white"
                stroke="black"
                strokeOpacity="0.05"
                strokeWidth="0.484374"
            />
            <path
                d="M13.931 4.76994L8.51992 1.79395C8.42025 1.73926 8.29732 1.73926 8.19765 1.79395L2.78659 4.76994C2.68931 4.82352 2.62964 4.92117 2.62964 5.02664V10.9792C2.62964 11.0847 2.68932 11.1823 2.78659 11.2359L8.19765 14.2119C8.29731 14.2666 8.42024 14.2666 8.51992 14.2119L13.931 11.2359C14.0283 11.1823 14.0879 11.0847 14.0879 10.9792V5.02664C14.0879 4.92118 14.0282 4.82352 13.931 4.76994V4.76994ZM8.35874 2.39552L13.142 5.02666L8.35874 7.6578L3.57544 5.02666L8.35874 2.39552ZM3.26637 5.54723L8.04065 8.17337V13.4351L3.26637 10.8084V5.54723ZM8.67682 13.4351V8.17337L13.4511 5.54723V10.8084L8.67682 13.4351Z"
                fill="#666666"
                stroke="#666666"
                strokeWidth="0.5"
            />
        </svg>
    );
}
