export default function LinkMdIcon(): JSX.Element {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_409_3181)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2679 1.64902C11.1214 1.50257 10.884 1.50257 10.7375 1.64902L7.94463 4.44192C7.70055 4.686 7.30482 4.686 7.06075 4.44192C6.81667 4.19784 6.81667 3.80211 7.06075 3.55804L9.85364 0.765137C10.4882 0.130534 11.5171 0.130534 12.1517 0.765136L14.2375 2.85092C14.8721 3.48553 14.8721 4.51442 14.2375 5.14902L11.4446 7.94192C11.2006 8.186 10.8048 8.186 10.5607 7.94192C10.3167 7.69784 10.3167 7.30212 10.5607 7.05804L13.3536 4.26514C13.5001 4.11869 13.5001 3.88125 13.3536 3.73481L11.2679 1.64902ZM10.9446 4.05803C11.1887 4.30211 11.1887 4.69783 10.9446 4.94191L4.94463 10.9419C4.70055 11.186 4.30482 11.186 4.06075 10.9419C3.81667 10.6978 3.81667 10.3021 4.06075 10.058L10.0607 4.05803C10.3048 3.81395 10.7006 3.81395 10.9446 4.05803ZM4.44463 7.05803C4.68871 7.30211 4.68871 7.69783 4.44463 7.94191L1.65174 10.7348C1.50529 10.8813 1.50529 11.1187 1.65174 11.2651L3.73752 13.3509C3.88397 13.4974 4.1214 13.4974 4.26785 13.3509L7.06075 10.558C7.30482 10.314 7.70055 10.314 7.94463 10.558C8.18871 10.8021 8.18871 11.1978 7.94463 11.4419L5.15174 14.2348C4.51713 14.8694 3.48824 14.8694 2.85364 14.2348L0.767853 12.149C0.133251 11.5144 0.133249 10.4855 0.767852 9.85092L3.56075 7.05803C3.80482 6.81395 4.20055 6.81395 4.44463 7.05803Z"
                    fill="#333333"
                />
            </g>
            <defs>
                <clipPath id="clip0_409_3181">
                    <rect width="15" height="15" fill="white" transform="translate(0.00268555)" />
                </clipPath>
            </defs>
        </svg>
    );
}
