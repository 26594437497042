import React, { useEffect } from 'react';
import { IntegrationAdapter, IntegrationName } from '../types';
import DriveIcon from '@/icons/DriveIcon';
import View from '@/components/entities/subcomponents/View';
import DriveFolderIcon from '../icons/DriveFolderIcon';
import EntityImageIcon from '@/components/EntityImageIcon';

const DriveMoreScreen = ({ authorize }: { authorize: (name: IntegrationName, props?: any) => void }) => {
    useEffect(() => {
        authorize('drive');
    }, []);

    return null;
};

const MIME_TYPES_EDIT_LIST = ['document', 'spreadsheet', 'presentation', 'drawing'];

const formatDriveFileUrl = (url) => {
    return url ? url.replace(/(edit|view)/, 'preview') : '';
};

export class DriveAdapter implements IntegrationAdapter {
    name = 'drive';
    icon = DriveIcon;
    moreScreen = DriveMoreScreen;
    label = 'Google Drive';

    itemsCallback = (tokens) => `/api/integrations/apps/${this.name}/getFiles?tokens=${encodeURIComponent(tokens)}`;
    revokeCallback = (tokens) => `/api/integrations/apps/${this.name}/revoke?tokens=${encodeURIComponent(tokens)}`;
    createItemCallback = (tokens) =>
        `/api/integrations/apps/${this.name}/createItem?tokens=${encodeURIComponent(tokens)}`;
    readFileMetadataCallback = () => `/api/integrations/apps/${this.name}/getFileMetadata`;
    getEntityId = (entity) => `${entity?.app}_${entity?.appId}`;
    getEntityPath = (entity) => `${entity?.app}/${entity?.appId}`;
    getEntityIcon = (entity) => {
        return entity?.metadata?.dsid && this.isFolder(entity) ? (
            <DriveFolderIcon />
        ) : entity?.iconLink || entity?.thumbnail_url ? (
            <EntityImageIcon src={entity?.iconLink || entity?.thumbnail_url} />
        ) : (
            <DriveIcon />
        );
    };
    renderEntityView = (entity) => {
        const src = MIME_TYPES_EDIT_LIST.some((type) => entity?.mimeType?.includes(type))
            ? entity?.webViewLink
            : formatDriveFileUrl(entity?.webViewLink);
        return <View src={src} height="100%" width="100%" entity={entity} />;
    };
    renderMoreScreen = (authorize) => <DriveMoreScreen authorize={authorize} />;
    isFolder = (entity) => entity?.mimeType === 'application/vnd.google-apps.folder' || Boolean(entity?.childrenList);

    constructor() {
        this.getAuthorizationUrl = this.getAuthorizationUrl.bind(this);
        this.getItems = this.getItems.bind(this);
        this.revoke = this.revoke.bind(this);
        this.createItem = this.createItem.bind(this);
        this.readFileMetadata = this.readFileMetadata.bind(this);
    }

    async getAuthorizationUrl() {
        const url = `/api/integrations/apps/${this.name}/getOauth2Link`;

        try {
            const response = await fetch(url).then((res) => res.json());
            return response?.authorizationUrl;
        } catch (error) {
            return error;
        }
    }

    async getItems(tokens): Promise<ServerResponseWithItemsData> {
        try {
            const url = this.itemsCallback(tokens);

            const appResponse = await fetch(url).then((res) => res.json());

            if (appResponse.ok) {
                return {
                    ...appResponse,
                    itemsData: { items: appResponse.data },
                };
            } else {
                throw appResponse;
            }
        } catch (error) {
            return error;
        }
    }

    async revoke(tokens): Promise<ServerResponseWithItemsData> {
        try {
            const url = this.revokeCallback(tokens);

            const response: ServerResponseWithItemsData = await fetch(url).then((data) => data.json());
            if (response.ok) {
                return response;
            } else {
                throw response;
            }
        } catch (error) {
            return error;
        }
    }

    async createItem(tokens, fileMetadata): Promise<ServerResponseWithItemsData> {
        try {
            const url = this.createItemCallback(tokens);

            const response: ServerResponseWithItemsData = await fetch(url, {
                method: 'POST',
                body: JSON.stringify(fileMetadata),
            }).then((data) => data.json());
            if (response.ok) {
                return response;
            } else {
                throw response;
            }
        } catch (error) {
            return error;
        }
    }

    async readFileMetadata(tokens, id): Promise<ServerResponseWithItemsData> {
        try {
            const url = this.readFileMetadataCallback();

            const response: ServerResponseWithItemsData = await fetch(url, {
                method: 'POST',
                body: JSON.stringify({ tokens, id }),
            }).then((res) => res.json());
            if (response.ok) {
                return response;
            } else {
                throw response;
            }
        } catch (error) {
            return error;
        }
    }
}
