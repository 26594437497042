import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { GET_USER, COMPLETE_USER_SIGNUP, DISMISS_PROMPT } from '../actionTypes';

import { getUserSuccess, getUserFailure, completeUserSignupSuccess, completeUserSignupFailure } from './actions';
import { addToCustomerio, prepareCompletedUserAndAssociatedEntities } from '../../utils/helpers';

import gqlRequest from '../api';
import { switchUser } from '@/utils/apiHelpers';
import { getUserSelector } from './selectors';
import { updateUser } from '../entities/actions';
import { NODE_ENV } from '@/config';

export function* getUserSaga(action) {
    try {
        const response = yield call(gqlRequest, action);
        const { data, errors } = response;
        if (data) {
            const user = data.me[0];
            yield put(getUserSuccess(user));
            const session = JSON.parse(localStorage.getItem('sessions') || '{}')[user.id];
            if (
                !session ||
                session.user.name !== user.name ||
                (session.user.avatarUrl && session.user.avatarUrl !== user.entity?.avatarUrl) ||
                session.user.email !== user.email
            ) {
                yield call(switchUser, user);
            }
        } else {
            yield put(getUserFailure({ error: errors[0].message }));
        }
    } catch (err) {
        yield put(getUserFailure({ error: err }));
    }
}

// export function* createUserSaga(action) {
//     try {
//         const currentUser = yield asyncGQL(SEARCH_ALL_PEOPLE_BY_EMAIL, { search: action.payload.email });
//         if (!currentUser?.data?.search_all_people_by_email?.[0]) {
//             // Create new user without associated entities like dataspace
//             const updatedAction = prepareNewUser(action);
//             const response = yield call(gqlRequest, updatedAction);
//             const newUser = response.data?.insertUser.requestEntity;
//             if (newUser) {
//                 yield put(createUserSuccess(newUser));
//                 signupWebhook(newUser);
//             } else yield put(createUserFailure({ error: response.errors }));
//         } else {
//             console.log('Email already in system');
//         }
//         if (action.payload?.callback) action.payload?.callback();
//     } catch (err) {
//         yield put(createUserFailure({ error: err }));
//     }
// }

export function* completeUserSignupSaga(action) {
    try {
        // Update completed user, and create user's Dataspace, Finder.
        const user = yield select(getUserSelector);
        const updatedAction = prepareCompletedUserAndAssociatedEntities(action, user);
        const response = yield call(gqlRequest, updatedAction);
        const newUser = response.data.update_people.returning[0];
        if (newUser) {
            yield put(completeUserSignupSuccess(response.data));
            if (NODE_ENV !== 'development') addToCustomerio(newUser.id, newUser.entity, newUser.created_at);
        } else {
            yield put(completeUserSignupFailure({ error: response.errors }));
        }
    } catch (err) {
        yield put(completeUserSignupFailure({ error: err }));
    }
}

export function* dismissPromptSaga() {
    const user = yield select(getUserSelector);
    yield put(updateUser(user.id, { ...user.entity, dismissedPrompts: user.dismissedPrompts }));
}

function* userSaga() {
    yield all([
        takeLatest(GET_USER, getUserSaga),
        // takeLatest(CREATE_USER, createUserSaga),
        takeLatest(COMPLETE_USER_SIGNUP, completeUserSignupSaga),
        takeLatest(DISMISS_PROMPT, dismissPromptSaga),
    ]);
}

export default userSaga;
