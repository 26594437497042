import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { getBrowsedInstanceSelector, isTrashPageSelector } from '@/redux/ui/selectors';
import { cloneEntity, isImage, uploadSingleFile } from '@/utils/helpers';
import { updateEntity } from '@/redux/entities/actions';
import useAppWrapper from '@/hooks/useAppWrapper';

async function createThumbnail(element: HTMLElement, filename: string, height = 500): Promise<File> {
    const { default: html2canvas } = await import('html2canvas');
    const canvas = await html2canvas(element, { scale: 0.5, allowTaint: true, useCORS: true, height });
    const blob = await new Promise<Blob>((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 1);
    });
    if (!blob) throw new Error('Blob could not be created');
    return new File([blob], filename, { type: blob?.type });
}

// TODO: try moving thumbnail creation outside of react lifecycle to reduce rerendering cost
const useCreateThumbnail = (entity: Entity) => {
    const dispatch = useDispatch();
    const isTrashPage = useSelector(isTrashPageSelector);
    const { isNotesApp } = useAppWrapper();
    const store = useStore();

    const [containerEl, setContainerEl] = useState<HTMLDivElement>();
    const createTimeoutId = useRef(null);

    const entityRef = useRef<Entity>(entity);

    const disableCreating =
        (isImage(entity?.url) && entity?.thumbnailUrl) ||
        ['HList', 'IntegrationItem'].includes(entity?.['@type']) ||
        isTrashPage ||
        isNotesApp;

    const onRefChangeCallback = useCallback((node) => {
        setContainerEl(node);
    }, []);

    useEffect(() => {
        entityRef.current = entity;
    }, [entity]);

    useEffect(() => {
        const browsedInstance = getBrowsedInstanceSelector(store.getState());
        const thumbnailEid = browsedInstance?.id;
        if (entityRef.current && thumbnailEid && containerEl && !disableCreating) {
            if (thumbnailEid !== entityRef.current?.metadata.id) {
                // If the thumbnailEid has changed, cancel the previous timeout
                clearTimeout(createTimeoutId.current);
            }

            // Set a new timeout to create the thumbnail
            createTimeoutId.current = setTimeout(() => {
                if (thumbnailEid && containerEl && entityRef.current?.metadata.id === thumbnailEid) {
                    handleCreateThumbnail(containerEl);
                }
            }, 3000);
        }
        return () => {
            if (createTimeoutId.current) clearTimeout(createTimeoutId.current);
        };
    }, [containerEl, disableCreating, store]);

    const handleCreateThumbnail = (containerEl) => {
        const entity = entityRef.current;
        if (entity && entity.metadata.condensed === false) {
            const filename = `thumbnail_${entity.metadata.id}.jpeg`;
            const snapshotHeight = containerEl.getBoundingClientRect().width * 0.66; // means that snapshot will keep stale aspect-ratio 3/2
            createThumbnail(containerEl, filename, snapshotHeight)
                .then((file: File) => uploadSingleFile(file, filename))
                .then((data) => {
                    const newEntity = cloneEntity(entityRef.current);
                    newEntity.thumbnailUrl = data.url;
                    dispatch(updateEntity(entity.metadata.id, newEntity, 'useCreateThumbnail'));
                })
                .catch((error) => {
                    console.error('Thumbnail could not be created: ', error);
                });
        }
        createTimeoutId.current = null;
    };

    return onRefChangeCallback;
};

export default useCreateThumbnail;
