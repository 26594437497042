import React, { useEffect, useState } from 'react';
import Text from '@/components/DesignSystem/Text';
import HListPreviewStyles from '@/components/entities/subcomponents/HListPreview.module.scss';
import { dropFilename, getLocalFileMetadata } from './helpers';
import { LocalFilesAdapter } from '.';

const LocalFileEntityView = ({ entity }: { entity: Entity }) => {
    const [fileData, setFileData] = useState(null);

    useEffect(() => {
        // if (!entity?.foreignId) return;
        getLocalFileMetadata(entity?.foreignId).then((response) => {
            if (response?.ok) {
                setFileData(response?.data);
            }
        });
    }, [entity?.foreignId]);

    return (
        <div>
            <div className={HListPreviewStyles.container}>
                <div className={HListPreviewStyles.position}>
                    <div className={HListPreviewStyles.details}>
                        <div className={`${HListPreviewStyles.title} ${HListPreviewStyles.withIcon}`}>
                            {new LocalFilesAdapter().getEntityIcon(entity)}

                            <Text align="center" noMargin>
                                {entity?.name || 'Untitled Item'}
                            </Text>
                        </div>

                        <div className={HListPreviewStyles.metadata}>
                            <>
                                {entity?.foreignId && (
                                    <span className={HListPreviewStyles.row} key={'metadata-path' + entity?.foreignId}>
                                        <Text
                                            align="center"
                                            variant="inactive"
                                            size="xs"
                                            className={HListPreviewStyles.label}
                                        >
                                            Location
                                        </Text>
                                        <Text align="center" size="xs" className={HListPreviewStyles.value}>
                                            {dropFilename(entity?.foreignId)}
                                        </Text>
                                    </span>
                                )}
                                {Object.keys(fileData?.metadata || {})?.length &&
                                    Object.keys(fileData?.metadata).map((key, i) => (
                                        <span
                                            className={HListPreviewStyles.row}
                                            key={'metadata-' + i + entity?.foreignId}
                                        >
                                            <Text
                                                align="center"
                                                variant="inactive"
                                                size="xs"
                                                className={HListPreviewStyles.label}
                                            >
                                                {fileData?.metadata[key].name}
                                            </Text>
                                            <Text align="center" size="xs" className={HListPreviewStyles.value}>
                                                {fileData?.metadata[key].value}
                                            </Text>
                                        </span>
                                    ))}
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocalFileEntityView;
