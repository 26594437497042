export default function CloseMdIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.4697 13.5303C12.7626 13.8232 13.2374 13.8232 13.5303 13.5303C13.8232 13.2374 13.8232 12.7626 13.5303 12.4697L8.56066 7.5L13.5303 2.53033C13.8232 2.23744 13.8232 1.76256 13.5303 1.46967C13.2374 1.17678 12.7626 1.17678 12.4697 1.46967L7.5 6.43934L2.53033 1.46967C2.23744 1.17678 1.76256 1.17678 1.46967 1.46967C1.17678 1.76256 1.17678 2.23744 1.46967 2.53033L6.43934 7.5L1.46967 12.4697C1.17678 12.7626 1.17678 13.2374 1.46967 13.5303C1.76256 13.8232 2.23744 13.8232 2.53033 13.5303L7.5 8.56066L12.4697 13.5303Z"
                fill="#333333"
            />
        </svg>
    );
}
