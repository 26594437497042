import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { PanelWrapper, usePanel } from '../wrappers/modal';

const FlexiblePanel = dynamic(() => import('./FlexiblePanel'), { ssr: false });
import { getOpenPanelSelector, getRecentQuickNotesSelector } from '../../redux/ui/selectors';
import EntityContainer from '../entities/EntityContainer';
import SaveToDataspaceButton from '../entities/subcomponents/SaveToDataspaceButton';
import { useEntity } from '../../hooks/reduxHooks';
import TimeAgo from 'react-timeago';
import typeInfo from '../entities/typeInfo';
import Button from '../DesignSystem/Button';
import CloseMdIcon from '@/icons/CloseMdIcon';
import { BASE_ORIGIN } from '@/config';
import { isDraftSelector } from '@/redux/entities/selectors';

const PanelQuickNote = ({ eid, setQuickNotePanelEid }) => {
    const panelName = 'quick-note-panel';
    const panelOpen = useSelector(getOpenPanelSelector(panelName));
    const entity = useEntity(eid, true, 'PanelQuickNote');
    const dataspaceEntity = useEntity(entity?.metadata?.dsid, true, 'PanelQuickNote2');
    const isDraft = useSelector(isDraftSelector(eid));

    const { close } = usePanel(panelName);

    const handleNewTab = function (): void {
        if (window) {
            window.open(`${BASE_ORIGIN}/entity/${eid}`);
        }
    };

    return (
        <>
            <PanelWrapper panelName={panelName}>
                <FlexiblePanel
                    initWidth={500}
                    initHeight={400}
                    right={50}
                    left={50}
                    disableKeyboardMove={true}
                    onFocus={() => console.log('Panel is clicked')}
                    className={'my-panel-custom-class'}
                    onRequestClose={close}
                    isOpen={panelOpen}
                >
                    <div className="panel">
                        <div className={'panelHeader'}>
                            <div className={'panelTitle'}>
                                <h6>{entity?.name ? entity.name : 'Untitled Text'}</h6>
                                <span className="secondary">{dataspaceEntity?.name}</span>
                            </div>
                            <div className="panelButtons">
                                {isDraft && <SaveToDataspaceButton eid={eid} />}
                                <Button size="s" variant="plain" onClick={handleNewTab}>
                                    Open
                                </Button>
                                <Button size="s" variant="plain" icon={CloseMdIcon} onClick={close} />
                            </div>
                        </div>
                        <div className={'panelContent'}>
                            <EntityContainer eid={eid} infoMode="quick-note" />
                        </div>
                    </div>
                    <div className="recentQuickNoteContainer">
                        <QuickNotesStack quickNotePanelEid={eid} setQuickNotePanelEid={setQuickNotePanelEid} />
                    </div>
                </FlexiblePanel>
            </PanelWrapper>
        </>
    );
};

export default PanelQuickNote;

const QuickNotesStack = ({ setQuickNotePanelEid, quickNotePanelEid }) => {
    const recentQuickNotes = useSelector(getRecentQuickNotesSelector);

    return (
        <>
            {recentQuickNotes?.map((eid) => {
                return (
                    <RecentQuickNoteCard
                        key={`recent-quick-note-${eid}`}
                        eid={eid}
                        setQuickNotePanelEid={setQuickNotePanelEid}
                        quickNotePanelEid={quickNotePanelEid}
                    />
                );
            })}
        </>
    );
};

const RecentQuickNoteCard = ({ eid, setQuickNotePanelEid, quickNotePanelEid }) => {
    const entity = useEntity(eid, true, 'RecentQuickNoteCard');
    const dataspaceEntity = useEntity(entity?.metadata?.dsid, true, 'RecentQuickNoteCard2');
    const handleClick = () => setQuickNotePanelEid(eid);
    const active = quickNotePanelEid === eid;

    return (
        <div className={`recentQuickNoteCard ${active ? 'activeNote' : ''}`} onClick={handleClick}>
            <div className="textXS recentQuickNoteCardTitle">
                {entity?.name || `Untitled ${typeInfo[entity?.['@type']]?.fancyName}`}
            </div>
            <div className="textXXSMedium secondary" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div className="recentQuickNoteCardSubtitle">
                    <div className="recentQuickNoteCardSubtitle">{dataspaceEntity?.name || 'Drafts'}</div>
                </div>
                <div className="recentQuickNoteCardTimestamp">
                    &nbsp;| <TimeAgo date={entity?.metadata?.updated_at} />
                </div>
            </div>
        </div>
    );
};
