export default function OpenInWorkspaceIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2 .38C1.1.38.37 1.1.37 2v3c0 .9.73 1.63 1.63 1.63h3c.9 0 1.63-.73 1.63-1.63V2C6.63 1.1 5.9.37 5 .37H2ZM1.62 2c0-.2.17-.38.38-.38h3c.2 0 .38.17.38.38v3c0 .2-.17.38-.38.38H2A.37.37 0 0 1 1.62 5V2ZM11 7.38c-.9 0-1.63.72-1.63 1.62v2c0 .9.73 1.63 1.63 1.63h2c.9 0 1.63-.73 1.63-1.63V9c0-.9-.73-1.63-1.63-1.63h-2ZM10.62 9c0-.2.17-.38.38-.38h2c.2 0 .38.17.38.38v2c0 .2-.17.38-.38.38h-2a.38.38 0 0 1-.38-.38V9Zm-8.74 1.5c0-.62.5-1.13 1.12-1.13h3c.62 0 1.13.5 1.13 1.13v3c0 .62-.5 1.13-1.13 1.13H3c-.62 0-1.13-.5-1.13-1.13v-3Zm1.25.13v2.74h2.75v-2.74H3.13Z"
                fill="#4D4D4D"
            />
            <path d="m7 8 6.92-6.92M14 4V1h-3.01" stroke="#4D4D4D" />
        </svg>
    );
}
