import React, { memo } from 'react';
import styles from './BaseBottomBar.module.scss';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
// import { getBrowsedInstanceSelector, getPreviewSizeSelector } from '@/redux/ui/selectors';
import { getUserSelector } from '@/redux/user/selectors';
import DeleteIcon from '@/icons/DeleteIcon';
import RecentMdIcon from '@/icons/RecentMdIcon';
import BaseSystemMenu from '@/components/menus/BaseSystemMenu';
import SharedIcon from '@/icons/SharedIcon';
import DraftSmIcon from '@/icons/DraftSmIcon';
import Tooltip from 'components/Tooltip';

const BaseBottomBar: React.FunctionComponent = () => {
    const router = useRouter();
    const { name, email, color, avatarUrl } = useSelector(getUserSelector);
    const userStyle = { backgroundColor: color };

    return (
        <div className={styles.baseBottomBar}>
            <div className={styles.leftBar}>
                <BaseSystemMenu />
                <Tooltip
                    placement="top"
                    content={
                        <div className={'tooltipTippy'}>
                            {name}
                            <span className={'tooltipLabel'}>{email}</span>
                        </div>
                    }
                    key="user-system-tooltip"
                    appendTo={() => document.body}
                >
                    <div style={userStyle} className={styles.bottomBarAvatar}>
                        <div className={styles.bottomBarIcon}>
                            {avatarUrl ? <img src={avatarUrl} alt="user avatar" /> : <span>{name?.[0]}</span>}
                        </div>
                    </div>
                </Tooltip>
                <span className={styles.bottomBarSeparator}>|</span>
                <Tooltip
                    placement="top"
                    content={<div className={'tooltipTippy'}>Trash</div>}
                    key="trash"
                    appendTo={() => document.body}
                >
                    <span
                        className={`${styles.bottomBarIcon} ${styles.greyFill}`}
                        onClick={() => router.push('/trash')}
                    >
                        <DeleteIcon />
                    </span>
                </Tooltip>
                <Tooltip
                    placement="top"
                    content={<div className={'tooltipTippy'}>Recent</div>}
                    key="recents"
                    appendTo={() => document.body}
                >
                    <span
                        className={`${styles.bottomBarIcon} ${styles.greyFill}`}
                        onClick={() => router.push('/recent')}
                    >
                        <RecentMdIcon />
                    </span>
                </Tooltip>
                <Tooltip
                    placement="top"
                    content={<div className={'tooltipTippy'}>Drafts</div>}
                    key="drafts"
                    appendTo={() => document.body}
                >
                    <span
                        className={`${styles.bottomBarIcon} ${styles.greyStroke}`}
                        onClick={() => router.push('/base/drafts')}
                    >
                        <DraftSmIcon />
                    </span>
                </Tooltip>
                <Tooltip
                    placement="top"
                    content={<div className={'tooltipTippy'}>Shared</div>}
                    key="shared"
                    appendTo={() => document.body}
                >
                    <span
                        className={`${styles.bottomBarIcon} ${styles.greyFill}`}
                        onClick={() => router.push('/base/shared')}
                    >
                        <SharedIcon />
                    </span>
                </Tooltip>
            </div>
        </div>
    );
};

export default memo(BaseBottomBar);
