export default function TextMdIcon(): JSX.Element {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            data-3d-icon={true}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="2.5481" y="0.875" width="10.9038" height="14.25" rx="1.54808" fill="white" />
            <rect
                x="2.5481"
                y="0.875"
                width="10.9038"
                height="14.25"
                rx="1.54808"
                stroke="#F2F2F2"
                strokeWidth="0.25"
            />
            <path
                d="M4.64526 5.2124H8.40255M4.64526 8.00086L11.3551 7.99967M4.64526 10.7893H9.5767"
                stroke="#666666"
                strokeLinecap="round"
            />
        </svg>
    );
}
