import { memo, ChangeEvent } from 'react';
import styles from './HlistSearchBar.module.scss';

type Props = {
    searchText: string;
    setSearchText: (value: string) => void;
};

const HlistSearchBar: React.FunctionComponent<Props> = ({ searchText, setSearchText }: Props) => {
    const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
        setSearchText(event.target.value);
    };
    return (
        <div className={styles.inputContainer}>
            <input type="text" value={searchText} onChange={onChange} placeholder="Search" className={styles.input} />
        </div>
    );
};

export default memo(HlistSearchBar);
