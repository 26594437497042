import { BASE_ORIGIN } from '@/config';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { debounce, difference } from 'lodash';
import { clsx } from 'clsx';

import { API } from '@/redux/api';
import { hideGlobalModal, toast } from '@/redux/ui/actions';
import { asyncGQL, asyncGQLAbortable, getHumanReadableUri, getEntityIcon } from '@/utils/helpers';
import Button from '../../DesignSystem/Button';
import UserAvatar from '../../entities/subcomponents/UserAvatar';
import typeInfo from '../../entities/typeInfo';
import {
    multiSelectComponents,
    multiSelectStyles,
    selectComponents,
    selectStyles as defaultSelectStyles,
    disabledSelectStyles,
} from './selectStyles';

import styles from './ShareEntityModal.module.scss';
import { getDataspaceSelector } from '@/redux/entities/selectors';
import Tabs from '@/components/DesignSystem/Tabs';

const userAccessSelectorStyles = {
    ...defaultSelectStyles,
    container: (provided) => ({
        ...provided,
        position: 'static',
        width: '150px',
        height: '32px',
    }),
    menu: (provided) => ({
        ...provided,
        top: 'auto',
        border: 'none',
        borderRadius: 5,
        boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
        width: '120px',
        overflow: 'hidden',
        padding: '2px 0',
        zIndex: 10_000,
    }),
};

const accessTemplateVariants = [
    { label: 'Only Me', value: 'onlyMe' },
    { label: 'DS Members', value: 'dataspaceMembers' },
    { label: 'Custom', value: 'custom' },
];

type AccessTemplate = 'onlyMe' | 'dataspaceMembers' | 'custom';
interface ShareEntityModalProps {
    entity: Entity;
}

type PeopleDataSubset = { id: string; email: string; name: string; avatar_url: string };

type Option<T> = { label: string; value: T };

type RoleOption = Option<string[]>;

const EMAIL_REGEX = /^\s*[^\s,@]+@[^\s,.]+\.[^\s,]{2,}\s*$/;

const ROLE_OPTIONS: RoleOption[] = [
    {
        label: 'Can view',
        value: ['read'],
    },
    {
        label: 'Can edit',
        value: ['read', 'write', 'delete'],
    },
    {
        label: 'Can edit & share',
        value: ['read', 'write', 'delete', 'share'],
    },
];

const MORE_ROLE_OPTIONS: RoleOption[] = [
    ...ROLE_OPTIONS,
    {
        label: 'None',
        value: [],
    },
];

const ShareEntityModal: React.FC<ShareEntityModalProps> = ({ entity }) => {
    const [childEntities, setChildEntities] = useState([]);
    const [aclUsers, setAclUsers] = useState<{ user: RawEntity; role: RoleOption }[]>([]);
    const [foundUsers, setFoundUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState<Option<PeopleDataSubset>[]>([]);
    const [inputValue, setInputValue] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [selectedRole] = useState(ROLE_OPTIONS[0]);
    const [noteToUsers, setNoteToUsers] = useState('');
    const [focusedUser, setFocusedUser] = useState(null);
    const [strictACL, setStrictACL] = useState(false);
    const [isPending, setIsPending] = useState(false);
    const dispatch = useDispatch();
    const prevController = useRef<AbortController>();
    const containerElement = useRef<HTMLDivElement>();
    const dropdownElement = useRef<HTMLDivElement>();
    const dataspace = useSelector(getDataspaceSelector);
    const dataspaceMembers = Object.keys(dataspace?.members || {});
    const [currentAccessTemplate, setCurrentAccessTemplate] = useState<AccessTemplate>();

    useEffect(() => {
        setIsPending(true);
        asyncGQL(API.GET_ENTITY_ACL_USERS, { id: entity.metadata.id }).then((res) => {
            setStrictACL(res.data?.Entities_by_pk.strict_acl);
            // console.log(res.data?.Entities_by_pk)
            setAclUsers(
                res.data?.Entities_by_pk.acl_users.map((e) => ({
                    user: e.acl_user,
                    role: ROLE_OPTIONS.find((r) => !difference(e.permissions, r.value).length),
                })),
            );
            setIsPending(false);
        });
    }, []);

    useEffect(() => {
        if (strictACL === false) {
            setCurrentAccessTemplate('dataspaceMembers');
        } else {
            if (aclUsers.length === 1) {
                setCurrentAccessTemplate('onlyMe');
            } else {
                setCurrentAccessTemplate('custom');
            }
        }
    }, [strictACL, aclUsers]);

    const containerClickListener = useCallback((e) => {
        if (!dropdownElement.current?.contains(e.target)) {
            setDropdownVisible(false);
        }
    }, []);

    const findUsers = useCallback(
        debounce((value) => {
            value = value.trim();
            if (value.length < 3) return;

            if (prevController.current) {
                prevController.current.abort();
            }

            const [abortController, requestPromise] = asyncGQLAbortable(API.FIND_USERS, { search: `%${value}%` });
            prevController.current = abortController;
            requestPromise.then((res) => {
                const foundUsers = res.data?.people || [];
                if (!foundUsers.find((e) => e.email === value)) {
                    foundUsers.unshift({ id: value, email: value, name: value });
                }
                setFoundUsers(foundUsers);
                setDropdownVisible(!!foundUsers.length);
            });
        }, 300),
        [],
    );

    const handleInputChange = (newInputValue) => {
        setInputValue(newInputValue);
        findUsers(newInputValue);
    };

    const handleUsersChange = (values) => {
        setSelectedUsers(values);
    };

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => setNoteToUsers(e.target.value);

    const handleDoneClick = () => dispatch(hideGlobalModal('ShareEntityModal'));

    const addUser = (user) => {
        if (selectedUsers.find((e) => e.value.id === user.id)) return;

        setSelectedUsers([...selectedUsers, { label: user.email, value: user }]);

        if (foundUsers.length === 1) setDropdownVisible(false);
    };

    const shareWithUsers = () => {
        const { usersToBeCreated, existingUsers } = selectedUsers.reduce(
            (acc, e) => {
                if (e.value.id === e.value.email) {
                    acc.usersToBeCreated.push(e.value.id);
                } else {
                    acc.existingUsers.push(e.value.id);
                }
                return acc;
            },
            { usersToBeCreated: [], existingUsers: [] },
        );

        shareEntity(existingUsers, usersToBeCreated, selectedRole.value, true);
        dispatch(hideGlobalModal('ShareEntityModal'));
    };

    const updateUserPermissions = (user: RawEntity, role: RoleOption, index: number) => {
        const aclUsersCopy = aclUsers.slice();
        aclUsersCopy[index] = { user, role };
        setAclUsers(aclUsersCopy);
        shareEntity([user.id], [], role.value, true);
    };

    const shareEntity = (userIds, newUserEmails, permissions, strict) => {
        return asyncGQL(
            API.SHARE_ENTITY,
            {
                id: entity.metadata.id,
                userIds,
                permissions,
                sendEmail: true,
                noteToUsers,
                newUserEmails,
                strictAcl: strict,
            },
            false,
        );
    };

    const copyLink = () => {
        navigator.clipboard.writeText(`${BASE_ORIGIN}/entity/${getHumanReadableUri(entity.name, entity.metadata.id)}`);
        dispatch(toast({ text: 'Link copied to clipboard', msgType: 'success' }));
    };

    const entityHasChildren = entity.childrenList && entity.childrenList.length > 0;
    useEffect(() => {
        if (!entityHasChildren) return;

        asyncGQL(API.GET_ENTITIES, { ids: entity.childrenList }, false).then((res) =>
            setChildEntities(res.data.Entities),
        );
    }, []);

    useEffect(() => {
        asyncGQL(API.GET_ENTITY_ACL_USERS, { id: entity.metadata.id }).then((res) => {
            const aclUsersUnsorted = res.data?.Entities_by_pk.acl_users.map((e) => ({
                user: e.acl_user,
                role: ROLE_OPTIONS.find((r) => !difference(e.permissions, r.value).length),
            }));

            const ownerUser = aclUsersUnsorted.find(({ user }) => entity.metadata.owned_by === user.id);
            const notOwnerUsers = aclUsersUnsorted.filter(({ user }) => entity.metadata.owned_by !== user.id);

            setAclUsers([ownerUser, ...notOwnerUsers]);
        });
    }, []);

    useEffect(() => {
        const container = containerElement.current;

        if (!container) return;

        container.addEventListener('click', containerClickListener);

        return () => container && container.removeEventListener('click', containerClickListener);
    }, [containerClickListener]);

    useEffect(() => () => prevController.current && prevController.current.abort(), []);

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (foundUsers.length === 0) return;

            let newIndex: number;
            const currentIndex = foundUsers.findIndex((user) => user.id === focusedUser?.id);

            switch (e.key) {
                case 'ArrowDown':
                    newIndex = (currentIndex + 1) % foundUsers.length;
                    break;

                case 'ArrowUp':
                    newIndex = (currentIndex - 1 + foundUsers.length) % foundUsers.length;
                    break;

                case 'Enter':
                    if (focusedUser) {
                        addUser(focusedUser);
                        setFocusedUser(null);
                        setInputValue('');
                        setFoundUsers((users) => users.slice(1));
                    }
                    return;

                case 'Escape':
                    setDropdownVisible(false);
                    setFoundUsers([]);
                    return;

                default:
                    return;
            }

            const domNode = document.querySelector(`[data-user-id="${foundUsers[newIndex].id}"]`);
            if (domNode) {
                setFocusedUser(foundUsers[newIndex]);
                domNode.scrollIntoView({ block: 'center' });
            }
        };

        if (dropdownVisible && foundUsers.length > 0) {
            document.addEventListener('keydown', handleKeyDown);
        }

        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [dropdownVisible, foundUsers.length, focusedUser]);

    const applyAccessTemplate = (accessTemplate) => {
        const userIds = [];
        aclUsers.map((entity) => {
            const userId = entity?.user?.id;
            if (userId) {
                userIds.push(userId);
            }
        });

        switch (accessTemplate) {
            case 'onlyMe':
                shareEntity(userIds, [], [], true);
                setSelectedUsers([]);
                setCurrentAccessTemplate('onlyMe');
                break;
            case 'dataspaceMembers':
                shareEntity(dataspaceMembers, [], ROLE_OPTIONS[2].value, false);
                setSelectedUsers([]);
                setCurrentAccessTemplate('dataspaceMembers');
                break;
            case 'custom':
                shareEntity(userIds, [], ROLE_OPTIONS[2].value, true);
                setSelectedUsers([]);
                setCurrentAccessTemplate('custom');
                break;
        }
    };

    return (
        <div className={styles.container} ref={containerElement}>
            <div className={styles.header}>
                <div className={styles.title}>{entity.name || 'Untitled ' + typeInfo[entity['@type']].fancyName}</div>
                {entityHasChildren && (
                    <div className={styles.entityAmountContainer}>
                        <div className={styles.entityAmount}>
                            {`${entity.childrenList.length} entit${entity.childrenList.length === 1 ? 'y' : 'ies'}`}
                        </div>
                        <div className={styles.sharedEntitiesIconsContainer}>
                            {childEntities.map((child, index) => {
                                if (index > 3) return;
                                return (
                                    <div className={styles.entityIconContainer} key={entity.id}>
                                        {getEntityIcon(child.entity)}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
            {isPending && <div className={styles.loading}>Loading...</div>}
            {!isPending && (
                <div className={styles.content}>
                    <div className={styles.subtitle}>People with access</div>
                    <div
                        className={`${styles.section} ${styles.shareSection} ${
                            selectedUsers.length ? '' : styles.noDivider
                        }`}
                    >
                        <Tabs
                            tabsData={accessTemplateVariants}
                            value={currentAccessTemplate}
                            onClick={applyAccessTemplate}
                        />
                    </div>
                    {currentAccessTemplate === 'custom' && (
                        <div
                            className={`${styles.section} ${styles.shareSection} ${
                                selectedUsers.length ? '' : styles.noDivider
                            } ${styles.customAccessWrapper}`}
                        >
                            <div
                                className={`${styles.section} ${styles.shareSection} ${
                                    selectedUsers.length ? '' : styles.noDivider
                                }`}
                            >
                                <div className={styles.selectsContainer}>
                                    <CreatableSelect
                                        isMulti
                                        isClearable
                                        autoFocus
                                        styles={multiSelectStyles}
                                        components={multiSelectComponents}
                                        placeholder="Add emails or people"
                                        menuIsOpen={false}
                                        onChange={handleUsersChange}
                                        value={selectedUsers}
                                        inputValue={inputValue}
                                        onInputChange={handleInputChange}
                                    />
                                    <Button
                                        className={clsx(styles.modalButton, styles.shareButton)}
                                        variant="plain"
                                        onClick={shareWithUsers}
                                        disabled={!selectedUsers.length}
                                    >
                                        Share
                                    </Button>
                                    {dropdownVisible && !!foundUsers.length && (
                                        <div
                                            className={styles.foundUsersDropdown}
                                            ref={dropdownElement}
                                            onMouseLeave={() => setFocusedUser(null)}
                                        >
                                            {foundUsers.map((user) => (
                                                <div
                                                    key={user.id}
                                                    className={`${styles.foundUser} ${
                                                        focusedUser?.id === user?.id ? styles.focusedUser : ''
                                                    }`}
                                                    onClick={() => addUser(user)}
                                                    onMouseEnter={() => setFocusedUser(user)}
                                                    data-user-id={user.id}
                                                >
                                                    <UserAvatar
                                                        name={user.name || user.email}
                                                        avatarUrl={user.avatar_url}
                                                        size={'24px'}
                                                    />
                                                    <div className={styles.userNameEmail}>
                                                        <div className={styles.userInfo}>
                                                            <div>{user.name}</div>
                                                            {user.id !== user.email && <div>{user.email}</div>}
                                                        </div>
                                                        {user.id === user.email &&
                                                            (user.email.match(EMAIL_REGEX) ? (
                                                                <div>Account will be created</div>
                                                            ) : (
                                                                <div className={styles.invalidEmail}>
                                                                    Invalid email to create an account
                                                                </div>
                                                            ))}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {!!selectedUsers.length && (
                                    <textarea
                                        placeholder="Message"
                                        onChange={handleTextareaChange}
                                        value={noteToUsers}
                                    ></textarea>
                                )}
                            </div>
                            <div className={`${styles.section} ${styles.usersSection}`}>
                                {aclUsers.map(({ user, role }, index) => {
                                    const isOwner = entity.metadata.owned_by === user.id;
                                    const ownerRole: RoleOption = {
                                        label: 'Owner',
                                        value: ['read', 'write', 'delete'],
                                    };
                                    const selectStyles = isOwner
                                        ? { ...userAccessSelectorStyles, ...disabledSelectStyles }
                                        : userAccessSelectorStyles;

                                    return (
                                        <div key={user.id} className={styles.user}>
                                            <div className={styles.userContent}>
                                                <UserAvatar
                                                    name={user.entity.name || user.entity.email}
                                                    avatarUrl={user.entity.avatarUrl}
                                                    size={'24px'}
                                                />
                                                <div className={styles.userInfo}>
                                                    <div className={styles.userName}>
                                                        {user.entity.name ?? '<No name>'}
                                                    </div>
                                                    <div className={styles.userEmail}>{user.entity.email}</div>
                                                </div>
                                            </div>
                                            <Select
                                                value={isOwner ? ownerRole : role}
                                                options={MORE_ROLE_OPTIONS}
                                                onChange={(option) => updateUserPermissions(user, option, index)}
                                                styles={selectStyles}
                                                components={selectComponents}
                                                isDisabled={isOwner}
                                                isSearchable={false}
                                                blurInputOnSelect
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </div>
            )}
            <div className={styles.footer}>
                {/* <div className={styles.accessContainer}>
                    <div className={styles.accessLabel}>
                        <LinkCircleIcon />
                        <div className={styles.accessText}>Anybody with the link</div>
                    </div>
                    <Select
                        value={currentAccessValue}
                        options={ACCESS_OPTIONS}
                        onChange={(option) => setCurrentAccessValue(option)}
                        styles={selectStyles}
                        components={selectComponents}
                        isSearchable={false}
                        blurInputOnSelect
                    />
                </div> */}
                <div className={styles.buttonsContainer}>
                    <Button className={clsx(styles.modalButton, styles.copyButton)} variant="plain" onClick={copyLink}>
                        Copy link
                    </Button>
                    <Button
                        className={clsx(styles.modalButton, styles.doneButton)}
                        variant="plain"
                        onClick={handleDoneClick}
                    >
                        Done
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ShareEntityModal;
