import { useEffect } from 'react';
// import { NODE_ENV } from '@/config';
// const isDev = NODE_ENV === 'development';

const useOutsideClick = (ref, callback, debugMessage?) => {
    useEffect(() => {
        function handleClickOutside(event) {
            if (false) console.log('handleClickOutside', debugMessage, ref.current, event.target);
            if (ref.current && !ref.current.contains(event.target)) callback();
        }
        document.addEventListener('mousedown', handleClickOutside);
        // document.addEventListener('focusout', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            // document.removeEventListener('focusout', handleClickOutside);
        };
    }, [ref, callback]);
};

export default useOutsideClick;
