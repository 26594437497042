import React, { memo, useState } from 'react';
import { getShortcutLabels, getFavicon, isImage, isVideo, isPdf, getIsAppFolderId } from '@/utils/helpers';
import styles from '../Entity.module.scss';
import typeInfo from '@/components/entities/typeInfo';
import { useDrag } from 'react-dnd';
import { ENTITY } from '@/dndTypes';
import QuickCreateButton from '@/components/modals/contextComponents/quickCreateButton';
import 'emoji-mart/css/emoji-mart.css';
import UserAvatars from './UserAvatars';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedEntitiesSelector, getBrowsedInstanceSelector, isTrashPageSelector } from '@/redux/ui/selectors';
import { getDataspaceSelector, getIsEmptyDeletedEntityList, isDraftSelector } from '@/redux/entities/selectors';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import EmojiPicker from '@/components/forms/emojiPicker';
import { Emoji } from '@/utils/helpers';
import SaveToDataspaceButton from './SaveToDataspaceButton';
import { useRouter } from 'next/router';
import 'tippy.js/dist/tippy.css';
import useFirstLoad from '@/hooks/firstLoad';
import SearchIcon from '@/icons/SearchIcon';
import HomeMdIcon from '@/icons/HomeMdIcon';
import ImageIcon from '@/icons/ImageIcon';
import VideoMdIcon from '@/icons/VideoMdIcon';
import GlobeMdIcon from '@/icons/GlobeMdIcon';
import { getIntegrationPendingSelector } from 'redux/integrations/selectors';
import Button from 'components/DesignSystem/Button';
import { getIsUserAnonymous } from 'redux/user/selectors';
import DeleteIcon from '@/icons/DeleteIcon';
import SearchInput from '@/components/forms/searchInput';
import Thumbnail from '@/components/entities/subcomponents/Thumbnail/Thumbnail';
import { showGlobalModal, triggerScrollToBrowsed } from '@/redux/ui/actions';
import IntegrationAccountIcon from '@/components/entities/subcomponents/IntegrationAccountIcon';
import { INTEGRATIONS } from 'integrations/constants';
import { DRAFTS_FINDER_ID, SHARED_FINDER_ID } from '@/redux/constants';
import SharedIcon from '@/icons/SharedIcon';
import DraftSmIcon from '@/icons/DraftSmIcon';
import ArrowLeftIcon from '@/icons/ArrowLeftIcon';
import ArrowRightIcon from '@/icons/ArrowRightIcon';
import ControlMenu from '@/components/entities/subcomponents/ControlMenu';
import PinnedIcon from '@/icons/PinnedIcon';
import PDFIcon from '@/icons/PDFIcon';
import TippyTooltip from 'components/Tooltip';
import { parseUID } from '@/utils/IDManager';

const HListControlBar: React.FunctionComponent<HListControlBarProps> = (props: HListControlBarProps) => {
    const {
        eid: uid,
        entity,
        infoMode,
        searchText,
        searchCallback,
        hListRootStack,
        prevHListRoot,
        nextHListRoot,
        changeHListRoot,
    } = props;

    const { fid } = parseUID(uid);

    const dispatch = useDispatch();
    const selectedItems = useSelector(getSelectedEntitiesSelector);
    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const isIntegrationPending = useSelector(getIntegrationPendingSelector);
    const isEmptyDeletedList = useSelector(getIsEmptyDeletedEntityList);
    const isDraft = useSelector(isDraftSelector(uid));
    const [isPickerOpened, setPickerOpened] = useState(false);
    const [isSearchInputHovered, setIsSearchInputHovered] = useState(false);
    const router = useRouter();
    const isDashboard = router.pathname.includes('dashboard');
    const isTrashPage = useSelector(isTrashPageSelector);
    const canSearch = entity?.childrenList?.length > 0;
    const userIsAnonymous = useSelector(getIsUserAnonymous);

    const { sonar, disableSonar } = useFirstLoad();
    const showSonar = entity['@type'] === 'Finder' && sonar;

    const currentDataspace = useSelector(getDataspaceSelector);
    const fancyTypeName = typeInfo[entity['@type']].fancyName;
    const titlePlaceholder =
        fancyTypeName === 'Base' ? `${currentDataspace?.name}` : entity?.name || `Untitled ${fancyTypeName}`;

    const [searchInputFocused, setSearchInputFocused] = useState(false);

    const onCloseSearch = () => {
        if (browsedInstance?.id) {
            dispatch(triggerScrollToBrowsed(true));
        }
    };

    let icon;
    if (canSearch && (isSearchInputHovered || searchInputFocused)) {
        icon = <SearchIcon />;
    } else if (entity.emoji) {
        icon = <Emoji emoji={entity.emoji.id} size={16} sheetSize={64} skin={entity.emoji.skin} />;
    } else if (entity['@type'] === 'Finder') {
        if (currentDataspace?.iconUrl) {
            icon = <img src={currentDataspace.iconUrl} className={styles.dataspaceIcon} />;
        } else {
            if (uid === SHARED_FINDER_ID) {
                icon = <SharedIcon />;
            } else if (uid === DRAFTS_FINDER_ID) {
                icon = <DraftSmIcon />;
            } else {
                icon = <HomeMdIcon />;
            }
        }
    } else if (entity['@type'] === 'IntegrationItem') {
        if (fid === 'root') {
            icon = <IntegrationAccountIcon id={`${props.app}_${props.appId}`} />;
        } else {
            icon = INTEGRATIONS[entity?.app].getEntityIcon(entity);
        }
    } else if (entity['@type'] === 'DigitalDocument') {
        if (isImage(entity?.url)) {
            entity?.thumbnailUrl ? (icon = <Thumbnail thumbnailUrl={entity?.thumbnailUrl} />) : (icon = <ImageIcon />);
        }
        if (isVideo(entity?.url)) icon = <VideoMdIcon />;
        if (isPdf(entity?.url)) icon = <PDFIcon />;
    } else if (entity['@type'] === 'Webwindow') {
        icon = getFavicon(entity.url);
    } else {
        icon = typeInfo[entity['@type']].icon;
    }

    const [, drag] = useDrag({
        type: ENTITY,
        item: { eid: uid, type: ENTITY },
    });

    const getSelectedItemsLabel = function (): React.ReactElement {
        // Fix for other HLists
        if (selectedItems.length > 1) {
            return <span className={styles.selectedItemsLabel}>{selectedItems.length} items</span>;
        } else {
            return;
        }
    };

    const hoverSearch = () => {
        if (canSearch) setIsSearchInputHovered(true);
    };

    const unHoverSearch = () => {
        if (!searchText) setIsSearchInputHovered(false);
    };

    const handleEmptyTrash = () => dispatch(showGlobalModal('ConfirmHardRemoveModal', { allFiles: true }));

    const isSpecialAppFolder = getIsAppFolderId(uid);

    const canCreateChildren =
        !userIsAnonymous &&
        (['Finder', 'HList'].includes(entity['@type']) ||
            (entity?.childrenList?.length > 0 && entity['@type'] !== 'IntegrationItem')) &&
        !isDraft &&
        !isDashboard &&
        infoMode !== 'workspace' &&
        uid !== SHARED_FINDER_ID &&
        !isSpecialAppFolder;
    const canSaveToDS =
        isDraft && entity['@type'] === 'IntegrationItem' && fid !== 'root' && !router.pathname.includes('base');
    const isIntegrationItemsRoot = entity?.app && entity?.appId && fid === 'root';

    const handleChangeRoot = (event) => {
        event.preventDefault();
        changeHListRoot();
    };

    return (
        <>
            {!['panel', 'quick-note'].includes(String(infoMode)) && !isTrashPage && (
                <div className={styles.topBar} data-top-bar-scoped-eid={canCreateChildren ? uid : null}>
                    <div ref={drag} className={styles.title} onMouseEnter={hoverSearch} onMouseLeave={unHoverSearch}>
                        <div className={styles.iconWrapper} onClick={(): void => setPickerOpened(true)}>
                            <div className={styles.emojiPickerBg} />
                            <div className={styles.iconContainer}>{icon}</div>
                        </div>
                        {canSearch ? (
                            <div className={styles.noStyleInput}>
                                <SearchInput
                                    placeholder={titlePlaceholder}
                                    autoFocus={false}
                                    className={styles.textFieldInput}
                                    callback={searchCallback}
                                    focused={setSearchInputFocused}
                                    onCloseSearch={onCloseSearch}
                                />
                            </div>
                        ) : (
                            <div className={styles.noStyleInput}>
                                <div className={styles.textFieldInput}>{titlePlaceholder}</div>
                            </div>
                        )}

                        {/* <div>{getAdditionalTypes()}</div> */}
                    </div>
                    {entity['@type'] !== 'Finder' ? (
                        <>
                            <UserAvatars eid={uid} context="EntityFrame" showIcons={true} />
                            {/* When fullscreen on finder route is available, allow that too */}
                            {/* <FullScreenOpenIcon /> */}
                        </>
                    ) : null}
                    <div className={styles.btnGroup}>
                        <div>{getSelectedItemsLabel()}</div>
                        {/* {entity.childrenList && <AddEntityButton eid={eid} entity={entity} />} */}
                        {entity?.metadata?.public_entity && (
                            <OverlayTrigger
                                placement="bottom"
                                delay={{ show: 500, hide: 0 }}
                                overlay={
                                    <Tooltip id="remove-schema-button">
                                        <span className="textXXS">Public</span>
                                    </Tooltip>
                                }
                            >
                                <div className={styles.frameButton}>
                                    <GlobeMdIcon />
                                </div>
                            </OverlayTrigger>
                        )}
                        {canSaveToDS && <SaveToDataspaceButton eid={uid} entity={entity} fullButton />}
                        {isIntegrationPending && <Spinner size="sm" animation="border" variant="primary" />}
                        {isIntegrationItemsRoot && <ControlMenu entity={entity} eid={uid} />}
                        {browsedInstance?.id && (
                            <TippyTooltip
                                key={`pin-current-entity`}
                                placement="bottom"
                                appendTo={() => document.body}
                                delay={100}
                                content={
                                    <div className={'tooltipTippy tooltipTippyCol'} id={`tooltip-${uid}`}>
                                        Scope Base to current entity
                                    </div>
                                }
                            >
                                <div>
                                    <Button
                                        size="m"
                                        variant="plain"
                                        icon={PinnedIcon}
                                        className="controlMenuButton"
                                        onClick={handleChangeRoot}
                                        disabled={uid === browsedInstance?.id}
                                    />
                                </div>
                            </TippyTooltip>
                        )}
                        {canCreateChildren && (
                            <TippyTooltip
                                key={`qc-${uid}`}
                                placement="top"
                                appendTo={() => document.body}
                                delay={100}
                                content={
                                    <div className={'tooltipTippy tooltipTippyCol'} id={`tooltip-${uid}`}>
                                        Quickly create an entity inside
                                        <span className={'tooltipSubtext'}>{getShortcutLabels().AddInScoped}</span>
                                    </div>
                                }
                            >
                                <div className={showSonar ? 'btn-sonar-wrapper' : ''}>
                                    <QuickCreateButton
                                        eid={uid}
                                        className={showSonar ? 'btn-sonar controlMenuButton' : 'controlMenuButton'}
                                        onClickCallback={showSonar ? disableSonar : null}
                                    />
                                </div>
                            </TippyTooltip>
                        )}
                        {hListRootStack?.length > 1 && (
                            <>
                                <Button
                                    size="m"
                                    variant="plain"
                                    icon={ArrowLeftIcon}
                                    className="controlMenuButton"
                                    onClick={prevHListRoot}
                                    disabled={uid === hListRootStack[0]?.eid}
                                />
                                <Button
                                    size="m"
                                    variant="plain"
                                    icon={ArrowRightIcon}
                                    className="controlMenuButton"
                                    onClick={nextHListRoot}
                                    disabled={uid === hListRootStack[hListRootStack.length - 1]?.eid}
                                />
                            </>
                        )}
                    </div>
                </div>
            )}
            {isTrashPage && (
                <div className={styles.topBar}>
                    <div className={styles.title}>
                        <div className={`${styles.iconWrapper} ${styles.defaultCursor}`}>
                            <div className={styles.iconContainer}>
                                <DeleteIcon />
                            </div>
                        </div>
                        <div className={styles.noStyleInput}>
                            <div className={`${styles.textFieldInput} ${styles.defaultCursor}`}>Deleted Entities</div>
                        </div>
                    </div>
                    <div className={styles.deletedEntitiesControls}>
                        <div>{getSelectedItemsLabel()}</div>
                        {!isEmptyDeletedList && (
                            <Button size="s" variant="plain" onClick={handleEmptyTrash}>
                                Empty trash
                            </Button>
                        )}
                    </div>
                </div>
            )}
            {entity['@type'] !== 'Finder' && !userIsAnonymous ? (
                <EmojiPicker
                    eid={uid}
                    entity={entity}
                    propertyKey={'emoji'}
                    isPickerOpened={isPickerOpened}
                    setPickerOpened={setPickerOpened}
                />
            ) : null}
        </>
    );
};

export default memo(HListControlBar);
