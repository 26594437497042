export default function CodeMdIcon(): JSX.Element {
    return (
        <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            data-3d-icon={true}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="3.26562" y="0.875" width="10.9038" height="14.25" rx="1.54808" fill="white" />
            <rect
                x="3.26562"
                y="0.875"
                width="10.9038"
                height="14.25"
                rx="1.54808"
                stroke="#F2F2F2"
                strokeWidth="0.25"
            />
            <path
                d="M11.2032 6.46875L12.7344 8L11.2032 9.53125M6.1689 9.53125L4.63765 8L6.1689 6.46875M9.27743 5.59375L7.96493 10.4062"
                stroke="#666666"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
