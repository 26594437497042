export default function BedrockInvertBlackIcon(): JSX.Element {
    return (
        <svg width="142" height="142" viewBox="0 0 142 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_di_24_1548)">
                <circle cx="71" cy="67" r="33.0323" fill="url(#paint0_radial_24_1548)" />
                <path
                    d="M70.9999 97.8402C88.0324 97.8402 101.84 84.0326 101.84 67C101.84 49.9675 88.0324 36.1599 70.9999 36.1599C53.9674 36.1599 40.1598 49.9675 40.1598 67C40.1598 84.0326 53.9674 97.8402 70.9999 97.8402Z"
                    fill="white"
                />
                <path
                    d="M47.7273 70.5134C46.2259 71.2942 44.7845 72.0449 43.2229 72.6755C43.1536 72.7026 43.0967 72.7544 43.0634 72.821C43.0301 72.8876 43.0228 72.9641 43.0428 73.0359C43.4031 74.7776 43.9436 76.4292 44.6043 77.9907C44.6313 78.0601 44.6832 78.1169 44.7498 78.1502C44.8163 78.1835 44.8929 78.1908 44.9646 78.1709C47.8075 77.494 50.5174 76.3472 52.9825 74.7776C57.9373 72.0449 63.5228 68.9218 75.6546 68.9218C85.7144 68.9218 93.3719 67.9008 99.3778 66.6396C99.4979 66.6096 99.618 66.4594 99.618 66.3393C99.5754 64.0069 99.2528 61.688 98.6571 59.4326C98.597 59.2224 98.3568 59.1323 98.2066 59.1923L97.9063 59.2824C91.6002 61.7148 82.9217 65.0781 70.4295 65.0781C58.1775 65.1682 52.6221 68.021 47.7273 70.5134Z"
                    fill="#2B2C2E"
                />
                <path
                    d="M86.1647 83.306C89.0175 83.306 91.63 83.3961 94.1825 83.5162C94.2726 83.5162 94.4228 83.4862 94.4528 83.3961C95.2035 82.285 95.9242 81.1439 96.4948 79.9427C96.5849 79.7325 96.4648 79.4623 96.2245 79.4923C93.2216 79.5523 90.0385 79.4923 86.465 79.4923H80.9396C70.5194 79.4923 65.9249 82.285 61.4806 84.9877C58.9281 86.4891 56.4657 88.0206 53.2225 88.8014C52.9222 88.8314 52.9222 89.1918 53.0724 89.402C54.8741 90.8133 56.826 92.0445 58.9281 93.0355C61.6437 92.2862 64.1985 91.0444 66.4655 89.3719C72.1496 85.1659 79.0991 83.0259 86.1647 83.306Z"
                    fill="#2B2C2E"
                />
                <path
                    d="M86.1645 86.6091C76.7053 86.6091 72.4111 89.4319 68.2971 92.1345C67.1559 92.9153 65.9848 93.666 64.6935 94.3867C64.4233 94.5068 64.4533 94.9272 64.7836 94.9873C69.4896 96.0082 74.3763 95.8341 78.9976 94.4807C83.619 93.1274 87.8277 90.638 91.2395 87.2397C91.4196 87.0596 91.2995 86.6992 91.0293 86.6992C89.4677 86.6692 87.8461 86.6091 86.1645 86.6091Z"
                    fill="#2B2C2E"
                />
                <path
                    d="M64.6937 94.3867C70.9999 93.7561 87.9965 88.9814 91.2396 87.2397C87.8423 90.6583 83.6334 93.1594 79.0066 94.5091C74.3799 95.8588 69.4864 96.013 64.7838 94.9573C64.4835 94.9273 64.4234 94.5068 64.6937 94.3567V94.3867Z"
                    fill="#282A2D"
                />
                <path
                    d="M46.196 67.6006C49.1092 65.9716 52.1901 64.6627 55.385 63.6968C59.7092 62.4656 64.634 61.835 70.3997 61.835C75.6848 61.835 80.6397 61.2344 85.6546 59.9432C89.9788 58.8321 93.5523 57.4507 96.7053 56.2496C96.8555 56.2195 97.0056 56.1595 97.1258 56.0694C97.3059 56.0394 97.396 55.7991 97.3059 55.6189C94.7312 49.5204 90.1198 44.5044 84.2588 41.427C78.3978 38.3497 71.6504 37.4017 65.1683 38.7449C58.6863 40.0881 52.8712 43.6392 48.7157 48.7922C44.5601 53.9451 42.3216 60.3805 42.3823 67.0001L42.4123 68.952C42.4123 69.1922 42.7126 69.3123 42.8628 69.2523C44.0339 68.7418 45.0849 68.1712 46.196 67.5706V67.6006Z"
                    fill="#2B2C2E"
                />
                <path
                    d="M59.4688 81.9845C64.1233 79.2219 69.3484 76.0387 80.6394 76.0387H86.1949L97.606 76.0087C97.7562 76.0087 97.9063 75.9186 97.9363 75.7685C98.5369 74.0268 98.9273 72.2551 99.1375 70.4233C99.1976 70.183 98.9573 70.0029 98.7471 70.0629C92.7413 71.2641 85.1739 72.195 75.4143 72.195C70.3694 72.195 66.1052 72.7956 62.3816 73.9367C59.5682 74.873 56.8687 76.122 54.3337 77.6603C51.8413 79.0417 49.439 80.363 46.406 81.1437C46.1658 81.1738 46.1057 81.444 46.2258 81.6242C47.1267 83.1257 48.0876 84.477 49.2288 85.7682C49.3489 85.8583 49.439 85.9184 49.5291 85.8583C53.1133 85.4353 56.5324 84.1131 59.4688 82.0146V81.9845Z"
                    fill="#2B2C2E"
                />
            </g>
            <defs>
                <filter
                    id="filter0_di_24_1548"
                    x="0.806484"
                    y="0.935516"
                    width="140.387"
                    height="140.387"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4.12903" />
                    <feGaussianBlur stdDeviation="18.5806" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_24_1548" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_24_1548" result="shape" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="-20.6452" />
                    <feGaussianBlur stdDeviation="8.25806" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.26 0" />
                    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_24_1548" />
                </filter>
                <radialGradient
                    id="paint0_radial_24_1548"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(71 67) rotate(90) scale(33.0323)"
                >
                    <stop offset="0.921875" stopColor="#525252" />
                    <stop offset="1" stopColor="#141414" />
                </radialGradient>
            </defs>
        </svg>
    );
}
