import { useEffect } from 'react';
import getConfig from 'next/config';
import { getElementByXpath } from '@/utils/helpers';

const { publicRuntimeConfig } = getConfig();

const useCrisp = () => {
    const hideCrispButton = () => {
        const crispBlueButton = getElementByXpath('//div[@class="crisp-client"]/div/div/a[@role="button"]');
        if (crispBlueButton) {
            crispBlueButton.setAttribute('style', 'display:none !important');
        }
    };

    const showCrispButton = () => {
        const crispBlueButton = getElementByXpath('//div[@class="crisp-client"]/div/div/a[@role="button"]');
        if (crispBlueButton) {
            crispBlueButton.setAttribute('style', 'display:block !important');
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined' && document) {
            window.$crisp = [];
            window.CRISP_WEBSITE_ID = publicRuntimeConfig.crispWebsiteId;
            const d = document;
            const s = d.createElement('script');
            s.src = 'https://client.crisp.chat/l.js';
            s.async = true;
            d.getElementsByTagName('body')[0].appendChild(s);
            globalThis?.window?.$crisp.push(['on', 'session:loaded', hideCrispButton]);
            globalThis?.window?.$crisp.push(['on', 'chat:closed', hideCrispButton]);
            globalThis?.window?.$crisp.push(['on', 'chat:opened', showCrispButton]);
            globalThis?.window?.$crisp.push(['on', 'message:received', showCrispButton]);
        }
    }, []);

    const openChat = function () {
        globalThis?.window?.$crisp.push(['do', 'chat:open']);
    };

    return {
        openChat,
    };
};

export default useCrisp;
