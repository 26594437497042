import React from 'react';
import { Form } from 'react-bootstrap';
import formStyles from '@/components/forms/forms.module.scss';
import Text from '../../DesignSystem/Text';
import Button from '../../DesignSystem/Button';
import welcomeModalStyles from './welcomeModal.module.scss';
import AvatarButton from '../../DesignSystem/Button/AvatarButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Tabs from '@/components/DesignSystem/Tabs';
import OccupationDropdown from './subcomponents/OccupationDropdown';
import HomeIcon from '@/icons/HomeIcon';
import StepControlButtons from '@/components/baseOnboarding/StepControlButtons';
import baseOnboardingStyles from '../../baseOnboarding/baseOnboarding.module.scss';
import OccupationDropdownBaseOnboarding from './subcomponents/OccupationDropDownBaseOnboarding';
import TeamSizeDropdown from './subcomponents/TeamSizeDropdown';

const TeamDataspaceSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
});

const teamSizeValues = [
    { label: '1-4', value: '1-4' },
    { label: '5-20', value: '5-20' },
    { label: '20-100', value: '20-100' },
    { label: '100+', value: '100+' },
];

const TeamDataspace = function (props): React.ReactElement {
    const { dataspace, nextStep, prevStep, handleIconChange, isBaseOnboarding } = props;

    const handleTeamSizeClick = (setFieldValue) => (value) => {
        setFieldValue('teamSize', value);
    };

    const handleWorkTypeSelect = (setFieldValue) => (option) => {
        setFieldValue('workType', option.value);
    };
    const handleTeamSizeSelect = (setFieldValue) => (option) => {
        setFieldValue('teamSize', option.value);
    };

    return (
        <Formik
            initialValues={{
                iconUrl: dataspace?.iconUrl,
                name: dataspace?.name,
                workType: dataspace?.workType,
                teamSize: dataspace?.teamSize || undefined,
            }}
            validationSchema={TeamDataspaceSchema}
            validateOnChange={false}
            onSubmit={nextStep}
        >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, setFieldValue }): JSX.Element => (
                <Form onSubmit={handleSubmit} className={welcomeModalStyles.avatarNameJobContainer}>
                    <div>
                        {isBaseOnboarding ? (
                            <div className={baseOnboardingStyles.headerContainer}>
                                <h2>{`Name your team’s dataspace`}</h2>
                                <div>The dataspace is where all your information will live.</div>
                            </div>
                        ) : (
                            <>
                                <Text variant="heading" size="h4" align="center">
                                    {`Name your team’s dataspace`}
                                </Text>
                                <Text variant="body" size="s" align="center">
                                    The dataspace is where all your information will live.
                                </Text>
                            </>
                        )}
                        <div
                            className={welcomeModalStyles.avatarNameJobInputsContainer}
                            style={isBaseOnboarding ? { maxWidth: 430 } : {}}
                        >
                            <AvatarButton
                                square
                                onChange={handleIconChange(setFieldValue, 'iconUrl')}
                                value={values.iconUrl}
                                placeholder={<HomeIcon />}
                            />
                            <span>
                                {!isBaseOnboarding && (
                                    <label htmlFor="dataspace-name-input" className={formStyles.inputLabel}>
                                        <Text size="s">Dataspace Name</Text>
                                    </label>
                                )}
                                <Form.Control
                                    className={
                                        isBaseOnboarding ? baseOnboardingStyles.textField : formStyles.formControl
                                    }
                                    id="dataspace-name-input"
                                    type="text"
                                    placeholder={isBaseOnboarding ? 'Team Name' : 'My Dataspace'}
                                    onChange={handleChange}
                                    name="name"
                                    disabled={isSubmitting}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                />
                                {errors.name ? <div className={formStyles.error}>{errors.name.toString()}</div> : null}
                            </span>
                            <span>
                                {isBaseOnboarding ? (
                                    <TeamSizeDropdown
                                        value={values.teamSize}
                                        onChange={handleTeamSizeSelect(setFieldValue)}
                                    />
                                ) : (
                                    <>
                                        <label htmlFor="team-size-input" className={formStyles.inputLabel}>
                                            <Text size="s">How many people are in your company?</Text>
                                        </label>
                                        <Tabs
                                            tabsData={teamSizeValues}
                                            value={values.teamSize}
                                            onClick={handleTeamSizeClick(setFieldValue)}
                                        />
                                    </>
                                )}
                            </span>

                            <span>
                                {isBaseOnboarding ? (
                                    <OccupationDropdownBaseOnboarding
                                        value={values.workType}
                                        onChange={handleWorkTypeSelect(setFieldValue)}
                                    />
                                ) : (
                                    <>
                                        <label htmlFor="work-type-input" className={formStyles.inputLabel}>
                                            <Text size="s">What is your team&apos;s focus?</Text>
                                        </label>
                                        <OccupationDropdown
                                            value={values.workType}
                                            onChange={handleWorkTypeSelect(setFieldValue)}
                                        />
                                    </>
                                )}
                            </span>
                        </div>
                        {isBaseOnboarding ? (
                            <div className={baseOnboardingStyles.stickyStepControl}>
                                <StepControlButtons prevStep={prevStep} nextStep={handleSubmit} />
                            </div>
                        ) : (
                            <div className={welcomeModalStyles.stepControlContainer}>
                                <Button onClick={prevStep} size="s" variant="secondary">
                                    {'<- Back'}
                                </Button>
                                <Button type="submit" size="s" variant="primary">
                                    {'Continue ->'}
                                </Button>
                            </div>
                        )}
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default TeamDataspace;
