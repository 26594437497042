import MacOsIcon from '@/icons/MacOsIcon';
import WindowsIcon from '@/icons/WindowsIcon';
import { getIntegrationAccountsSelector } from '@/redux/user/selectors';
import { INTEGRATIONS } from 'integrations/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import styles from './IntegrationAccountIcon.module.scss';

export default function IntegrationAccountIcon({ id }): React.ReactElement {
    const integrationAccounts = useSelector(getIntegrationAccountsSelector);
    const { icon: Icon } = INTEGRATIONS[integrationAccounts[id]?.app] || { icon: () => null };

    const profilePic = () => {
        if (integrationAccounts[id]?.profile?.picture)
            return (
                <div className={styles.profileImg}>
                    <img src={integrationAccounts[id]?.profile?.picture} />
                </div>
            );
        if (integrationAccounts[id]?.tokens?.os) {
            const { os } = integrationAccounts[id].tokens;
            if (os === 'MacOS')
                return (
                    <div className={styles.profileImg}>
                        <MacOsIcon />
                    </div>
                );
            if (os === 'Windows')
                return (
                    <div className={styles.profileImg}>
                        <WindowsIcon />
                    </div>
                );
            // if (os === 'Linux') return <LinuxIcon />;
        }
    };

    return (
        <div className={styles.iconContainer} data-icon-type="integration-icon">
            <span className={styles.integrationAppIcon}>
                <Icon />
            </span>
            {profilePic()}
        </div>
    );
}
