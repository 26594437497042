import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { loadStripe } from '@stripe/stripe-js';
import getConfig from 'next/config';
import Button from '@/components/DesignSystem/Button';
import styles from './Plans.module.scss';
import Link from 'next/link';

const { publicRuntimeConfig } = getConfig();

const stripePromise = loadStripe(publicRuntimeConfig.stripePublicKey);

export default function Plans({ dsid, subscription }): JSX.Element {
    const handlePlusSubscribe = async () => {
        const stripe = await stripePromise;

        // Call backend to create a checkout session.
        const response = await fetch('/api/createCheckoutSession', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ dsid }),
        });

        const session = await response.json();

        // Redirect to the Stripe checkout page.
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });

        if (result.error) {
            console.error(result.error.message);
        }
    };

    const handleManageSubscription = async () => {
        // Call backend to create a checkout session.
        const response = await fetch('/api/createCustomerPortalSession', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ customerId: subscription?.stripe_customer_id }),
        });

        const session = await response.json();

        // Redirect to the Stripe customer portal.
        window.location.href = session.url;
    };

    return (
        <Container>
            <Table className={styles.table}>
                <thead>
                    <tr>
                        <th></th>
                        <th>
                            <h3>Personal</h3>
                            <h2 style={{ paddingRight: '10px', margin: 0, fontWeight: 500 }}>$0</h2>
                            <div style={{ marginTop: '10px' }}>
                                {subscription?.stripe_plan_id !== publicRuntimeConfig.stripeProPlanId ? (
                                    <Button variant="secondary" className={styles.button} disabled>
                                        Your current plan
                                    </Button>
                                ) : (
                                    <div style={{ height: '34px' }}></div>
                                )}
                            </div>
                        </th>
                        <th className={styles.highlight}>
                            <h3>Personal Pro</h3>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <h2 style={{ paddingRight: '10px', margin: 0, fontWeight: 500 }}>$8</h2>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        fontWeight: 400,
                                        color: '#999',
                                    }}
                                >
                                    <div style={{ lineHeight: '14px' }}>per member</div>
                                    <div style={{ lineHeight: '14px' }}>per month</div>
                                </div>
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                {subscription?.stripe_plan_id === publicRuntimeConfig.stripeProPlanId &&
                                subscription?.stripe_subscription_status === 'active' ? (
                                    <Button variant="secondary" className={styles.button} disabled>
                                        Your current plan
                                    </Button>
                                ) : subscription?.stripe_plan_id === publicRuntimeConfig.stripeProPlanId &&
                                  subscription?.stripe_subscription_status !== 'active' ? (
                                    <Link href="#">
                                        <a onClick={handleManageSubscription}>
                                            Resolve an issue with your subscription
                                        </a>
                                    </Link>
                                ) : (
                                    <Button onClick={handlePlusSubscribe} className={styles.button}>
                                        Upgrade
                                    </Button>
                                )}
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Entities</td>
                        <td>200</td>
                        <td className={styles.highlight}>2000</td>
                    </tr>
                    <tr>
                        <td>Members</td>
                        <td>5</td>
                        <td className={styles.highlight}>Unlimited</td>
                    </tr>
                    <tr>
                        <td>Max upload size</td>
                        <td>20 MB</td>
                        <td className={styles.highlight}>200 MB</td>
                    </tr>
                    <tr>
                        <td>Storage Space</td>
                        <td>10 GB</td>
                        <td className={styles.highlight}>200 GB</td>
                    </tr>
                    <tr>
                        <td>Priority support</td>
                        <td></td>
                        <td className={styles.highlight}>
                            <span style={{ fontSize: 20 }}>&#x2713;</span>
                        </td>
                    </tr>
                    {subscription && (
                        <tr className={styles.lastRow}>
                            <td></td>
                            <td></td>
                            <td>
                                <Link href="#">
                                    <a onClick={handleManageSubscription}>Manage your subscription</a>
                                </Link>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Container>
    );
}
