import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Head from 'next/head';
import { getDataspaceSelector, getDsidSelector } from '@/redux/entities/selectors';
import { getPendingSelector } from '@/redux/user/selectors';
import { updateEntity } from '@/redux/entities/actions';
import { cloneEntity, uploadFiles } from '@/utils/helpers';
import AvatarButton from '../../../DesignSystem/Button/AvatarButton';
import styles from './General.module.scss';
import formStyles from '../../../forms/forms.module.scss';
import Button from '../../../DesignSystem/Button';

const DataspaceGeneral = function (): React.ReactElement {
    const DataspaceSchema = Yup.object().shape({
        name: Yup.string().required('Cannot be empty').min(2, 'Too Short').max(50, 'Too Long'),
    });

    const pending = useSelector(getPendingSelector);
    const dataspace = useSelector(getDataspaceSelector);
    const dispatch = useDispatch();
    const [isSubmittingDS, setIsSubmittingDS] = useState(pending);
    const [saveDSButtonVisibility, setSaveDSButtonVisibility] = useState(false);
    const [icon, setIcon] = useState(null);
    const dsid = useSelector(getDsidSelector);

    useEffect(() => {
        if (!pending) {
            setIsSubmittingDS(pending);
        }
    }, [pending]);

    const saveDataspace = async (values) => {
        setIsSubmittingDS(true);
        if (icon) {
            await uploadFiles([icon], {}, dsid).then((docEntities) => {
                const iconUrl = docEntities[0]?.url;
                const newEntity = Object.assign({}, cloneEntity(dataspace), values, { iconUrl }) as Entity;
                dispatch(updateEntity(dataspace.metadata.id, newEntity, 'dataspaceGeneral1'));
            });
        } else {
            const newEntity = Object.assign({}, cloneEntity(dataspace), values) as Entity;
            dispatch(updateEntity(dataspace.metadata.id, newEntity, 'dataspaceGeneral2'));
        }
        setSaveDSButtonVisibility(false);
        setIcon(null);
        setIsSubmittingDS(false);
    };

    const checkFormFieldChange = (handler, setter, originalValue) => (event) => {
        setter(false);
        if (event.target.value !== originalValue) {
            setter(true);
        }
        handler(event);
    };

    const handleIconChange = (file) => {
        setIcon(file);
        setSaveDSButtonVisibility(true);
    };

    return (
        <>
            <Head>
                <meta property="og:title" content={'Settings - Bedrock'} />
                <title>Settings - Bedrock</title>
            </Head>
            {dataspace ? (
                <Formik
                    validationSchema={DataspaceSchema}
                    validateOnChange={false}
                    enableReinitialize
                    onSubmit={(values) => {
                        saveDataspace(values);
                    }}
                    initialValues={{
                        name: dataspace.name,
                    }}
                >
                    {({ handleSubmit, handleChange, values, touched, errors }) => (
                        <Form
                            onSubmit={handleSubmit}
                            id="settingsForm"
                            style={{ paddingBottom: 20 }}
                            className={styles.generalSettingsContainer}
                        >
                            <div className={styles.avatarNameContainer}>
                                <div>
                                    <AvatarButton onChange={handleIconChange} value={dataspace?.iconUrl} square />
                                </div>
                                <Form.Group controlId="formCreateDataspaceName" style={{ paddingLeft: 0 }}>
                                    <Form.Label className={`${formStyles.formLabel} textXXS medium`}>
                                        Dataspace Name
                                    </Form.Label>
                                    <Form.Control
                                        className={formStyles.formControl}
                                        type="text"
                                        onChange={checkFormFieldChange(
                                            handleChange,
                                            setSaveDSButtonVisibility,
                                            dataspace.name,
                                        )}
                                        name="name"
                                        value={values.name}
                                        isValid={touched.name && !errors.name}
                                        isInvalid={!!errors.name}
                                        style={{ width: '100%' }}
                                    />
                                    {errors.name ? (
                                        <div className={formStyles.error}>{errors.name.toString()}</div>
                                    ) : null}
                                </Form.Group>
                            </div>
                            {saveDSButtonVisibility && (
                                <Button variant="primary" type="submit" size="s" disabled={isSubmittingDS}>
                                    {isSubmittingDS ? 'Processing' : 'Save'}
                                </Button>
                            )}
                        </Form>
                    )}
                </Formik>
            ) : null}
        </>
    );
};

export default DataspaceGeneral;
