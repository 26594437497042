export default function VolumeIcon(): React.ReactElement {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18" viewBox="0 0 22 18" id="volume">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke="#000" strokeWidth="2" transform="translate(-1039 -2191)">
                    <g transform="translate(1040 2192)">
                        <path d="M9 1L4 5H0v6h4l5 4zM17.07.93c3.904 3.905 3.904 10.235 0 14.14M13.54 4.46a5 5 0 010 7.07"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}
