import {
    ADD_INTEGRATION_ITEM_TO_DATASPACE,
    ADD_INTEGRATION_ITEM_TO_DATASPACE_FAILURE,
    AUTHORIZE_INTEGRATION,
    AUTHORIZE_INTEGRATION_FAILURE,
    AUTHORIZE_INTEGRATION_SUCCESS,
    CREATE_INTEGRATION_ITEM,
    CREATE_INTEGRATION_ITEM_FAILURE,
    GET_INTEGRATION_ITEMS,
    GET_INTEGRATION_ITEMS_FAILURE,
    GET_INTEGRATION_ITEMS_SUCCESS,
    REVOKE_INTEGRATION,
    REVOKE_INTEGRATION_FAILURE,
    REVOKE_INTEGRATION_SUCCESS,
    UPDATE_INTEGRATION_ITEM_SUCCESS,
    DELETE_INTEGRATION_ITEM_SUCCESS,
    CREATE_INTEGRATION_ITEM_SUCCESS,
    ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS,
    GET_CACHED_INTEGRATION_DATA,
    GET_CACHED_INTEGRATION_DATA_SUCCESS,
    GET_CACHED_INTEGRATION_DATA_FAILURE,
    IMPORT_INTEGRATION_FILES_TO_DATASPACE,
} from 'redux/actionTypes';
import { IntegrationFileMetadata, IntegrationName } from '../../integrations/types';
import { GetIntegrationItemsProps } from '../types';
import { AnalyticsBrowser } from '@june-so/analytics-next';
import { getNextAuthSession } from 'integrations/helpers';
import { NODE_ENV } from '@/config';

let analytics;
const juneKey = process.env.NEXT_PUBLIC_JUNE_WRITE_KEY;

if (NODE_ENV !== 'development' && juneKey && typeof window !== 'undefined') {
    AnalyticsBrowser.load({
        writeKey: juneKey,
    }).then(([resolvedAnalytics]) => {
        analytics = resolvedAnalytics;
    });
}
const session: any = getNextAuthSession();

export const authorizeIntegration = (integrationAccount) => {
    analytics?.track('Connect Integration', {
        integration: integrationAccount.integration,
        userId: session?.id,
    });
    return {
        type: AUTHORIZE_INTEGRATION,
        payload: { integrationAccount },
    };
};

export const authorizeIntegrationSuccess = (integrationAccount) => {
    return {
        type: AUTHORIZE_INTEGRATION_SUCCESS,
        payload: { integrationAccount },
    };
};

export const authorizeIntegrationFailure = (integrationAccount) => {
    return {
        type: AUTHORIZE_INTEGRATION_FAILURE,
        payload: { integrationAccount },
    };
};

export const getIntegrationItems = (app: IntegrationName, appId, props?: GetIntegrationItemsProps) => {
    return {
        type: GET_INTEGRATION_ITEMS,
        payload: { app, appId, props },
    };
};

export const getIntegrationItemsSuccess = (
    data: IntegrationItemsData,
    app: IntegrationName,
    appId,
    props?: GetIntegrationItemsProps,
) => {
    return {
        type: GET_INTEGRATION_ITEMS_SUCCESS,
        payload: { data, app, appId, props },
    };
};

export const getIntegrationItemsFailure = (error) => {
    return {
        type: GET_INTEGRATION_ITEMS_FAILURE,
        payload: { error },
    };
};

export const revokeIntegrationAccount = (userId: string, userEntity: Entity, app: IntegrationName, appId) => {
    analytics?.track('Revoke Integration', {
        integration: app,
        userId: session?.id,
    });
    return {
        type: REVOKE_INTEGRATION,
        payload: { userId, userEntity, app, appId },
    };
};

export const revokeIntegrationAccountSuccess = (app: IntegrationName, appId) => {
    return {
        type: REVOKE_INTEGRATION_SUCCESS,
        payload: { app, appId },
    };
};

export const revokeIntegrationAccountFailure = (error) => {
    return {
        type: REVOKE_INTEGRATION_FAILURE,
        payload: { error },
    };
};

export const addIntegrationItemToDataspace = (
    integrationItem: IntegrationItem,
    dsid: string,
    parentId?: string,
    updateBrowsed?: boolean,
) => {
    analytics?.track('Add Integration Item to DS', {
        integration: integrationItem.app,
        userId: session?.id,
    });
    return {
        type: ADD_INTEGRATION_ITEM_TO_DATASPACE,
        payload: { integrationItem, dsid, parentId, updateBrowsed },
    };
};

export const addIntegrationItemToDataspaceSuccess = () => {
    return {
        type: ADD_INTEGRATION_ITEM_TO_DATASPACE_SUCCESS,
        payload: {},
    };
};

export const addIntegrationItemToDataspaceFailure = (error) => {
    return {
        type: ADD_INTEGRATION_ITEM_TO_DATASPACE_FAILURE,
        payload: { error },
    };
};

type CreateIntegrationFileParams = {
    app: IntegrationName;
    appId;
    fileMetadata: IntegrationFileMetadata;
    parentId: string;
};

export const createIntegrationItem = ({ app, appId, fileMetadata, parentId }: CreateIntegrationFileParams) => {
    analytics?.track('Create Integration Item from Bedrock', {
        integration: app,
        userId: session?.id,
    });
    return {
        type: CREATE_INTEGRATION_ITEM,
        payload: { app, appId, fileMetadata, parentId },
    };
};

export const createIntegrationFailure = (error) => {
    return {
        type: CREATE_INTEGRATION_ITEM_FAILURE,
        payload: { error },
    };
};

export const udpateIntegrationItemSuccess = (app: IntegrationName, appId, updatedItems: IntegrationItem[]) => {
    return {
        type: UPDATE_INTEGRATION_ITEM_SUCCESS,
        payload: { app, appId, updatedItems },
    };
};

export const deleteIntegrationItemSuccess = (
    app: IntegrationName,
    appId,
    deletedItemIds: { parentId: string; id: string }[],
) => {
    return {
        type: DELETE_INTEGRATION_ITEM_SUCCESS,
        payload: { app, appId, deletedItemIds },
    };
};

export const createIntegrationItemSuccess = (
    app: IntegrationName,
    appId,
    createdItems: { parentId: string; item: IntegrationItem }[],
) => {
    return {
        type: CREATE_INTEGRATION_ITEM_SUCCESS,
        payload: { app, appId, createdItems },
    };
};

export const getCachedIntegrationData = (userId: string) => {
    return {
        type: GET_CACHED_INTEGRATION_DATA,
        payload: { userId },
    };
};

export const getCachedIntegrationDataSuccess = (data: any) => {
    return {
        type: GET_CACHED_INTEGRATION_DATA_SUCCESS,
        payload: { data },
    };
};

export const getCachedIntegrationDataFailure = (error) => {
    return {
        type: GET_CACHED_INTEGRATION_DATA_FAILURE,
        payload: { error },
    };
};

export const importIntegrationFilesToDataspace = (ids: string[], app: string, appId: string): ReduxAction => {
    return {
        type: IMPORT_INTEGRATION_FILES_TO_DATASPACE,
        payload: { ids, app, appId },
    };
};
