export default function PasteMdIcon(): JSX.Element {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.24 2.6c0-.15.12-.28.3-.28h11c.18 0 .3.13.3.27v1.36h1.25V2.59c0-.85-.7-1.52-1.54-1.52H2.53C1.7 1.07 1 1.74 1 2.59V11c0 .85.7 1.52 1.54 1.52h5.05v-1.25H2.53a.29.29 0 0 1-.29-.27v-8.4Zm7.34 3.6c0-.14.12-.28.3-.28h4.59c.17 0 .29.14.29.28v6.6c0 .13-.12.27-.3.27H9.89a.29.29 0 0 1-.3-.28V11a15.54 15.54 0 0 1-1.25-.1v1.9c0 .85.7 1.53 1.55 1.53h4.59c.84 0 1.54-.68 1.54-1.53V6.2c0-.85-.7-1.53-1.54-1.53h-4.6c-.83 0-1.54.68-1.54 1.53v2.68a7.16 7.16 0 0 0 1.25.1V6.2Z"
                fill="#333"
            />
            <path
                d="M2.81 6.94a.63.63 0 0 1 0-.88l2.44-2.44L7.7 6.06a.63.63 0 0 1-.88.88l-.88-.88c.05.59.13 1.06.24 1.45.19.68.46 1.05.81 1.3.37.25.9.41 1.71.5.8.07 1.8.07 3.06.07a.63.63 0 0 1 0 1.24c-1.23.01-2.3.01-3.18-.07a4.8 4.8 0 0 1-2.3-.71 3.4 3.4 0 0 1-1.3-1.99 9.32 9.32 0 0 1-.3-1.88l-.98.97a.63.63 0 0 1-.88 0Z"
                fill="#333"
            />
        </svg>
    );
}
