import { createPortal } from 'react-dom';

const Portal = ({ children, targetId }) => {
    const portalRoot = document.getElementById(targetId);

    if (!portalRoot) {
        return null;
    }

    return createPortal(children, portalRoot);
};

export default Portal;
