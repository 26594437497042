import styles from './EmptyTab.module.scss';
import BedrockInvertBlackIcon from '@/icons/BedrockInvertBlackIcon';
import GlobalSearchOmnibox from '@/components/Omnibox/GlobalSearchOmnibox';

export default function EmptyTab() {
    return (
        <div className={styles.emptyEntityContainer}>
            <div className={styles.innerContainer}>
                <BedrockInvertBlackIcon />
                <GlobalSearchOmnibox
                    // noLink
                    data={{
                        emptyTab: true,
                    }}
                />
            </div>
        </div>
    );
}
