import React from 'react';
import { Form } from 'react-bootstrap';
import formStyles from '@/components/forms/forms.module.scss';
import Text from '../../DesignSystem/Text';
import Button from '../../DesignSystem/Button';
import welcomeModalStyles from './welcomeModal.module.scss';
import AvatarButton from '../../DesignSystem/Button/AvatarButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import OccupationDropdown from './subcomponents/OccupationDropdown';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { hideGlobalModal } from '@/redux/ui/actions';
import { useDispatch } from 'react-redux';

const AvatarNameJobSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short').max(50, 'Too Long').required('Required'),
});

const AvatarNameJob = function (props): React.ReactElement {
    const { entity, nextStep, handleAvatarChange } = props;
    const router = useRouter();
    const dispatch = useDispatch();

    const handleOccupationChange = (setFieldValue) => (option) => {
        setFieldValue('occupation', option.value);
    };

    const signOutAndRedirect = () => {
        dispatch(hideGlobalModal());
        signOut({ redirect: false }).then(() => router.push('/login'));
    };

    return (
        <Formik
            initialValues={{
                email: entity?.email,
                name: entity?.name,
                avatarUrl: entity?.avatarUrl,
                occupation: entity?.occupation,
            }}
            validationSchema={AvatarNameJobSchema}
            validateOnChange={false}
            onSubmit={nextStep}
        >
            {({ handleSubmit, handleChange, values, touched, errors, isSubmitting, setFieldValue }): JSX.Element => (
                <Form onSubmit={handleSubmit} className={welcomeModalStyles.avatarNameJobContainer}>
                    <div className={welcomeModalStyles.avatarNameJobContent}>
                        <Text variant="heading" size="h4" align="center">
                            Welcome!
                        </Text>
                        <Text variant="body" size="s" align="center">
                            Tell us a bit about yourself
                        </Text>
                        <div className={welcomeModalStyles.avatarNameJobInputsContainer}>
                            <AvatarButton
                                onChange={handleAvatarChange(setFieldValue, 'avatarUrl')}
                                value={values.avatarUrl}
                            />
                            <span>
                                <label htmlFor="your-name-input" className={formStyles.inputLabel}>
                                    <Text size="s">Your Name</Text>
                                </label>
                                <Form.Control
                                    className={formStyles.formControl}
                                    id="your-name-input"
                                    type="text"
                                    placeholder="John Doe"
                                    onChange={handleChange}
                                    name="name"
                                    disabled={isSubmitting}
                                    value={values.name}
                                    isValid={touched.name && !errors.name}
                                />
                                {errors.name ? <div className={formStyles.error}>{errors.name.toString()}</div> : null}
                            </span>
                            <span>
                                <label htmlFor="what-do-you-do-input" className={formStyles.inputLabel}>
                                    <Text size="s">What do you do?</Text>
                                </label>
                                <OccupationDropdown
                                    value={values.occupation}
                                    onChange={handleOccupationChange(setFieldValue)}
                                />
                            </span>
                        </div>
                        <Button type="submit" size="s" variant="primary" className={welcomeModalStyles.singleContinue}>
                            {'Continue ->'}
                        </Button>
                    </div>
                    <Text variant="inactive" size="xs" align="center">
                        You{`’`}re creating an account for <b>{values.email}</b>.
                    </Text>
                    <Text variant="inactive" size="xs" align="center">
                        <span>
                            If you don{`’`}t mean to create a new account you can{' '}
                            <span
                                onClick={signOutAndRedirect}
                                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                log in with a different email
                            </span>
                            .
                        </span>
                    </Text>
                </Form>
            )}
        </Formik>
    );
};

export default AvatarNameJob;
