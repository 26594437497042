import React, { useEffect, useState } from 'react';
import { PopupWrapper, usePopup } from '../wrappers/modal';
import styles from './addIntegrationPopup.module.scss';
import { INTEGRATIONS_LIST, INTEGRATIONS } from '../../integrations/constants';
import { useRouter } from 'next/router';
import { IntegrationName } from '../../integrations/types';
import landingStyles from '@/pages/index.module.scss';
import Button from '../../components/DesignSystem/Button';
import { isElectron } from '@/utils/helpers';
import Link from 'next/link';
import Text from '../DesignSystem/Text';
import Tooltip from 'components/Tooltip';

export const useAddIntegrationPopup = () => {
    const { open: openPopup } = usePopup();

    return (): void => {
        openPopup('add-integration-popup');
    };
};

const AddIntegrationPopup: React.FunctionComponent = () => {
    const { close } = usePopup();
    const router = useRouter();
    const [integrationName, setIntegrationName] = useState<'' | IntegrationName>('');
    const [viewState, setViewState] = useState<'init' | 'more'>('init');

    const authorize = async (name: IntegrationName, props?: any) => {
        try {
            const authorizationUrl = await INTEGRATIONS[name].getAuthorizationUrl(props);
            close();
            setViewState('init');
            router.push(authorizationUrl);
        } catch (e) {
            console.error(`AddIntegrationPopup > authorize: ${e}`);
        }
    };

    useEffect(() => {
        if (router.pathname === '/onboarding' && router.query?.app) {
            setViewState('more');
            setIntegrationName(router.query?.app as IntegrationName);
        }
    }, [router]);

    const onIntegrationClick = (name) => {
        setIntegrationName(name);
        setViewState('more');
    };

    return (
        <PopupWrapper text={'add-integration-popup'} onClose={() => setViewState('init')}>
            <div className={styles.mainWrapper}>
                <div className={styles.header}>
                    <h6>Integrations</h6>
                </div>
                {viewState === 'init' &&
                    INTEGRATIONS_LIST.map((name) => {
                        // if (name === 'localFiles' && !isElectron()) return null;
                        // TODO: show that current device is connected
                        // let currentDeviceLocalFilesConnected = false;
                        // if (Object.keys(integrationAccounts).some((account) => integrationAccounts[account].app === 'localFiles' && integrationAccounts[account].appId === window?.homedir)) {
                        //     currentDeviceLocalFilesConnected = true;
                        // }

                        const Icon = INTEGRATIONS[name].icon;
                        const isDisabled = name === 'localFiles' && !isElectron();

                        return (
                            <div key={name} className={styles.integration}>
                                <div className={styles.integrationIconTitleWrapper}>
                                    <div className={styles.integrationIcon}>
                                        <Icon />
                                    </div>
                                    <div>{INTEGRATIONS[name].label}</div>
                                </div>
                                <div className={styles.integrationConnectBlock}>
                                    <Tooltip
                                        key={`integration-${name}`}
                                        placement="left"
                                        appendTo="parent"
                                        delay={[300, null]}
                                        disabled={isDisabled}
                                        content={
                                            <div
                                                style={{ margin: '5px 10px 5px 10px', color: 'black' }}
                                                id={`integration-${name}-id`}
                                            >
                                                {name === 'localFiles' && <>Connect {window?.homedir} directory</>}
                                                {name !== 'localFiles' && (
                                                    <>Connect your {INTEGRATIONS[name].label} account</>
                                                )}
                                            </div>
                                        }
                                    >
                                        <div>
                                            <Button
                                                className={landingStyles.googleButton}
                                                customButtonStyle={{ width: '150px' }}
                                                icon={Icon}
                                                size="m"
                                                variant="secondary"
                                                onClick={() => onIntegrationClick(name)}
                                                disabled={isDisabled}
                                            >
                                                Connect
                                            </Button>
                                        </div>
                                    </Tooltip>
                                    {isDisabled && (
                                        <div className={styles.disabledText}>
                                            <Text variant="body" size="xs" className={styles.appLink}>
                                                Available in
                                                <Link href="/downloads">
                                                    <a className={styles.downloadLink} target="_blank">
                                                        {' '}
                                                        Desktop Apps↗
                                                    </a>
                                                </Link>
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}

                {viewState === 'more' && integrationName && INTEGRATIONS[integrationName].renderMoreScreen(authorize)}
            </div>
        </PopupWrapper>
    );
};

export default AddIntegrationPopup;
