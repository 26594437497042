import { cloneEntity } from '../../utils/helpers';
import { pickBy } from 'lodash';
import { ReactElement, useRef, useState } from 'react';

import dynamic from 'next/dynamic';
import { useDispatch } from 'react-redux';

import { updateEntity } from '../../redux/entities/actions';
import useOutsideClick from '../../hooks/outsideClick';

import styles from '../entities/Entity.module.scss';

export const Picker = dynamic(() => import('emoji-mart').then((mod) => mod.Picker), { ssr: false });

interface Props {
    eid: string;
    entity: Entity;
    propertyKey: string;
    isPickerOpened: boolean;
    setPickerOpened: (isOpened: boolean) => void;
}

export default function EmojiPicker({
    eid,
    entity,
    propertyKey,
    isPickerOpened,
    setPickerOpened,
}: Props): ReactElement {
    const dispatch = useDispatch();
    const menuRef = useRef<HTMLDivElement>(null);
    const setNewEmoji = (emoji): void => {
        const currentEmoji = pickBy({
            id: emoji.id,
            skin: emoji.skin,
        });
        const newEntity = cloneEntity(entity);
        newEntity[propertyKey] = currentEmoji;
        dispatch(updateEntity(eid, newEntity, 'emojiPicker1'));
    };

    const closeMenu = function (): void {
        setPickerOpened(false);
    };
    useOutsideClick(menuRef, closeMenu);

    const [tab, setTab] = useState('emoji');
    const handleTabClick = (e) => {
        const tabName = e?.target?.getAttribute('data-tab');
        if (tabName) setTab(tabName);
    };

    const handleRemoveIcon = (e) => {
        e.stopPropagation();
        const newEntity = cloneEntity(entity);
        delete newEntity.emoji;
        dispatch(updateEntity(eid, newEntity, 'emojiPicker2'));
    };

    return (
        <div className={styles.emojiPickerZIndexWrapper}>
            {isPickerOpened && (
                <div className={`${styles.emojiPickerWrapper + ' emojiPickerWrapper'}`} ref={menuRef}>
                    <div className={styles.emojiPickerHeader}>
                        <div className={styles.emojiPickerTabs}>
                            <div
                                className={tab === 'emoji' ? styles.emojiPickerTabActive : styles.emojiPickerTab}
                                data-tab="emoji"
                                onClick={handleTabClick}
                            >
                                Emoji
                            </div>
                            <div
                                className={tab === 'image' ? styles.emojiPickerTabActive : styles.emojiPickerTabSoon}
                                data-tab="image"
                                onClick={handleTabClick}
                            >
                                Upload Image
                                <span className="menuItemMessage" style={{ marginLeft: 5 }}>
                                    Coming Soon!
                                </span>
                            </div>
                        </div>
                        <div className={`textButtonContainer ${styles.emojiRemoveButton}`} onClick={handleRemoveIcon}>
                            <div className="textButton textXS medium">Remove</div>
                        </div>
                    </div>
                    <Picker
                        onClick={setNewEmoji}
                        title=""
                        emoji={''}
                        skinEmoji={'raised_hands'}
                        enableFrequentEmojiSort={true}
                        color={'#00a0f7'}
                        autoFocus
                        emojiSize={24}
                        showPreview={false}
                        showSkinTones={false}
                    />
                </div>
            )}
        </div>
    );
}
