import { all, put, takeLatest } from '@redux-saga/core/effects';
import { QUERY_AI } from '../actionTypes';
import { queryAISuccess, queryAIFailure } from './actions';

export function* queryAISaga(action) {
    try {
        const { query } = action.payload;
        const response = yield fetch('/api/openaiQuery', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ query }),
        });

        if (response.ok) {
            const jsonResponse = yield response.json();
            yield put(queryAISuccess(jsonResponse));
        } else {
            const errorResponse = yield response.text();
            yield put(queryAIFailure({ error: errorResponse }));
        }
    } catch (err) {
        yield put(queryAIFailure({ error: err }));
    }
}

function* aiSaga() {
    yield all([takeLatest(QUERY_AI, queryAISaga)]);
}

export default aiSaga;
