export default function DriveIcon(): JSX.Element {
    return (
        <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.2.73H5.8l4.41 7.65.7 1.19h5.41L11.21.73Z" fill="#F9C941" />
            <path d="M16.3 9.57 9.72 7.53l.5.88.68 1.16h5.42Z" fill="#E8B835" />
            <path d="m13.6 14.27 2.71-4.7H6.11l-2.71 4.7h10.2Z" fill="#2A71E9" />
            <path d="m3.4 14.27 5.06-4.7H6.11l-2.71 4.7Z" fill="#296AD9" />
            <path d="m.68 9.57 2.72 4.7L7.8 6.6l.68-1.18L5.8.73.69 9.57Z" fill="#0BA25E" />
            <path d="m5.78.73 1.54 6.74.5-.88.67-1.16L5.8.73Z" fill="#089156" />
        </svg>
    );
}
