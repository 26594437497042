import { useEffect, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import BedrockDropdown from '@/components/DesignSystem/Dropdown';
import { getUserSelector } from '@/redux/user/selectors';
import bedrockDropdownStyles from '@/components/DesignSystem/Dropdown/BedrockDropdownStyles.module.scss';
import styles from '@/components/sidebar/styles.module.scss';
import { getDataspaceIdsSelector } from '@/redux/ui/selectors';
import { initialLoad, setDataspace } from '@/redux/entities/actions';
import { getUser } from '@/redux/user/actions';
import { switchUser } from '@/utils/apiHelpers';
import UserIcon from '@/icons/UserIcon';

const UserSelector = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(getUserSelector);
    const dataspaceIds = useSelector(getDataspaceIdsSelector);
    useEffect(() => {
        dispatch(initialLoad());
    }, [currentUser?.id]);
    useEffect(() => {
        dispatch(setDataspace(dataspaceIds[0]));
    }, [dataspaceIds[0]]);

    const listedSessions = useMemo((): JSX.Element[] => {
        const sessions = JSON.parse(localStorage.getItem('sessions') || '{}');
        return Object.keys(sessions).map((id: string) => {
            const session = sessions[id];
            return (
                <Dropdown.Item
                    key={`user-${id}`}
                    eventKey={`user-${id}`}
                    className={styles.dataspaceDropdownItem}
                    onClick={() => switchUser({ id }).then(() => dispatch(getUser()))}
                    href="#"
                >
                    <UserIcon src={session.user.avatarUrl} />
                    {session.user.name}
                </Dropdown.Item>
            );
        });
    }, []);

    return (
        <div className={styles.dataspaceSelectorDropdown}>
            <BedrockDropdown>
                <Dropdown>
                    <Dropdown.Toggle className={'bold textS'} style={{ padding: 4 }}>
                        <UserIcon src={currentUser.avatarUrl} />
                        <span className={styles.dataspaceDropdownCurrent}>{currentUser?.name}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={bedrockDropdownStyles.dropdownMenu} rootCloseEvent="mousedown">
                        {listedSessions}
                    </Dropdown.Menu>
                </Dropdown>
            </BedrockDropdown>
        </div>
    );
};

export default UserSelector;
