import { GET_DIR_CONTENT_FAILURE, GET_DIR_CONTENT_SUCCESS } from '../actionTypes';
import { FilesState } from '../types';

const initialState = {
    tree: {},
} as FilesState;

const FilesReducer = (state = initialState, action) => {
    const getNewState = () => {
        switch (action.type) {
            case GET_DIR_CONTENT_SUCCESS: {
                return {
                    ...state,
                    tree: { ...state.tree, ...action.payload.tree },
                };
            }
            case GET_DIR_CONTENT_FAILURE: {
                console.error('action.payload.error', action.payload.error);
            }
            default:
                return state;
        }
    };

    // const newState = getNewState();
    // if (typeof window !== 'undefined') window.localStorage.setItem('UIState', cleanUIState(newState));

    return getNewState();
};

export default FilesReducer;
