import { ReactElement, useState, useEffect } from 'react';
import styles from './forms.module.scss';
import CloseSmIcon from '@/icons/CloseSmIcon';
import Button from '../DesignSystem/Button';

interface TextFieldProps {
    placeholder?: string;
    className?: string;
    autoFocus?: boolean;
    name?: string;
    inputEventHandler?: (event: MouseEvent) => void;
    callback?: (value: any) => void;
    focused?: (isFocused: boolean) => void;
    onCloseSearch: () => void;
}

export default function SearchInput({
    placeholder,
    className,
    autoFocus,
    inputEventHandler,
    callback,
    focused,
    onCloseSearch,
}: TextFieldProps): ReactElement {
    const [state, setState] = useState('');

    const [active, setActive] = useState(false);

    useEffect(() => {
        setActive(autoFocus);
    }, []);

    const submit = function (e): void {
        if (e) e.preventDefault();
        setActive(false);
        focused(false);
    };

    const handleChange = function (e): void {
        e.preventDefault();
        setState(e?.currentTarget.value);
        callback(e?.currentTarget.value.trim());
    };

    const handleRename = function (): void {
        setActive(true);
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const onFocus = (e) => {
        e.target.select();
        focused(true);
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 13 || e.keyCode === 9) {
            if (inputEventHandler) inputEventHandler(e);
        }
        callback(e?.target?.value.trim());
    };

    const handleClear = (e) => {
        e.stopPropagation();
        setState('');
        setActive(false);
        callback('');
        onCloseSearch();
    };

    const clearButton = (
        <Button
            size="m"
            variant="plain"
            icon={CloseSmIcon}
            className={styles.searchCloseButton}
            onMouseDown={handleClear}
        />
    );

    return active ? (
        <form onSubmit={submit} className={`${styles.searchInput} ${className}`}>
            <input
                type="text"
                onClick={handleClick}
                onFocus={onFocus}
                onChange={handleChange}
                onBlur={submit}
                onKeyDown={handleKeyPress}
                value={state}
                autoFocus={true}
                className={styles.searchInputField}
                placeholder={`Search ${placeholder}`}
            />
            {state?.length > 0 && clearButton}
        </form>
    ) : (
        <div className={`${styles.searchInput} ${className}`}>
            <div className={styles.searchInputField} onClick={handleRename} onContextMenu={handleRename}>
                {state || placeholder || 'Search'}
            </div>
            {state?.length > 0 && clearButton}
        </div>
    );
}
