export default function CloseSmIcon(): JSX.Element {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9.64645 10.3536C9.84171 10.5488 10.1583 10.5488 10.3536 10.3536C10.5488 10.1583 10.5488 9.84171 10.3536 9.64645L6.70711 6L10.3536 2.35355C10.5488 2.15829 10.5488 1.84171 10.3536 1.64645C10.1583 1.45118 9.84171 1.45118 9.64645 1.64645L6 5.29289L2.35355 1.64645C2.15829 1.45118 1.84171 1.45118 1.64645 1.64645C1.45118 1.84171 1.45118 2.15829 1.64645 2.35355L5.29289 6L1.64645 9.64645C1.45118 9.84171 1.45118 10.1583 1.64645 10.3536C1.84171 10.5488 2.15829 10.5488 2.35355 10.3536L6 6.70711L9.64645 10.3536Z"
                fill="#333333"
            />
        </svg>
    );
}
