import styles from '../contextMenu.module.scss';
import React from 'react';
import clsx from 'clsx';

const MenuItem = ({ item, currentSelectedItem, setCurrentSelectedItem }) => {
    return (
        <div
            data-item-type={item.type}
            data-item-value={item.value}
            onClick={item.onClick}
            className={clsx(styles.item, currentSelectedItem === item.name && styles.selected)}
            onMouseEnter={() => setCurrentSelectedItem(item.name)}
            data-action-name={currentSelectedItem === item.name && item.name}
        >
            {item.icon && <div className={styles.itemIcon}>{item.icon}</div>}
            <span className={styles.itemLabel}>{item.name}</span>
        </div>
    );
};

export default MenuItem;
