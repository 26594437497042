import {
    SET_ENTITIES_STATE,
    SET_ENTITIES_SUCCESS,
    CREATE_ENTITY,
    CREATE_ENTITY_SUCCESS,
    CREATE_ENTITY_FAILURE,
    GET_ENTITY,
    GET_ENTITY_SUCCESS,
    GET_ENTITY_FAILURE,
    GET_ENTITIES,
    GET_ENTITIES_SUCCESS,
    GET_ENTITIES_FAILURE,
    GET_RECENT_ENTITIES,
    GET_RECENT_ENTITIES_SUCCESS,
    GET_RECENT_ENTITIES_FAILURE,
    RESTORE_ENTITIES_FROM_TRASH_FAILURE,
    RESTORE_ENTITIES_FROM_TRASH,
    RESTORE_ENTITIES_FROM_TRASH_SUCCESS,
    INITIAL_LOAD,
    INITIAL_LOAD_SUCCESS,
    INITIAL_LOAD_FAILURE,
    GET_USER_SUCCESS,
    UPDATE_ENTITY,
    UPDATE_ENTITY_SUCCESS,
    UPDATE_ENTITY_FAILURE,
    GET_ALL_COLLABORATORS,
    GET_ALL_COLLABORATORS_SUCCESS,
    GET_ALL_COLLABORATORS_FAILURE,
    CREATE_USER_SUCCESS,
    SET_DATASPACE,
    CREATE_DATASPACE_SUCCESS,
    CREATE_DATASPACE_FAILURE,
    COMPLETE_USER_SIGNUP_SUCCESS,
    CREATE_SCHEMA_FROM_OBJECT_SUCCESS,
    BULK_CREATE_ENTITIES,
    BULK_CREATE_ENTITIES_SUCCESS,
    BULK_UPDATE_ENTITIES,
    BULK_UPDATE_ENTITIES_SUCCESS,
    BULK_UPDATE_ENTITIES_FAILURE,
    BULK_MOVE_ENTITIES_SUCCESS,
    BULK_GROUP_ENTITIES_SUCCESS,
    BULK_REMOVE_CHILD_EIDS_SUCCESS,
    REMOVE_CHILD_EID_SUCCESS,
    PRESET_ENTITIES,
    UNDO_PRESET_ENTITIES,
    ADD_MEMBERS_SUCCESS,
    ADD_MEMBERS_FAILURE,
    SAVE_DRAFTS_FAILURE,
    SAVE_DRAFTS_SUCCESS,
    HARD_DELETE_ENTITIES,
    HARD_DELETE_ENTITIES_SUCCESS,
    HARD_DELETE_ENTITIES_FAILURE,
    SET_LOADING_IMAGES_DATA_URL,
    UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS,
    UPDATE_ENTITIES_PUBLIC_STATE_FAILURE,
    EMPTY_TRASH_FAILURE,
    GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE,
    GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS,
    BULK_REMOVE_CHILD_EIDS,
    BULK_MOVE_ENTITIES,
    BULK_GROUP_ENTITIES,
    ADD_MEMBERS,
    PRESET_CONDENSED_ENTITIES,
    UNDO_PRESET_CONDENSED_ENTITIES,
    DELETE_ENTITIES_WITHOUT_READ_ACCESS,
    ADD_APP_SUCCESS,
    REMOVE_APP_SUCCESS,
} from '../actionTypes';
import { DRAFTS_DATASPACE_ID, DRAFTS_FINDER_ID, SHARED_DATASPACE_ID, SHARED_FINDER_ID } from '../constants';

import { EntitiesState } from '../types';
import { filter, reduce } from 'lodash';

const initialState = {
    entities: {},
    condensedEntities: {} as EntityMap,
    entityLists: [],
    deletedEntityList: [],
    recent: [],
    rootEid: null,
    fetching: {},
    fetchErrorCounts: {},
    dsid: null,
    pending: false,
    error: null,
    invalidIds: {},
    initialLoad: true,
    imageDataUrlsWithIds: {},
    isBulkCreateProcessing: false,
} as EntitiesState;

const normalizeEntity = (rawEntity: RawEntity, state: EntitiesState) => {
    if (rawEntity.updated_at && state.entities[rawEntity.id]?.metadata?.updated_at > rawEntity.updated_at) {
        return state.entities[rawEntity.id];
    } else {
        // From Entities table
        const oldMetadata = state.entities[rawEntity.id]?.metadata || {};
        return {
            ...rawEntity.entity,
            metadata: Object.assign({}, oldMetadata, {
                id: rawEntity.id,
                dsid: rawEntity.dsid || oldMetadata.dsid,
                created_at: rawEntity.created_at || oldMetadata.created_at,
                updated_at: rawEntity.updated_at || oldMetadata.updated_at,
                owned_by: rawEntity.owned_by || oldMetadata.owned_by,
                condensed: false,
                public_entity: rawEntity.public_entity,
            }),
        };
    }
};

const normalizeCondensedEntity = (rawEntity: RawCondensedEntity, state: EntitiesState): CondensedEntity => {
    if (rawEntity.updated_at && state.condensedEntities[rawEntity.id]?.metadata?.updated_at > rawEntity.updated_at) {
        return state.condensedEntities[rawEntity.id];
    } else {
        // From condensedEntities view
        const oldMetadata = state.condensedEntities[rawEntity.id]?.metadata || {};
        return {
            name: rawEntity.name,
            ['@type']: rawEntity.entity_type,
            childrenList: rawEntity.children_list,
            url: rawEntity.url,
            additionalTypes: rawEntity.additional_types,
            emoji: rawEntity.emoji,
            email: rawEntity.email,
            foreignId: rawEntity.foreign_id,
            thumbnailUrl: rawEntity.thumbnail_url,
            iconLink: rawEntity.icon_link,
            mimeType: rawEntity.mime_type,
            app: rawEntity?.app,
            appId: rawEntity?.app_id,
            deleted: rawEntity.deleted,
            hasCustomName: rawEntity.has_custom_name || false,
            key: rawEntity?.key,
            metadata: Object.assign({}, oldMetadata, {
                id: rawEntity.id,
                dsid: rawEntity.dsid || oldMetadata.dsid,
                owned_by: rawEntity.owned_by || oldMetadata.owned_by,
                created_at: rawEntity.created_at || oldMetadata.created_at,
                updated_at: rawEntity.updated_at || oldMetadata.updated_at,
                condensed: true,
                public_entity: rawEntity.public_entity,
            }),
        };
    }
};

const normalizeCondensedEntityFromRawEntity = (rawEntity: RawEntity, state: EntitiesState): CondensedEntity => {
    if (rawEntity.updated_at && state.condensedEntities[rawEntity.id]?.metadata?.updated_at > rawEntity.updated_at) {
        return state.condensedEntities[rawEntity.id];
    } else {
        const oldMetadata = state.condensedEntities[rawEntity.id]?.metadata || {};
        return {
            name: rawEntity.entity.name,
            ['@type']: rawEntity.entity['@type'],
            childrenList: rawEntity.entity.childrenList,
            url: rawEntity.entity.url,
            additionalTypes: rawEntity.entity.additionalTypes,
            emoji: rawEntity.entity.emoji,
            email: rawEntity.entity.email,
            foreignId: rawEntity.entity.foreignId,
            thumbnailUrl: rawEntity.entity.thumbnailUrl,
            iconPath: rawEntity.entity.iconPath,
            iconLink: rawEntity.entity.iconLink,
            app: rawEntity.entity?.app,
            appId: rawEntity.entity?.appId,
            deleted: rawEntity.deleted || false,
            hasCustomName: rawEntity.entity?.hasCustomName || false,
            metadata: Object.assign({}, oldMetadata, {
                id: rawEntity.id,
                dsid: rawEntity.dsid || oldMetadata.dsid,
                owned_by: rawEntity.owned_by || oldMetadata.owned_by,
                created_at: rawEntity.created_at || oldMetadata.created_at,
                updated_at: rawEntity.updated_at || oldMetadata.updated_at,
                condensed: true,
                public_entity: rawEntity.public_entity,
            }),
        };
    }
};

const mergeEntityArrays = (state: EntitiesState, ...entityArrays: RawEntity[][]) => {
    const newEntitiesMap = entityArrays
        .flat()
        .filter((e) => !!e)
        .reduce((acc, e) => {
            acc[e.id] = normalizeEntity(e, state);
            return acc;
        }, {});
    return Object.assign({}, state.entities, newEntitiesMap);
};

const mapRawCondensedEntities = (entityArray: RawCondensedEntity[], state: EntitiesState): CondensedEntityMap => {
    return entityArray
        .filter((e) => !!e)
        .reduce((acc, e) => {
            acc[e.id] = normalizeCondensedEntity(e, state);
            return acc;
        }, {});
};

const mapCondensedEntities = (entityArray: CondensedEntity[]): CondensedEntityMap => {
    return entityArray
        .filter((e) => !!e)
        .reduce<CondensedEntityMap>((acc, e) => {
            acc[e.metadata.id] = e;
            return acc;
        }, {});
};

const mergeRawCondensedEntities = (
    state: EntitiesState,
    entityArray: RawCondensedEntity[] = [],
): CondensedEntityMap => {
    const condensedEntitiesMap = mapRawCondensedEntities(entityArray, state);
    return Object.assign({}, state.condensedEntities, condensedEntitiesMap);
};

const mergeCondensedEntities = (state: EntitiesState, entityArray: CondensedEntity[] = []) => {
    const condensedEntitiesMap = mapCondensedEntities(entityArray);
    return Object.assign({}, state.condensedEntities, condensedEntitiesMap);
};

const mergeRawEntitiesToCondensed = (state: EntitiesState, entityArray: RawEntity[] = []): CondensedEntityMap => {
    const condensedEntitiesMap = entityArray
        .filter((e) => !!e)
        .reduce((acc, e) => {
            acc[e.id] = normalizeCondensedEntityFromRawEntity(e, state);
            return acc;
        }, {});
    return Object.assign({}, state.condensedEntities, condensedEntitiesMap);
};

const mergeSingleEntity = (state: EntitiesState, payload) => {
    return Object.assign({}, state.entities, { [payload.id]: normalizeEntity(payload, state) });
};

const mergeFetching = (state: EntitiesState, bool: boolean, ...entityArrays: any[][]) => {
    const fetchingHash = {};
    for (const entityArray of entityArrays) {
        const entities = entityArray || [];
        entities.map((e) => {
            const id = e?.id || e;
            fetchingHash[id] = bool;
        });
    }
    return Object.assign({}, state.fetching, fetchingHash);
};

const incrementFetchErrorCounts = (state: EntitiesState, ids: string[]) => {
    const errorCountHash = {};
    for (const id of ids) {
        const count = state.fetchErrorCounts[id] || 0;
        errorCountHash[id] = count + 1;
    }
    return Object.assign({}, state.fetchErrorCounts, errorCountHash);
};

const clearFetchErrorCounts = (state: EntitiesState, ids: string[]) => {
    const errorCountHash = Object.assign({}, state.fetchErrorCounts);
    for (const id of ids) {
        if (errorCountHash[id]) delete errorCountHash[id];
    }
    return errorCountHash;
};

function buildSpecialEntityList(
    sourceCondensedEntities: CondensedEntityMap,
    specialFinderId: string,
    filterFunction?: (entity: CondensedEntity) => boolean,
) {
    let condensedEntities;
    if (filterFunction) {
        condensedEntities = Object.values(sourceCondensedEntities).filter(filterFunction);
    } else {
        condensedEntities = Object.values(sourceCondensedEntities);
    }
    const childrenList = condensedEntities.flatMap((e) => e.childrenList || []);
    const orphanedEntityIds = condensedEntities
        .filter((e) => !childrenList.includes(e.metadata.id))
        .map((e) => e.metadata.id);

    return insertChildrenInList(specialFinderId, [specialFinderId], orphanedEntityIds, sourceCondensedEntities, 0).flat(
        Infinity,
    );
}

const buildSharedFinderAndDataspaceEntities: (entityList: Record<string, Entity>) => RawEntity[] = (entityLists) => {
    return [
        {
            id: SHARED_FINDER_ID,
            dsid: SHARED_DATASPACE_ID,
            owned_by: '',
            entity: {
                '@type': 'Finder',
                name: 'Shared finder',
                childrenList: entityLists[SHARED_DATASPACE_ID].filter((e) => e.parentId === SHARED_FINDER_ID).map(
                    (e) => e.id,
                ),
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            public_entity: false,
            deleted: false,
        },
        {
            id: SHARED_DATASPACE_ID,
            dsid: null,
            owned_by: '',
            entity: {
                '@type': 'Dataspace',
                name: 'Shared',
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            public_entity: false,
            deleted: false,
        },
    ];
};

const buildDraftsFinderAndDataspaceEntities: (entityList: Record<string, Entity>) => RawEntity[] = (entityLists) => {
    return [
        {
            id: DRAFTS_FINDER_ID,
            dsid: DRAFTS_DATASPACE_ID,
            owned_by: '',
            entity: {
                '@type': 'Finder',
                name: 'Drafts Base',
                childrenList: entityLists[DRAFTS_DATASPACE_ID].filter((e) => e.parentId === DRAFTS_FINDER_ID).map(
                    (e) => e.id,
                ),
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            public_entity: false,
            deleted: false,
        },
        {
            id: DRAFTS_DATASPACE_ID,
            dsid: null,
            owned_by: '',
            entity: {
                '@type': 'Dataspace',
                name: 'Drafts',
            },
            created_at: new Date().toISOString(),
            updated_at: new Date().toISOString(),
            public_entity: false,
            deleted: false,
        },
    ];
};

const extractDataspacesFromEntityArray = (entities: Entity[]): Entity[] => {
    return entities.filter((entity) => entity?.entity?.['@type'] === 'Dataspace');
};

const buildFakeRawCondensedAppFolders = (dataspaces: Entity[]): RawCondensedEntity[] => {
    return dataspaces.map((dataspace: Entity) => ({
        id: dataspace.id + '_apps',
        dsid: dataspace.id,
        owned_by: dataspace.owned_by,
        name: 'Apps',
        entity_type: 'Apps',
        children_list: dataspace?.entity?.apps || [],
        additional_types: null,
        emoji: null,
        url: null,
        email: null,
        foreign_id: null,
        thumbnail_url: null,
        icon_link: null,
        mime_type: null,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        deleted: false,
        public_entity: false,
        has_custom_name: false,
    }));
};

const buildFakeRawAppFolders = (dataspaces: Entity[]): RawEntity[] => {
    return dataspaces.map((dataspace: Entity) => ({
        id: dataspace.id + '_apps',
        dsid: dataspace.id,
        entity: {
            '@type': 'Apps',
            name: 'Apps',
            childrenList: dataspace?.entity?.apps || [],
        },
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        owned_by: dataspace.owned_by,
        public_entity: false,
        deleted: false,
    }));
};

// Ordered list of entities by finder
const rebuildEntityLists = ({
    state,
    condensedEntities,
    draftCondensedEntities,
    sharedCondensedEntities,
    userId,
    findersArray,
    rebuildSpecialLists = false,
    publicEntityId,
}: {
    state: EntitiesState;
    condensedEntities: CondensedEntityMap;
    userId?: string;
    draftCondensedEntities?: CondensedEntityMap;
    sharedCondensedEntities?: CondensedEntityMap;
    findersArray?: Entity[];
    publicEntityId?: string;
    rebuildSpecialLists?: boolean;
}): Record<string, Entity> => {
    const entityLists = {};
    const finders = findersArray || filter(condensedEntities, ['@type', 'Finder']);
    finders.forEach((f) => {
        const dsid = f.metadata?.dsid;
        const finderId = f.metadata?.id;
        const appsFolderId = dsid + '_apps';
        const childrenList = publicEntityId ? [publicEntityId] : f.childrenList;
        if (!publicEntityId && appsFolderId && !f.childrenList?.includes(appsFolderId)) {
            childrenList.unshift(appsFolderId);
        }
        entityLists[dsid] = insertChildrenInList(finderId, [finderId], childrenList, condensedEntities, 0).flat(
            Infinity,
        );
    });

    if (rebuildSpecialLists && userId && !draftCondensedEntities && !sharedCondensedEntities) {
        entityLists[DRAFTS_DATASPACE_ID] = buildSpecialEntityList(
            condensedEntities,
            DRAFTS_FINDER_ID,
            (entity) => !entity.metadata.dsid && entity.metadata.owned_by === userId,
        );
        entityLists[SHARED_DATASPACE_ID] = buildSpecialEntityList(
            condensedEntities,
            SHARED_FINDER_ID,
            (entity) => entity.metadata.owned_by !== userId,
        );
    } else {
        if (draftCondensedEntities) {
            entityLists[DRAFTS_DATASPACE_ID] = buildSpecialEntityList(draftCondensedEntities, DRAFTS_FINDER_ID);
        }
        if (sharedCondensedEntities) {
            entityLists[SHARED_DATASPACE_ID] = buildSpecialEntityList(sharedCondensedEntities, SHARED_FINDER_ID);
        }
    }

    return Object.assign({}, state.entityLists, entityLists) as Record<string, Entity>;
};

const rebuildDeletedEntityList = (
    state: EntitiesState,
    condensedEntities: CondensedEntityMap,
    findersArray?: Entity[],
) => {
    const finders = findersArray || filter(condensedEntities, ['@type', 'Finder']);

    return finders.reduce((result, f) => {
        const dsid = f.metadata?.dsid;
        const finderId = f.metadata?.id;
        const grandchildrenArray = [];
        const deletedEntities = reduce(
            condensedEntities,
            (result, ent) => {
                if (ent.metadata?.dsid == dsid && ent.deleted) {
                    result.push(ent);
                    if (ent.childrenList) grandchildrenArray.push(ent.childrenList);
                }
                return result;
            },
            [],
        );
        const childrenList = deletedEntities.map((entity) => entity.metadata.id);

        const uniqueGrandchildren = grandchildrenArray.flat(Infinity).filter((v, i, a) => a.indexOf(v) === i);
        const topLevelChildren = childrenList.filter((childId) => !uniqueGrandchildren.includes(childId));

        const deletedEntityList = insertChildrenInList(
            finderId,
            [finderId],
            topLevelChildren,
            condensedEntities,
            0,
        ).flat(Infinity);

        return result.concat(deletedEntityList);
    }, []);
};

const insertChildrenInList = (
    parentId: string,
    ancestorList: string[],
    childIds: string[],
    entities: CondensedEntityMap,
    zIndex: number,
) => {
    const arr = [];
    for (let i = 0; i < childIds.length; i++) {
        const childId = childIds[i];
        const condensedEntity = entities[childId];
        if (!condensedEntity) continue;
        const grandchildIds = condensedEntity.childrenList || [];
        if (grandchildIds.length > 0) {
            arr.push({
                id: childIds[i],
                childIndex: i,
                parentId,
                ancestorList,
                hasChildren: true,
                zIndex,
                name: condensedEntity.name,
                '@type': condensedEntity['@type'],
                email: condensedEntity.email,
                foreignId: condensedEntity.foreignId,
                app: condensedEntity.app,
                appId: condensedEntity.appId,
            });
            arr.push(
                insertChildrenInList(childId, ancestorList.concat([childId]), grandchildIds, entities, zIndex + 1),
            );
        } else {
            arr.push({
                id: childId,
                childIndex: i,
                parentId,
                ancestorList,
                hasChildren: false,
                zIndex,
                name: condensedEntity.name,
                '@type': condensedEntity['@type'],
                email: condensedEntity.email,
                foreignId: condensedEntity.foreignId,
                app: condensedEntity.app,
                appId: condensedEntity.appId,
            });
        }
    }
    return arr;
};

const mergeInvalidIds = (state: EntitiesState, invalidIds: string[]) => {
    if (invalidIds?.length > 0) {
        const map = invalidIds.reduce((m, curr) => ((m[curr] = true), m), {});
        return Object.assign({}, state.invalidIds, map);
    } else return state.invalidIds;
};

const EntitiesReducer = (state = initialState, action) => {
    const getNewState = () => {
        switch (action.type) {
            case SET_ENTITIES_STATE: {
                return Object.assign({}, state, action.payload.state);
            }
            case SET_ENTITIES_SUCCESS: {
                if (action.payload.entities) {
                    const condensedEntities = mergeRawEntitiesToCondensed(state, action.payload.entities);
                    const newState = {
                        ...state,
                        entities: mergeEntityArrays(state, action.payload.entities),
                        condensedEntities,
                    };
                    if (action.payload.rebuildEntityList) {
                        newState.entityLists = rebuildEntityLists({
                            state,
                            condensedEntities,
                            rebuildSpecialLists: true,
                            userId: action.payload.userId,
                        });
                    }
                    return newState;
                } else {
                    return state;
                }
            }
            case GET_PUBLIC_ENTITY_DESCENDANTS_SUCCESS:
                const condensedEntities = mergeRawEntitiesToCondensed(state, action.payload.entities);
                return {
                    ...state,
                    entities: mergeEntityArrays(state, action.payload.entities),
                    condensedEntities,
                    entityLists: rebuildEntityLists({
                        state,
                        condensedEntities,
                        publicEntityId: action.payload.publicEntityId,
                    }),
                };
            case CREATE_ENTITY:
            case UPDATE_ENTITY:
            case BULK_UPDATE_ENTITIES:
            case BULK_CREATE_ENTITIES:
            case BULK_MOVE_ENTITIES:
            case BULK_GROUP_ENTITIES:
            case BULK_REMOVE_CHILD_EIDS:
            case ADD_MEMBERS:
            case GET_ALL_COLLABORATORS: {
                return {
                    ...state,
                    pending: true,
                };
            }
            case BULK_CREATE_ENTITIES_SUCCESS: {
                const condensedEntities = mergeRawEntitiesToCondensed(state, action.payload.entities);
                const newState = {
                    ...state,
                    condensedEntities,
                    entities: mergeEntityArrays(state, action.payload.entities),
                    isBulkCreateProcessing: false,
                };

                if (action.payload.rebuildEntityList) {
                    newState.entityLists = rebuildEntityLists({
                        state,
                        condensedEntities,
                        rebuildSpecialLists: true,
                        userId: action.payload.userId,
                    });
                }

                return newState;
            }
            case CREATE_ENTITY_SUCCESS: {
                const entityArray = [];
                const results = action.payload.results;
                for (const e in results) {
                    const entity = results[e].insertedEntity || results[e];
                    entityArray.push(entity);
                }
                const condensedEntities = mergeRawEntitiesToCondensed(state, entityArray);

                return {
                    ...state,
                    entities: mergeEntityArrays(state, entityArray),
                    condensedEntities,
                    entityLists: rebuildEntityLists({
                        state,
                        condensedEntities,
                        rebuildSpecialLists: true,
                        userId: action.payload.userId,
                    }),
                    pending: false,
                    error: null,
                };
            }
            case GET_ENTITY: {
                return {
                    ...state,
                    fetching: mergeFetching(state, true, [action.payload.id]),
                    pending: true,
                };
            }
            case GET_ENTITIES: {
                return {
                    ...state,
                    fetching: mergeFetching(state, true, action.payload.ids),
                    pending: true,
                };
            }
            case GET_RECENT_ENTITIES:
            case RESTORE_ENTITIES_FROM_TRASH:
            case HARD_DELETE_ENTITIES:
                return {
                    ...state,
                    pending: true,
                };
            case GET_RECENT_ENTITIES_SUCCESS:
                return {
                    ...state,
                    recent: action.payload.entities.map((e: RawCondensedEntity) => normalizeCondensedEntity(e, state)),
                    pending: false,
                };
            case UNDO_PRESET_CONDENSED_ENTITIES:
            case PRESET_CONDENSED_ENTITIES: {
                const condensedEntities = mergeCondensedEntities(state, action.payload.condensedEntities);
                const newState = {
                    ...state,
                    condensedEntities,
                    fetching: mergeFetching(
                        state,
                        false,
                        action.payload.condensedEntities.map((e) => e.metadata.id),
                    ),
                    pending: false,
                    error: null,
                };
                if (action.payload.rebuildEntityList) {
                    newState.entityLists = rebuildEntityLists({
                        state,
                        condensedEntities,
                        userId: action.payload.userId,
                        rebuildSpecialLists: true,
                    });
                    newState.deletedEntityList = rebuildDeletedEntityList(state, condensedEntities);
                }
                return newState;
            }
            case PRESET_ENTITIES:
            case UNDO_PRESET_ENTITIES:
            case GET_ENTITY_SUCCESS:
            case GET_ENTITIES_SUCCESS:
            case GET_ALL_COLLABORATORS_SUCCESS:
            case BULK_UPDATE_ENTITIES_SUCCESS:
            case BULK_MOVE_ENTITIES_SUCCESS:
            case BULK_GROUP_ENTITIES_SUCCESS:
            case BULK_REMOVE_CHILD_EIDS_SUCCESS:
            case ADD_MEMBERS_SUCCESS:
            case CREATE_SCHEMA_FROM_OBJECT_SUCCESS:
            case REMOVE_CHILD_EID_SUCCESS:
            case SAVE_DRAFTS_SUCCESS:
            case UPDATE_ENTITIES_PUBLIC_STATE_SUCCESS:
            case UPDATE_ENTITY_SUCCESS:
            case ADD_APP_SUCCESS:
            case REMOVE_APP_SUCCESS:
            case RESTORE_ENTITIES_FROM_TRASH_SUCCESS: {
                const updatedDataspaces = extractDataspacesFromEntityArray(action.payload.entities);
                const updatedFakeApsFolders = buildFakeRawAppFolders(updatedDataspaces);
                const fullUpdatedEntityList = [...action.payload.entities, ...updatedFakeApsFolders];
                const condensedEntities = mergeRawEntitiesToCondensed(state, fullUpdatedEntityList);
                const newState = {
                    ...state,
                    entities: mergeEntityArrays(state, action.payload.entities),
                    condensedEntities,
                    fetching: mergeFetching(state, false, action.payload.entities, action.payload.invalidIds),
                    invalidIds: mergeInvalidIds(state, action.payload.invalidIds),
                    pending: action.type === PRESET_ENTITIES ? state.pending : false,
                    error: null,
                };
                if (action.payload.rebuildEntityList) {
                    newState.entityLists = rebuildEntityLists({
                        state,
                        condensedEntities,
                        userId: action.payload.userId,
                        rebuildSpecialLists: true,
                    });
                    newState.deletedEntityList = rebuildDeletedEntityList(state, condensedEntities);
                }
                if ([GET_ENTITY_SUCCESS, GET_ENTITIES_SUCCESS].includes(action.type)) {
                    newState.fetchErrorCounts = clearFetchErrorCounts(state, action.payload.ids || [action.payload.id]);
                }
                return newState;
            }
            case HARD_DELETE_ENTITIES_SUCCESS: {
                const newCondensedEntities = { ...state.condensedEntities };
                action.payload.deletedEids.forEach((id) => {
                    delete newCondensedEntities[id];
                });
                if (action.payload.parentEntities) {
                    action.payload.parentEntities.forEach((entity) => {
                        const normalizedParent = normalizeCondensedEntityFromRawEntity(entity, state);
                        newCondensedEntities[normalizedParent.metadata.id] = normalizedParent;
                    });
                }

                return {
                    ...state,
                    condensedEntities: newCondensedEntities,
                    deletedEntityList: rebuildDeletedEntityList(state, newCondensedEntities),
                    entityLists: rebuildEntityLists({
                        state,
                        condensedEntities: newCondensedEntities,
                        rebuildSpecialLists: true,
                        userId: action.payload.userId,
                    }),
                    pending: false,
                    error: null,
                };
            }
            case INITIAL_LOAD_FAILURE:
            case ADD_MEMBERS_FAILURE:
            case CREATE_ENTITY_FAILURE:
            case CREATE_DATASPACE_FAILURE:
            case GET_ALL_COLLABORATORS_FAILURE:
            case BULK_UPDATE_ENTITIES_FAILURE:
            case GET_RECENT_ENTITIES_FAILURE:
            case RESTORE_ENTITIES_FROM_TRASH_FAILURE:
            case HARD_DELETE_ENTITIES_FAILURE:
            case SAVE_DRAFTS_FAILURE:
            case UPDATE_ENTITIES_PUBLIC_STATE_FAILURE:
            case UPDATE_ENTITY_FAILURE:
            case EMPTY_TRASH_FAILURE:
            case GET_PUBLIC_ENTITY_DESCENDANTS_FAILURE: {
                return {
                    ...state,
                    pending: false,
                    error: action.payload.error,
                };
            }
            case GET_ENTITY_FAILURE:
            case GET_ENTITIES_FAILURE:
                return {
                    ...state,
                    pending: false,
                    fetching: mergeFetching(state, false, action.payload.ids || [action.payload.id]),
                    fetchErrorCounts: incrementFetchErrorCounts(state, action.payload.ids || [action.payload.id]),
                    error: action.payload.error,
                };
            case INITIAL_LOAD: {
                return {
                    ...state,
                    pending: true,
                };
            }
            case INITIAL_LOAD_SUCCESS: {
                const fakeAppsFolders = buildFakeRawCondensedAppFolders(action.payload.dataspaces);
                const condensedEntities = mergeRawCondensedEntities(state, [
                    ...action.payload.condensedEntities,
                    ...action.payload.draftCondensedEntities,
                    ...action.payload.sharedCondensedEntities,
                    ...fakeAppsFolders,
                ]);
                const findersArray = action.payload.finders.map((e) => normalizeEntity(e, state));
                const entityLists = rebuildEntityLists({
                    state,
                    condensedEntities,
                    findersArray,
                    draftCondensedEntities: mapRawCondensedEntities(action.payload.draftCondensedEntities, state),
                    sharedCondensedEntities: mapRawCondensedEntities(action.payload.sharedCondensedEntities, state),
                });
                const deletedEntityList = rebuildDeletedEntityList(state, condensedEntities, findersArray);
                const sharedFinderAndDS = buildSharedFinderAndDataspaceEntities(entityLists);
                const draftsFinderAndDS = buildDraftsFinderAndDataspaceEntities(entityLists);

                return {
                    ...state,
                    entities: mergeEntityArrays(
                        state,
                        action.payload.finders,
                        action.payload.dataspaces,
                        action.payload.additionalTypes,
                        sharedFinderAndDS,
                        draftsFinderAndDS,
                    ),
                    condensedEntities,
                    entityLists,
                    deletedEntityList,
                    fetching: Object.assign(
                        {},
                        mergeFetching(state, false, action.payload.finders, action.payload.dataspaces),
                        mergeFetching(state, true, action.payload.idsToFetch),
                    ),
                    pending: false,
                    error: null,
                    initialLoad: false,
                };
            }
            case GET_USER_SUCCESS:
            case CREATE_USER_SUCCESS: {
                const user = action.payload.user;
                return {
                    ...state,
                    dsid: state.dsid ? state.dsid : action.payload.user.dsid,
                    entities: mergeSingleEntity(state, user),
                };
            }
            case SET_DATASPACE: {
                return {
                    ...state,
                    dsid: action.payload.dsid,
                };
            }
            // created dataspace, finder; update user
            case COMPLETE_USER_SIGNUP_SUCCESS:
            case CREATE_DATASPACE_SUCCESS: {
                const dataspace = action.payload.results.insertDataspace.insertedEntity;
                const finder = action.payload.results.insertFinder.insertedEntity;
                const fakeAppFolder = buildFakeRawAppFolders([dataspace])[0];
                const entityArray = [
                    ...action.payload.results.update_people.returning,
                    dataspace,
                    finder,
                    fakeAppFolder,
                ];
                const condensedEntities = mergeRawEntitiesToCondensed(state, entityArray);
                return {
                    ...state,
                    entities: mergeEntityArrays(state, entityArray),
                    condensedEntities,
                    entityLists: rebuildEntityLists({ state, condensedEntities }),
                    fetching: mergeFetching(state, false, entityArray),
                    dsid: dataspace.id,
                    pending: false,
                    error: null,
                };
            }
            case SET_LOADING_IMAGES_DATA_URL: {
                const imageDataUrlsWithIds = action.payload.imageDataUrlsWithIds;

                return {
                    ...state,
                    imageDataUrlsWithIds,
                };
            }
            case DELETE_ENTITIES_WITHOUT_READ_ACCESS: {
                const entitiesToDelete = action.payload.entitiesWithoutReadAccess || [];
                const currentEntities = { ...state.entities };
                const currentCondensedEntities = { ...state.condensedEntities };

                entitiesToDelete.forEach((entityWithoutReadAccess) => {
                    if (currentEntities[entityWithoutReadAccess]?.childrenList) {
                        currentEntities[entityWithoutReadAccess].childrenList.forEach((childId) => {
                            entitiesToDelete.push(childId);
                        });
                    }
                    delete currentEntities[entityWithoutReadAccess];
                    delete currentCondensedEntities[entityWithoutReadAccess];
                });

                return {
                    ...state,
                    entities: currentEntities,
                    condensedEntities: currentCondensedEntities,
                    entityLists: rebuildEntityLists({ state, condensedEntities: currentCondensedEntities }),
                };
            }
            default:
                return state;
        }
    };

    const newState = getNewState();
    // if (typeof window !== 'undefined') window.localStorage.setItem('EntitiesState', JSON.stringify(newState));

    return newState;
};

export default EntitiesReducer;
