import uploadProgressModalStyles from './uploadProgressModal.module.scss';

const UploadProgressModal = (props): JSX.Element => {
    const { files } = props;
    return (
        <div className={uploadProgressModalStyles.container}>
            <div className={uploadProgressModalStyles.header}>
                <div>Uploading [{files.length}]</div>
                <div className={uploadProgressModalStyles.loadSpinner}>
                    {/* empty divs needed for animation */}
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            {files.map((f) => (
                <div className="textXS" key={f.name}>
                    {f.name}
                </div>
            ))}
        </div>
    );
};

export default UploadProgressModal;
