class ListControlService {
    subscribers = [];
    hListControlBarProps: HListControlBarProps = {
        eid: undefined,
        entity: undefined,
        hListRootStack: undefined,
        changeHListRoot: undefined,
        prevHListRoot: undefined,
        nextHListRoot: undefined,
        ancestorList: undefined,
    };

    updateValues(props: HListControlBarProps) {
        this.hListControlBarProps.eid = props.eid;
        this.hListControlBarProps.entity = props.entity;
        this.hListControlBarProps.hListRootStack = props.hListRootStack;
        this.hListControlBarProps.ancestorList = props.ancestorList;
        this.hListControlBarProps.changeHListRoot = props.changeHListRoot;
        this.hListControlBarProps.prevHListRoot = props.prevHListRoot;
        this.hListControlBarProps.nextHListRoot = props.nextHListRoot;

        this.notifySubscribers();
    }

    getControlBarProps(): HListControlBarProps {
        return this.hListControlBarProps;
    }

    subscribe(callback): void {
        this.subscribers.push(callback);
    }

    unsubscribe(callback): void {
        this.subscribers = this.subscribers.filter((subscriber) => subscriber !== callback);
    }

    notifySubscribers(): void {
        this.subscribers.forEach((subscriber) => subscriber({ ...this.hListControlBarProps }));
    }
}

const ListControlServiceInstance = new ListControlService();

export default ListControlServiceInstance;
