export default function DraftIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13 6.5v7a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-11a1 1 0 0 1 1-1h4m5 5-5-5m5 5H9a1 1 0 0 1-1-1v-4"
                stroke="#333"
            />
        </svg>
    );
}
