import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useCreateEntity } from '../hooks/reduxHooks';
import { getFinderSelector, getRecentQuickNotesSelector } from '../redux/ui/selectors';
import { GlobalHotKeys } from 'react-hotkeys';
import PanelQuickNote from './panels/PanelQuickNote';
import QuickCreatePopup, { useQuickCreatePopup } from './modals/QuickCreatePopup';
import { useCallback, useState } from 'react';
import {
    addRecentQuickNote,
    hideGlobalModal,
    setPanelOpen,
    setRecentQuickNotes,
    showGlobalModal,
} from '../redux/ui/actions';
import useAppWrapper from '@/hooks/useAppWrapper';

const GlobalShortcutsHandler = () => {
    const dispatch = useDispatch();
    const router = useRouter();
    const finderId = useSelector(getFinderSelector)?.id;
    const createEntity = useCreateEntity(finderId);
    const isNotes = router.asPath.indexOf('/notes', 0) > -1;
    const openQuickCreatePopup = useQuickCreatePopup();
    const [quickNotePanelEid, setQuickNotePanelEid] = useState(null);
    const recentQuickNotes = useSelector(getRecentQuickNotesSelector);
    const { isBaseApp } = useAppWrapper();

    const handleQuickCreateShortcut = (e) => {
        e.preventDefault();
        if (isBaseApp) return;
        if (isNotes) {
            createEntity('RichText');
        } else {
            openQuickCreatePopup();
        }
    };

    const handlePanelQuickNoteShortcut = useCallback(
        (e) => {
            e.preventDefault();
            const setNewQuickNote = (res) => {
                setQuickNotePanelEid(res.id);
                dispatch(addRecentQuickNote(res.id));
            };
            const resetState = () => {
                setQuickNotePanelEid(null);
                dispatch(setRecentQuickNotes(recentQuickNotes));
            };

            createEntity('RichText', {
                isDraft: true,
                customCallback: {
                    callback: setNewQuickNote,
                    fallback: resetState,
                },
            });
            dispatch(setPanelOpen('quick-note-panel', true));
        },
        [recentQuickNotes],
    );

    const handleSpotlightShortcut = () => {
        dispatch(
            showGlobalModal(
                'GlobalSearchOmnibox',
                {
                    onSelectSideEffect: () => dispatch(hideGlobalModal('GlobalSearchOmnibox')),
                },
                {
                    transparentModal: true,
                    fade: false,
                    canClose: true,
                    top30: true,
                    backdrop: 'none',
                    fullWidth: true,
                    maxWidth: '456px',
                },
            ),
        );
    };

    const keyMap = {
        QUICK_CREATE_POPUP_SHORTCUT_WIN: 'ctrl+n',
        QUICK_CREATE_POPUP_SHORTCUT_MAC: 'command+n',
        QUICK_NOTE_PANEL_SHORTCUT_MAC: 'command+shift+p',
        QUICK_NOTE_PANEL_SHORTCUT_WIN: 'ctrl+shift+p',
        GLOBAL_SEARCH_POPUP_WIN: 'ctrl+p',
        GLOBAL_SEARCH_POPUP_MAC: 'command+p',
        SPOTLIGHT_MAC: 'command+k',
        SPOTLIGHT_WIN: 'ctrl+k',
    };

    const handlers = {
        QUICK_CREATE_POPUP_SHORTCUT_WIN: handleQuickCreateShortcut,
        QUICK_CREATE_POPUP_SHORTCUT_MAC: handleQuickCreateShortcut,
        QUICK_NOTE_PANEL_SHORTCUT_MAC: handlePanelQuickNoteShortcut,
        QUICK_NOTE_PANEL_SHORTCUT_WIN: handlePanelQuickNoteShortcut,
        SPOTLIGHT_MAC: handleSpotlightShortcut,
        SPOTLIGHT_WIN: handleSpotlightShortcut,
    };

    return (
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges>
            <PanelQuickNote eid={quickNotePanelEid} setQuickNotePanelEid={setQuickNotePanelEid} />
            <QuickCreatePopup />
        </GlobalHotKeys>
    );
};

export default GlobalShortcutsHandler;
