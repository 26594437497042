export default function PageIcon(): JSX.Element {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.5 7.61c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h2c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-2ZM2 9.11c0-.27.22-.5.5-.5h2c.27 0 .5.23.5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1Zm7.5-1.5c-.83 0-1.5.67-1.5 1.5v1c0 .83.67 1.5 1.5 1.5h2c.83 0 1.5-.67 1.5-1.5v-1c0-.83-.67-1.5-1.5-1.5h-2ZM9 9.11c0-.27.22-.5.5-.5h2c.27 0 .5.23.5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1ZM1.5 1.61a.5.5 0 0 0 0 1h11a.5.5 0 1 0 0-1h-11Zm0 3a.5.5 0 0 0 0 1h11a.5.5 0 1 0 0-1h-11Z"
                fill="#333"
            />
        </svg>
    );
}
