import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';
import styles from './toast.module.scss';
import { getToastsSelector } from '../../redux/ui/selectors';
import { removeToast } from '../../redux/ui/actions';
import { MessageType } from '../../types/toasts';
import CloseMdIcon from '@/icons/CloseMdIcon';
import GreenCheckboxIcon from '@/icons/GreenCheckboxIcon';
import ErrorRedIcon from '@/icons/ErrorRedIcon';
import { LoadingOutlined } from '@ant-design/icons';
import Button from '../DesignSystem/Button';
import TriangleDownIcon from '@/icons/TriangleDownIcon';
import TriangleUpIcon from '@/icons/TriangleUpIcon';

const renderIcon = (type: MessageType): JSX.Element => {
    let icon = <GreenCheckboxIcon />;
    switch (type) {
        case MessageType.ERROR:
        case MessageType.WARNING:
            icon = <ErrorRedIcon />;
            break;
        case MessageType.LOADING:
            icon = <LoadingOutlined />;
            break;
    }
    return icon;
};

const Toast = (): JSX.Element => {
    const dispatch = useDispatch();
    const messages: MessageBlock[] = useSelector(getToastsSelector);
    const handleClose = (toastId) => dispatch(removeToast(toastId));
    const handleUndo = (action, toastId) => {
        dispatch(action);
        handleClose(toastId);
    };

    const slicedMessages = useMemo(() => {
        return messages.slice(0, 3);
    }, [messages]);

    const content = useMemo(() => {
        if (slicedMessages?.length) {
            return slicedMessages.map((message: MessageBlock) => {
                return (
                    <ToastItem message={message} handleClose={handleClose} handleUndo={handleUndo} key={message.id} />
                );
            });
        } else {
            return null;
        }
    }, [slicedMessages]);

    return <div className={`${styles.toastWrapper} ${!messages.length ? styles.hidden : ''}`}>{content}</div>;
};

type ToastItemProps = {
    message: MessageBlock;
    handleClose: (string) => void;
    handleUndo: (undo: ReduxAction, id: string) => void;
};

const ToastItem = ({ message, handleClose, handleUndo }: ToastItemProps) => {
    const [expanded, setIsExpanded] = useState(false);

    const toggleExpanded = () => {
        setIsExpanded((expanded) => !expanded);
    };

    const allowedTextSize = message?.undo ? 58 : 70;
    const canBeExpanded = message.text.length > allowedTextSize;
    const toastText = useMemo(() => {
        return canBeExpanded && !expanded ? message.text.slice(0, allowedTextSize) + '...' : message.text;
    }, [canBeExpanded, expanded, message.text, message?.undo]);

    return (
        <div className={`${styles.toastItem} ${expanded ? styles.toastItemExpanded : ''}`} key={message.id}>
            <div className={styles.toastCloseButton} onClick={() => handleClose(message.id)}>
                <CloseMdIcon />
            </div>
            <div className={styles.toastIcon}>{renderIcon(message.msgType)}</div>
            <span className={`${styles.toastItemText} ${expanded ? styles.toastItemTextExpanded : ''}`}>
                {toastText}
                {canBeExpanded && (
                    <div className={styles.showMore} onClick={toggleExpanded}>
                        {expanded ? <TriangleUpIcon /> : <TriangleDownIcon />}
                    </div>
                )}
            </span>
            {message?.undo && (
                <Button
                    variant="plain"
                    size="s"
                    onClick={() => handleUndo(message.undo, message.id)}
                    className={styles.toastUndoButton}
                >
                    <span>UNDO</span>
                </Button>
            )}
        </div>
    );
};

export default Toast;
