import { all, put, takeEvery, delay } from 'redux-saga/effects';
import { TOAST } from '../actionTypes';
import { addToast, removeToast } from './actions';

export function* toastSaga(action) {
    const { payload } = action;
    yield put(addToast(payload));
    yield delay(5000);
    yield put(removeToast());
}

function* uiSaga() {
    yield all([takeEvery(TOAST, toastSaga)]);
}

export default uiSaga;
