import React from 'react';
import { Form } from 'react-bootstrap';
import formStyles from '@/components/forms/forms.module.scss';
import Text from '../../DesignSystem/Text';
import Button from '../../DesignSystem/Button';
import welcomeModalStyles from './welcomeModal.module.scss';
import { useState } from 'react';
import { Emoji } from '@/utils/helpers';
import * as yup from 'yup';
import DeleteIcon from '@/icons/DeleteIcon';

const schema = yup.object().shape({
    email: yup.string().email(),
});

const emojisRanked = [
    '',
    ':thumbsup:',
    ':v:',
    ':heart:',
    ':star-struck:',
    ':scream:',
    ':shocked_face_with_exploding_head:',
    ':boom:',
];

const Invite = function (props): React.ReactElement {
    const { emails, addEmail, removeEmail, skipStep, prevStep, handleInviteClick } = props;
    const emoji = emojisRanked[emails?.length > 7 ? 7 : emails?.length];
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);

    const handleEmailInputChange = (e) => {
        setEmail(e.target.value);
    };

    const handleEmailSubmit = (e) => {
        e.preventDefault();
        const newEmail = e.target.elements.email.value;
        if (emails?.indexOf(newEmail) === -1) {
            addEmail(newEmail);
            setEmail('');
            setError(null);
        } else {
            setEmail('');
            setError(newEmail + ' is already in the list');
        }
    };

    return (
        <div className={welcomeModalStyles.avatarNameJobContainer}>
            <div>
                <Text variant="heading" size="h4" align="center">
                    Invite teammates
                </Text>
                <Text variant="body" size="s" align="center">
                    Get the most out of Bedrock by inviting your teammates
                </Text>
                <Form onSubmit={handleEmailSubmit} className={welcomeModalStyles.avatarNameJobInputsContainer}>
                    <div className={welcomeModalStyles.inviteEmailInput}>
                        <Form.Control
                            value={email}
                            onChange={handleEmailInputChange}
                            style={{ margin: '0 10px 0 0', fontSize: 14 }}
                            className={formStyles.formControl}
                            type="email"
                            placeholder="Email"
                            name="email"
                        />
                        {Boolean(email?.length) && (
                            <Button disabled={!schema.isValidSync({ email })} type="submit" size="m" variant="primary">
                                Add
                            </Button>
                        )}
                    </div>
                    {error ? <div className={formStyles.error}>{error}</div> : null}
                    <div
                        className={`${welcomeModalStyles.emailList} ${
                            emails?.length > 0 ? welcomeModalStyles.showList : ''
                        }`}
                    >
                        {emails.map((inviteListEmail, index) => (
                            <div key={index} className={welcomeModalStyles.email} onClick={() => removeEmail(index)}>
                                <Button icon={DeleteIcon} variant="plain" size="s" />
                                <div>{inviteListEmail}</div>
                            </div>
                        ))}
                    </div>
                </Form>
                <div className={welcomeModalStyles.stepControlContainer}>
                    <Button onClick={prevStep} size="s" variant="secondary">
                        {'<- Back'}
                    </Button>
                    <Button
                        className={welcomeModalStyles.inviteButton}
                        disabled={emails?.length == 0}
                        type="submit"
                        size="s"
                        variant="primary"
                        onClick={handleInviteClick}
                    >
                        Invite{' '}
                        <span className={welcomeModalStyles?.emoji} key={emoji}>
                            <Emoji native emoji={emoji} size={20} />
                        </span>
                    </Button>
                    <div />
                    <Text onClick={skipStep} size="xs" align="center" className={welcomeModalStyles.blueText}>
                        Invite later
                    </Text>
                </div>
            </div>
        </div>
    );
};

export default Invite;
