import { combineReducers } from 'redux';

import EntitiesReducer from './entities/reducer';
import UserReducer from './user/reducer';
import UIReducer from './ui/reducer';
import FilesReducer from './files/reducer';
import IntegrationReducer from './integrations/reducer';
import AIReducer from './ai/reducer';

const rootReducer = combineReducers({
    Entities: EntitiesReducer,
    User: UserReducer,
    UI: UIReducer,
    Files: FilesReducer,
    Integrations: IntegrationReducer,
    AI: AIReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
