import { useState, useEffect } from 'react';

const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(() => {
        if (typeof window === 'undefined') return;
        const media = window.matchMedia(query);
        return media.matches;
    });

    useEffect(() => {
        const media = window.matchMedia(query);
        setMatches(media.matches);

        const listener = () => {
            setMatches(media.matches);
        };
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, [matches, query]);

    return matches;
};

export const useMobileQuery = () => useMediaQuery('screen and (max-width: 767px)');

export default useMediaQuery;
