import { new_GET_ENTITY, SEARCH_ALL_PEOPLE_BY_EMAIL } from '@/queries';
import gqlRequest, { API } from '../redux/api';
import { asyncGQL, prepareCompletedUserAndAssociatedEntities, prepareNewUser } from './helpers';
import { BASE_ORIGIN } from '@/config';
import { COMPLETE_USER_SIGNUP } from '@/redux/actionTypes';

export const fetchGql = (options, session?) => {
    const sessionHeaders = session
        ? {
              'X-Hasura-Role': 'user',
              'X-Hasura-Used-Email': session.user.email,
              'X-Hasura-User-Id': session.id as string,
              'X-Hasura-Dataspaces': `{${Object.keys(session.memberships).join(',')}}`,
          }
        : {};
    return fetch(`${BASE_ORIGIN}/v1/graphql/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Hasura-Admin-Secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET,
            ...sessionHeaders,
        },
        body: JSON.stringify(options),
    }).then((res) => res.json());
};

export const createEntityAPI = (variables) => {
    return fetchGql({
        query: API.CREATE_ENTITY_AND_UPDATE_PARENT,
        variables,
    });
};

export const updateEntity = (variables) => {
    return fetchGql({
        query: API.UPDATE_ENTITY,
        variables,
    });
};

export const setEntityDeleted = (variables) => {
    return fetchGql({
        query: API.SOFT_DELETE_ENTITY,
        variables,
    });
};

export const fetchUser = async (session) => {
    const response = await fetchGql(
        {
            query: API.GET_USER,
        },
        session,
    );
    return response.data.me[0];
};

export const getFinderByDSID = async (dsid: string) => {
    const response = await fetchGql({
        query: API.GET_FINDER_BY_DSID,
        variables: {
            dsid,
        },
    });

    return response.data.EntityIndices[0];
};

export const searchEntities = async (search, dataspace) => {
    const response = await fetchGql({
        query: API.SEARCH_ENTITIES,
        variables: {
            search,
            dataspaces: `{${dataspace}}`,
        },
    });
    return response.data?.search_entities;
};

export const findOrCreatePersonEntity = async (email: string): Promise<Entity> => {
    const searchResponse = await asyncGQL(SEARCH_ALL_PEOPLE_BY_EMAIL, { search: email });
    let personEntity = searchResponse?.data?.search_all_people_by_email?.[0];
    if (!personEntity) {
        const updatedAction = prepareNewUser({
            queryType: 'CREATE_USER',
            payload: { email },
        });
        const response = await gqlRequest(updatedAction);
        personEntity = response.data?.insertUser?.requestEntity;

        const createUserDataspaceAction = prepareCompletedUserAndAssociatedEntities(
            { payload: {}, type: COMPLETE_USER_SIGNUP },
            personEntity,
        );
        await asyncGQL(API.COMPLETE_USER_SIGNUP, createUserDataspaceAction.payload.nextQueryVariables, true, {
            'X-Hasura-Admin-Secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET,
        });
    }
    return personEntity;
};

export const getPersonEntity = async (email: string): Promise<Entity | null> => {
    const searchResponse = await asyncGQL(SEARCH_ALL_PEOPLE_BY_EMAIL, { search: email });
    return searchResponse?.data?.search_all_people_by_email?.[0] || null;
};

// export const createPersonEntity = async (email: string, options): Promise<Entity> => {
//     const updatedAction = prepareNewUser({
//         queryType: 'CREATE_USER',
//         payload: { email, options },
//     });
//     const response = await gqlRequest(updatedAction);
//     const personEntity = response.data?.insertUser;
//     return personEntity;
// };

export const updatePersonEntity = async (personEntity: Entity | null, options): Promise<Entity> => {
    const updatedEntity = Object.assign({}, personEntity.entity, options);
    const action = {
        queryType: 'UPDATE_ENTITY',
        payload: { nextQueryVariables: { id: personEntity.id, entity: updatedEntity } },
    };
    const response = await gqlRequest(action);
    const updatedPersonEntity = response.data?.update_Entities?.returning[0];
    return updatedPersonEntity;
};

export const findPersonEntity = async (email: string): Promise<Entity> => {
    const searchResponse = await asyncGQL(SEARCH_ALL_PEOPLE_BY_EMAIL, { search: email }, true, {
        'X-Hasura-Admin-Secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET,
    });
    return searchResponse.data?.search_all_people_by_email?.[0];
};

export const getEntityById = async (id: string): Promise<Entity> => {
    const response = await asyncGQL(new_GET_ENTITY, { id }, true, {
        'X-Hasura-Admin-Secret': process.env.HASURA_GRAPHQL_ADMIN_SECRET,
    });
    return response.data?.Entities[0];
};

interface AuthInfo {
    sessionToken: string;
    sessionExpires: string;
    csrfToken: string;
    crispId: string;
    crispToken: string;
    crispExpires: string;
    callbackUrl: string;
}

interface Session {
    user: {
        name: string;
        email: string;
        avatarUrl: string;
    };
    auth: AuthInfo;
    expires?: number;
    crispE: string;
}

export async function switchUser(user) {
    const { sessions: sessionsRaw, currentUserId } = localStorage;
    const sessions: { [userId: string]: Session } = JSON.parse(sessionsRaw || '{}');
    const session = sessions[user.id];
    if (
        session &&
        currentUserId === user.userId &&
        session.user.name === user.name &&
        session.user.avatarUrl === user.avatarUrl &&
        session.user.email === user.email
    ) {
        return;
    }
    const res = await fetch('/api/auth/switchUser', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ ...session?.auth }),
    });
    const auth: AuthInfo = await res.json();
    const crispKey = Object.keys(localStorage).find((k) => k.startsWith('crisp-client'));
    const crispId = auth.crispId || crispKey?.match(/\/([^\/]+)$/)?.[1] || session?.auth.crispToken;
    const crispToken = auth.crispToken || localStorage[`crisp-client/session/${crispId}`] || session?.auth.crispToken;
    const crispE = localStorage.getItem(`crisp-client/session/${crispId}:e`) || session?.crispE;
    const { id: userId, name = session?.user.name, email = session?.user.email } = user;
    const avatarUrl = user.entity?.avatarUrl || user.avatarUrl || session?.user.avatarUrl;
    if (email && auth) {
        sessions[userId] = {
            auth: { ...auth, crispId, crispToken },
            user: { name, email, avatarUrl },
            crispE,
        };
    }
    localStorage.setItem('sessions', JSON.stringify(sessions));
    localStorage.setItem('currentUserId', userId);

    if (auth && crispId && crispToken && crispE) {
        localStorage.setItem(`crisp-client/session/${crispId}`, crispToken);
        localStorage.setItem(`crisp-client/session/${crispId}:e`, crispE);
        // leaving this for reference, if will ever be fully supported:
        // await cookieStore.set({
        //     name: encodeURIComponent(`crisp-client/session/${crispId}`),
        //     value: crispToken,
        //     expires: auth.crispExpires,
        //     sameSite: 'lax',
        //     secure: false,
        // });
    }
}

export const getDSIDFromSession = (session) => {
    const { memberships } = session;
    // WARNING: This is a hack to get the first owner's dsid, because we don't have a "deafult" dsid yet.
    return Object.keys(memberships).find((id) => memberships[id] === 'owner');
};

export const getPublicEntityDescendants = async (dsid: string, eid: string): Promise<Entity[]> => {
    const response = await fetch('/api/getPublicEntityDescendants', {
        method: 'POST',
        body: JSON.stringify({ dsid: dsid, eid: eid }),
    });

    return await response.json();
};
