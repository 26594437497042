import React, { useEffect, useState } from 'react';
import styles from './uploadFilesToast.module.scss';
import Button from '../DesignSystem/Button';
import { useSelector, useDispatch } from 'react-redux';
import { getUploadFilesToastSelector } from '../../redux/ui/selectors';
import { hideUploadFilesToast } from '../../redux/ui/actions';
import CloseMdIcon from '@/icons/CloseMdIcon';
import ListIcon from '@/icons/ListIcon';
import TriangleDownIcon from '@/icons/TriangleDownIcon';
import CheckboxMdIcon from '@/icons/CheckboxMdIcon';
import CloseSmIcon from '@/icons/CloseSmIcon';
import Text from '../DesignSystem/Text';

export const UploadFilesToast: React.FC = () => {
    const [showList, setShowList] = useState<boolean>(true);
    const dispatch = useDispatch();
    const { show, files } = useSelector(getUploadFilesToastSelector);

    const handleClose = () => {
        setShowList(true);
        return dispatch(hideUploadFilesToast());
    };

    const handleToggleList = () => {
        setShowList(!showList);
    };

    const uploadSuccessFiles = files.filter((file) => !file.isUploading && file.isUploadedSuccessfully);
    const uploadErrorFiles = files.filter((file) => !file.isUploading && file.isUploadFailed);
    const totalUploadFileCount = files.filter((file) => !file.isUploading).length;

    useEffect(() => {
        // hide if all files uploaded successfully, keep show if there's any errored
        if (files?.length && uploadSuccessFiles?.length === files?.length) {
            setTimeout(() => handleClose(), 5000);
        }
    }, [files]);

    if (!show || !totalUploadFileCount) {
        return null;
    }

    return (
        <div className={styles.toast}>
            <div className={styles.toastHeader} onClick={handleToggleList}>
                <div className={styles.toastButtons}>
                    <Button
                        size="s"
                        icon={showList ? TriangleDownIcon : ListIcon}
                        variant="plain"
                        className={styles.toastToggleButton}
                    />
                    <Text
                        variant="heading"
                        size="h6"
                        weight="semiBold"
                    >{`${uploadSuccessFiles.length}/${files.length} files were uploaded`}</Text>
                </div>
                <Button size="s" icon={CloseMdIcon} variant="plain" onClick={handleClose} />
            </div>
            <div className={`${styles.toastContent} ${!showList ? styles.toastContentClosed : ''}`}>
                {uploadErrorFiles.map((file) => (
                    <div className={`${styles.fileItem} ${styles.fileItemError}`} key={file.name}>
                        <Text variant="body" size="s" className={styles.fileName}>
                            {file.name}
                        </Text>
                        <CloseSmIcon />
                    </div>
                ))}
                {uploadSuccessFiles.map((file) => (
                    <div className={`${styles.fileItem} ${styles.fileItemSuccess}`} key={file.name}>
                        <Text variant="body" size="s" className={styles.fileName}>
                            {file.name}
                        </Text>
                        <CheckboxMdIcon />
                    </div>
                ))}
            </div>
        </div>
    );
};
