import { DriveAdapter, FigmaAdapter } from './adapter';
import { LocalFilesAdapter } from './adapters/LocalFilesAdapter';
import { IntegrationName } from './types';

export const INTEGRATIONS_LIST: IntegrationName[] = ['drive', 'figma', 'localFiles'];

export const INTEGRATIONS = {
    drive: new DriveAdapter(),
    figma: new FigmaAdapter(),
    localFiles: new LocalFilesAdapter(),
};

export const IDB_INTEGRATION_STORE_NAME = 'IntegrationsCache';
