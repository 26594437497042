import Select from 'react-select/creatable';

const TEAM_SIZE = ['1-4', '5-20', '20-100', '100+'];

const options = TEAM_SIZE.map((size) => {
    return { label: size, value: size };
});

const defaultStyles = {
    input: (provided) => ({
        ...provided,
        fontSize: 17,
        paddingBottom: 0,
        paddingTop: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(0, 160, 247, 0.15)' : 'none',
        borderRadius: 3,
        color: state.isSelected ? '#00A0F7' : 'black',
        fontSize: 17,
        marginLeft: 6,
        marginRight: 6,
        padding: '12px 18px',
        width: 'calc(100% - 12px)',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        cursor: 'pointer',
        outline: '0.5px solid #BFBFBF',
        padding: '12px 18px',
        borderRadius: 12,
        boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.10)',
        '&:hover': {
            outline: '1.5px solid #007AFF',
        },
        backgroundColor: 'linear-gradient(180deg, #FFF 0%, #FCFCFC 100%)',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        padding: 0,
        svg: {
            fill: '#00a0f7',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: 0,
        fontSize: 17,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: 17,
        lineHeight: '26px',
        width: '100%',
    }),
    menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 5,
        boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
        overflow: 'hidden',
        padding: '2px 0',
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#8B979C',
        padding: 0,
        margin: 0,
        fontSize: 17,
    }),
};

const TeamSizeDropdown = ({ onChange, value }) => {
    return (
        <Select
            id="team-size-input"
            value={value ? { label: value, value: value } : null}
            options={options}
            onChange={onChange}
            styles={defaultStyles}
            isSearchable
            blurInputOnSelect
            placeholder="How many people are in your company?"
            components={{
                IndicatorSeparator: () => null,
            }}
        />
    );
};

export default TeamSizeDropdown;
