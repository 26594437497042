import { all, put, takeEvery } from '@redux-saga/core/effects';
import { GET_DIR_CONTENT } from '../actionTypes';
import { getDirContentFailure } from './actions';

export function* getDirContentSaga(action) {
    try {
        const { path } = action.payload;
        window?.sendToElectron(window?.dirEventsChannelName, {
            action: 'GET_TREE',
            options: { path },
        });
    } catch (err) {
        yield put(getDirContentFailure({ error: err }));
    }
}

function* uiSaga() {
    yield all([takeEvery(GET_DIR_CONTENT, getDirContentSaga)]);
}

export default uiSaga;
