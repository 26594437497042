import { createSelector } from 'reselect';
import { AppState } from '../rootReducer';
import { find, map } from 'lodash';
import { includesInstance, instancesAreEqual } from '../../utils/helpers';
import { UIState } from '../types';

const getState = (state: AppState): UIState => state.UI;
export const getUIStateSelector = createSelector(getState, (state) => state);
export const getClearQueueSelector = createSelector(getState, (state) => state.clearQueue);
export const getEditModeSelector = createSelector(getState, (state) => state.editMode);
export const getEditEidSelector = createSelector(getState, (state) => state.editEid);
export const getPreviewSizeSelector = createSelector(getState, (state) => state.previewSize);
export const getHlistCollapsedStateSelector = createSelector(getState, (state) => state.isHlistCollapsed);
export const getIsHlistCanBeToggledSelector = createSelector(getState, (state) => state.hlistCanBeToggled);
export const getFolderViewSelector = createSelector(getState, (state) => state.folderView);
export const getModalSelector = createSelector(getState, (state) => state.modal);
export const getReroutingSelector = createSelector(getState, (state) => state.rerouting);
export const getUploadEntityPopupParentIdSelector = createSelector(
    getState,
    (state) => state.uploadEntityPopupParentId,
);
export const getEntityPanelId = createSelector(getState, (state) => state.entityPanelId);
export const getCopiedEntitiesSelector = createSelector(getState, (state) => state.copiedEntities);
export const getDataspaceIdsSelector = createSelector(
    (state: AppState) => state.UI.finder.allFinders,
    (state) => map(state, 'dsid'),
);
export const getDraggingHListItemSelector = createSelector(getState, (state) => state.draggingHListItem);
export const getSidebarVisibilitySelector = createSelector(getState, (state) => state.collapseSidebar);
export const getToastsSelector = createSelector(getState, (state) => state.toasts);

const getSelectedEntities = (state: AppState) => state.UI.selectedEntities;
export const getSelectedEntitiesSelector = createSelector(getSelectedEntities, (selectedEntities) => selectedEntities);
export const getHasManySelectedEntitiesSelector = createSelector(
    getSelectedEntities,
    (selectedEntities) => selectedEntities?.length > 1,
);

export const getIsInSelectedEntitiesSelector = (id: string, parentId: string, passthrough?: EntityPassthroughProps) =>
    createSelector(getSelectedEntities, (selectedEntities) => {
        if (!selectedEntities.length) return false;
        if (passthrough?.localDirectory) {
            return includesInstance(selectedEntities, {
                id,
                isLocalDirectory: passthrough?.localDirectory,
                localDirectoryPath: passthrough?.localDirItemData?.path,
            });
        }
        const isSelected = includesInstance(selectedEntities, { id, parentId });
        return isSelected;
        // return selectedEntities.length > 0 && includesInstance(selectedEntities, { id, parentId });
    });

const getBrowsedInstance = (state: AppState) => state.UI.browsedInstance;
export const getBrowsedInstanceSelector = createSelector(
    getBrowsedInstance,
    (browsedInstance): EntityIdentity => browsedInstance || {},
);

const getOpenPanels = (state: AppState) => state.UI.openPanels;
export const getOpenPanelSelector = (panelName: string) => {
    return createSelector(getOpenPanels, (openPanels) => openPanels[panelName]);
};

export const getIsBrowsedOrSelectedSelector = (id: string, parentId: string, passthrough?: any, infoMode?: InfoMode) =>
    createSelector(getBrowsedInstance, getSelectedEntities, (browsedInstance, selectedEntities) => {
        return (
            instancesAreEqual(browsedInstance, { id, parentId }) ||
            (includesInstance(selectedEntities, { id, parentId }) && selectedEntities?.length > 1) ||
            (passthrough?.drafts &&
                browsedInstance?.id?.length &&
                (id === browsedInstance?.id || id?.startsWith(browsedInstance?.id))) ||
            (infoMode === 'compact-flat' && id === browsedInstance?.id)
        );
    });

const getQuillEditorRefs = (state: AppState) => state.UI.quillEditorRefs;
export const getQuillEditorRefsSelector = createSelector(getQuillEditorRefs, (quillEditorRefs) => quillEditorRefs);

const getFinder = (state: AppState) => state.UI.finder;
const getEntities = (state: AppState) => state.Entities.entities;

export const getFinderSelector = createSelector(getFinder, getEntities, (finder, entities) => ({
    ...finder,
    entity: entities[finder.id],
}));

export const getTabsSelector = createSelector(
    getFinder,
    (finder) => finder.tabs?.[finder.id] || { tabsArray: [], tabsHistory: [] },
);

export const getFinderEntityByDsidSelector = (dsid: string) =>
    createSelector(getFinder, getEntities, (finder, entities) => {
        const finderId = find(finder.allFinders, { dsid })?.id;
        return entities[finderId];
    });

export const getAllExpandedHListItemsSelector = createSelector(getState, (state) => state?.expandedHListItems);
// Todo: Scope expandedHListItems to hlist eid
export const getExpandedHListItemsSelector = (hlistEid: string) => {
    return createSelector(getState, (state) => state?.expandedHListItems[hlistEid]);
};

const getRecentlyBrowsed = (state: AppState) => state.UI.recentlyBrowsed;
export const getRecentlyBrowsedSelector = createSelector(getRecentlyBrowsed, (recentlyBrowsed) => recentlyBrowsed);

const getActiveEntityContainers = (state: AppState) => state.UI.activeEntityContainers;
export const getActiveEntityContainersSelector = createSelector(getActiveEntityContainers, (activeEntityContainers) =>
    activeEntityContainers.map((container) => container?.props),
);

export const getGlobalModalsSelector = createSelector(getState, (state) => state.globalModals);

export const getShowHListSelector = createSelector(getState, (state) => state.showHList);

export const getRecentQuickNotesSelector = createSelector(getState, (state) => state.recentQuickNotes);

export const getUploadFilesToastSelector = createSelector(getState, (state) => state.filesUploadToast);

export const isTrashPageSelector = createSelector(getState, (state) => state.isTrashPage);

export const getQuickCreateMenuLogsSelector = createSelector(getState, (state) => state.quickCreateMenuLogs);

export const getScrollToBrowsed = createSelector(getState, (state) => state.scrollToBrowsed);

export const getMenuSelector = createSelector(getState, (state) => state.menu);

export const getRenamingInstanceSelector = createSelector(getState, (state) => state.renamingInstance);

export const getProtectSelectionSelector = createSelector(getState, (state) => state.protectSelection);

export const getSingleUseHListRootSelector = createSelector(getState, (state) => state.singleUseHListRoot);

export const getEntityUploadingProgressSelector = (eid: string) =>
    createSelector(getState, (state) => state.uploadingEntities?.[eid]);

export const getIsAllNotesViewSelector = createSelector(getState, (state) => state.isAllNotesView);

export const getIsBaseOnboardingSelector = createSelector(getState, (state) => state.isBaseOnboarding);

export const getShowChatPanelSelector = createSelector(getState, (state) => state.showChatPanel);

export const getIsAudibleContainer = (eid: string) =>
    createSelector(getState, (state) => state.audibleContainers?.includes(eid));
