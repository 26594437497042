export default function TriangleDownIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.91309 12.3008C8.71518 12.5976 8.27896 12.5976 8.08104 12.3008L4.0153 6.20215C3.79379 5.86988 4.03198 5.42481 4.43133 5.42481L12.5628 5.42481C12.9622 5.42481 13.2004 5.86988 12.9788 6.20216L8.91309 12.3008Z"
                fill="black"
                fillOpacity="1"
            />
        </svg>
    );
}
