import React, { useEffect } from 'react';
import { IntegrationAdapter, IntegrationName } from '../../types';
import LocalDirectoryIcon from '@/icons/LocalDirectoryIcon';
import { isImage, isVideo } from '@/utils/helpers';
import { GetIntegrationItemsProps } from '@/redux/types';
import DraftIcon from '@/icons/DraftIcon';
import ImageIcon from '@/icons/ImageIcon';
import VideoMdIcon from '@/icons/VideoMdIcon';
import LocalFileEntityView from './LocalFilesEntityView';
import { getLocalDirectoryTree, initElectronLocalFilesWatcher, stopElectronLocalFilesWatcher } from './helpers';
import FolderIcon from '@/icons/FolderIcons/FolderIcon';
import { buildUID } from '@/utils/IDManager';

const LocalFilesMoreScreen = ({ authorize }: { authorize: (name: IntegrationName, props?: any) => void }) => {
    useEffect(() => {
        initElectronLocalFilesWatcher().then((response) => {
            if (response?.ok) {
                authorize('localFiles', {
                    homedir: window?.homedir,
                    username: window?.deviceInfo?.username,
                    os: window?.deviceInfo?.os,
                });
            }
        });
    }, []);

    return null;
};

export class LocalFilesAdapter implements IntegrationAdapter {
    name = 'localFiles';
    icon = LocalDirectoryIcon;
    label = 'Local Files';

    // itemsCallback = (tokens) => `/api/integrations/apps/${this.name}/getFiles?tokens=${JSON.stringify(tokens)}`;
    // revokeCallback = (tokens) => `/api/integrations/apps/${this.name}/revoke?tokens=${JSON.stringify(tokens)}`;
    // createItemCallback = (tokens) => `/api/integrations/apps/${this.name}/createItem?tokens=${JSON.stringify(tokens)}`;
    initLocalFilesWatcher = initElectronLocalFilesWatcher;
    getCurrentDeviceId = () => {
        try {
            return window?.deviceInfo ? `${window?.deviceInfo?.username}@${window?.deviceInfo?.os}` : null;
        } catch (error) {
            console.error(error);
            return null;
        }
    };
    getEntityId = (entity) => `${entity?.app}_${entity?.appId}`;
    getEntityPath = (entity) => `${entity?.app}/${entity?.appId}`;
    getEntityIcon = (entity, params = { isOpen: false }) => {
        // <img className={styles.icon} src="/icons/browser/arrow_back.png" />

        if (this.isFolder(entity)) return <FolderIcon opened={params?.isOpen} localFolder />;
        if (isImage(entity?.name)) return <ImageIcon />;
        if (isVideo(entity?.name)) return <VideoMdIcon />;
        return <DraftIcon />;
    };
    renderEntityView = (entity) => <LocalFileEntityView entity={entity} />;
    renderMoreScreen = (authorize) => <LocalFilesMoreScreen authorize={authorize} />;
    isFolder = (entity) => entity?.isFolder || entity?.childrenList?.length || entity?.hasChildren;

    constructor() {
        this.getAuthorizationUrl = this.getAuthorizationUrl.bind(this);
        this.getItems = this.getItems.bind(this);
        this.revoke = this.revoke.bind(this);
        this.createItem = this.createItem.bind(this);
    }

    async getAuthorizationUrl({ homedir, username, os }, redirectToBaseApp = false) {
        return `${window.location.origin}/integrations/complete?${
            redirectToBaseApp ? 'redirect=base&' : ''
        }data=${encodeURIComponent(
            JSON.stringify({
                app: 'localFiles',
                appId: `${username}@${os}`,
                tokens: {
                    homedir,
                    username,
                    os,
                },
            }),
        )}`;
    }

    async getItems(tokens, props: GetIntegrationItemsProps): Promise<ServerResponseWithItemsData> {
        try {
            let paths = [tokens?.homedir, ...(props?.paths || [])];
            if (props?.mergeItems) {
                paths = paths.slice(1);
            }
            const appResponse = await getLocalDirectoryTree(paths);
            const app = 'localFiles';
            const appId = `${tokens?.username}@${tokens?.os}`;
            const rootId = buildUID({ fid: 'root', app, appId });
            const rootItem = appResponse?.data?.tree?.[tokens?.homedir];

            const items = {
                [rootId]: {
                    ...rootItem,
                    id: rootId,
                    app,
                    appId,
                    '@type': 'IntegrationItem',
                    name: `${tokens?.username} ${tokens?.os}`,
                    childrenList: rootItem?.childrenList?.map((child) => buildUID({ fid: child, app, appId })),
                },
            };
            for (const path in appResponse?.data?.tree) {
                const itemId = buildUID({ fid: path, app, appId });
                const item = appResponse?.data?.tree[path];
                const childrenList = item?.childrenList || [];
                items[itemId] = {
                    ...item,
                    app,
                    appId,
                    '@type': 'IntegrationItem',
                    id: itemId,
                    childrenList: childrenList.map((child) => buildUID({ fid: child, app, appId })),
                };
            }

            return {
                ...appResponse,
                itemsData: { items },
            };
        } catch (error) {
            return error;
        }
    }

    async revoke(tokens): Promise<ServerResponseWithItemsData> {
        try {
            const appResponse = await stopElectronLocalFilesWatcher(tokens?.homedir);

            return appResponse;
        } catch (error) {
            return error;
        }
    }

    // not used at the moment
    async createItem(): Promise<ServerResponseWithItemsData> {
        try {
        } catch (error) {
            return error;
        }
    }
}
