import Select from 'react-select/creatable';

const OCCUPATIONS =
    'Design, Engineering, Education, Product, Leadership, Investing, Scholarship, Writing, Marketing, Other'.split(
        ', ',
    );
const options = OCCUPATIONS.map((role) => {
    return { label: role, value: role };
});

const defaultStyles = {
    input: (provided) => ({
        ...provided,
        fontSize: '14px',
        paddingBottom: 0,
        paddingTop: 0,
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused ? 'rgba(0, 160, 247, 0.15)' : 'none',
        borderRadius: 3,
        color: state.isSelected ? '#00A0F7' : 'black',
        fontSize: 14,
        marginLeft: 6,
        marginRight: 6,
        paddingBottom: 0,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 0,
        width: 'calc(100% - 12px)',
    }),
    control: (provided) => ({
        ...provided,
        border: 'none',
        height: '24px',
        cursor: 'pointer',
        boxShadow: '0 0 0 0.5pt #ccc;',
        minHeight: 35,
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        paddingLeft: 0,
        svg: {
            fill: '#00a0f7',
        },
    }),
    valueContainer: (provided) => ({
        ...provided,
        paddingBottom: 0,
        paddingTop: 0,
        paddingRight: 0,
        fontSize: 14,
    }),
    singleValue: (provided) => ({
        ...provided,
        fontSize: 14,
        lineHeight: '26px',
        width: '100%',
        '@media screen and (max-width: 760x)': {
            fontSize: 12,
        },
    }),
    menu: (provided) => ({
        ...provided,
        border: 'none',
        borderRadius: 5,
        boxShadow: '0px 5px 30px 0px #00000026, 0px 4px 10px #00000026',
        overflow: 'hidden',
        padding: '2px 0',
    }),
};

const OccupationDropdown = ({ onChange, value, styles = {}, ...props }) => {
    return (
        <Select
            id="work-type-input"
            value={value ? { label: value, value: value } : null}
            options={options}
            onChange={onChange}
            styles={{ ...defaultStyles, ...styles }}
            isSearchable
            blurInputOnSelect
            components={{
                IndicatorSeparator: () => null,
            }}
            {...props}
        />
    );
};

export default OccupationDropdown;
