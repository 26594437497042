import { DRAFTS_DATASPACE_ID, SHARED_DATASPACE_ID } from '@/redux/constants';
import DraftSmIcon from './icons/DraftSmIcon';
import HomeIcon from './icons/HomeIcon';
import SharedIcon from './icons/SharedIcon';
import EntityImageIcon from './EntityImageIcon';

interface Props {
    dataspace: any;
    classname?: string;
}

const DataspaceIcon = ({ dataspace, classname }: Props): React.ReactElement => {
    const icon = dataspace?.iconUrl ? (
        <EntityImageIcon src={dataspace.iconUrl} />
    ) : dataspace?.id === SHARED_DATASPACE_ID ? (
        <SharedIcon />
    ) : dataspace?.id === DRAFTS_DATASPACE_ID ? (
        <DraftSmIcon />
    ) : (
        <HomeIcon />
    );

    return classname ? <div className={classname}>{icon}</div> : icon;
};

export default DataspaceIcon;
