import * as React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import HListItem from './subcomponents/HListItem/container';
import { HotKeys } from 'react-hotkeys';
import { useMemoCompare, useHListShortcutKeys } from '@/hooks/remoteHooks';
import { useDispatch, useSelector } from 'react-redux';
import {
    getBrowsedInstanceSelector,
    getAllExpandedHListItemsSelector,
    getSelectedEntitiesSelector,
    getShowHListSelector,
    isTrashPageSelector,
    getScrollToBrowsed,
    getMenuSelector,
    getRenamingInstanceSelector,
    getProtectSelectionSelector,
    getTabsSelector,
    getFinderSelector,
    getSingleUseHListRootSelector,
} from '@/redux/ui/selectors';
import {
    getAllCondensedEntitiesSelector,
    getDeletedEntityListSelector,
    getDsidSelector,
    getEntityLists,
} from '@/redux/entities/selectors';
import { findIndex, findLast, isEqual, uniq } from 'lodash';
import {
    clearBrowsed,
    clearSelectedEntities,
    unprotectSelection,
    setUI,
    triggerScrollToBrowsed,
    showMenu,
    singleUseHListRoot,
} from '@/redux/ui/actions';
import { ctrlClick, shiftClick } from '@/utils/multiselectHelpers';
import { includesInstance, simpleTextMatch, fillAppEntityWithAccounts } from '@/utils/helpers';
import { bulkMoveEntities } from '@/redux/entities/actions';
import typeInfo from './typeInfo';
import useVirtualSmooth from '../../hooks/useVirtualSmooth';
import { getIntegrationStateSelector } from 'redux/integrations/selectors';
import HListControlBar from './subcomponents/HListControlBar';
import styles from './Entity.module.scss';
import router from 'next/router';
import { useAppsFolderList, useEntity, useSelectorFactory } from '@/hooks/reduxHooks';
import { useCurrentDeviceLocalFilesConnectionStatus, useLocalFilesPathFetcher } from '@/hooks/localFilesHooks';
import { insertTab } from './subcomponents/Tabs/TabsBar';
import { DRAFTS_FINDER_ID, SHARED_FINDER_ID } from '@/redux/constants';
import BaseBottomBar from '@/components/entities/subcomponents/BaseBottomBar';
import ListControlService from '@/utils/listControlService';
import useAppWrapper from '@/hooks/useAppWrapper';
import HlistSearchBar from './subcomponents/HlistSearchBar';
import { createSelector } from 'reselect';
import { UIState } from '@/redux/types';
import { buildUID, parseUID } from '@/utils/IDManager';
import getConfig from 'next/config';
const { publicRuntimeConfig } = getConfig();
const NODE_ENV = publicRuntimeConfig.nodeEnv;

const getLastChildIndex = (
    eid: string,
    entityListLength: number,
    entityType: string,
    hasChildren: boolean,
    list: any[],
    rootIndex: number,
    firstChildIndex: number,
    rootZIndex: number,
) => {
    if (entityType === 'Finder' || eid.startsWith('root')) return entityListLength - 1;
    if (!hasChildren) return firstChildIndex;

    const nextNonChildIndex = list?.slice(firstChildIndex).findIndex((e) => e.zIndex <= rootZIndex);
    if (nextNonChildIndex > 0) return rootIndex + nextNonChildIndex;
    else if (nextNonChildIndex === 0) return rootIndex + 1;

    return (list?.length ?? 0) - 1;
};

const entityListSelectorFactory = (app: string, appId: string, isIntegrationHList: boolean) =>
    createSelector(
        getDsidSelector,
        getEntityLists,
        getDeletedEntityListSelector,
        isTrashPageSelector,
        getIntegrationStateSelector,
        (dsid, entityLists, deleted, isTrashPage, integrationState) => {
            if (isTrashPage) return deleted;
            if (isIntegrationHList) return integrationState?.[app]?.[appId]?.itemsList;
            return entityLists[dsid];
        },
    );

const combinedEntityListSelectorFactory = (eid: string, entityListSelector: (state: any) => any) =>
    createSelector(entityListSelector, getIntegrationStateSelector, (entityList, integrationState) => {
        if (!entityList) return [];
        const combinedList = [];

        const processAccountIntegrationItems = (accountItem, itemsList) => {
            for (const integrationItem of itemsList) {
                const newAncestorList = [accountItem.id, ...accountItem.ancestorList];
                const { fid, app, appId } = parseUID(integrationItem.id);
                const localId = buildUID({ fid, app, appId, parentEid: accountItem.rootAncestor });
                combinedList.push(
                    Object.assign({}, integrationItem, {
                        zIndex: integrationItem.zIndex + accountItem.zIndex + 1,
                        app: accountItem.app,
                        appId: accountItem.appId,
                        id: localId,
                        isIntegrationItem: true,
                        rootAncestor: accountItem.id,
                        ancestorList: newAncestorList,
                    }),
                );
            }
        };

        const processAppEntity = (entityListItem) => {
            const appEntity = fillAppEntityWithAccounts(entityListItem, integrationState);
            combinedList.push(appEntity);

            if (appEntity.childrenList) {
                for (let index = 0; index < appEntity.childrenList.length; index++) {
                    const integrationRootId = appEntity.childrenList[index];
                    const { fid, app, appId } = parseUID(integrationRootId);
                    const accountData = integrationState?.[app]?.[appId];
                    const integrationRootItem = accountData?.items[integrationRootId];

                    const newItem = {
                        ...integrationRootItem,
                        name: accountData?.name,
                        ancestorList: [entityListItem.id],
                        zIndex: entityListItem.zIndex + 1,
                        id: buildUID({ fid, app, appId, parentEid: entityListItem.id }),
                        rootAncestor: entityListItem.id,
                        hasChildren: accountData?.itemsList?.length > 0,
                        childIndex: index,
                    };
                    combinedList.push(newItem);

                    if (accountData?.itemsList) processAccountIntegrationItems(newItem, accountData.itemsList);
                }
            }
        };

        const processIntegrations = (integrationItemsList, entityListItem) => {
            const localRootIndex = findIndex(integrationItemsList, ['id', entityListItem.foreignId]);
            if (localRootIndex === -1) return;
            const localFirstChildIndex = localRootIndex + 1;

            const localRootZIndex = localRootIndex >= 0 ? integrationItemsList?.[localRootIndex].zIndex : 0;
            const localLastChildIndex = getLastChildIndex(
                eid,
                entityList.length,
                entityListItem['@type'],
                entityListItem.hasChildren,
                integrationItemsList,
                localRootIndex,
                localFirstChildIndex,
                localRootZIndex,
            );

            for (let i = localFirstChildIndex; i <= localLastChildIndex; i++) {
                const integrationItem = integrationItemsList[i];
                const isAncestorOfIntegrationItem =
                    integrationItem.ancestorList.includes(entityListItem.foreignId) ||
                    integrationItem.ancestorList.includes(entityListItem.id);
                if (!isAncestorOfIntegrationItem) continue;

                const newAncestorList = [...entityListItem.ancestorList];
                for (const ancestor of integrationItem.ancestorList) {
                    const isForeign = ancestor === entityListItem.foreignId;
                    if (isForeign) newAncestorList.push(entityListItem.id);
                    else newAncestorList.push(ancestor);
                }

                const { fid, app, appId } = parseUID(integrationItem.id);
                const localId =
                    fid === 'root' ? integrationItem.id : buildUID({ fid, app, appId, parentEid: entityListItem.id });

                combinedList.push(
                    Object.assign({}, integrationItem, {
                        zIndex: integrationItem.zIndex + entityListItem.zIndex - localRootZIndex,
                        app: entityListItem.app,
                        appId: entityListItem.appId,
                        id: localId,
                        isIntegrationItem: true,
                        rootAncestor: entityListItem.id,
                        ancestorList: newAncestorList,
                    }),
                );
            }
        };

        for (const entityListItem of entityList) {
            if (entityListItem['@type'] === 'App') {
                processAppEntity(entityListItem);
                continue;
            }

            combinedList.push(entityListItem);

            const integrations = integrationState?.[entityListItem.app]?.[entityListItem.appId]?.itemsList;
            const isIntegratonItemWithChildren =
                entityListItem['@type'] === 'IntegrationItem' && entityListItem.hasChildren && integrations;
            if (!isIntegratonItemWithChildren) continue;

            processIntegrations(integrations, entityListItem);
        }

        return combinedList;
    });

const hlistSelectorFactory = (
    searchText,
    entity,
    includeRootInHList,
    eid,
    combinedEntityListSelector,
    entityListSelector,
) =>
    createSelector(
        isTrashPageSelector,
        getAllExpandedHListItemsSelector,
        combinedEntityListSelector,
        (state) => entityListSelector(state)?.length ?? 0,
        (isTrashPage, expandedHListItems, combinedEntityIntegrationList: any[], entityListLength: number) => {
            const rootIndex = findIndex(combinedEntityIntegrationList, ['id', eid]);
            const rootZIndex = rootIndex >= 0 ? combinedEntityIntegrationList[rootIndex].zIndex : -1;
            const entityType = entity?.['@type'];
            const childrenList = entity?.childrenList;
            const firstChildIndex = rootIndex + 1;
            const lastChildIndex = getLastChildIndex(
                eid,
                entityListLength,
                entityType,
                childrenList?.length,
                combinedEntityIntegrationList,
                rootIndex,
                firstChildIndex,
                rootZIndex,
            );
            if ((!childrenList?.length && !isTrashPage) || !combinedEntityIntegrationList) return [];
            const hlist =
                includeRootInHList && firstChildIndex
                    ? combinedEntityIntegrationList.slice(rootIndex, lastChildIndex + 1)
                    : firstChildIndex
                    ? combinedEntityIntegrationList.slice(firstChildIndex, lastChildIndex + 1)
                    : combinedEntityIntegrationList;

            if (searchText) {
                return hlist.filter((item) => {
                    let name = item?.name || 'Untitled ' + typeInfo[item?.['@type']]?.fancyName;
                    name = name.replace(/(\r\n|\n|\r)+/gm, ' '); //Ignore newline chars. Should probably be done before name is saved
                    return simpleTextMatch(name, searchText);
                });
            }

            let zHideThreshold = -1;
            return hlist.filter((item) => {
                if (zHideThreshold < 0 || (zHideThreshold >= 0 && item.zIndex <= zHideThreshold)) {
                    if (item.hasChildren && !expandedHListItems[item.id]) zHideThreshold = item.zIndex;
                    else zHideThreshold = -1;
                    return true;
                }
                return false;
            });
        },
    );

const useEntityListSelectors = (
    searchText: string,
    includeRootInHList: boolean,
    entity: Entity,
    eid: string,
    isIntegrationHList: boolean,
) => {
    const { app, appId } = parseUID(eid);
    const [entityListSelector, entityList] = useSelectorFactory(
        entityListSelectorFactory,
        app,
        appId,
        isIntegrationHList,
    );
    const [combinedEntityListSelector, combinedEntityIntegrationList] = useSelectorFactory(
        combinedEntityListSelectorFactory,
        eid,
        entityListSelector,
    );

    const [, hlist] = useSelectorFactory(
        hlistSelectorFactory,
        searchText,
        entity,
        includeRootInHList,
        eid,
        combinedEntityListSelector,
        entityListSelector,
    );
    return [entityList, combinedEntityIntegrationList, hlist];
};

const EntityHList: React.FunctionComponent<EntityContainerProps> = (props: any) => {
    const {
        eid: eidFromProps,
        passthrough,
        infoMode,
        app: appFromProps,
        appId: appIdFromProps,
        onCheckCallback,
    } = props;
    const [hListRoot, setHListRoot] = useState<HListIdentity>({
        eid: eidFromProps,
        app: appFromProps,
        appId: appIdFromProps,
        isIntegrationItem: appFromProps && appIdFromProps,
    }); // Jank workaround to find out if the initial root was a Bedrock IntegrationItem
    const [hListRootStack, setHListRootStack] = useState([hListRoot]);
    const { eid, app, appId, isIntegrationItem: isIntegrationHList } = hListRoot;
    const entity = useEntity(eid, true, 'EntityHList');

    const entityType = entity?.['@type'];
    // Note: A Bedrock IntegrationItem entity is not an integrationHList, confusing I know.
    // Need to separate Bedrock IntegrationItems from non-Bedrock integrationItems
    const childrenList = entity?.childrenList;
    const dispatch = useDispatch();

    let newPassthrough = passthrough || {};
    const isLocalDir = (!eid && entity?.path) || entityType === 'LocalDirectory' || passthrough?.localDirectory;
    if (isLocalDir) newPassthrough = { ...newPassthrough, localDirectory: isLocalDir };
    const memoizedPassthrough = useMemoCompare(newPassthrough);
    const isTrashPage = useSelector(isTrashPageSelector);
    // const userIsAnonymous = useSelector(getIsUserAnonymous);

    const browsedInstance = useSelector(getBrowsedInstanceSelector);
    const browsedEntity = useEntity(browsedInstance?.id, true);
    const expandedHListItems = useSelector(getAllExpandedHListItemsSelector);
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const isProtectedSelection = useSelector(getProtectSelectionSelector);
    const condensedEntities = useSelector(getAllCondensedEntitiesSelector);
    const menuParams = useSelector(getMenuSelector);
    const showHList = useSelector(getShowHListSelector);
    const shouldScrollToBrowsed = useSelector(getScrollToBrowsed);
    const tabs = useSelector(getTabsSelector);
    const finder = useSelector(getFinderSelector);
    const [localState, setLocalState] = useState({
        browsedInstance: browsedInstance,
        selectedEntities: selectedEntities,
        anchorInstance: browsedInstance,
        expandedHListItems: Object.assign({}, expandedHListItems, { [eid]: true }),
    });
    const [searchText, setSearchText] = useState('');
    const [checkedEntityIds, setCheckedEntityIds] = useState<string[]>([]);
    const { hlistKeyMap: keyMap, hlistHandlers: handlers } = useHListShortcutKeys();
    const renamingInstance = useSelector(getRenamingInstanceSelector);
    const includeRootInHList = !(entityType === 'Finder' || router.pathname.includes('integrations'));
    const [entityList, combinedEntityIntegrationList, hlist] = useEntityListSelectors(
        searchText,
        includeRootInHList,
        entity,
        eid,
        isIntegrationHList,
    );

    const [isConnected, currentDeviceId, isRootFetched] = useCurrentDeviceLocalFilesConnectionStatus();
    const fetchLocalFilesPath = useLocalFilesPathFetcher(isConnected, currentDeviceId, isRootFetched);
    const singleUseHlistRoot = useSelector(getSingleUseHListRootSelector);
    const { appsFolderId } = useAppsFolderList();

    const { isBaseApp } = useAppWrapper();
    const isOnboarding = router.pathname.includes('/onboarding');

    useEffect(() => {
        const rootFromProps = {
            eid: eidFromProps,
            app: appFromProps,
            appId: appIdFromProps,
            isIntegrationItem: appFromProps && appIdFromProps,
        };
        if (!isEqual(rootFromProps, hListRoot)) {
            setHListRoot(rootFromProps);
            setHListRootStack([rootFromProps]);
        }
    }, [eidFromProps, appFromProps, appIdFromProps]);

    // For handleItemClick to access latest state;
    const localStateRef = useRef(localState);
    const entityListRef = useRef(entityList);
    const condensedEntitiesRef = useRef(condensedEntities);
    const searchTextRef = useRef(searchText);
    const tabsStateRef = useRef(tabs);
    const finderRef = useRef(finder);
    useEffect(() => {
        localStateRef.current = localState;
    }, [localState]);
    useEffect(() => {
        entityListRef.current = entityList;
    }, [entityList]);
    useEffect(() => {
        condensedEntitiesRef.current = condensedEntities;
    }, [condensedEntities]);
    useEffect(() => {
        searchTextRef.current = searchText;
    }, [searchText]);
    useEffect(() => {
        tabsStateRef.current = tabs;
    }, [tabs]);
    useEffect(() => {
        finderRef.current = finder;
    }, [finder]);

    // Respond to global browsedInstance change
    useEffect(() => {
        const newState = {} as UIState;
        if (browsedInstance?.id !== localState?.browsedInstance?.id) {
            newState.browsedInstance = browsedInstance;
        }
        if (browsedInstance?.id && !localState?.selectedEntities?.length) {
            newState.selectedEntities = [browsedInstance];
        }
        if (Object.keys(newState).length !== 0) {
            setLocalState((state) => ({
                ...state,
                ...newState,
                anchorInstance: browsedInstance,
            }));
        }
    }, [browsedInstance?.id]);

    // Respond to global selectedEntities change
    useEffect(() => {
        if (!isEqual(localState?.selectedEntities, selectedEntities))
            setLocalState((state) => ({ ...state, selectedEntities: selectedEntities }));
    }, [selectedEntities]);

    // Respond to global expandedHListItems change
    useEffect(() => {
        if (!isEqual(localState?.expandedHListItems, expandedHListItems))
            setLocalState((state) => ({ ...state, expandedHListItems: expandedHListItems }));
    }, [expandedHListItems]);

    const handleRangeSelection = useCallback((clickedInstance: EntityIdentity) => {
        const newSelectedEntities = shiftClick(
            clickedInstance,
            localStateRef.current?.selectedEntities,
            localStateRef.current?.anchorInstance,
            entityListRef.current,
        );
        setLocalState({
            ...localStateRef.current,
            selectedEntities: newSelectedEntities,
            anchorInstance: clickedInstance,
        });
        dispatch(setUI({ selectedEntities: newSelectedEntities }, 'EntityHList1'));
    }, []);

    const handleMultiSelection = useCallback((clickedInstance: EntityIdentity) => {
        const newSelectedEntities = ctrlClick(
            clickedInstance,
            localStateRef.current?.selectedEntities,
            entityListRef.current,
            condensedEntitiesRef.current,
            localStateRef.current?.browsedInstance?.id,
        );
        const newAnchorInstance =
            localStateRef.current?.browsedInstance?.id === clickedInstance?.id
                ? localStateRef.current?.anchorInstance
                : clickedInstance;
        setLocalState({
            ...localStateRef.current,
            selectedEntities: newSelectedEntities,
            anchorInstance: newAnchorInstance,
        });
        dispatch(setUI({ selectedEntities: newSelectedEntities }, 'EntityHList2'));
    }, []);

    const toggleListOpen = useCallback(
        (id: string, open: boolean) => {
            setLocalState((state) => {
                dispatch(setUI({ expandedHListItems: { ...state?.expandedHListItems, [id]: open } }));
                return { ...state, expandedHListItems: { ...state?.expandedHListItems, [id]: open } };
            });
        },
        [expandedHListItems],
    );

    const changeHListRoot = useCallback(
        (newHListRoot?: EntityIdentity, debugMessage?: string) => {
            if (debugMessage && NODE_ENV === 'development') console.log(debugMessage);
            const currentIndex = findIndex(hListRootStack, ['eid', eid]);
            const newRoot = newHListRoot
                ? (({ id: eid, app, appId, isIntegrationItem }) => ({ eid, app, appId, isIntegrationItem }))(
                      newHListRoot,
                  )
                : (({ id: eid, app, appId, isIntegrationItem }) => ({ eid, app, appId, isIntegrationItem }))(
                      browsedInstance,
                  );

            setHListRoot(newRoot);
            setHListRootStack((oldStack) => [...oldStack.slice(0, currentIndex + 1), newRoot]);
            toggleListOpen(newRoot.eid, true);
        },
        [hListRootStack, browsedInstance, eid, toggleListOpen],
    );

    const handleNormalItemClick = useCallback(
        (clickedInstance: EntityIdentity, clickType: string) => {
            // if (
            //     localStateRef.current?.browsedInstance?.id === clickedInstance?.id &&
            //     localStateRef.current?.browsedInstance?.parentId === clickedInstance?.parentId &&
            //     !isTrashPage &&
            //     !clickedInstance.isIntegrationItem &&
            //     !userIsAnonymous
            // ) {
            //     dispatch(setRenamingInstance(clickedInstance.id, clickedInstance.parentId));
            // } else {
            //     if (renamingInstance?.id) dispatch(clearRenamingInstance());
            // }
            const newSelectedEntities = [clickedInstance];
            const ancestorList = getAncestorList(clickedInstance);
            const expandMap = ancestorList.reduce((acc, curr) => {
                acc[curr] = true;
                return acc;
            }, {});
            const newExpandedHListItems = Object.assign({}, localStateRef.current?.expandedHListItems, expandMap);
            if (isBaseApp && clickType === 'double') {
                changeHListRoot(clickedInstance, 'handleNormalItemClick');
            }
            const newTabs = insertTab(
                tabsStateRef.current,
                clickedInstance,
                localStateRef.current?.browsedInstance?.id,
                clickType === 'double',
            );
            setLocalState({
                ...localStateRef.current,
                browsedInstance: clickedInstance,
                selectedEntities: newSelectedEntities,
                anchorInstance: clickedInstance,
                expandedHListItems: newExpandedHListItems,
            });
            dispatch(
                setUI(
                    {
                        browsedInstance: clickedInstance,
                        selectedEntities: newSelectedEntities,
                        expandedHListItems: newExpandedHListItems,
                        finder: {
                            ...finderRef.current,
                            tabs: {
                                ...finderRef.current?.tabs,
                                [finderRef.current?.id]: newTabs,
                            },
                        },
                    },
                    'EntityHList3',
                ),
            );
        },
        [isBaseApp, changeHListRoot],
    );

    const handleItemClick = useCallback(
        (clickedInstance: EntityIdentity, e: any) => {
            // do nothing on onboarding page
            if (isOnboarding) {
                return;
            }
            // If shift click
            if (e.shiftKey && !isIntegrationHList && !searchTextRef.current) {
                handleRangeSelection(clickedInstance);
                return;
            }
            // If ctrl/cmd click
            if ((e.metaKey || e.ctrlKey) && !isIntegrationHList && !searchTextRef.current) {
                handleMultiSelection(clickedInstance);
                return;
            }
            // If normal click
            const clickType = e.detail == 1 ? 'single' : 'double';
            handleNormalItemClick(clickedInstance, clickType);
        },
        [handleMultiSelection, handleNormalItemClick, handleRangeSelection, isIntegrationHList, isOnboarding],
    );

    useEffect(() => {
        if (!isBaseApp) return;
        const noChildren = !browsedEntity?.childrenList || browsedEntity?.childrenList?.length === 0;
        const hListRootId = hListRoot.eid;
        if (
            (hListRootId === browsedEntity?.metadata?.id ||
                hListRootId === browsedEntity?.id ||
                hListRootId === browsedEntity?.key) &&
            noChildren
        ) {
            dispatch(setUI({ isHlistCollapsed: true }));
        } else {
            dispatch(setUI({ isHlistCollapsed: false }));
        }
    }, [hListRoot, browsedEntity, dispatch, isBaseApp]);

    useEffect(() => {
        if (singleUseHlistRoot) changeHListRoot(singleUseHlistRoot, 'singleUseHlistRoot');
        dispatch(singleUseHListRoot(null));
    }, [singleUseHlistRoot]);

    const prevHListRoot = useCallback(() => {
        const currentIndex = findIndex(hListRootStack, ['eid', eid]);
        if (currentIndex > 0) {
            setHListRoot(hListRootStack[currentIndex - 1]);
        }
    }, [eid, hListRootStack]);

    const nextHListRoot = useCallback(() => {
        const currentIndex = findIndex(hListRootStack, ['eid', eid]);
        if (currentIndex > -1 && currentIndex < hListRootStack.length - 1) {
            setHListRoot(hListRootStack[currentIndex + 1]);
        }
    }, [eid, hListRootStack]);

    // Enter to Rename
    // (Disabled until global focus management added)

    // useEffect(() => {
    //     if (browsedInstance?.id) {
    //         const allowEnterPressToRename =
    //             !browsedInstance?.isIntegrationItem &&
    //             !isTrashPage &&
    //             !userIsAnonymous &&
    //             !searchText &&
    //             !document?.activeElement.classList.contains('ProseMirror');
    //         const handleEnterPress = (e) => {
    //             if (e.key === 'Enter' && allowEnterPressToRename) {
    //                 e.preventDefault();
    //                 dispatch(setRenamingInstance(browsedInstance.id, browsedInstance?.parentId));
    //             }
    //         };
    //         document.addEventListener('keydown', handleEnterPress);

    //         return () => document.removeEventListener('keydown', handleEnterPress);
    //     }
    // }, [browsedInstance, document.activeElement]);

    const rootId = isIntegrationHList ? entity?.id : eid;
    const rootIndex = useMemo(
        () => findIndex(combinedEntityIntegrationList, ['id', rootId]),
        [rootId, combinedEntityIntegrationList],
    );
    const rootZIndex = rootIndex >= 0 ? combinedEntityIntegrationList[rootIndex].zIndex : -1;

    const dropEntities = useCallback(
        (item: DnDMovedItem, target: DnDMovedItem, zone: string, additionalIndent?: number) => {
            const dropItem = { id: item.eid, parentId: item.parentId };
            const droppedEntities = includesInstance(localStateRef.current?.selectedEntities, dropItem)
                ? localStateRef.current?.selectedEntities
                : [dropItem];

            const droppedIds = droppedEntities?.map((e) => e.id);

            const listIndex = {};
            for (let i = 0; i < entityListRef.current.length; i++) {
                const item = entityListRef.current[i];
                if (!listIndex[item.id]) listIndex[item.id] = [];
                listIndex[item.id].push(i);
            }

            if (zone === 'edge') {
                // Note: The correct drop "target" must be determined to calculate drop index among siblings.
                // Scenario 1: If there is no additionalIndent, the drop target is the item below the edgeDrop line.
                //      The dragged item is dropped as a sibling "above" this target.
                // Scenario 2: If there is an additionalIndent, the drop target is the closest item above the edgeDrop line
                //      with that indent level. The dragged item is dropped as a sibling "below" this target.
                // If dropped at the bottom of an hlist, and there is no additionalIndent, a fake last item
                //      is used as the drop target for Scenario 1.
                const initialTargetIndex =
                    listIndex[target.eid].find((i) => {
                        return entityListRef.current[i].parentId === target.parentId;
                    }) + (target.hlistBottom ? 1 : 0);
                const targetZIndex = target.hlistBottom
                    ? rootZIndex + 1 + additionalIndent
                    : entityListRef.current?.[initialTargetIndex]?.zIndex + additionalIndent;

                if (additionalIndent > 0) {
                    target = findLast(
                        entityListRef.current?.slice(0, initialTargetIndex),
                        (n) => n.zIndex === targetZIndex,
                    );
                } else if (target.hlistBottom) {
                    target = { parentId: eid, childIndex: childrenList.length };
                }

                // Workaround check to make sure none of the target's ancestors or children are in selectedEntities (bad news);
                // Remove with Aliases
                if (target.ancestorList?.includes(droppedIds)) return;
                if (target.childrenList?.includes(droppedIds)) return;

                let diff = 0;
                if (droppedEntities?.length > 1) {
                    // Reduce drop index by # of DIRECT SIBLING selected entities above it that are being dragged (diff).
                    // i.e. Drop index = target.childIndex - diff
                    const targetMasterIndex = target.hlistBottom
                        ? listIndex[eid] + childrenList?.length + 1
                        : listIndex[target.eid].find((i) => {
                              return entityListRef.current[i].parentId === target.parentId;
                          });
                    // Probably needlessly slow.
                    diff = localStateRef.current?.selectedEntities.filter(
                        (s) =>
                            listIndex[s.eid].find((i) => {
                                return entityListRef.current[i].parentId === s.parentId;
                            }) < targetMasterIndex && s.parentId == target.parentId,
                    ).length;
                }
                // If dropping above target as a sibling (no additionalIndent), and dragged item and drop target already were siblings,
                // and dragged item started above target, subtract one from drop index to compensate.
                if (
                    additionalIndent === 0 &&
                    item.parentId === target.parentId &&
                    item.childIndex < target.childIndex
                ) {
                    diff += 1;
                    // If dropping below target as a sibling (had additionalIndent), and dragged item and drop target already were NOT siblings,
                    // and dragged item did NOT started above target, subtract one to drop below target.
                } else if (
                    additionalIndent > 0 &&
                    !(item.parentId === target.parentId && item.childIndex < target.childIndex)
                ) {
                    diff -= 1;
                }
                dispatch(bulkMoveEntities(target.parentId, droppedEntities, target.childIndex - diff));
            } else if (zone === 'middle') {
                // Workaround check to make sure none of the target's ancestors or children are in selectedEntities (bad news);
                // Remove with Aliases
                if (target.ancestorList.includes(droppedIds)) return;
                if (target.childrenList?.includes(droppedIds)) return;
                dispatch(bulkMoveEntities(target.eid, droppedEntities, 0));
            }
        },
        [childrenList?.length, eid, rootZIndex],
    );

    const combinedEntityIntegrationListRef = useRef(combinedEntityIntegrationList);
    useEffect(() => {
        combinedEntityIntegrationListRef.current = combinedEntityIntegrationList;
    }, [combinedEntityIntegrationList]);

    const getAncestorList = useCallback((clickedInstance: EntityIdentity): string[] => {
        const index = findIndex(combinedEntityIntegrationListRef.current, ['id', clickedInstance.id]);
        if (index === -1) return [];

        const item = combinedEntityIntegrationListRef.current[index];
        let lastZIndex = item.zIndex;
        const ancestorList = [];
        for (let i = index; i >= 0; i--) {
            const ent = combinedEntityIntegrationListRef.current[i];
            if (ent.zIndex < lastZIndex) {
                ancestorList.push(ent.id);
                lastZIndex = ent.zIndex;
            }
            if (lastZIndex <= 0) break;
        }
        return ancestorList;
    }, []);

    const handleFinderContextMenu = (event) => {
        const universalId = parseUID(eid);
        const eidOrFid = universalId.fid || universalId.eid;

        const isRestrictedId = ['root', SHARED_FINDER_ID, DRAFTS_FINDER_ID].includes(eidOrFid);
        const isValidFinderEntity = entityType === 'Finder' && !isRestrictedId && !isTrashPage;

        if (!isValidFinderEntity) {
            return;
        }

        event.preventDefault();
        const { clientX: x, clientY: y } = event;
        const rootContextEntity = {
            id: eid,
            entity,
        };
        dispatch(showMenu({ type: 'ContextMenu', x, y, contextMenuEntity: rootContextEntity }));
    };

    const { rowVirtualizer, parentRef } = useVirtualSmooth(hlist.length);

    const scrollToBrowsed = () => {
        const browsedIndex = hlist?.findIndex((item) => item.id === localState.browsedInstance.id);
        rowVirtualizer?.scrollToIndex(browsedIndex, { align: 'center' });
    };
    useEffect(() => {
        if (shouldScrollToBrowsed) {
            scrollToBrowsed();
            dispatch(triggerScrollToBrowsed(false));
        }
    }, [shouldScrollToBrowsed]);

    useEffect(() => {
        if (showHList) scrollToBrowsed();
    }, [showHList]);

    useEffect(() => {
        if (menuParams?.contextMenuEntity) fetchLocalFilesPath(menuParams.contextMenuEntity.entity);
    }, [menuParams]);

    const handleCheckboxChange = useCallback((value: boolean, ids: string[]): void => {
        if (value) {
            setCheckedEntityIds((prevState) => {
                return uniq(prevState.concat(ids));
            });
            return;
        }
        setCheckedEntityIds((prevState) =>
            prevState.filter((entityId) => {
                return !ids.includes(entityId);
            }),
        );
    }, []);

    useEffect(() => {
        if (onCheckCallback) {
            onCheckCallback(checkedEntityIds);
        }
    }, [checkedEntityIds, onCheckCallback]);

    const handleUnprotectSelection = useCallback(
        (identity?: EntityIdentity) => {
            dispatch(unprotectSelection());
            const isNotSelected =
                identity && !includesInstance(selectedEntities, { id: identity.id, parentId: identity.parentId });
            if (isNotSelected) {
                handleItemClick(identity, { detail: 1 });
            }
        },
        [handleItemClick, selectedEntities],
    );

    const hlistItems = useMemo(() => {
        const renderItem = (virtualItem) => {
            const item = hlist[virtualItem.index];
            const isRootAppItem = parseUID(item.id).fid === 'root';
            const isBrowsed = localState?.browsedInstance?.id === item.id;
            const isSelected = includesInstance(localState?.selectedEntities, { id: item.id, parentId: item.parentId });
            const isDescendantOfBrowsed = item?.ancestorList.includes(localState?.browsedInstance?.id);
            const isDescendantOfSelected = localState?.selectedEntities.some((selectedEntity) =>
                item?.ancestorList.includes(selectedEntity.id),
            );
            const isAncestorChecked = checkedEntityIds.some((checkedId) => item?.ancestorList.includes(checkedId));
            const prevItem = hlist[virtualItem.index - 1];

            const isChecked = checkedEntityIds.includes(item.id);

            const isRenaming = renamingInstance?.id === item.id && renamingInstance?.parentId === item.parentId;
            const itemStyle: React.CSSProperties = {
                transform: `translateY(${virtualItem.start}px)`,
            };
            if (isRenaming) {
                itemStyle.zIndex = 1000;
            }

            const triggerToggleListOpen = (toggledId, open) => {
                if (open) fetchLocalFilesPath(item?.raw || item);
                toggleListOpen(toggledId, open);
            };

            const edgeMaxAdditionalIndent = !prevItem?.ancestorList.includes(appsFolderId)
                ? (prevItem?.zIndex || rootZIndex) - item.zIndex
                : rootZIndex - item.zIndex;

            const disableDragAndDrop =
                isIntegrationHList || item.isIntegrationItem || item?.['@type'] === 'App' || isRootAppItem;

            // TODO: when app type entities will have their account entities generated in redux, remove appChildrenLength
            const appChildrenLength = item?.['@type'] === 'App' ? item?.childrenList?.length : 0;

            return (
                <HListItem
                    key={`hlist-${item.id}-${item.parentId}`}
                    style={itemStyle}
                    eid={item.id}
                    parentId={item.parentId}
                    childIndex={item.childIndex}
                    infoMode={infoMode === 'checkbox' ? infoMode : 'hlist'}
                    indentLevel={item.zIndex - rootZIndex - 1}
                    isBrowsed={isBrowsed}
                    isSelected={isSelected}
                    isOpen={localState?.expandedHListItems[item.id]}
                    isChildOfBrowsed={isDescendantOfBrowsed}
                    isChildOfSelected={isDescendantOfSelected}
                    isFocused={menuParams?.contextMenuEntity?.id === item.id || menuParams?.eid === item.id}
                    passthrough={memoizedPassthrough}
                    handleItemClick={handleItemClick}
                    overrideClick={isProtectedSelection ? handleUnprotectSelection : null}
                    toggleListOpen={triggerToggleListOpen}
                    dropEntities={dropEntities}
                    ancestorList={item.ancestorList}
                    searchText={searchText}
                    disableDragDrop={disableDragAndDrop}
                    app={app || item.app}
                    appId={appId || item.appId}
                    isIntegrationItem={isIntegrationHList || item.isIntegrationItem}
                    edgeMaxAdditionalIndent={edgeMaxAdditionalIndent}
                    lastItem={virtualItem.index == hlist.length - 1}
                    rootEid={eid}
                    includeRootInHList={includeRootInHList}
                    isChecked={isChecked}
                    isAncestorChecked={isAncestorChecked}
                    setIsChecked={handleCheckboxChange}
                    isRootAppItem={isRootAppItem}
                    appsFolderId={appsFolderId}
                    // TODO: when app type entities will have their account entities generated in redux, remove appChildrenLength
                    appChildrenLength={appChildrenLength}
                    changeHListRoot={changeHListRoot}
                />
            );
        };
        return rowVirtualizer.virtualItems.map(renderItem);
    }, [
        app,
        appId,
        appsFolderId,
        checkedEntityIds,
        dropEntities,
        eid,
        fetchLocalFilesPath,
        handleCheckboxChange,
        handleItemClick,
        handleUnprotectSelection,
        hlist,
        includeRootInHList,
        infoMode,
        isIntegrationHList,
        isProtectedSelection,
        localState?.browsedInstance?.id,
        localState?.expandedHListItems,
        localState?.selectedEntities,
        memoizedPassthrough,
        menuParams?.contextMenuEntity?.id,
        menuParams?.eid,
        renamingInstance?.id,
        renamingInstance?.parentId,
        rootZIndex,
        rowVirtualizer.virtualItems,
        searchText,
        toggleListOpen,
    ]);

    const handleFinderWrapperClick = () => {
        if (isProtectedSelection) {
            handleUnprotectSelection();
            return;
        }
        setLocalState((state) => ({ ...state, selectedEntities: [], browsedInstance: {} }));
        if (selectedEntities?.length) dispatch(clearSelectedEntities());
        if (browsedInstance?.id) dispatch(clearBrowsed());
    };

    useEffect(() => {
        if (isBaseApp) {
            const ancestorList = getAncestorList({ id: eid });
            ListControlService.updateValues({
                eid,
                hListRootStack,
                entity,
                prevHListRoot,
                nextHListRoot,
                changeHListRoot,
                ancestorList,
            });
        }
    }, [changeHListRoot, eid, entity, hListRootStack, nextHListRoot, prevHListRoot, getAncestorList, isBaseApp]);

    const listStyles = useMemo(() => {
        if (infoMode === 'checkbox') {
            return {
                height: 'calc(100% - 45px)',
                padding: '0',
            };
        }

        if (isOnboarding) {
            return {
                height: '100%',
                padding: '0',
            };
        }

        if (isBaseApp) {
            return {
                height: 'calc(100% - 27px)',
            };
        }

        return {};
    }, [infoMode, isOnboarding, isBaseApp]);

    return (
        <div
            className={`${styles.hlistContainer} ${isBaseApp ? styles.hlistContainerBaseApp : ''} ${
                !browsedInstance?.id && styles.hlistContainerNotBrowsed
            }`}
        >
            {entity && !isBaseApp && (
                <HListControlBar
                    eid={eid}
                    hListRootStack={hListRootStack}
                    entity={entity}
                    infoMode={infoMode}
                    searchText={searchText}
                    searchCallback={setSearchText}
                    prevHListRoot={prevHListRoot}
                    nextHListRoot={nextHListRoot}
                    changeHListRoot={changeHListRoot}
                    app={app}
                    appId={appId}
                />
            )}
            {infoMode === 'checkbox' && <HlistSearchBar searchText={searchText} setSearchText={setSearchText} />}
            <div
                id={`entity-hlist-${eid}`}
                ref={parentRef}
                className={styles.hlistContent}
                style={listStyles}
                onClick={handleFinderWrapperClick}
                onContextMenu={handleFinderContextMenu}
            >
                <HotKeys
                    keyMap={keyMap}
                    handlers={handlers}
                    allowChanges
                    style={{ position: 'relative', height: '100%' }}
                >
                    <div
                        style={{
                            height: rowVirtualizer.totalSize,
                            minHeight: '100%',
                            width: '100%',
                            position: 'relative',
                        }}
                        className={`${styles.hlistRows}`}
                    >
                        {hlistItems}

                        {/* {rowVirtualizer.virtualItems.map(renderItem)} */}
                    </div>
                </HotKeys>
            </div>
            {isBaseApp && !isOnboarding && <BaseBottomBar />}
            <svg
                width="5"
                height="5"
                viewBox="0 0 5 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ position: 'absolute' }}
            >
                <defs>
                    <clipPath id="lastSelectionBottomCorners">
                        <path
                            d="M-1.52588e-05 6.10352e-05C-4.69501e-06 2.99994 2.5 5.00003 4.99999 5.00003L2.99998 5.00005L-1.52588e-05 4.99999V6.10352e-05Z"
                            fill="#D9D9D9"
                            fillOpacity="0.62"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default React.memo(EntityHList);
