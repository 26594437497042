import React, { memo, useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useEntity } from '../../hooks/reduxHooks';
import { isEmptyTab, isRichTextBlank } from '../../utils/helpers';
import styles from './Entity.module.scss';
import { useMemoCompare } from '../../hooks/remoteHooks';
import typeInfo from './typeInfo';
import 'emoji-mart/css/emoji-mart.css';
import 'tippy.js/dist/tippy.css';
import useCreateThumbnail from '@/hooks/useCreateThumbnail';
import HListPreview from './subcomponents/HListPreview';
import { INTEGRATIONS } from 'integrations/constants';
import { cloneDeep } from 'lodash';
import Head from 'next/head';
import EmptyTab from './subcomponents/EmptyTab';
import useAppWrapper from '@/hooks/useAppWrapper';
import { parseUID } from '@/utils/IDManager';

const EntityContainer: React.FunctionComponent<EntityContainerProps> = (props: EntityContainerProps) => {
    const { app, appId, eid, infoMode, passthrough, condensedOk } = props;
    const { isBaseApp } = useAppWrapper();

    const entity = useEntity(eid, condensedOk, 'EntityContainer');

    const newPass: EntityPassthroughProps = passthrough ? cloneDeep(passthrough) : {};

    const memoizedPassthrough = useMemoCompare(newPass);

    const [isBlankEntity, setBlankEntity] = useState(isRichTextBlank(entity));
    const setIsBlankEntity = useCallback((value) => setBlankEntity(value), []);

    useEffect(() => {
        setIsBlankEntity(isRichTextBlank(entity));
    }, [entity?.content]);

    const componentRef = useRef(null);
    const uniqueId = `${eid}${app ? `_${app}` : ''}${appId ? `_${appId}` : ''}`;

    const containerRefCallback = useCreateThumbnail(entity);

    const isFolder =
        entity?.['@type'] === 'HList' ||
        (entity?.['@type'] === 'IntegrationItem' && INTEGRATIONS[entity?.app]?.isFolder(entity));
    const ThisComponent =
        infoMode === 'hlist'
            ? entity?.['@type'] === 'IntegrationItem'
                ? (typeInfo['IntegrationItem'].component as React.FunctionComponent<EntityContainerProps>)
                : (typeInfo['HList'].component as React.FunctionComponent<EntityContainerProps>)
            : isFolder && infoMode === 'browsed'
            ? (HListPreview as React.FunctionComponent<EntityContainerProps>)
            : (typeInfo[entity?.['@type']]?.component as React.FunctionComponent<EntityContainerProps>);

    const [name, setName] = useState('');

    const emptyTab = isEmptyTab(eid);

    useEffect(() => {
        if (entity) {
            setName(entity?.name || `Untitled ${typeInfo[entity?.['@type']]?.fancyName}`);
        }
    }, [entity]);

    const thisComponent = useMemo(
        () => (
            <ThisComponent
                {...props}
                entity={entity}
                passthrough={memoizedPassthrough}
                {...(entity?.['@type'] === 'RichText' && infoMode !== 'panel'
                    ? { isBlankEntity, setIsBlankEntity }
                    : {})}
            />
        ),
        [ThisComponent, entity, infoMode, isBlankEntity, memoizedPassthrough, props, setIsBlankEntity],
    );

    return (
        <div
            ref={componentRef}
            className={
                !isBaseApp
                    ? styles.entity
                    : styles.baseEntity +
                      (typeof infoMode == 'string' ? ` ${infoMode}` : '') +
                      ` ${eid} entity-container`
            }
            data-eid={eid}
            {...((entity && entity['@type'] !== 'Finder' && parseUID(eid).fid !== 'root') || emptyTab
                ? { 'data-unique-id': uniqueId }
                : {})}
        >
            {infoMode === 'browsed' && (
                <Head>
                    <title>{name}</title>
                    <meta property="og:title" content={name} />
                    <meta property="twitter:title" content={name} />
                </Head>
            )}
            {entity ? (
                <section className={styles.frame}>
                    <div
                        className={styles.content}
                        ref={
                            entity['@type'] !== 'Finder' && (infoMode === 'browsed' || infoMode === 'entity-page')
                                ? containerRefCallback
                                : null
                        }
                    >
                        {thisComponent}
                    </div>
                </section>
            ) : emptyTab ? (
                <EmptyTab />
            ) : null}
        </div>
    );
};

export default memo(EntityContainer);
