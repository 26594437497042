export default function BedrockIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.5 9c-.42.2-.83.41-1.27.59-.04.02-.06.06-.05.1.1.49.24.95.43 1.4.02.04.06.06.1.05.87-.18 1.52-.54 2.25-.94 1.39-.77 2.95-1.63 6.35-1.63 2.8 0 4.94-.28 6.62-.64a.1.1 0 0 0 .07-.1 8 8 0 0 0-.28-1.94c0-.05-.07-.08-.12-.06l-.09.03c-1.76.7-4.19 1.63-7.68 1.63-3.4 0-4.96.8-6.33 1.5ZM12.25 12.55c.8 0 1.54.02 2.24.06a.1.1 0 0 0 .08-.04c.22-.3.4-.63.58-.96a.1.1 0 0 0-.08-.13h-4.29c-2.9 0-4.19.78-5.44 1.54-.71.43-1.4.84-2.3 1.06-.08.02-.1.11-.03.16.5.4 1.05.74 1.64 1.02h.06c.8-.19 1.4-.58 2.05-1.01 1.2-.8 2.57-1.7 5.49-1.7Z"
                fill="#B3B3B3"
            />
            <path
                d="M12.25 13.48a7.8 7.8 0 0 0-5 1.55c-.32.22-.65.43-1.01.62-.07.04-.06.14.02.16a8.08 8.08 0 0 0 7.4-2.15c.06-.06.03-.16-.06-.16-.43-.02-.88-.02-1.35-.02ZM1.07 8.17c.7-.37 1.5-.78 2.57-1.09 1.2-.35 2.58-.53 4.2-.53 1.47 0 2.86-.17 4.26-.52 1.2-.31 2.2-.7 3.08-1.04.05-.01.08-.04.12-.05.05-.02.07-.07.05-.12A8 8 0 0 0 0 8l.02.55c0 .06.07.1.12.07.31-.13.62-.29.93-.45ZM4.85 12.22a9.9 9.9 0 0 1 5.93-1.67h1.56c1.18 0 2.24.01 3.2-.01.04 0 .07-.02.08-.06.15-.49.27-.98.33-1.5 0-.06-.05-.11-.1-.1-1.69.34-3.8.6-6.53.6-1.42 0-2.62.16-3.65.48-.9.29-1.59.66-2.24 1.03-.7.39-1.36.77-2.22.98-.06.01-.08.09-.05.14.24.41.52.8.84 1.15.02.03.06.04.08.04a6.09 6.09 0 0 0 2.77-1.08Z"
                fill="#B3B3B3"
            />
        </svg>
    );
}
