import {
    createIntegrationItemSuccess,
    deleteIntegrationItemSuccess,
    getIntegrationItems,
} from '@/redux/integrations/actions';
import { getIntegrationItemsSelector } from '@/redux/integrations/selectors';
import { getIntegrationAccountsSelector, getUserSelector } from '@/redux/user/selectors';
import { isElectron } from '@/utils/helpers';
import { LocalFilesAdapter } from 'integrations/adapters/LocalFilesAdapter';
import { INTEGRATIONS } from 'integrations/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAppWrapper from '@/hooks/useAppWrapper';
import { buildUID } from '@/utils/IDManager';
import { useEntity } from './reduxHooks';

enum DIR_EVENT_ACTION {
    CREATED = 0,
    DELETED = 1,
    MODIFIED = 2, // electron skips this event, so we don't use it
    RENAMED = 3,
}

interface DIR_EVENT {
    directory: string;
    file: string;
    newDirectory: string;
    newFile: string;
    oldFile: string;
    action: DIR_EVENT_ACTION;
}
interface LocalFilesEvent {
    type: 'DIR_EVENT';
    events?: DIR_EVENT[];
}

export const useCurrentDeviceLocalFilesConnectionStatus = (): [boolean, string, boolean] => {
    const localFilesAdapter = new LocalFilesAdapter();
    const [isConnected, setIsConnected] = useState(false);
    const app = 'localFiles';
    const currentDeviceId = localFilesAdapter.getCurrentDeviceId();
    const rootId = buildUID({ fid: 'root', app, appId: currentDeviceId });
    const rootItem = useEntity(rootId, true, 'useCurrentDeviceLocalFilesConnectionStatus');
    const integrationAccounts = useSelector(getIntegrationAccountsSelector);
    const isRootFetched = Boolean(rootItem);

    useEffect(() => {
        setIsConnected(
            Boolean(isElectron() && currentDeviceId && integrationAccounts?.[`${app}_${currentDeviceId}`]?.tokens),
        );
    }, [integrationAccounts, currentDeviceId, isElectron]);

    return [isConnected, currentDeviceId, isRootFetched];
};

export const useLocalFilesPathFetcher = (isConnected: boolean, currentDeviceId: string, isRootFetched: boolean) => {
    const integrationAccounts = useSelector(getIntegrationAccountsSelector);
    const dispatch = useDispatch();

    const fetch = useCallback(
        (item: IntegrationItem | Entity) => {
            if (
                isElectron() &&
                currentDeviceId &&
                integrationAccounts?.[`${item?.app}_${currentDeviceId}`]?.tokens &&
                item?.app === 'localFiles' &&
                item?.appId === currentDeviceId &&
                INTEGRATIONS['localFiles'].isFolder(item)
            ) {
                dispatch(
                    getIntegrationItems(item?.app, currentDeviceId, {
                        paths: [item?.foreignId],
                        mergeItems: isRootFetched,
                    }),
                );
            }
        },
        [currentDeviceId, dispatch, integrationAccounts, isRootFetched],
    );

    return fetch;
};

export const useLocalFilesSubscription = () => {
    const [isConnected, currentDeviceId] = useCurrentDeviceLocalFilesConnectionStatus();
    const app = 'localFiles';
    const localFilesAdapter = INTEGRATIONS[app];
    const integrationItems = useSelector(getIntegrationItemsSelector(app, currentDeviceId));
    const user = useSelector(getUserSelector);
    const { isBaseApp } = useAppWrapper();
    const accountAdded = Boolean(user?.entity?.integrationAccounts?.[`${app}_${currentDeviceId}`]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isBaseApp && accountAdded && !integrationItems) {
            dispatch(getIntegrationItems(app, currentDeviceId));
        }
    }, [isBaseApp, accountAdded, integrationItems, currentDeviceId]);

    const buildIntegrationItem = useCallback(
        (file: string, directory: string) => ({
            '@type': 'IntegrationItem',
            app,
            appId: currentDeviceId,
            id: `${directory}/${file}`,
            foreignId: `${directory}/${file}`,
            name: file,
            extension: file.split('.').pop(),
        }),
        [app, currentDeviceId],
    );

    const isEventRelatedToCurrentTree = useCallback(
        (event: DIR_EVENT) => {
            const { directory, newDirectory } = event;
            let related = false;
            if (directory) {
                related = Boolean(integrationItems?.[directory]);
            }
            if (newDirectory) {
                related = Boolean(integrationItems?.[newDirectory]);
            }
            return related;
        },
        [integrationItems],
    );

    const handleEvent = useCallback(
        (e, data: LocalFilesEvent) => {
            const { type } = data;
            if (type === 'DIR_EVENT') {
                const createItemsParams = [];
                const deleteItemsParams = [];

                for (const event of data.events) {
                    const { directory, oldFile, newFile, file } = event;
                    if (!isEventRelatedToCurrentTree(event)) {
                        // ignore events that are not related to the current tree
                        continue;
                    }

                    switch (event.action) {
                        case 0:
                            const createdItem = buildIntegrationItem(file, directory);
                            createItemsParams.push({ parentId: directory, item: createdItem as IntegrationItem });
                            break;
                        case 1:
                            const path = `${directory}/${file}`;
                            deleteItemsParams.push({ parentId: directory, id: path });
                            break;
                        case 3:
                            const oldPath = `${directory}/${oldFile}`;

                            const udpatedItem = buildIntegrationItem(newFile, directory);
                            deleteItemsParams.push({ parentId: directory, id: oldPath });
                            createItemsParams.push({ parentId: directory, item: udpatedItem as IntegrationItem });
                            break;
                        default:
                            break;
                    }
                }

                if (deleteItemsParams.length) {
                    dispatch(deleteIntegrationItemSuccess(app, currentDeviceId, deleteItemsParams));
                }
                if (createItemsParams.length) {
                    dispatch(createIntegrationItemSuccess(app, currentDeviceId, createItemsParams));
                }
            }
        },
        [integrationItems, isEventRelatedToCurrentTree, buildIntegrationItem, app, currentDeviceId],
    );

    const initLocalFilesWatcher = useMemo(() => {
        if (isConnected) {
            return localFilesAdapter.initLocalFilesWatcher();
        }
        return null;
    }, [isConnected, localFilesAdapter]);

    useEffect(() => {
        if (initLocalFilesWatcher) {
            window?.addElectronListener(window.dirEventsChannelName, handleEvent);
            return () => {
                window?.removeElectronListener(window.dirEventsChannelName, handleEvent);
            };
        }
    }, [initLocalFilesWatcher, handleEvent]);
};
