export default function DriveFolderIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.30004 14C2.58205 14 2 13.418 2 12.7V8.65573C1.17103 8.65573 0.499023 9.32774 0.499023 10.1567V12.7C0.499023 14.2469 1.75308 15.501 3.30004 15.501H5.8433C6.67226 15.501 7.34427 14.829 7.34427 14H3.30004ZM8.65573 14C8.65573 14.829 9.32774 15.501 10.1567 15.501H12.7C14.2469 15.501 15.501 14.2469 15.501 12.7V10.1567C15.501 9.32774 14.829 8.65573 14 8.65573V12.7C14 13.418 13.418 14 12.7 14H8.65573ZM14 7.34427C14.829 7.34427 15.501 6.67226 15.501 5.8433V3.30004C15.501 1.75308 14.2469 0.499023 12.7 0.499023H10.1567C9.32774 0.499023 8.65573 1.17103 8.65573 2H12.7C13.418 2 14 2.58205 14 3.30004V7.34427ZM7.34427 2C7.34427 1.17103 6.67226 0.499023 5.8433 0.499023H3.30004C1.75308 0.499023 0.499023 1.75308 0.499023 3.30004V5.8433C0.499023 6.67226 1.17103 7.34427 2 7.34427V3.30004C2 2.58205 2.58205 2 3.30004 2H7.34427Z"
                fill="#333333"
            />
            <path
                d="M9.62715 3.94022L6.37305 3.94022L9.02351 8.53095L9.43623 9.24088L12.6875 9.24088L9.62715 3.94022Z"
                fill="#F9C941"
            />
            <path
                d="M12.6871 9.23999L8.72797 8.01889L9.03073 8.54329L9.43594 9.23999L12.6871 9.23999Z"
                fill="#E8B835"
            />
            <path
                d="M11.0603 12.0593L12.6874 9.24112L7.38644 9.24112L6.56526 9.24359L4.93964 12.0593L11.0603 12.0593Z"
                fill="#2A71E9"
            />
            <path d="M4.94073 12.0593L7.97779 9.24112L7.37227 9.24112L6.5663 9.24369L4.94073 12.0593Z" fill="#296AD9" />
            <path
                d="M3.3125 9.24161L4.93955 12.0597L7.59001 7.46901L7.99846 6.75661L6.37284 3.94095L3.3125 9.24161Z"
                fill="#0BA25E"
            />
            <path d="M6.37244 3.94098L7.29448 7.98022L7.59724 7.45582L7.998 6.75654L6.37244 3.94098Z" fill="#089156" />
        </svg>
    );
}
