import store from '../../redux/store';
import { UserState } from '../../redux/types';

const genericEntitySkeleton = (): Entity => {
    const state = store.getState();
    const user = state.User as UserState;

    return {
        author: user?.name,
    };
};

export default genericEntitySkeleton;
