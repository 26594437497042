import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal } from '../../redux/ui/actions';
import { getFinderSelector } from '../../redux/ui/selectors';
import { createEntity } from '../../redux/entities/actions';

import { useRouter } from 'next/router';
import Button from '../DesignSystem/Button';

// Not sure if we still use this file
const Adder: React.FunctionComponent<AdderProps> = (props: AdderProps) => {
    const { WrappedComponent, entity, submitCallback, buttonText, type } = props;
    const dispatch = useDispatch();

    const [workingEntity, setWorkingEntity] = useState(entity);

    const router = useRouter();
    const finder = useSelector(getFinderSelector);

    let updateWorkingEntity = function (obj: object): void {
        const newEntity = Object.assign({}, workingEntity, obj);
        setWorkingEntity(newEntity);
    };

    let submit = function (newEntity): void {
        dispatch(
            createEntity(
                {
                    entity: newEntity,
                    type,
                    parentId: finder?.id,
                    parentEntity: finder?.entity,
                },
                (ent) => {
                    if (submitCallback) {
                        submitCallback(ent);
                    }
                    dispatch(closeModal());
                    if (router.pathname == '/') {
                        router.push(`/entity/${ent?.id}`);
                    }
                },
                // Need some sort of fallback here to enable presetEntities behavior
            ),
        );
    };

    submit = submit.bind(this);
    updateWorkingEntity = updateWorkingEntity.bind(this);

    const ThisComponent = WrappedComponent as React.FunctionComponent<FormChildProps>;

    return (
        <>
            {ThisComponent ? (
                <ThisComponent
                    workingEntity={workingEntity}
                    setWorkingEntity={setWorkingEntity}
                    updateWorkingEntity={updateWorkingEntity}
                    // uploadEntitiesAndSetParent={uploadEntitiesAndSetParent}
                    submit={submit}
                    parentEntity={props.parentEntity}
                />
            ) : (
                <Button
                    size="s"
                    variant="plain"
                    onClick={function (): void {
                        submit(workingEntity);
                    }}
                >
                    {buttonText}
                </Button>
            )}
        </>
    );
};

export default Adder;
