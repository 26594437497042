export default function CopyLinkIcon(): JSX.Element {
    return (
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.5 4.00001L10.2929 1.20711C10.6834 0.816583 11.3166 0.816583 11.7071 1.20711L13.7929 3.29289C14.1834 3.68342 14.1834 4.31658 13.7929 4.70711L11 7.50001M4 7.5L1.20711 10.2929C0.816582 10.6834 0.816583 11.3166 1.20711 11.7071L3.29289 13.7929C3.68342 14.1834 4.31658 14.1834 4.70711 13.7929L7.5 11M4.5 10.5L10.5 4.5"
                stroke="#4D4D4D"
                strokeWidth="1.25"
                strokeLinecap="round"
            />
        </svg>
    );
}
