import { createSelector } from 'reselect';

import { AppState } from '../rootReducer';
import { UserState } from '../types';

const getState = (state: AppState): UserState => state.User;
export const getUserSelector = createSelector(getState, (state) => state);

const getPending = (state: AppState) => state.User.pending;
export const getPendingSelector = createSelector(getPending, (pending) => pending);

const getError = (state: AppState) => state.User.error;
export const getErrorSelector = createSelector(getError, (error) => error);

export const getBlankFocusPreference = createSelector(
    getState,
    (state) => state.entity?.preferences?.blankFocus || 'content',
);
export const getIntegrationAccountsSelector = createSelector(getState, (state) => state?.integrationAccounts);

export const getAppBackground = createSelector(getState, (state) => state.entity?.appBackground);

export const getDismissedPromptsSelector = createSelector(getState, (state) => state.entity?.dismissedPrompts);

export const getIsUserAnonymous = createSelector(getState, (state) => !(state.dsid && state.email));

export const getIsUserInDataspace = (entityDsid: string) =>
    createSelector(getState, (state) => Object.keys(state.memberships).includes(entityDsid));
