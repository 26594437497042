export default function ExternalLinkMdIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.34 1.74a.75.75 0 1 0 0 1.5h2.97L7.08 8.47a.75.75 0 0 0 1.06 1.06l5.23-5.23v2.97a.75.75 0 1 0 1.5 0V1.74H9.34ZM12 7.25c.41 0 .75.34.75.75v4.73c0 .97-.78 1.75-1.75 1.75H3.87c-.96 0-1.75-.77-1.75-1.74V5.78c0-.97.79-1.75 1.75-1.75H8.5a.75.75 0 0 1 0 1.5H3.87a.25.25 0 0 0-.25.25v6.96c0 .13.11.25.25.25H11c.14 0 .25-.12.25-.26V8c0-.41.34-.75.75-.75Z"
                fill="#333"
            />
        </svg>
    );
}
