import { createSelector } from 'reselect';
import { filter, find, orderBy, uniq } from 'lodash';
import { AppState } from '../rootReducer';
import { DRAFTS_DATASPACE_ID, DRAFTS_FINDER_ID, SHARED_DATASPACE_ID, SHARED_FINDER_ID } from '../constants';

const getFullEntities = (state: AppState) => state.Entities.entities;
const getCondensedEntities = (state: AppState) => state.Entities.condensedEntities;
export const getEntityLists = (state: AppState) => state.Entities.entityLists;
const getDsid = (state: AppState) => state.Entities.dsid;
const getInvalidIds = (state: AppState) => state.Entities.invalidIds;
const getFetching = (state: AppState) => state.Entities.fetching;
const getFetchErrorCounts = (state: AppState) => state.Entities.fetchErrorCounts;
const getPending = (state: AppState) => state.Entities.pending;
const getError = (state: AppState) => state.Entities.error;
const getInitialLoad = (state: AppState) => state.Entities.initialLoad;
const getDeletedEntityList = (state: AppState): Entity[] => state.Entities.deletedEntityList;
const getImagesDataUrl = (state: AppState): Record<string, any> => state.Entities.imageDataUrlsWithIds;
const getIsBulkCreateProcessing = (state: AppState) => state.Entities.isBulkCreateProcessing;
const getEntitiesWithoutReadAccess = (state: AppState) => state.Entities.entitiesWithoutReadAccess;

// ---------Condensed & Full Entity selectors (condensed = without entity content);
export const getCondensedEntitySelector = (id: string) => {
    return createSelector(getCondensedEntities, (entities) => entities[id]);
};
export const getCondensedEntitiesSelector = (ids: string[]) => {
    return createSelector(getCondensedEntities, (entities) =>
        ids?.map((id) => {
            return { ...entities[id], id };
        }),
    );
};
export const getAllCondensedEntitiesSelector = createSelector(getCondensedEntities, (entities) => entities);

export const getDeletedCondensedEntitySelector = createSelector(getCondensedEntities, (entities) => {
    return Object.keys(entities).reduce((acc, id) => {
        if (entities[id].deleted) {
            acc[id] = entities[id];
        }
        return acc;
    }, {});
});

export const getDeletedEntityListSelector = createSelector(
    getDeletedEntityList,
    (deletedEntityList) => deletedEntityList,
);

export const getIsEmptyDeletedEntityList = createSelector(
    getDeletedEntityList,
    (deletedEntityList) => deletedEntityList.length === 0,
);

export const getFullEntitySelector = (id: string) => {
    return createSelector(getFullEntities, (entities) => entities[id]);
};
export const getFullEntitiesSelector = (ids: string[]) => {
    return createSelector(getFullEntities, (entities) =>
        ids?.map((id) => {
            return { ...entities[id], id };
        }),
    );
};
export const getAllFullEntitiesSelector = createSelector(getFullEntities, (entities) => entities);

// ------------

export const isDraftSelector = (id: string) =>
    createSelector(
        getEntityLists,
        (entityLists) => id === DRAFTS_FINDER_ID || (entityLists[DRAFTS_DATASPACE_ID] || []).some((e) => e.id === id),
    );

export const isSharedSelector = (id: string) =>
    createSelector(
        getEntityLists,
        (entityLists) => id === SHARED_FINDER_ID || (entityLists[SHARED_DATASPACE_ID] || []).some((e) => e.id === id),
    );

export const getRecentSelector = (state: AppState): CondensedEntity[] => state.Entities.recent;

export const getEntityListSelector = (dsid: string) => {
    return createSelector(getEntityLists, (entityLists) => entityLists[dsid]);
};

export const getEntityListItemSelector = (dsid: string, id: string, parentId?: string) => {
    return createSelector(getEntityLists, (entityLists) => {
        const list = entityLists[dsid];
        if (parentId) return find(list, { id: id, parentId: parentId });
        else return find(list, { id: id });
    });
};

export const getDsidSelector = createSelector(getDsid, (dsid) => dsid);
export const getDataspaceSelector = createSelector(getDsid, getFullEntities, (dsid, entities) => entities[dsid]);
export const getUsersInDSSelector = createSelector(getDsid, getFullEntities, (dsid, entities) => {
    return filter(entities, (entity) => {
        return entity?.['@type'] === 'Person' && entity.memberships && Object.keys(entity.memberships).includes(dsid);
    });
});

export const getUserByEmailSelector = (email: string) => {
    return createSelector(getFullEntities, (entities) => {
        return find(entities, ['email', email]);
    });
};

export const getFetchingSelector = (id: string) => {
    return createSelector(getFetching, (fetching) => fetching[id]);
};

export const getFetchErrorCountSelector = (id: string) => {
    return createSelector(getFetchErrorCounts, (fetchErrorCounts) => fetchErrorCounts[id] || 0);
};

export const getMultiFetchingSelector = (ids: string[]) => {
    return createSelector(getFetching, (fetching) =>
        ids?.map((id) => {
            return fetching[id];
        }),
    );
};

export const getInvalidIdsSelector = createSelector(getInvalidIds, (invalidIds) => invalidIds);

export const getPendingSelector = createSelector(getPending, (pending) => pending);

export const getErrorSelector = createSelector(getError, (error) => error);

export const getInitialLoadSelector = createSelector(getInitialLoad, (initialLoad) => initialLoad);

export const getCurrentDsidMembers = createSelector(getDsid, getFullEntities, (dsid, entities) => {
    const members = Object.keys(entities[dsid]?.members);
    if (members) {
        return members.map((id) => entities[id]);
    }
});

export const getEidsFromEntityListByTypeSelector = (
    type: EntityType,
    sortBy?: EntitySortField,
    sortOrder?: 'asc' | 'desc',
) => {
    return createSelector(getDsid, getEntityLists, getCondensedEntities, (dsid, entityLists, condensedEntities) => {
        const currentEntityList = entityLists[dsid];
        const filteredEntitiesFromEntityList = filter(currentEntityList, (entity) => entity?.['@type'] === type);
        const condensedFilteredEntities = filteredEntitiesFromEntityList.map((entity) => condensedEntities[entity.id]);

        if (!sortBy) {
            return condensedFilteredEntities.map((e) => e.metadata?.id);
        }
        return uniq(orderBy(condensedFilteredEntities, sortBy, sortOrder).map((e) => e.metadata?.id));
    });
};

export const getEidsFromAllEntityListsByTypeSelector = (
    type: EntityType,
    sortBy?: EntitySortField,
    sortOrder?: 'asc' | 'desc',
) => {
    return createSelector(getEntityLists, getCondensedEntities, (entityLists, condensedEntities) => {
        const mergedEntityLists: Entity[] = Object.values(entityLists).flat();
        const filteredEntitiesList: Entity[] = filter(mergedEntityLists, (entity) => entity?.['@type'] === type);
        const condensedFilteredEntities: Entity[] = filteredEntitiesList.map((entity) => condensedEntities[entity.id]);

        if (!sortBy) {
            return condensedFilteredEntities.map((e) => e.metadata?.id);
        }
        return uniq(orderBy(condensedFilteredEntities, sortBy, sortOrder).map((e) => e.metadata?.id));
    });
};

export const getAllSchemasSelector = createSelector(getDsid, getFullEntities, (dsid, entities) => {
    return filter(entities, (entity) => {
        return entity?.['@type'] === 'AdditionalType' && entity?.metadata?.dsid === dsid;
    });
});

export const getAllObjectsBySchema = (schemaId: string) => {
    return createSelector(getDsid, getCondensedEntities, (dsid, entities) => {
        return filter(entities, (entity) => {
            return (
                entity?.['@type'] === 'Blob' &&
                entity?.metadata?.dsid === dsid &&
                entity?.additionalTypes?.includes(schemaId)
            );
        });
    });
};

export const getImagesWithDataUrl = createSelector(getImagesDataUrl, (imageDataUrlsWithIds) => imageDataUrlsWithIds);

export const getIsBulkCreateProcessingSelector = createSelector(
    getIsBulkCreateProcessing,
    (isProcessing) => isProcessing,
);

export const getEntitiesWithoutReadAccessSelector = createSelector(
    getEntitiesWithoutReadAccess,
    (entitiesWithoutReadAccess) => entitiesWithoutReadAccess,
);

export const getDataspaceAppsFolderSelector = createSelector(getDsid, getFullEntities, (dsid, entities) => {
    const dataspace = entities[dsid];
    if (!dataspace) return;
    const id = dataspace?.appsFolderId;
    return entities[id];
});

export const getDataspaceAppsSelector = createSelector(
    getDataspaceAppsFolderSelector,
    getCondensedEntities,
    (appsFolder, condensedEntities) => appsFolder?.childrenList?.map((id) => condensedEntities[id]) ?? [],
);
