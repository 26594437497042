export default function AdditionalTypeIcon(): JSX.Element {
    return (
        <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M13.5617 3.62042L7.06836 0.0492184C6.94877 -0.0164061 6.80125 -0.0164061 6.68164 0.0492184L0.188338 3.62042C0.0716063 3.6847 0 3.80189 0 3.92846V11.0715C0 11.1981 0.0716137 11.3153 0.188338 11.3796L6.68164 14.9508C6.80123 15.0164 6.94876 15.0164 7.06836 14.9508L13.5617 11.3796C13.6784 11.3153 13.75 11.1981 13.75 11.0715V3.92846C13.75 3.8019 13.6784 3.6847 13.5617 3.62042ZM6.87495 0.771104L12.6149 3.92847L6.87495 7.08585L1.13496 3.92847L6.87495 0.771104ZM0.764078 4.55316L6.49325 7.70453V14.0186L0.764078 10.8665V4.55316ZM7.25665 14.0186V7.70453L12.9858 4.55316V10.8665L7.25665 14.0186Z"
                    fill="#333333"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="13.75" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}
