import { useEffect } from 'react';

const useViewportHeight = (): void => {
    useEffect(() => {
        const listener = () => {
            const root = document.documentElement;
            root.style.setProperty('--app-height', window ? `${window.innerHeight}px` : '100vh');
        };
        listener();
        window.addEventListener('resize', listener);
        return () => window.removeEventListener('resize', listener);
    }, []);
};

export default useViewportHeight;
