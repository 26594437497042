import baseOnboardingStyles from './baseOnboarding.module.scss';

const BackButton = ({ onClick }) => {
    return (
        <button onClick={onClick} className={baseOnboardingStyles.backButton}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
                    stroke="black"
                    strokeOpacity="0.85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
            Back
        </button>
    );
};

const ContinueButton = ({ onClick }) => {
    return (
        <button onClick={onClick} className={baseOnboardingStyles.continueButton}>
            Continue
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75"
                    stroke="black"
                    strokeOpacity="0.85"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </button>
    );
};

const StepControlButtons = ({ prevStep, nextStep }) => {
    return (
        <div className={baseOnboardingStyles.stepControlContainer}>
            <BackButton onClick={prevStep} />
            <ContinueButton onClick={nextStep} />
        </div>
    );
};

export default StepControlButtons;
export { BackButton, ContinueButton };
