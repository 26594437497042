export default function ArrowLeftIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8408 2.4873L5.21582 8.1123L10.8408 13.7373"
                stroke="#333333"
                strokeWidth="1.5"
                strokeLinecap="round"
            />
        </svg>
    );
}
