import { gql } from '@apollo/client';

export const new_GET_ADDITIONAL_TYPES = gql`
    query GetAdditionalTypes($ids: [uuid!]) {
        additionalTypes(where: { id: { _in: $ids } }) {
            id
            entity
            owned_by
            created_at
            updated_at
        }
    }
`;

export const new_GET_ALL_ADDITIONAL_TYPES = gql`
    query GetAdditionalTypes {
        EntityIndices(where: { entity_type: { _eq: "AdditionalType" } }) {
            id
            entity
            owned_by
            created_at
            updated_at
        }
    }
`;

export const new_CREATE_ENTITY = gql`
    mutation createEntity($entity: jsonb!, $owner: uuid) {
        insert_Entities_one(object: { entity: $entity, owned_by: $owner }) {
            id
            entity
            owned_by
            dsid
        }
    }
`;

export const GET_ENTITIES_VERSIONS = gql`
    query getEntityVersions($ids: [uuid!]) {
        EntityVersions(where: { entity_id: { _in: $ids } }) {
            id
            entity_id
            created_at
            obsoleted_by
        }
    }
`;

export const new_GET_ENTITY = gql`
    query getEntity($id: uuid!) {
        Entities(where: { id: { _eq: $id } }) {
            id
            entity
            owned_by
            created_at
            updated_at
        }
    }
`;

export const GET_ENTITIES = gql`
    query getEntities($ids: [uuid!]) {
        Entities(where: { id: { _in: $ids } }) {
            id
            entity
            owned_by
            created_at
            updated_at
            deleted
        }
    }
`;

export const RESTORE_ENTITIES_FROM_TRASH = gql`
    mutation restoreSoftDeletedEntities($ids: [uuid!]) {
        update_Entities(where: { id: { _in: $ids } }, _set: { deleted: false }) {
            affected_rows
            returning {
                id
                entity
                dsid
                owned_by
                created_at
                updated_at
                deleted
            }
        }
    }
`;

export const new_SUBSCRIBE_TO_ENTITIES = gql`
    subscription SubToEntity($ids: [uuid!]) {
        Entities(where: { _and: { id: { _in: $ids }, owned_by: { _eq: $owner } } }) {
            id
            entity
            dsid
            owned_by
            created_at
            updated_at
        }
    }
`;

export const SUBSCRIBE_TO_DATASPACE = gql`
    subscription SubToDataspace($dataspaceId: uuid!) {
        last_updated_entities(args: { dsid: $dataspaceId }) {
            id
            entity
            dsid
            owned_by
            created_at
            updated_at
            deleted
            public_entity
        }
    }
`;

// TODO: put limit into a variable
export const new_GET_RECENT_TYPES = gql`
    query GetAdditionalTypes {
        EntityIndices(where: { entity_type: { _eq: "AdditionalType" } }, order_by: { created_at: desc }, limit: 5) {
            id
            entity
            owned_by
            created_at
            updated_at
        }
    }
`;

export const new_GET_VERIFIED_TYPES = gql`
    query GetVerifiedTypes {
        EntityIndices(
            where: { _and: [{ entity_type: { _eq: "AdditionalType" } }, { verified: { _eq: "true" } }] }
            order_by: { created_at: desc }
            limit: 5
        ) {
            id
            entity
            owned_by
            created_at
            updated_at
        }
    }
`;

// System queries used in api/public/update-entities.tsx

export const SYSTEM_ADD_ENTITY = gql`
    mutation AddEntities($entity: jsonb!, $owned_by: uuid!) {
        insert_Entities(objects: [{ entity: $entity, owned_by: $owned_by }]) {
            affected_rows
            returning {
                id
                entity
                obsoleted_by
            }
        }
    }
`;

export const SYSTEM_ADD_ENTITIES = gql`
    type AddObject {
        entity: jsonb!
        owned_by: uuid!
    }

    mutation AddEntities($newEntities: [AddObject!]!) {
        insert_Entities(objects: $newEntities) {
            affected_rows
            returning {
                id
                entity
                obsoleted_by
            }
        }
    }
`;

// This is a nested upsert (i.e. if obsolete_id doesn't exist, it is inserted,
// and then obsoleted with $obj).
// This should be replaced with a nested update when Hasura supports it:
//
// https://github.com/hasura/graphql-engine/pull/3852
//
// Note: For now, any use of this query must verify that obsolete_id already
// exists manually before running this query.
export const SYSTEM_OBSOLETE_ENTITY = gql`
    mutation ObsoleteEntity($obsolete_id: uuid!, $entity: Entities_obj_rel_insert_input!) {
        insert_Entities(
            objects: [{ id: $obsolete_id, entity: {}, entityByObsoletedBy: $entity }]
            on_conflict: { constraint: Entities_pkey, update_columns: [obsoleted_by] }
        ) {
            returning {
                id
                entity
                obsoleted_by
                entityByObsoletedBy {
                    id
                    entity
                    obsoleted_by
                }
            }
        }
    }
`;
export const SYSTEM_OBSOLETE_ENTITIES = gql`
    type ObsoleteObject {
        id: uuid!
        entity: jsonb!
        entityByObsoletedBy: Entities_obj_rel_insert_input!
    }

    mutation ObsoleteEntities($obsoleteObjects: [ObsoleteObject!]!) {
        insert_Entities(
            objects: $obsoleteObjects
            on_conflict: { constraint: Entities_pkey, update_columns: [obsoleted_by] }
        ) {
            returning {
                id
                entity
                obsoleted_by
                entityByObsoletedBy {
                    id
                    entity
                    obsoleted_by
                }
            }
        }
    }
`;
export const SEARCH_ENTITIES = gql`
    query ($search: String!, $dataspaces: _uuid!) {
        search_entities(args: { search: $search, dataspaces: $dataspaces }) {
            id
            dsid
            entity
            owned_by
            updated_at
        }
    }
`;
export const SEARCH_ENTITY_NAMES = gql`
    query ($search: String!, $dataspaces: _uuid!) {
        search_entity_names(args: { search: $search, dataspaces: $dataspaces }, limit: 20) {
            id
            dsid
            entity
        }
    }
`;
export const SEARCH_CONDENSED_ENTITY_NAMES = gql`
    query ($search: String!, $dataspaces: _uuid) {
        search_condensed_entity_names(args: { search: $search, dataspaces: $dataspaces }, limit: 50) {
            id
            dsid
            name
            emoji
            entity_type
            url
            thumbnail_url
            app
            icon_link
            mime_type
            key
        }
    }
`;
export const SEARCH_PEOPLE = gql`
    query ($search: String!, $dataspace: String!) {
        search_people(args: { search: $search, dataspace: $dataspace }) {
            id
            entity
        }
    }
`;
export const SEARCH_ALL_PEOPLE_BY_EMAIL = gql`
    query ($search: String!) {
        search_all_people_by_email(args: { search: $search }) {
            id
            entity
        }
    }
`;
export const SEARCH_ADDITIONAL_TYPES = gql`
    query ($search: String!, $dataspace: String!) {
        search_additional_types(args: { search: $search, dataspace: $dataspace }, limit: 20) {
            id
            entity
        }
    }
`;

export const new_UPDATE_ENTITY = gql`
    mutation updateEntity($entity: jsonb!, $id: uuid!) {
        update_Entities(where: { id: { _eq: $id } }, _set: { entity: $entity }) {
            returning {
                id
                entity
                dsid
                owned_by
                updated_at
                created_at
                deleted
            }
        }
    }
`;

export const GET_LATEST_USERS = gql`
    query GetLatestUsers {
        people(order_by: { created_at: desc }, limit: 100) {
            id
            name
            email
            waitlist
            complete
            created_at
        }
    }
`;

export const GET_ENTITY_ACL = gql`
    query getEntity($id: uuid!) {
        Entities_by_pk(id: $id) {
            acl
            owned_by
        }
    }
`;

export const SET_ENTITY_ACL = `
    mutation setEntityAcl($id: uuid!, $acl: jsonb!, $strictAcl: Boolean!) {
        update_Entities_by_pk(pk_columns: { id: $id }, _set: { acl: $acl, strict_acl: $strictAcl }) {
            acl
            strict_acl
            entity
        }
    }
`;

export const GET_DATASPACE_WITH_SUBSCRIPTION = gql`
    query getDataspace($dsid: uuid!) {
        dataspaces(where: { id: { _eq: $dsid } }) {
            id
            entity
            owned_by
            created_at
            updated_at
            subscription {
                id
                dsid
                stripe_customer_id
                stripe_subscription_id
                stripe_plan_id
                stripe_subscription_status
                created_at
                updated_at
            }
        }
    }
`;

export const SUBSCRIBE_TO_ENTITIES_WITH_READ_ACCESS = gql`
    subscription SubToEntitiesWithReadAccess($entityIds: [uuid!]!) {
        Entities(where: { id: { _in: $entityIds } }) {
            id
        }
    }
`;
