import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showMenu } from '@/redux/ui/actions';
import Button from '../../DesignSystem/Button';
import EllipsisMdIcon from '@/icons/EllipsisMdIcon';
import { getIntegrationItemSelector } from 'redux/integrations/selectors';
import { getMenuSelector } from '@/redux/ui/selectors';
import { getEntityIcon } from '@/utils/helpers';
import typeInfo from '../typeInfo';

interface Props {
    eid: string;
    entity: Entity;
    parentId?: string;
    childIndex?: number;
    name?: string;
    className?: string;
    fullButton?: boolean;
    offsetX?: number;
}

export default function ControlMenu({
    eid,
    entity,
    parentId,
    childIndex,
    className,
    fullButton,
    offsetX = 0.5,
}: Props): React.ReactElement {
    const dispatch = useDispatch();
    const integrationItem = useSelector(getIntegrationItemSelector(eid));
    const menuParams = useSelector(getMenuSelector);
    const [isActive, setActive] = useState(false);

    const dispatchContextMenu = (e) => {
        const el = e.target.getBoundingClientRect();
        const x = el.x - el.width * offsetX;
        const y = el.y + el.height / 1.25;
        const contextMenuEntity = {
            id: eid,
            entity,
            parentId,
            isLocalDirectory: false,
            childIndex,
            isIntegrationItem: Boolean(integrationItem),
        };
        dispatch(
            showMenu({
                type: 'ContextMenu',
                x,
                y,
                eid,
                contextMenuEntity,
            }),
        );
        setActive(true);
    };

    useEffect(() => {
        if (menuParams?.contextMenuEntity?.id !== eid) setActive(false);
    }, [menuParams?.contextMenuEntity?.id]);

    if (fullButton) {
        return (
            <Button
                className={`controlMenuButton ${className}`}
                active={isActive}
                size="s"
                variant="plain"
                onClick={dispatchContextMenu}
            >
                {getEntityIcon(entity)}
                <span>{entity?.name || `Untitled ${typeInfo[entity?.['@type']]?.fancyName}`}</span>
            </Button>
        );
    } else {
        return (
            <Button
                className={`controlMenuButton ${className}`}
                icon={EllipsisMdIcon}
                active={isActive}
                size="m"
                variant="plain"
                onClick={dispatchContextMenu}
            />
        );
    }
}
