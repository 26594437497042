import { ElectronRequestProps } from 'integrations/types';
import { uniq } from 'lodash';

export function dropFilename(fullPath) {
    const segments = fullPath.split('/');
    segments.pop();
    return segments.join('/');
}

export function getLocalDirectoryTree(paths: string[]) {
    return requestElectron({
        channelName: window?.dirEventsChannelName,
        action: 'GET_TREE',
        responseEvent: 'GET_TREE_RESPONSE',
        options: { paths: uniq(paths) },
    });
}

export function getLocalFileMetadata(path: string) {
    return requestElectron({
        channelName: window?.dirEventsChannelName,
        action: 'GET_FILE_METADATA',
        responseEvent: 'GET_FILE_METADATA_RESPONSE',
        options: { path },
    });
}

export function initElectronLocalFilesWatcher() {
    return requestElectron({
        channelName: window?.dirEventsChannelName,
        action: 'INIT_WATCHER',
        responseEvent: 'INIT_WATCHER_RESPONSE',
        options: { path: window?.homedir },
        timeoutLimitMS: null,
    });
}

export function stopElectronLocalFilesWatcher(path: string) {
    return requestElectron({
        channelName: window?.dirEventsChannelName,
        action: 'KILL_WATCHER',
        responseEvent: 'KILL_WATCHER_RESPONSE',
        options: { path },
    });
}

export function openLocalFile(path: string) {
    return requestElectron({
        channelName: window?.dirEventsChannelName,
        action: 'OPEN_FILE',
        responseEvent: 'OPEN_FILE_RESPONSE',
        options: { path },
    });
}

export async function requestElectron({
    channelName,
    action,
    responseEvent,
    options,
}: ElectronRequestProps): Promise<ServerResponseWithItemsData> {
    return new Promise(async (resolve, reject) => {
        const listener = (event, data) => {
            if (data?.type === responseEvent) {
                window.removeElectronListener(channelName, listener);
                resolve(data);
            }
        };

        try {
            window?.addElectronListener(channelName, listener);
            window?.sendToElectron(channelName, {
                action: action,
                options: options,
            });
        } catch (error) {
            // Handle any other generic errors
            window?.removeElectronListener(channelName, listener);
            reject(error);
        }
    });
}
