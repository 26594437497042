import styles from './styles.module.scss';
import { convertTimestampToLocaleString, getItemName } from '@/utils/helpers';
import DataspaceIcon from '@/components/DataspaceIcon';
import SharedIcon from '@/icons/SharedIcon';
import DraftSmIcon from '@/icons/DraftSmIcon';

type Props = {
    entity?: Entity;
    dataspace?: Entity;
    includeDataspace?: boolean;
    onClick?: (e) => void;
    isDraft?: boolean;
    isShared?: boolean;
};

const DetailedHlistItemView = ({
    entity,
    dataspace,
    includeDataspace,
    onClick,
    isShared,
    isDraft,
}: Props): JSX.Element => {
    const itemName = getItemName(entity);
    const createdAtString = convertTimestampToLocaleString(entity?.metadata?.updated_at) || '';
    const previewContent = entity?.content?.replace(/<[^>]+>/g, ' ')?.slice(0, 50);

    const getDataspaceName = (): string => {
        if (isDraft) {
            return 'Draft';
        }
        if (isShared) {
            return 'Shared';
        }

        return dataspace?.name;
    };

    const getDataspaceIcon = (): JSX.Element => {
        if (isDraft) {
            return <DraftSmIcon />;
        }
        if (isShared) {
            return <SharedIcon />;
        }

        return <DataspaceIcon dataspace={dataspace} classname={styles.dsIcon} />;
    };

    return (
        <div className={styles.container} onClick={onClick}>
            <span className={styles.entityName}>{itemName}</span>
            <span className={styles.entityInfo}>
                <span className={styles.date}>{createdAtString}</span>
                <span className={styles.previewContent}>{previewContent}</span>
            </span>
            {includeDataspace && (
                <span className={styles.dataspace}>
                    {getDataspaceIcon()}
                    <span className={styles.dsName}>{getDataspaceName()}</span>
                </span>
            )}
        </div>
    );
};

export default DetailedHlistItemView;
