export default function ListIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.75 2.11a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 0-1.5h-10ZM4 7.86c0-.41.33-.75.75-.75h10a.75.75 0 0 1 0 1.5h-10A.75.75 0 0 1 4 7.86Zm0 5c0-.41.33-.75.75-.75h10a.75.75 0 0 1 0 1.5h-10a.75.75 0 0 1-.75-.75ZM3.5 2.86a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm0 5a1.25 1.25 0 1 1-2.5 0 1.25 1.25 0 0 1 2.5 0Zm-1.25 6.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5Z"
                fill="#333"
            />
        </svg>
    );
}
