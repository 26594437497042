import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideGlobalModal } from '../../redux/ui/actions';
import { getGlobalModalsSelector } from '../../redux/ui/selectors';
import ShareEntityModal from './ShareEntityModal/ShareEntityModal';
import WelcomeModal from './WelcomeModal';
import UploadProgressModal from './UploadProgressModal';
import globalModalStyles from './globalModal.module.scss';
import SettingsModal from './SettingsModal';
import UpgradePlanModal from './SettingsModal/upgradePlanModal';
import ImageModal from './ImageModal';
import SendFeedbackModal from './SendFeedbackModal/SendFeedbackModal';
import AboutAppModal from './AboutAppModal/AboutAppModal';
import WelcomeVideoModal from './WelcomeVideoModal/WelcomeVideoModal';
import NoAccessMessageModal from './NoAccessMessageModal';
import SignInMessageModal from './SignInMessageModal';
import ConfirmHardRemoveModal from './ConfirmHardRemoveModal';
import ConfirmRevokeIntegrationModal from './ConfirmRevokeIntegrationModal/ConfirmRevokeIntegrationModal';
import GlobalSearchOmnibox from '../Omnibox/GlobalSearchOmnibox';
import clsx from 'clsx';

// HOW-TO
// Redux actions: SHOW_GLOBAL_MODAL, HIDE_GLOBAL_MODAL
//
// Adding a new modal
//
// import new component here
// add to GLOBAL_MODAL_COMPONENTS
//
//
// Show modal
//
// Do: dispatch(showGlobalModal(<modalName>, <modalProps?>, <GlobalModalParams?>));
// Each new modal will be stacked on top of the rest
//
// Example: dispatch(showGlobalModal('UploadProgressModal', { files: acceptedFiles }));
// Example: dispatch(showGlobalModal('WelcomeModal', {}, { canClose: false, backdrop: 'blur' }));
//
//
// Hiding modal
//
// Hide all modals: dispatch(hideGlobalModal())
// Hide one modal: dispatch(hideGlobalModal(<modalName>))

const GLOBAL_MODAL_COMPONENTS = {
    UploadProgressModal,
    WelcomeModal,
    SettingsModal,
    UpgradePlanModal,
    ImageModal,
    SendFeedbackModal,
    AboutAppModal,
    WelcomeVideoModal,
    NoAccessMessageModal,
    SignInMessageModal,
    ConfirmHardRemoveModal,
    ShareEntityModal,
    ConfirmRevokeIntegrationModal,
    GlobalSearchOmnibox,
};

export type GlobalModalTypes = keyof typeof GLOBAL_MODAL_COMPONENTS;
export type GlobalModalParams = {
    backdrop: 'darken' | 'blur' | 'none';
    canClose: boolean;
    fade?: boolean;
    hideModalCallback?: () => void;
    transparentModal?: boolean;
    top30?: boolean;
    maxWidth?: string;
    fullWidth?: boolean;
};
export const GLOBAL_MODAL_DEFAULT_PARAMS = {
    backdrop: 'darken',
    canClose: true,
};

const ModalComponent: React.FC<{ modal; params }> = ({ modal, params }) => {
    const dispatch = useDispatch();
    const stopPropagation = (e): void => e?.stopPropagation();
    const [hiding, setHiding] = useState(false);

    const hide = (e): void => {
        if (e?.stopPropagation) e.stopPropagation();
        setHiding(true);
        setTimeout(() => {
            dispatch(hideGlobalModal(modal.type));
            if (params?.hideModalCallback) {
                params.hideModalCallback();
            }
        }, 400);
    };

    useEffect(() => {
        const handleEsc = (e): void => {
            if (e.key === 'Escape') {
                hide(e);
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const ContentComponent = GLOBAL_MODAL_COMPONENTS[modal.type];
    return (
        <div
            className={`${globalModalStyles.backdrop} ${globalModalStyles[params.backdrop]} ${
                params?.fade && !hiding ? globalModalStyles.fade : ''
            } ${hiding ? globalModalStyles.hide : ''}`}
            onClick={params?.canClose ? hide : null}
        >
            <div
                className={clsx(
                    globalModalStyles.modal,
                    params?.fade && globalModalStyles.fade,
                    params?.transparentModal && globalModalStyles.transparentModal,
                    params?.top30 && globalModalStyles.top30,
                    params?.fullWidth && globalModalStyles.fullWidth,
                )}
                onClick={stopPropagation}
                style={{ maxWidth: params?.maxWidth }}
                key={modal.type}
            >
                <ContentComponent {...modal.props} hideModal={hide} />
            </div>
        </div>
    );
};

const GlobalModal: React.FC = () => {
    const globalModals = useSelector(getGlobalModalsSelector);
    const modals = globalModals?.modals;
    const params = globalModals?.params;

    if (!Boolean(modals?.length)) return <span />;

    return (
        <div className={globalModalStyles.globalContainer}>
            {modals.map((modal, idx) => (
                <ModalComponent key={'global-modal-' + modal.type + `${idx}`} modal={modal} params={params} />
            ))}
        </div>
    );
};

export default GlobalModal;
