export default function PDFIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_1505_5187"
                style={{ maskType: 'alpha' }}
                maskUnits="userSpaceOnUse"
                x="1"
                y="1"
                width="14"
                height="14"
            >
                <path
                    d="M13.1636 15H2.79999C2.2477 15 1.79999 14.5523 1.79999 14V2C1.79999 1.44772 2.2477 1 2.79999 1H9.52727L14.1636 5.63636V14C14.1636 14.5523 13.7159 15 13.1636 15Z"
                    fill="url(#paint0_linear_1505_5187)"
                />
            </mask>
            <g mask="url(#mask0_1505_5187)">
                <path
                    d="M13.1636 15H2.79999C2.2477 15 1.79999 14.5523 1.79999 14V2C1.79999 1.44772 2.2477 1 2.79999 1H9.52727L14.1636 5.63636V14C14.1636 14.5523 13.7159 15 13.1636 15Z"
                    fill="url(#paint1_linear_1505_5187)"
                />
                <rect width="9.26549" height="4.53012" transform="translate(3.36725 8.73486)" fill="#ED5E6D" />
                <path
                    d="M4.63201 11.4672V11.0534H5.28076C5.43272 11.0534 5.55078 11.0148 5.63494 10.9377C5.72027 10.8594 5.76293 10.7483 5.76293 10.6045V10.601C5.76293 10.4573 5.72027 10.3468 5.63494 10.2696C5.55078 10.1925 5.43272 10.1539 5.28076 10.1539H4.63201V9.73486H5.41051C5.58818 9.73486 5.74365 9.7711 5.8769 9.84357C6.01016 9.91488 6.11361 10.0154 6.18725 10.1452C6.26206 10.2749 6.29947 10.4263 6.29947 10.5993V10.6028C6.29947 10.777 6.26206 10.9289 6.18725 11.0587C6.11361 11.1884 6.01016 11.2889 5.8769 11.3602C5.74365 11.4315 5.58818 11.4672 5.41051 11.4672H4.63201ZM4.36725 12.265V9.73486H4.89677V12.265H4.36725Z"
                    fill="white"
                />
                <path
                    d="M7.03827 12.265V9.73486H8.03419C8.2855 9.73486 8.49941 9.78396 8.67592 9.88215C8.85243 9.97917 8.98744 10.1206 9.08095 10.3065C9.17446 10.4923 9.22122 10.7173 9.22122 10.9815V10.985C9.22122 11.2574 9.17446 11.4888 9.08095 11.6794C8.98861 11.8699 8.85418 12.0154 8.67767 12.1159C8.50117 12.2153 8.28667 12.265 8.03419 12.265H7.03827ZM7.56779 11.8284H7.95002C8.10666 11.8284 8.23933 11.7962 8.34804 11.732C8.45675 11.6677 8.53916 11.5736 8.59527 11.4497C8.65254 11.3246 8.68118 11.1726 8.68118 10.9938V10.9903C8.68118 10.8161 8.65196 10.6682 8.59351 10.5467C8.53624 10.4239 8.45266 10.331 8.34278 10.2679C8.23407 10.2036 8.10315 10.1715 7.95002 10.1715H7.56779V11.8284Z"
                    fill="white"
                />
                <path
                    d="M9.9758 12.265V9.73486H11.6327V10.1715H10.5053V10.85H11.5346V11.2691H10.5053V12.265H9.9758Z"
                    fill="white"
                />
                <g filter="url(#filter0_d_1505_5187)">
                    <path
                        d="M14.1637 5.63636H10.5273C9.975 5.63636 9.52728 5.18865 9.52728 4.63636V1L14.1637 5.63636Z"
                        fill="url(#paint2_linear_1505_5187)"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_1505_5187"
                    x="8.48183"
                    y="-0.0909095"
                    width="6.90908"
                    height="6.90896"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dx="0.0909091" dy="0.0454546" />
                    <feGaussianBlur stdDeviation="0.568182" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.33 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1505_5187" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1505_5187" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_1505_5187"
                    x1="7.98181"
                    y1="1"
                    x2="7.98181"
                    y2="15"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FF7979" />
                    <stop offset="1" stopColor="#E85555" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1505_5187"
                    x1="7.98181"
                    y1="1"
                    x2="7.98181"
                    y2="15"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#EDEBEB" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_1505_5187"
                    x1="11.8455"
                    y1="1"
                    x2="11.8455"
                    y2="5.63636"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset="1" stopColor="#E0E0E0" />
                </linearGradient>
            </defs>
        </svg>
    );
}
