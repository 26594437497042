import React, { useEffect } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { OverlayChildren } from 'react-bootstrap/esm/Overlay';
import { useDispatch, useSelector } from 'react-redux';
import { setUI, closeModal, setPanelOpen } from '../../redux/ui/actions';
import { getModalSelector, getOpenPanelSelector } from '../../redux/ui/selectors';
import Button from '../DesignSystem/Button';

type ModalProps = {
    text: string;
    children: Array<React.ReactChild> | React.ReactChild;
    icon?: () => JSX.Element;
    className?: string;
    tooltip?: OverlayChildren;
    forceOpen?: boolean;
    variant?: 'primary' | 'secondary' | 'tertiary' | 'plain' | 'secondaryBlue';
    onClose?: () => any;
    onClickSideEffect?: () => any;
    dontCloseOnBackdrop?: boolean;
};

export const usePopup = (): { open: (name: string) => void; close?: () => void } => {
    const dispatch = useDispatch();

    let open = function (name: string): void {
        if (name) {
            dispatch(setUI({ modal: name }));
        }
    };

    const close = () => dispatch(setUI({ modal: '' }));

    open = open.bind(this);
    return { open, close };
};

export const PopupWrapper: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
    const { text, className, onClose } = props;
    const modalType = text.toLowerCase().split(' ').join('-');

    const dispatch = useDispatch();
    const modal = useSelector(getModalSelector);

    const onModalClose = () => {
        dispatch(closeModal());

        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            {modalType == modal ? (
                <div className="backdrop" onClick={onModalClose}>
                    <div
                        className={className ? className : 'modal-old'}
                        onClick={function (e): void {
                            e.stopPropagation();
                        }}
                    >
                        {props.children}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export const PopupWithButton: React.FunctionComponent<ModalProps> = ({
    text,
    icon: Icon,
    tooltip,
    forceOpen,
    children,
    className,
    variant,
    onClickSideEffect,
    onClose,
    dontCloseOnBackdrop,
}: ModalProps) => {
    const modalType = text.toLowerCase().split(' ').join('-');

    const dispatch = useDispatch();
    const modal = useSelector(getModalSelector);

    let open = function (): void {
        dispatch(setUI({ modal: modalType }));
        if (onClickSideEffect) {
            onClickSideEffect();
        }
    };

    open = open.bind(this);

    useEffect(() => {
        if (forceOpen) open();
    }, [forceOpen]);

    const onModalClose = () => {
        if (dontCloseOnBackdrop) return;

        dispatch(closeModal());
        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            {tooltip ? (
                <OverlayTrigger placement="auto" overlay={tooltip}>
                    <Button
                        onClick={open}
                        size="s"
                        variant={variant || 'primary'}
                        icon={Icon}
                        className={className || ''}
                    >
                        {text}
                    </Button>
                </OverlayTrigger>
            ) : (
                <Button onClick={open} size="s" variant={variant || 'primary'} icon={Icon} className={className || ''}>
                    {text}
                </Button>
            )}
            {modalType == modal ? (
                <div className="backdrop" onClick={onModalClose}>
                    <div
                        className="modal-old"
                        onClick={function (e): void {
                            e.stopPropagation();
                        }}
                    >
                        {children}
                    </div>
                </div>
            ) : (
                ''
            )}
        </>
    );
};

export const FloatingSidebarWithButton: React.FunctionComponent<ModalProps> = (props: ModalProps) => {
    const { text } = props;

    const modalType = text.toLowerCase().split(' ').join('-');

    const dispatch = useDispatch();
    const modal = useSelector(getModalSelector);

    let open = function (): void {
        dispatch(setUI({ modal: modalType }));
    };

    open = open.bind(this);

    return (
        <>
            <button onClick={open}>{text}</button>
            {modalType == modal ? (
                <div className="side-popup">
                    <button className="x-button" onClick={() => dispatch(closeModal())}>
                        𝗫
                    </button>
                    {props.children}
                </div>
            ) : (
                ''
            )}
        </>
    );
};

// import this wherever you want to open the Panel from
export const usePanel = (name: string): { open: () => void; close: () => void } => {
    const dispatch = useDispatch();
    let open = (): void => {
        if (name) {
            dispatch(setPanelOpen(name, true));
        }
    };

    const close = (): void => {
        if (name) {
            dispatch(setPanelOpen(name, false));
        }
    };

    open = open.bind(this);
    return { open, close };
};

export const PanelWrapper = (props) => {
    const { panelName } = props;
    const panelOpen = useSelector(getOpenPanelSelector(panelName));
    return <>{panelOpen ? <>{props.children}</> : ''}</>;
};
