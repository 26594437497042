export default function CopyEntityIcon(): JSX.Element {
    return (
        <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.5667 2.59406L15.1917 2.59406V2.59406H14.5667ZM13.6333 1.06732H2.43333V2.31732H13.6333V1.06732ZM2.43333 12.5139H7.56667V11.2639H2.43333V12.5139ZM15.1917 3.94666L15.1917 2.59406L13.9417 2.59406L13.9417 3.94666L15.1917 3.94666ZM13.6333 2.31732C13.8241 2.31732 13.9417 2.46134 13.9417 2.59406H15.1917C15.1917 1.73075 14.4735 1.06732 13.6333 1.06732V2.31732ZM0.875 10.9871C0.875 11.8504 1.59315 12.5139 2.43333 12.5139V11.2639C2.24258 11.2639 2.125 11.1198 2.125 10.9871H0.875ZM2.125 2.59406C2.125 2.46134 2.24258 2.31732 2.43333 2.31732V1.06732C1.59316 1.06732 0.875 1.73075 0.875 2.59406H2.125ZM9.9 14.3173H14.5667V13.0673H9.9V14.3173ZM16.125 12.7906V6.20099H14.875V12.7906H16.125ZM14.5667 4.67426H9.9V5.92426H14.5667V4.67426ZM16.125 6.20099C16.125 5.33769 15.4068 4.67426 14.5667 4.67426V5.92426C14.7574 5.92426 14.875 6.06827 14.875 6.20099H16.125ZM14.5667 14.3173C15.4068 14.3173 16.125 13.6539 16.125 12.7906H14.875C14.875 12.9233 14.7574 13.0673 14.5667 13.0673V14.3173ZM8.34167 12.7906C8.34167 13.6539 9.05982 14.3173 9.9 14.3173V13.0673C9.70925 13.0673 9.59167 12.9233 9.59167 12.7906H8.34167ZM9.59167 6.20099C9.59167 6.06827 9.70925 5.92426 9.9 5.92426V4.67426C9.05982 4.67426 8.34167 5.33769 8.34167 6.20099H9.59167ZM0.875 2.59406V10.9871H2.125V2.59406H0.875ZM8.34167 6.20099V12.7906H9.59167V6.20099H8.34167Z"
                fill="#4D4D4D"
            />
        </svg>
    );
}
