import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { PanelWrapper, usePanel } from '../wrappers/modal';
import 'tippy.js/dist/tippy.css';

const FlexiblePanel = dynamic(() => import('./FlexiblePanel'), { ssr: false });
import { getEntityPanelId, getOpenPanelSelector } from '../../redux/ui/selectors';
import EntityContainer from '../entities/EntityContainer';
import SaveToDataspaceButton from '../entities/subcomponents/SaveToDataspaceButton';
import { useEntity } from '../../hooks/reduxHooks';
import Button from '../DesignSystem/Button';
import ExpandMdIcon from '@/icons/ExpandMdIcon';
import CloseMdIcon from '@/icons/CloseMdIcon';
import { BASE_ORIGIN } from '@/config';
import { isDraftSelector } from '@/redux/entities/selectors';
import Tooltip from 'components/Tooltip';

const PanelEntity = () => {
    const eid = useSelector(getEntityPanelId);
    const panelName = 'entity-panel';
    const panelOpen = useSelector(getOpenPanelSelector(panelName));
    const entity = useEntity(eid, true, 'PanelEntity');
    const dataspaceEntity = useEntity(entity?.metadata?.dsid, true, 'PanelEntity2');
    const isDraft = useSelector(isDraftSelector(eid));

    const { close } = usePanel(panelName);

    const handleNewTab = function (): void {
        if (window) {
            window.open(`${BASE_ORIGIN}/entity/${eid}`);
        }
    };

    // TODO: Probably add a back button and make this a stack, for nested links
    return (
        <>
            {eid ? (
                <PanelWrapper panelName={panelName}>
                    <FlexiblePanel
                        initWidth={400}
                        initHeight={500}
                        top={50}
                        right={50}
                        disableKeyboardMove={true}
                        className={'my-panel-custom-class'}
                        onRequestClose={close}
                        isOpen={panelOpen}
                    >
                        <div className="panel">
                            <div className={'panelHeader'}>
                                <div className={'panelTitle'}>
                                    <h6>{entity?.name}</h6>
                                    <span className="secondary">{dataspaceEntity?.name}</span>
                                </div>
                                <div className="panelButtons">
                                    {isDraft && <SaveToDataspaceButton eid={eid} />}
                                    <Tooltip
                                        key={'full-page'}
                                        placement="bottom"
                                        appendTo={() => document.body}
                                        delay={100}
                                        content={
                                            <div className={'tooltipTippy tooltipTippyCol'} id={'tooltip-full-page'}>
                                                Open in full page
                                                {/* <span className={'tooltipSubtext'}>
                                                    <kbd>⌘</kbd>
                                                    <kbd>ENTER</kbd>
                                                </span> */}
                                            </div>
                                        }
                                    >
                                        <div>
                                            <Button
                                                size="s"
                                                variant="plain"
                                                icon={ExpandMdIcon}
                                                onClick={handleNewTab}
                                            />
                                        </div>
                                    </Tooltip>
                                    <Button size="s" variant="plain" icon={CloseMdIcon} onClick={close} />
                                </div>
                            </div>
                            <div className={'panelContent'}>
                                <EntityContainer eid={eid} infoMode="panel" />
                            </div>
                        </div>
                    </FlexiblePanel>
                </PanelWrapper>
            ) : (
                <></>
            )}
        </>
    );
};

export default PanelEntity;
