import React, { useState, useEffect, useCallback, MutableRefObject, useRef, useMemo } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { GET_ENTITIES, new_CREATE_ENTITY } from '../queries';
import {
    addEidsToChildrenListAndClone,
    buildCondensedEntityListFromUploads,
    cloneEntity,
    getEntriesAsync,
    isElectron,
    parsePdfs,
    pushEidToEntity,
    removeDuplicates,
    uploadFiles,
} from '../utils/helpers';

import { useDispatch, useSelector } from 'react-redux';
import {
    setFileUploadSuccess,
    setFileUploadFail,
    setFinder,
    toggleExpandedHListItems,
    setUI,
    showUploadFilesToast,
    setCopiedEntities,
    toast,
    showMenu,
    setUploadingEntities,
    setUploadingEntityProgress,
    clearUploadingEntities,
} from '../redux/ui/actions';
import {
    getCopiedEntitiesSelector,
    getFinderSelector,
    getHlistCollapsedStateSelector,
    getIsHlistCanBeToggledSelector,
    getQuillEditorRefsSelector,
    getRenamingInstanceSelector,
    getSelectedEntitiesSelector,
} from '../redux/ui/selectors';
import { getUserSelector } from '../redux/user/selectors';
import {
    bulkCreateEntities,
    bulkGroupEntities,
    bulkRemoveChildEids,
    presetCondensedEntities,
    updateEntity,
} from '../redux/entities/actions';
import ReactQuill, { Quill } from 'react-quill';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDrop } from 'react-dnd';
import reactDnDStyles from '../styles/react-dnd.module.scss';
import typeInfo from '../components/entities/typeInfo';
import genericEntitySkeleton from '../components/entities/entitySkeleton';
import { cloneDeep, isEqual, throttle } from 'lodash';
import { BASE_ORIGIN } from '@/config';
import { useEntities } from '@/hooks/reduxHooks';
import { getCondensedEntitySelector, getDsidSelector, getFullEntitySelector } from '@/redux/entities/selectors';
import { SHARED_DATASPACE_ID } from '@/redux/constants';
import { MessageType } from 'types/toasts';

export const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};

export const useMemoCompare = (next: any) => {
    const previousRef = useRef();
    const previous = previousRef.current;

    const equal = isEqual(previous, next);
    useEffect(() => {
        if (!equal) {
            previousRef.current = next;
        }
    });
    return equal ? previous : next;
};

// no need for "safe edit" (it used to take care of obsoleting entities)
// TODO: remove?
export const useSafeEdit = function (): (oldEid: string, newEntity: Entity) => Promise<any> {
    const dispatch = useDispatch();

    const safeEdit = async function (eid: string, newEntity: Entity): Promise<any> {
        newEntity.childrenList = removeDuplicates(newEntity.childrenList);
        return dispatch(updateEntity(eid, newEntity, 'remoteHooks'));
    };

    return safeEdit;
};

// If ever reintroduced, remove new_CREATE_ENTITY, as it doesn't use permissions
export const useBlankEntity = function (): (
    type: string,
    withProps?: object,
) => Promise<{ id: string; entity: Entity }> {
    const user = useSelector(getUserSelector);
    // new_CREATE_ENTITY returns data.insert_Entities_one
    const [addEntity] = useMutation(new_CREATE_ENTITY);
    const dispatch = useDispatch();

    const uploadEntity = async function (type: string, withProps?: any): Promise<{ id: string; entity: Entity }> {
        const finalEntity = Object.assign(genericEntitySkeleton(), typeInfo[type]?.skeleton, withProps || {}) as Entity;

        const addData = await addEntity({ variables: { entity: finalEntity, owner: user.id } });
        dispatch(setFinder({ recentlyAdded: addData.data.insert_Entities_one.id }));
        return addData.data.insert_Entities_one;
    };

    const uploadWithAppend = async function (
        type: string,
        appendType: string,
        withProps?: any,
    ): Promise<{ id: string; entity: Entity }> {
        return await uploadEntity(appendType).then((res) => {
            let finalEntity = Object.assign(
                genericEntitySkeleton(),
                typeInfo[type]?.skeleton,
                withProps || {},
            ) as Entity;
            finalEntity = pushEidToEntity(finalEntity, res.id);
            return addEntity({ variables: { entity: finalEntity, owner: user.id } }).then((res) => {
                return res.data.insert_Entities_one;
            });
        });
    };

    const addBlankEntity = async function (
        type: string,
        withProps?: string[],
    ): Promise<{ id: string; entity: Entity }> {
        let action;
        if (type === 'Page') {
            action = uploadWithAppend(type, 'RichText', withProps);
        } else if (type === 'PageAndBlob') {
            action = uploadWithAppend('Page', 'Blob', withProps);
        } else {
            action = uploadEntity(type, withProps);
        }
        return await action;
    };

    return addBlankEntity;
};

interface MousePos {
    x: number;
    y: number;
    flush?: () => void;
}
export const useMousePosition = (throttleTime = 0): MousePos => {
    const [mousePosition, setMousePosition] = useState<MousePos>({ x: 0, y: 0 });
    const updateMousePosition = (ev: MouseEvent): void => {
        setMousePosition({ x: ev.clientX, y: ev.clientY });
    };
    const [update, flush] = useMemo(() => {
        if (throttleTime) {
            const throttledUpdateMousePosition = throttle(updateMousePosition, throttleTime);
            return [throttledUpdateMousePosition, throttledUpdateMousePosition.flush];
        }
        return [updateMousePosition];
    }, [throttleTime]);

    useEffect(() => {
        window.addEventListener('mousemove', update);

        return () => window.removeEventListener('mousemove', update);
    }, [throttleTime]);

    return { ...mousePosition, flush };
};

interface QuillRefHelpers {
    addQuillRefToState: (eid: string, ref: MutableRefObject<ReactQuill>) => void;
    removeQuillRefFromState: (eid: string) => void;
    getQuillRefs: () => { [key: string]: MutableRefObject<ReactQuill> };
    focusQuillByRefKey: (eid: string) => Quill;
    isEmpty: (eid: string) => boolean;
}
/**
 * Returns three functions:
 * @function `addQuillRefToState(eid, ref)` adds quill eid, and its ref to context
 * @function `removeQuillRefFromState(eid)`
 * @function `getQuillRefs()` returns object with quill refs that are rendered
 * @function `focusQuillByRefKey(eid)` looks for quill in quillEditorRefs, and focuses on it
 * @function `isEmpty(eid)` true/false -- empty/not empty; undefined -- not found/error;
 */
export const useQuillControls = (): QuillRefHelpers => {
    const quillEditorRefs = useSelector(getQuillEditorRefsSelector);
    const dispatch = useDispatch();

    const addQuillRefToState = useCallback(
        (eid, ref): void => {
            dispatch(setUI({ quillEditorRefs: { [eid]: ref } }));
        },
        [quillEditorRefs],
    );
    const removeQuillRefFromState = useCallback(
        (eid): void => {
            const tempQuillRefs = { ...quillEditorRefs };
            delete tempQuillRefs[eid];

            dispatch(setUI({ quillEditorRefs: tempQuillRefs }));
        },
        [quillEditorRefs],
    );
    const getQuillRefs = useCallback(() => quillEditorRefs, [quillEditorRefs]);
    const isLoaded = useCallback(
        (ref: string) => quillEditorRefs[ref] && quillEditorRefs[ref].current,
        [quillEditorRefs],
    );

    const isEmpty = useCallback(
        (eid: string) => {
            if (isLoaded(eid)) {
                // quill found and is rendered
                const newLineCharCode = 10;
                const quill = quillEditorRefs[eid].current.getEditor();
                try {
                    return (
                        quill.getLength().valueOf() === 0 ||
                        (quill.getLength().valueOf() === 1 && quill.getText().charCodeAt(0) === newLineCharCode)
                    );
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [quillEditorRefs, isLoaded],
    );

    const focusQuillByRefKey = useCallback(
        (eid: string) => {
            if (isLoaded(eid)) {
                const quill = quillEditorRefs[eid].current.getEditor();
                try {
                    quill.setSelection(quill.getLength().valueOf(), 0);
                    return quill;
                } catch (error) {
                    console.error(error);
                    return error;
                }
            }
        },
        [quillEditorRefs, isLoaded],
    );

    return {
        addQuillRefToState,
        removeQuillRefFromState,
        getQuillRefs,
        focusQuillByRefKey,
        isEmpty,
    };
};

// To be reintroduced when we fix Spreadsheet Focusing
// ---
// interface SpreadsheetRefHelpers {
//     addSpreadsheetRefToState: (eid: string, ref: MutableRefObject<ReactSpreadsheet>) => void;
//     removeSpreadsheetRefFromState: (eid: string) => void;
//     getSpreadsheetRefs: () => { [key: string]: MutableRefObject<ReactSpreadsheet> };
//     focusSpreadsheetByRefKey: (eid: string) => Spreadsheet;
//     isEmpty: (eid: string) => boolean;
// }
// /**
//  * Returns three functions:
//  * @function `addSpreadsheetRefToState(eid, ref)` adds spreadsheet eid, and its ref to context
//  * @function `removeSpreadsheetRefFromState(eid)`
//  * @function `getSpreadsheetRefs()` returns object with spreadsheet refs that are rendered
//  * @function `focusSpreadsheetByRefKey(eid)` looks for spreadsheet in spreadsheetEditorRefs, and focuses on it
//  * @function `isEmpty(eid)` true/false -- empty/not empty; undefined -- not found/error;
//  */
// export const useSpreadsheetControls = (): SpreadsheetRefHelpers => {
//     const spreadsheetEditorRefs = useSelector(getSpreadsheetEditorRefsSelector);
//     const dispatch = useDispatch();

//     const addSpreadsheetRefToState = useCallback(
//         (eid, ref): void => {
//             dispatch(setUI({ spreadsheetEditorRefs: { [eid]: ref } }));
//         },
//         [spreadsheetEditorRefs],
//     );
//     const removeSpreadsheetRefFromState = useCallback(
//         (eid): void => {
//             const tempSpreadsheetRefs = { ...spreadsheetEditorRefs };
//             delete tempSpreadsheetRefs[eid];

//             dispatch(setUI({ spreadsheetEditorRefs: tempSpreadsheetRefs }));
//         },
//         [spreadsheetEditorRefs],
//     );
//     const getSpreadsheetRefs = useCallback(() => spreadsheetEditorRefs, [spreadsheetEditorRefs]);
//     const isLoaded = useCallback((ref: string) => spreadsheetEditorRefs[ref] && spreadsheetEditorRefs[ref].current, [
//         spreadsheetEditorRefs,
//     ]);

//     const isEmpty = useCallback(
//         (eid: string) => {
//             if (isLoaded(eid)) {
//                 // spreadsheet found and is rendered
//                 const newLineCharCode = 10;
//                 const spreadsheet = spreadsheetEditorRefs[eid].current.getEditor();
//                 try {
//                     return (
//                         spreadsheet.getLength().valueOf() === 0 ||
//                         (spreadsheet.getLength().valueOf() === 1 && spreadsheet.getText().charCodeAt(0) === newLineCharCode)
//                     );
//                 } catch (error) {
//                     console.error(error);
//                 }
//             }
//         },
//         [spreadsheetEditorRefs, isLoaded],
//     );

//     const focusSpreadsheetByRefKey = useCallback(
//         (eid: string) => {
//             if (isLoaded(eid)) {
//                 const spreadsheet = spreadsheetEditorRefs[eid].current.getEditor();
//                 try {
//                     spreadsheet.setSelection(spreadsheet.getLength().valueOf(), 0);
//                     return spreadsheet;
//                 } catch (error) {
//                     console.error(error);
//                     return error;
//                 }
//             }
//         },
//         [spreadsheetEditorRefs, isLoaded],
//     );

//     return {
//         addSpreadsheetRefToState,
//         removeSpreadsheetRefFromState,
//         getSpreadsheetRefs,
//         focusSpreadsheetByRefKey,
//         isEmpty,
//     };
// };

// ops: {eid: string, function: (entityByLatest) => Entity}[]

type OpsArray = {
    eid: string;
    function: (entityByLatest) => Entity;
}[];

export const useMassEdit = function (validEid): { massEdit: (ops: OpsArray) => Promise<Promise<object>[]> } {
    const { refetch: getEntities } = useQuery(GET_ENTITIES, { variables: { ids: [validEid] } });
    const safeEdit = useSafeEdit();

    let massEdit = async function (ops) {
        const eids = ops.map((op) => op.eid);
        return getEntities({ ids: eids }).then((res) => {
            const hash = {};
            const resultPromise = [];
            res.data.Entities.forEach((entityRecord) => {
                hash[entityRecord.id] = entityRecord;
            });
            ops.forEach((item) => {
                const newEntity = item.function(hash[item.eid]) as Entity;
                resultPromise.push(safeEdit(hash[item.eid].id, newEntity));
            });
            return resultPromise;
        });
    };
    massEdit = massEdit.bind(this);
    return { massEdit };
};

// part of the multiple h.list item DnD feature
export const useRemoveChildrenFromLists = function (
    validEid,
): (references: EntityIdentity[]) => Promise<Promise<object>[]> {
    const { massEdit } = useMassEdit(validEid);
    const createRemoveFromParent = function (childIds): (entityByLatest) => Entity {
        let removeFromParent = function (entityByLatest): Entity {
            const newEntity = cloneEntity(entityByLatest.entity);

            newEntity.childrenList = newEntity.childrenList.filter((value) => {
                return !childIds.includes(value);
            });
            if (newEntity.contentList) {
                newEntity.contentList = newEntity.contentList.filter((value) => {
                    return !childIds.includes(value);
                });
            }
            return newEntity;
        };
        removeFromParent = removeFromParent.bind(this);
        return removeFromParent;
    };
    const removeChildrenFromLists = function (references: EntityIdentity[]): Promise<Promise<object>[]> {
        const childrenByParent = {};
        references.forEach((reference) => {
            childrenByParent[reference.parentId] = childrenByParent[reference.parentId] || [];
            childrenByParent[reference.parentId].push(reference.id);
        });
        const ops = Object.keys(childrenByParent).map((parentId) => {
            return { eid: parentId, function: createRemoveFromParent(childrenByParent[parentId]) };
        });
        return massEdit(ops);
    };
    return removeChildrenFromLists;
};

export const useTabFocus = (): boolean => {
    const [windowFocused, setFocused] = useState(true);
    const handleFocus = useCallback(() => setFocused(true), []);
    const handleBlur = useCallback(() => setFocused(false), []);

    // User has switched back to the tab
    const onFocus = (): void => {
        handleFocus();
    };

    // User has switched away from the tab (AKA tab is hidden)
    const onBlur = (): void => {
        handleBlur();
    };
    useEffect(() => {
        window.addEventListener('focus', onFocus);
        window.addEventListener('blur', onBlur);
        // Specify how to clean up after this effect:
        return (): void => {
            window.removeEventListener('focus', onFocus);
            window.removeEventListener('blur', onBlur);
        };
    });

    return windowFocused;
};

export interface DraggedFileType {
    files?: FileList | File[];
    items?: DataTransferItemList;
}

export const useFileDrop = (
    parentId?: string,
    index?: number,
    callback?: () => void,
    fallback?: () => void,
): ((data: DraggedFileType, options?: { index?: number; skipBrowse?: boolean }) => Promise<void>) => {
    const dispatch = useDispatch();
    const dsid = useSelector(getDsidSelector);
    const condensedParentEntity = useSelector(getCondensedEntitySelector(parentId));
    const parentCopy = cloneDeep(condensedParentEntity);
    const userId = useSelector(getUserSelector)?.id;

    return async (data: DraggedFileType, options?: { index?: number; skipBrowse?: boolean }): Promise<void> => {
        const position = typeof options?.index === 'number' ? options.index : index;
        const onFileUploadSuccess = (name: string) => {
            return dispatch(setFileUploadSuccess(name));
        };
        const onFileUploadError = (name: string, errorMessage: string) => {
            dispatch(setFileUploadFail(name));
            dispatch(
                toast({
                    msgType: MessageType.ERROR,
                    text: errorMessage,
                }),
            );
        };
        const onUploadProgress = (event: ProgressEvent, eid: string) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            dispatch(setUploadingEntityProgress(eid, progress));
        };
        const entries = data?.items ? await getEntriesAsync(Array.from(data.items)) : Array.from(data.files);
        const { fullList, topLevelChildren } = buildCondensedEntityListFromUploads(entries, userId, dsid);
        if (parentCopy) {
            parentCopy.childrenList = addEidsToChildrenListAndClone(
                parentCopy,
                topLevelChildren,
                position,
            ).childrenList;
        }

        dispatch(setUploadingEntities(fullList.map((entity) => entity.metadata.id)));
        dispatch(showUploadFilesToast(fullList.map((entity) => entity.name)));
        dispatch(toggleExpandedHListItems([parentId], true));
        dispatch(presetCondensedEntities([parentCopy, ...fullList].filter(Boolean), true, 'useFileDrop'));

        const docEntities = await uploadFiles(
            entries,
            { author: name },
            dsid,
            onFileUploadSuccess,
            onFileUploadError,
            onUploadProgress,
        );
        dispatch(
            bulkCreateEntities({
                newEntities: docEntities,
                parentId,
                index: position,
                callback,
                fallback,
                allEntitiesIncluded: true,
                skipBrowseOnCreate: options?.skipBrowse || false,
            }),
        );
        dispatch(clearUploadingEntities());
        parentId && dispatch(toggleExpandedHListItems([parentId], true));
        parsePdfs(data.files, docEntities);
    };
};

interface FileItem {
    type: string;
    files?: FileList;
    items?: DataTransferItemList;
}

export const useFullScreenDropZone = (
    parentId,
    callback?: () => void,
    fallback?: () => void,
    msToDismiss = 1500,
): JSX.Element => {
    const handleFileDrop = useFileDrop(parentId, null, callback, fallback);
    const DROPZONE_ID = 'full-screen-dropzone';
    const [classname, setClassname] = useState('');
    const [timer, setTimer] = useState(null);

    const isFile = (e): boolean => e.dataTransfer?.items[0]?.kind === 'file';

    const showDropzone = (): void => setClassname(reactDnDStyles.dropzone);
    const hideDropzone = (): void => setClassname('');

    const handleDragEnter = (e): void => {
        if (isFile(e) && !timer) {
            showDropzone();
            if (msToDismiss)
                setTimer(
                    setTimeout(() => {
                        hideDropzone();
                        document?.removeEventListener('dragenter', handleDragEnter);
                    }, msToDismiss),
                );
        }
    };

    const resetDropzone = (): void => {
        if (timer) clearTimeout(timer);
        setTimer(null);
        hideDropzone();
        document?.addEventListener('dragenter', handleDragEnter);
    };

    const [, drop] = useDrop({
        accept: [NativeTypes.FILE],
        canDrop: (item: FileItem, monitor) => {
            if (item.files) return monitor.isOver({ shallow: true });
        },
        drop: (item: FileItem, monitor) => {
            if (!monitor.didDrop() && item.files) {
                handleFileDrop(item, { index: -1, skipBrowse: true });
                resetDropzone();
                return;
            }
        },
    });

    useEffect(() => {
        document?.addEventListener('dragenter', handleDragEnter);
        document?.addEventListener('mouseleave', resetDropzone);

        return (): void => {
            document?.removeEventListener('dragenter', handleDragEnter);
            document?.removeEventListener('mouseleave', resetDropzone);
        };
    }, []);

    return <div ref={drop} id={DROPZONE_ID} className={classname} />;
};

const globalKeyMap = {
    CTRL_ENTER: 'ctrl+enter',
    COMMAND_ENTER: 'command+enter',
    CTRL_V: 'ctrl+v',
    COMMAND_V: 'command+v',
    CTRL_SLASH: 'ctrl+/',
    COMMAND_SLASH: 'command+/',
};

const hlistKeyMap = {
    CTRL_C: 'ctrl+c',
    COMMAND_C: 'command+c',
    CTRL_V: 'ctrl+v',
    COMMAND_V: 'command+v',
    CTRL_L: 'ctrl+l',
    COMMAND_L: 'command+l',
    CTRL_G: 'ctrl+g',
    COMMAND_G: 'command+g',
    CTRL_DEL: 'ctrl+del',
    COMMAND_DEL: 'command+backspace',
    CTRL_ENTER: 'ctrl+enter',
    COMMAND_ENTER: 'command+enter',
    CTRL_SHIFT_ENTER: 'ctrl+shift+enter',
    COMMAND_SHIFT_ENTER: 'command+shift+enter',
};

export const useHListShortcutKeys = () => {
    const dispatch = useDispatch();
    const dsid = useSelector(getDsidSelector);
    const selectedEntities = useSelector(getSelectedEntitiesSelector);
    const singleSelectedEntity = selectedEntities?.length === 1 ? selectedEntities[0] : null;
    const copiedEntities = useSelector(getCopiedEntitiesSelector);
    const copiedEntityIds = copiedEntities.map((e) => e.id);
    const copiedActualEntities = useEntities(copiedEntityIds, false);
    const fullSingleSelectedEntity = useSelector(getFullEntitySelector(singleSelectedEntity?.id));
    const finderId = useSelector(getFinderSelector)?.id;
    const renamingInstance = useSelector(getRenamingInstanceSelector);
    const isHlistCollapsed = useSelector(getHlistCollapsedStateSelector);
    const hlistCanBeToggled = useSelector(getIsHlistCanBeToggledSelector);

    const copyEntities = useCallback(
        (e) => {
            if (selectedEntities?.length && !renamingInstance) {
                e.preventDefault();
                dispatch(setCopiedEntities(selectedEntities));
                dispatch(
                    toast({
                        msgType: 'success',
                        text: `Successfully copied ${selectedEntities.length > 1 ? 'Entities' : 'Entity'} to clipboard`,
                    }),
                );
            }
        },
        [selectedEntities, renamingInstance],
    );

    const pasteEntities = useCallback(
        (e) => {
            if (!copiedEntities?.length || renamingInstance) return;
            e.preventDefault();
            dispatch(
                bulkCreateEntities({
                    newEntities: copiedActualEntities,
                    parentId: singleSelectedEntity ? singleSelectedEntity.id : finderId,
                    isPasted: true,
                }),
            );
        },
        [copiedActualEntities, copiedEntities?.length, finderId, renamingInstance, singleSelectedEntity],
    );

    const copyEntityLink = useCallback(
        (e) => {
            if (isElectron()) {
                e.preventDefault();
                if (singleSelectedEntity) return;

                const url = `${BASE_ORIGIN}/entity/${singleSelectedEntity.id}`;
                navigator.clipboard.writeText(url);
                dispatch(
                    toast({
                        msgType: 'success',
                        text: `Successfully copied ${
                            fullSingleSelectedEntity?.name ||
                            'Untitled ' + typeInfo[fullSingleSelectedEntity['@type']].fancyName
                        } link`,
                    }),
                );
            }
        },
        [fullSingleSelectedEntity, singleSelectedEntity],
    );

    const deleteEntity = useCallback(
        (e) => {
            e.preventDefault();
            const isSharedDataspace = dsid === SHARED_DATASPACE_ID;
            if (selectedEntities?.length && !isSharedDataspace) {
                dispatch(bulkRemoveChildEids(selectedEntities));
            }
        },
        [dsid, selectedEntities],
    );

    const openEntityInNewTab = useCallback(
        (e) => {
            e.preventDefault();
            if (!singleSelectedEntity) return;

            const url = `${BASE_ORIGIN}/entity/${singleSelectedEntity.id}`;
            window.open(url);
        },
        [singleSelectedEntity],
    );

    const addEntityGlobal = useCallback((e) => {
        e.preventDefault();
        const topBarElement = document.querySelector(`div[data-top-bar-scoped-eid]`);
        const scopedEid = topBarElement?.getAttribute('data-top-bar-scoped-eid');

        if (!scopedEid) return;
        const { right, bottom } = topBarElement.getBoundingClientRect();
        const horizontalOffset = 30;
        const verticalOffset = 10;
        dispatch(
            showMenu({
                type: 'QuickCreateMenu',
                x: right - horizontalOffset,
                y: bottom - verticalOffset,
                eid: scopedEid,
            }),
        );
    }, []);

    const addEntity = useCallback(
        (e) => {
            e.preventDefault();
            if (!singleSelectedEntity) return;

            const targetNode = document.querySelector(`div[data-hlistitemid='${singleSelectedEntity.id}']`);
            if (!targetNode) return;

            const { right, bottom } = targetNode.getBoundingClientRect();
            const horizontalOffset = 54;
            const verticalOffset = 7;
            dispatch(
                showMenu({
                    type: 'QuickCreateMenu',
                    x: right - horizontalOffset,
                    y: bottom - verticalOffset,
                    eid: singleSelectedEntity.id,
                }),
            );
        },
        [singleSelectedEntity],
    );

    const groupEntities = useCallback(
        (e) => {
            e.preventDefault();
            if (selectedEntities?.length) {
                dispatch(bulkGroupEntities(selectedEntities, selectedEntities[0].parentId, selectedEntities[0].id));
            }
        },
        [selectedEntities],
    );

    const toggleHlistVisible = useCallback(
        (e) => {
            e.preventDefault;
            if (!hlistCanBeToggled) {
                return;
            }
            dispatch(setUI({ isHlistCollapsed: !isHlistCollapsed }));
        },
        [hlistCanBeToggled, isHlistCollapsed],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const hlistHandlers = useMemo(
        () => ({
            CTRL_C: copyEntities,
            COMMAND_C: copyEntities,
            CTRL_V: pasteEntities,
            COMMAND_V: pasteEntities,
            CTRL_L: copyEntityLink,
            COMMAND_L: copyEntityLink,
            CTRL_G: groupEntities,
            COMMAND_G: groupEntities,
            CTRL_DEL: deleteEntity,
            COMMAND_DEL: deleteEntity,
            CTRL_ENTER: openEntityInNewTab,
            COMMAND_ENTER: openEntityInNewTab,
            CTRL_SHIFT_ENTER: addEntity,
            COMMAND_SHIFT_ENTER: addEntity,
        }),
        [addEntity, copyEntities, copyEntityLink, deleteEntity, groupEntities, openEntityInNewTab, pasteEntities],
    );

    const globalHandlers = useMemo(
        () => ({
            CTRL_ENTER: addEntityGlobal,
            COMMAND_ENTER: addEntityGlobal,
            CTRL_V: pasteEntities,
            COMMAND_V: pasteEntities,
            CTRL_SLASH: toggleHlistVisible,
            COMMAND_SLASH: toggleHlistVisible,
        }),
        [addEntityGlobal, pasteEntities, toggleHlistVisible],
    );

    return { hlistKeyMap, hlistHandlers, globalKeyMap, globalHandlers };
};
