type UniversalId = {
    eid?: string;
    fid?: string;
    app?: string;
    appId?: string;
    parentEid?: string;
};

const delimiter = ':::';

function buildUID({ eid, fid, app, appId, parentEid }: UniversalId) {
    if (eid) return eid;
    let uid = '';

    [fid, app, appId, parentEid].forEach((part) => {
        if (part != null) {
            uid += (uid.length > 0 ? delimiter : '') + part;
        }
    });

    return uid;
}

function parseUID(uid): UniversalId {
    const parts = uid?.split(delimiter) || [];
    const [id, app, appId, parentId] = parts;
    if (app && appId) {
        return { fid: id, app, appId, parentEid: parentId };
    } else {
        return { eid: id };
    }
}

export { buildUID, parseUID };
