import Text from '@/components/DesignSystem/Text';
import typeInfo from '@/components/entities/typeInfo';
import { useCreateEntity } from '@/hooks/reduxHooks';
import { logQuickCreateMenuItem } from '@/redux/ui/actions';
import { getQuickCreateMenuLogsSelector } from '@/redux/ui/selectors';
import { buildObjectByType } from '@/utils/objectTemplateFactory';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    createIntegrationFilesApps,
    entityTypes,
    filterItemsByLabel,
    findMenuItem,
    integrationCreateTypes,
    menuItems,
    objectTypes,
} from './data';
import styles from '../contextMenu.module.scss';
import { useFileDrop } from '@/hooks/remoteHooks';
import { getIntegrationAccountsSelector } from '@/redux/user/selectors';
import { INTEGRATIONS } from '../../../integrations/constants';
import { MenuType } from '@/redux/types';
import BlobMdIcon from '@/icons/BlobMdIcon';
import IntegrationAccountIcon from '@/components/entities/subcomponents/IntegrationAccountIcon';
import { createIntegrationItem } from '@/redux/integrations/actions';
import { QuickCreateItem } from '@/components/modals/QuickCreateMenu/types';
import FlatListItems from '@/components/modals/QuickCreateMenu/FlatListItems';
import SubMenuItem from '@/components/modals/QuickCreateMenu/SubMenuItem';
import MenuItem from '@/components/modals/QuickCreateMenu/MenuItem';
import clsx from 'clsx';

const findAncestorsDataAttributes = (element, attributes) => {
    if (!element) {
        return null;
    }
    const result = {};
    attributes.forEach(function (attribute) {
        const value = element.getAttribute(`data-${attribute}`);
        if (value) {
            result[attribute] = value;
        }
    });
    if (Object.keys(result).length > 0) {
        return result;
    }
    return findAncestorsDataAttributes(element.parentNode, attributes);
};

type Props = {
    isVisible: boolean;
    menuParams: MenuType;
    menuRef: React.RefObject<HTMLDivElement>;
    closeMenu: () => void;
    onSelect: (action: (e: any) => void) => (e: any) => void;
    updateSubmenuPositions: (value: CSSProperties) => void;
    subMenuPositionCSS: CSSProperties;
};

export function QuickCreateMenu({
    onSelect,
    isVisible,
    closeMenu,
    subMenuPositionCSS,
    updateSubmenuPositions,
    menuParams,
    menuRef,
}: Props): React.ReactElement {
    const dispatch = useDispatch();
    const { eid: parentId, y } = menuParams;
    const searchInputRef = useRef<HTMLInputElement>(null);
    const [searchInputValue, setSearchInputValue] = useState('');
    const [selectedItem, setSelectedItem] = useState(0);
    const [entityFiles, setEntityFiles] = useState<File[]>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const createEntity = useCreateEntity(parentId);
    const recentlyCreatedLogs = useSelector(getQuickCreateMenuLogsSelector);
    const integrationAccounts = useSelector(getIntegrationAccountsSelector);
    const recentlyCreatedMenuItems = useMemo(
        () => recentlyCreatedLogs.map((log) => findMenuItem(menuItems, log)),
        [recentlyCreatedLogs, menuItems],
    );
    const filteredItems = useMemo(() => filterItemsByLabel(menuItems, searchInputValue), [searchInputValue, menuItems]);

    const handleFileDrop = useFileDrop(parentId);

    const integrationList = Object.keys(integrationAccounts || {})
        ?.filter((id) => createIntegrationFilesApps.includes(INTEGRATIONS[integrationAccounts[id].app].name))
        .map((id) => ({ ...integrationAccounts[id], label: INTEGRATIONS[integrationAccounts[id].app].label, id }));

    const handleEntityCreation = (itemType: string, itemValue) => {
        if (itemType === 'entityType') {
            createEntity(itemValue);
        } else if (itemType === 'objectType') {
            const objectEntity = buildObjectByType(itemValue);
            createEntity('Blob', { entity: objectEntity });
        }
        dispatch(logQuickCreateMenuItem(itemValue));
    };

    const handleSearchInputValueChange = (e) => {
        setSearchInputValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (searchInputValue.length) {
            if (e.key === 'ArrowUp') {
                setSelectedItem(selectedItem > 0 ? selectedItem - 1 : filteredItems.length - 1);
                e.preventDefault();
            } else if (e.key === 'ArrowDown') {
                setSelectedItem(selectedItem < filteredItems.length - 1 ? selectedItem + 1 : 0);
                e.preventDefault();
            } else if (e.key === 'Enter') {
                const item = filteredItems[selectedItem];
                // need to imitate click so that ui feedback on select works properly
                const targetElement = document.querySelector(
                    `[data-item-type="${item.type}"][data-item-value="${item.value}"]`,
                ) as HTMLElement;
                targetElement.click();
                e.preventDefault();
            } else if (e.key === 'Escape') {
                closeMenu();
                e.preventDefault();
            }
        }
    };

    const handleMenuItemClick = (e) => {
        const keys = ['item-type', 'item-value'];
        const attributes = findAncestorsDataAttributes(e.target, keys);
        handleEntityCreation(attributes['item-type'], attributes['item-value']);
    };

    const handleCreateIntegrationItem = (e) => {
        const keys = ['item-mime-type', 'item-app', 'item-app-id'];
        const attributes = findAncestorsDataAttributes(e.target, keys);
        dispatch(
            createIntegrationItem({
                app: attributes['item-app'],
                appId: attributes['item-app-id'],
                fileMetadata: {
                    mimeType: attributes['item-mime-type'],
                },
                parentId,
            }),
        );
    };

    const handleUploadEntityClick = (e) => {
        e.target.classList.add(styles.blink);
        e.target.addEventListener(
            'animationend',
            () => {
                inputRef.current.click();
                e.target.classList.remove(styles.blink);
            },
            { once: true },
        );
    };

    const normalizeRecentCreateItems = () => {
        return recentlyCreatedMenuItems.map(
            (recentType): QuickCreateItem => ({
                display: true,
                name: recentType.label,
                type: recentType.type,
                value: recentType.value,
                icon: recentType.icon,
                onClick: onSelect(handleMenuItemClick),
            }),
        );
    };

    const normalizeIntegrationCreateItems = () => {
        return integrationList.map(
            (integration): QuickCreateItem => ({
                name: integration.app + integration.appId,
                isIntegration: true,
                helperLabel: {
                    base: integration.app,
                    extra: integration.appId,
                },
                icon: <IntegrationAccountIcon id={integration.id} />,
                display: true,
                subMenu: true,
                subMenuItems: integrationCreateTypes[integration.app].map((integrationType) => ({
                    value: integrationType.mimeType,
                    iconUrl: integrationType.iconUrl,
                    name: integrationType.label,
                    onClick: onSelect(handleCreateIntegrationItem),
                    app: integration.app,
                    appId: integration.appId,
                })),
            }),
        );
    };

    const quickCreateItems: QuickCreateItem[] = [
        {
            name: 'EntityTypes',
            flatMenu: true,
            display: true,
            flatItems: entityTypes.map((entityType) => ({
                value: entityType.value,
                type: 'entityType',
                icon: entityType.icon,
                name: typeInfo[entityType.value].fancyName,
                onClick:
                    entityType.value !== 'DigitalDocument' ? onSelect(handleMenuItemClick) : handleUploadEntityClick,
            })),
        },
        {
            divider: true,
            display: true,
        },
        {
            name: 'Objects',
            subMenu: true,
            display: true,
            icon: <BlobMdIcon />,
            subMenuItems: objectTypes.map((objectType) => ({
                value: objectType.value,
                icon: objectType.icon,
                type: 'objectType',
                name: objectType.label,
                onClick: onSelect(handleMenuItemClick),
            })),
        },
        ...normalizeIntegrationCreateItems(),
        {
            divider: true,
            dividerLabel: 'Recents',
            display: recentlyCreatedMenuItems.length > 0,
        },
        ...normalizeRecentCreateItems(),
    ];

    const filteredQuickCreateItems = quickCreateItems.filter((item) => item.display && !item?.divider);

    const [currentSelectedItem, setCurrentSelectedItem] = useState(null);
    const [currentSelectedSubmenuItem, setCurrentSelectedSubmenuItem] = useState(null);
    const [currentSubmenuList, setCurrentSubmenuList] = useState(null);

    useEffect(() => {
        const checkFlatItemIndex = (selectedItem) => {
            return filteredQuickCreateItems.findIndex((item) =>
                item?.flatItems?.some((flatItem) => flatItem.name === selectedItem),
            );
        };
        const isInFlatPosition = (item) => {
            return item?.flatMenu;
        };
        if (!searchInputValue.length) {
            const handleKeyboardNavigation = (e) => {
                switch (e.key) {
                    case 'ArrowDown':
                        if (!currentSelectedItem && !currentSelectedSubmenuItem) {
                            const isNextFlatItem = isInFlatPosition(filteredQuickCreateItems[0]);
                            if (isNextFlatItem) {
                                setCurrentSelectedSubmenuItem(filteredQuickCreateItems[0].flatItems[0].name);
                            } else {
                                setCurrentSelectedItem(filteredQuickCreateItems[0].name);
                            }
                        } else if (currentSubmenuList) {
                            const prevIndex = currentSubmenuList.findIndex(
                                (item) => item.name === currentSelectedSubmenuItem,
                            );
                            const newIndex = prevIndex === currentSubmenuList.length - 1 ? 0 : prevIndex + 1;
                            setCurrentSelectedSubmenuItem(currentSubmenuList[newIndex].name);
                        } else if (!currentSelectedItem && currentSelectedSubmenuItem) {
                            const flatMenuIndex = checkFlatItemIndex(currentSelectedSubmenuItem);
                            if (flatMenuIndex !== -1) {
                                const nextIndex =
                                    flatMenuIndex + 1 === filteredQuickCreateItems.length ? 0 : flatMenuIndex + 1;
                                setCurrentSelectedItem(filteredQuickCreateItems[nextIndex].name);
                                setCurrentSelectedSubmenuItem(null);
                            }
                        } else {
                            const prevIndex = filteredQuickCreateItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            const newIndex = prevIndex === filteredQuickCreateItems.length - 1 ? 0 : prevIndex + 1;
                            const isNextFlatItem = isInFlatPosition(filteredQuickCreateItems[newIndex]);
                            if (isNextFlatItem) {
                                setCurrentSelectedSubmenuItem(filteredQuickCreateItems[newIndex].flatItems[0].name);
                                setCurrentSelectedItem(null);
                            } else {
                                setCurrentSelectedItem(filteredQuickCreateItems[newIndex].name);
                            }
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowUp':
                        if (!currentSelectedItem && !currentSelectedSubmenuItem) {
                            const isNextFlatItem = isInFlatPosition(filteredQuickCreateItems[0]);
                            if (isNextFlatItem) {
                                setCurrentSelectedSubmenuItem(filteredQuickCreateItems[0].flatItems[0].name);
                            } else {
                                setCurrentSelectedItem(filteredQuickCreateItems[0].name);
                            }
                        } else if (currentSubmenuList) {
                            const prevIndex = currentSubmenuList.findIndex(
                                (item) => item.name === currentSelectedSubmenuItem,
                            );
                            const newIndex = prevIndex === 0 ? currentSubmenuList.length - 1 : prevIndex - 1;
                            setCurrentSelectedSubmenuItem(currentSubmenuList[newIndex].name);
                        } else if (!currentSelectedItem && currentSelectedSubmenuItem) {
                            const flatMenuIndex = checkFlatItemIndex(currentSelectedSubmenuItem);
                            if (flatMenuIndex !== -1) {
                                const nextIndex =
                                    flatMenuIndex === 0 ? filteredQuickCreateItems.length - 1 : flatMenuIndex - 1;
                                setCurrentSelectedItem(filteredQuickCreateItems[nextIndex].name);
                                setCurrentSelectedSubmenuItem(null);
                            }
                        } else {
                            const prevIndex = filteredQuickCreateItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            const newIndex = prevIndex === 0 ? filteredQuickCreateItems.length - 1 : prevIndex - 1;
                            const isNextFlatItem = isInFlatPosition(filteredQuickCreateItems[newIndex]);
                            if (isNextFlatItem) {
                                setCurrentSelectedSubmenuItem(filteredQuickCreateItems[newIndex].flatItems[0].name);
                                setCurrentSelectedItem(null);
                            } else {
                                setCurrentSelectedItem(filteredQuickCreateItems[newIndex].name);
                            }
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowRight':
                        if (currentSelectedSubmenuItem && !currentSelectedItem) {
                            const currentFlatMenuIndex = checkFlatItemIndex(currentSelectedSubmenuItem);
                            if (currentFlatMenuIndex !== -1) {
                                const flatList = filteredQuickCreateItems[currentFlatMenuIndex].flatItems;
                                const prevIndex = flatList.findIndex(
                                    (item) => item.name === currentSelectedSubmenuItem,
                                );
                                const newIndex = prevIndex === flatList.length - 1 ? 0 : prevIndex + 1;
                                setCurrentSelectedSubmenuItem(flatList[newIndex].name);
                            }
                        } else if (currentSelectedItem && !currentSelectedSubmenuItem) {
                            const currentIndex = filteredQuickCreateItems.findIndex(
                                (item) => item.name === currentSelectedItem,
                            );
                            if (filteredQuickCreateItems[currentIndex]?.subMenu) {
                                setCurrentSubmenuList(filteredQuickCreateItems[currentIndex]?.subMenuItems);
                                setCurrentSelectedSubmenuItem(
                                    filteredQuickCreateItems[currentIndex]?.subMenuItems[0].name,
                                );
                            }
                        }
                        e.preventDefault();
                        break;
                    case 'ArrowLeft':
                        if (currentSelectedSubmenuItem) {
                            const currentFlatMenuIndex = checkFlatItemIndex(currentSelectedSubmenuItem);
                            if (currentFlatMenuIndex !== -1) {
                                const flatList = filteredQuickCreateItems[currentFlatMenuIndex].flatItems;
                                const prevIndex = flatList.findIndex(
                                    (item) => item.name === currentSelectedSubmenuItem,
                                );
                                const newIndex = prevIndex === 0 ? flatList.length - 1 : prevIndex - 1;
                                setCurrentSelectedSubmenuItem(flatList[newIndex].name);
                            } else {
                                setCurrentSubmenuList(null);
                                setCurrentSelectedSubmenuItem(null);
                            }
                        }
                        e.preventDefault();
                        break;
                    case 'Enter':
                        if (currentSelectedSubmenuItem) {
                            // need to imitate click so that ui feedback on select works properly
                            const targetElement = document.querySelector(
                                `[data-action-name="${currentSelectedSubmenuItem}"]`,
                            ) as HTMLElement;
                            targetElement.click();
                        } else if (currentSelectedItem) {
                            // need to imitate click so that ui feedback on select works properly
                            const targetElement = document.querySelector(
                                `[data-action-name="${currentSelectedItem}"]`,
                            ) as HTMLElement;
                            targetElement.click();
                        }
                        e.preventDefault();
                        break;
                    case 'Escape':
                        closeMenu();
                        e.preventDefault();
                        break;
                    default:
                        break;
                }
            };
            document.addEventListener('keydown', handleKeyboardNavigation);

            return () => document.removeEventListener('keydown', handleKeyboardNavigation);
        }
    }, [currentSelectedItem, currentSelectedSubmenuItem, currentSubmenuList, searchInputValue, closeMenu]);

    useEffect(() => {
        setSelectedItem(0);
    }, [searchInputValue]);

    useEffect(() => {
        if (isVisible) {
            searchInputRef.current.focus();
            setSearchInputValue('');
        }
    }, [isVisible]);

    useEffect(() => {
        if (entityFiles?.length) {
            handleFileDrop({ files: entityFiles });
            closeMenu();
        }
    }, [entityFiles, closeMenu]);

    useEffect(() => {
        const verticalPadding = 5;
        const submenuItemHeight = 31;
        const subMenuVerticalOffset = 90;
        const objectTypesHeight = objectTypes.length * submenuItemHeight;
        if (y > window?.innerHeight - (verticalPadding * 2 + objectTypesHeight + subMenuVerticalOffset)) {
            updateSubmenuPositions({
                bottom: `-${verticalPadding}px`,
                top: 'unset',
            });
        }
    }, [y, menuRef]);

    const results = useMemo(() => {
        if (searchInputValue?.length === 0) {
            return quickCreateItems.map((item, idx) => {
                if (!item.display) return;

                if (item?.divider) {
                    return item?.dividerLabel ? (
                        <div key={idx}>
                            <div className={styles.divider}></div>
                            <div style={{ paddingLeft: 14 }}>
                                <Text variant="inactive" size="xs">
                                    {item.dividerLabel}
                                </Text>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.divider} key={idx}></div>
                    );
                }

                if (item.flatMenu) {
                    return (
                        <FlatListItems
                            key={item.name + idx}
                            items={item.flatItems}
                            currentSelectedSubmenuItem={currentSelectedSubmenuItem}
                            setCurrentSelectedSubmenuItem={setCurrentSelectedSubmenuItem}
                        />
                    );
                }

                if (item.subMenu) {
                    return (
                        <SubMenuItem
                            key={item.name + idx}
                            item={item}
                            currentSelectedItem={currentSelectedItem}
                            setCurrentSelectedItem={setCurrentSelectedItem}
                            currentSubmenuList={currentSubmenuList}
                            setCurrentSubmenuList={setCurrentSubmenuList}
                            currentSelectedSubmenuItem={currentSelectedSubmenuItem}
                            setCurrentSelectedSubmenuItem={setCurrentSelectedSubmenuItem}
                            subMenuPositionCSS={subMenuPositionCSS}
                        />
                    );
                } else {
                    return (
                        <MenuItem
                            key={item.name + idx}
                            item={item}
                            currentSelectedItem={currentSelectedItem}
                            setCurrentSelectedItem={setCurrentSelectedItem}
                        />
                    );
                }
            });
        }
        const filteredSearchItemsToRender = filteredItems.map((item, index) => {
            const label = item.label.replace(
                new RegExp(searchInputValue, 'gi'),
                (match) => `<span class="${styles.highlighted}">${match}</span>`,
            );
            return (
                <div
                    key={index}
                    className={clsx(styles.item, index === selectedItem && styles.selected)}
                    data-item-type={item.type}
                    data-item-value={item.value}
                    onMouseEnter={() => setSelectedItem(index)}
                    onClick={item.value !== 'DigitalDocument' ? onSelect(handleMenuItemClick) : handleUploadEntityClick}
                >
                    <div className={styles.itemIcon}>{item.icon}</div>
                    <div className={styles.itemLabel} dangerouslySetInnerHTML={{ __html: label }}></div>
                </div>
            );
        });

        if (filteredSearchItemsToRender.length === 0)
            return (
                <Text align="center" size="s" variant="inactive">
                    No results
                </Text>
            );

        return filteredSearchItemsToRender;
    }, [
        currentSelectedItem,
        currentSelectedSubmenuItem,
        currentSubmenuList,
        filteredItems,
        handleMenuItemClick,
        onSelect,
        quickCreateItems,
        searchInputValue,
        selectedItem,
        subMenuPositionCSS,
    ]);

    return (
        <div>
            <div className={styles.searchContainer}>
                <input
                    type="text"
                    placeholder="Search"
                    className={styles.searchInput}
                    ref={searchInputRef}
                    value={searchInputValue}
                    onChange={handleSearchInputValueChange}
                    onKeyDown={handleKeyDown}
                />
            </div>

            <input
                ref={inputRef}
                tabIndex={-1}
                type="file"
                multiple
                accept="*/*"
                style={{ display: 'none' }}
                onChange={(e) => setEntityFiles([...e.target.files])}
            />

            <div className={styles.divider}></div>
            {results}
        </div>
    );
}
