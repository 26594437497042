export default function VideoMdIcon(): JSX.Element {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M6.94884 9.74352L7.19691 10.1776L6.94884 9.74352ZM6.94884 6.25648L6.70077 6.6906L6.94884 6.25648ZM9.54417 8.26047L9.2961 7.82635L9.54417 8.26047ZM9.54417 7.73953L9.2961 8.17365L9.54417 7.73953ZM1.00003 11.75V13.5H2.00003V11.75H1.00003ZM15 13.5V11.75H14V13.5H15ZM1.00003 2.5V4.5H2.00003V2.5H1.00003ZM15 4.5V2.5H14V4.5H15ZM3.5 1H2.50003V2H3.5V1ZM13.5 1H12.5V2H13.5V1ZM6 6.51695V9.48305H7V6.51695H6ZM7.19691 10.1776L9.79224 8.69459L9.2961 7.82635L6.70077 9.3094L7.19691 10.1776ZM9.79224 7.30541L7.19691 5.82236L6.70077 6.6906L9.2961 8.17365L9.79224 7.30541ZM2.50003 15H3.5V14H2.50003V15ZM12.5 15H13.5V14H12.5V15ZM12 1.5V4.5H13V1.5H12ZM14.5 4H12.5V5H14.5V4ZM12 11.75V14.5H13V11.75H12ZM14.5 11.25H12.5V12.25H14.5V11.25ZM15 11.75V8.125H14V11.75H15ZM15 8.125V4.5H14V8.125H15ZM12 4.5V8.125H13V4.5H12ZM12 8.125V11.75H13V8.125H12ZM14.5 7.625H12.5V8.625H14.5V7.625ZM1.00003 4.5V8H2.00003V4.5H1.00003ZM1.00003 8V11.75H2.00003V8H1.00003ZM3.5 7.5H1.50003V8.5H3.5V7.5ZM3 1.5V4.5H4V1.5H3ZM3 4.5V8H4V4.5H3ZM1.50003 5H3.5V4H1.50003V5ZM3 8V11.75H4V8H3ZM3 11.75V14.5H4V11.75H3ZM1.50003 12.25H3.5V11.25H1.50003V12.25ZM3.5 2H12.5V1H3.5V2ZM3.5 15H12.5V14H3.5V15ZM6 9.48305C6 10.0973 6.66359 10.4824 7.19691 10.1776L6.70077 9.3094C6.83411 9.23321 7 9.32948 7 9.48305H6ZM7 6.51695C7 6.67052 6.8341 6.76679 6.70077 6.6906L7.19691 5.82236C6.66359 5.5176 6 5.9027 6 6.51695H7ZM15 2.5C15 1.67157 14.3284 1 13.5 1V2C13.7761 2 14 2.22386 14 2.5H15ZM14 13.5C14 13.7761 13.7761 14 13.5 14V15C14.3284 15 15 14.3284 15 13.5H14ZM1.00003 13.5C1.00003 14.3284 1.6716 15 2.50003 15V14C2.22389 14 2.00003 13.7761 2.00003 13.5H1.00003ZM9.79224 8.69459C10.3297 8.38748 10.3297 7.61252 9.79224 7.30541L9.2961 8.17365C9.16174 8.09687 9.16174 7.90313 9.2961 7.82635L9.79224 8.69459ZM2.00003 2.5C2.00003 2.22386 2.22389 2 2.50003 2V1C1.6716 1 1.00003 1.67157 1.00003 2.5H2.00003Z"
                fill="#333333"
            />
        </svg>
    );
}
